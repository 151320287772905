import { Controller, Validate, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { ModuleSoftParamsShell } from "../../../../shared/ModuleSoftParamsShell";
import { SoftParamsFormsProps } from "../../../../shared/ModuleSoftParamsShell/types";
import { InputWrapper } from "../../../../shared/style";
import { CompareSelector } from "../shared/CompareSelector";

interface ModuleWithDecimalProps extends SoftParamsFormsProps {
  validate?:
    | Validate<string, IStrategyCreator>
    | Record<string, Validate<string, IStrategyCreator>>;
}

export const ModuleWithDecimal = ({ index, validate, ...props }: ModuleWithDecimalProps) => {
  const { control } = useFormContext<IStrategyCreator>();

  return (
    <ModuleSoftParamsShell {...props}>
      <InputWrapper>
        <CompareSelector control={control} index={index} />

        <Controller
          control={control}
          name={`conditions.${index}.soft_params.value`}
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate,
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `conditions.${index}.soft_params.value`);
            return (
              <LabeledInput
                label="Value"
                type="number"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e, true))}
                errorHint={error?.message}
              />
            );
          }}
        />
      </InputWrapper>
    </ModuleSoftParamsShell>
  );
};
