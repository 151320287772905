import { observer } from "mobx-react-lite";
import React from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { noOp } from "src/helpers/utils";
import useAppState from "src/state";
import EditUserStore from "src/state/UserManager/EditUserStore";
import * as styles from "../../../shared";
import { SaveForm } from "../../../shared/SaveForm";
import { ContentWrapper, ReadOnlyInput, ToggleLabel } from "./style";

export interface EditUserProps {
  state: EditUserStore;
}

export const EditUser = observer(({ state: userState }: EditUserProps) => {
  const { UserManagerState: state } = useAppState();

  return (
    <ModalPanel
      label={`Edit ${userState.currentUser.name}`}
      show={userState.isShown}
      close={userState.closeModal}
      loading={userState.isLoading}
    >
      <styles.Container>
        <ContentWrapper>
          <SaveForm
            onSubmit={userState.saveTgHandler()}
            saved={userState.savedStatus("tg_handler")}
          >
            <LabeledInput
              label="Tg handler"
              value={userState.currentEditUser.tg_handler}
              onChange={userState.getHandler("tg_handler")}
              errorHint={userState.errors.tg_handler}
            />
          </SaveForm>

          <ReadOnlyInput
            label="User type"
            value={userState.currentUser.group_type}
            readOnly
            onChange={noOp}
          />

          <SaveForm onSubmit={userState.saveEmail()} saved={userState.savedStatus("email")}>
            <LabeledInput
              label="Change email"
              value={userState.currentEditUser.email}
              onChange={userState.getHandler("email")}
              errorHint={userState.errors.email}
            />
          </SaveForm>

          <SaveForm onSubmit={userState.savePassword()} saved={userState.savedStatus("password")}>
            <LabeledInput
              label="Change password"
              value={userState.currentEditUser.password}
              onChange={userState.getHandler("password")}
              errorHint={userState.errors.password}
            />
          </SaveForm>

          <ToggleLabel checked={userState.currentUser.is_active}>
            {" "}
            <ToggleSwitch
              checked={userState.currentUser.is_active}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                state.toggleActiveUser(userState.currentUser, e.target.checked);
              }}
            />{" "}
            Deactivation profile
          </ToggleLabel>
        </ContentWrapper>
      </styles.Container>
    </ModalPanel>
  );
});
