import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { getDEXSettings, setDEXSettings } from "src/api/bots/DEX/settings";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { FormDataKeys, FormErrors, FormHandlers } from "src/helpers/forms/types";
import { DEXSettings } from "src/modules/settings";
import {
  graterThan,
  graterThanKey,
  isInteger,
  required,
  smallerThanKey,
  strictlyGreaterThan,
  validateData,
} from "src/validation-schemas";

export type DEXSettingsKeys = FormDataKeys<DEXSettings>;

class DEXSettingsStore {
  _bot_uuid = "";

  market = "";

  data: DEXSettings = {
    link: "",
    settings: {
      period: 0,
      minAmount: 0,
      maxAmount: 0,
      ordersMin: 0,
      ordersMax: 0,
      buyPercent: 0,
      slipPage: 0,
      gasMult: 0,
      gasMax: 0,
      timeSeconds: 0,
    },
  };

  tokensBuySell = 0;

  tokenVolume = 0;

  fee = 0;

  showLoader = false;

  handlers: FormHandlers<DEXSettings> = {};

  validation = {
    "settings.period": [required(), isInteger(), graterThan(0, "The value must be positive")],
    "settings.minAmount": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      smallerThanKey("settings.maxAmount", "Min amount should be less than Max amount"),
    ],
    "settings.maxAmount": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      graterThanKey("settings.minAmount", "Max amount must be greater than Min amount"),
    ],
    "settings.slipPage": [required(), graterThan(0, "The value must be positive")],
    "settings.gasMult": required(),
    "settings.gasMax": required(),
    "settings.timeSeconds": [required(), graterThan(0, "The value must be positive")],
    "settings.ordersMin": [
      required(),
      isInteger(),
      strictlyGreaterThan(0, "The value must be strictly greater than 0"),
      smallerThanKey("settings.ordersMax", "TradesMin should be less than TradesMax"),
    ],
    "settings.ordersMax": [
      required(),
      isInteger(),
      strictlyGreaterThan(0, "The value must be strictly greater than 0"),
      graterThanKey("settings.ordersMin", "TradesMax must be greater than TradesMin"),
    ],
    "settings.buyPercent": required(),
  };

  errors: FormErrors<DEXSettings> = {};

  onChangeValidate: any = {
    "settings.ordersMin": ["settings.ordersMin", "settings.ordersMax"],
    "settings.ordersMax": ["settings.ordersMin", "settings.ordersMax"],
    "settings.minAmount": ["settings.minAmount", "settings.maxAmount"],
    "settings.maxAmount": ["settings.minAmount", "settings.maxAmount"],
  };

  constructor() {
    makeAutoObservable(this);
  }

  get bot_uuid() {
    return this._bot_uuid;
  }

  setBotUUID = (uuid: string) => {
    this._bot_uuid = uuid;
  };

  setMarket = (value: string) => {
    this.market = value;
  };

  setLoad = (bool: boolean) => {
    this.showLoader = bool;
  };

  setData = (obj: DEXSettings) => {
    this.data.link = obj.link;
    this.overwriteForm("settings", this.data, obj);
  };

  getData = async () => {
    this.setLoad(true);
    try {
      const { isError, data } = await getDEXSettings(this._bot_uuid);
      if (!isError) {
        this.setData(data);
        this.calculateInfo();
      }
    } finally {
      this.setLoad(false);
    }
  };

  overwriteForm = (key: string, obj1: any, obj2: any) => {
    for (const nextKey of Object.keys(obj1[key])) {
      const nextObj1 = obj1[key];
      const nextObj2 = obj2[key];
      if (typeof nextObj1[nextKey] === "object") {
        this.overwriteForm(nextKey, nextObj1, nextObj2);
      } else {
        nextObj1[nextKey] = nextObj2[nextKey];
      }
    }
  };

  getHandler = (key: DEXSettingsKeys) => {
    // При необходимости задавать вложенную структуру data
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      this.handlers[key] = (e) => {
        if (key === "settings.buyPercent") {
          if (+this.getChangeEventValueRangePer(e) <= 100) {
            targetData[endKey] = this.getChangeEventValueRangePer(e);
          }
        } else targetData[endKey] = this.getChangeEventValue(e);
        if (key in this.onChangeValidate) {
          this.validate(this.onChangeValidate[key]);
        }
        this.calculateInfo();
      };
    }
    return this.handlers[key];
  };

  getChangeEventValueRangePer = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    return +e.target.value;
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number" || e.target.type === "radio") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    if (e.target.type === "checkbox") {
      return e.target.checked;
    }
    if (e.target.type === "text") {
      return e.target.value;
    }
  };

  typeOfExchange = (exchange: string) => {
    const bnb = ["cake", "cake1", "cake2", "ape", "baby", "blhswap", "sushibsc", "biswap"];
    const eth = ["uni"];

    const matic = ["quick", "sushimatic"];

    const avax = ["joe", "pangolin"];

    const ftm = ["spooky"];

    const ada = ["wingriders"];

    const arbitrum = ["sushiarbi"];

    if (bnb.find((element) => element === exchange)) {
      return 0.0005;
    }
    if (eth.find((element) => element === exchange)) {
      return 0.01;
    }
    if (matic.find((element) => element === exchange)) {
      return 0.0023;
    }
    if (avax.find((element) => element === exchange)) {
      return 0.0017;
    }
    if (ftm.find((element) => element === exchange)) {
      return 0.3;
    }
    if (ada.find((element) => element === exchange)) {
      return 3;
    }
    if (arbitrum.find((element) => element === exchange)) {
      return 0.0001;
    }

    return 0;
  };

  calculateInfo = () => {
    if (
      //   this.data.settings.minAmount &&
      //   this.data.settings.tradesMin &&
      this.data.settings.period
    ) {
      const Fee = this.typeOfExchange(this.market.split("_")[2]);

      this.fee =
        (((1440 / this.data.settings.period) *
          (this.data.settings.ordersMin + this.data.settings.ordersMax)) /
          2) *
        Fee;

      this.tokenVolume =
        (((1440 / this.data.settings.period) *
          (this.data.settings.ordersMin + this.data.settings.ordersMax) *
          (this.data.settings.minAmount + this.data.settings.maxAmount)) /
          4) *
        0.997;
      this.tokensBuySell =
        (((this.tokenVolume / 24) * (this.data.settings.buyPercent - 50)) / 100) * 2;
    }
  };

  getError = (key: DEXSettingsKeys) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();
    if (valid) {
      this.setLoad(true);
      try {
        const { isError } = await setDEXSettings(this._bot_uuid, this.data);
        if (!isError)
          toast.success("Settings saved successfully", {
            autoClose: 2000,
          });
      } finally {
        this.setLoad(false);
      }
    }
  };
}

export default DEXSettingsStore;
