import { TextArea } from "src/components/shared/Notes/style";
import styled from "styled-components";

interface TextAreaProps {
  $error?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;

  border-top: ${({ theme }) => `1px solid ${theme.lightGray}`};
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExpressionTextArea = styled(TextArea)<TextAreaProps>`
  min-height: 100px;

  padding: 8px;

  background-color: ${({ theme }) => theme.dashboard.backgroundBaseField};
  border: 1px solid;
  border-color: ${({ theme, $error }) => ($error ? theme.errorLabelColor : theme.lightGray)};
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;
