import { Lambda, makeAutoObservable, onBecomeObserved, onBecomeUnobserved } from "mobx";
import {
  getCurrentUnix,
  getSystemTimezone,
  getUTCOffsetText,
  unixToDateFormat,
} from "src/helpers/dateUtils";
import { Disposable } from "src/helpers/utils";
import { ITimezoneState } from "./TimezoneStore";

interface IClockParams {
  timezone: ITimezoneState;
}

export default class ClockStore implements Disposable {
  private _currentTime!: number;

  private _intervalHandler: NodeJS.Timer | null = null;

  private _disposers: Lambda[] = [];

  private _timezoneState: ITimezoneState;

  constructor({ timezone }: IClockParams) {
    makeAutoObservable(this);

    this._timezoneState = timezone;

    this._initTime();

    const observedDisposer = onBecomeObserved(this, "_currentTime", this._startTicking);
    const unobservedDisposer = onBecomeUnobserved(this, "_currentTime", this._stopTicking);
    this._disposers.push(...[observedDisposer, unobservedDisposer]);
  }

  private get _timezone() {
    return this._timezoneState.timezone;
  }

  get time() {
    return unixToDateFormat(this._currentTime, "FullDate");
  }

  get utcOffset() {
    const systemTimezone = getSystemTimezone();

    return getUTCOffsetText(systemTimezone);
  }

  private _initTime = () => {
    this._tick();
  };

  private _tick = () => {
    this._currentTime = getCurrentUnix();
  };

  private _startTicking = () => {
    this._tick();
    this._intervalHandler = setInterval(this._tick, 1000);
  };

  private _stopTicking = () => {
    if (this._intervalHandler) {
      clearInterval(this._intervalHandler);
    }
    this._intervalHandler = null;
  };

  destroy() {
    this._stopTicking();

    this._disposers.forEach((disposer) => disposer());
    this._disposers = [];
  }
}
