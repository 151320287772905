import { faker } from "@faker-js/faker";
import dayjs, { QUnitType } from "dayjs";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { generateTime, generateTokenTickers } from "./FundingStore";

type SpreadTokenData = {
  ticker: string;
  data: ChartPoint[];
};

export type SpreadData = SpreadTokenData[];

export interface GenerateTimeSeriesOptions {
  startTimestamp?: number;
  step?: {
    value: number;
    unit: QUnitType;
  };
  count?: number;
  value?: {
    min?: number;
    max?: number;
    precision?: number;
  };
}

export const generateTimeSeries = ({
  step: { value: dayCount, unit: dayUnit } = { value: 1, unit: "hour" },
  startTimestamp,
  count,
  value: { min: minValue = 0, max: maxValue = 100, precision = 0.01 } = {},
}: GenerateTimeSeriesOptions = {}): ChartPoint[] => {
  const startTime = startTimestamp ?? generateTime();
  const startDay = dayjs.utc(startTime * 1000);

  let currentDay = startDay;

  const generateTimePoint = (): ChartPoint => {
    const time = currentDay.unix();
    const value = faker.number.float({
      min: minValue,
      max: maxValue,
      precision,
    });
    currentDay = currentDay.add(dayCount, dayUnit);
    return { time, value };
  };

  const timeSeries = faker.helpers.multiple(generateTimePoint, {
    count,
  });

  return timeSeries;
};
const generateSpreadData = (): SpreadData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const tokens = generateTokenTickers({ count: { min: 4, max: 10 } });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 100,
      precision: 0.01,
    },
  };

  const spreadData = tokens.map((ticker) => {
    const data = generateTimeSeries(generateSeriesOptions);
    return { ticker, data };
  });

  return spreadData;
};

export default class SpreadStore implements Disposable {
  private _data: SpreadData = [];

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: SpreadData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  getSpread = async () => {
    this._setLoading(true);
    this._setData([]);
    try {
      await delay(200);
      const data = generateSpreadData();
      this._setData(data);
    } catch {
      this._setData([]);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
