import { providers } from "ethers";
import { makeAutoObservable } from "mobx";
import { DEXV2VaultType } from "src/api/bots/DEXV2/create";
import { makeLoggable } from "src/helpers/logger";
import { Disposable } from "src/helpers/utils";
import { DEXV2BotVersion, DEXV2ExchangeVersion } from "src/modules/bots";
import { DEXV2Settings } from "../../DEXV2Settings";
import { ISwapVaultContract, SwapVaultContract } from "./SwapVaultContract";

export interface IDEXV2SwapSettingsProvider {
  settings: Pick<DEXV2Settings, "dex_version" | "version" | "base_data">;
}

export interface ISwapVersionProvider {
  get dexVersion(): DEXV2ExchangeVersion | null;
  get botVersion(): DEXV2BotVersion | null;
  getVaultContract: (
    type: DEXV2VaultType,
    address: string,
    signer: providers.JsonRpcSigner
  ) => ISwapVaultContract | null;
}

interface IDEXV2SwapVersionParams {
  settingsProvider: IDEXV2SwapSettingsProvider;
}

export class DEXV2SwapVersionStore implements Disposable, ISwapVersionProvider {
  private _settingsProvider: IDEXV2SwapSettingsProvider;

  constructor({ settingsProvider }: IDEXV2SwapVersionParams) {
    makeAutoObservable(this);

    this._settingsProvider = settingsProvider;

    makeLoggable<any>(this, {
      factoryAddress: true,
      _routerAddress: true,
    });
  }

  private get _settings() {
    return this._settingsProvider.settings;
  }

  private get _poolPercent() {
    return this._settings.base_data.pool_percent;
  }

  get dexVersion() {
    const version = this._settings.dex_version;
    return version || null;
  }

  get botVersion() {
    const { version } = this._settings;

    return version || null;
  }

  getVaultContract = (type: DEXV2VaultType, address: string, signer: providers.JsonRpcSigner) => {
    const version = this.botVersion;
    const { dexVersion } = this;
    const poolPercent = this._poolPercent;

    if (!version || !dexVersion || type === "main") return null;

    const contract: ISwapVaultContract = new SwapVaultContract(
      type,
      version,
      dexVersion,
      poolPercent,
      address,
      signer
    );

    return contract;
  };

  destroy = () => {};
}
