import { makeAutoObservable } from "mobx";
import { deployProfile, getDeployInfo, GetDeployInfoResponse } from "src/api/bots/DEXV2/create";
import { getBalances, GetBalancesResponse } from "src/api/bots/DEXV2/stats";
import { TabStepNavigationAction } from "src/components/BotCreating/DEX";
import { getBalanceHelperContract, getBalanceNative } from "src/helpers/getBalances";
import { makeLoggable } from "src/helpers/logger";
import { bigNumbersToNumbers } from "src/helpers/math";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValue } from "src/helpers/rounding";
import { Disposable, Mapper } from "src/helpers/utils";
import DeployerInfoStore from "./DeployerInfoStore";

export interface DEXV2Wallet {
  address: string;
  type: "deployer" | "limit" | "volume";
  balance: number;
}

export interface DeployerWallet extends DEXV2Wallet {
  type: "deployer";
}

const INITIAL_DEPLOYER_WALLET: DeployerWallet = {
  address: "",
  type: "deployer",
  balance: 0,
};

const deployerInfoRespToPrice = ({ price }: GetDeployInfoResponse): number => price;

const balancesRespToDeployerBalance: Mapper<GetBalancesResponse, number> = ({ deployer_balance }) =>
  deployer_balance;

export default class WalletGasFillStore implements Disposable {
  private _wallet: DeployerWallet = INITIAL_DEPLOYER_WALLET;

  private _deployPrice = 0;

  private _loading = false;

  private _priceLoading = false;

  private _botUUID = "";

  private _deployerInfo: DeployerInfoStore;

  constructor(deployerInfo: DeployerInfoStore) {
    this._deployerInfo = deployerInfo;

    makeAutoObservable(this);

    makeLoggable(this, { loading: true, deployDisabled: true, wallet: true });
  }

  get wallet(): DeployerWallet {
    const wallet = this._wallet;
    const { balance } = wallet;
    const fractionDigits = calcRoundingValue(balance);
    const roundedBalance = +balance.toFixed(fractionDigits);

    return { ...wallet, balance: roundedBalance };
  }

  get actionsDisabled() {
    return !this.wallet.address;
  }

  get chainMeta() {
    return this._deployerInfo.chainMeta;
  }

  private get _chainProvider() {
    return this._deployerInfo.chainProvider;
  }

  private get _chainInfo() {
    return this._chainProvider.currentChain;
  }

  get nativeTicker() {
    return this._chainInfo?.native ?? "Native";
  }

  private get _info() {
    return this._deployerInfo.info;
  }

  get deployDisabled() {
    const { hash, status } = this._info;
    if (hash && status.toLowerCase() === "pending") {
      return true;
    }
    return false;
  }

  private get _balancesHelperContract() {
    const chainInfo = this._chainProvider.currentChain;
    if (!chainInfo) return null;
    return getBalanceHelperContract(chainInfo);
  }

  private _setDeployPrice = (price: number) => {
    this._deployPrice = price;
  };

  get deployerPrice() {
    const price = this._deployPrice;
    const fractionDigits = calcRoundingValue(price);
    const roundedPrice = +price.toFixed(fractionDigits);
    return roundedPrice;
  }

  get chainInfo() {
    return this._chainProvider.currentChain;
  }

  private _setWalletAddress = (address: string) => {
    this._wallet.address = address;
  };

  private _setWalletBalance = (balance: number) => {
    this._wallet.balance = balance;
  };

  get loading() {
    return this._loading;
  }

  _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get priceLoading() {
    return this._priceLoading;
  }

  private _setPriceLoading = (loading: boolean) => {
    this._priceLoading = loading;
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  getDeployer = async () => {
    this._setLoading(true);
    try {
      await Promise.all([this._getDeployerInfo(), this._getDeployer()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  _getDeployerInfo = async () => {
    await this._deployerInfo.getDeployerInfo(this._botUUID);
  };

  private _getDeployer = async () => {
    const isError = await this._getDeployPrice();
    if (!isError) {
      await this._getDeployerBalance();
    }
  };

  private _getDeployPrice = async () => {
    this._setPriceLoading(true);
    try {
      const { isError, data } = await getDeployInfo(this._botUUID);

      if (!isError) {
        const deployPrice = deployerInfoRespToPrice(data);
        this._setDeployPrice(deployPrice);
        const deployerAddress = data.address;
        this._setWalletAddress(deployerAddress);
      }
      return isError;
    } finally {
      this._setPriceLoading(false);
    }
  };

  refreshDeployPrice = async () => {
    this._setPriceLoading(true);

    try {
      const { isError, data } = await getDeployInfo(this._botUUID);

      if (!isError) {
        const deployPrice = deployerInfoRespToPrice(data);
        this._setDeployPrice(deployPrice);
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setPriceLoading(false);
    }
  };

  private _getDeployerBalance = async () => {
    const { isError, data } = await getBalances(this._botUUID, {
      deployer_id: this._botUUID,
    });
    if (!isError) {
      const balance = balancesRespToDeployerBalance(data);
      this._setWalletBalance(balance);
    }
    return isError;
  };

  refreshDeployerBalance = async () => {
    this._setLoading(true);

    try {
      await this._getDeployerBalance();
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  refreshChainDeployerBalance = async () => {
    this._setLoading(true);

    try {
      const { address } = this._wallet;
      const chainBalances = await this._fetchChainBalances(address);
      if (chainBalances.length > 0) {
        const balance = chainBalances[0];
        this._setWalletBalance(balance);
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  _fetchChainBalances = async (address: string) => {
    const contract = this._balancesHelperContract;
    if (!contract) return [];

    try {
      const data = await getBalanceNative(contract, [address]);

      const balances = bigNumbersToNumbers(data);
      return balances;
    } catch (err) {
      chainErrorHandler(err);
      return [];
    }
  };

  deployProfile = async (nextStep: TabStepNavigationAction) => {
    this._setLoading(true);

    try {
      const { isError } = await deployProfile(this._botUUID);

      if (!isError) {
        nextStep(this._botUUID);
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
