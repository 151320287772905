import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { UserGroupsContext } from "src/context/UserManager/UserGroups";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { UserGroup } from "src/modules/userManager";
import useAppState from "src/state";
import WindowConsent from "src/state/WindowConsent";
import { SelectItem } from "../../shared";
import { Filter } from "../Filter";
import { List } from "../List";
import { AddUserGroup } from "./AddUserGroup";
import { EditUserGroup } from "./EditUserGroup";

export const UserGroupsList = observer(() => {
  const { UserManagerState: state } = useAppState();

  const {
    userGroupsState: { addUserGroupState, editUserGroupState },
  } = useLateInitContext(UserGroupsContext);

  const openEditUserGroup = useCallback(
    (group: UserGroup) => {
      editUserGroupState.openModal(group.name);
    },
    [editUserGroupState]
  );

  const onRemoveUserGroup = useCallback(
    (groupName: string) => {
      WindowConsent.showWindow(
        "",
        `Are you sure you want to delete the user group: ${groupName}?`,
        state.removeUserGroup,
        groupName
      );
    },
    [state.removeUserGroup]
  );

  const columns: any = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        disableSortBy: true,

        Cell: ({
          cell: {
            row: { original: group },
          },
        }: any) => <SelectItem onClick={() => openEditUserGroup(group)}>{group.name}</SelectItem>,
      },
      {
        Header: "",
        accessor: "users",
        disableSortBy: true,
        Cell: ({ value }: { value: string[] }) => value.length,
      },
      {
        Header: "",
        accessor: "cancel",
        disableSortBy: true,
        Cell: ({
          cell: {
            row: { original: group },
          },
        }: any) => <DeleteButton onClick={() => onRemoveUserGroup(group.name)} />,
      },
    ],
    [onRemoveUserGroup, openEditUserGroup]
  );

  return (
    <>
      <List
        style={{ gridArea: "groups" }}
        label="User groups"
        btnLabel="add user group +"
        openModal={addUserGroupState.openModal}
        data={state.userGroups}
        columns={columns}
        filter={<Filter value={state.userGroupsFilter} onChange={state.setUserGroupsFilter} />}
        loader={state.isLoadingUserGroups}
      />

      <AddUserGroup />
      <EditUserGroup />
    </>
  );
});
