import { observer } from "mobx-react-lite";
import React from "react";
import useAppState from "src/state";
import { Spectr } from "./Spectr";
import * as styles from "./style";

export const TimeLine = observer(({ botInfo, startPoint, endPoint }) => {
  const { SpectrState: state } = useAppState();

  return (
    <styles.TimeLineContainer>
      <styles.StatusLine>
        <styles.CurrentTime>{state.trackedTime}</styles.CurrentTime>
        <styles.Status>
          Status:
          <styles.StatusInd id="statusInd" value={state.trackedStatus} />
        </styles.Status>
      </styles.StatusLine>
      <Spectr botInfo={botInfo} startPoint={startPoint} endPoint={endPoint} />
    </styles.TimeLineContainer>
  );
});
