import styled from "styled-components";
import { Card } from "../../shared/Card";
import { ChartCardPaddingFix } from "../../shared/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LiquidityCard = styled(Card)`
  ${ChartCardPaddingFix}
`;
