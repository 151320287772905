import { makeAutoObservable } from "mobx";
import { Nullish, isDefined } from "src/helpers/utils";
import { DEXV2CounterStrategy } from "src/state/DEXV2/DEXV2Settings";

export interface ICounterStrategiesProvider {
  get counterStrategies(): DEXV2CounterStrategy[];
  setCounterStrategies: (strategies: DEXV2CounterStrategy[]) => void;
  saveCounterStrategies: () => Promise<boolean>;
}

export interface ICounterStrategies {
  updateCounterStrategy: (strategy: DEXV2CounterStrategy) => Promise<boolean>;
  deleteCounterStrategy: (index: number) => Promise<boolean>;
  toggleActiveCounterStrategy: (index: number) => Promise<boolean>;
  setSelectedCounterStrategy: (index: Nullish<number>) => void;
  get selectedCounterStrategy(): DEXV2CounterStrategy | null;
}

export default class CounterStrategiesStore implements ICounterStrategies {
  private _strategiesProvider: ICounterStrategiesProvider;

  private _selectedStrategyIndex: Nullish<number> = null;

  constructor(strategiesProvider: ICounterStrategiesProvider) {
    makeAutoObservable(this);

    this._strategiesProvider = strategiesProvider;
  }

  private get _counterStrategies() {
    return this._strategiesProvider.counterStrategies;
  }

  private _setCounterStrategies = (strategies: DEXV2CounterStrategy[]) => {
    this._strategiesProvider.setCounterStrategies(strategies);
  };

  private _saveCounterStrategies = async () =>
    await this._strategiesProvider.saveCounterStrategies();

  private _getCounterStrategy = (index: Nullish<number>): DEXV2CounterStrategy | null => {
    if (!isDefined(index)) return null;
    const strategies = this._counterStrategies;
    return strategies[index] ?? null;
  };

  private _addCounterStrategy = (strategy: DEXV2CounterStrategy) => {
    const newStrategies = [...this._counterStrategies, strategy];
    this._setCounterStrategies(newStrategies);
  };

  private _updateCounterStrategy = (strategy: DEXV2CounterStrategy, index: number) => {
    const newStrategies = this._counterStrategies.map((currentStrategy, i) =>
      i === index ? strategy : currentStrategy
    );
    this._setCounterStrategies(newStrategies);
  };

  private _deleteCounterStrategy = (index: number) => {
    const newStrategies = this._counterStrategies.filter((_, i) => i !== index);
    this._setCounterStrategies(newStrategies);
  };

  private _updateCounterStrategies = (strategy?: DEXV2CounterStrategy, index?: Nullish<number>) => {
    if (isDefined(index)) {
      if (strategy) {
        this._updateCounterStrategy(strategy, index);
      } else {
        this._deleteCounterStrategy(index);
      }
    } else if (strategy) {
      this._addCounterStrategy(strategy);
    }
  };

  updateCounterStrategy = async (strategy: DEXV2CounterStrategy) => {
    const strategyIndex = this._selectedStrategyIndex;
    this._updateCounterStrategies(strategy, strategyIndex);

    return await this._saveCounterStrategies();
  };

  private _toggleActiveCounterStrategy = (index: Nullish<number>) => {
    const currentStrategy = this._getCounterStrategy(index);
    if (!currentStrategy || !isDefined(index)) return;

    const newStrategy = { ...currentStrategy, status: !currentStrategy.status };
    this._updateCounterStrategy(newStrategy, index);
  };

  toggleActiveCounterStrategy = async (index: number) => {
    this._toggleActiveCounterStrategy(index);

    return await this._saveCounterStrategies();
  };

  deleteCounterStrategy = async (index: number) => {
    this._updateCounterStrategies(undefined, index);

    return await this._saveCounterStrategies();
  };

  setSelectedCounterStrategy = (index: Nullish<number>) => {
    this._selectedStrategyIndex = index;
  };

  get selectedCounterStrategy() {
    const selectedIndex = this._selectedStrategyIndex;
    const strategy = this._getCounterStrategy(selectedIndex);
    return strategy;
  }
}
