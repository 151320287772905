import { ComponentPropsWithoutRef } from "react";
import { ChooseSettingParamsProvider } from "src/context/CEX/ExpertSystem/modules";
import { ModuleSoftParamsShell } from "../ModuleSoftParamsShell";
import { SoftParamsFormsProps } from "../ModuleSoftParamsShell/types";
import { ChooseParams } from "./ChooseParams";
import * as styles from "./style";

export interface ChangeSettingsPanelProps extends ComponentPropsWithoutRef<"div"> {
  shellProps: SoftParamsFormsProps;
  error: string | undefined;
}

export const ChangeSettingsPanel = ({ shellProps, error, children }: ChangeSettingsPanelProps) => (
  <ChooseSettingParamsProvider>
    <ModuleSoftParamsShell {...shellProps}>
      <styles.Container error={!error}>
        <styles.Header>
          <ChooseParams />
        </styles.Header>

        <styles.Content>{children}</styles.Content>
      </styles.Container>
    </ModuleSoftParamsShell>
  </ChooseSettingParamsProvider>
);
