import { Token } from "@uniswap/sdk-core";
import { BigNumber, providers } from "ethers";
import { invariant } from "mobx-utils";
import { IUniswapV3Pool__factory } from "src/contracts/factories/uniswap/v3/IUniswapV3Pool__factory";
import { IUniswapV3Pool } from "src/contracts/uniswap/v3/IUniswapV3Pool";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logDev } from "src/helpers/network/logger";
import { ICache, MapCache } from "src/state/shared/Cache";
import { CacheOptions, validatePoolsPath } from "../../../../utils";
import { V3Pool } from "../entities/V3Pool";
import { V3Route } from "../entities/V3Route";

type Slot0 = Omit<Awaited<ReturnType<IUniswapV3Pool["slot0"]>>, keyof [] | `${number}`>;

type V3PoolInfo = {
  token0: string;
  token1: string;
  fee: number;
  liquidity: BigNumber;
  slot0: Slot0;
};

export interface IV3RoutesProvider {
  getRoute: (
    path: Token[],
    pools: string[],
    options?: CacheOptions
  ) => Promise<V3Route<Token, Token> | null>;
}

export interface IV3RoutesProviderParams {
  chainId: number;
  provider: providers.JsonRpcProvider;
  poolsCache: ICache<V3Pool>;
}

export class V3RoutesProvider implements IV3RoutesProvider {
  private _provider: providers.JsonRpcProvider;

  private _chainId: number;

  private _poolsContractsCache: ICache<IUniswapV3Pool>;

  private _poolsCache: ICache<V3Pool>;

  constructor({ provider, chainId, poolsCache }: IV3RoutesProviderParams) {
    this._provider = provider;

    this._chainId = chainId;

    this._poolsContractsCache = new MapCache();

    this._poolsCache = poolsCache;
  }

  private _getContractPoolCacheKey = (chainId: number, pairAddress: string) =>
    `pool-${chainId}-${pairAddress}`;

  private _getPoolCacheKey = (chainId: number, pairAddress: string) =>
    this._getContractPoolCacheKey(chainId, pairAddress);

  private _getPoolContract = (pairAddress: string) => {
    const provider = this._provider;

    if (!provider || !pairAddress) {
      return null;
    }

    const contract = IUniswapV3Pool__factory.connect(pairAddress, provider);
    return contract;
  };

  private _getCachedPoolContract = (pairAddress: string) => {
    const pairKey = this._getContractPoolCacheKey(this._chainId, pairAddress);

    const cachedContract = this._poolsContractsCache.get(pairKey);
    if (cachedContract) {
      return cachedContract;
    }

    const contract = this._getPoolContract(pairAddress);
    if (contract) {
      this._poolsContractsCache.set(pairKey, contract);
    }
    return contract;
  };

  private _getPoolInfo = async (poolContract: IUniswapV3Pool): Promise<V3PoolInfo | undefined> => {
    try {
      const [token0, token1, fee, liquidity, slot0] = await Promise.all([
        poolContract.token0(),
        poolContract.token1(),
        poolContract.fee(),
        poolContract.liquidity(),
        poolContract.slot0(),
      ]);
      return {
        token0,
        token1,
        fee,
        liquidity,
        slot0,
      };
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  private _getPool = async (contract: IUniswapV3Pool, token0: Token, token1: Token) => {
    const info = await this._getPoolInfo(contract);
    if (!info) {
      return;
    }
    const { token0: token0Address, token1: token1Address, slot0, fee, liquidity } = info;

    invariant(
      token0Address === token0.address && token1Address === token1.address,
      "Pool tokens addresses mismatch"
    );

    logDev(["_getPool", "tokens", token0, token1, info]);

    return new V3Pool(
      token0,
      token1,
      fee,
      slot0.sqrtPriceX96.toString(),
      liquidity.toString(),
      slot0.tick
    );
  };

  private _getCachedPool = async (
    poolContract: IUniswapV3Pool,
    token0: Token,
    token1: Token,
    useCache: boolean
  ) => {
    const poolAddress = poolContract.address;

    const pairKey = this._getPoolCacheKey(this._chainId, poolAddress);

    if (useCache) {
      const cachedPool = this._poolsCache.get(pairKey);
      if (cachedPool) {
        return cachedPool;
      }
    }

    const pool = await this._getPool(poolContract, token0, token1);
    if (pool) {
      this._poolsCache.set(pairKey, pool);
    }

    return pool;
  };

  private _getPoolPair = async (
    tokenA: Token,
    tokenB: Token,
    poolAddress: string,
    useCache = true
  ) => {
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];

    const contract = this._getCachedPoolContract(poolAddress);
    if (!contract) return;

    const pair = await this._getCachedPool(contract, token0, token1, useCache);
    return pair;
  };

  getRoute = async (path: Token[], poolsAddresses: string[], options: CacheOptions = {}) => {
    validatePoolsPath(path, poolsAddresses);

    const pools: V3Pool[] = [];

    for (let i = 0; i < path.length - 1; i += 1) {
      const token0 = path[i];
      const token1 = path[i + 1];
      const poolAddress = poolsAddresses[i];
      // eslint-disable-next-line no-await-in-loop
      const pool = await this._getPoolPair(token0, token1, poolAddress, options?.useCache);
      if (!pool) {
        continue;
      }
      pools.push(pool);
    }

    // pairs count should be tokens count - 1;
    if (pools.length !== path.length - 1) {
      return null;
    }

    const tokenIn = path[0];
    const tokenOut = path[path.length - 1];

    return new V3Route(pools, tokenIn, tokenOut);
  };
}
