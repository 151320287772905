import { observer } from "mobx-react-lite";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { CardProps } from "../shared/Card";
import { SantimentCard } from "./SantimentCard";

export const SantimentOverview = observer((props: CardProps) => {
  const { party, selectedRange } = useLateInitContext(DashboardContext.Context);

  return (
    <SantimentCard party={party} range={selectedRange} title="Santiment overview" {...props} />
  );
});
