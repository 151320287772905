import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import AbilitiesStore from "src/state/DEXV2/shared/AbilitiesStore";

export const AbilitiesContext = createLateInitContext<AbilitiesStore>();

type AbilitiesProviderProps = ChildrenProps;

export const AbilitiesProvider = ({ children }: AbilitiesProviderProps) => {
  const { userState } = useAppState();

  const botsStore = useLocalStore(AbilitiesStore, {
    abilitiesProvider: userState,
  });

  return <AbilitiesContext.Provider value={botsStore}>{children}</AbilitiesContext.Provider>;
};
