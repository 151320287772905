import { observer } from "mobx-react-lite";
import { useStatsStore } from "../../hooks/useStatsStore";
import { CardProps } from "../../shared/Card";
import { useLiquidityData } from "../BuyLiquidityChart";
import { LiquidityBar, LiquidityBarProps } from "../shared/LiquidityBar";
import { LiquidityCard } from "../shared/style";

const SELL_LIQUIDITY_INFO_TEXT = `Bar charts show organic and Gotbit shares of liquidity in the orderbook for sell side orders.
Yellow line displays the average price of the ticker in USD.
`;

export interface SellLiquidityChartProps
  extends Pick<LiquidityBarProps, "zoomDefault">,
    CardProps {}

export const SellLiquidityChart = observer(({ zoomDefault, ...props }: SellLiquidityChartProps) => {
  const { time, sell, price, loading, priceRange } = useStatsStore("liquidity");

  const data = useLiquidityData({ time, data: sell, price });
  const barProps = { data, zoomDefault };
  return (
    <LiquidityCard
      title="Sell liquidity"
      info={SELL_LIQUIDITY_INFO_TEXT}
      loading={loading}
      {...props}
    >
      <LiquidityBar priceRange={priceRange} {...barProps} />
    </LiquidityCard>
  );
});
