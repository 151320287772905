import { rgba } from "polished";
import { Tooltip } from "react-tooltip";
import styled, { css } from "styled-components";
import { Headline1, Headline2 } from "./Typography";

interface LabelProps {
  error: boolean;
}

interface PriceProps {
  side: "buy" | "sell" | "";
}

export const ErrorMsg = styled(Tooltip)`
  width: max-content;
  padding: 5px 13px !important;

  font-size: ${({ theme }) => theme.fontSizes["9px"]} !important;
  color: #d84e4e !important;
  background: #f5e1e1 !important;
  opacity: 1 !important;
  white-space: pre-wrap;

  :after {
    bottom: -7px !important;
    left: -7px !important;
    border-right-color: #d84e4e0f !important;
  }
`;

export const PromptMsg = styled(ErrorMsg)`
  max-width: 220px;
  color: ${({ theme }) => theme.primaryColor} !important;
  background: ${({ theme }) => theme.promptMsgBg} !important;

  :after {
    display: none;
    bottom: -7px !important;
    left: -7px !important;
    border-right-color: ${({ theme }) => rgba(theme.primaryColor, 0.06)} !important;
  }
`;

export const Label = styled.h5<LabelProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  /* min-height: 16px; */
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme, error }) => (error ? theme.errorLabelColor : theme.smallHeader)};
  margin: 0 0 6px 0;
`;

export const CardSurface = css`
  border-radius: ${({ theme }) => theme.radii["4px"]};
  box-shadow: ${({ theme }) => theme.shadows.dashboard};
  background: ${({ theme }) => theme.bgSettingsPnl};
`;

export const PanelContainer = styled.div`
  position: relative;

  width: 100%;

  ${CardSurface}
`;

export const Container = styled.div`
  height: max-content;
  width: 100%;
  max-width: 1700px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const MainLabel = styled(Headline1)``;

export const TitleSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["13px"]};
  color: ${({ theme }) => theme.darkGray};
`;

export const PanelTitle = styled(Headline2)``;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;

  background: rgba(46, 46, 46, 0.3);
`;

export const TextLineHeight = css`
  ::before {
    content: "\u200b"; /* unicode zero width space character */
  }
`;

export const EmptyTextLineHeight = css`
  &:empty {
    ${TextLineHeight}
  }
`;

export const ScrollSize = css`
  ::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
`;

// Safari utility to fix scrolling z-index order issues
export const EmptyTransform = css`
  -webkit-transform: translate3d(0, 0, 0);
`;

export const Price = styled.span<PriceProps>`
  color: ${({ theme, side }) => {
    switch (side) {
      case "buy":
        return theme.successColor;
      case "sell":
        return theme.dangerColor;

      default:
        return theme.textColor;
    }
  }};
  text-align: center;
`;

export const Subtitle = styled(Headline2)`
  color: ${({ theme }) => theme.lightGray};

  ${EmptyTextLineHeight}
`;

export const CheckMark = css`
  :after {
    position: absolute;

    left: 38%;
    top: 28%;

    content: "";

    width: 4px;
    height: 6px;

    border-bottom: solid;
    border-right: solid;
    border-width: 2px;
    border-color: #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.5s;
  }
`;

export const EmptyListText = styled.p`
  margin: 0;
  padding: 0;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.smallHeader};
`;
