import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { GetPNLRequestParams, GetPNLResponse, getPNL } from "src/api/bots/DEXV2/stats";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { PNLProps } from "src/components/shared/PNL";
import { getUTCRangeFromDuration } from "src/helpers/dateUtils";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { roundNumber, roundSingleValue } from "src/helpers/rounding";
import { Disposable } from "src/helpers/utils";
import { IBotProvider } from "../../DEXV2Bots/DEXV2BotStore";

interface PNLData extends GetPNLResponse {}

const INITIAL_PNL_DATA: PNLData = {
  base: 0,
  native: 0,
  price: 0,
  quote: 0,
};

export interface PNLInfo
  extends Required<
    Pick<PNLProps, "token" | "asset" | "deltaToken" | "deltaTotal" | "price" | "fees">
  > {}

export default class PNLStore implements Disposable {
  private _botUUID = "";

  private _loading = false;

  private _pnl: PNLData = INITIAL_PNL_DATA;

  private _dateRange: DateTimeRange = getUTCRangeFromDuration({ hours: 12 });

  private _botProvider: IBotProvider;

  private _rangeChangedReaction: IReactionDisposer;

  constructor(botProvider: IBotProvider) {
    this._botProvider = botProvider;

    makeAutoObservable(this);

    makeLoggable<any>(this, {
      pnlInfo: true,
      _currentDateRange: true,
      _range: true,
      _pnlParams: true,
    });

    this._rangeChangedReaction = reaction(
      () => this._dateRange,
      () => {
        this.getPNL();
      }
    );
  }

  private _setPNL = (pnl: PNLData) => {
    this._pnl = pnl;
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private get _bot() {
    return this._botProvider.bot;
  }

  private get _base() {
    const { base } = this._bot;
    return base || "BASE";
  }

  private get _quote() {
    const { quote } = this._bot;

    return quote || "QUOTE";
  }

  private get _deltaToken() {
    const { base } = this._pnl;
    return roundSingleValue(base).toString();
  }

  private get _deltaTotal() {
    const { quote } = this._pnl;
    return roundSingleValue(quote).toString();
  }

  private get _fees() {
    const { native } = this._pnl;
    return roundSingleValue(native).toString();
  }

  private get _price() {
    const deltaToken = +this._deltaToken;
    const deltaTotal = +this._deltaTotal;
    const roundedDeltaToken = roundNumber(deltaToken);
    const roundedDeltaTotal = roundNumber(deltaTotal);
    if (
      (deltaTotal >= 0 && deltaToken >= 0) ||
      (deltaTotal >= 0 && roundedDeltaToken === 0) ||
      (roundedDeltaTotal === 0 && deltaToken >= 0)
    ) {
      return "NET PROFIT";
    }
    if (
      (deltaTotal < 0 && deltaToken < 0) ||
      (deltaTotal < 0 && roundedDeltaToken === 0) ||
      (roundedDeltaTotal === 0 && deltaToken < 0)
    ) {
      return "NET LOSS";
    }
    const deltaRatio = +Math.abs(deltaTotal / deltaToken);
    const roundedDeltaRatio = roundNumber(deltaRatio);
    return roundedDeltaRatio;
  }

  get pnlInfo(): PNLInfo {
    return {
      token: this._base,
      asset: this._quote,
      deltaToken: this._deltaToken,
      deltaTotal: this._deltaTotal,
      price: this._price,
      fees: this._fees,
    };
  }

  setDateRange = (range: DateTimeRange) => {
    this._dateRange = range;
  };

  get dateRange() {
    return this._dateRange;
  }

  private get _pnlParams(): GetPNLRequestParams | undefined {
    const currentRange = this._dateRange;
    const [start, end] = currentRange;
    if (!start || !end) return undefined;
    return { start: `${start.unix()}`, end: `${end.unix()}` };
  }

  getPNL = async () => {
    this._setLoading(true);

    try {
      const { isError, data } = await getPNL(this._botUUID, this._pnlParams);
      if (!isError) {
        this._setPNL(data);
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._rangeChangedReaction();
  };
}
