import { roundToTwoDecimals } from "../rounding";
import { numFormatter } from "../separation";
import { Nullish } from "../utils";
import { filterBoolean } from "../utils/filterBoolean";

export const strFormatter = (
  text: string,
  start: number | undefined = 5,
  end: number | undefined = 4,
  separator: string | undefined = "..."
) => text.slice(0, start) + separator + text.slice(text.length - end, text.length);

/**
 *
 * @param data - convertible data
 * @returns json
 */
export const toStringifyJSON = (data: any) => JSON.stringify(data);

/**
 *
 * @param json - string with data in json format
 * @returns parsed data
 */
export const toParseJSON = <T>(json: string) => JSON.parse(json) as T;

export const joinStrings = (arr: Array<Nullish<string>>, separator = "\n") => {
  const filteredArray = filterBoolean(arr);

  return filteredArray.join(separator);
};

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const formatWithSign = (rawValue: string | number, value: string) => {
  if (Math.sign(+rawValue) > 0) {
    return `+${value}`;
  }
  return `${value}`;
};

export const formatFiat = (
  value: Nullish<number | string>,
  showSymbol = true,
  showSign = false
) => {
  const roundedValue = isDefined(value) ? roundToTwoDecimals(+value) : "-";

  const formattedValue = numFormatter(roundedValue);

  const valueWithSign = showSign ? formatWithSign(roundedValue, formattedValue) : formattedValue;

  return showSymbol ? `${valueWithSign} $` : valueWithSign;
};

export const formatPercent = (
  value: Nullish<number | string>,
  showSymbol = true,
  showSign = false
) => {
  const roundedValue = isDefined(value) ? roundToTwoDecimals(+value) : "-";

  const valueWithSign = showSign ? formatWithSign(roundedValue, `${roundedValue}`) : roundedValue;

  return showSymbol ? `${valueWithSign}%` : `${valueWithSign}`;
};

export const formatDecimalPercent = (
  value: Nullish<number | string>,
  showSymbol = true,
  showSign = false
) => {
  const percentage = value && +value * 100;

  return formatPercent(percentage, showSymbol, showSign);
};

export const formatPNLWithSign = (value: number, type: "fiat" | "percent", showSymbol = true) => {
  const formattedValue =
    type === "fiat" ? formatFiat(value, showSymbol, true) : formatPercent(value, showSymbol, true);
  return formattedValue;
};
