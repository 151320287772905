import { makeAutoObservable } from "mobx";
import { BotsType } from "src/modules/bots";

const STORAGE_KEY = "currentTab";

class AllBotsState {
  private _realtimeEnabled = true;

  private _currentTab: BotsType = (localStorage.getItem(STORAGE_KEY) as BotsType) || "CEX";

  constructor() {
    makeAutoObservable(this);
  }

  get realtimeEnabled() {
    return this._realtimeEnabled;
  }

  switchRealtime = () => {
    this._realtimeEnabled = !this._realtimeEnabled;
  };

  get currentTab() {
    return this._currentTab;
  }

  switchCurrentTab = (value: BotsType) => {
    this._currentTab = value;
    localStorage.setItem(STORAGE_KEY, this.currentTab);
  };
}

export default AllBotsState;
