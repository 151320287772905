import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { Disposable } from "src/helpers/utils";

const MOCK_EXCHANGES = [
  "Binance",
  "Coinbase",
  "Kraken",
  "Bitfinex",
  "Bitstamp",
  "Bittrex",
  "Poloniex",
  "Gemini",
  "Kucoin",
  "Huobi",
];

export type AccountBalance = {
  total?: number;
  free?: number;
  locked?: number;
};

export type AccountBalances = {
  exchange: string;
  accountName: string;
  totalUSD: AccountBalance;
  baseAmount: AccountBalance;
  quoteAmount: AccountBalance;
  otherAmount: AccountBalance;
};

const generateBalance = () => faker.number.float({ precision: 0.0001 });

export const generateRandomBoolean = () => Math.random() < 0.5;

export const generateRandomBooleanProb = (prob: number) => Math.random() < prob;

const generateBalanceOrNull = () => (generateRandomBoolean() ? generateBalance() : undefined);
const generateBalances = (): AccountBalance => ({
  total: generateBalanceOrNull(),
  free: generateBalanceOrNull(),
  locked: generateBalanceOrNull(),
});

export const generateAccountName = () => faker.finance.accountName().replace(/Account/g, "");

export const generateExchange = () => faker.helpers.arrayElement(MOCK_EXCHANGES);

const generateAccountBalance = (): AccountBalances => {
  const exchange = generateExchange();
  const accountName = generateAccountName();
  return {
    exchange,
    accountName,
    totalUSD: generateBalances(),
    baseAmount: generateBalances(),
    quoteAmount: generateBalances(),
    otherAmount: generateBalances(),
  };
};

const generateAccountBalances = (): AccountBalances[] => {
  const accountBalances: AccountBalances[] = faker.helpers.multiple(generateAccountBalance, {
    count: { min: 5, max: 10 },
  });
  return accountBalances;
};

export type AccountBalancesKeys = keyof AccountBalances;

export const BALANCES_COLUMNS = ["baseAmount", "otherAmount", "quoteAmount", "totalUSD"] as const;

export type BalancesColumnsKeys = (typeof BALANCES_COLUMNS)[number];

export interface IAccountBalanceProvider {
  getAccountBalance: (currencyKey: BalancesColumnsKeys, index: number) => AccountBalance | null;
}

export default class AccountBalancesStore implements Disposable {
  private _data: AccountBalances[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  private _setData = (data: AccountBalances[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  getAccountBalances = () => {
    try {
      const accountBalances = generateAccountBalances();
      this._setData(accountBalances);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
