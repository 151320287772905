import { formatFiat } from "src/helpers/string";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import { KPICard, KPICardProps } from "../../shared/KPICard";

export interface BalancesCardProps extends Omit<KPICardProps, "title" | "kpi"> {
  free: string;
  locked: string;
}

const BALANCES_INFO_TEXT = `Locked balance is the balance that is used to trade and provide liquidity.
Free balance is the balance that is not used in current active strategies (opposite to locked balance) and can be withdrawn at the moment. 
`;

export const BalancesCard = ({ free, locked, ...props }: BalancesCardProps) => {
  const kpi = `${formatFiat(free)} / ${formatFiat(locked)}`;
  return (
    <KPICard
      titleIcon={<CEXDashboardIcons.Wallet />}
      title="Current free/locked balance:"
      info={BALANCES_INFO_TEXT}
      kpi={kpi}
      {...props}
    />
  );
};
