import styled from "styled-components";
import { StatsTable } from "../shared/StatsTable";
import { Row, TableBody, TableCell } from "../shared/StatsTable/style";

export const Container = styled.div``;

export const StyledAumTable = styled(StatsTable)`
  ${TableBody} {
    ${Row}:last-child ${TableCell}:last-child {
      position: relative;
      padding: 0;

      > * {
        border-left: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
        padding-right: 8px;
        padding-left: 16px;
      }
    }
  }
` as typeof StatsTable;
