import { IChartApi, MouseEventHandler } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { ComponentType, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { SeriesMap, SeriesTitlesMap } from "src/state/Graph/SeriesStore";
import { Tooltip, TooltipProps } from "../Tooltip";

export interface ChartTooltipProps {
  container: HTMLDivElement | null;
  isChartMounted: boolean;
  chart: React.RefObject<IChartApi>;
  seriesTitlesMap: SeriesTitlesMap;
  seriesMap: SeriesMap;
  cursorMargin?: number;
  showTooltip?: boolean;
  tooltip?: ComponentType<TooltipProps>;
}

export const ChartTooltip = observer(
  ({
    chart,
    isChartMounted,
    container,
    seriesTitlesMap,
    seriesMap,
    cursorMargin = 15,
    showTooltip,
    tooltip: tooltipSlot,
  }: ChartTooltipProps) => {
    const state = useTooltipContext();

    const tooltip = useRef<HTMLDivElement>(null);

    const TooltipComponent = tooltipSlot ?? Tooltip;

    useEffect(() => {
      if (!showTooltip) return;
      if (!chart.current || !isChartMounted) return;
      const currentChart = chart.current;
      const crossHairMoveCb: MouseEventHandler = (params) => {
        const tooltipEl = tooltip.current;
        if (!tooltipEl) return;
        const containerWidth = container?.clientWidth ?? 0;
        const containerHeight = container?.clientHeight ?? 0;
        if (
          !container ||
          !seriesMap ||
          params.point === undefined ||
          !params.time ||
          params.point.x < 0 ||
          params.point.x > containerWidth ||
          params.point.y < 0 ||
          params.point.y > containerHeight
        ) {
          tooltipEl.style.display = "none";
        } else {
          state.updateTooltipData(params.time, seriesMap, seriesTitlesMap, params.seriesData);

          const { width: tooltipWidth, height: tooltipHeight } = tooltipEl.getBoundingClientRect();

          const currentX = params.point.x;
          const currentY = params.point.y;

          let left = currentX + cursorMargin;
          if (left > containerWidth - tooltipWidth) {
            left = currentX - cursorMargin - tooltipWidth;
          }

          let top = currentY + cursorMargin;
          if (top > containerHeight - tooltipHeight) {
            top = currentY - tooltipHeight - cursorMargin;
          }

          tooltipEl.style.left = `${left}px`;
          tooltipEl.style.top = `${top}px`;
          tooltipEl.style.display = "block";
        }
      };
      currentChart.subscribeCrosshairMove(crossHairMoveCb);

      return () => {
        currentChart.unsubscribeCrosshairMove(crossHairMoveCb);
      };
    }, [
      chart,
      container,
      cursorMargin,
      isChartMounted,
      seriesMap,
      seriesTitlesMap,
      showTooltip,
      state,
    ]);

    return (
      <>
        {showTooltip && container && createPortal(<TooltipComponent ownRef={tooltip} />, container)}
      </>
    );
  }
);
