import { History } from "history";
import { makeAutoObservable, runInAction } from "mobx";
import { logIn } from "src/api/auth";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormValidation,
} from "src/helpers/forms/types";
import { Credentials } from "src/modules/auth";
import { required, validateData } from "src/validation-schemas";
import { pushOrReplaceHistory } from "../../helpers/router";

type CredentialsKeys = FormDataKeys<Credentials>;

class LoginStore {
  data: Credentials = {
    login: "",
    password: "",
  };

  showLoader = false;

  validation: FormValidation<Credentials> = {
    login: required(),
    password: required(),
  };

  errors: FormErrors<Credentials> = {};

  constructor() {
    makeAutoObservable(this);
  }

  private _setLoader = (loading: boolean) => {
    this.showLoader = loading;
  };

  private _setValue = (field: CredentialsKeys, value: string) => {
    this.data[field] = value;
  };

  private _setError = (field: CredentialsKeys, value: string) => {
    this.errors[field] = value;
  };

  private _setToken = (token: string) => {
    sessionStorage.setItem("token", JSON.stringify(token));
  };

  getHandler =
    (field: CredentialsKeys): FormFieldHandler =>
    (e) => {
      this._setValue(field, String(getChangeEventValue(e)));
    };

  getError = (key: CredentialsKeys) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  private _checkErrorMessage = (msg: unknown) => {
    if (msg === "rpc error: code = InvalidArgument desc = wrong password") {
      this._setError("password", "Wrong password");
    } else {
      this._setError("password", "Wrong username or password");
      this._setError("login", "Wrong username or password");
    }
  };

  private _validate = (validateKeys?: string[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  submitHandler = (history: History) => (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this._validate();
    if (valid) {
      this._fetchLogin(history);
    }
  };

  private _fetchLogin = async (history: History) => {
    this._setLoader(true);
    try {
      const { isError, data } = await logIn(this.data);
      if (!isError) {
        pushOrReplaceHistory(history, `/Confirm/${this.data.login}/`);
        this._setToken(data);
      } else {
        this._checkErrorMessage(data);
      }
    } catch ({ message }: any) {
      this._checkErrorMessage(message);
    } finally {
      this._setLoader(false);
    }
  };
}

export default LoginStore;
