import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import { makeAutoObservable } from "mobx";
import {
  DEXV2Vault,
  DEXV2VaultType,
  GetBotVaultsResponse,
  GetVaultsBalancesResponse,
  getBotVaults,
  getVaultBalances,
} from "src/api/bots/DEXV2/create";
import { getBalance, getBalanceHelperContract } from "src/helpers/getBalances";
import { makeLoggable } from "src/helpers/logger";
import { bigNumbersToNumbers } from "src/helpers/math";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValues } from "src/helpers/rounding";
import { Disposable, Mapper, entries } from "src/helpers/utils";
import {
  IBotAddressesProvider,
  IBotChainProvider,
  IBotTradePairProvider,
  IBotUUIDProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import { tryParseCurrencyAmount, zeroCurrencyAmount } from "../../DEXV2Swap/utils";
import { ITradePairPriceProvider, PairPrice } from "../../Providers/TradePairUSDPriceProvider";
import TradePair, { PairAddresses, PairTickers } from "../../shared/TradePair";
import { SetLoading } from "../GasWallets/WithdrawGasStore";

type DEXV2VaultBalance = Pick<DEXV2Vault, "quote" | "base">;

export type VaultQuotes = {
  base: { quote?: CurrencyAmount<Token>; usd?: CurrencyAmount<Token> };
  quote: { usd?: CurrencyAmount<Token> };
};

export type VaultQuotesMap = Partial<Record<string, VaultQuotes>>;

export const vaultsBalancesRespToVaults = (
  balances: GetVaultsBalancesResponse,
  vaults: GetBotVaultsResponse
): DEXV2Vault[] => {
  const vaultTypes = entries(balances)
    .filter(([, vault]) => typeof vault === "object")
    .map(([type]) => type) as Array<DEXV2VaultType>;

  return vaultTypes.map((type) => {
    const addressKey = `${type}_vault` as const;
    return {
      type,
      ...balances[type],
      address: vaults[addressKey],
    };
  });
};

const vaultsRespToVaultsAddresses: Mapper<GetBotVaultsResponse, DEXV2Vault[]> = (vaults) =>
  entries(vaults)
    .filter(([key]) => key.includes("vault"))
    .map(([key, address]) => {
      const type = key.split("_")[0] as DEXV2VaultType;
      return {
        type,
        base: 0,
        quote: 0,
        address,
      };
    });

export const vaultsRespToTickers: Mapper<GetBotVaultsResponse, PairTickers> = ({
  base_ticker,
  quote_ticker,
}) => ({ base: base_ticker, quote: quote_ticker });

export const getBaseQuotes = (
  base: number,
  baseToken: Token,
  { usd: baseUsdPrice, quote: baseQuotePrice }: PairPrice["base"]
): VaultQuotes["base"] => {
  if (base === 0) {
    return {
      quote: zeroCurrencyAmount(baseQuotePrice.quoteCurrency),
      usd: zeroCurrencyAmount(baseUsdPrice.quoteCurrency),
    };
  }

  const baseAmount = tryParseCurrencyAmount(base.toString(), baseToken);

  if (!baseAmount) return {};

  const baseUsdAmount = baseUsdPrice.quote(baseAmount);
  const baseQuoteAmount = baseQuotePrice.quote(baseAmount);
  return { quote: baseQuoteAmount, usd: baseUsdAmount };
};

export const getQuoteQuotes = (
  quote: number,
  quoteToken: Token,
  { usd: quoteUsdPrice }: PairPrice["quote"]
): VaultQuotes["quote"] => {
  if (quote === 0) {
    return { usd: zeroCurrencyAmount(quoteUsdPrice.quoteCurrency) };
  }

  const quoteAmount = tryParseCurrencyAmount(quote.toString(), quoteToken);

  if (!quoteAmount) return {};

  const quoteUsdAmount = quoteUsdPrice.quote(quoteAmount);

  return {
    usd: quoteUsdAmount,
  };
};

const getVaultQuotes = (
  vault: DEXV2Vault,
  tradePair: TradePair,
  tradePairPrice: PairPrice
): VaultQuotes => {
  const baseQuotes = getBaseQuotes(vault.base, tradePair.base, tradePairPrice.base);

  const quoteQuotes = getQuoteQuotes(vault.quote, tradePair.quote, tradePairPrice.quote);

  return { base: baseQuotes, quote: quoteQuotes };
};

export interface IVaultsQuotesProvider {
  get vaultsQuotes(): VaultQuotesMap;
  get isEmptyQuotes(): boolean;
}

const isBotNotApproved = (message: string) => message.toLowerCase() === "bot is not approved";

export interface IVaultsProvider extends IVaultsQuotesProvider, Disposable {
  get vaults(): DEXV2Vault[];
  get roundedVaults(): DEXV2Vault[];
  get tickers(): PairTickers | null;
  get addresses(): PairAddresses | null;
  getVaults: (setLoading?: SetLoading) => Promise<void>;
  refreshBalances: (setLoading?: SetLoading) => Promise<void>;
  refreshChainBalances: (setLoading?: SetLoading) => Promise<void>;
  get vaultsDeployed(): boolean;
}

export interface IVaultsProviderParams {
  chainProvider: IBotChainProvider;
  botUUIDProvider: IBotUUIDProvider;
  addressesProvider: IBotAddressesProvider;
  priceProvider?: {
    tradePairProvider: IBotTradePairProvider;
    tradePairPriceProvider: ITradePairPriceProvider;
  };
}

export default class VaultsProviderStore implements IVaultsProvider {
  private _vaults: DEXV2Vault[] = [];

  private _botChainProvider: IBotChainProvider;

  private _botUUIDProvider: IBotUUIDProvider;

  private _addressesProvider: IBotAddressesProvider;

  private _tradePairProvider?: IBotTradePairProvider;

  private _tradePairPriceProvider?: ITradePairPriceProvider;

  private _vaultsDeployed = true;

  private _tickers: PairTickers | null = null;

  constructor({
    chainProvider,
    botUUIDProvider,
    addressesProvider,
    priceProvider,
  }: IVaultsProviderParams) {
    this._botChainProvider = chainProvider;
    this._botUUIDProvider = botUUIDProvider;
    this._addressesProvider = addressesProvider;
    this._tradePairProvider = priceProvider?.tradePairProvider;
    this._tradePairPriceProvider = priceProvider?.tradePairPriceProvider;

    makeAutoObservable(this);

    makeLoggable(this, {
      vaults: true,
      addresses: true,
      getVaults: () => this._chainProvider.currentChain,
      roundedVaults: () => this._balancesHelperContract,
      vaultsDeployed: true,
    });
  }

  private get _chainProvider() {
    return this._botChainProvider.chainProvider;
  }

  private get _provider() {
    return this._chainProvider.multicallProvider;
  }

  private get _balancesHelperContract() {
    const chainInfo = this._chainProvider.currentChain;
    if (!chainInfo) return null;
    return getBalanceHelperContract(chainInfo, this._provider);
  }

  private get _botUUID() {
    return this._botUUIDProvider.botUUID;
  }

  private _setVaults = (vaults: DEXV2Vault[]) => {
    this._vaults = vaults;
  };

  get vaults() {
    return this._vaults;
  }

  get roundedVaults(): DEXV2Vault[] {
    const vaults = this._vaults;

    const quoteBalances = vaults.map(({ quote }) => quote);
    const baseBalances = vaults.map(({ base }) => base);

    const quoteFractionDigits = calcRoundingValues(quoteBalances);
    const baseFractionDigits = calcRoundingValues(baseBalances);

    return vaults.map(({ quote, base, ...vault }) => ({
      ...vault,
      quote: +quote.toFixed(quoteFractionDigits),
      base: +base.toFixed(baseFractionDigits),
    }));
  }

  private _setVaultsDeployed = (deployed: boolean) => {
    this._vaultsDeployed = deployed;
  };

  get vaultsDeployed() {
    return this._vaultsDeployed;
  }

  private get _pairPrice() {
    return this._tradePairPriceProvider?.pairPrice;
  }

  private get _tradePair() {
    return this._tradePairProvider?.tradePair;
  }

  get vaultsQuotes(): VaultQuotesMap {
    const pairPrice = this._pairPrice;
    const tradePair = this._tradePair;
    const { vaults } = this;
    if (!pairPrice || !vaults.length || !tradePair) return {};

    const quotesMap = vaults.reduce((curQuotesMap, vault) => {
      const quotes = getVaultQuotes(vault, tradePair, pairPrice);

      // eslint-disable-next-line no-param-reassign
      curQuotesMap[vault.address] = quotes;

      return curQuotesMap;
    }, {} as VaultQuotesMap);

    return quotesMap;
  }

  get isEmptyQuotes() {
    const quotes = this.vaultsQuotes;
    return !quotes || Object.values(quotes).length === 0;
  }

  private _setTickers = (tickers: PairTickers) => {
    this._tickers = tickers;
  };

  get tickers() {
    return this._tickers;
  }

  get addresses() {
    return this._addressesProvider.addresses;
  }

  private get _vaultsAddresses() {
    return this._vaults.map(({ address }) => address);
  }

  private _getPairPrice = async () => {
    await this._tradePairPriceProvider?.getTradePairPrice({
      waitTimeout: 3000,
    });
  };

  getVaults = async (setLoading?: SetLoading) => {
    try {
      await Promise.all([this._getVaultBalances(setLoading), this._getPairPrice()]);
    } catch (err) {
      logError(err);
    }
  };

  private _getVaultBalances = async (setLoading?: SetLoading) => {
    await this._refreshVaultsBalances(this._refreshBalances, setLoading);
  };

  refreshBalances = async (setLoading?: SetLoading) => {
    try {
      await Promise.all([this._getVaultBalances(setLoading), this._refreshPairPrice()]);
    } catch (err) {
      logError(err);
    }
  };

  private _refreshPairPrice = async () => {
    await this._tradePairPriceProvider?.getTradePairPrice();
  };

  private _refreshVaultsBalances = async (
    refreshBalances: () => Promise<any>,
    setLoading?: SetLoading
  ) => {
    setLoading?.(true);
    try {
      const isVaultsError = await this._refreshVaults();
      if (!isVaultsError) {
        await refreshBalances();
      }
    } finally {
      setLoading?.(false);
    }
  };

  private _refreshVaults = async () => {
    if (this._vaultsAddresses.length) return false;
    const { isError, data } = await getBotVaults(this._botUUID);
    if (!isError) {
      const vaultAddresses = vaultsRespToVaultsAddresses(data);
      this._setVaults(vaultAddresses);

      if (!this.tickers) {
        const tickers = vaultsRespToTickers(data);
        this._setTickers(tickers);
      }

      this._setVaultsDeployed(true);
    } else if (isBotNotApproved(data)) {
      this._setVaultsDeployed(false);
    }
    return isError;
  };

  private _setBalances = (balancesResp: GetVaultsBalancesResponse) => {
    this._vaults = this._vaults.map((vault) => ({
      ...vault,
      ...balancesResp[vault.type],
    }));
  };

  private _refreshBalances = async () => {
    const { isError, data } = await getVaultBalances(this._botUUID);
    if (!isError) {
      this._setBalances(data);
    }
  };

  refreshChainBalances = async (setLoading?: SetLoading) => {
    try {
      await Promise.all([
        this._refreshVaultsBalances(this._refreshChainBalances, setLoading),
        this._refreshPairPrice(),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  private _refreshChainBalances = async () => {
    const baseAddress = this.addresses?.base;
    const quoteAddress = this.addresses?.quote;
    if (!baseAddress || !quoteAddress) return;

    try {
      const [quoteBalances, baseBalances] = await Promise.all([
        this._fetchChainBalances(quoteAddress, this._vaultsAddresses),
        this._fetchChainBalances(baseAddress, this._vaultsAddresses),
      ]);

      const balances: DEXV2VaultBalance[] = quoteBalances.map((quoteBalance, index) => ({
        quote: quoteBalance,
        base: baseBalances[index],
      }));
      this._setVaultBalances(balances);
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  private _setVaultBalances = (balances: DEXV2VaultBalance[]) => {
    if (this._vaults.length !== balances.length) return;
    this._vaults = this._vaults.map((vault, index) => ({
      ...vault,
      ...balances[index],
    }));
  };

  private _fetchChainBalances = async (token: string, addresses: string[]) => {
    const contract = this._balancesHelperContract;
    if (!contract) return [];
    try {
      const data = (await getBalance(contract, token, addresses, "")) as BigNumber[];
      const balances = bigNumbersToNumbers(data);
      return balances;
    } catch (err) {
      chainErrorHandler(err);
      return [];
    }
  };

  destroy = () => {};
}
