import { toast } from "react-toastify";
import { showSuccessMsg } from "../message";

/**
 * the method writes the passed value to the clipboard
 * @param text - string to be copied to the clipboard
 */
export const setTextClipboard = async (text: string) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);

      showSuccessMsg("Copied!");
    } catch {
      toast.error("Something went wrong");
    }
  } else fallback(text);
};

const fallback = (text: string) => {
  const isIos = navigator.userAgent.match(/ipad|iphone/i);
  const textarea = document.createElement("textarea");

  // create textarea
  textarea.value = text;

  // ios will zoom in on the input if the font-size is < 16px
  textarea.style.fontSize = "20px";
  document.body.appendChild(textarea);

  // select text
  if (isIos) {
    const range = document.createRange();
    range.selectNodeContents(textarea);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
    textarea.setSelectionRange(0, 999999);
  } else {
    textarea.select();
  }

  // copy selection
  document.execCommand("copy");

  // cleanup
  document.body.removeChild(textarea);

  toast.success("Copied!", {
    autoClose: 2000,
  });
};
