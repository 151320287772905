import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { generateTime, generateTokenTickers } from "./FundingStore";
import { GenerateTimeSeriesOptions, generateTimeSeries } from "./SpreadStore";

export type TokenBalanceData = {
  ticker: string;
  data: ChartPoint[];
};

type TokenBalancesData = TokenBalanceData[];

const INITIAL_TOKEN_BALANCE_DATA: TokenBalanceData = {
  ticker: "",
  data: [],
};

const generateTokenBalanceData = (): TokenBalancesData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const tokens = generateTokenTickers({ count: { min: 1, max: 6 } });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 100,
      precision: 0.01,
    },
  };

  const data = tokens.map((ticker) => {
    const data = generateTimeSeries(generateSeriesOptions);
    return { ticker, data };
  });

  return data;
};

export default class TokensBalancesStore implements Disposable {
  private _data: TokenBalancesData = [];

  private _loading = false;

  private _currentToken = "";

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: TokenBalancesData) => {
    this._data = data;
  };

  private get _currentTokenData() {
    return (
      this._data.find(({ ticker }) => ticker === this._currentToken) ?? INITIAL_TOKEN_BALANCE_DATA
    );
  }

  get data() {
    return this._currentTokenData;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  setCurrentToken = (token: string) => {
    this._currentToken = token;
  };

  get currentToken() {
    return this._currentToken;
  }

  get tokens() {
    return this._data.map(({ ticker }) => ticker);
  }

  getTokensBalances = async () => {
    this._setLoading(true);
    this._setData([]);
    try {
      await delay(200);

      const data = generateTokenBalanceData();
      this._setData(data);
      this.setCurrentToken(data[0].ticker);
    } catch {
      this._setData([]);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
