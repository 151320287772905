import { Control, Controller } from "react-hook-form";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { MODULE_COMPARE_LIST, useGetCompare } from "../hooks/useGetCompare";

export interface CompareSelectorProps {
  control: Control<IStrategyCreator, any>;
  index: number;
}

export const CompareSelector = ({ control, index }: CompareSelectorProps) => {
  const getCompareSelectorValue = useGetCompare();

  return (
    <Controller
      control={control}
      name={`conditions.${index}.soft_params.compare`}
      rules={{ required: ERRORS_MSGS.isRequired }}
      render={({ field: { value, onChange }, formState: { errors } }) => (
        <LabeledSelector
          label="Compare"
          menuPosition="fixed"
          options={MODULE_COMPARE_LIST}
          onChange={(data) => onChange(data?.value)}
          value={getCompareSelectorValue(value)}
          errorHint={(errors.conditions?.[index]?.soft_params as any)?.compare?.message}
        />
      )}
    />
  );
};
