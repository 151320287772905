import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable } from "src/helpers/utils";
import { generateAccountName, generateExchange } from "./AccountBalancesStore";

const FUNDING_TYPE = ["Deposit", "Withdraw"] as const;
export type FundingType = (typeof FUNDING_TYPE)[number];

export type FundingData = {
  type: FundingType;
  amount: string;
  currency: string;
  exchange: string;
  account: string;
  time: number;
};

export type FundingDataKeys = keyof FundingData;

const generateFundingType = () => faker.helpers.arrayElement(FUNDING_TYPE);

export const generateAmount = () => faker.finance.amount({ max: 1_000_000_000, dec: 10 });

const MOCK_TOKENS = ["BTC", "ETH", "BNB", "ADA", "USDT", "XRP", "DOT", "DOGE", "LTC"];

interface GenerateTokenTickersOptions {
  count?: number | { min: number; max: number };
}

export const generateTokenTickers = ({ count }: GenerateTokenTickersOptions) =>
  faker.helpers.arrayElements(MOCK_TOKENS, count);

export const generateTokenTicker = () => generateTokenTickers({ count: 1 })[0];

export const generateTimeMs = () => {
  const date = faker.date.past();
  return date.getTime();
};

export const generateTime = () => {
  const date = faker.date.past();
  return dayjs(date).unix();
};

const generateFunding = (): FundingData => ({
  type: generateFundingType(),
  amount: generateAmount(),
  currency: generateTokenTicker(),
  exchange: generateExchange(),
  account: generateAccountName(),
  time: generateTimeMs(),
});

const generateFundings = (): FundingData[] => {
  const fundings = faker.helpers.multiple(generateFunding, {
    count: { min: 3, max: 10 },
  });
  return fundings;
};

export default class FundingStore implements Disposable {
  private _data: FundingData[] = [];

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: FundingData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  getFundings = () => {
    try {
      const fundings = generateFundings();
      this._setData(fundings);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
