import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { BotInfoDEX } from "src/components/BotsContent/DEX/Stats/BotInfoDEX";
import { BotInfoDEXFallback } from "src/components/BotsContent/DEX/Stats/BotInfoDEX/styles";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { useFindWidth } from "src/hooks/useFindWidth";
import useAppState from "src/state";
import { BotInfo } from "../../CEX";
import { Info } from "./Info";
import { Settings } from "./Settings";
import * as styles from "./style";

interface DEXBotSettingsProps {
  botInfo: BotInfo;
  abilityName: string;
}

export const DEXBotSettings = observer(
  ({ botInfo: { market, party, bot_uuid }, abilityName }: DEXBotSettingsProps) => {
    const { DEXSettingsState: state } = useAppState();
    const changesSize = useFindWidth(900);

    useEffect(() => {
      state.setBotUUID(bot_uuid);
      if (bot_uuid) state.getData();
    }, [bot_uuid, state]);

    useEffect(() => {
      state.setMarket(market);
    }, [state, market]);

    return (
      <styles.Container>
        <styles.SettingsForm
          onSubmit={state.submitHandler}
          onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        >
          <styles.Header>
            <styles.Title>Bot settings {market} </styles.Title>
            <ErrorBoundary fallback={<BotInfoDEXFallback />}>
              <BotInfoDEX bot_uuid={bot_uuid} />
            </ErrorBoundary>
            <LabeledInput
              label="Link"
              value={state.data.link}
              onChange={state.getHandler("link")}
              // @ts-ignore
              getError={state.getError("link")}
              // @ts-ignore
              errorHint={state.errors.link}
            />
          </styles.Header>
          <styles.Content>
            {changesSize ? <Info /> : null}
            <Settings party={party} abilityName={abilityName} />
            {!changesSize ? <Info /> : null}
            <Loader show={state.showLoader} />
          </styles.Content>
        </styles.SettingsForm>
      </styles.Container>
    );
  }
);
