import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { logError } from "src/helpers/network/logger";
import { Note } from "src/modules/shared";

class NotesStore {
  message = "";

  _bot_uuid = "";

  showLoader = false;

  constructor() {
    makeAutoObservable(this);
  }

  get reqParam() {
    return this._bot_uuid;
  }

  resetReqParams = () => {
    this._bot_uuid = "";
  };

  setBotUUID = (uuid: string) => {
    this._bot_uuid = uuid;
  };

  setLoader = (bool: boolean) => {
    this.showLoader = bool;
  };

  setMessage = (value: string) => {
    this.message = value;
  };

  clearMessage = () => {
    this.message = "";
  };

  getValueNote = async (getNote: (reqParam: string) => Promise<any>) => {
    this.clearMessage();
    this.setLoader(true);

    try {
      const {
        isError,
        data: { note },
      } = await getNote(this.reqParam);

      if (!isError) this.setMessage(note);
    } catch (error) {
      logError(error);
    } finally {
      this.setLoader(false);
    }
  };

  textAreaHandler = () => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setMessage(e.target.value);
  };

  submitHandler =
    (setNote: (reqParam: string, data: Note) => Promise<any>) => async (e: React.FormEvent) => {
      e.preventDefault();
      this.setLoader(true);

      try {
        const { isError } = await setNote(this.reqParam, {
          note: this.message,
        });

        if (!isError) toast.success("Note created", { autoClose: 2000 });
      } catch (err) {
        logError(err);
      } finally {
        this.setLoader(false);
      }
    };
}

export default NotesStore;
