import styled from "styled-components";
import { KPIText } from "../../shared/KPICard/style";

export const KPILabel = styled(KPIText)``;

export const KPIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
