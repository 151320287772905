import styled from "styled-components";

export const SeriesContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  font-size: ${({ theme }) => theme.fontSizes["14px"]};
`;

export const SeriesNameContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  font-size: inherit;
`;

export interface SeriesColorProps {
  color: string;
}

export const SeriesColor = styled.div<SeriesColorProps>`
  width: 10px;
  height: 4px;
  background: ${({ color }) => color};
`;

export const SeriesName = styled.span`
  font-size: inherit;
`;

export const SeriesPrice = styled.span`
  font-size: inherit;
`;
