import { Chart, ChartDataset } from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Chart as ChartComponent } from "react-chartjs-2";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { Totalizer, getTotal } from "src/config/chartjs/plugins/totalizer";
import { roundSingleValue } from "src/helpers/rounding";
import { formatFiat } from "src/helpers/string";
import { entries, isDefined } from "src/helpers/utils";
import { deepMerge } from "src/helpers/utils/deepMerge";
import {
  BalancesTokensTransactions,
  BalancesTokensTransactionsValue,
} from "src/state/CEX/CEXDashboard/BalancesStore";
import { useChartZoom } from "../../../hooks/useChartZoom";
import { useDashboardColors } from "../../../hooks/useDashboardColors";
import {
  BASE_TIME_CONFIG,
  useBaseTooltip,
  useGridOptions,
  usePlaceholderOptions,
} from "../../../shared/config";
import { InOutIndicatorPlugin, InOutIndicatorStatus } from "../../../shared/plugins";
import { ChartProps } from "../../../shared/types";
import { getSeriesLabel } from "../../../shared/utils";

const isTransactionsDataset = (dataset: ChartDataset) => dataset.label === "Transactions";

const isPriceDataset = (dataset: ChartDataset) => dataset.label === "Price";

const getPriceText = (chart: Chart<"bar" | "line">, dataIndex: number) => {
  const dataset = chart.data.datasets.find(isPriceDataset);
  if (!dataset) return 0;
  const price = dataset.data[dataIndex] as number;
  const priceText = roundSingleValue(+price);
  return priceText;
};

const hasTokenTransactions = (transactions?: BalancesTokensTransactions) => {
  if (!transactions) return undefined;
  return Object.keys(transactions).length > 0;
};

const getTransactionsIndicatorStatus = (
  datasets: ChartDataset[],
  dataIndex: number
): InOutIndicatorStatus => {
  const transactionsDataset = datasets.find((dataset) => dataset.label === "Transactions");
  if (!transactionsDataset) return {};

  const transactions = transactionsDataset.data[dataIndex] as BalancesTokensTransactionsValue;
  if (!transactions) return {};

  const { in: inTransactions, out: outTransactions } = transactions;

  const hasInTransactions = hasTokenTransactions(inTransactions);
  const hasOutTransactions = hasTokenTransactions(outTransactions);

  return {
    in: hasInTransactions,
    out: hasOutTransactions,
  };
};

const joinMessages = (messages: (string | undefined)[], delimiter = "\n") =>
  messages.filter(Boolean).join(delimiter);

export const transactionsToText = (
  transactions: BalancesTokensTransactions | undefined,
  title = "Transactions"
) => {
  if (!transactions) return "";

  const transactionsText = entries(transactions)
    .map(([token, value]) => {
      const amount = formatFiat(value, false);
      return `${token}: ${amount}`;
    })
    .join("\n");

  return joinMessages([title, transactionsText]);
};

const DELIMITER_LINE = "‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾";

const getTransactionsText = (transactions: BalancesTokensTransactionsValue) => {
  if (!transactions) return "";
  const { in: inTransactions, out: outTransactions } = transactions;

  const inTransactionsText = transactionsToText(inTransactions, "In:");
  const outTransactionsText = transactionsToText(outTransactions, "Out:");

  const transactionsText = joinMessages([inTransactionsText, outTransactionsText], "\n\n");

  if (!transactionsText) return "";

  return joinMessages([DELIMITER_LINE, transactionsText]);
};

const useBaseOptions = () => {
  const {
    textSubhead,
    textAdditional,
    textDefault,
    borderDefault,
    textSecondary,
    accent: { darkLime, rubyRed },
  } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const options = useMemo(
    (): ChartProps<"bar" | "line", number[], number>["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: 8 },
      scales: {
        x: {
          type: "time",
          ...BASE_TIME_CONFIG,
          stacked: true,
        },
        y: {
          stacked: true,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",

          grid: {
            drawOnChartArea: false,
            color: borderDefault,
            borderColor: borderDefault,
          },
          ticks: {
            color: textSecondary,
          },
        },
      },
      plugins: {
        tooltip: {
          ...tooltipOptions,
          displayColors: true,
          mode: "index",
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSubhead,
          footerFont: {
            size: 8,
            weight: "500",
          },
          multiKeyBackground: "rgba(0,0,0,0)",
          filter(item) {
            const dataset = item.chart.data.datasets[item.datasetIndex];
            return dataset.type !== "line";
          },
          callbacks: {
            footer() {
              const { dataIndex } = this.dataPoints[0];
              const total = getTotal(this.chart, dataIndex);
              const totalText = `Total: ${total}`;

              const price = getPriceText(this.chart, dataIndex);
              const priceText = `Price: ${price}`;

              const transactionsDataset = this.chart.data.datasets.find(
                (dataset) => dataset.label === "Transactions"
              );

              const transactions = transactionsDataset?.data[
                dataIndex
              ] as BalancesTokensTransactionsValue;
              const transactionsText = getTransactionsText(transactions);

              return `${totalText}\n${priceText}\n${transactionsText}`;
            },
            labelColor(item) {
              const color = (item.dataset.backgroundColor as string) ?? "#fff";
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: color,
                borderWidth: 0,
              };
            },
            label(ctx) {
              const title = ctx.dataset.label || "";
              const value = ctx.raw as number;
              const total = getTotal(ctx.chart, ctx.dataIndex);

              return getSeriesLabel(title, value, total);
            },
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            pointStyle: "rect",
            usePointStyle: true,
            boxHeight: 12,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
            filter(item, data) {
              const { datasetIndex } = item;
              if (!isDefined(datasetIndex)) return false;
              const dataset = data.datasets[datasetIndex];
              return !isTransactionsDataset(dataset);
            },
          },
        },
        totalizer: {
          filter: (dataset) => !isPriceDataset(dataset) && !isTransactionsDataset(dataset),
        },
        inOutIndicator: {
          gap: 10,
          fillColor: {
            in: darkLime,
            out: rubyRed,
            neutral: textDefault,
          },
          getInOutStatus(datasets, index) {
            return getTransactionsIndicatorStatus(datasets, index);
          },
          datasetLabels: ["Free", "Locked"],
        },
      },
    }),
    [
      borderDefault,
      darkLime,
      rubyRed,
      textAdditional,
      textDefault,
      textSecondary,
      textSubhead,
      tooltipOptions,
    ]
  );

  return options;
};

export interface BalancesOverviewBarProps
  extends Omit<
    ChartProps<"bar" | "line", number[] | BalancesTokensTransactionsValue[], number>,
    "type"
  > {
  zoomDefault?: boolean;
}

export const BalancesOverviewBar = observer(
  ({ options: inOptions, zoomDefault, ...props }: BalancesOverviewBarProps) => {
    const baseOptions = useBaseOptions();

    const placeholderOptions = usePlaceholderOptions();

    const gridOptions = useGridOptions();

    const zoomOptions = useChartZoom({
      shouldZoom: zoomDefault,
      data: props.data,
    });

    const options = useMemo(
      () => deepMerge(placeholderOptions, gridOptions, baseOptions, zoomOptions, inOptions),
      [baseOptions, gridOptions, inOptions, placeholderOptions, zoomOptions]
    );

    return (
      <ChartComponent
        type="bar"
        options={options}
        plugins={[Totalizer, Zoom, ChartPlaceholder, InOutIndicatorPlugin]}
        {...props}
      />
    );
  }
);
