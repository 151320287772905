import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { toast } from "react-toastify";
import { setLiquiditySettings } from "src/api/bots/CEX/liquidity";
import { BotNameParts } from "src/helpers/botName";
import { Disposable } from "src/helpers/utils";
import LiquidityTabStore from ".";
import GridForm, { IGridForm } from "./GridForm";

export interface IBaseGridForm {
  form: IGridForm;
  loading: boolean;
  setLoading: (bool: boolean) => void;
  submitHandler: () => (e: React.FormEvent) => Promise<void>;
}

export interface ICreateGrid extends IBaseGridForm {}

class CreateGrid implements ICreateGrid, Disposable {
  form: IGridForm;

  loading = false;

  private _liquidityTab: LiquidityTabStore;

  private _nameFetchedReaction: IReactionDisposer;

  constructor(liquidityTab: LiquidityTabStore) {
    this._liquidityTab = liquidityTab;
    this.form = new GridForm(liquidityTab);
    makeAutoObservable(this);

    this._nameFetchedReaction = reaction(
      () => ({
        market: this._liquidityTab.botNameParts,
        botId: this._liquidityTab.botUUID,
      }),
      ({ market, botId }) => {
        if (!market || !botId) return;
        this._setData(market, botId);
      },
      {
        fireImmediately: true,
      }
    );
  }

  private _setData(botNameParts: BotNameParts, botId: string) {
    this.form.clearForm();
    const { exchange, quote, base } = botNameParts;
    this.form.data.pair.quote = quote;
    this.form.data.pair.base = base;
    this.form.data.exchange = exchange;
    this.form.data.botUUID = botId;
  }

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  private _getSubmitSettings = () => this.form.data;

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this.form.validate();
    if (valid) {
      this.setLoading(true);

      this.form.data.buyFees.quote = this.form.data.sellFees.quote;

      try {
        const { isError } = await setLiquiditySettings(this._getSubmitSettings());
        if (!isError) {
          toast.success("Grid started successfully", {
            autoClose: 2000,
          });

          return this._liquidityTab.getLiquidity();
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  destroy() {
    this._nameFetchedReaction();
  }
}

export default CreateGrid;
