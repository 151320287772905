import { observer } from "mobx-react-lite";
import { SeparatorNum } from "src/components/shared/Separator";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import useAppState from "src/state";
import { Copy, Row } from "../style";
import * as styles from "./style";

export const WalletRow = observer(
  ({ wallets = [], name, leftBalances, rightBalances, nativeBalances }) => {
    const { WalletsState: state } = useAppState();

    const cutWallet = (wallet) =>
      `${wallet.slice(0, 5)}...${wallet.slice(wallet.length - 4, wallet.length)}`;

    return (
      <Row>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Title key={el.open}>{`${name}${index + 1}`}</styles.Title>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Wallet
              key={el.open}
              href={
                state.exchanges[state.exchange]
                  ? state.exchanges[state.exchange].walletLink + el.open
                  : "#"
              }
            >
              {cutWallet(el.open)}
            </styles.Wallet>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <Copy key={el.open} onClick={state.copyWalletHandler(el.open)}>
              {DEXStatsIcons.copy()}
            </Copy>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={leftBalances.length ? leftBalances[index]?.allowance : null}
            >
              <SeparatorNum value={leftBalances.length ? leftBalances[index].value : null} />
            </styles.Value>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={rightBalances.length ? rightBalances[index]?.allowance : null}
            >
              <SeparatorNum value={rightBalances.length ? rightBalances[index].value : null} />
            </styles.Value>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={nativeBalances.length ? nativeBalances[index]?.allowance : null}
            >
              <SeparatorNum value={nativeBalances.length ? nativeBalances[index].value : null} />
            </styles.Value>
          ))}
        </styles.Column>
        {/* <CheckBox /> */}
      </Row>
    );
  }
);
