import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { addHierarchy } from "src/api/userManager/hierarchyAPI";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { Disposable, Optional } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { NewHierarchy } from "src/modules/userManager";
import { required, validateData } from "src/validation-schemas";
import { ListUpdate } from "./Scopes/ScopesStore";

type NewHierarchyForm = Optional<NewHierarchy, "scope_id">;

const INITIAL_HIERARCHY: NewHierarchyForm = {
  role: "",
  user_name: "",
};

export interface AddDependencyParams {
  updateList: ListUpdate;
  closeModal: () => void;
  isModalShown: boolean;
}

export default class AddDependencyStore implements Disposable {
  private _params: AddDependencyParams;

  private _validation = {
    scope_id: required(),
    role: required(),
    user_name: required(),
  };

  private _isShownReaction: IReactionDisposer;

  newHierarchy: NewHierarchyForm = INITIAL_HIERARCHY;

  handlers: any = {};

  errors: any = {};

  isLoading = false;

  constructor(params: AddDependencyParams) {
    this._params = params;

    makeAutoObservable(this);

    this._isShownReaction = reaction(
      () => this._params.isModalShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );
  }

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this.newHierarchy = INITIAL_HIERARCHY;
  };

  getHandler = (key: string) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newHierarchy, path);

      switch (key) {
        case "role":
        case "user_name":
          this.handlers[key] = ({ value }: SelectorValue) => {
            targetData[endKey] = `${value}`;
          };
          break;
        case "scope_id": {
          this.handlers[key] = ({ id }: SelectorValue) => {
            if (id) targetData[endKey] = +id;
          };
          break;
        }
      }
    }

    return this.handlers[key];
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this._validation, this.newHierarchy, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this.validate(undefined);

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      try {
        const { isError } = await addHierarchy(this.newHierarchy as NewHierarchy);

        if (!isError) {
          toast.success("Dependency created successfully", {
            autoClose: 2000,
          });
          this._params.closeModal();
          this._params.updateList();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  destroy() {
    this._isShownReaction();
  }
}
