import { Route, Switch } from "react-router-dom";
import { Stories } from "src/components/stories";
import { ButtonsStory } from "src/components/stories/ButtonsStory";
import { OldButtonsStory } from "src/components/stories/OldButtonsStory";
import { PNLV2Story } from "src/components/stories/PNLV2Story";
import { enableStories } from "src/environment/env";

const useShowStories = () => {
  const showStories = enableStories;
  return showStories === "true";
};

export const useStoryRouter = () => {
  const showStories = useShowStories();

  if (!showStories) {
    return null;
  }

  // extra wrapper to use story switch router as a part parent router
  // can't male it work without inlining
  const StoryRoute = (
    <Route path="/stories">
      <StoryRouter />
    </Route>
  );

  return StoryRoute;
};

export const StoryRouter = () => (
  <Switch>
    <Route path="/stories/buttons">
      <ButtonsStory />
    </Route>
    <Route path="/stories/old-buttons">
      <OldButtonsStory />
    </Route>
    <Route path="/stories/pnlv2">
      <PNLV2Story />
    </Route>
    <Route exact path="/stories/">
      <Stories />
    </Route>
  </Switch>
);
