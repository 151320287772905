import { useTableInstanceContext } from "src/context/shared/TableInstance";
import { AccountBalances } from "src/state/CEX/CEXDashboard/AccountBalancesStore";
import { ColumnsTotalMap } from "src/state/CEX/CEXDashboard/shared/TableTotalizer";
import { StatsTableInstanceProps, StatsTableProps } from "../../shared/StatsTable";
import * as styles from "./style";

export interface AccountBalancesTableContentProps<T extends ColumnsTotalMap<AccountBalances>>
  extends Omit<
    StatsTableProps<AccountBalances, T>,
    keyof StatsTableInstanceProps<AccountBalances>
  > {}

export const AccountBalancesTableContent = <T extends ColumnsTotalMap<AccountBalances>>(
  props: AccountBalancesTableContentProps<T>
) => {
  const instance = useTableInstanceContext<AccountBalances>();
  return <styles.StyledTable instance={instance} {...props} />;
};
