import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { ScopesProvider } from "src/context/UserManager/Scopes";
import { UserGroupsProvider } from "src/context/UserManager/UserGroups";
import useAppState from "src/state";
import { MainLabel } from "../../shared/shared";
import { HierarchyList } from "./Lists/HierarchyList";
import { RolesList } from "./Lists/RolesList";
import { UserGroupsList } from "./Lists/UserGroupsList";
import { UserList } from "./Lists/UserList";
import { Scopes } from "./Scopes";
import * as styles from "./style";

export const Access = observer(() => {
  const { UserManagerState: state } = useAppState();

  useEffect(() => {
    state.loadData();
  }, [state]);

  return (
    <styles.ContentArea>
      <MainLabel>Access</MainLabel>
      <styles.Content>
        <ErrorBoundary fallback={<styles.UsersFallback />}>
          <UserList />
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.RolesFallback />}>
          <RolesList />
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.HierarchiesFallback />}>
          <HierarchyList />
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.UserGroupsFallback />}>
          <UserGroupsProvider>
            <UserGroupsList />
          </UserGroupsProvider>
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.ScopesFallback />}>
          <ScopesProvider>
            <Scopes />
          </ScopesProvider>
        </ErrorBoundary>
      </styles.Content>
    </styles.ContentArea>
  );
});
