import { OmitEndingWith } from "src/helpers/utils";
import { DEXV2CounterStrategy } from "src/state/DEXV2/DEXV2Settings";

export interface ICEXBot {
  base: string;
  diff: string;
  exchange: string;
  name: string;
  link: string;
  message: string;
  modifyTime: number;
  modifyVolume: boolean;
  party: string;
  quote: string;
  side: string;
  status: number;
  timeDontWork: number;
  updated_at: number;
  bot_uuid: string;
  created_at: number;
}

export enum CEXBotStatus {
  Red,
  Yellow,
  Blue,
  Green,
  Gray,
}

export interface DEXBot {
  swap_bot_id: number;
  swap_bot_uuid: string;
  base: string;
  client: string;
  exchange: string;
  lastTrade: number;
  link: string;
  message: string;
  nextTrade: number;
  note: string;
  party: string;
  party_id: number;
  quote: string;
  visible: boolean;
  created_at: number;
  updated_at: number;
}

export enum DEXV2BotModeStatus {
  Stopped,
  Running,
  Error,
}

export enum DEXV2BotStatus {
  Stopped,
  Stale,
  Running,
  Error,
  Warning,
}

export enum DEXV2ExchangeVersion {
  V2 = "2",
  V3 = "3",
}

export enum DEXV2BotVersion {
  V2 = "2",
  V3 = "3",
}

type DEXV2ModeInfo<T extends DEXV2BotMode> = {
  [k in `${T}Status`]: DEXV2BotModeStatus;
} & {
  [k in `${T}Message`]: string;
} & {
  [k in `${T}NextTrade`]: number;
} & {
  [k in `${T}LastTrade`]: number;
};

export type DEXV2LimitInfo = DEXV2ModeInfo<"limit">;

export type DEXV2VolumeInfo = DEXV2ModeInfo<"volume">;

export type DEXV2CounterInfo = OmitEndingWith<DEXV2ModeInfo<"counter">, "Trade"> &
  Partial<Pick<DEXV2ModeInfo<"counter">, "counterLastTrade">>;

export interface DEXV2Bot extends DEXV2LimitInfo, DEXV2VolumeInfo, DEXV2CounterInfo {
  base: string;
  base_addr: string;
  quote: string;
  quote_addr: string;
  exchange: string;
  link: string;
  party: string;
  bot_uuid: string;
  name: string;
  chain_id: number;
  dex_version: DEXV2ExchangeVersion;
  isStopped: boolean;
  status: DEXV2BotStatus;
}

export interface StartCEXBot {
  lastPrice: number;
  settings: {
    sameMM: boolean;
    accounts: {
      ACCOUNT_NAME: {
        name: string;
        exchange: string;
        accountID: string;
        apiKey: string;
        apiSecret: string;
      };
    };
    pair: {
      exchange: string;
      asset: string;
      token: string;
      minExchangeAmount: number;
      dontCheckAmount: number;
      amountDecimal: number;
      priceDecimal: number;
      minTrades: number;
      maxTrades: number;
      dontTradePriceMax: number;
      dontTradePriceMin: number;
      buyPercent: number;
      buyBeforeSellMin: number;
      buyBeforeSellMax: number;
      candlePercentMax: number;
      candlePercentMin: number;
      period: number;
      volumeToTradePerDayMinUSD: number;
      volumeToTradePerDayMaxUSD: number;
      modifyVolumeToTradePerDayMinUSD: number;
      modifyVolumeToTradePerDayMaxUSD: number;
      modifyDeltaBalanceQuoteUSD: number;
      modifyDeltaBalanceBase: number;
    };
    virtualRange: {
      binding: {
        kind: "bot";
        mainBot: string;
        rightToLeft: boolean;
        same: boolean;
        mainBotAsset: string;
        currentAsset: string;
        name: string;
        id: number;
      };
      enableBuyWall: boolean;
      enableSellWall: boolean;
      buyMinAmount: number;
      buyMaxAmount: number;
      sellMinAmount: number;
      sellMaxAmount: number;
      spread: number;
      diff: number;
      ratio: number;
    };
    cancelSpreadOrders: boolean;
  };
}

export interface Client {
  id: string;
  websocket: boolean;
}

export interface ICEXBotInfo {
  base: string;
  bot_uuid: string;
  exchange: string;
  name: string;
  quote: string;
}

export interface IDEXBotInfo {
  base: string;
  bot_uuid: string;
  exchange: string;
  path: string;
  quote: string;
}

export interface IDEXV2BotInfo extends ICEXBotInfo {}

export interface ICEXBotInfoWithMarket extends ICEXBotInfo {
  market: string;
}

export interface IDEXBotInfoWithMarket extends IDEXBotInfo {
  market: string;
}

export interface DEXV2CreatingBot {
  base_data: {
    party: string;
    bot_name: string;
    chain_id: number;
    base: string;
    quote: string;
    exchange: string;
    transfer_oracle_wallets: string[];
    swap_oracle_wallets: string[];
    withdrawer: string;
    receiver_num: number | "";
  };

  main_data: {
    limit_executors_num: number | "";
    volume_executors_num: number | "";
    counter_executors_num: number | "";
    tt_buy_fee: number;
    tt_sell_fee: number;
    gas_limit: number | "";
    pool_percent: number | "";
    stable: string;
  };
  volume_data: {
    on: boolean;
    use_receiver: boolean;
    period: number | "";
    min_trades: number | "";
    max_trades: number | "";
    buy_percent: number | "";
    min_amount: number | "";
    max_amount: number | "";
    slippage: number | "";
    gas_mult: number;
    gas_price_limit: number | "";
  };
  limit_data: {
    on: boolean;
    use_receiver: boolean;
    mod: "buy" | "sell";
    trigger_compare: "greater" | "less";
    period: number | "";
    price: number | "";
    min_amount: number | "";
    max_amount: number | "";
    slippage: number | "";
    gas_mult: number;
    gas_price_limit: number | "";
  };
  counter_data: {
    on: boolean;
    cumulative: boolean;
    black_listed_wallets: string[];
    data: DEXV2CounterStrategy[];
    gas_mult: number;
    gas_price_limit: number | "";
    slippage: number | "";
  };
}
export interface RequestRecentParty {
  created_at: number;
  party: string;
}

export type BotsType = "CEX" | "DEX" | "DEXV2";

export type DEXV2BotMode = "limit" | "volume" | "counter";

export enum TransactionState {
  Error,
  Pending,
  Success,
}

export type TransactionStatus =
  | {
      state: TransactionState.Pending;
    }
  | { state: TransactionState.Success }
  | { state: TransactionState.Error; message: string };

export type TransactionStatusType<T extends TransactionState> = Extract<
  TransactionStatus,
  {
    state: T;
  }
>;

type TransactionStatusValues<T extends TransactionState> = Omit<TransactionStatusType<T>, "state">;

export const createTransactionStatus = <T extends TransactionState>(
  state: T,
  values: TransactionStatusValues<T>
) => {
  const status = { state, ...values };
  return status as TransactionStatusType<T>;
};

export interface TransactionHistoryHash {
  type: string;
  hash: string;
  date: number;
  status: TransactionStatus;
}
