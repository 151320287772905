import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import FundingDEXStore from "src/state/funding/fundingDEX";
import { DateTimeInputPrompt } from "../../CEX/ExpertSystem/shared";
import { SubTitle } from "../style";
import * as styles from "./style";

const MENU_ITEMS = [
  { value: "WITHDRAWAL", label: "WITHDRAWAL" },
  { value: "DEPOSIT", label: "DEPOSIT" },
];

interface FundingFormProps {
  state: FundingDEXStore;
}

export const FundingForm = observer(({ state }: FundingFormProps) => (
  <styles.Form
    onSubmit={state.submitHandler}
    onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
  >
    <SubTitle>New funding</SubTitle>
    <styles.Content>
      <styles.Wrapper>
        <LabeledSelector
          label="Type"
          options={MENU_ITEMS}
          value={{ value: state.typeOperation, label: state.typeOperation }}
          onChange={state.getSelectHandler("typeOperation")}
          errorHint={state.typeOperationErrMsg}
        />

        <LabeledInput
          label="Amount"
          type="number"
          step="any"
          value={state.funding.amount}
          onChange={state.getInputHandler("amount")}
          errorHint={state.errors.amount}
        />
      </styles.Wrapper>
      <styles.Wrapper>
        <LabeledSelector
          label="Quote"
          options={state.assets}
          value={{
            value: state.funding.currency,
            label: state.funding.currency,
          }}
          onChange={state.getSelectHandler("funding", "currency")}
          errorHint={state.errors.currency}
        />

        <LabeledInput
          label="Time"
          type="datetime-local"
          after={<DateTimeInputPrompt />}
          placeholder="YYYY-MM-DDThh:mm"
          value={state.dateValue}
          onChange={state.getInputDateHandler()}
          errorHint={state.errors.time}
        />
      </styles.Wrapper>

      <Loader show={state.showLoader} />
    </styles.Content>

    <styles.ButtonWrapper>
      <SubmitButton size="m">Submit</SubmitButton>
    </styles.ButtonWrapper>
  </styles.Form>
));
