import { observer } from "mobx-react-lite";
import { Redirect, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useAppState from "src/state";
import { AllBots } from "../components/AllBots";
import { Confirm } from "../components/Auth/Confirm";
import { Login } from "../components/Auth/Login";
import { CEXBotCreating } from "../components/BotCreating/CEX";
import { DEXBotCreating } from "../components/BotCreating/DEX";
import { CEXContent } from "../components/BotsContent/CEX";
import { DEXContent } from "../components/BotsContent/DEX";
import { DEXV2Content } from "../components/BotsContent/DEX_V2";
import { ExchangeAccounting } from "../components/ExchangeAccounting";
import { Access } from "../components/UserManagement/Access";
import { Teams } from "../components/UserManagement/Teams";
import { ErrorBoundary } from "../components/shared/ErrorBoundary";
import { ExchangeAccountingContext } from "../context/CEX/ExchangeAccounting";
import { ChainConnectionProvider } from "../context/DEXV2/ChainConnectionContext";
import { DEXV2BotProvider } from "../context/DEXV2/DEXV2Bots/DEXV2Bot";
import { BotWalletConnectionProvider } from "../context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { TeamsProvider } from "../context/UserManager/Groups";
import { ContentPermission } from "../permissions/ContentPermission";
import { DEXV2Ability } from "../state/DEXV2/shared/AbilitiesStore";
import { useStoryRouter } from "./routers/StoryRouter";
import * as styles from "./style";

export const AppRouter = observer(() => {
  const { userState } = useAppState();

  const StoryRouter = useStoryRouter();

  return (
    <styles.RouterContainer>
      {userState.isLoggedIn ? (
        <Switch>
          <Route path="/MainPage">
            <Redirect to="/AllBots" />
          </Route>

          <Route path="/AllBots">
            <AllBots />
          </Route>

          <Route path="/CEX/:path/">
            <CEXContent />
          </Route>

          <Route path="/DEX/:path/">
            <DEXContent />
          </Route>

          <Route path="/DEX_V2/:uuid/">
            <ErrorBoundary fallback={<styles.PageFallback />}>
              <DEXV2BotProvider>
                <ChainConnectionProvider>
                  <BotWalletConnectionProvider>
                    <DEXV2Content />
                  </BotWalletConnectionProvider>
                </ChainConnectionProvider>
              </DEXV2BotProvider>
            </ErrorBoundary>
          </Route>

          <Route path="/Access">
            <ContentPermission abilityName="auth-view">
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <Access />
              </ErrorBoundary>
            </ContentPermission>
          </Route>

          <Route path="/Teams">
            <ContentPermission abilityName="auth-user-group-view">
              <TeamsProvider>
                <ErrorBoundary fallback={<styles.PageFallback />}>
                  <Teams />
                </ErrorBoundary>
              </TeamsProvider>
            </ContentPermission>
          </Route>

          <Route path="/BotCreating">
            <ContentPermission abilityName="bot-manager">
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <CEXBotCreating />
              </ErrorBoundary>
            </ContentPermission>
          </Route>

          <Route path="/DEXCreating/:uuid?/">
            <ContentPermission abilityName={DEXV2Ability.BotManage}>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <DEXBotCreating />
              </ErrorBoundary>
            </ContentPermission>
          </Route>

          <Route path="/Accounting">
            <ErrorBoundary>
              <ContentPermission abilityName="accounting-view">
                <ExchangeAccountingContext.Provider>
                  <ExchangeAccounting />
                </ExchangeAccountingContext.Provider>
              </ContentPermission>
            </ErrorBoundary>
          </Route>

          {StoryRouter}

          <Route path="/">
            <Redirect to="/MainPage" />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/Confirm/:login/">
            <Confirm />
          </Route>

          {StoryRouter}

          <Route path="/">
            <Redirect to="/Login" />
          </Route>
        </Switch>
      )}
    </styles.RouterContainer>
  );
});
