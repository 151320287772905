import { toast } from "react-toastify";

/**
 * success message trigger
 * @param message - message to display on the client
 * @param autoClose - message display time
 */
export const showSuccessMsg = (message: string, autoClose: number | undefined = 2000) => {
  toast.success(message, { autoClose });
};

/**
 * error message trigger
 * @param message - message to display on the client
 * @param autoClose - message display time
 */
export const showErrorMsg = (message: string, autoClose?: number) => {
  toast.error(message, { autoClose });
};
