import { observer } from "mobx-react-lite";
import { formatFiat } from "src/helpers/string";
import { TooltipSeriesData } from "src/state/Graph/TooltipStore";
import {
  GraphTooltipSeriesContent,
  GraphTooltipSeriesContentProps,
  useSeriesData,
} from "../../../shared/Graph/GraphTooltip/GraphTooltipSeriesContent";

export interface ArbitrageTooltipSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const ArbitrageTooltipSeries = observer(
  ({ title: seriesTitle }: ArbitrageTooltipSeriesProps) => {
    const { title, color, price } = useSeriesData(seriesTitle);

    const titleText = `${title}:`;
    const formattedPercent = formatFiat(price);

    const contentProps: GraphTooltipSeriesContentProps = {
      color,
      title: titleText,
      value: formattedPercent,
    };

    return <GraphTooltipSeriesContent {...contentProps} />;
  }
);
