import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { PNL } from "src/components/shared/PNL";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import PNLStore from "src/state/DEXV2/DEXV2Stats/PNL";
import * as styles from "./style";

export const DEXV2PNL = observer(() => {
  const botState = useLateInitContext(DEXV2BotContext);
  const state = useLocalStore(PNLStore, botState);

  const { botUUID } = botState;

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getPNL();
  }, [botUUID, state]);

  const { pnlInfo: pnlProps, dateRange, setDateRange } = state;
  return (
    <styles.Container>
      <DateTimeRangePickerSelector
        value={dateRange}
        onChange={setDateRange}
        maxDate={getCurrentDayjs()}
        disableFuture
        popupProps={{ position: "bottom left" }}
        mobileQuery="(max-width: 400px)"
        selectorDropdown={styles.DropdownSelector}
      />

      <PNL {...pnlProps} pricePrecision={0} actualPrice={null} showLoader={state.loading} />
    </styles.Container>
  );
});
