import { CheckBox } from "src/components/shared/CheckBox";
import { HashesList } from "src/components/shared/HashesList";
import styled, { css } from "styled-components";
import { mobileMediaQuery } from "../style";

export const FormContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormRow = styled.div`
  width: 100%;

  display: flex;
  gap: 28px;
`;

export const FormCol = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderCheckbox = styled(CheckBox)`
  @media ${mobileMediaQuery("450px")} {
    width: 100%;
  }
`;

export interface WalletsListProps {
  fixedHeight?: boolean;
}

export const WalletsList = styled(HashesList)<WalletsListProps>`
  ${({ fixedHeight }) => {
    if (fixedHeight) {
      return css`
        height: 55px;
      `;
    }
  }}
`;
