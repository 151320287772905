import { observer } from "mobx-react-lite";
import { PanelHeaderOwnProps } from "src/components/shared/PanelHeader";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";

import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { SaveModuleButton } from "../Buttons/SaveModuleButton";
import { SettingsForm, SettingsFormOwnProps, SettingsFormProps } from "../shared/SettingsForm";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";
import { mobileMediaQuery } from "../style";
import * as styles from "./style";

export interface PanelComponents extends Omit<PanelHeaderOwnProps, "label" | "info"> {}

export interface SaveableProps {
  saveable?: boolean;
}

export interface SettingsFormPanelsProps
  extends Omit<SettingsFormProps, "disabled" | "onSubmit" | keyof SettingsFormOwnProps>,
    SaveableProps {}

export interface VolumeProps extends SettingsFormPanelsProps {}

export const Volume = observer(({ ...props }: VolumeProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const isMobile = useMediaQuery(mobileMediaQuery("700px"));
  const settingsOrientation = isMobile ? "column" : "row";

  const panelComponents: PanelComponents = {
    btn: <SaveModuleButton module="volume_data" />,
    toggle: (
      <SettingsToggleSwitch
        checked={state.modulesStatuses("volume")}
        onChange={state.getOnHandler("volume_data")}
      />
    ),
  };

  return (
    <SettingsForm
      {...props}
      orientation={settingsOrientation}
      onSubmit={state.submitModuleHandler("volume_data")}
      loader={state.moduleLoading("volume_data")}
    >
      <styles.VolumePanel {...(isMobile ? panelComponents : undefined)} />
      <styles.TradePanel {...(isMobile ? undefined : panelComponents)} />
    </SettingsForm>
  );
});
