import { observer } from "mobx-react-lite";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import { SettingsPanel } from "../SettingsPanel";
import { Wrapper } from "../shared";
import * as styles from "./style";

export const TradeSettings = observer(() => {
  const { createBotState: state } = useAppState();

  return (
    <SettingsPanel style={{ gridArea: "trade" }} title="Trade">
      <styles.TradesWrapper>
        <LabeledPercentRangeInput
          label="BuyPercent"
          value={state.data.settings?.trade?.buyPercent ?? ""}
          onChange={state.getHandler("settings.trade.buyPercent")}
          errorHint={state.errors.settings?.trade?.buyPercent}
        />

        <Wrapper>
          <LabeledInput
            type="number"
            label="BBsMin"
            value={state.data.settings?.trade?.buyBeforeSellMin ?? ""}
            onChange={state.getHandler("settings.trade.buyBeforeSellMin")}
            errorHint={state.errors.settings?.trade?.buyBeforeSellMin}
            step="any"
          />
          <LabeledInput
            type="number"
            label="BBsMax"
            value={state.data.settings?.trade?.buyBeforeSellMax ?? ""}
            onChange={state.getHandler("settings.trade.buyBeforeSellMax")}
            errorHint={state.errors.settings?.trade?.buyBeforeSellMax}
            step="any"
          />
        </Wrapper>

        <Wrapper>
          <LabeledInput
            type="number"
            label="TradesMin"
            value={state.data.settings?.trade?.minTrades ?? ""}
            onChange={state.getHandler("settings.trade.minTrades")}
            errorHint={state.errors.settings?.trade?.minTrades}
            step="any"
          />
          <LabeledInput
            type="number"
            label="TradesMax"
            value={state.data.settings?.trade?.maxTrades ?? ""}
            onChange={state.getHandler("settings.trade.maxTrades")}
            errorHint={state.errors.settings?.trade?.maxTrades}
            step="any"
          />
        </Wrapper>
      </styles.TradesWrapper>
    </SettingsPanel>
  );
});
