import styled from "styled-components";

export const Column = styled.div`
  display: grid;
  /* height: 100%; */
  width: 100%;
  grid-row-gap: 25px;
  grid-template-rows: 20px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  height: 20px;
  width: 100%;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  justify-content: center;
  align-items: center;
  color: #767676;
`;

export const Value = styled(Title)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ allowance, theme }) => {
    if (allowance === null) return "#767676";
    // return allowance ? theme.successColor : theme.dangerColor;
    return allowance ? "#767676" : theme.dangerColor;
  }};
`;

export const Wallet = styled.a`
  width: 95px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  font-family: "Roboto", monospace;
  align-items: center;
  /* text-decoration: none; */
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  height: 20px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.searchInputBgColor};
  border-radius: ${({ theme }) => theme.radii["4px"]};
  color: ${({ theme }) => theme.linkColorText};
  :visited {
    color: #70a5ed;
  }
`;
