import { useMemo } from "react";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { DashboardView } from "src/state/CEX/CEXDashboard";
import { SHOW_VIEWS_TOGGLE, VISIBLE_VIEWS } from "../shared/config/views";

interface DashboardViewMenuItem extends MenuItemData {
  value: DashboardView;
}

const BASE_DASHBOARD_VIEW_MENU_ITEMS: DashboardViewMenuItem[] = [
  { label: "Summary", value: "summary" },
  { label: "Detailed", value: "detailed" },
  { label: "Balance", value: "balance-summary" },
];

export const useDashboardViews = () => {
  const showToggle = SHOW_VIEWS_TOGGLE;
  const visibleViews = VISIBLE_VIEWS;

  const menuItems = useMemo(
    () => BASE_DASHBOARD_VIEW_MENU_ITEMS.filter((item) => visibleViews.includes(item.value)),
    [visibleViews]
  );

  return { showToggle, menuItems };
};
