import { makeAutoObservable, runInAction } from "mobx";
import { UseFormSetValue, UseFormUnregister } from "react-hook-form";
import { SingleValue } from "react-select";
import { deleteElem } from "src/helpers/array";
import { getDataByKey } from "src/helpers/forms/getByKey";
import { getSelectorValueFromOptions } from "src/helpers/forms/selectors";
import { GenericSelectorValue, isEmptyObject, objectToArrayOptions } from "src/helpers/obj";
import { toStringifyJSON } from "src/helpers/string";
import { noOp } from "src/helpers/utils";
import { IStrategyCreator } from "src/modules/expertSystem";
import { EMPTY_CHANGEABLE_SETTINGS_PARAMS, SETTINGS_PARAMS_CONFIG_MAP } from "./shared/consts";
import { ChangeableCEXSettings, LabelsParamsMapType, SettingsParamsPaths } from "./shared/types";
import { formConfigToSelectorOptions, getParamDependencies } from "./shared/utils";

export class ChooseSettingParamsStore {
  private _selectParam: SettingsParamsPaths | "" = "";

  private _settingsParams: SettingsParamsPaths[] = [];

  private _setValueForm: UseFormSetValue<Partial<ChangeableCEXSettings>> = noOp;

  private _labelsParamsMap = formConfigToSelectorOptions(SETTINGS_PARAMS_CONFIG_MAP);

  private _selectorOptions = objectToArrayOptions(this._labelsParamsMap);

  private _setModuleHardParam: UseFormSetValue<IStrategyCreator> = noOp;

  private _moduleIndex: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get selectParam() {
    if (!this._selectParam) return null;

    const getSelectValue = getSelectorValueFromOptions(this._selectorOptions);

    return getSelectValue(this._selectParam);
  }

  get selectorOptions() {
    return this._selectorOptions.filter((el) => !this._settingsParams.includes(el.value));
  }

  get settingsParams() {
    return this._settingsParams;
  }

  get isAddedParams() {
    return !this._settingsParams.length;
  }

  setFormSetter = (cb: UseFormSetValue<Partial<ChangeableCEXSettings>>) => {
    this._setValueForm = cb;
  };

  setModuleHardParamSetter = (cb: UseFormSetValue<IStrategyCreator>) => {
    this._setModuleHardParam = cb;
  };

  setModuleIndex = (indx: number) => {
    this._moduleIndex = indx;
  };

  selectorHandler = (
    data: SingleValue<GenericSelectorValue<LabelsParamsMapType, SettingsParamsPaths>>
  ) => {
    const selectParam = data?.value;

    if (selectParam) this._selectParam = selectParam;
  };

  addSettingParam = () => {
    if (!this._selectParam) return;

    this._addSettingsParam(this._selectParam);

    const paramDependency = getParamDependencies(this._selectParam);

    if (paramDependency) {
      paramDependency.forEach((param) => {
        this._addSettingsParam(param);
      });
    }

    this._resetSelectParam();
  };

  private _addSettingsParam = (param: SettingsParamsPaths) => {
    this._settingsParams.push(param);

    this._setValueForm(param, this.getDefaultParam(param));
  };

  removeSettingParamHandler =
    (param: SettingsParamsPaths, unregister: UseFormUnregister<Partial<ChangeableCEXSettings>>) =>
    () => {
      this._removeSettingsParam(param, unregister);

      const paramDependencies = getParamDependencies(param);

      if (paramDependencies) {
        paramDependencies.forEach((el) => {
          this._removeSettingsParam(el, unregister);
        });
      }
    };

  private _removeSettingsParam = (
    param: SettingsParamsPaths,
    unregister: UseFormUnregister<Partial<ChangeableCEXSettings>>
  ) => {
    runInAction(() => deleteElem(this._settingsParams, param));

    unregister(param);
  };

  private _resetSelectParam = () => {
    this._selectParam = "";
  };

  getDefaultParam = (param: SettingsParamsPaths) =>
    getDataByKey(EMPTY_CHANGEABLE_SETTINGS_PARAMS, param);

  generateSettingsConfig = (data: Partial<ChangeableCEXSettings>) => {
    const isEmpty = isEmptyObject(data);

    if (!isEmpty) {
      // crutch for calculating max settings volumes
      if (data.volume?.tradePerDayMinUSD) {
        // eslint-disable-next-line no-param-reassign
        (data as any).volume.tradePerDayMaxUSD = String(
          Math.round(data.volume.tradePerDayMinUSD * 1.1)
        );
      }
      // crutch for calculating max settings volumes
      if (data.volume?.modifyTradePerDayMinUSD) {
        // eslint-disable-next-line no-param-reassign
        (data as any).volume.modifyTradePerDayMaxUSD = String(
          Math.round(data.volume.modifyTradePerDayMinUSD * 1.1)
        );
      }

      const jsonSettingsConfig = toStringifyJSON(data);

      if (this._moduleIndex !== null) {
        this._setModuleHardParam(
          `actions.${this._moduleIndex}.soft_params.settings`,
          jsonSettingsConfig
        );
      }
    }
  };

  destroy = () => {};
}
