import { observer } from "mobx-react-lite";
import useAppState from "src/state";
import { TabsStats } from "../style";
import { Graphs } from "./Graphs";
import { NativeGraph } from "./NativeGraph";
import * as styles from "./style";

export const Charts = observer(({ showTabs, token, asset }) => {
  const { DEXStatsState: state } = useAppState();

  if (showTabs) {
    return (
      <styles.GraphsContainer>
        <TabsStats
          menuItems={[
            { label: "Balances", value: "BALANCES" },
            { label: "Native", value: "NATIVE" },
          ]}
          defaultKey="BALANCES"
        >
          <Graphs
            key="BALANCES"
            assetLabel={asset}
            tokenLabel={token}
            data={state.graphicsData}
            update={state.updateBalances}
            showLoader={state.showLoader}
          />
          <NativeGraph key="NATIVE" data={state.graphicsData} />
        </TabsStats>
      </styles.GraphsContainer>
    );
  }
  return (
    <styles.GraphsContainer>
      <Graphs
        assetLabel={asset}
        tokenLabel={token}
        data={state.graphicsData}
        update={state.updateBalances}
        showLoader={state.showLoader}
      />
      <NativeGraph data={state.graphicsData} showLoader={state.showLoader} />
    </styles.GraphsContainer>
  );
});
