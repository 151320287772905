import { useMemo } from "react";
import { LinearProgress } from "src/components/shared/Progress";
import { formatElapsedTime, unixToSinceFormat } from "src/helpers/dateUtils";
import { formatPercent } from "src/helpers/string";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import { PartyExchange } from "src/state/CEX/CEXDashboard/KPIStore";
import { KPICard, KPICardProps } from "../../shared/KPICard";
import * as styles from "./style";

const getIndexImpactInfoText = (party: string) =>
  `Gotbit impact index indicates what share Gotbit holds in the ${party} markets. It takes into account trading volume, number of trades and provided liquidity.`;

const getSinceTimeText = (sinceTime: number) => {
  const formattedSinceTime = unixToSinceFormat(sinceTime);
  const formattedElapsedTime = formatElapsedTime(sinceTime);
  return `${formattedSinceTime} / ${formattedElapsedTime}`;
};

const useSinceDateText = (sinceTime: number) => {
  const sinceTimeText = useMemo(() => (sinceTime ? getSinceTimeText(sinceTime) : "-"), [sinceTime]);

  return sinceTimeText;
};

const useExchangesTimeText = (exchanges: PartyExchange[]) => {
  const exchangesText = useMemo(
    () =>
      exchanges
        .map(({ name, workingSince }) => {
          const formattedSinceTime = getSinceTimeText(workingSince);
          return `${name} - ${formattedSinceTime}`;
        })
        .join("\n"),
    [exchanges]
  );
  return exchangesText;
};

export interface ImpactCardProps
  extends Omit<KPICardProps, "title" | "caption" | "subtitle" | "kpi"> {
  sinceDate: number;
  impact: string;
  exchanges: PartyExchange[];
  party?: string;
}

export const ImpactCard = ({
  sinceDate,
  impact,
  exchanges,
  party = "party",
  ...props
}: ImpactCardProps) => {
  const progressPercent = +impact;
  const impactPercent = formatPercent(impact);

  const impactInfoText = getIndexImpactInfoText(party);

  const sinceDateText = useSinceDateText(sinceDate);

  const exchangesText = useExchangesTimeText(exchanges);

  return (
    <KPICard
      title="Gotbit impact index:"
      info={impactInfoText}
      titleIcon={<CEXDashboardIcons.Percent />}
      subtitle="Working with Gotbit since"
      subtitleInfo={exchangesText}
      caption={sinceDateText}
      kpi={
        <styles.KPIWrapper>
          <styles.KPILabel>{impactPercent}</styles.KPILabel>
          <LinearProgress value={progressPercent} />
        </styles.KPIWrapper>
      }
      {...props}
    />
  );
};
