import { mobileMediaQuery } from "src/components/UserManagement/Teams/style";
import styled from "styled-components";
import { DashboardBreakpoints } from "../../views/SummaryView/style";
import { ExchangesSelector } from "../DesktopHeader/ExchangesSelector";
import {
  PeriodSwitchButton,
  PeriodSwitchButtonProps,
} from "../DesktopHeader/TimeRangePicker/PeriodSwitchButton";
import * as Desktop from "../DesktopHeader/style";

export const Container = styled(Desktop.Container)``;

export const TitleRow = styled(Desktop.TitleRow)``;

export const Title = styled(Desktop.Title)``;

export const PartyText = styled(Desktop.PartyText)``;

export const FiltersRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const PickerButtonsRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const PeriodButton = styled(PeriodSwitchButton).attrs<{}, PeriodSwitchButtonProps>({
  stretched: true,
})``;

export const ViewSwitch = styled(Desktop.ViewSwitch)`
  max-width: 100%;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DrawerExchangesSelector = styled(ExchangesSelector)`
  @media ${mobileMediaQuery(DashboardBreakpoints.Mobile)} {
    max-width: 100%;
  }
`;
