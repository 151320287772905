import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable } from "src/helpers/utils";
import { PairTickers } from "src/state/DEXV2/shared/TradePair";
import { generateExchange, generateRandomBoolean } from "./AccountBalancesStore";
import { generateTokenTicker } from "./FundingStore";

export type PairsData = {
  exchange: string;
  pair: PairTickers;
  priceUSD: string;
  priceChange: string;
  volume: string;
  volumeShare: string;
  liquidity: string;
  indexImpact: string;
};

const generatePairTickers = (): PairTickers => ({
  base: generateTokenTicker(),
  quote: generateTokenTicker(),
});

const generatePriceUSD = () => faker.commerce.price({ min: 0, max: 100_000 });

export const generatePercent = () => {
  const percent = faker.number.float({ min: 0, max: 1, precision: 0.0001 });
  return `${percent}`;
};

export const generatePercentChange = () => {
  const isPositive = generateRandomBoolean();
  const percent = generatePercent();
  return isPositive ? `${percent}` : `-${percent}`;
};

const generateVolume = () => faker.commerce.price({ min: 0, max: 10_000_000 });

const generateLiquidity = () => generateVolume();

const generatePair = (): PairsData => ({
  exchange: generateExchange(),
  pair: generatePairTickers(),
  priceUSD: generatePriceUSD(),
  priceChange: generatePercentChange(),
  volume: generateVolume(),
  volumeShare: generatePercent(),
  liquidity: generateLiquidity(),
  indexImpact: generatePercent(),
});

const generatePairs = (): PairsData[] => {
  const pairs = faker.helpers.multiple(generatePair, {
    count: { min: 5, max: 10 },
  });
  return pairs;
};

export type PairsDataKeys = keyof PairsData;

export default class PairsStore implements Disposable {
  private _data: PairsData[] = [];

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: PairsData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  getPairs = () => {
    try {
      const pairs = generatePairs();
      this._setData(pairs);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
