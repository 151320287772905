import { AuxiliaryModuleText } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;
`;

export const ValueText = styled(AuxiliaryModuleText)`
  font-weight: bold;
`;
