import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { toast } from "react-toastify";
import { addParties } from "src/api/userManager/partiesAPI";
import { getUserGroup } from "src/api/userManager/userGroupsAPI";
import {
  getData,
  getPathAndKey,
  getTargetValueByPath,
  getValueByPath,
  setData,
} from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { FormDataKeys, FormErrors, FormHandlers, FormValidation } from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { Disposable, Extends } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { GroupType, NewParty, UserGroup } from "src/modules/userManager";
import { required, textFormat, validateData } from "src/validation-schemas";
import { SelectorHandler } from "../UserGroups/AddUserGroupStore";
import { ScopesUpdate } from "./ScopesStore";

type NewPartyForm = NewParty;

type NewPartyFormKeys = FormDataKeys<NewPartyForm>;

type FormSelectors = Extends<NewPartyFormKeys, "admin_group" | "client_group" | "admin">;

export interface NewPartyProvider extends ScopesUpdate {
  userGroupsByType: (type: GroupType) => UserGroup[];
}

const INITIAL_PARTY: NewPartyForm = {
  party: "",
  admin_group: "",
  admin: "",
  scopes: [],
};

const ERROR_PARTY_MESSAGE = `The party name must be at least 5 and not more than 10 characters.
You can only enter Latin letters using _ - as well as numbers.`;

export default class AddPartyStore implements Disposable {
  private _validation: FormValidation<NewPartyForm> = {
    party: [
      required(),
      textFormat(ERROR_PARTY_MESSAGE, /^[a-zA-Z0-9][a-zA-Z0-9_-]{0,8}[a-zA-Z0-9]$/),
    ],
    scopes: required(),
    admin_group: required(),
    admin: required(),
  };

  private _provider: NewPartyProvider;

  private _isShownReaction: IReactionDisposer;

  private _adminGroupChangedReaction: IReactionDisposer;

  private _adminUserNames: string[] = [];

  handlers: FormHandlers<NewPartyForm> = {};

  errors: FormErrors<NewPartyForm> = {};

  isLoading = false;

  newParty: NewPartyForm = INITIAL_PARTY;

  isShown: boolean = false;

  constructor(provider: NewPartyProvider) {
    this._provider = provider;

    makeAutoObservable(this, {
      selectorEnabled: false,
      selectorOptions: false,
      selectorValue: false,
    });

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );

    this._adminGroupChangedReaction = reaction(
      () => this.newParty.admin_group,
      (admin_group) => {
        if (!admin_group) {
          this._setAdminUserNames([]);
        } else {
          this._getAdminUserGroupUsers(admin_group);
        }
      }
    );
  }

  _setIsModalShow(isShown: boolean) {
    this.isShown = isShown;
  }

  private _setModalShow = (isShown: boolean, scopeId?: number) => {
    this._setIsModalShow(isShown);
    this.newParty.scopes = scopeId ? [scopeId] : [];
  };

  openModal = (scopeId: number) => {
    this._setModalShow(true, scopeId);
  };

  closeModal = () => {
    this._setModalShow(false);
  };

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this.newParty = INITIAL_PARTY;
  };

  private _setAdminUserNames = (adminUserNames: string[]) => {
    this._adminUserNames = adminUserNames;
  };

  private get _adminGroups() {
    return this._provider.userGroupsByType("admin");
  }

  getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "admin_group": {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value));
          this._setData("admin", "");
        };
      }
      default: {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value));
        };
      }
    }
  };

  selectorEnabled = computedFn((key: Extends<FormSelectors, "admin">) => {
    switch (key) {
      case "admin": {
        return this.newParty.admin_group && this.selectorOptions("admin").length;
      }
    }
  });

  selectorValue = computedFn((key: FormSelectors): SelectorValue => {
    const value = this._getData(key) ?? "";
    return stringToSelectorValue(value);
  });

  selectorOptions = computedFn((key: FormSelectors): SelectorValue[] => {
    switch (key) {
      case "client_group": {
        return getSelectorList(
          this._provider.userGroupsByType("client").map((group) => group.name)
        );
      }
      case "admin_group": {
        return getSelectorList(this._adminGroups.map((group) => group.name));
      }
      case "admin": {
        return getSelectorList(this._adminUserNames);
      }
    }
  });

  private _setData = <K extends NewPartyFormKeys>(
    key: K,
    value: ObjectPathValue<NewPartyForm, K>
  ) => {
    setData(this.newParty, key, value);
  };

  private _getData = <K extends NewPartyFormKeys>(key: K) => getData(this.newParty, key);

  getHandler = (key: NewPartyFormKeys) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newParty, path);

      switch (key) {
        default: {
          this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
            targetData[endKey] = getChangeEventValue(e);
          };
        }
      }
    }

    return this.handlers[key];
  };

  getError = (key: NewPartyFormKeys) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this._validation, this.newParty, this.errors, validateKeys);

  private _getAdminUserGroupUsers = async (groupName: string) => {
    try {
      const { data, isError } = await getUserGroup(groupName);

      if (!isError) {
        this._setAdminUserNames(data.users);
      } else {
        this._setAdminUserNames([]);
      }
    } catch {
      this._setAdminUserNames([]);
    }
  };

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      try {
        const { isError } = await addParties(this.newParty as NewParty);

        if (!isError) {
          toast.success("Party created successfully", {
            autoClose: 2000,
          });
          this._setIsModalShow(false);
          this._provider.getScopesList();
          this._provider.getPartiesList();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  destroy() {
    this._isShownReaction();
    this._adminGroupChangedReaction();
  }
}
