import { Chart, ChartType, Chart as IChart, TooltipItem } from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { Chart as ChartComponent } from "react-chartjs-2";
import { isInRange } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { roundSingleValue, roundToExp, roundToSignificant } from "src/helpers/rounding";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useChartZoom } from "../../../hooks/useChartZoom";
import { useDashboardColors } from "../../../hooks/useDashboardColors";
import {
  BASE_TIME_CONFIG,
  useBaseTooltip,
  useGridOptions,
  usePlaceholderOptions,
} from "../../../shared/config";
import { ChartProps } from "../../../shared/types";
import { getSeriesLabel, getSeriesTotal } from "../../../shared/utils";

const getPriceText = <TType extends ChartType>({ raw }: TooltipItem<TType>) => {
  const price = raw as number;
  const priceText = roundSingleValue(+price);
  return priceText;
};

const defaultLabelCallback = Chart.defaults.plugins.tooltip.callbacks.label;

const MIN_PRICE_RANGE = 10 ** -2;
const MAX_PRICE_RANGE = 10 ** 3;

const formatPriceTick = (value: number, priceRange?: number) => {
  if (
    priceRange !== undefined &&
    isInRange(priceRange, MIN_PRICE_RANGE, MAX_PRICE_RANGE, {
      minInclusive: true,
      maxInclusive: true,
    })
  ) {
    return roundToSignificant(value, 3);
  }
  return roundToExp(value, 2);
};

interface UseBaseOptionsParams {
  priceRange?: number;
}

const useBaseOptions = ({ priceRange }: UseBaseOptionsParams) => {
  const { textSubhead, textAdditional, borderDefault, textSecondary } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const options = useMemo(
    (): ChartProps<"bar" | "line", number[], number>["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: { left: 8, right: 8, bottom: 8 } },
      scales: {
        x: {
          type: "time",
          ...BASE_TIME_CONFIG,
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",

          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
            color: borderDefault,
            borderColor: borderDefault,
          },
          ticks: {
            color: textSecondary,
            callback(value) {
              return formatPriceTick(value as number, priceRange);
            },
          },
        },
      },
      plugins: {
        tooltip: {
          ...tooltipOptions,
          mode: "index",
          displayColors: true,
          itemSort(a) {
            return a.dataset.type !== "line" ? -1 : 1;
          },
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSubhead,
          footerFont: {
            size: 8,
            weight: "500",
          },
          multiKeyBackground: "rgba(0,0,0,0)",
          callbacks: {
            label(ctx) {
              if (ctx.dataset.type !== "line") {
                return defaultLabelCallback.apply(this, [ctx]);
              }

              const title = ctx.dataset.label || "";
              const value = getPriceText(ctx);

              return getSeriesLabel(title, value);
            },
            footer() {
              const totalText = getSeriesTotal(this);

              return `Total: ${totalText}`;
            },
            labelColor(item) {
              const color = (item.dataset.backgroundColor as string) ?? "#fff";
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: color,
                borderWidth: 100,
                borderRadius: 3,
              };
            },
          },
        },
        legend: {
          position: "top",
          align: "end",
          // maxHeight: 20,
          labels: {
            pointStyle: "circle",
            usePointStyle: true,
            boxHeight: 4,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
            filter(tooltipItem, data) {
              const datasetIndex = tooltipItem.datasetIndex ?? 0;
              const dataset = data.datasets[datasetIndex];
              return !dataset.hidden;
            },
          },
        },
      },
    }),
    [borderDefault, priceRange, textAdditional, textSecondary, textSubhead, tooltipOptions]
  );

  return options;
};

export interface LiquidityBarProps
  extends Omit<ChartProps<"bar" | "line", number[], number>, "type"> {
  zoomDefault?: boolean;
  priceRange?: number;
}

export const LiquidityBar = observer(
  ({ options: inOptions, zoomDefault, priceRange, ...props }: LiquidityBarProps) => {
    const chartRef = useRef<IChart<any, any, any>>(null);

    const baseOptions = useBaseOptions({ priceRange });

    const placeholderOptions = usePlaceholderOptions();

    const zoomOptions = useChartZoom({
      chartRef,
      shouldZoom: zoomDefault,
      data: props.data,
    });

    const gridOptions = useGridOptions();

    const options = useMemo(
      () => deepMerge(placeholderOptions, gridOptions, baseOptions, zoomOptions, inOptions),
      [baseOptions, gridOptions, inOptions, placeholderOptions, zoomOptions]
    );

    return (
      <ChartComponent type="bar" options={options} plugins={[Zoom, ChartPlaceholder]} {...props} />
    );
  }
);
