import { makeAutoObservable } from "mobx";
import {
  addWatchList,
  deleteWatchList,
  getBases,
  getBotStatuses,
  getChains,
  getExchanges,
  getWatchList,
} from "src/api/bots/DEXV2/bots";
import { getParties } from "src/api/userManager/partiesAPI";
import { getSelectorList } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Comparators } from "src/helpers/sorting";
import { Disposable } from "src/helpers/utils";
import { DEXV2Bot, DEXV2ExchangeVersion } from "src/modules/bots";
import { IChainMetaProvider } from "src/state/chain/ChainMetaStore";
import { IChainsInfo } from "src/state/chain/ChainsInfoStore";
import {
  BotsListSelectorValue,
  IBotsFilter,
  IBotsSelectors,
  IRecentPartiesProvider,
} from "../../CEX/CEXBots/CEXBots";
import RecentPartiesFilterStore, {
  IRecentPartiesFilter,
} from "../../CEX/CEXBots/RecentPartiesFilterStore";
import RecentlyAddedFilterStore from "../../CEX/CEXBots/RecentlyAddedFilterStore";
import { DEXVersionOptions, DEX_VERSION_OPTIONS } from "../DEXV2Create/Create";
import DEXV2BotsCacheProvider, {
  IDEXV2BotsCacheProvider,
} from "../Providers/DEXV2BotsCacheProvider";
import DEXV2BotStatusFilterStore, { IDEXV2BotStatusFilter } from "./DEXV2BotFilterStore";
import {
  DEXV2BotToListDEXV2Bot,
  DEXV2BotsColorStatus,
  ListDEXV2Bot,
  botStatusResponseToDEXV2Bot,
} from "./DEXV2BotInfoStore";

const BOTS_FETCHING_INTERVAL = 5000;

export interface IDEXV2BotsProvider {
  get allBots(): ListDEXV2Bot[];
}

export default class DEXV2BotsState
  implements
    IDEXV2BotsProvider,
    IRecentPartiesProvider,
    IBotsFilter,
    IBotsSelectors<true>,
    Disposable
{
  private _bots: ListDEXV2Bot[] = [];

  private _selectedBase: string[] = [];

  private _selectedSwaps: string[] = [];

  private _selectedParties: string[] = [];

  private _selectedChains: string[] = [];

  private _selectedDEXVersions: DEXV2ExchangeVersion[] = [];

  private _allBases: string[] = [];

  private _allExchanges: string[] = [];

  private _allParties: string[] = [];

  private _allChains: number[] = [];

  private _intervalHandler?: ReturnType<typeof setInterval>;

  private _recentlyAddedFilter: RecentlyAddedFilterStore;

  private _recentPartiesFilter: IRecentPartiesFilter;

  private _blueStatusFilter: IDEXV2BotStatusFilter;

  private _yellowStatusFilter: IDEXV2BotStatusFilter;

  private _redStatusFilter: IDEXV2BotStatusFilter;

  private _firstLoad = true;

  private _loading = false;

  watchListEnabled = localStorage.getItem("watchListEnabled") === "true";

  private _chainsInfo: IChainsInfo;

  private _chainMetaProvider: IChainMetaProvider;

  private _cacheProvider: DEXV2BotsCacheProvider;

  constructor(chainsInfo: IChainsInfo, chainMetaProvider: IChainMetaProvider) {
    makeAutoObservable(this);

    this._recentlyAddedFilter = new RecentlyAddedFilterStore();
    this._recentPartiesFilter = new RecentPartiesFilterStore();

    this._blueStatusFilter = new DEXV2BotStatusFilterStore(this, DEXV2BotsColorStatus.Blue);

    this._yellowStatusFilter = new DEXV2BotStatusFilterStore(this, DEXV2BotsColorStatus.Yellow);

    this._redStatusFilter = new DEXV2BotStatusFilterStore(this, DEXV2BotsColorStatus.Red);

    this._chainsInfo = chainsInfo;

    this._chainMetaProvider = chainMetaProvider;

    this._cacheProvider = new DEXV2BotsCacheProvider();

    makeLoggable<any>(this, {
      bots: true,
      _selectedChains: true,
      selectedChains: true,
      _allChainSelectorValues: true,
    });
  }

  chainsEnabled = true as const;

  dexVersionsEnabled = true as const;

  get cacheProvider(): IDEXV2BotsCacheProvider {
    return this._cacheProvider;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private _setFirstLoad = (loading: boolean) => {
    this._firstLoad = loading;
  };

  private _resetLoading = () => {
    this._setLoading(false);
    this._setFirstLoad(false);
  };

  get firstLoad() {
    return this._firstLoad;
  }

  private _setBots = (bots: DEXV2Bot[], watchList: string[]) => {
    const watchListSet = new Set(watchList);

    const listBots = bots.map((bot) => {
      const listBot = DEXV2BotToListDEXV2Bot(bot, this._chainMetaProvider.chainMetaMap);
      listBot.isWatched = watchListSet.has(listBot.bot_uuid);
      return listBot;
    });

    this._bots = listBots;
  };

  private _setAllBases = (bases: string[]) => {
    this._allBases = bases;
  };

  get allBase() {
    const selectorValues = getSelectorList(this._allBases);
    return this._sortSelectorValue(selectorValues);
  }

  private _setAllExchanges = (exchanges: string[]) => {
    this._allExchanges = exchanges;
  };

  get allExchanges() {
    const selectorValues = getSelectorList(this._allExchanges);
    return this._sortSelectorValue(selectorValues);
  }

  private _setAllParties = (parties: string[]) => {
    this._allParties = parties;
  };

  get allParties() {
    const selectorValues = getSelectorList(this._allParties);
    return this._sortSelectorValue(selectorValues);
  }

  private _setAllChains = (chains: number[]) => {
    this._allChains = chains;
  };

  private get _allChainSelectorValues() {
    return this._allChains
      .map((chainId) => {
        const chainInfo = this._chainsInfo.chains[chainId];
        if (!chainInfo) return null;
        const { id, name } = chainInfo;
        return { value: id, label: name };
      })
      .filter(Boolean) as BotsListSelectorValue[];
  }

  get allChains() {
    return this._sortSelectorValue(this._allChainSelectorValues);
  }

  get allVersions() {
    return DEX_VERSION_OPTIONS;
  }

  setSelectedBases = (options: readonly BotsListSelectorValue[]) => {
    this._selectedBase = options.map(({ value }) => value);
  };

  get selectedBase() {
    return getSelectorList(this._selectedBase);
  }

  setSelectedExchanges = (options: readonly BotsListSelectorValue[]) => {
    this._selectedSwaps = options.map(({ value }) => value);
  };

  get selectedExchanges() {
    return getSelectorList(this._selectedSwaps);
  }

  setSelectedParties = (options: readonly BotsListSelectorValue[]) => {
    this._selectedParties = options.map(({ value }) => value);
  };

  get selectedParties() {
    return getSelectorList(this._selectedParties);
  }

  setSelectedChains = (options: readonly BotsListSelectorValue[]) => {
    this._selectedChains = options.map(({ value }) => value);
  };

  get selectedChains() {
    return this._allChainSelectorValues.filter(({ value }) => this._selectedChains.includes(value));
  }

  setSelectedVersions = (options: readonly BotsListSelectorValue[]) => {
    const versionsOptions = options as DEXVersionOptions[];
    this._selectedDEXVersions = versionsOptions.map(({ value }) => value);
  };

  get selectedVersions() {
    return this.allVersions.filter(({ value }) => this._selectedDEXVersions.includes(value));
  }

  private _sortSelectorValue = (values: BotsListSelectorValue[]) =>
    values.slice().sort((that, other) => Comparators.String(that.value, other.value));

  get allBots() {
    return this._bots;
  }

  get bots() {
    return this._bots.filter(
      (el) =>
        this._baseFilter(el) &&
        this._swapsFilter(el) &&
        this._partiesFilter(el) &&
        this._chainsFilter(el) &&
        this._versionsFilter(el) &&
        this._watchListFilter(el) &&
        this._statusFilter(el) &&
        // this._recentlyAddedFilter.switchableFilter(el) &&
        this._recentPartiesFilter.filter(el)
    );
  }

  get totalBotsCount(): number {
    return this._bots.length;
  }

  get redCount(): number {
    return this._redStatusFilter.count;
  }

  get redStatus(): boolean {
    return this._redStatusFilter.isEnabled;
  }

  get blueCount(): number {
    return this._blueStatusFilter.count;
  }

  get blueStatus(): boolean {
    return this._blueStatusFilter.isEnabled;
  }

  get yellowCount(): number {
    return this._yellowStatusFilter.count;
  }

  get yellowStatus(): boolean {
    return this._yellowStatusFilter.isEnabled;
  }

  get recentlyAddedEnabled() {
    return this._recentlyAddedFilter.isEnabled;
  }

  get recentlyAddedCount() {
    return 0;
    // return this._bots.filter(this._recentlyAddedFilter.filter).length;
  }

  get recentParties() {
    return this._recentPartiesFilter.recentParties;
  }

  togglePartySelection = (name: string) => this._recentPartiesFilter.togglePartySelection(name);

  toggleWatchList = () => {
    this.watchListEnabled = !this.watchListEnabled;
    localStorage.setItem("watchListEnabled", String(this.watchListEnabled));
  };

  removeBaseSelected = (item: string) => {
    this._selectedBase = this._selectedBase.filter((value) => value !== item);
  };

  removeExchSelected = (item: string) => {
    this._selectedSwaps = this._selectedSwaps.filter((value) => value !== item);
  };

  removePartiesSelected = (item: string) => {
    this._selectedParties = this._selectedParties.filter((value) => value !== item);
  };

  removeSelectedChain = (item: string) => {
    this._selectedChains = this._selectedChains.filter((value) => value !== item);
  };

  removeSelectedVersion = (item: string) => {
    const version = item as DEXV2ExchangeVersion;
    this._selectedDEXVersions = this._selectedDEXVersions.filter((value) => value !== version);
  };

  private _watchListFilter = ({ isWatched }: ListDEXV2Bot) => !this.watchListEnabled || isWatched;

  private _toggleIsWatched = (bot: ListDEXV2Bot) => {
    // eslint-disable-next-line no-param-reassign
    bot.isWatched = !bot.isWatched;
  };

  toggleIsWatched = async (bot_uuid: string) => {
    this._setLoading(true);

    const bot = this._getBotById(bot_uuid);
    if (!bot) return;

    const currentWatched = bot.isWatched;

    const updateWatchList = currentWatched ? deleteWatchList : addWatchList;

    try {
      this._toggleIsWatched(bot);

      const { isError } = await updateWatchList([bot.bot_uuid]);
      if (isError) {
        this._toggleIsWatched(bot);
      }
    } catch (err) {
      this._toggleIsWatched(bot);
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _getBotById = (bot_uuid: string) => this._bots.find((item) => item.bot_uuid === bot_uuid);

  private _getBases = async () => {
    const { isError, data } = await getBases();

    if (!isError) {
      this._setAllBases(data);
    }
  };

  private _getChainsInfo = async () => {
    await this._chainsInfo.getChainsInfo();
  };

  private _getExchanges = async () => {
    const { isError, data } = await getExchanges();

    if (!isError) {
      this._setAllExchanges(data);
    }
  };

  private _getParties = async () => {
    const { data: allParties } = await getParties();

    this._setAllParties(allParties);
  };

  private _getChains = async () => {
    const { isError, data } = await getChains();

    if (!isError) {
      this._setAllChains(data);
    }
  };

  private _getWatchList = async () => {
    try {
      const { isError, data } = await getWatchList();

      if (!isError) {
        return data.bots;
      }
      return [];
    } catch {
      return [];
    }
  };

  private _getBotsWatched = async () => {
    try {
      const watchList = await this._getWatchList();

      const { isError, data } = await getBotStatuses();

      if (!isError) {
        const bots = data.map(botStatusResponseToDEXV2Bot);
        this._setBots(bots, watchList);
      }
    } catch (err) {
      logError(err);
    }
  };

  private _fetchAllBots = async () => {
    this._setLoading(true);
    try {
      await Promise.all([
        this._getBotsWatched(),
        this._getBases(),
        this._getExchanges(),
        this._getParties(),
        this._getChains(),
        this._getChainsInfo(),
      ]);

      this._resetLoading();
    } catch (err) {
      logError(err);
      this._resetLoading();
    }
  };

  resumeBotsFetching = () => {
    this._fetchAllBots();
    this._intervalHandler = setInterval(() => this._fetchAllBots(), BOTS_FETCHING_INTERVAL);
  };

  suspendBotsFetching = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  private _baseFilter = ({ base }: ListDEXV2Bot) =>
    this._selectedBase.length === 0 || this._selectedBase.includes(base);

  private _swapsFilter = ({ exchange }: ListDEXV2Bot) =>
    this._selectedSwaps.length === 0 || this._selectedSwaps.includes(exchange);

  private _partiesFilter = ({ party }: ListDEXV2Bot) =>
    this._selectedParties.length === 0 || this._selectedParties.includes(party);

  private _chainsFilter = ({ chain_id }: ListDEXV2Bot) => {
    const chainId = `${chain_id}`;
    return this._selectedChains.length === 0 || this._selectedChains.includes(chainId);
  };

  private _versionsFilter = ({ dex_version }: ListDEXV2Bot) =>
    this._selectedDEXVersions.length === 0 || this._selectedDEXVersions.includes(dex_version);

  private _statusFilter = (bot: ListDEXV2Bot) => {
    const allFiltersDisabled =
      !this._redStatusFilter.isEnabled &&
      !this._yellowStatusFilter.isEnabled &&
      !this._blueStatusFilter.isEnabled;

    return (
      allFiltersDisabled ||
      this._redStatusFilter.filter(bot) ||
      this._yellowStatusFilter.filter(bot) ||
      this._blueStatusFilter.filter(bot)
    );
  };

  toggleRedStatus = () => {
    this._redStatusFilter.toggleEnabled();
  };

  toggleBlueStatus = () => {
    this._blueStatusFilter.toggleEnabled();
  };

  toggleYellowStatus = () => {
    this._yellowStatusFilter.toggleEnabled();
  };

  toggleRecentlyAddedEnabled = () => {
    this._recentlyAddedFilter.toggleEnabled();
  };

  destroy = () => {
    this._cacheProvider.destroy();
  };
}
