import { observer } from "mobx-react-lite";
import AllBotsContext from "src/context/AllBots";
import { BotsType } from "src/modules/bots";
import useAppState from "src/state";
import { ErrorBoundary } from "../shared/ErrorBoundary";
import { Aside } from "./Aside";
import { CEXBots } from "./Bots/CEXBots";
import { DEXBots } from "./Bots/DEXBots";
import { DEXV2Bots } from "./Bots/DEXV2Bots";
import * as styles from "./style";

const getBotsByType = (type: BotsType) => {
  switch (type) {
    case "CEX": {
      return <CEXBots />;
    }
    case "DEX": {
      return <DEXBots />;
    }
    case "DEXV2": {
      return <DEXV2Bots />;
    }
  }
};

export const AllBots = observer(() => {
  const { allBotsState: state } = useAppState();

  return (
    <styles.Container>
      <Aside />

      <ErrorBoundary fallback={<styles.MainFallback />}>
        <styles.Main>
          <AllBotsContext.Provider value={state}>
            {getBotsByType(state.currentTab)}
          </AllBotsContext.Provider>
        </styles.Main>
      </ErrorBoundary>
    </styles.Container>
  );
});
