import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { formatDecimalPercent } from "src/helpers/string";
import { Disposable, delay } from "src/helpers/utils";
import { generateAmount } from "./FundingStore";
import { generatePercent } from "./PairsStore";

type TotalVolumeData = {
  total: string;
  our: string;
};

const INITIAL_DATA: TotalVolumeData = {
  total: "",
  our: "",
};

const generateTotalVolumeData = (): TotalVolumeData => {
  const total = generateAmount();
  const percent = generatePercent();
  const our = (+total * +percent).toString();

  return {
    total,
    our,
  };
};

export default class TotalVolumeStore implements Disposable {
  private _data: TotalVolumeData = INITIAL_DATA;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { our: true });
  }

  private _setData = (data: TotalVolumeData) => {
    this._data = data;
  };

  get our() {
    return this._data.our;
  }

  get total() {
    return this._data.total;
  }

  get ourPercent() {
    const percent = +this._data.our / +this._data.total;

    if (Number.isNaN(percent)) {
      return "0";
    }

    return formatDecimalPercent(percent, false);
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  getTotalVolume = async () => {
    this._setLoading(true);
    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateTotalVolumeData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
