import dayjs from "dayjs";
import { useState } from "react";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { FiatBalanceChart } from "./FiatBalanceChart";
import { TokensBalancesChart } from "./TokensBalancesChart";
import * as styles from "./style";

export interface BalancesChartsProps {}

export const BalancesCharts = (props: BalancesChartsProps) => {
  const [range, setRange] = useState<DateTimeRange>([dayjs.utc().subtract(1, "week"), dayjs.utc()]);

  const rangeProps = { onRangeUpdate: setRange };

  return (
    <styles.Container>
      <DateTimeRangePickerSelector onChange={setRange} value={range} />
      <FiatBalanceChart style={{ width: "100%", height: "270px" }} {...rangeProps} />
      <TokensBalancesChart style={{ width: "100%", height: "270px" }} {...rangeProps} />
    </styles.Container>
  );
};
