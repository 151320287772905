import { reaction } from "mobx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { ScopesContext } from "src/context/UserManager/Scopes";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import useAppState from "src/state";
import { ScopeSelection } from "src/state/UserManager";
import WindowConsent from "src/state/WindowConsent";
import { SelectItem } from "../../../shared";
import { ExpandButton } from "../ExpandButton";
import * as styles from "../shared";

interface ScopeItemProps {
  scope: ScopeSelection;
  childrenID: number[];
  hideEditPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedParty: React.Dispatch<React.SetStateAction<string>>;
  parentLeftPosition: number;
}

export const ScopeItem = ({
  scope: { selected, model: scope, parties },
  childrenID,
  hideEditPanel,
  setEditedParty,
  parentLeftPosition,
}: ScopeItemProps) => {
  const { UserManagerState: state } = useAppState();

  const scopeItem = useRef<HTMLDivElement>(null);

  const [leftPosition, setLeftPosition] = useState(0);

  const [width, setWidth] = useState(0);

  const updLeftPosition = useCallback(
    (position: number) => {
      setLeftPosition(position);
    },
    [setLeftPosition]
  );

  const calcPositions = useCallback(() => {
    if (parentLeftPosition) {
      setWidth(leftPosition - parentLeftPosition);
    } else setWidth(0);
  }, [parentLeftPosition, leftPosition]);

  const cb = useCallback(() => {
    if (scopeItem.current !== null) {
      updLeftPosition(scopeItem.current.getBoundingClientRect().left);
    }

    calcPositions();
  }, [calcPositions, updLeftPosition]);

  useEffect(() => {
    cb();
  }, [cb, updLeftPosition, calcPositions]);

  const {
    scopesState: { addScopeState, addPartyState, editScopeState },
  } = useLateInitContext(ScopesContext);

  const [hide, setHide] = useState(true);

  const toggleVisibility = useCallback(() => {
    setHide(!hide);
  }, [hide]);

  useEffect(
    () =>
      reaction(
        () => state.scopesSearchDebouncedText,
        () => {
          setHide(true);
        }
      ),
    [state]
  );

  const onAddScopeModalClick = useCallback(() => {
    addScopeState.openModal(scope.scope_id);
  }, [addScopeState, scope.scope_id]);

  const onAddPartyModalClick = useCallback(() => {
    addPartyState.openModal(scope.scope_id);
  }, [addPartyState, scope.scope_id]);

  const onEditScopeModalClick = useCallback(() => {
    editScopeState.openModal(scope, childrenID);
  }, [childrenID, editScopeState, scope]);

  return (
    <styles.Container>
      {scope.scope_id !== 0 ? (
        <styles.Wrapper>
          <ExpandButton hide={hide} onClick={() => toggleVisibility()} />

          <styles.ScopeItemHeader ref={scopeItem} widthArrow={width} isHighlighted={selected}>
            <SelectItem style={{ width: "100%" }} onClick={onEditScopeModalClick}>
              {scope.name}
            </SelectItem>
          </styles.ScopeItemHeader>

          <IconButton onClick={onAddScopeModalClick}>{Icons.addTeam()}</IconButton>
          <IconButton onClick={onAddPartyModalClick}>{Icons.addParty()}</IconButton>
        </styles.Wrapper>
      ) : (
        <styles.Wrapper onClick={() => toggleVisibility()}>
          <ExpandButton hide={hide} />

          <styles.ScopeGenTop ref={scopeItem}>
            <SelectItem style={{ width: "100%" }}>{scope.name}</SelectItem>
          </styles.ScopeGenTop>
        </styles.Wrapper>
      )}

      <styles.ItemBody>
        {hide ? (
          <styles.ItemsList>
            {childrenID.map((elID) => (
              <ScopeItem
                key={state.scopeGraph[elID].model.scope_id}
                scope={state.scopeGraph[elID]}
                childrenID={state.scopeAdjList[elID]}
                hideEditPanel={hideEditPanel}
                setEditedParty={setEditedParty}
                parentLeftPosition={leftPosition}
              />
            ))}

            {scope.name !== "All"
              ? parties.map((party, index) => {
                  const onPartyRemove = (scope_id: number, party: string) => {
                    WindowConsent.showWindow(
                      "",
                      `Are you sure you want to detach the party:
         ${party} from scope: ${scope.name}`,
                      state.removeParty,
                      { scope_id, party }
                    );
                  };

                  const key = party.name + index;

                  return (
                    <styles.PartyWrapper key={key}>
                      <styles.Party
                        onClick={() => {
                          hideEditPanel(true);
                          setEditedParty(party.name);
                        }}
                        widthArrow={width}
                        isHighlighted={party.selected}
                      >
                        party: {party.name}
                      </styles.Party>

                      <DeleteButton
                        onClick={() => {
                          onPartyRemove(scope.scope_id, party.name);
                        }}
                      />
                    </styles.PartyWrapper>
                  );
                })
              : null}
          </styles.ItemsList>
        ) : null}
      </styles.ItemBody>
    </styles.Container>
  );
};
