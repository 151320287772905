import { roundSingleValue } from "src/helpers/rounding";
import { PairsData, PairsDataKeys } from "src/state/CEX/CEXDashboard/PairsStore";
import { ColumnTotalCb, ColumnsTotalMap } from "src/state/CEX/CEXDashboard/shared/TableTotalizer";

export type PairsTotalMap = ColumnsTotalMap<PairsData, number>;

const getTotalValue = (currentTotal: number, value: string | undefined) => {
  const valueNumber = value ? +value : 0;
  return +roundSingleValue(currentTotal + valueNumber);
};

export const getAllPairsTotal: ColumnTotalCb<PairsData, PairsTotalMap> = (id, row, prevTotal) => {
  const key = id as PairsDataKeys;
  switch (key) {
    case "volume":
    case "liquidity": {
      const currentValue = row[key];
      const currentTotal = prevTotal || 0;

      const total = getTotalValue(currentTotal, currentValue);

      return total;
    }
    default: {
      return prevTotal;
    }
  }
};
