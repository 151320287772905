import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { noOp, noOpAsync } from "src/helpers/utils";
import { BotTransactionFees } from "../..";
import { Slippage } from "../shared/SlippageStore";
import { ISwapWidget } from "../shared/SwapModules/SwapWidget";
import { Field, SwapStateStore } from "../shared/SwapStateStore";
import { ISwapInfo } from "../v2/SwapWidget/SwapInfoStore";

const EMPTY_VAULT: DEXV2Vault = {
  address: "",
  type: "volume",
  base: 0,
  quote: 0,
};

const EMPTY_SWAP_INFO: ISwapInfo = {
  info: { trade: {}, price: {}, [Field.INPUT]: {}, [Field.OUTPUT]: {} },
  calculateInfo: noOpAsync,
};

export class EmptySwapWidget implements ISwapWidget {
  private _swapState = new SwapStateStore();

  get enabled() {
    return false;
  }

  get isRefreshing() {
    return false;
  }

  get isForceRefreshing() {
    return false;
  }

  get swapState() {
    return this._swapState;
  }

  get swapInfoState(): ISwapInfo {
    return EMPTY_SWAP_INFO;
  }

  get currentVault(): DEXV2Vault {
    return EMPTY_VAULT;
  }

  get swapParamsReady(): boolean {
    return false;
  }

  get slippage(): Slippage {
    return { slippage: "" };
  }

  get transactionFees(): BotTransactionFees {
    return { buy: "", sell: "" };
  }

  setSlippage = noOp;

  refreshSwap = noOpAsync;

  forceRefreshSwap = noOpAsync;

  swap = noOpAsync;
}
