import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import useAppState from "src/state";
import { IBotsFilter } from "src/state/CEX/CEXBots/CEXBots";
import { useBotsState } from "../../hooks/useBotsStore";
import { useShowContent } from "../../hooks/useShowContent";
import { BotFilterPanel } from "./BotFilterPanel";
import { BotSelectors } from "./BotSelectors";
import * as styles from "./style";

export const MainFilter = observer(() => {
  const { allBotsState } = useAppState();

  const { currentTab, realtimeEnabled, switchRealtime, switchCurrentTab } = allBotsState;

  const botsState: IBotsFilter = useBotsState();

  const {
    toggleRedStatus,
    redStatus,
    redCount,
    toggleYellowStatus,
    yellowStatus,
    yellowCount,
    toggleBlueStatus,
    blueStatus,
    blueCount,
    totalBotsCount,
    toggleWatchList,
    watchListEnabled,
    toggleRecentlyAddedEnabled,
    recentlyAddedEnabled,
    recentlyAddedCount,
  } = botsState;

  const [showSelectors, toggleShowSelectors] = useShowContent(true);

  return (
    <styles.MainFilterContainer showSelectors={showSelectors}>
      <BotFilterPanel
        currentBots={currentTab}
        toggleBot={switchCurrentTab}
        toggleRedStatus={toggleRedStatus}
        toggleYellowStatus={toggleYellowStatus}
        toggleBlueStatus={toggleBlueStatus}
        redStatus={redStatus}
        yellowStatus={yellowStatus}
        blueStatus={blueStatus}
        redCount={redCount}
        yellowCount={yellowCount}
        blueCount={blueCount}
        totalCount={totalBotsCount}
        toggleWatchList={toggleWatchList}
        watchListEnabled={watchListEnabled}
        toggleRecentlyAddedEnabled={toggleRecentlyAddedEnabled}
        recentlyAddedEnabled={recentlyAddedEnabled}
        recentlyAddedCount={recentlyAddedCount}
      />

      <styles.Content>
        <styles.RealtimeUpdt onClick={toggleShowSelectors}>
          <CheckBox label="Realtime update" checked={realtimeEnabled} onChange={switchRealtime} />

          <styles.Arrow check={showSelectors} />
        </styles.RealtimeUpdt>

        {showSelectors ? <BotSelectors /> : null}
      </styles.Content>
    </styles.MainFilterContainer>
  );
});
