import styled from "styled-components";
import { SwitchTabs, TabContent } from "../../../shared";
import { ModulesPagination } from "../../Conditions/ConditionsTable/style";

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledSwitchTabs = styled(SwitchTabs)`
  min-width: 180px;
`;

export const ModuleContainer = styled(TabContent)`
  ${ModulesPagination} {
    align-self: flex-end;
    width: 200px;
  }
`;
