import { observer } from "mobx-react-lite";
import { TooltipProps } from "src/components/shared/Graph/Tooltip";
import { TooltipDate } from "src/components/shared/Graph/Tooltip/TooltipDate";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { GraphsTooltipData } from "src/state/DEXV2/DEXV2Stats/Graphs";
import { TooltipSeries } from "./TooltipSeries";
import * as styles from "./style";

export interface StatsGraphTooltipProps extends TooltipProps {}

export const StatsGraphTooltip = observer(({ ownRef, ...props }: StatsGraphTooltipProps) => {
  const state = useTooltipContext<GraphsTooltipData>();

  return (
    <styles.TooltipContainer ref={ownRef} {...props}>
      <styles.Content>
        <TooltipDate />
        <styles.SeriesContainer>
          {state.seriesTitles.map((title) => (
            <TooltipSeries title={title} key={title} />
          ))}
        </styles.SeriesContainer>
      </styles.Content>
    </styles.TooltipContainer>
  );
});
