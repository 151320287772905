import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { formatFiat } from "src/helpers/string";
import useLocalStore from "src/hooks/useLocalStore";
import TotalVolumeStore from "src/state/CEX/CEXDashboard/TotalVolumeStore";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { StatsSection } from "./StatsSection";
import { StatsSectionItem } from "./StatsSection/StatsSectionItem";
import * as styles from "./style";

export interface TotalVolumeShareProps extends ComponentPropsWithoutRef<"div"> {}

export const TotalVolumeShare = observer((props: TotalVolumeShareProps) => {
  const { our, ourPercent, total, getTotalVolume, loading } = useLocalStore(TotalVolumeStore);

  useEffect(() => {
    getTotalVolume();
  }, [getTotalVolume]);

  const ourPercentValue = +ourPercent;

  const ourText = formatFiat(our);

  const totalText = formatFiat(total);

  return (
    <>
      <RefreshDataButton onRefresh={getTotalVolume} />
      <styles.Container {...props}>
        <styles.Title>Share of Gotbit volume in total volume</styles.Title>
        <styles.TotalVolumeBar value={ourPercentValue} />
        <StatsSection>
          <StatsSectionItem label="Gotbit" value={ourText} />
          <StatsSectionItem label="Total" value={totalText} />
        </StatsSection>
        <Loader show={loading} />
      </styles.Container>
    </>
  );
});
