import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { PNL } from "src/components/shared/PNL";
import useLocalStore from "src/hooks/useLocalStore";
import { ContentDirection } from "src/modules/shared";
import PnLStore from "src/state/shared/Pnl";

export interface PNLPanelProps {
  party?: string;
  base?: string;
  range: DateTimeRange;
  isShortView?: boolean;
  contentDirection?: ContentDirection;
  setUpdPnLCb?: (key: "updPnL", cb: () => void) => void;
}

export const PnLPanel = observer(
  ({
    party,
    base,
    range,
    isShortView = true,
    contentDirection: direction = "row",
    setUpdPnLCb,
  }: PNLPanelProps) => {
    const state = useLocalStore(PnLStore);

    useEffect(() => {
      if (!party || !base) return;

      state.setParty(party);
      state.setSymbol(base);
    }, [state, party, base]);

    useEffect(() => {
      if (!party || !base) return;

      state.rangeState.setRange(range);
    }, [state, range, party, base]);

    useEffect(() => {
      if (setUpdPnLCb) setUpdPnLCb("updPnL", state.loadData);
    }, [setUpdPnLCb, state]);

    return (
      <PNL
        deltaTotal={state.deltaTotal}
        token={base || ""}
        deltaToken={state.deltaToken}
        price={state.price}
        actualPrice=""
        showLoader={state.isLoading}
        isShortView={isShortView}
        contentDirection={direction}
      />
    );
  }
);
