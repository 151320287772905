import { observer } from "mobx-react-lite";
import { RefObject, useCallback, useContext, useRef } from "react";
import { checkParty } from "src/helpers/checkParty";
import { OmitStrict, noOp } from "src/helpers/utils";
import Icons from "src/icons/Icons";
import useAppState from "src/state";
import { ListDEXV2Bot } from "src/state/DEXV2/DEXV2Bots/DEXV2BotInfoStore";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { ThemeContext } from "styled-components";
import { BotMark } from "../../shared/BotMark";
import { BotPairButton } from "../../shared/BotPairButton";
import { BotContainer, IconWrapper, Line, Name } from "../../shared/shared";
import { IBotCredential, copyBotCredential } from "../../shared/utils";
import { ChainInfoChip } from "../shared/ChainInfoChip";
import { ModeChip, ModeChipProps } from "../shared/ModeChip";
import * as styles from "./style";

export const useBotActionPermissions = (party: string) => {
  const { userState } = useAppState();
  const startButtonPermissions = checkParty(userState.abilities, DEXV2Ability.BotManage, party);
  return startButtonPermissions;
};

interface UseBaseModeChipProps
  extends Pick<DEXV2BotProps, "getInfoContainer" | "showModesInfo" | "bot_uuid"> {
  containerRef?: RefObject<HTMLDivElement>;
}

export const useBaseModeChipProps = ({
  getInfoContainer,
  showModesInfo,
  bot_uuid,
  containerRef,
}: UseBaseModeChipProps) => {
  const baseModeChipProps: Omit<ModeChipProps, "mode"> = {
    botId: bot_uuid,
    showInfo: showModesInfo,
    infoAnchorEl: getInfoContainer?.() ?? containerRef?.current,
  };

  return baseModeChipProps;
};

export interface DEXV2BotOwnProps
  extends OmitStrict<
    ListDEXV2Bot,
    "base" | "quote" | "base_addr" | "quote_addr" | "chain_id" | "status"
  > {
  favorite?: boolean;
  toggleIsWatched?: (bot_uuid: string) => void;
  showStartBtn?: boolean;
  onStartClick?: () => void;
}

export interface DEXV2BotProps
  extends DEXV2BotOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof DEXV2BotOwnProps> {
  credential: IBotCredential;
  showModesInfo?: boolean;
  getInfoContainer?: () => HTMLElement | null;
}

export const DEXV2Bot = observer(
  ({
    favorite = false,
    toggleIsWatched,
    isWatched,
    name,
    bot_uuid,
    pair,
    exchange,
    dex_version,
    link,
    colorStatus,
    showStartBtn,
    isStopped,
    onStartClick = noOp,
    party,
    chainMeta,

    limitLastTrade,
    limitMessage,
    limitNextTrade,
    limitStatus,

    volumeLastTrade,
    volumeMessage,
    volumeNextTrade,
    volumeStatus,

    counterMessage,
    counterStatus,
    counterLastTrade,

    showModesInfo = true,
    getInfoContainer,
    credential,
    ...props
  }: DEXV2BotProps) => {
    const {
      botColorStatus: colors,
      botBgColorStatus: bgColors,
      // botBgSideColorStatus: bgSideColors,
    } = useContext(ThemeContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const startButtonPermissions = useBotActionPermissions(party);

    const showStartButton = showStartBtn && startButtonPermissions;

    const baseModeChipProps = useBaseModeChipProps({
      getInfoContainer,
      showModesInfo,
      bot_uuid,
      containerRef,
    });

    const toggleWatched = useCallback(() => {
      toggleIsWatched?.(bot_uuid);
    }, [bot_uuid, toggleIsWatched]);

    return (
      <BotContainer {...props} ref={containerRef}>
        {favorite ? (
          <styles.Favorites>
            <IconWrapper onClick={toggleWatched}>
              {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
            </IconWrapper>
          </styles.Favorites>
        ) : null}

        <styles.DEXFrame color={colors[colorStatus]}>
          <styles.Content style={{ backgroundColor: bgColors[colorStatus] }}>
            <styles.PairCellContent>
              <BotPairButton to={`/DEX_V2/${bot_uuid}/?tab=STATS`} color={colors[colorStatus]}>
                {pair?.split("_").join(" _ ")}
              </BotPairButton>

              <Name>{name}</Name>
            </styles.PairCellContent>

            <styles.ExchangeCellContent>
              <styles.DEXV2Exchange href={link || "#"} target="_blank">
                {exchange}
              </styles.DEXV2Exchange>
              <styles.DEXV2Version>{dex_version}</styles.DEXV2Version>
            </styles.ExchangeCellContent>

            {chainMeta !== undefined ? (
              <styles.CellContent>
                {chainMeta ? <ChainInfoChip {...chainMeta} /> : <Line />}
              </styles.CellContent>
            ) : null}

            <styles.ModeCellContent>
              <ModeChip
                mode="limit"
                nextTrade={limitNextTrade}
                lastTrade={limitLastTrade}
                message={limitMessage}
                status={limitStatus}
                {...baseModeChipProps}
              />
            </styles.ModeCellContent>

            <styles.ModeCellContent>
              <ModeChip
                mode="volume"
                nextTrade={volumeNextTrade}
                lastTrade={volumeLastTrade}
                message={volumeMessage}
                status={volumeStatus}
                {...baseModeChipProps}
              />
            </styles.ModeCellContent>

            <styles.ModeCellContent>
              <ModeChip
                mode="counter"
                message={counterMessage}
                status={counterStatus}
                lastTrade={counterLastTrade}
                {...baseModeChipProps}
              />
            </styles.ModeCellContent>

            <styles.ButtonCellContent>
              {showStartButton ? (
                <styles.StartButton
                  started={!isStopped}
                  onClick={onStartClick}
                  color={colors[colorStatus]}
                />
              ) : (
                <BotMark
                  onClick={() => copyBotCredential(credential)}
                  color={colors[colorStatus]}
                />
              )}
            </styles.ButtonCellContent>
          </styles.Content>
        </styles.DEXFrame>
      </BotContainer>
    );
  }
);
