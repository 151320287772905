import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import BalancesDynamicsStore from "src/state/CEX/CEXDashboard/BalancesDynamicsStore";

const useBalancesDynamicsStore = () => {
  const state = useLocalStore(BalancesDynamicsStore);
  return state;
};

export const BalancesDynamicsContext = createContextProvider({
  stateCreator: useBalancesDynamicsStore,
});
