import styled from "styled-components";
import { PanelContainer } from "../shared";

interface CellProps {
  isProfit?: boolean;
}

export const StatsContainer = styled(PanelContainer)`
  position: relative;

  /* height: 100%; */

  display: flex;
  align-items: center;

  flex: 1;

  padding: 5px;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const Cell = styled.div<CellProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 5px;

  gap: 5px;

  color: ${({ theme, isProfit }) => {
    if (isProfit !== undefined) {
      return isProfit ? theme.successColor : theme.dangerColor;
    }

    return theme.lightGray;
  }};
`;

export const Label = styled.label`
  white-space: nowrap;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const Value = styled.label`
  display: flex;
  align-items: center;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["13px"]};

  & > svg {
    margin-left: 5px;
  }
`;
