import { useEffect, useState } from "react";
import { ClassConstructor, Disposable } from "src/helpers/utils";

const useDisposableStore = <Store extends Disposable>(store: Store) => {
  useEffect(
    () => () => {
      store.destroy();
    },
    [store]
  );
};

/**
 * Creates local store instance from the class declaration,
 * destroys the store on unmount if available
 *
 * Warning: doesn't support class with overloads
 * and erase generics, use {@link useLocalStoreCreator} for such cases
 * @param storeConstructor - class declaration used to create store instance
 * @param args - parameters of the store class constructor
 * @returns - created store instance
 */
const useLocalStore = <Store extends ClassConstructor<Store, Disposable>>(
  StoreConstructor: Store,
  ...args: ConstructorParameters<Store>
): InstanceType<Store> => {
  const [store] = useState(() => new StoreConstructor(...args));

  useDisposableStore(store);

  return store;
};

export const useLocalStoreCreator = <Store extends Disposable>(
  storeCreator: () => Store
): Store => {
  const [store] = useState(storeCreator);

  useDisposableStore(store);

  return store;
};

export default useLocalStore;
