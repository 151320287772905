import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { BindingsBotProvider } from "src/context/CEX/BindingsBotProvider";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import useAppState from "src/state";
import { Balances } from "../../Balances";
import { BuySellForms } from "../../BuySellForms";
import { ChartPanel } from "../../ChartPanel";
import { OrderBook } from "../../OrderBook";
import { OrderBookFallback } from "../../OrderBook/style";
import { PairChange } from "../../PairChange";
import { PairChangeFallback } from "../../PairChange/style";
import { Settings } from "../../Settings";
import { TerminalPnL } from "../../TerminalPnL";
import { TradePanel } from "../../TradePanel";
import { PNLWrapper } from "../../shared";
import { FullExchangeProps } from "../FullExchange";
import * as styles from "./style";

export interface MobileVersionProps extends FullExchangeProps {
  showTradeHistory: boolean;
}

export const MobileVersion = observer(
  ({ abilityName, showTradeHistory, botInfoProps }: MobileVersionProps) => {
    const { party, bot_uuid, market } = botInfoProps;
    const exchangeState = useLateInitContext(ExchangeContext);
    const { SettingsBotState: settingsState, userState } = useAppState();

    const exchange = exchangeState.exchange ?? "";

    const checkSettings = useMemo(
      () => checkParty(userState.abilities, "cex-settings-view", party),
      [userState.abilities, party]
    );

    const checkTrade = useMemo(
      () => checkParty(userState.abilities, abilityName, party),
      [userState.abilities, party, abilityName]
    );

    useEffect(() => {
      if (!bot_uuid) return;
      if (!market) return;
      settingsState.setBotUUID(bot_uuid);
      settingsState.setMarket(market);
      if (checkSettings) {
        settingsState.getData();
      }
    }, [bot_uuid, settingsState, checkSettings, market]);

    const menuItems = checkSettings
      ? [
          {
            label: `${exchange.toUpperCase()}`,
            value: "EXCHANGE",
          },
          {
            label: `${exchangeState.selectedAccount.toUpperCase()}`,
            value: "ACCOUNT",
          },
          {
            label: `${settingsState.data.settings.pair.dontTradePriceMin}/
        ${settingsState.data.settings.pair.dontTradePriceMax}`,
            value: "SETTINGS",
          },
          {
            label: "ORDERS",
            value: "ORDERS",
          },
        ]
      : [
          {
            label: `${exchange.toUpperCase()}`,
            value: "EXCHANGE",
          },
          {
            label: `${exchangeState.selectedAccount.toUpperCase()}`,
            value: "ACCOUNT",
          },
          {
            label: "ORDERS",
            value: "ORDERS",
          },
        ];

    return (
      <styles.ContainerMobile>
        <styles.ExchangeTabs menuItems={menuItems} defaultKey="EXCHANGE" type="spread">
          <styles.ExchangeContainer key="EXCHANGE">
            {checkTrade ? <BuySellForms showTradeHistory={showTradeHistory} /> : null}

            <styles.OrderBookWrapper>
              <ErrorBoundary fallback={<PairChangeFallback />}>
                <PairChange />
              </ErrorBoundary>

              <ErrorBoundary fallback={<OrderBookFallback />}>
                <OrderBook
                  DTPMin={+settingsState.data.settings.pair.dontTradePriceMin}
                  DTPMax={+settingsState.data.settings.pair.dontTradePriceMax}
                />
              </ErrorBoundary>
            </styles.OrderBookWrapper>
          </styles.ExchangeContainer>
          <styles.AccountContainer key="ACCOUNT">
            <PermissionWrapper party={party} abilityName="cex-stats-view">
              <ErrorBoundary fallback={<styles.PNLWrapperFallback />}>
                <PNLWrapper>
                  <TerminalPnL botInfo={botInfoProps} isShortView contentDirection="column" />
                </PNLWrapper>
              </ErrorBoundary>
            </PermissionWrapper>

            <ErrorBoundary>
              <Balances
                switchAccount={exchangeState.switchAccount}
                updAcc={exchangeState.updAccData}
              />
            </ErrorBoundary>
          </styles.AccountContainer>

          {checkSettings ? (
            <ErrorBoundary key="SETTINGS" fallback={<styles.SettingsContainerFallback />}>
              <styles.SettingsContainer>
                <BindingsBotProvider>
                  <Settings botInfo={botInfoProps} abilityName="cex-settings-edit" isMobile />
                </BindingsBotProvider>
              </styles.SettingsContainer>
            </ErrorBoundary>
          ) : null}

          <styles.OrdersContainer key="ORDERS">
            <ChartPanel party={botInfoProps.party} />

            <ErrorBoundary fallback={<styles.TradePanelFallback />}>
              <TradePanel
                showTradeHistory={showTradeHistory}
                showNotes={false}
                botInfo={botInfoProps}
                abilityName={abilityName}
              />
            </ErrorBoundary>
          </styles.OrdersContainer>
        </styles.ExchangeTabs>
      </styles.ContainerMobile>
    );
  }
);
