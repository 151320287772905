import { ComponentPropsWithoutRef } from "react";
import { mobileMediaQuery } from "src/components/BotsContent/DEX_V2/Settings/style";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { Loader } from "src/components/shared/Loader";
import useMediaQuery from "src/hooks/useMediaQuery";
import Icons from "src/icons/Icons";
import { StepProps } from "..";
import { StepHeading, StepHeadingOwnProps } from "../shared/StepHeading";
import * as styles from "./style";

export interface TablePanelProps
  extends ComponentPropsWithoutRef<"div">,
    Pick<StepProps, "nextStep"> {
  headingProps: StepHeadingOwnProps;
  warning?: string;
  onRefreshWarning?: () => void;
  warningLoading?: boolean;
  onRefreshClick?: () => void;
  onChainRefreshClick?: () => void;
  loading?: boolean;
  isDisabled?: boolean;
  nextDisabled?: boolean;
  nextStepLabel?: string;
}

export const TablePanel = ({
  nextStep,
  onRefreshClick,
  onChainRefreshClick,
  warning,
  onRefreshWarning,
  warningLoading,
  headingProps,
  loading,
  children,
  isDisabled,
  nextDisabled,
  nextStepLabel = "Deploy",
}: TablePanelProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery("500px"));

  const refreshContent = isMobile ? Icons.updateBackend() : "Refresh";

  const refreshChainContent = isMobile ? Icons.updateOnChain() : "Refresh chain";

  return (
    <styles.Container>
      <StepHeading {...headingProps} />

      <styles.WrapperContent>
        <styles.WarningWrapper>
          {warning && <styles.Warning text={warning} isLoading={warningLoading} />}
          {onRefreshWarning && <ReloadButton onClick={onRefreshWarning} disabled={isDisabled} />}
        </styles.WarningWrapper>

        <styles.WalletPanel>
          {children}
          <styles.ButtonsWrapper gap={isMobile ? 16 : 32}>
            {onRefreshClick && (
              <OutlineButton onClick={onRefreshClick} stretched disabled={isDisabled}>
                {refreshContent}
              </OutlineButton>
            )}
            {onChainRefreshClick && (
              <OutlineButton stretched onClick={onChainRefreshClick} disabled={isDisabled}>
                {refreshChainContent}
              </OutlineButton>
            )}
            <Button onClick={nextStep} disabled={isDisabled || nextDisabled}>
              {nextStepLabel}
            </Button>
          </styles.ButtonsWrapper>
          <Loader show={loading} />
        </styles.WalletPanel>
      </styles.WrapperContent>
    </styles.Container>
  );
};
