import {
  ACTIONS_MODULE_TYPES,
  ActionTypes,
  CONDITION_MODULE_GROUPS,
  ConditionModuleGroupsNames,
  ConditionTypes,
  StrategyCreatedModule,
  StrategyModuleTypes,
} from "src/modules/expertSystem";

interface ModuleTag {
  label: string;
  count: number;
  color: string;
}

const CONDITION_TYPE_TITLE_MAP: Record<ConditionTypes, string> = {
  bot_status: "Bot status",
  price_abs: "Price abs",
  time: "Time",
  price_change: "Price change",
  spread_abs: "Spread abs",
  volatility_abs: "Volatility",
  volume_abs: "Volume abs",
  volume_change: "Volume change",
  balance_abs: "Balance abs",
  balance_change: "Balance change",
  balance_delta: "Balance delta",
  price_account_pnl: "Price account PnL",
  price_exchange_pnl: "Price exchange PnL",
  change_settings: "Change settings",
  depth_abs: "Depth abs",
  // multigrinder_status: "Multigrinder status",
  // pnl_abs: "PnL abs",
};

const CONDITION_GROUP_TITLE_MAP: Record<ConditionModuleGroupsNames, string> = {
  bot_status: "Bot status",
  abs_modules: "Abs modules",
  // spread_abs: "Spread abs",
  timeframe_modules: "Change modules",
  time: "Time",
  balance_abs: "Balance abs",
  balance_change_modules: "Balance change modules",
  // change_settings: "Change settings",
  // price_account_pnl: "Price account PnL",
  // price_exchange_pnl: "Price exchange PnL",
  // change_settings: "Change settings",
  depth_abs: "Depth abs",
  // multigrinder_status: "Multigrinder status",
  // pnl_abs: "PnL abs",
};

export const TAG_COLOR_MAP: Record<StrategyModuleTypes, string> = {
  price_abs: "#DEB501",
  price_change: "#2E7D32",
  time: "#00BCD4",
  bot_status: "#8BB300",
  alert_tg: "#C22E25",
  trade: "#1D6DE4",
  spread_abs: "#EE2222",
  volatility_abs: "#FF7E55",
  volume_abs: "#D81B60",
  volume_change: "#00796B",
  balance_abs: "#42A5F5",
  balance_change: "#283593",
  balance_delta: "#D500F9",
  depth_abs: "#69B40A",
  price_account_pnl: "#B40A8F",
  price_exchange_pnl: "#EE5322",
  // multigrinder_status: "#EE5322",
  change_settings: "#8A39F2",
};

export const getConditionGroupTitle = (type: ConditionModuleGroupsNames) =>
  CONDITION_GROUP_TITLE_MAP[type];

export const getConditionTitle = (type: ConditionTypes) => CONDITION_TYPE_TITLE_MAP[type];

export const getConditionGroupTitleList = () =>
  CONDITION_MODULE_GROUPS.map((type) => ({
    label: getConditionGroupTitle(type),
    value: type,
  }));

export const getConditionOptionList = (types: readonly ConditionTypes[]) =>
  types.map((type) => ({
    label: getConditionTitle(type),
    value: type,
  }));

const ACTION_TYP_TITLE_MAP: Record<ActionTypes, string> = {
  alert_tg: "Alert tg",
  trade: "Trade",
  change_settings: "Change settings",
};

export const getActionTitle = (type: ActionTypes) => ACTION_TYP_TITLE_MAP[type];

export const getActionTitleList = () =>
  ACTIONS_MODULE_TYPES.map((type) => ({
    label: getActionTitle(type),
    value: type,
  }));

export const getTagList = (modules: StrategyCreatedModule[]) => {
  const moduleList: ModuleTag[] = [];

  for (const module of modules) {
    const tagFound = moduleList.find((tag) => tag.label === module.type);

    if (tagFound) {
      tagFound.count += 1;
    } else {
      const newTag = {
        label: module.type,
        count: 1,
        color: TAG_COLOR_MAP[module.type],
      };

      moduleList.push(newTag);
    }
  }

  return moduleList;
};
