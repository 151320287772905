import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useDashboardViews } from "../../hooks/useDashboardViews";
import { ExchangesSelector } from "./ExchangesSelector";
import { TimeRangePicker } from "./TimeRangePicker";
import * as styles from "./style";

export const DASHBOARD_VIEW_MENU_ITEMS = [
  { label: "Summary", value: "summary" },
  { label: "Detailed", value: "detailed" },
  { label: "Balance", value: "balance-summary" },
];

export interface DesktopHeaderProps extends ComponentPropsWithoutRef<"div"> {}

export const DesktopHeader = observer((props: DesktopHeaderProps) => {
  const state = useLateInitContext(DashboardContext.Context);
  const { party } = state.botParams;

  const { showToggle, menuItems } = useDashboardViews();

  return (
    <styles.Container {...props}>
      <styles.TitleRow>
        <styles.TitleWrapper>
          <styles.Title>Dashboard</styles.Title>
          <styles.PartyText>{party}</styles.PartyText>
        </styles.TitleWrapper>
        {showToggle && (
          <styles.ViewSwitch
            selectedItem={state.currentView}
            direction="row"
            menuItems={menuItems}
            onChange={state.setCurrentView}
          />
        )}
      </styles.TitleRow>
      <styles.SelectorsRow>
        <ExchangesSelector />

        <TimeRangePicker />
      </styles.SelectorsRow>
    </styles.Container>
  );
});
