import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";
import { ScrollSize } from "./shared";

export const TableContainer = styled.table`
  min-width: 100%;

  display: grid;
  grid-template-rows: repeat(auto-fill, 32px);

  border-collapse: collapse;
  overflow: auto;

  ${ScrollSize}
`;

const DisplayMixin = css`
  display: contents;
`;

export const Header = styled.thead`
  ${DisplayMixin}
`;

export const Body = styled.tbody`
  ${DisplayMixin}
`;

export const Footer = styled.tfoot`
  ${DisplayMixin}
`;

export const TableRow = styled.tr`
  ${DisplayMixin}
`;

const CellMixin = css`
  height: 45px;

  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

export const HeaderCell = styled.th`
  position: sticky;
  top: 0;

  z-index: 1;

  background: ${({ theme }) => theme.bgSettingsPnl};
  color: ${({ theme }) => theme.darkGray};

  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  font-weight: ${({ theme }) => theme.fontWeights["700"]};

  ${CellMixin}
`;

export const Cell = styled.td`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.smallHeader};

  ${CellMixin}
`;

export const FooterCell = styled.td`
  position: sticky;

  left: 0;
  bottom: 0;

  z-index: 1;
`;

interface SortProps {
  column?: any;
}

export const TableSortMixin = css<SortProps>`
  ${({ column }) => {
    if (!column) return "";
    return css`
      ${column.isSorted &&
      css`
        ::after {
          color: ${({ theme }) => theme.primaryColor};
          content: "${column.isSortedDesc ? "▼" : "▲"}";
          position: absolute;
        }
      `}
      ${column.canSort &&
      css`
        cursor: pointer;
      `}
    `;
  }}
`;

export type StatusType = "success" | "danger" | "neutral" | "warning" | "primary";

export interface StatusPointProps {
  type: StatusType;
}

export const StatusPoint = styled.span<StatusPointProps>`
  height: 19px;

  display: inline-block;
  text-align: center;

  border-radius: 2px;
  border: 1px solid;
  border-color: currentColor;

  padding: 2px 5px;

  font-size: 10px;

  ${({ theme, type }) => {
    let typeColor = "";

    if (type === "primary") typeColor = theme.primaryColor;
    if (type === "neutral") typeColor = theme.lightGray;
    if (type === "danger") typeColor = theme.dangerColor;
    if (type === "success") typeColor = theme.successColor;
    if (type === "warning") typeColor = theme.botColorStatus.yellow;

    return css`
      color: ${typeColor};
      background-color: ${hexToRgb(typeColor, 0.1)};
    `;
  }}
`;
