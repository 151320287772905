import { observer } from "mobx-react-lite";
import { PriceImpact } from "src/state/DEXV2/DEXV2Swap/SwapModules/v2/SwapWidget/SwapInfoStore";
import { formatPriceImpact } from "src/state/DEXV2/DEXV2Swap/utils";
import { useSwapWidgetState } from "../../hooks/useSwapWidgetState";
import * as styles from "./style";

export interface WarningRowProps {}

const getPriceImpactMessage = (impact?: PriceImpact) => {
  const { impact: formattedPriceImpact, sign: priceImpactSign } = formatPriceImpact(
    impact?.percent
  );

  const priceImpactVector = priceImpactSign !== undefined && priceImpactSign > 0 ? "rise" : "drop";

  return `High price impact! More than ${formattedPriceImpact} ${priceImpactVector}!`;
};

export const WarningRow = observer((_props: WarningRowProps) => {
  const { swapInfoState } = useSwapWidgetState();
  const { impact } = swapInfoState.info;
  const impactMessage = getPriceImpactMessage(impact);

  return <>{impact?.warning ? <styles.WarningAlert text={impactMessage} /> : null}</>;
});
