import styled from "styled-components";
import { Card } from "../shared/Card";
import { Content } from "../shared/Card/style";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SpreadChartCard = styled(Card)`
  ${Content} {
    padding: 8px;
  }
`;
