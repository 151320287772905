import { BigNumber, providers } from "ethers";
import { SyncSwapClassicPool } from "src/contracts/ethers/SyncSwapClassicPool";
import { SyncSwapClassicPool__factory } from "src/contracts/ethers/factories/SyncSwapClassicPool__factory";
import { IUniswapV2Pair__factory } from "src/contracts/factories/uniswap/v2/IUniswapV2Pair__factory";
import { IUniswapV2Pair } from "src/contracts/uniswap/v2/IUniswapV2Pair";
import { EntriesUnion } from "src/helpers/utils";
import { SwapDEXType } from "../../../../../utils";

type SwapPairContractMap = {
  [SwapDEXType.SYNC]: SyncSwapClassicPool;
  [SwapDEXType.UNISWAP]: IUniswapV2Pair;
};

type SwapPairContract = EntriesUnion<SwapPairContractMap, "type", "contract">;

export type PairReserves = [BigNumber, BigNumber];

export interface IPairContract {
  get address(): string;
  getReserves: () => Promise<PairReserves>;
}

export class PairContract implements IPairContract {
  private _address: string;

  private _contract!: SwapPairContract;

  constructor(address: string, provider: providers.JsonRpcProvider, dexType: SwapDEXType) {
    this._address = address;

    this._initContract(address, provider, dexType);
  }

  private _initContract = (
    address: string,
    provider: providers.JsonRpcProvider,
    dexType: SwapDEXType
  ) => {
    const contract: SwapPairContract =
      dexType === SwapDEXType.SYNC
        ? {
            type: SwapDEXType.SYNC,
            contract: SyncSwapClassicPool__factory.connect(address, provider),
          }
        : {
            type: SwapDEXType.UNISWAP,
            contract: IUniswapV2Pair__factory.connect(address, provider),
          };

    this._contract = contract;
  };

  get address() {
    return this._address;
  }

  getReserves = async (): Promise<PairReserves> => {
    const { type, contract } = this._contract;
    switch (type) {
      case SwapDEXType.SYNC: {
        const reserves = await contract.getReserves();

        return reserves;
      }
      case SwapDEXType.UNISWAP: {
        const [reserve0, reserve1] = await contract.getReserves();

        return [reserve0, reserve1];
      }
    }
  };
}
