import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DashboardView } from "src/state/CEX/CEXDashboard";
import { BotInfoProps } from "..";
import { mobileMediaQuery } from "../../DEX_V2/Settings/style";
import { MobileHeader } from "./Header/MobileHeader";
import * as styles from "./style";
import { BalanceSummaryView } from "./views/BalanceSummaryView";
import { DetailedView } from "./views/DetailedView";
import { SummaryView } from "./views/SummaryView";
import { DashboardBreakpoints } from "./views/SummaryView/style";

const getViewContent = (view: DashboardView) => {
  switch (view) {
    case "detailed": {
      return <DetailedView />;
    }
    case "summary": {
      return <SummaryView />;
    }
    case "balance-summary": {
      return <BalanceSummaryView />;
    }
  }
};

export interface DashboardProps extends BotInfoProps {}

export const Dashboard = observer(({ botInfo: { bot_uuid, party } }: DashboardProps) => {
  const state = useLateInitContext(DashboardContext.Context);

  const isMobile = useMediaQuery(mobileMediaQuery(DashboardBreakpoints.XMobile));

  const Header = isMobile ? <MobileHeader /> : <styles.DashboardHeader />;

  useEffect(() => {
    if (!party || !bot_uuid) return;
    state.setBotParams({
      uuid: bot_uuid,
      party,
    });
    state.getInitialData();
  }, [party, bot_uuid, state]);

  const viewContent = getViewContent(state.currentView);

  const content = !state.initialLoading && (
    <>
      {Header}
      <styles.Content>{viewContent}</styles.Content>
    </>
  );

  return (
    <styles.Container>
      {content}
      <Loader show={state.initialLoading} />
    </styles.Container>
  );
});
