import { observer } from "mobx-react-lite";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import { SubTitle, Wrapper } from "../../style";
import * as styles from "./style";

export const MainSettings = observer(() => {
  const { DEXSettingsState: state } = useAppState();

  return (
    <styles.Container>
      <SubTitle>Main settings</SubTitle>
      <Wrapper>
        <LabeledInput
          label="Period"
          type="number"
          value={state.data.settings.period}
          onChange={state.getHandler("settings.period")}
          error={state.getError("settings.period")}
          errorHint={state.errors.settings?.period}
          step="any"
        />
        <LabeledInput
          label="Min amount"
          type="number"
          value={state.data.settings.minAmount}
          onChange={state.getHandler("settings.minAmount")}
          error={state.getError("settings.minAmount")}
          errorHint={state.errors.settings?.minAmount}
          step="any"
        />
        <LabeledInput
          label="Max amount"
          type="number"
          value={state.data.settings.maxAmount}
          onChange={state.getHandler("settings.maxAmount")}
          error={state.getError("settings.maxAmount")}
          errorHint={state.errors.settings?.maxAmount}
          step="any"
        />
      </Wrapper>
      <Wrapper>
        <LabeledPercentRangeInput
          step="0.1"
          label="Buy Percent"
          value={state.data.settings.buyPercent}
          onChange={state.getHandler("settings.buyPercent")}
          error={state.getError("settings.buyPercent")}
          errorHint={state.errors.settings?.buyPercent}
        />
        <LabeledInput
          label="Trades Min"
          type="number"
          value={state.data.settings.ordersMin}
          onChange={state.getHandler("settings.ordersMin")}
          error={state.getError("settings.ordersMin")}
          errorHint={state.errors.settings?.ordersMin}
          step="any"
        />
        <LabeledInput
          label="Trades Max"
          type="number"
          value={state.data.settings.ordersMax}
          onChange={state.getHandler("settings.ordersMax")}
          error={state.getError("settings.ordersMax")}
          errorHint={state.errors.settings?.ordersMax}
          step="any"
        />
      </Wrapper>
    </styles.Container>
  );
});
