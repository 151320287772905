import { ChildrenProps } from "src/helpers/utils";
import { useChainProvider } from "src/hooks/useChainProvider";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import DEXV2BotStore from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { AbilitiesContext } from "./AbilitiesContext";

export const DEXV2BotContext = createLateInitContext<DEXV2BotStore>();

type DEXV2BotProviderProps = ChildrenProps;

export const DEXV2BotProvider = ({ children }: DEXV2BotProviderProps) => {
  const chainProvider = useChainProvider();
  const { chainMetaState, dexV2BotsState } = useAppState();

  const abilitiesVerifier = useLateInitContext(AbilitiesContext);

  const botsStore = useLocalStore(DEXV2BotStore, {
    chainProvider,
    chainMetaProvider: chainMetaState,
    cacheProvider: dexV2BotsState.cacheProvider,
    abilitiesVerifier,
  });

  return <DEXV2BotContext.Provider value={botsStore}>{children}</DEXV2BotContext.Provider>;
};
