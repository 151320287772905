import { observer } from "mobx-react-lite";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { SaveModuleButton } from "../Buttons/SaveModuleButton";
import { PanelComponents, SettingsFormPanelsProps } from "../Volume";
import { SettingsForm } from "../shared/SettingsForm";
import { mobileMediaQuery } from "../style";
import * as styles from "./style";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";

export interface LimitProps extends SettingsFormPanelsProps {}

export const Limit = observer(({ ...props }: LimitProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const isMobile = useMediaQuery(mobileMediaQuery("700px"));
  const settingsOrientation = isMobile ? "column" : "row";

  const panelComponents: PanelComponents = {
    btn: <SaveModuleButton module="limit_data" />,
    toggle: (
      <SettingsToggleSwitch
        checked={state.modulesStatuses("limit")}
        onChange={state.getOnHandler("limit_data")}
      />
    ),
  };

  return (
    <SettingsForm
      {...props}
      orientation={settingsOrientation}
      onSubmit={state.submitModuleHandler("limit_data")}
      loader={state.moduleLoading("limit_data")}
    >
      <styles.LimitPanel {...(isMobile ? panelComponents : undefined)} />
      <styles.TradePanel {...(isMobile ? undefined : panelComponents)} />
    </SettingsForm>
  );
});
