import { Graph } from "src/components/shared/Graph";

const { default: styled } = require("styled-components");

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const DEXGraph = styled(Graph)`
  width: 100%;
  height: 210px;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 15px;
  color: ${({ theme }) => theme.textColor};
  :after {
    position: absolute;
    display: block;
    /* width: 40vw; */
    height: 1px;
    top: 10px;
    background-color: gray;
    content: "";
    right: 0;
    left: 60px;
  }
`;
