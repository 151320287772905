import { observer } from "mobx-react-lite";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PeriodSwitchButton } from "./PeriodSwitchButton";
import * as styles from "./style";

export interface TimeRangePickerProps {}

export const TimeRangePicker = observer((props: TimeRangePickerProps) => {
  const state = useLateInitContext(DashboardContext.Context);

  return (
    <styles.Container>
      <PeriodSwitchButton onClick={state.onPreviousMonth} disabled={state.previousMonthDisabled}>
        Previous
      </PeriodSwitchButton>
      <DateTimeRangePickerSelector
        value={state.selectedRange}
        onChange={state.setRange}
        selectorDropdown={styles.RangePickerSelector}
        minDate={state.minDate}
        disableFuture
        maxDate={getCurrentDayjs()}
      />
      <styles.TimezoneText>UTC+0</styles.TimezoneText>
      <PeriodSwitchButton onClick={state.onCurrentMonth}>Current</PeriodSwitchButton>
      <PeriodSwitchButton onClick={state.onNextMonth} disabled={state.nextMonthDisabled}>
        Next
      </PeriodSwitchButton>
    </styles.Container>
  );
});
