import { observer } from "mobx-react-lite";
import { useState } from "react";
import { PanelTitle } from "src/components/shared/shared";
import useAppState from "src/state";
import { Filter } from "../Lists/Filter";
import { AddParty } from "./AddParty";
import { AddScope } from "./AddScope";
import { EditParty } from "./EditParty";
import { EditScope } from "./EditScope";
import { ScopesTree } from "./ScopesTree";
import * as styles from "./style";

export const Scopes = observer(() => {
  const [showEditParty, setShowEditParty] = useState(false);
  const [editedParty, setEditedParty] = useState("");

  const { UserManagerState: state } = useAppState();

  return (
    <>
      <styles.ScopesContainer>
        <styles.Header>
          <PanelTitle>Scopes</PanelTitle>

          <Filter value={state.scopesSearchText} onChange={state.setScopesFilter} />
        </styles.Header>
        <styles.Content>
          <ScopesTree hide={setShowEditParty} setEditedParty={setEditedParty} />
          <EditParty partyName={editedParty} show={showEditParty} hide={setShowEditParty} />
        </styles.Content>
      </styles.ScopesContainer>

      <AddScope />
      <AddParty />
      <EditScope />
    </>
  );
});
