import { makeAutoObservable } from "mobx";
import { ChooseSettingParamsStore } from "./ChooseSettingParamsStore";
import { IChangeSettingsModuleStore } from "./shared/types";

export class ActionsChangeSettingsStore implements IChangeSettingsModuleStore {
  private _chooseParamsState: ChooseSettingParamsStore;

  constructor(state: ChooseSettingParamsStore) {
    this._chooseParamsState = state;

    makeAutoObservable(this);
  }

  get chooseParamsState() {
    return this._chooseParamsState;
  }

  get selectedParams() {
    return this._chooseParamsState.settingsParams;
  }

  destroy = () => {};
}
