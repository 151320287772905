import styled from "styled-components";
import { mobileMediaQuery } from "../style";

export const TeamsGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 60px;

  @media ${mobileMediaQuery()} {
    grid-template-columns: 1fr;
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TEAMS_COLOR_WHEEL = [
  "#69B40A",
  "#EF5DA8",
  "#156CE3",
  "#F2BD01",
  "#7A65F8",
  "#F48D76",
  "#A1A1A1",
] as const;

export const getTeamThemeColor = (index: number) => {
  const colorsCount = TEAMS_COLOR_WHEEL.length;
  const indexToColorIndex = index % colorsCount;
  return TEAMS_COLOR_WHEEL[indexToColorIndex];
};
