import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  grid-area: PNL;

  gap: 5px;
`;
