import { BigNumber } from "ethers";
import { makeAutoObservable, observable } from "mobx";
import { getBotSettings } from "src/api/bots/DEXV2/settings";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";
import { DEXV2BotMode } from "src/modules/bots";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  IBotUUIDProvider,
} from "../DEXV2Bots/DEXV2BotStore";
import { DEXV2Settings, INITIAL_DEXV2_SETTINGS } from "../DEXV2Settings";
import { INITIAL_PAIR_ADDRESSES } from "../DEXV2Stats/Vaults";
import { ITradePairPriceProvider } from "../Providers/TradePairUSDPriceProvider";
import WithdrawerProvider, { IWithdrawerProvider } from "../shared/WithdrawerProvider";
import {
  BaseUSDPriceProvider,
  IBaseUSDPriceProvider,
} from "./SwapModules/shared/Providers/BaseUsdPriceProvider";
import { DEXV2SwapVersionStore, ISwapVersionProvider } from "./Version/DEXV2SwapVersionStore";

export interface BotTransactionFees {
  buy: string;
  sell: string;
}

export interface BotSlippages extends Record<DEXV2BotMode, string> {}

export interface ISwapFactoryAddressProvider {
  get factoryAddress(): string | null;
}

export interface ISwapPairAddressProvider {
  get pairAddress(): string | null;
}

export interface ISwapGasLimitProvider {
  get gasLimit(): BigNumber | null;
}

export interface ISwapDexNameProvider {
  get dexName(): string;
}

export interface ISwapInfoProvider
  extends ISwapFactoryAddressProvider,
    ISwapPairAddressProvider,
    ISwapGasLimitProvider,
    IBotUUIDProvider,
    ISwapDexNameProvider,
    Pick<IWithdrawerProvider, "withdrawer"> {
  get slippage(): string;

  get transactionFees(): BotTransactionFees;
}

export interface ISwapProviders {
  get baseUSDPriceProvider(): IBaseUSDPriceProvider;
  get tradePairPriceProvider(): ITradePairPriceProvider;
  get tradePairProvider(): IBotTradePairProvider;
  get versionProvider(): ISwapVersionProvider;
}

export interface IDEXV2SwapParams {
  tradePairProvider: IBotTradePairProvider;
  chainInfoProvider: IBotChainInfoProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
}
export class DEXV2SwapStore implements Disposable, ISwapInfoProvider, ISwapProviders {
  private _botUUID = "";

  private _loading = false;

  private _settings: DEXV2Settings = INITIAL_DEXV2_SETTINGS;

  // private _factoryAddress: string | null = null;

  private _botChainInfoProvider: IBotChainInfoProvider;

  private _versionProvider: ISwapVersionProvider;

  // private _factorAddressReaction: IReactionDisposer;
  private _withdrawerProvider: IWithdrawerProvider;

  private _tradePairProvider: IBotTradePairProvider;

  private _baseUSDPriceProvider: BaseUSDPriceProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _pairAddressProvider: ISwapPairAddressProvider;

  constructor({
    tradePairProvider,
    chainInfoProvider,
    pairAddressProvider,
    tradePairPriceProvider,
  }: IDEXV2SwapParams) {
    makeAutoObservable<this, "_baseUSDPriceProvider">(this, {
      _baseUSDPriceProvider: observable.ref,
    });

    this._botChainInfoProvider = chainInfoProvider;
    this._tradePairProvider = tradePairProvider;
    this._pairAddressProvider = pairAddressProvider;

    this._withdrawerProvider = new WithdrawerProvider(this);

    this._versionProvider = new DEXV2SwapVersionStore({
      settingsProvider: this,
    });

    this._tradePairPriceProvider = tradePairPriceProvider;

    this._baseUSDPriceProvider = new BaseUSDPriceProvider({
      tradePairPriceProvider: this._tradePairPriceProvider,
    });

    // this._factorAddressReaction = when(
    //   () => {
    //     return (
    //       Boolean(this._chainProvider.provider) && Boolean(this._routerAddress)
    //     );
    //   },
    //   () => {
    //     this._getFactoryAddress();
    //   }
    // );

    makeLoggable<any>(this, {
      factoryAddress: true,
      _routerAddress: true,
    });
  }

  get baseUSDPriceProvider() {
    return this._baseUSDPriceProvider;
  }

  get tradePairPriceProvider() {
    return this._tradePairPriceProvider;
  }

  get tradePairProvider() {
    return this._tradePairProvider;
  }

  get versionProvider() {
    return this._versionProvider;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setSettings(settings: DEXV2Settings) {
    this._settings = settings;
  }

  get settings() {
    return this._settings;
  }

  get transactionFees(): BotTransactionFees {
    return {
      buy: this._settings.base_data.tt_buy_fee.toString(),
      sell: this._settings.base_data.tt_sell_fee.toString(),
    };
  }

  get addresses() {
    return this._tradePair?.addresses ?? INITIAL_PAIR_ADDRESSES;
  }

  get slippages(): BotSlippages {
    return {
      limit: this._settings.limit_data.slippage.toString(),
      volume: this._settings.volume_data.slippage.toString(),
      counter: this._settings.counter_data.slippage.toString(),
    };
  }

  get slippage() {
    return this.slippages.volume;
  }

  get gasLimit() {
    const gasLimit = this._settings.base_data.gas_limit;
    if (!gasLimit) return null;
    return BigNumber.from(gasLimit);
  }

  get dexName() {
    return this._settings.market;
  }

  get chainMeta() {
    return this._botChainInfoProvider.chainMeta;
  }

  // private _setFactoryAddress = (address: string) => {
  //   this._factoryAddress = address;
  // };

  // get factoryAddress() {
  //   return this._factoryAddress;
  // }

  get pairAddress() {
    return this._pairAddressProvider.pairAddress;
  }

  private get _chainProvider() {
    return this._botChainInfoProvider.chainProvider;
  }

  private get _exchange() {
    return this._botChainInfoProvider.exchange;
  }

  private get _exchangesInfo() {
    return this._chainProvider.currentChainExchanges;
  }

  get factoryAddress() {
    const exchangesInfo = this._exchangesInfo;
    const exchange = this._exchange;

    if (!exchange || !exchangesInfo) return null;

    return exchangesInfo[exchange]?.factory ?? null;
  }

  // private get _routerAddress() {
  //   return this._settings.market_router;
  // }

  // private get _routerContract() {
  //   const { provider } = this._chainProvider;
  //   const address = this._routerAddress;
  //   if (!provider || !address) {
  //     return null;
  //   }

  //   const routerContract = IUniswapV2Router02__factory.connect(
  //     address,
  //     provider
  //   );

  //   return routerContract;
  // }

  // private _getFactoryAddress = async () => {
  //   if (!this._routerContract) return;
  //   try {
  //     const address = await this._routerContract.factory();
  //     this._setFactoryAddress(address);
  //   } catch (err) {
  //     chainErrorHandler(err);
  //   }
  // };

  get withdrawer() {
    return this._withdrawerProvider.withdrawer;
  }

  private _getWithdrawer = async () => {
    await this._withdrawerProvider.getWithdrawer();
  };

  private _getSettings = async () => {
    const { isError, data } = await getBotSettings(this._botUUID);
    if (!isError) {
      this._setSettings(data);
    }
    return isError;
  };

  getSettings = async () => {
    this._setLoading(true);

    try {
      await Promise.all([this._getSettings(), this._getWithdrawer()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._baseUSDPriceProvider.destroy();

    // this._factorAddressReaction();
  };
}
