import { Observer, observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { formatDecimalPercent, formatFiat } from "src/helpers/string";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BalancesDynamicsData } from "src/state/CEX/CEXDashboard/BalancesDynamicsStore";
import { TokensColorsMap } from "..";
import { StatsTableProps } from "../../shared/StatsTable";
import { BalancesDynamicsContext } from "../context";
import { BalancesTicker } from "./BalancesTicker";
import * as styles from "./style";

export interface BalancesTableProps
  extends Omit<StatsTableProps<BalancesDynamicsData>, "columns" | "data"> {
  colorsMap: TokensColorsMap;
}

const DEFAULT_COLUMN: Omit<Column<BalancesDynamicsData>, "accessor"> = {
  width: 30,
  disableSortBy: true,
};

export const BalancesTable = observer(({ colorsMap, ...props }: BalancesTableProps) => {
  const state = useLateInitContext(BalancesDynamicsContext.Context);

  const columns = useMemo(
    (): Column<BalancesDynamicsData>[] => [
      {
        Header: "",
        accessor: "currency",
        ...DEFAULT_COLUMN,
        width: 15,
        Footer: "Total",
        Cell: ({ value }) => <BalancesTicker color={colorsMap[value]}>{value}</BalancesTicker>,
      },
      {
        Header: "%",
        accessor: "percent",
        ...DEFAULT_COLUMN,
        Cell: ({ value }) => <>{formatDecimalPercent(value)}</>,
      },
      {
        Header: "Abs",
        accessor: "abs",
        ...DEFAULT_COLUMN,
        Cell: ({ value }) => <>{formatFiat(value, false)}</>,
      },
      {
        Header: "Abs $",
        accessor: "absUsd",
        ...DEFAULT_COLUMN,
        Cell: ({ value }) => <>{formatFiat(value, false)}</>,
        Footer: () => <Observer>{() => <>{formatFiat(state.absTotal, false)}</>}</Observer>,
      },
    ],
    [colorsMap, state]
  );

  return <styles.Table columns={columns} data={state.data} {...props} />;
});
