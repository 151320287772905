import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import styled from "styled-components";
import { Card } from "../shared/Card";
import { MainContentWrapper } from "../shared/Card/style";

export const PNLCard = styled(Card)`
  ${MainContentWrapper} {
    width: calc(100% - 120px);
  }
`;

export const ModeSwitch = styled(SwitchMenu).attrs({ direction: "row" })`
  max-width: 150px;
  height: 24px;

  ${Label} {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;
