import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getConditionTitle } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/modules";
import { CreateStrategyContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IConditionStrategyCreatorModule, IStrategyCreator } from "src/modules/expertSystem";
import { HardParamsViewProps } from "../../shared/ModuleSoftParamsShell/HardParamsView";
import { FormsAreaContainer } from "../../shared/style";
import { ModuleWithDecimal, ModuleWithTimeframe, ModuleWithTimestamp } from "./SoftParams";
import { getFormType, getValidate } from "./shared/utils";

export const FormsArea = () => {
  const state = useLateInitContext(CreateStrategyContext);
  const { control, watch } = useFormContext<IStrategyCreator>();

  const { remove } = useFieldArray({
    control,
    name: "conditions",
  });

  const watchFieldArray = watch("conditions");

  const getRemoveCb = useCallback(
    (index: number, module: IConditionStrategyCreatorModule) => () => {
      remove(index);
      state.removeConditionHandler(module.soft_params.key);
    },
    [remove, state]
  );

  const getHardParamsProps = useCallback(
    (uuid: string) => state.getConditionHardParamsProps(uuid),
    [state]
  );

  const getModuleProps = useCallback(
    (index: number, module: IConditionStrategyCreatorModule, removeCb: () => void) => ({
      index,
      uuid: module.uuid,
      headerProps: {
        title: getConditionTitle(module.type),
        moduleKey: module.soft_params.key,
        remove: removeCb,
      },
    }),
    []
  );

  const getContent = useCallback(
    (module: IConditionStrategyCreatorModule, index: number) => {
      const key = module.uuid + module.soft_params.key;

      const removeCb = getRemoveCb(index, module);

      const hardParamsProps: HardParamsViewProps = getHardParamsProps(module.uuid);

      const moduleProps = getModuleProps(index, module, removeCb);

      const formType = getFormType(module.type);

      const validate = getValidate(module.type);

      switch (formType) {
        case "moduleWithTimeframe":
          return <ModuleWithTimeframe key={key} {...hardParamsProps} {...moduleProps} />;
        case "moduleWithDecimal":
          return (
            <ModuleWithDecimal
              key={key}
              {...hardParamsProps}
              {...moduleProps}
              validate={validate}
            />
          );
        case "moduleWithTimestamp":
          return <ModuleWithTimestamp key={key} {...hardParamsProps} {...moduleProps} />;

        default:
          return null;
      }
    },
    [getHardParamsProps, getModuleProps, getRemoveCb]
  );

  return (
    <FormsAreaContainer>
      {watchFieldArray.map((field, index) => getContent(field, index))}
    </FormsAreaContainer>
  );
};
