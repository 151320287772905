import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, RefCallback, useLayoutEffect, useMemo } from "react";
import { Row, TableInstance, TableOptions, useFilters, useSortBy, useTable } from "react-table";
import { ElementRef } from "src/helpers/utils";
import * as styles from "./style";

export interface TableContentProps extends ComponentPropsWithoutRef<"table"> {
  data: any;
  columns: any;
  ownRef?: ElementRef<"table">;
  instanceRef?: RefCallback<TableInstance<any>>;
  onRowsChanged?: (rows: Row<object>[]) => void;
}

export const TableContent = observer(
  ({ data, columns, ownRef, instanceRef, onRowsChanged, ...props }: TableContentProps) => {
    // explicit memo for data to prevent
    // array recreating on react table rerenders
    const dataCopy = useMemo(() => data.slice(), [data]);

    const options: TableOptions<any> = {
      data: dataCopy,
      columns,
      autoResetSortBy: false,
    };

    const instance = useTable(options, useFilters, useSortBy);

    useLayoutEffect(() => {
      instanceRef?.(instance);

      return () => {
        instanceRef?.(null);
      };
    }, [instance, instanceRef]);

    const { rows, headers, prepareRow, getTableProps, getTableBodyProps } = instance;

    useLayoutEffect(() => {
      onRowsChanged?.(rows);
    }, [onRowsChanged, rows]);

    return (
      <styles.TableContent {...getTableProps()} {...props} ref={ownRef}>
        <styles.Head>
          <styles.HeaderRow>
            {headers.map((column, index) => {
              const { key, ...headerProps } = column.getHeaderProps([
                column.getSortByToggleProps(),
              ]);

              return (
                <styles.TableHeaderCell key={key} {...headerProps} column={column}>
                  <styles.TableHeaderCellContent>
                    {column.render("Header")}
                    {column.canFilter ? column.render("Filter") : null}
                  </styles.TableHeaderCellContent>
                </styles.TableHeaderCell>
              );
            })}
          </styles.HeaderRow>
        </styles.Head>

        <styles.TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <styles.Row key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps();
                  return (
                    <styles.TableCell key={key} {...cellProps}>
                      {cell.render("Cell")}
                    </styles.TableCell>
                  );
                })}
              </styles.Row>
            );
          })}
        </styles.TableBody>
      </styles.TableContent>
    );
  }
);
