import { StyledPNLV2Value } from "src/components/shared/PNLV2/shared/Item/shared/PNLV2Value/style";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 8px;

  ${StyledPNLV2Value} {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
`;
