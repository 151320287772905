import { useMemo } from "react";
import { Bar, BarProps } from "src/components/shared/Charts/Bar";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useBaseTooltip, useGridOptions } from "../../shared/config";

const useBaseOptions = () => {
  const tooltipOptions = useBaseTooltip();

  const options = useMemo(
    (): BarProps<any, any>["options"] => ({
      indexAxis: "y",
      maintainAspectRatio: false,
      layout: { padding: 8 },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...tooltipOptions,
        },
      },
      scales: {
        x: {
          ticks: {
            format: {
              style: "percent",
            },
          },
        },
      },
    }),
    [tooltipOptions]
  );

  return options;
};

export interface BalancesBarProps extends BarProps<number[], string> {}

export const BalancesBar = ({ options: inOptions, ...props }: BalancesBarProps) => {
  const baseOptions = useBaseOptions();

  const gridOptions = useGridOptions();

  const options = useMemo(
    () => deepMerge(baseOptions, gridOptions, inOptions),
    [baseOptions, gridOptions, inOptions]
  );

  return <Bar options={options} {...props} />;
};
