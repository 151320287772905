import {
  EthersError,
  RETURN_VALUE_ERROR_CODES,
  getParsedEthersError,
} from "@enzoferey/ethers-error-parser";
import { toast } from "react-toastify";
import { getMessageFromError } from "./logger";

export interface ChainErrorHandlerOptions {
  showToast?: boolean;
  logError?: boolean;
}

export const joinErrorMessage = (tag: string, body?: string) =>
  [tag, body].filter(Boolean).join(" : ");

type ParsedEthersError = ReturnType<typeof getParsedEthersError>;
type ErrorCode = ParsedEthersError["errorCode"];

export type EthersErrorMessage = {
  code: ErrorCode;
  tag: string;
  message?: string;
};
const getEthersErrorTag = ({ errorCode }: ParsedEthersError) => {
  switch (errorCode) {
    case RETURN_VALUE_ERROR_CODES.TRANSACTION_RAN_OUT_OF_GAS: {
      return "Transaction run out of gas";
    }
    case RETURN_VALUE_ERROR_CODES.TRANSACTION_UNDERPRICED: {
      return "Transaction underpriced";
    }
    case RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION: {
      return "Transaction rejected";
    }
    case RETURN_VALUE_ERROR_CODES.CALL_REVERTED: {
      return "Call reverted";
    }
    case RETURN_VALUE_ERROR_CODES.EXECUTION_REVERTED: {
      return "Execution reverted";
    }
    case RETURN_VALUE_ERROR_CODES.NONCE_TOO_LOW: {
      return "Transaction nonce too low";
    }
    case RETURN_VALUE_ERROR_CODES.INSUFFICIENT_FUNDS_FOR_GAS: {
      return "Insufficient gas funds";
    }
    case RETURN_VALUE_ERROR_CODES.MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS: {
      return "Max priority fee higher than max fee";
    }
    case RETURN_VALUE_ERROR_CODES.MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE: {
      return "Max fee less than block base fee";
    }
    case RETURN_VALUE_ERROR_CODES.UNKNOWN_ERROR: {
      return undefined;
    }
  }
};

export const getEthersErrorMessage = (error: unknown): EthersErrorMessage | undefined => {
  const parsedError = getParsedEthersError(error as EthersError);
  const tag = getEthersErrorTag(parsedError);
  if (!tag) return undefined;
  return {
    tag,
    code: parsedError.errorCode,
    message: parsedError.context,
  };
};

export const chainErrorHandler = (
  error: unknown,
  { showToast = true, logError = true }: ChainErrorHandlerOptions = {}
) => {
  const ethersMessage = getEthersErrorMessage(error);
  const message = ethersMessage
    ? joinErrorMessage(ethersMessage.tag, ethersMessage.message)
    : getMessageFromError(error);

  if (showToast) {
    toast.error(message);
  }
  if (logError) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
