import { observer } from "mobx-react-lite";
import { Label } from "src/components/shared/Forms/Label";
import { DateTimeInputPrompt } from "src/components/shared/Prompts/DateTimeInputPrompt";
import { CreateStrategyContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

const TEXT_AREA_ID = "expression_area";
const PROMPT_TEXT = `Logical expression can contain parentheses "(" ")" and can use "or" or "and" operators.
The expression must contain all selected condition modules (x0, x1...).
All elements of the expression must be separated by spaces. 
`;

export const ExpressionArea = observer(() => {
  const { expressionAreaState: state } = useLateInitContext(CreateStrategyContext);

  return (
    <styles.Container>
      <styles.LabelWrapper>
        <Label htmlFor={TEXT_AREA_ID} error={state.expressionError}>
          Logical expression:
        </Label>

        <DateTimeInputPrompt text={PROMPT_TEXT} />
      </styles.LabelWrapper>

      <styles.ExpressionTextArea
        id={TEXT_AREA_ID}
        value={state.expression}
        onChange={state.expressionHandler}
        $error={!!state.expressionError}
      />
    </styles.Container>
  );
});
