import { makeAutoObservable } from "mobx";
import {
  DashboardRangeParams,
  GetBalancePNLResponse,
  GetPNLResponse,
  getBalancePNL,
  getPNL,
} from "src/api/bots/CEX/dashboard";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { logError } from "src/helpers/network/logger";
import { Disposable, Mapper } from "src/helpers/utils";
import {
  IBaseStatsStoreParams,
  IDashboardStateProvider,
  IStatsFetcher,
  getDashboardQueryParams,
  getDashboardRangeQueryParams,
} from ".";
import { padSeriesData, rawSeriesToData } from "./utils";

interface IPNlStoreParams extends IBaseStatsStoreParams {}

type PNLData = {
  allTime: number;
  absBars: {
    time: number[];
    value: number[];
  };
  percentBars: {
    time: number[];
    value: number[];
  };
};

const INITIAL_DATA: PNLData = {
  allTime: 0,
  absBars: {
    time: [],
    value: [],
  },
  percentBars: {
    time: [],
    value: [],
  },
};

const pnlResponseToData: Mapper<GetPNLResponse, PNLData> = ({
  all_time,
  bars_abs,
  bars_percent,
}) => ({
  allTime: all_time,
  absBars: bars_abs,
  percentBars: bars_percent,
});

const balancePNLResponseToTotal: Mapper<GetBalancePNLResponse, number> = ({ delta_usd }) =>
  +delta_usd;

export default class PNLStore implements IStatsFetcher, Disposable {
  private _stateProvider: IDashboardStateProvider;

  private _data: PNLData = INITIAL_DATA;

  private _allTimePNL: number | null = null;

  private _monthPNL: number | null = null;

  private _previousMonthPNL: number | null = null;

  private _loading = false;

  constructor({ stateProvider }: IPNlStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _currentQueryParams() {
    return this._stateProvider.queryParams;
  }

  private get _selectedExchanges() {
    return this._stateProvider.selectedExchanges;
  }

  private get _currentMonth() {
    return this._stateProvider.currentMonth;
  }

  private get _previousMonth() {
    return this._stateProvider.previousMonth;
  }

  private get _currentRange() {
    return this._stateProvider.selectedRange;
  }

  private _getQueryRangeParams = (range: DateTimeRange | null): DashboardRangeParams | null =>
    getDashboardRangeQueryParams(range);

  private _getQueryParams = (range: DateTimeRange | null) => {
    const rangeParams = this._getQueryRangeParams(range);
    const exchanges = this._selectedExchanges;
    return getDashboardQueryParams(rangeParams, exchanges);
  };

  private _setData = (data: PNLData) => {
    this._data = data;
  };

  private _setAllTimePNL = (pnl: number | null) => {
    this._allTimePNL = pnl;
  };

  private _setMonthPNL = (pnl: number | null) => {
    this._monthPNL = pnl;
  };

  private _setPreviousMonthPNL = (pnl: number | null) => {
    this._previousMonthPNL = pnl;
  };

  get allTime() {
    return this._allTimePNL;
  }

  get previousMonth() {
    return this._previousMonthPNL;
  }

  private _getRangeMonth = (range: DateTimeRange | null) => {
    if (!range) return null;
    const [start, end] = range;
    if (!start || !end) return null;
    return start.format("MMMM");
  };

  get previousMonthName() {
    return this._getRangeMonth(this._previousMonth);
  }

  get month() {
    return this._monthPNL;
  }

  get monthName() {
    return this._getRangeMonth(this._currentMonth);
  }

  private get _absBars() {
    return rawSeriesToData(this._data.absBars);
  }

  get absBars() {
    const seriesData = this._absBars;
    return padSeriesData(seriesData);
  }

  private get _percentBars() {
    return rawSeriesToData(this._data.percentBars);
  }

  get percentBars() {
    const seriesData = this._percentBars;
    return padSeriesData(seriesData);
  }

  private _getTotalPnl = async (
    setTotalPnl: (pnl: number | null) => void,
    period: DateTimeRange | null
  ) => {
    const { party } = this._botParams;
    const queryParams = this._getQueryParams(period);
    if (!party || !queryParams) return;

    setTotalPnl(null);
    const { data, isError } = await getBalancePNL(party, queryParams);

    if (!isError) {
      const monthPNL = balancePNLResponseToTotal(data);
      setTotalPnl(monthPNL);
    }
  };

  private _getAllTimePNL = async () => {
    await this._getTotalPnl(this._setAllTimePNL, this._currentRange);
  };

  private _getMonthPNL = async () => {
    await this._getTotalPnl(this._setMonthPNL, this._currentMonth);
  };

  private _getPreviousMonthPNL = async () => {
    await this._getTotalPnl(this._setPreviousMonthPNL, this._previousMonth);
  };

  private _getPNL = async () => {
    const { party } = this._botParams;
    const queryParams = this._currentQueryParams;
    if (!party || !queryParams) return;

    this._setData(INITIAL_DATA);
    this._setLoading(true);
    try {
      const { data, isError } = await getPNL(party, queryParams);

      if (!isError) {
        const pnlData = pnlResponseToData(data);
        this._setData(pnlData);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await Promise.all([
        this._getPNL(),
        this._getMonthPNL(),
        this._getAllTimePNL(),
        this._getPreviousMonthPNL(),
      ]);
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
