import AppContext from "src/context";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import AllBotsState from "./AllBots";
import { CEXBotStore } from "./CEX/CEXBot";
import CEXBotsState from "./CEX/CEXBots/CEXBots";
import SettingsBotStore from "./CEX/CEXSettings/settingsBot";
import SpectrStore from "./CEX/CEXStats/Spectr";
import LiquidityTabStore from "./CEX/liquidityTab";
import CreateBotStore from "./CreateBot";
import { DEXBotStore } from "./DEX/DEXBot";
import DEXBotsState from "./DEX/DEXBots";
import DEXSettingsStore from "./DEX/DEXSettings/DEXSettings";
import DEXStatsStore from "./DEX/DEXStats/DEXStats";
import WalletsState from "./DEX/DEXStats/Wallets";
import DEXV2BotsState from "./DEXV2/DEXV2Bots/DEXV2Bots";
import GoogleAuth from "./GoogleAuth";
import NotesStore from "./Notes";
import UserState from "./User";
import UserManagerStore from "./UserManager";
import ConfirmStore from "./auth/ConfirmStore";
import LoginStore from "./auth/LoginStore";
import ChainMetaStore from "./chain/ChainMetaStore";
import ChainsInfoStore, { IChainsInfo } from "./chain/ChainsInfoStore";
import FundingDEXStore from "./funding/fundingDEX";
import UserConnection from "./shared/UserConnection";
import TimezoneStore, { ITimezoneState } from "./time/TimezoneStore";

const chainsStore = new ChainsInfoStore() as IChainsInfo;

const chainMetaStore = new ChainMetaStore();

const userStore = new UserState();

const userConnection = new UserConnection(userStore);

export const appState = {
  allBotsState: new AllBotsState(),
  cexBotsState: new CEXBotsState(),
  dexBotsState: new DEXBotsState(),
  dexV2BotsState: new DEXV2BotsState(chainsStore, chainMetaStore),
  userState: userStore,
  createBotState: new CreateBotStore(),
  SettingsBotState: new SettingsBotStore(),
  UserManagerState: new UserManagerStore(),
  NotesState: new NotesStore(),
  SpectrState: new SpectrStore(),
  FundingDEXState: new FundingDEXStore(),
  DEXSettingsState: new DEXSettingsStore(),
  WalletsState: new WalletsState(),
  DEXStatsState: new DEXStatsStore(),
  loginState: new LoginStore(),
  confirmState: new ConfirmStore(),
  liquidityTabState: new LiquidityTabStore(),
  CEXBotState: new CEXBotStore(),
  DEXBotState: new DEXBotStore(),
  chainsInfoState: chainsStore,
  chainMetaState: chainMetaStore,
  userConnectionState: userConnection,
  googleAuthState: new GoogleAuth(),
  timezone: new TimezoneStore() as ITimezoneState,
};

export type AppState = typeof appState;

const useAppState = (): AppState => useLateInitContext(AppContext);

export default useAppState;
