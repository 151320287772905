import { PriceFormatterFn } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { GraphDataRequest } from "src/components/shared/Graph";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { GraphsContext } from "src/context/DEXV2/DEXV2Stats/Graphs/Graphs";
import { toRounding } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BalancesGraph } from "./BalancesGraph";
import { GraphType, GraphsHeader } from "./GraphsHeader";
import { NativeGraph } from "./NativeGraph";
import * as styles from "./style";

export interface BaseGraphsProps {
  onRefresh?: GraphDataRequest;
  isLoading?: boolean;
}

export const priceFormatter: (precision: number) => PriceFormatterFn = (precision) => (price) =>
  numFormatter(toRounding(price, precision));

export const Graphs = observer(() => {
  const botState = useLateInitContext(DEXV2BotContext);
  const state = useLateInitContext(GraphsContext);

  const { botUUID } = botState;

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getGraphs();
  }, [botUUID, state]);

  const [currentType, setCurrentType] = useState<GraphType>("Balances");

  const getCurrentGraph = () => {
    switch (currentType) {
      case "Balances": {
        return (
          <BalancesGraph
            key={currentType}
            data={state.balancesPoints}
            quoteTicker={state.quoteTicker}
            baseTicker={state.baseTicker}
            quotePrecision={state.quotePrecision}
            basePrecision={state.basePrecision}
            isLoading={state.loading}
          />
        );
      }
      case "Native": {
        return (
          <NativeGraph
            key={currentType}
            data={state.nativePoints}
            ticker={state.nativeTicker}
            nativePrecision={state.nativePrecision}
            isLoading={state.loading}
          />
        );
      }
    }
  };

  const currentGraph = getCurrentGraph();

  return (
    <styles.Container>
      <GraphsHeader
        currentType={currentType}
        onTypeChange={setCurrentType}
        onRefresh={state.getGraphs}
      />
      {currentGraph}
    </styles.Container>
  );
});
