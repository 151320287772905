import { makeAutoObservable, runInAction } from "mobx";
import { getStrategy } from "src/api/expertSystem";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { logError } from "src/helpers/network/logger";
import { ConditionCompareType, ConditionCreatedModule, IStrategy } from "src/modules/expertSystem";

export const MODULE_COMPARE_LABEL_MAP: Record<ConditionCompareType, string> = {
  lte: "<=",
  lt: "<",
  eq: "=",
  ne: "!=",
  gt: ">",
  gte: ">=",
};

const convertConditions = (conditions: ConditionCreatedModule[]) => {
  const convertedConditions = conditions.map((el) => {
    const humanCompare = el.soft_params.compare
      ? MODULE_COMPARE_LABEL_MAP[el.soft_params.compare]
      : "";

    const convertCondition = {
      ...el,
      updated_at: el.updated_at ? unixToDateFormat(el.updated_at, "FullDate") : "",
      soft_params: {
        ...el.soft_params,
        compare: humanCompare,
      },
    };

    if (convertCondition.type === "time") {
      return {
        ...convertCondition,
        current: convertCondition.current
          ? unixToDateFormat(parseInt(convertCondition.current, 10), "FullDate")
          : "",
        soft_params: {
          ...convertCondition.soft_params,
          value: convertCondition.soft_params.value
            ? unixToDateFormat(parseInt(convertCondition.soft_params.value, 10), "FullDate")
            : "",
        },
      };
    }

    return convertCondition;
  });

  return convertedConditions;
};

export default class StrategyInfoModalStore {
  private _strategy: IStrategy | undefined;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get strategy() {
    if (!this._strategy) return;

    const convertStrategy = {
      ...this._strategy,
      conditions: convertConditions(this._strategy.conditions),
    };

    return convertStrategy as never as IStrategy;
  }

  get strategyStatus() {
    return this._strategy?.status;
  }

  get isLoading() {
    return this._isLoading;
  }

  getStrategy = (uuid: string) => {
    this._getStrategy(uuid);
  };

  private _getStrategy = async (uuid: string) => {
    runInAction(() => {
      this._isLoading = true;
    });

    try {
      const { isError, data } = await getStrategy(uuid);

      if (!isError) {
        runInAction(() => {
          this._strategy = data;
        });
      }
    } catch (error) {
      logError(error);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  destroy = () => {};
}
