import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import useLocalStore from "src/hooks/useLocalStore";
import { useThemeMode } from "src/hooks/useThemeMode";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { SantimentStore } from "src/state/CEX/CEXDashboard/SantimentStore";
import { Card, CardProps } from "../shared/Card";
import { Santiment } from "./Santiment";

interface SantimentWrapperProps {
  party: string;
  range: DateTimeRange;
}

export const SantimentCard = observer(
  ({ party, range, title, ...props }: SantimentWrapperProps & CardProps) => {
    const theme = useThemeMode();
    const { data, loading, getSantimentData } = useLocalStore(SantimentStore);
    const [startDate, endDate] = range;

    useEffect(() => {
      if (party) getSantimentData(party);
    }, [getSantimentData, party]);

    return (
      <Card title={title} loading={loading} {...props}>
        {!loading && (
          <Santiment
            theme={theme}
            startDate={startDate?.toISOString()}
            endDate={endDate?.toISOString()}
            slug={data?.slug}
            ticker={data?.ticker}
            style={{ padding: "8px" }}
          />
        )}
      </Card>
    );
  }
);
