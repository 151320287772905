import { makeAutoObservable } from "mobx";
import { ISwapModules } from "..";
import { V2PriceCalculatorStore } from "./PriceCalculator";
import { V2RouterProvider } from "./Providers/V2RouterProvider";
import V2SwapWidgetStore, { IV2SwapWidgetParams } from "./SwapWidget";

export interface IV2SwapModulesParams
  extends Omit<IV2SwapWidgetParams, "calculatorState" | "routerProvider"> {}

export class V2SwapModulesStore implements ISwapModules {
  private _swapWidgetState;

  private _calculatorState;

  private _routerProvider;

  constructor(params: IV2SwapModulesParams) {
    makeAutoObservable(this);

    this._routerProvider = new V2RouterProvider({
      factoryAddressProvider: params.swapInfoProvider,
      chainProvider: params.chainInfoProvider.chainProvider,
    });

    this._calculatorState = new V2PriceCalculatorStore({
      ...params,
      routerProvider: this._routerProvider,
      pairAddressProvider: params.swapInfoProvider,
    });

    this._swapWidgetState = new V2SwapWidgetStore({
      ...params,
      calculatorState: this._calculatorState,
      routerProvider: this._routerProvider,
    });
  }

  get swapWidgetState() {
    return this._swapWidgetState;
  }

  get calculatorState() {
    return this._calculatorState;
  }

  get router() {
    return this._routerProvider.router;
  }

  destroy = () => {
    this._swapWidgetState.destroy();
    this._calculatorState.destroy();
    this._routerProvider.destroy();
  };
}
