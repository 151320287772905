import { IReactionDisposer, makeAutoObservable, observable, reaction } from "mobx";
import { Disposable } from "src/helpers/utils";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import { ISwapProviders } from "..";
import { ISwapVersionProvider } from "../Version/DEXV2SwapVersionStore";
import { EmptySwapModulesStore } from "./empty";
import { IRouter } from "./shared/Swap/Router";
import { IPriceCalculator } from "./shared/SwapModules/PriceCalculator";
import { ISwapWidget } from "./shared/SwapModules/SwapWidget";
import { IV2SwapModulesParams, V2SwapModulesStore } from "./v2";
import { V3SwapModulesStore } from "./v3";

export interface ISwapModulesParams extends IV2SwapModulesParams {
  swapProviders: ISwapProviders;
}

export interface IRouterProvider {
  get router(): IRouter | null;
}
export interface ISwapModules extends IRouterProvider, Disposable {
  swapWidgetState: ISwapWidget;
  calculatorState: IPriceCalculator;
}

export class SwapModulesStore implements ISwapModules {
  private _versionProvider: ISwapVersionProvider;

  private _modulesParams: ISwapModulesParams;

  private _currentModulesState: ISwapModules = new EmptySwapModulesStore();

  private _stateCreateReaction: IReactionDisposer;

  constructor(params: ISwapModulesParams) {
    makeAutoObservable<this, "_currentModulesState">(this, {
      _currentModulesState: observable.ref,
    });

    this._versionProvider = params.swapProviders.versionProvider;

    this._modulesParams = params;

    this._stateCreateReaction = reaction(
      () => this._version,
      (version) => {
        const modules = this._getModulesState(version, this._modulesParams);
        this._setCurrentModulesState(modules);
      }
    );
  }

  private get _version() {
    return this._versionProvider.dexVersion;
  }

  private _getModulesState = (
    version: DEXV2ExchangeVersion | null,
    params: ISwapModulesParams
  ): ISwapModules => {
    switch (version) {
      case DEXV2ExchangeVersion.V2: {
        return new V2SwapModulesStore(params);
      }
      case DEXV2ExchangeVersion.V3: {
        return new V3SwapModulesStore(params);
      }
      default: {
        return new EmptySwapModulesStore();
      }
    }
  };

  private _destroyModulesState = () => {
    this._currentModulesState.destroy();
  };

  private _setCurrentModulesState = (modules: ISwapModules) => {
    this._destroyModulesState();

    this._currentModulesState = modules;
  };

  get swapWidgetState() {
    return this._currentModulesState.swapWidgetState;
  }

  get calculatorState() {
    return this._currentModulesState.calculatorState;
  }

  get router() {
    return this._currentModulesState.router;
  }

  destroy = () => {
    this._destroyModulesState();
    this._stateCreateReaction();
  };
}
