import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { Abilities } from "src/modules/abilities";
import FundingDEXStore from "src/state/funding/fundingDEX";
import { FundingProps } from "..";
import { BotInfoProps } from "../../CEX";
import { SubTitle } from "../style";
import { FundingItem } from "./FundingItem";
import * as styles from "./style";

export interface CurrentFundingsProps extends BotInfoProps, Pick<FundingProps, "type"> {
  abilities: Abilities;
  abilityName: string;
  state: FundingDEXStore;
}

export const CurrentFundings = observer(
  ({
    type,
    state,
    botInfo: { bot_uuid, party = "" },
    abilityName,
    abilities,
  }: CurrentFundingsProps) => {
    useEffect(() => {
      if (!bot_uuid) return;
      const dexStore = state as FundingDEXStore;
      dexStore.setBotUUID(bot_uuid);
      dexStore.getAllFundings();
    }, [bot_uuid, state, type]);

    return (
      <styles.Container party={party} abilityName={abilityName} abilities={abilities}>
        <SubTitle>Current fundings</SubTitle>
        <styles.FundingList>
          {state.currentFundings.length
            ? state.currentFundings.map((el) => (
                <FundingItem
                  key={el.funding_id}
                  item={el}
                  deleteCurrentFunding={state.deleteCurrentFunding}
                />
              ))
            : "You don't have yet fundings"}
          <Loader show={state.showLoader} />
        </styles.FundingList>
      </styles.Container>
    );
  }
);
