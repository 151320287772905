import { CurrencyAmount, Token, TradeType, sqrt } from "@uniswap/sdk-core";
import { IReactionDisposer, makeAutoObservable, when } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";
import { IRouterProvider } from "../..";
import { ISwapPairAddressProvider, ISwapProviders } from "../../..";
import { IBotTradePairProvider } from "../../../../DEXV2Bots/DEXV2BotStore";
import { PairTokens } from "../../../../shared/TradePair";
import { TradeSide } from "../../../../shared/TradeToken";
import { ISwapVersionProvider } from "../../../Version/DEXV2SwapVersionStore";
import { CacheOptions, tryParsePrice, unitCurrencyAmount } from "../../../utils";
import { AbstractStableCoin } from "../../../utils/AbstractStableCoin";
import { IBaseUSDPriceProvider } from "../../shared/Providers/BaseUsdPriceProvider";
import { RefreshStateStore, RefreshType } from "../../shared/RefreshStateStore";
import { IRouteParams, SwapRoute, SwapRouteState } from "../../shared/Swap/Router";
import { IPriceCalculator } from "../../shared/SwapModules/PriceCalculator";
import { V2SwapRoute } from "../Swap/Providers/V2RouteStateProvider";

type BaseUSDSide = "base" | "usd";

type TradePairReserves = Partial<Record<TradeSide, CurrencyAmount<Token>>>;
type USDBaseReserves = Partial<Record<BaseUSDSide, CurrencyAmount<Token>>>;

type BaseUsdPair = Record<BaseUSDSide, Token>;

export interface PriceCalculatorInfo {
  baseReservesBefore: {
    base?: CurrencyAmount<Token>;
    usd?: CurrencyAmount<Token>;
  };
  baseReservesAfter: {
    base?: CurrencyAmount<Token>;
    usd?: CurrencyAmount<Token>;
  };
  quoteAmount: {
    usd?: CurrencyAmount<Token>;
    quote?: CurrencyAmount<Token>;
  };
}

export interface IPriceCalculatorParams {
  swapProviders: ISwapProviders;
  routerProvider: IRouterProvider;
  pairAddressProvider: ISwapPairAddressProvider;
}

interface RefreshOptions extends CacheOptions {
  refreshType?: RefreshType;
}

// target price calculation for a stablecoin/base pair
// with a quote assets needed info
export class V2PriceCalculatorStore implements IPriceCalculator, Disposable {
  private _routerProvider: IRouterProvider;

  private _rawTargetPrice = "";

  private _swapRoute: SwapRoute | null = null;

  private _tradePairProvider: IBotTradePairProvider;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _versionProvider: ISwapVersionProvider;

  private _refreshState: RefreshStateStore;

  private _initialFetchReaction: IReactionDisposer;

  private _pairAddressProvider: ISwapPairAddressProvider;

  constructor({
    swapProviders: { tradePairProvider, baseUSDPriceProvider, versionProvider },
    routerProvider,
    pairAddressProvider,
  }: IPriceCalculatorParams) {
    makeAutoObservable(this);

    this._routerProvider = routerProvider;

    this._tradePairProvider = tradePairProvider;

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._versionProvider = versionProvider;

    this._pairAddressProvider = pairAddressProvider;

    this._refreshState = new RefreshStateStore();

    this._initialFetchReaction = when(
      () => this.canQuery,
      () => {
        this.forceRefreshCalculator();
      }
    );

    makeLoggable<any>(this, {
      _tradeReserves: true,
      _parsedTargetPrice: true,
      _pairK: true,
      _swapRoute: true,
    });
  }

  get enabled() {
    return true;
  }

  private _setRefreshing = (loading: boolean, type: RefreshType) => {
    this._refreshState.setRefreshing(loading, type);
  };

  get isRefreshing() {
    return this._refreshState.isRefreshing;
  }

  get isForceRefreshing() {
    return this._refreshState.isForceRefreshing;
  }

  private get _router() {
    return this._routerProvider.router;
  }

  private get _tradePair(): Partial<PairTokens> {
    return this._tradePairProvider.tradePair?.pair ?? {};
  }

  private get _baseUSDPair(): Partial<BaseUsdPair> {
    const { base: baseToken } = this._tradePair;
    if (!baseToken) return {};
    const usdToken = new AbstractStableCoin(baseToken.chainId);
    return { base: baseToken, usd: usdToken };
  }

  private get _baseUSDPrice() {
    return this._baseUSDPriceProvider.baseUSDPrice;
  }

  private _setSwapRoute = (route: SwapRoute) => {
    this._swapRoute = route;
  };

  updateTargetPrice = (newPrice: string) => {
    this._rawTargetPrice = newPrice;
  };

  get targetPrice() {
    return this._rawTargetPrice;
  }

  // get in/out reserves of possibly multihop route
  private get _tradeReserves() {
    const route = this._swapRoute?.route as V2SwapRoute["route"] | undefined;
    const pairs = route?.pairs;
    const midPrice = route?.midPrice;
    if (!route || !pairs || !midPrice) {
      return null;
    }

    const tokenIn = route.input;
    const pairIn = pairs[0];
    const reservesIn = pairIn.reserveOf(tokenIn);

    // approximate reservesOut with midPrice as ratio between in/out token "pair"
    const reservesOut = midPrice.quote(reservesIn);
    return [reservesIn, reservesOut] as const;
  }

  // verify that route In/Out is Base/Quote
  // since we query static path
  private get _isTradePairRoute() {
    const route = this._swapRoute?.route as V2SwapRoute["route"] | undefined;
    const { base: baseToken, quote: quoteToken } = this._tradePair;
    if (!route || !baseToken || !quoteToken) {
      return false;
    }
    const { path } = route;
    const tokenIn = path[0];
    const tokenOut = path[path.length - 1];

    if (tokenIn.equals(baseToken) && tokenOut.equals(quoteToken)) {
      return true;
    }

    return false;
  }

  private get _tradePairReserves(): TradePairReserves {
    const validRoute = this._isTradePairRoute;
    const reserves = this._tradeReserves;
    if (!validRoute || !reserves) {
      return {};
    }

    const [reserveIn, reserveOut] = reserves;

    return {
      base: reserveIn,
      quote: reserveOut,
    };
  }

  private get _usdBaseReserves(): USDBaseReserves {
    const { base: baseReserves } = this._tradePairReserves;
    const baseUSDPrice = this._baseUSDPrice;
    if (!baseReserves || !baseUSDPrice) {
      return {};
    }

    // usd/base pair reserves are based on base reserves size and scaled by price for usd
    const baseReservesInUSD = baseUSDPrice.quote(baseReserves);

    return {
      base: baseReserves,
      usd: baseReservesInUSD,
    };
  }

  // target price in usd of usd/base pair
  private get _parsedTargetPrice() {
    const validRoute = this._isTradePairRoute;
    const { base: baseToken, usd: usdToken } = this._baseUSDPair;
    const rawTargetPrice = this._rawTargetPrice;

    if (!validRoute || !baseToken || !usdToken || !rawTargetPrice) {
      return undefined;
    }

    const baseUsdPrice = tryParsePrice(rawTargetPrice, usdToken, baseToken);

    return baseUsdPrice;
  }

  // we denote usd reserves before/after as
  // (base reserves in usd * 2) ~= total pool usd value
  private get _usdBaseReservesBefore() {
    const { base: baseReserves } = this._usdBaseReserves;
    const baseUSDPrice = this._baseUSDPrice;
    if (!baseReserves || !baseUSDPrice) {
      return undefined;
    }

    const usdBaseReserves = baseUSDPrice.quote(baseReserves);

    return usdBaseReserves.multiply(2);
  }

  private get _baseReservesBefore() {
    const { base: baseReserves } = this._usdBaseReserves;
    return { usd: this._usdBaseReservesBefore, base: baseReserves };
  }

  private get _pairK() {
    const { base: baseReserves, usd: quoteReserves } = this._usdBaseReserves;
    if (!baseReserves || !quoteReserves) {
      return undefined;
    }
    return baseReserves.multiply(quoteReserves.quotient).asFraction;
  }

  private get _rawRemainingBaseReserves() {
    const K = this._pairK;
    const price = this._parsedTargetPrice;
    if (!K || !price) {
      return undefined;
    }

    // B_R = sqrt(K / PRICE);
    const reservesSquared = K.divide(price.asFraction);
    return sqrt(reservesSquared.quotient);
  }

  private get _remainingBaseReserves() {
    const { base: baseToken } = this._baseUSDPair;
    const rawReserves = this._rawRemainingBaseReserves;
    if (!baseToken || !rawReserves) {
      return undefined;
    }

    return CurrencyAmount.fromRawAmount(baseToken, rawReserves);
  }

  private get _remainingUSDBaseReserves() {
    const remainingBaseReserves = this._remainingBaseReserves;
    const baseUSDTargetPrice = this._parsedTargetPrice;
    if (!remainingBaseReserves || !baseUSDTargetPrice) {
      return undefined;
    }

    // we use target price in after reserves to denote "increase" in usd pool value
    const remainingUSDBaseReserves = baseUSDTargetPrice.quote(remainingBaseReserves);

    return remainingUSDBaseReserves.multiply(2);
  }

  private get _baseReservesAfter() {
    const remainingBaseReserves = this._remainingBaseReserves;
    const remainingUSDBaseReserves = this._remainingUSDBaseReserves;
    return { usd: remainingUSDBaseReserves, base: remainingBaseReserves };
  }

  // quote is usd in usd/base pair
  private get _rawRequiredQuoteAmount() {
    const remainingBaseReserves = this._rawRemainingBaseReserves;
    const K = this._pairK;
    const { usd: quoteReserves } = this._usdBaseReserves;
    if (!remainingBaseReserves || !K || !quoteReserves) {
      return undefined;
    }

    // Q = K/B_R - Q_R
    const quoteAmount = K.divide(remainingBaseReserves).subtract(quoteReserves);
    return quoteAmount.quotient;
  }

  private get _requiredUSDQuoteAmount() {
    const { usd: quoteToken } = this._baseUSDPair;
    const rawQuoteAmount = this._rawRequiredQuoteAmount;
    if (!quoteToken || !rawQuoteAmount) {
      return undefined;
    }

    return CurrencyAmount.fromRawAmount(quoteToken, rawQuoteAmount);
  }

  // required usd amount in trade quote
  private get _requiredQuoteAmount() {
    const midPrice = this._swapRoute?.route?.midPrice;
    const validRoute = this._isTradePairRoute;
    const usdQuoteAmount = this._requiredUSDQuoteAmount;
    const baseUSDPrice = this._baseUSDPrice;
    if (!validRoute || !midPrice || !usdQuoteAmount || !baseUSDPrice) {
      return undefined;
    }

    // usdInQuote = B/usd*Q/B
    const usdInQuotePrice = baseUSDPrice.invert().multiply(midPrice);
    const quoteAmount = usdInQuotePrice.quote(usdQuoteAmount);
    return quoteAmount;
  }

  private get _quoteAmount() {
    const requiredQuoteAmount = this._requiredQuoteAmount;
    const requiredUSDQuoteAmount = this._requiredUSDQuoteAmount;
    return { usd: requiredUSDQuoteAmount, quote: requiredQuoteAmount };
  }

  get info(): PriceCalculatorInfo {
    const quoteAmount = this._quoteAmount;
    const baseReservesAfter = this._baseReservesAfter;
    const baseReservesBefore = this._baseReservesBefore;

    return {
      quoteAmount,
      baseReservesAfter,
      baseReservesBefore,
    };
  }

  private get _poolAddress() {
    return this._pairAddressProvider.pairAddress;
  }

  // get route params to perform static Base/Quote swap
  private get _routeParams(): Omit<IRouteParams, "options"> | null {
    const { base: baseToken, quote: quoteToken } = this._tradePair;
    const poolAddress = this._poolAddress;
    if (!baseToken || !quoteToken || !poolAddress) return null;
    const type = TradeType.EXACT_INPUT;
    const path = [baseToken, quoteToken];
    const unitAmount = unitCurrencyAmount(baseToken);

    return { amount: unitAmount, swapType: type, path, pools: [poolAddress] };
  }

  private get _routerDeps() {
    const router = this._router;
    const routeParams = this._routeParams;
    if (!router || !routeParams) return null;
    return { router, routeParams };
  }

  private _routerTrade = async (options?: CacheOptions) => {
    const routerDeps = this._routerDeps;
    if (!routerDeps) return;

    const { routeParams, router } = routerDeps;

    const route = await router.route({ ...routeParams, options });
    if (route.state === SwapRouteState.Invalid) {
      return;
    }

    this._setSwapRoute(route);
  };

  private _refreshBasePrice = async (options?: CacheOptions) => {
    await this._baseUSDPriceProvider.getBaseUSDPrice(options);
  };

  get canQuery() {
    return Boolean(this._routerDeps) && this._baseUSDPriceProvider.canQuery;
  }

  private _refreshCalculator = async (options?: RefreshOptions) => {
    const refreshType = options?.refreshType ?? "normal";

    this._setRefreshing(true, refreshType);
    try {
      await Promise.all([this._routerTrade(options), this._refreshBasePrice(options)]);
    } catch (err) {
      logError(err);
    } finally {
      this._setRefreshing(false, refreshType);
    }
  };

  refreshCalculator = async () => {
    await this._refreshCalculator({ useCache: true, refreshType: "normal" });
  };

  forceRefreshCalculator = async () => {
    await this._refreshCalculator({ useCache: false, refreshType: "force" });
  };

  destroy = () => {
    this._initialFetchReaction();
  };
}
