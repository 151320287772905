import { TradeType } from "@uniswap/sdk-core";
import { IReactionDisposer, makeAutoObservable, reaction, when } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";
import { IWalletConnectionProvider } from "src/state/chain/ChainConnectionStore";
import { IRouterProvider } from "../..";
import { BotTransactionFees, ISwapInfoProvider, ISwapProviders } from "../../..";
import { IBotChainInfoProvider, IBotTradePairProvider } from "../../../../DEXV2Bots/DEXV2BotStore";
import { INativeUSDPriceProvider } from "../../../../Providers/NativeUSDPriceProvider";
import { ISwapVaultProvider, ISwapVaultsBalancesProvider } from "../../../Vaults";
import { ISwapVersionProvider } from "../../../Version/DEXV2SwapVersionStore";
import { CacheOptions, DEFAULT_DEADLINE_FROM_NOW } from "../../../utils";
import {
  BlockTimestampProvider,
  IBlockTimestampProvider,
} from "../../shared/Providers/BlockTimestampProvider";
import { RefreshStateStore, RefreshType } from "../../shared/RefreshStateStore";
import { ISlippage, Slippage, SlippageStore } from "../../shared/SlippageStore";
import { IPriceCalculator } from "../../shared/SwapModules/PriceCalculator";
import { ISwapWidget } from "../../shared/SwapModules/SwapWidget";
import { SwapStateStore } from "../../shared/SwapStateStore";
import {
  ISwapExecuteParams,
  ISwapExecutor,
  SwapExecutorStore,
  SwapParams,
  SwapPermissionParams,
} from "./SwapExecutorStore";
import { SwapInfoStore } from "./SwapInfoStore";

export interface ISwapSlippageProvider {
  get slippage(): Slippage;

  get transactionFees(): BotTransactionFees;
}

export interface IV2SwapWidgetParams {
  vaultProvider: ISwapVaultProvider;
  vaultsBalancesProvider: ISwapVaultsBalancesProvider;
  swapInfoProvider: ISwapInfoProvider;
  calculatorState: IPriceCalculator;
  chainInfoProvider: IBotChainInfoProvider;
  walletConnectionProvider: IWalletConnectionProvider;
  swapProviders: ISwapProviders;
  routerProvider: IRouterProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
}

export interface RefreshOptions extends CacheOptions {
  refreshCalculator?: boolean;
  refreshBalances?: boolean;
  refreshType?: RefreshType;
}

export default class V2SwapWidgetStore implements ISwapWidget, Disposable {
  private _botUUID = "";

  private _vaultProvider: ISwapVaultProvider;

  private _swapInfoProvider: ISwapInfoProvider;

  private _vaultsBalancesProvider: ISwapVaultsBalancesProvider;

  private _chainInfoProvider: IBotChainInfoProvider;

  private _walletConnectionProvider: IWalletConnectionProvider;

  private _blockTimestampProvider: IBlockTimestampProvider;

  private _swapState: SwapStateStore;

  private _swapInfoState: SwapInfoStore;

  private _swapExecutor: ISwapExecutor;

  private _refreshState: RefreshStateStore;

  private _slippageState: ISlippage;

  private _isSwapping = false;

  private _tradePairReaction: IReactionDisposer;

  private _slippageChangedReaction: IReactionDisposer;

  private _tradePairProvider: IBotTradePairProvider;

  private _versionProvider: ISwapVersionProvider;

  private _calculatorState: IPriceCalculator;

  constructor({
    vaultProvider,
    vaultsBalancesProvider,
    swapInfoProvider,
    chainInfoProvider,
    walletConnectionProvider,
    calculatorState,
    swapProviders,
    routerProvider,
    nativeUSDPriceProvider,
  }: IV2SwapWidgetParams) {
    makeAutoObservable<this, "_tokenPair">(this, { _tokenPair: false });

    this._vaultProvider = vaultProvider;
    this._vaultsBalancesProvider = vaultsBalancesProvider;
    this._swapInfoProvider = swapInfoProvider;
    this._calculatorState = calculatorState;
    this._chainInfoProvider = chainInfoProvider;
    this._walletConnectionProvider = walletConnectionProvider;
    this._tradePairProvider = swapProviders.tradePairProvider;
    this._versionProvider = swapProviders.versionProvider;

    this._swapExecutor = new SwapExecutorStore();

    this._blockTimestampProvider = new BlockTimestampProvider(
      this._chainInfoProvider.chainProvider
    );

    this._refreshState = new RefreshStateStore();

    this._slippageState = new SlippageStore();

    this._swapState = new SwapStateStore();
    this._swapInfoState = new SwapInfoStore({
      swapState: this._swapState,
      chainProvider: this._chainInfoProvider.chainProvider,
      vaultProvider: this._vaultProvider,
      slippageProvider: this,
      gasLimitProvider: this._swapInfoProvider,
      tradePairProvider: this._tradePairProvider,
      routerProvider,
      baseUSDPriceProvider: swapProviders.baseUSDPriceProvider,
      nativeUSDPriceProvider,
      poolAddressProvider: this._swapInfoProvider,
    });

    this._tradePairReaction = when(
      () => Boolean(this._tradePair),
      () => {
        const pair = this._tradePair;
        if (!pair) return;

        this._swapState.setSwapTokens(pair);
      }
    );

    this._slippageChangedReaction = reaction(
      () => this._swapInfoProvider.slippage,
      (slippage) => {
        this.setSlippage(slippage);
      },
      { fireImmediately: true }
    );

    makeLoggable<any>(this, { deadline: true, _swapCallParams: true, slippage: true });
  }

  get enabled() {
    return true;
  }

  private _setRefreshing = (loading: boolean, type: RefreshType) => {
    this._refreshState.setRefreshing(loading, type);
  };

  get isRefreshing() {
    return this._refreshState.isRefreshing;
  }

  get isForceRefreshing() {
    return this._refreshState.isForceRefreshing || this._isSwapping;
  }

  private _setIsSwapping = (swapping: boolean) => {
    this._isSwapping = swapping;
  };

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _walletProvider() {
    const isWalletInit = this._walletConnectionProvider.isInit;
    if (!isWalletInit) {
      return null;
    }
    return this._walletConnectionProvider.ethersProvider;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  get slippage() {
    return this._slippageState.slippage;
  }

  setSlippage = (slippage: string) => {
    this._slippageState.setSlippage(slippage);
  };

  private get _chainInfo() {
    return this._chainInfoProvider.chainProvider.currentChain;
  }

  get nativeTicker() {
    return this._chainInfo?.native ?? "Native";
  }

  get transactionFees() {
    return this._swapInfoProvider.transactionFees;
  }

  get swapState() {
    return this._swapState;
  }

  get swapInfoState() {
    return this._swapInfoState;
  }

  private get _deadline() {
    const blockTimestamp = this._blockTimestampProvider.timestamp;
    if (!blockTimestamp) return undefined;
    return blockTimestamp.add(DEFAULT_DEADLINE_FROM_NOW);
  }

  get currentVault() {
    return this._vaultProvider.vault;
  }

  private get _vaultContract() {
    const provider = this._walletProvider;
    const { type, address } = this.currentVault;
    if (!provider || !address) return null;

    const signer = provider.getSigner();

    const contract = this._versionProvider.getVaultContract(type, address, signer);

    return contract;
  }

  private _refreshCalculator = async () => {
    await this._calculatorState.forceRefreshCalculator();
  };

  private _refreshBalances = async (options?: CacheOptions) => {
    await this._vaultsBalancesProvider.refreshChainBalances();
  };

  private _refreshSwap = async (options?: RefreshOptions) => {
    const refreshCalculator = async () => {
      if (options?.refreshCalculator) {
        await this._refreshCalculator();
      }
    };

    const refreshType = options?.refreshType ?? "normal";

    const refreshBalances = async (cacheOptions?: CacheOptions) => {
      if (options?.refreshBalances) {
        await this._refreshBalances(cacheOptions);
      }
    };

    this._setRefreshing(true, refreshType);
    try {
      await Promise.all([
        this._swapInfoState.calculateInfo(options),
        this._blockTimestampProvider.getBlockTimestamp(options),
        refreshCalculator(),
        refreshBalances(options),
      ]);
    } catch (err) {
      logError(err);
    } finally {
      this._setRefreshing(false, refreshType);
    }
  };

  refreshSwap = async () => {
    await this._refreshSwap({
      useCache: true,
      refreshCalculator: false,
      refreshBalances: false,
      refreshType: "normal",
    });
  };

  forceRefreshSwap = async (options?: Omit<RefreshOptions, "useCache" | "refreshType">) => {
    await this._refreshSwap({
      useCache: false,
      refreshCalculator: true,
      refreshBalances: true,
      refreshType: "force",
      ...options,
    });
  };

  private get _swapParams(): SwapParams | undefined {
    const {
      trade: { trade },
      slippage,
    } = this._swapInfoState.info;

    const deadline = this._deadline;

    if (!trade || !slippage || !deadline) return undefined;

    const amountIn = trade.maximumAmountIn(slippage);
    const amountOut = trade.minimumAmountOut(slippage);

    const path = trade.route.path.map((token) => token.address);
    const useReceiver = false;

    if (trade.tradeType === TradeType.EXACT_INPUT) {
      return {
        method: "swapExactTokensForTokens",
        amountIn,
        amountOutMin: amountOut,
        deadline,
        path,
        useReceiver,
      };
    }
    return {
      method: "swapTokensForExactTokens",
      amountInMax: amountIn,
      amountOut,
      deadline,
      path,
      useReceiver,
    };
  }

  private get _swapPermissionParams(): SwapPermissionParams | undefined {
    const botId = this._swapInfoProvider.botUUID;
    const vaultType = this.currentVault.type;
    const sender = this._walletConnectionProvider.currentAccount;
    const { withdrawer } = this._swapInfoProvider;

    if (!botId || !sender || !withdrawer) {
      return undefined;
    }

    return {
      botId,
      vaultType,
      sender,
      withdrawer,
    };
  }

  private get _swapExecuteParams(): ISwapExecuteParams | undefined {
    const vaultContract = this._vaultContract;
    const swapParams = this._swapParams;
    const permissionParams = this._swapPermissionParams;
    if (!vaultContract || !swapParams || !permissionParams) return undefined;
    const setLoading = this._setIsSwapping;
    return { vaultContract, swapParams, permissionParams, setLoading };
  }

  get swapParamsReady() {
    const params = this._swapExecuteParams;
    return Boolean(params);
  }

  swap = async () => {
    const swapExecuteParams = this._swapExecuteParams;
    if (!swapExecuteParams) return;
    try {
      // refresh swap info before executing swap with permissions
      await this.forceRefreshSwap({
        refreshBalances: false,
        refreshCalculator: false,
      });

      const receipt = await this._swapExecutor.swap(swapExecuteParams);

      if (receipt) {
        await this.forceRefreshSwap();
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    this._tradePairReaction();
    this._slippageChangedReaction();
    this._swapInfoState.destroy();
  };
}
