import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { getDEXNotes, setDEXNotes } from "src/api/bots/CEX/stats";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { Selector } from "src/components/shared/Forms/Selectors";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import useAppState from "src/state";
import { BotInfo } from "../../CEX";
import { BotInfoDEX } from "./BotInfoDEX";
import { BotInfoDEXFallback } from "./BotInfoDEX/styles";
import { Charts } from "./Charts";
import { Lists } from "./Lists";
import * as styles from "./style";

const intervals = [
  { value: 15, label: "Last 15 minutes" },
  { value: 30, label: "Last 30 minutes" },
  { value: 60, label: "Last 1 hour" },
  { value: 180, label: "Last 3 hour" },
  { value: 360, label: "Last 6 hour" },
  { value: 720, label: "Last 12 hour" },
  { value: 1440, label: "Last 24 hour" },
  { value: 4320, label: "Last 3 days" },
  { value: 10080, label: "Last week" },
];

interface StatsProps {
  botInfo: BotInfo;
}

export const Stats = observer(({ botInfo: { bot_uuid, market, party } }: StatsProps) => {
  const showTabs = useFindWidth(1100);
  // const [select, setSelect] = useState(720);
  const { DEXStatsState: state, userState, WalletsState } = useAppState();

  useSubscribableStore(WalletsState);

  useEffect(() => {
    if (!bot_uuid) return;
    state.setBotUUID(bot_uuid);
    state.loadBalances();
  }, [state, bot_uuid]);

  useEffect(() => {
    if (!market) return;
    state.setMarket(market);
  }, [state, market]);

  const [token, asset] = market.split("_");

  return (
    <styles.Container>
      <styles.Title>
        {DEXStatsIcons.exclamation()}
        <styles.TextTitle> Stats for {market.split("_").join(" ")}</styles.TextTitle>
      </styles.Title>
      <styles.Content>
        <styles.Dashboards>
          <ErrorBoundary fallback={<BotInfoDEXFallback />}>
            <BotInfoDEX bot_uuid={bot_uuid} />
          </ErrorBoundary>
          <styles.DashboardsWrapper abilities={userState.abilities} party={party}>
            <styles.PNLContainer>
              <Selector
                onChange={(value) => state.setInterval(value ? value.value : 720)}
                defaultValue={intervals[5]}
                options={intervals}
              />
              <styles.PNLInfo
                token={token}
                asset={asset}
                deltaToken={String(state.deltaToken)}
                deltaTotal={String(state.deltaTotal)}
                price={state.price}
                actualPrice={null}
                fees={String(state.fees)}
                showLoader={state.showLoader}
              />
            </styles.PNLContainer>
            <PermissionWrapper party={party} abilityName="dex-notes-view">
              <ErrorBoundary fallback={<styles.NotesFallback />}>
                <styles.NotesContainer
                  getNote={getDEXNotes}
                  setNote={setDEXNotes}
                  bot_uuid={bot_uuid}
                  party={party}
                  minRows={6}
                  abilityName="dex-notes-edit"
                />
              </ErrorBoundary>
            </PermissionWrapper>
          </styles.DashboardsWrapper>
          {showTabs ? (
            <styles.TabsStats
              menuItems={[
                { label: "Charts", value: "CHARTS" },
                { label: "Wallets", value: "WALLETS" },
              ]}
              defaultKey="CHARTS"
              type="spread"
            >
              <Charts showTabs={showTabs} token={token} asset={asset} key="CHARTS" />
              <Lists
                bot_uuid={bot_uuid}
                showTabs={showTabs}
                token={token}
                asset={asset}
                market={market}
                key="WALLETS"
              />
            </styles.TabsStats>
          ) : null}
          {!showTabs ? <Charts showTabs={showTabs} token={token} asset={asset} /> : null}
        </styles.Dashboards>
        {!showTabs ? (
          <Lists
            bot_uuid={bot_uuid}
            showTabs={showTabs}
            token={token}
            asset={asset}
            market={market}
          />
        ) : null}
      </styles.Content>
    </styles.Container>
  );
});
