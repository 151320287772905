import { StrategyModulesHardParams } from "src/modules/expertSystem";
import { Title } from "../../style";
import * as styles from "./style";

export interface HardParamsViewProps {
  name: string;
  hardParams: StrategyModulesHardParams;
}

export const HardParamsView = ({ name, hardParams }: HardParamsViewProps) => (
  <styles.Container>
    <Title>
      Name: <styles.ValueText>{name}</styles.ValueText>
    </Title>
    {(Object.keys(hardParams) as Array<keyof typeof hardParams>).map((key) => (
      <Title key={key}>
        {key}: <styles.ValueText>{hardParams[key]}</styles.ValueText>
      </Title>
    ))}
  </styles.Container>
);
