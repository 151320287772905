import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { noOp } from "src/helpers/utils";
import { Account, Credential } from "src/modules/userManager";
import { ERRORS_MSGS } from "src/validation-schemas";
import { APIStore } from "./APIStore";
import { AccStore } from "./AccStore";

export interface LabelParams {
  addLabel: (data: Record<string, string>, uuid: string) => Promise<any>;

  validate: () => boolean;
}

class AddLabelStore {
  name: string = "";

  value: string = "";

  meta: Record<string, string>;

  isLoading: boolean = false;

  nameErr = "";

  valueErr = "";

  party: string = "";

  uuid: string = "";

  showTrigger: React.Dispatch<React.SetStateAction<boolean>> = noOp;

  updateList: () => void = () => {};

  paramsStore: LabelParams;

  constructor(
    currentParty: string,
    currentUUID: string,
    account: Account,
    type: "API" | "ACCOUNT",
    API?: Credential | undefined
  ) {
    makeAutoObservable(this);

    this.party = currentParty;
    this.uuid = currentUUID;

    if (type === "API" && API) {
      this.paramsStore = new APIStore(API.id);
      this.uuid = currentUUID;
      this.meta = API.meta;
    } else {
      this.paramsStore = new AccStore();
      this.uuid = account.uuid;
      this.meta = account.meta;
    }
  }

  reset = () => {
    this.name = "";
    this.value = "";

    this.nameErr = "";
    this.valueErr = "";
  };

  setTrigger = (func: React.Dispatch<React.SetStateAction<boolean>>) => {
    this.showTrigger = func;
  };

  setUpdateList = (func: () => void) => {
    this.updateList = func;
  };

  setValue = (key: "name" | "value", value: string) => {
    this[key] = value;
  };

  getError = () => {
    if (!this.name) this.nameErr = ERRORS_MSGS.isRequired;
    if (!this.value) this.valueErr = ERRORS_MSGS.isRequired;

    if (this.name) this.nameErr = "";
    if (this.value) this.valueErr = "";
  };

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    this.getError();

    const valid = this.name && this.value && this.paramsStore.validate();

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      const newMeta = runInAction(() => Object.assign(this.meta, { [this.name]: this.value }));

      try {
        const { isError } = await this.paramsStore.addLabel(newMeta, this.uuid);
        if (!isError) {
          toast.success("Label success created", {
            autoClose: 2000,
          });
          this.showTrigger(false);
          this.updateList();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };
}

export default AddLabelStore;
