import { observer } from "mobx-react-lite";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { SaveModuleButton } from "../Buttons/SaveModuleButton";
import { PanelComponents, SettingsFormPanelsProps } from "../Volume";
import { SettingsForm } from "../shared/SettingsForm";
import { mobileMediaQuery } from "../style";
import * as styles from "./style";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";

export interface CounterProps extends SettingsFormPanelsProps {}

export const Counter = observer(({ ...props }: CounterProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const isTablet = useMediaQuery(mobileMediaQuery());
  const isMobile = useMediaQuery(mobileMediaQuery("700px"));
  const settingsGroupOrientation = isMobile ? "column" : "row";
  const settingsOrientation = isTablet ? "column" : "row";

  const panelComponents: PanelComponents = {
    btn: <SaveModuleButton module="counter_data" />,
    toggle: (
      <SettingsToggleSwitch
        checked={state.modulesStatuses("counter")}
        onChange={state.getOnHandler("counter_data")}
      />
    ),
  };

  return (
    <SettingsForm
      {...props}
      orientation={settingsOrientation}
      onSubmit={state.submitModuleHandler("counter_data")}
      loader={state.moduleLoading("counter_data")}
    >
      <styles.CounterPanel isMobile={isTablet} />
      <styles.CounterPanelGroup orientation={settingsGroupOrientation}>
        <styles.BlacklistPanel isMobile={isMobile} />
        <styles.TradePanel {...panelComponents} />
      </styles.CounterPanelGroup>
    </SettingsForm>
  );
});
