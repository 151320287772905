import { Token } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { IReactionDisposer, makeAutoObservable, when } from "mobx";
import { GroupBase } from "react-select";
import { toast } from "react-toastify";
import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { LabeledCreatableSelectorProps } from "src/components/shared/Forms/Selectors";
import { CloseModalCb } from "src/components/shared/ModalPanel";
import { IERC20__factory } from "src/contracts/factories/IERC20__factory";
import { IVaultMainUpgradeable__factory } from "src/contracts/factories/vaults/IVaultMainUpgradeable__factory";
import { IVaultMainUpgradeable } from "src/contracts/vaults/IVaultMainUpgradeable";
import { getIERC20Token } from "src/helpers/chain/contracts";
import { setData } from "src/helpers/forms/getByKey";
import { FormDataKeys, FormValidation } from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { Disposable, Extends, entries } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import DefaultFormStore, {
  FormWarnings,
  IParentFormStore,
} from "src/state/shared/DefaultFormStore";
import { isEthAddress, required, strictlyGreaterThan } from "src/validation-schemas";
import { IBotChainProvider } from "../../DEXV2Bots/DEXV2BotStore";
import { tryParseCurrencyAmount } from "../../DEXV2Swap/utils";
import TradePair from "../../shared/TradePair";
import { SetLoading } from "../GasWallets/WithdrawGasStore";
import { IBotWalletConnectionProvider } from "./BotWalletConnectionStore";
import { DEXV2VaultToken } from "./TransferStore";

export interface CreatableSelectorFormProps<Option extends SelectorValue = SelectorValue>
  extends Pick<
    LabeledCreatableSelectorProps<Option, false, GroupBase<Option>>,
    "onChange" | "value" | "options" | "errorHint" | "onCreateOption" | "isDisabled"
  > {}
interface TokenWithdraw {
  receiver: string;
  token: string;
  amount: number;
}

interface TokenWithdrawForm extends Omit<TokenWithdraw, "amount"> {
  amount: number | "";
}

export type TokenAddressMap = Record<DEXV2VaultToken, string>;
export interface TokenWithdrawParameters {
  fromVault: DEXV2Vault;
  onClose: CloseModalCb;
  setLoading: SetLoading;
  walletChainProvider: ethers.providers.Web3Provider;
  botChainProvider: IBotChainProvider;
  withdrawer: string;
  tradePair: TradePair;
  walletConnectionProvider: IBotWalletConnectionProvider;
}

type TokenWithdrawKeys = FormDataKeys<TokenWithdrawForm>;

type TokenTransferSelectors = Extends<TokenWithdrawKeys, "token">;

interface IWithdrawStore extends IParentFormStore<TokenWithdrawForm>, Disposable {}

const INITIAL_TOKEN_WITHDRAW: TokenWithdrawForm = {
  receiver: "",
  token: "",
  amount: "",
};

const addressValidator = isEthAddress();

const getCustomTokenLabel = (token: Token, index: number) => {
  const { symbol } = token;
  if (symbol) return symbol;

  const baseName = "CUSTOM";
  return index > 0 ? `${baseName}${index + 1}` : baseName;
};

const CUSTOM_TOKEN_TYPE = "custom";

type CustomTokenType = typeof CUSTOM_TOKEN_TYPE;

const isCustomTokenType = (token: string): token is CustomTokenType => token === CUSTOM_TOKEN_TYPE;

type WithdrawTokenType = DEXV2VaultToken | CustomTokenType;

export default class WithdrawStore implements IWithdrawStore {
  private _fromVault: DEXV2Vault;

  private _withdrawer: string;

  private _data: TokenWithdrawForm = INITIAL_TOKEN_WITHDRAW;

  validation: FormValidation<TokenWithdrawForm> = {
    receiver: [required(), isEthAddress()],
    token: [required(), isEthAddress()],
    amount: [required(), strictlyGreaterThan(0, "The value must be positive")],
  };

  private _closeModal: CloseModalCb;

  private _setLoading: SetLoading;

  private _customTokenLoading = false;

  private _tradePair: TradePair;

  formState: DefaultFormStore<TokenWithdrawForm>;

  private _walletChainProvider: ethers.providers.Web3Provider;

  private _botChainProvider: IBotChainProvider;

  private _walletConnectionProvider: IBotWalletConnectionProvider;

  private _walletDisconnectedReaction: IReactionDisposer;

  private _customTokens: Token[] = [];

  constructor({
    fromVault,
    withdrawer,
    tradePair,
    onClose,
    setLoading,
    walletChainProvider,
    botChainProvider,
    walletConnectionProvider: chainConnectionProvider,
  }: TokenWithdrawParameters) {
    this._fromVault = fromVault;
    this._withdrawer = withdrawer;
    this._tradePair = tradePair;
    this._closeModal = onClose;
    this._setLoading = setLoading;
    this._walletChainProvider = walletChainProvider;
    this._botChainProvider = botChainProvider;
    this._walletConnectionProvider = chainConnectionProvider;

    this._initData(withdrawer);

    this.formState = new DefaultFormStore(this);

    makeAutoObservable(this);

    this._walletDisconnectedReaction = when(
      () => this._walletConnectionProvider.connectionState !== "Connected",
      () => {
        this.closeModal();
      }
    );

    makeLoggable(this, { data: true });
  }

  private _initData = (withdrawer: string) => {
    this._data = {
      ...INITIAL_TOKEN_WITHDRAW,
      receiver: withdrawer,
    };
  };

  get data() {
    return this._data;
  }

  get customTokenLoading() {
    return this._customTokenLoading;
  }

  private _setCustomTokenLoading = (loading: boolean) => {
    this._customTokenLoading = loading;
  };

  private get _chainProvider() {
    return this._botChainProvider.chainProvider;
  }

  private get _provider() {
    return this._chainProvider.provider;
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private get _addresses() {
    return this._tradePair.addresses;
  }

  private get _tickers() {
    return this._tradePair.tickers;
  }

  private get _tokenType(): WithdrawTokenType | null {
    const tokenSelectorValue = this._selectorValue("token");
    const tokenType = tokenSelectorValue?.id?.toString();
    if (!tokenType) return null;
    if (isCustomTokenType(tokenType)) return "custom";
    return tokenType as DEXV2VaultToken;
  }

  private get _customTokenOptions(): SelectorValue[] {
    return this._customTokens.map((token, index) => ({
      id: CUSTOM_TOKEN_TYPE,
      label: getCustomTokenLabel(token, index),
      value: token.address,
    }));
  }

  private get _baseTokenOptions(): SelectorValue[] {
    return entries(this._addresses).map(([token, address]) => ({
      id: token,
      label: this._tickers[token] ?? token,
      value: address,
    }));
  }

  private get _tokenOptions(): SelectorValue[] {
    return this._baseTokenOptions.concat(this._customTokenOptions);
  }

  private _selectorOptions = (key: TokenTransferSelectors): SelectorValue[] => {
    switch (key) {
      case "token": {
        return this._tokenOptions;
      }
    }
  };

  private _selectorValue = (key: TokenTransferSelectors): SelectorValue | null => {
    switch (key) {
      case "token": {
        const address = this._data.token;
        return this._selectorOptions("token").find(({ value }) => value === address) ?? null;
      }
    }
  };

  private get _currentCustomToken() {
    const address = this._data.token;
    if (!address) return null;
    const token = this._customTokens.find((token) => token.address === address);
    return token ?? null;
  }

  private _addCustomToken = (token: Token) => {
    this._customTokens.push(token);
  };

  private _getCurrentTokenContract = (address: string) => {
    const provider = this._provider;
    if (!provider || !address) return null;

    return IERC20__factory.connect(address, provider);
  };

  private _addCustomTokenOption = async (address: string) => {
    const contract = this._getCurrentTokenContract(address);
    const chainId = this._chainId;

    if (!contract || !chainId) {
      return;
    }

    try {
      this._setCustomTokenLoading(true);

      const token = await getIERC20Token(contract, +chainId);

      if (!token) return;

      this._addCustomToken(token);

      // set custom token as current
      this._setTokenAddress(address);
    } finally {
      this._setCustomTokenLoading(false);
    }
  };

  private _setData = <K extends TokenWithdrawKeys>(
    key: K,
    value: ObjectPathValue<TokenWithdrawForm, K>
  ) => {
    setData(this._data, key, value);
  };

  private _setTokenAddress = (address: string) => {
    this._setData("token", address);
  };

  private get _currentToken() {
    const tokenType = this._tokenType;
    if (!tokenType) return null;

    if (isCustomTokenType(tokenType)) {
      return this._currentCustomToken;
    }

    const token = this._tradePair.pair[tokenType];
    return token;
  }

  private _getSelectHandler = (key: TokenTransferSelectors) => {
    switch (key) {
      case "token": {
        return (data: SelectorValue | null) => {
          const newValue = data?.value ?? "";
          const address = newValue as string;
          this._setTokenAddress(address);
        };
      }
    }
  };

  private _onCreateOption = (key: TokenTransferSelectors) => {
    switch (key) {
      case "token": {
        return (address: string) => {
          const isNotValidAddress = addressValidator(address);

          if (isNotValidAddress) return;

          this._addCustomTokenOption(address);
        };
      }
    }
  };

  getSelectorFormProps = (key: TokenTransferSelectors): CreatableSelectorFormProps => ({
    options: this._selectorOptions(key),
    value: this._selectorValue(key),
    onChange: this._getSelectHandler(key),
    onCreateOption: this._onCreateOption(key),
    errorHint: this.formState.errors[key],
    isDisabled: this._customTokenLoading,
  });

  private get _vaultContract() {
    const contractAddress = this._fromVault.address;

    return IVaultMainUpgradeable__factory.connect(
      contractAddress,
      this._walletChainProvider.getSigner()
    );
  }

  private _getAmountWei = (amount: number) => {
    const token = this._currentToken;
    if (!token) return;
    const amountWei = tryParseCurrencyAmount(amount.toString(), token);
    return amountWei?.quotient.toString();
  };

  private _tokenWithdrawToChainWithdrawParams = (
    data: TokenWithdraw
  ): Parameters<IVaultMainUpgradeable["withdraw"]> | null => {
    const { token, amount, receiver } = data;
    const amountWei = this._getAmountWei(amount);
    if (!amountWei) return null;

    return [token, receiver, amountWei];
  };

  private _withdrawTokens = async (data: TokenWithdraw) => {
    try {
      const params = this._tokenWithdrawToChainWithdrawParams(data);
      if (!params) return;

      const transaction = await this._vaultContract.withdraw(...params);
      const receipt = await transaction.wait();
      return receipt;
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  closeModal = () => {
    this._closeModal(false);
  };

  private _warningsValidation = (warnings: FormWarnings<TokenWithdraw>) => {
    const withdrawerValid = this.data.receiver === this._withdrawer;
    if (!withdrawerValid) {
      // eslint-disable-next-line no-param-reassign
      warnings.receiver = "receiver address differs from withdrawer!";
    }
    return withdrawerValid;
  };

  validateConstraints = (warnings: FormWarnings<TokenWithdraw>) =>
    this._warningsValidation(warnings);

  submit = async () => {
    this._setLoading(true);
    try {
      const data = this._data as TokenWithdraw;
      const receipt = await this._withdrawTokens(data);

      if (receipt) {
        toast.success(`Tokens withdrawn to ${data.receiver} successfully`, {
          autoClose: 2000,
        });
        this.closeModal();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._walletDisconnectedReaction();
  };
}
