import { Chart, ChartData } from "chart.js";
import { RefObject, useMemo } from "react";
import { dateDiff } from "src/helpers/dateUtils";
import {
  HOURS_MS,
  TEN_DAYS_MS,
  THREE_DAY_MS,
  ZoomPluginConfigParams,
  getZoomPluginConfig,
} from "../shared/config";

const useMinTimeRange = ({ labels }: ChartData<any, any, number>) => {
  const start = labels?.[0];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const end = labels?.[labels?.length - 1];

  if (!start || !end) return HOURS_MS;

  const rangeInDays = dateDiff(start, end);

  if (rangeInDays <= 1) return HOURS_MS;
  if (rangeInDays < 14) return THREE_DAY_MS;
  return TEN_DAYS_MS;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const zoomToStart = (chart: Chart, zoomFactor: number) => {
  const { x } = chart.getInitialScaleBounds();
  const minTime = chart.data.labels?.[0] as number;
  const minX = minTime ?? x.min;

  // force reset zoom to adjust for offset bugs
  chart.resetZoom();
  chart.zoom(zoomFactor, "resize");
  chart.pan({ x: minX }, undefined, "resize");
};

interface UseChartZoomParams {
  shouldZoom?: boolean;
  zoomFactor?: number;
  chartRef?: RefObject<Chart<any, any, any>>;
  configProps?: ZoomPluginConfigParams;
  data: ChartData<any, any, number>;
}

export const useChartZoom = ({ shouldZoom = true, configProps, data }: UseChartZoomParams) => {
  const minTimeRange = useMinTimeRange(data);

  const zoomOptions = useMemo(
    () =>
      shouldZoom
        ? getZoomPluginConfig({
            ...configProps,
            minTimeRange: minTimeRange || undefined,
          })
        : {},
    [configProps, minTimeRange, shouldZoom]
  );

  return zoomOptions;
};
