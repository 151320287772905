import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Graph, SeriesProps } from "src/components/shared/Graph";
import useLocalStore from "src/hooks/useLocalStore";
import SpreadStore, { SpreadData } from "src/state/CEX/CEXDashboard/SpreadStore";
import SeriesStore, { ISeriesState } from "src/state/Graph/SeriesStore";
import { useChartColorWheel } from "../hooks/useChartColorWheel";
import { CardProps } from "../shared/Card";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { useGraphsChartOptions } from "../shared/config/graphs";
import { LegendSeriesSelector } from "./SpreadChartLegend/LegendSeriesSelector";
import { SpreadChartTooltip } from "./SpreadChartTooltip";
import * as styles from "./style";

interface UseSeriesProps {
  data: SpreadData;
}

const useSeriesData = ({ data }: UseSeriesProps): SeriesProps[] => {
  const colorWheel = useChartColorWheel();

  const seriesProps = useMemo(
    (): SeriesProps[] =>
      data.map(({ ticker, data }, index) => {
        const color = colorWheel[index];
        const series: SeriesProps = {
          color,
          data,
          visible: index === 0,
          title: ticker,
          side: "left",
          startOptions: {
            priceFormat: {
              type: "percent",
            },
          },
          options: {
            lineWidth: 1,
          },
        };

        return series;
      }),
    [colorWheel, data]
  );

  return seriesProps;
};

export interface SpreadChartProps extends Omit<CardProps, "title"> {}

export const SpreadChart = observer((props: SpreadChartProps) => {
  const state = useLocalStore(SpreadStore);
  const seriesState: ISeriesState = useLocalStore(SeriesStore);

  const { data, getSpread, loading } = state;

  useEffect(() => {
    getSpread();
  }, [getSpread]);

  const { options } = useGraphsChartOptions();

  const seriesProps = useSeriesData({ data });

  return (
    <>
      <RefreshDataButton onRefresh={getSpread} />
      <styles.SpreadChartCard
        title="Spread"
        loading={loading}
        afterTitle={
          <LegendSeriesSelector
            data={seriesState.legendSeriesData}
            showSeries={seriesState.showSingleSeries}
          />
        }
        {...props}
      >
        <styles.Container>
          <Graph
            series={seriesProps}
            allowTimeScale={false}
            autoColorScales={false}
            options={options}
            tooltip={SpreadChartTooltip}
            showInfo={false}
            showAreaColor={false}
            seriesStateProvider={seriesState}
          />
        </styles.Container>
      </styles.SpreadChartCard>
    </>
  );
});
