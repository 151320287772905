import { ComponentPropsWithoutRef } from "react";
import { formatDecimalPercent } from "src/helpers/string";
import { PriceDirectionProps } from "../../ProfitLossChart/ProfitLossInfoPanel/InfoPanelSection/PNLValue/style";
import * as styles from "./style";

interface UsePriceChangeValueResponse extends PriceDirectionProps {
  formattedValue: string;
}

const usePriceChangeValue = (value: string): UsePriceChangeValueResponse => {
  const direction = Math.sign(+value) > 0 ? "profit" : "loss";
  const formattedValue = formatDecimalPercent(value, true, true);
  return { direction, formattedValue };
};

export interface PriceChangeTextProps extends ComponentPropsWithoutRef<"span"> {
  children: string;
}

export const PriceChangeText = ({ children: value, ...props }: PriceChangeTextProps) => {
  const { formattedValue, direction } = usePriceChangeValue(value);

  return (
    <styles.PriceChangeSpan $direction={direction} {...props}>
      {formattedValue}
    </styles.PriceChangeSpan>
  );
};
