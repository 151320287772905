import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { checkParty } from "src/helpers/checkParty";
import { Disposable } from "src/helpers/utils";
import { IAbilitiesProvider } from "src/state/User";
import { IPartyProvider } from "../DEXV2Bots/DEXV2BotStore";

export enum DEXV2Ability {
  StatsView = "dex-v2-stats-view",
  ExchangeView = "dex-v2-exchange-view",
  ExchangeTrade = "dex-v2-exchange-trade",

  NotesView = "dex-v2-notes-view",
  NotesEdit = "dex-v2-notes-edit",

  SettingsView = "dex-v2-settings-view",
  SettingsEdit = "dex-v2-settings-edit",
  InfoSettingsView = "dex-v2-info-settings-view",

  TransferWalletsView = "dex-v2-transfer-wallets-view",
  TransferWalletsEdit = "dex-v2-transfer-wallets-edit",
  SwapWalletsView = "dex-v2-swap-wallets-view",
  SwapWalletsEdit = "dex-v2-swap-wallets-edit",

  WalletsView = "dex-v2-wallets-view",
  WalletsGasWithdraw = "dex-v2-wallets-gas-withdraw",
  WalletsGasFill = "dex-v2-wallets-gas-fill",
  VaultsView = "dex-v2-vaults-view",
  VaultsTransfer = "dex-v2-vaults-transfer",
  VaultsWithdraw = "dex-v2-vaults-withdraw",

  VolumeHashView = "dex-v2-volume-hash-view",
  LimitHashView = "dex-v2-limit-hash-view",
  CounterHashView = "dex-v2-counter-hash-view",
  WalletsHashView = "dex-v2-wallets-hash-view",

  BotManage = "dex-v2-bot-manage",
}

export interface IAbilitiesParams {
  abilitiesProvider: IAbilitiesProvider;
  partyProvider?: IPartyProvider;
}

export interface IAbilitiesVerifier {
  hasAbility: (ability: DEXV2Ability) => boolean;
  setPartyProvider: (provider: IPartyProvider | null) => void;
}

export default class AbilitiesStore implements IAbilitiesVerifier, Disposable {
  private _partyProvider: IPartyProvider | null;

  private _abilitiesProvider: IAbilitiesProvider;

  constructor({ abilitiesProvider, partyProvider }: IAbilitiesParams) {
    makeAutoObservable(this, { hasAbility: false });

    this._abilitiesProvider = abilitiesProvider;
    this._partyProvider = partyProvider ?? null;
  }

  get abilities() {
    return this._abilitiesProvider.abilities;
  }

  setPartyProvider = (provider: IPartyProvider | null) => {
    this._partyProvider = provider;
  };

  get party() {
    return this._partyProvider?.party;
  }

  hasAbility = computedFn((ability: DEXV2Ability) => {
    const { party } = this;
    if (!party) {
      return false;
    }
    return checkParty(this.abilities, ability, party);
  });

  destroy = () => {};
}
