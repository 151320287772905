import { ComponentPropsWithoutRef } from "react";
import Icons from "src/icons/Icons";
import { SeparatorNum } from "../../Separator";
import * as styles from "../shared";

interface CellProps extends ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string | number;
  isProfit?: boolean;
  isArrow?: boolean;
}

const usePNLIcon = ({ isArrow, isProfit }: Pick<CellProps, "isArrow" | "isProfit">) => {
  if (isProfit !== undefined && isArrow) {
    return isProfit ? Icons.arrowUp() : Icons.arrowDown();
  }
  return null;
};

export const PNLCell = ({ label, value, isProfit, isArrow = true, ...props }: CellProps) => {
  const Icon = usePNLIcon({ isArrow, isProfit });

  return (
    <styles.Cell isProfit={isProfit} {...props}>
      <styles.Label>{label}</styles.Label>
      <styles.Value>
        <SeparatorNum value={value} />
        {Icon}
      </styles.Value>
    </styles.Cell>
  );
};
