import { makeAutoObservable } from "mobx";
import { destroyer } from "src/helpers/mobx";
import { Disposable } from "src/helpers/utils";
import { StrategyModuleCategory } from "src/modules/expertSystem";
import ExpertSystemStore from "..";
import { ModuleCreateCb } from "./CreateModuleStore";
import { ActionsInfoStoreProvider } from "./Info/ActionsInfoStoreProvider";
import { ConditionsInfoStoreProvider } from "./Info/ConditionsInfoStoreProvider";
import { IModulesInfoStore } from "./Info/ModulesInfoStore";

export type ModulesStoreMap = {
  condition: IModulesInfoStore<"condition"> & Disposable;
  action: IModulesInfoStore<"action"> & Disposable;
};

type ModuleTypeState = {
  [K in StrategyModuleCategory]: { type: K; state: ModulesStoreMap[K] };
}[StrategyModuleCategory];

export default class ModulesStore implements Disposable {
  private _mainState: ExpertSystemStore;

  private _currentType: StrategyModuleCategory = "condition";

  private _modulesStores: ModulesStoreMap;

  constructor(state: ExpertSystemStore) {
    makeAutoObservable(this);

    this._mainState = state;

    const conditionsInfoStore = new ConditionsInfoStoreProvider(this._mainState);
    const actionsInfoStore = new ActionsInfoStoreProvider(this._mainState);

    this._modulesStores = {
      condition: conditionsInfoStore.createConditionsStore(),
      action: actionsInfoStore.createConditionsStore(),
    };
  }

  get party() {
    return this._mainState.party;
  }

  get currentType() {
    return this._currentType;
  }

  get currentModuleState(): ModuleTypeState {
    const type = this._currentType;
    return { type, state: this._modulesStores[type] } as ModuleTypeState;
  }

  setCurrentType = (type: StrategyModuleCategory) => {
    this._currentType = type;
  };

  onModuleCreate: ModuleCreateCb = async ({ type }) => {
    if (type === "condition") {
      await this._modulesStores.condition.getModules(0);
    } else {
      await this._modulesStores.action.getModules(0);
    }
  };

  destroy = () => {
    destroyer(this._modulesStores);
  };
}
