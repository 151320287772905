import { ComponentPropsWithoutRef } from "react";
import { PanelHeaderOwnProps } from "src/components/shared/PanelHeader";
import { ChildrenProps } from "src/helpers/utils";
import * as styles from "./style";

export interface InfoPanelOwnProps extends Pick<PanelHeaderOwnProps, "label"> {}

export interface InfoPanelProps
  extends ChildrenProps,
    InfoPanelOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof InfoPanelOwnProps> {}

export const InfoPanel = ({ label, children, ...props }: InfoPanelProps) => (
  <styles.Container {...props}>
    <styles.Header label={label} />
    <styles.Content>{children}</styles.Content>
  </styles.Container>
);
