import { observer } from "mobx-react-lite";
import { AumSummaryTable } from "../../AumSummaryTable";
import { BalancesCharts } from "../../BalancesCharts";
import { BalancesDynamics } from "../../BalancesDynamics";
import { BalancesDynamicsContext } from "../../BalancesDynamics/context";
import { BalancesOverviewCard, BalancesOverviewInfoPanel } from "../../BalancesOverviewChart";
import { BalanceOverviewContext } from "../../BalancesOverviewChart/context";
import { TotalVolumeShare } from "../../TotalVolumeShare";
import * as styles from "./style";

export interface BalanceSummaryViewProps {}

export const BalanceSummaryView = observer(() => (
  <styles.Container>
    <AumSummaryTable style={{ width: "480px", height: "150px" }} />

    <BalanceOverviewContext.Provider>
      <BalancesOverviewCard style={{ width: "760px", height: "350px" }} />
      <BalancesOverviewInfoPanel style={{ width: "270px", height: "212px" }} />
    </BalanceOverviewContext.Provider>

    <BalancesDynamicsContext.Provider>
      <BalancesDynamics style={{ width: "460px", height: "210px" }} />
    </BalancesDynamicsContext.Provider>

    <BalancesCharts />

    <TotalVolumeShare style={{ width: "70px", height: "300px" }} />
  </styles.Container>
));
