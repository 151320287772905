import { rgba } from "polished";
import { Tooltip as BaseTooltip, ITooltip as TooltipProps } from "react-tooltip";
import styled, { css } from "styled-components";
import { CaptionBody } from "../../shared/KPICard/Caption/style";

export const Container = styled.li`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ExchangeText = styled(CaptionBody)`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const TokensText = styled(ExchangeText)`
  font-weight: ${({ theme }) => theme.fontWeights[500]};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TokensTextWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const CounterText = styled(ExchangeText).attrs({ as: "span" })`
  color: ${({ theme }) => theme.dashboard.textSecondary};

  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const TOOLTIP_CLASSNAME = "counter-tooltip";

export const TooltipSurface = css`
  width: 190px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
  background: ${({ theme }) => theme.dashboard.backgroundOverlay};
  box-shadow: 0px 3px 12px 0px ${({ theme }) => rgba(theme.dashboard.backgroundBaseContrast, 0.8)};
`;

export const CounterTooltip = styled(BaseTooltip).attrs<{}, TooltipProps>({
  className: TOOLTIP_CLASSNAME,
  noArrow: true,
  float: true,
  opacity: 1,
})`
  &.${TOOLTIP_CLASSNAME} {
    ${TooltipSurface}
  }
`;

export const CounterTooltipText = styled(CaptionBody)`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
