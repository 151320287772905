import { PARAMETER_DEPENDENCY_GRAPH } from "../consts";
import { FormControlConfig, SettingsParamsPaths } from "../types";

export const formConfigToSelectorOptions = (
  map: Record<SettingsParamsPaths, FormControlConfig>
) => {
  const keys = Object.keys(map) as SettingsParamsPaths[];

  const newMap: any = {};

  keys.forEach((key) => {
    const { label } = map[key];

    if (label) {
      newMap[key] = label;
    }
  });

  return newMap as Record<SettingsParamsPaths, string>;
};

export const getParamDependencies = (param: SettingsParamsPaths) =>
  PARAMETER_DEPENDENCY_GRAPH[param];
