import { observer } from "mobx-react-lite";
import { useCallback, useContext, useMemo } from "react";
import { Loader } from "src/components/shared/Loader";
import { calcRoundingValue, convToExp } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { ContentDirection } from "src/modules/shared";
import { ThemeContext } from "styled-components";
import { PNLCell } from "./PNLCell";
import * as styles from "./shared";

export interface PNLProps {
  token: string;
  asset?: string;
  deltaToken: string | null;
  deltaTotal: string | null;
  price: number | "NET PROFIT" | "NET LOSS";
  actualPrice: string | null;
  pricePrecision?: number;
  showLoader: boolean;
  isShortView?: boolean;
  contentDirection?: ContentDirection;
  fees?: string | undefined;
}

export const PNL = observer(
  ({
    token,
    asset = "",
    deltaToken,
    deltaTotal,
    price,
    actualPrice,
    pricePrecision,
    showLoader,
    isShortView = false,
    fees = undefined,
    contentDirection = "column",
    ...props
  }: PNLProps) => {
    const rounding = useCallback((value: number | null) => {
      if (value === null || isNaN(value)) return 0;

      const roundedValue = value.toFixed(calcRoundingValue(value));

      if (+roundedValue === 0 || `${roundedValue}` === "-0") {
        return 0;
      }
      return roundedValue;
    }, []);

    const checkProfit = useCallback(
      (value: string | null) => !!(value !== null && parseFloat(value) >= 0),
      []
    );

    const theme = useContext(ThemeContext);

    const actualPriceColor = useMemo(() => {
      if (!isNaN(Number(price)) && actualPrice !== null) {
        return Number(price) < +actualPrice ? theme.successColor : theme.dangerColor;
      }

      return theme.disableInputColor;
    }, [actualPrice, price, theme.successColor, theme.dangerColor, theme.disableInputColor]);

    const nativeValue = useMemo(() => {
      if (fees !== undefined) {
        return rounding(+fees);
      }
      if (actualPrice) {
        return convToExp(parseFloat(String(actualPrice)).toFixed(pricePrecision));
      }
      return "-";
    }, [actualPrice, fees, pricePrecision, rounding]);

    return (
      <styles.StatsContainer {...props}>
        <PNLCell
          label={asset || "USD"}
          value={deltaTotal ? rounding(parseFloat(String(deltaTotal))) : "-"}
          isProfit={checkProfit(deltaTotal)}
          style={{ flexDirection: contentDirection }}
        />
        <PNLCell
          label={token}
          value={deltaToken ? numFormatter(parseFloat(String(deltaToken))) : "-"}
          isProfit={checkProfit(deltaToken)}
          style={{ flexDirection: contentDirection }}
        />
        <PNLCell
          label="Price"
          value={convToExp(price)}
          isProfit={+price >= 0 || price === "NET PROFIT"}
          isArrow={false}
          style={{ flexDirection: contentDirection }}
        />
        {!isShortView ? (
          <PNLCell
            label={fees !== undefined ? "Native" : "Actual price"}
            value={nativeValue}
            style={{ color: actualPriceColor, flexDirection: contentDirection }}
          />
        ) : null}

        <Loader show={showLoader} />
      </styles.StatsContainer>
    );
  }
);
