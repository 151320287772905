import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { useFindWidth } from "src/hooks/useFindWidth";
import useAppState from "src/state";
import { useBotsVirtualizer } from "../../hooks/useBotsVirtualizer";
import { Header } from "../Header";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import { CEXBot } from "./CEXBot";
import { CEXBotMob } from "./CEXBotMob";

const HEADER = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Downtime", icon: "" },
  { title: "Side", icon: "" },
  { title: "Message", icon: "" },
  { title: "Comment", icon: "" },
];

export const CEXBots = observer(() => {
  const {
    cexBotsState: CEXState,
    allBotsState: { realtimeEnabled },
  } = useAppState();

  const showMobVers = useFindWidth(1050);

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobVers,
    size: CEXState.bots.length,
  });

  useEffect(() => {
    if (realtimeEnabled) {
      CEXState.resumeBotsFetching();
    } else {
      CEXState.suspendBotsFetching();
    }

    return () => {
      if (realtimeEnabled) {
        CEXState.suspendBotsFetching();
      }
    };
  }, [CEXState, realtimeEnabled, CEXState.resumeBotsFetching, CEXState.suspendBotsFetching]);

  const bots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = CEXState.bots[virtualRow.index];

    const statsLink = `/CEX/${bot.bot_uuid}/?tab=STATS`;

    const credential = {
      market: bot.market,
      name: bot.name,
      UUID: bot.bot_uuid,
      link: statsLink,
    };

    return showMobVers ? (
      <CEXBotMob
        favorite
        toggleIsWatched={CEXState.toggleIsWatched}
        key={bot.bot_uuid}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    ) : (
      <CEXBot
        favorite
        toggleIsWatched={CEXState.toggleIsWatched}
        key={bot.bot_uuid}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    );
  });

  return (
    <Container>
      {!showMobVers ? (
        <Header
          favorite
          toggleWatchList={CEXState.toggleWatchList}
          watchListEnabled={CEXState.watchListEnabled}
          cells={HEADER}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{bots}</ContentScrollWrapper>
      </Content>
      <Loader show={CEXState.showLoader && CEXState.firstLoad} />
    </Container>
  );
});
