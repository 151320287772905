import { BarElement, Chart, ChartDataset, Plugin } from "chart.js";
import { OmitStrict, isDefined } from "src/helpers/utils";

export type InOutIndicatorStatus = {
  in?: boolean;
  out?: boolean;
};

type InOutIndicatorFillColors = {
  in: string;
  neutral: string;
  out: string;
};

interface InOutIndicatorPluginOptions {
  fillColor?: Partial<InOutIndicatorFillColors>;
  offset?: number;
  gap?: number;
  radius?: number;
  clip?: boolean;
  datasetLabels?: string[];
  getInOutStatus?: (datasets: ChartDataset[], index: number, chart: Chart) => InOutIndicatorStatus;
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    inOutIndicator?: InOutIndicatorPluginOptions;
  }
}

const defaultInOutStatus: InOutIndicatorPluginOptions["getInOutStatus"] = () => ({
  in: true,
  out: true,
});

interface DrawCircleOptions {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  radius: number;
  fillColor: string;
}

const drawCircle = ({ ctx, x, y, radius, fillColor }: DrawCircleOptions) => {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.fillStyle = fillColor;
  ctx.fill();
};

interface DrawIndicatorOptions extends OmitStrict<DrawCircleOptions, "fillColor"> {
  indicator?: boolean;
  type: "in" | "out";
  fillColors: InOutIndicatorFillColors;
}

const drawIndicator = ({ indicator, type, fillColors, ...circleOptions }: DrawIndicatorOptions) => {
  if (indicator === undefined) return;

  const fillColor = indicator ? fillColors[type] : fillColors.neutral;

  drawCircle({ ...circleOptions, fillColor });
};

interface DrawIndicatorsOptions extends OmitStrict<DrawIndicatorOptions, "type" | "indicator"> {
  status: InOutIndicatorStatus;
  gap: number;
}

const drawIndicators = ({ status, gap, x, ...options }: DrawIndicatorsOptions) => {
  const { in: inStatus, out: outStatus } = status;

  const indicatorsCount = +isDefined(inStatus) + +isDefined(outStatus);

  if (indicatorsCount > 1) {
    drawIndicator({ type: "in", indicator: inStatus, x: x - gap / 2, ...options });
    drawIndicator({ type: "out", indicator: outStatus, x: x + gap / 2, ...options });
  } else {
    drawIndicator({ type: "in", indicator: inStatus, x, ...options });
    drawIndicator({ type: "out", indicator: outStatus, x, ...options });
  }
};

const getDatasetMeta = (chart: Chart, datasetLabels?: string[]) => {
  if (!datasetLabels?.length) {
    const datasetIndex = 0;
    const meta = chart.getDatasetMeta(datasetIndex);
    return meta;
  }
  const meta = chart.data.datasets
    .map((_dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      if (!meta) return null;
      return meta;
    })
    .filter((meta) => meta && !meta.hidden && datasetLabels.includes(meta.label))[0];

  return meta;
};

export const InOutIndicatorPlugin: Plugin<any> = {
  id: "inOutIndicator",
  afterDraw: (chart, _args, options: InOutIndicatorPluginOptions) => {
    const {
      fillColor: {
        in: inCircleColor = "#00FF00",
        out: outCircleColor = "#ff0000",
        neutral: neutralCircleColor = "#DDDDDD",
      } = {},
      offset = 8,
      gap = 10,
      radius = 3,
      clip = true,
      datasetLabels,
      getInOutStatus = defaultInOutStatus,
    } = options;

    const fillColors: InOutIndicatorFillColors = {
      in: inCircleColor,
      out: outCircleColor,
      neutral: neutralCircleColor,
    };

    const { ctx, chartArea: area } = chart;

    const meta = getDatasetMeta(chart, datasetLabels);

    if (!meta) return;

    const { datasets } = chart.data;

    ctx.save();

    if (clip) {
      ctx.beginPath();
      ctx.rect(area.left, area.top, area.width, area.height + 15);
      ctx.clip();
    }

    meta.data.forEach((element, index) => {
      const status = getInOutStatus(datasets, index, chart);

      const barElement = element as any as BarElement;
      const { x: barX, base: barBase } = barElement.getProps(["x", "base"]);
      const x = barX;
      const y = barBase + offset;

      drawIndicators({ status, gap, x, y, ctx, radius, fillColors });
    });

    ctx.restore();
  },

  // @ts-ignore
  descriptors: {
    _scriptable: (name: string) => name !== "getInOutStatus",
  },
};
