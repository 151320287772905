import {
  BaseSeriesColorBox,
  BaseSeriesTitle,
} from "src/components/shared/Graph/ChartLegend/Legend/style";
import styled from "styled-components";

export const SeriesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
`;

export const SeriesTitle = styled(BaseSeriesTitle)`
  color: ${({ theme }) => theme.dashboard.textSubhead};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const SeriesColorBox = styled(BaseSeriesColorBox)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;
