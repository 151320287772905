import { makeAutoObservable } from "mobx";
import { addAction, addCondition } from "src/api/expertSystem";
import {
  getActionTitleList,
  getConditionGroupTitleList,
} from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/modules";
import { getSelectorList } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { showSuccessMsg } from "src/helpers/message";
import { destroyer } from "src/helpers/mobx/destroyer";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";
import {
  ActionTypes,
  ConditionModuleGroupsNames,
  IActionModuleCreate,
  IConditionModuleCreate,
  IStrategyModuleCreate,
  IStrategyModuleCreateType,
} from "src/modules/expertSystem";
import BotListStore from "src/state/CEX/shared/PartyBotListStore";
import AccountReceiver from "src/state/shared/AccountReceiver";
import AllExchangesProvider from "src/state/shared/AllExchangesProvider";
import ExpertSystemStore from "..";

const TAB_MENU_ITEMS = ["CONDITIONS", "ACTIONS"] as const;

export const ACTION_EMPTY_MAP: {
  [Key in ActionTypes]: IStrategyModuleCreateType<Key>;
} = {
  alert_tg: {
    type: "alert_tg",
    name: "",
    party: "",
    hard_params: {
      username: "",
    },
  },
  trade: {
    type: "trade",
    name: "",
    party: "",
    hard_params: {
      account_uuid: "",
      exchange: "",
      pair: "",
    },
  },
  change_settings: {
    type: "change_settings",
    name: "",
    party: "",
    hard_params: {
      bot_uuid: "",
    },
  },
};

type CreatedModuleContext =
  | {
      type: "action";
      module: IActionModuleCreate;
    }
  | {
      type: "condition";
      module: IConditionModuleCreate;
    };

export type ModuleCreateCb = (context: CreatedModuleContext) => void;

export interface ICreateModuleParams {
  onModuleCreate?: ModuleCreateCb;
  expertState: ExpertSystemStore;
}

class CreateModuleStore implements Disposable {
  private _mainState: ExpertSystemStore;

  private _currentCondition: ConditionModuleGroupsNames = "abs_modules";

  private _currentAction: ActionTypes = "trade";

  private _childStores;

  private _isLoading = false;

  private _currentModuleTab = "CONDITIONS";

  private _onModuleCreate?: ModuleCreateCb;

  constructor({ expertState, onModuleCreate }: ICreateModuleParams) {
    makeAutoObservable(this);

    this._mainState = expertState;

    this._onModuleCreate = onModuleCreate;

    this._childStores = {
      accountsState: new AccountReceiver(this),
      botListState: new BotListStore(this),
      allExchangesProvider: new AllExchangesProvider(),
    };

    makeLoggable(this, { party: true });
  }

  get party() {
    return this._mainState.party;
  }

  private get _allExchangesProvider() {
    return this._childStores.allExchangesProvider;
  }

  get conditions() {
    return getConditionGroupTitleList();
  }

  get actions() {
    return getActionTitleList();
  }

  get currentCondition() {
    return this._currentCondition;
  }

  get currentAction() {
    return this._currentAction;
  }

  get exchangesOptions() {
    return this._childStores.accountsState.exchOptions;
  }

  get allExchangesOptions() {
    return this._allExchangesProvider.allExchangesOptions;
  }

  get isLoading() {
    return this._isLoading;
  }

  get currentModuleType() {
    if (this.currentModuleTab === "CONDITIONS") {
      return this._currentCondition;
    }
    return this._currentAction;
  }

  get botList() {
    return this._botListState.botList;
  }

  // eslint-disable-next-line class-methods-use-this
  get tabMenuItems() {
    return getSelectorList(TAB_MENU_ITEMS);
  }

  get currentModuleTab() {
    return this._currentModuleTab;
  }

  get accountsState() {
    return this._childStores.accountsState;
  }

  private get _botListState() {
    return this._childStores.botListState;
  }

  getExchanges = async () => {
    await Promise.all([
      this.accountsState.loadAccounts(),
      this._allExchangesProvider.getAllExchanges(),
    ]);
  };

  getBotList = () => {
    this._botListState.getBotList();
  };

  getSelectBot = (uuid: string) => this._botListState.getSelectBot(uuid);

  setModuleTab = (tab: string) => {
    this._currentModuleTab = tab;
  };

  setCondition = (name: ConditionModuleGroupsNames) => {
    this._currentCondition = name;
  };

  setAction = (name: ActionTypes) => {
    this._currentAction = name;
  };

  createConditionHandler = (close: () => void) => (module: IConditionModuleCreate) => {
    const requestModule = this._getRequestModuleCreate(module);

    this._createConditionModule(requestModule, close);
  };

  createActionHandler = (close: () => void) => (module: IActionModuleCreate) => {
    const requestModule = this._getRequestModuleCreate(module);

    this._createActionModule(requestModule, close);
  };

  private _getRequestModuleCreate = <T extends IStrategyModuleCreate>(module: T): T => ({
    ...module,
    party: this.party,
  });

  private setLoading = (bool: boolean) => {
    this._isLoading = bool;
  };

  private _createConditionModule = async (module: IConditionModuleCreate, close: () => void) => {
    this.setLoading(true);

    try {
      const { isError } = await addCondition(module);
      if (!isError) {
        showSuccessMsg("Condition module create successful");
        close();
        this._onModuleCreate?.({ type: "condition", module });
      }
    } catch (error) {
      logError(error);
    } finally {
      this.setLoading(false);
    }
  };

  private _createActionModule = async (module: IActionModuleCreate, close: () => void) => {
    this.setLoading(true);

    try {
      const { isError } = await addAction(module);
      if (!isError) {
        showSuccessMsg("Action module create successful");
        close();
        this._onModuleCreate?.({ type: "action", module });
      }
    } catch (error) {
      logError(error);
    } finally {
      this.setLoading(false);
    }
  };

  destroy = () => {
    destroyer(this._childStores);
  };
}

export default CreateModuleStore;
