import debounce from "lodash.debounce";
import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { ChangeEvent } from "react";
import { Disposable } from "src/helpers/utils";

type InitialState = {
  initialText?: string;
  waitTime?: number;
};

export interface ISearchStore {
  searchText: string;
  debouncedSearchText: string;
  onSearchTextChange(event: ChangeEvent<HTMLInputElement>): void;
}

export default class SearchStore implements ISearchStore, Disposable {
  private _searchText!: string;

  private _debouncedSearchText!: string;

  private readonly _debouncedSearchTextReaction: IReactionDisposer;

  private readonly _waitTime: number;

  constructor({ initialText = "", waitTime = 500 }: InitialState = {}) {
    this._waitTime = waitTime;

    makeAutoObservable(this);

    this._debouncedSearchTextReaction = reaction(
      () => this._searchText,
      debounce((searchText: string) => {
        this._setDebouncedSearchText(searchText);
      }, this._waitTime)
    );

    // reaction won't be triggered
    // on initial observable set =>
    // init search and debounced fields manually
    this._init(initialText);
  }

  private _init(initText: string) {
    this._setSearchText(initText);
    this._setDebouncedSearchText(initText);
  }

  private _setSearchText(searchText: string) {
    this._searchText = searchText;
  }

  private _setDebouncedSearchText(searchText: string) {
    this._debouncedSearchText = searchText;
  }

  get searchText() {
    return this._searchText;
  }

  onSearchTextChange(event: ChangeEvent<HTMLInputElement>) {
    const searchFieldString = event.target.value;
    this._setSearchText(searchFieldString);
  }

  get debouncedSearchText() {
    return this._debouncedSearchText;
  }

  destroy() {
    this._debouncedSearchTextReaction();
  }
}
