import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import UserGroupsStore from "src/state/UserManager/UserGroups/UserGroupsStore";

export const UserGroupsContext = createLateInitContext<UserGroupsStore>();

type ScopesProviderProps = ChildrenProps;

export const UserGroupsProvider = ({ children }: ScopesProviderProps) => {
  const { UserManagerState: state } = useAppState();

  const scopesStore: UserGroupsStore = useLocalStore(UserGroupsStore, state);

  return <UserGroupsContext.Provider value={scopesStore}>{children}</UserGroupsContext.Provider>;
};
