import { ChartArea, FontSpec, Plugin } from "chart.js";

type CircleOptions = {
  type: "circle";
  radiusDecrease?: number;
};

type RectangleOptions = {
  type: "rect";
};

interface ChartPlaceholderPluginOptions {
  fillColor?: string;
  shape?: CircleOptions | RectangleOptions;
  title?: {
    text?: string;
    font?: Partial<FontSpec>;
    color?: string;
  };
  clearSurface?: boolean;
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    chartPlaceholder?: ChartPlaceholderPluginOptions;
  }
}

function fontObjectToFontString(fontConfig: Partial<FontSpec> = {}) {
  const {
    family = "Arial",
    size = 12,
    style = "normal",
    weight = "normal",
    lineHeight = 1.2,
  } = fontConfig;

  return `${style || ""} ${weight || ""} ${size}px ${family}${lineHeight ? `/${lineHeight}` : ""}`;
}

interface DrawCircleConfig {
  fillColor: string;
  radiusDecrease: number;
}

const drawCircle = (
  ctx: CanvasRenderingContext2D,
  { left, top, right, bottom }: ChartArea,
  { fillColor, radiusDecrease }: DrawCircleConfig
) => {
  const centerX = (left + right) / 2;
  const centerY = (top + bottom) / 2;
  const r = Math.min(right - left, bottom - top) / 2;

  ctx.beginPath();
  ctx.fillStyle = fillColor;
  ctx.arc(centerX, centerY, r - radiusDecrease, 0, 2 * Math.PI);
  ctx.fill();
};

interface DrawRectConfig {
  fillColor: string;
}

const drawRect = (
  ctx: CanvasRenderingContext2D,
  { top, left, width, height }: ChartArea,
  { fillColor }: DrawRectConfig
) => {
  ctx.beginPath();
  ctx.fillStyle = fillColor;
  ctx.rect(left, top, width, height);
  ctx.fill();
};

export const ChartPlaceholder: Plugin<any> = {
  id: "chartPlaceholder",
  afterDraw(chart, _args, options: ChartPlaceholderPluginOptions) {
    const { datasets } = chart.data;
    const {
      fillColor = "rgba(128, 128, 128, 0.5)",
      shape = { type: "circle" },
      title: { font: titleFont, text = "No data", color: titleColor = "black" } = {},
      clearSurface = false,
    } = options;

    const hasData = datasets.some(
      ({ data }) => data.length > 0 && data.some((value) => value !== 0)
    );

    if (!hasData) {
      const { chartArea, ctx } = chart;

      const { left, top, right, bottom } = chartArea;

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      if (clearSurface) {
        chart.clear();
      }

      ctx.save();

      if (shape.type === "circle") {
        const { radiusDecrease = 0 } = shape;
        drawCircle(ctx, chartArea, {
          fillColor,
          radiusDecrease,
        });
      } else {
        drawRect(ctx, chartArea, { fillColor });
      }

      ctx.restore();

      if (text) {
        const fontString = fontObjectToFontString(titleFont);

        // Add text in the center of the circle
        ctx.fillStyle = titleColor; // Color for the text
        ctx.font = fontString; // Font size and family
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(text, centerX, centerY);
      }
    }
  },
};
