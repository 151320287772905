import styled from "styled-components";

export const SpectrContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
`;

export const SpectrTimeLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 15px;
  border-radius: ${({ theme }) => theme.radii["6px"]};
  background: ${({ value }) => value};
`;

export const TimeInterval = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes["9px"]};
  color: ${({ theme }) => theme.textColor};
  height: 40px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border-right: ${({ theme }) => theme.borders.solidGray}; */
  /* width: ${({ width }) => `${width}px`}; */
  ::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 30px;
    top: 5px;
    right: 0;
    background-color: #767676;
  }
  :last-of-type {
    ::after {
      display: none;
    }
  }
`;

export const SvgContainer = styled.div`
  width: 100%;
  margin-top: 35px;
`;

export const Spectr = styled.canvas`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 15px;
  border-radius: ${({ theme }) => theme.radii["10px"]};
`;
