import { Headline4 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const HeaderTitle = styled(Headline4)``;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
