import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import { SettingsPanel } from "../SettingsPanel";
import { Wrapper } from "../shared";

export const DecimalSettings = observer(() => {
  const { createBotState: state } = useAppState();

  return (
    <SettingsPanel title="Decimals" style={{ gridArea: "decimals" }}>
      <Wrapper>
        <LabeledInput
          type="number"
          label="Price Decimal"
          value={state.data.settings?.decimals?.price ?? ""}
          onChange={state.getHandler("settings.decimals.price")}
          errorHint={state.errors.settings?.decimals?.price}
          step="any"
        />
        <LabeledInput
          type="number"
          label="Amount Decimal"
          value={state.data.settings?.decimals?.amount ?? ""}
          onChange={state.getHandler("settings.decimals.amount")}
          errorHint={state.errors.settings?.decimals?.amount}
          step="any"
        />
      </Wrapper>
    </SettingsPanel>
  );
});
