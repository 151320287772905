import { observer } from "mobx-react-lite";
import { ConstraintsInput } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledCreatableSelector } from "src/components/shared/Forms/Selectors";
import { ChainConnectionContext } from "src/context/DEXV2/ChainConnectionContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { OmitStrict } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IBotChainProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import WithdrawStore, {
  TokenWithdrawParameters,
} from "src/state/DEXV2/DEXV2Stats/Vaults/WithdrawStore";
import { Form } from "../../../shared";

export interface WithdrawModalContentProps
  extends OmitStrict<
    TokenWithdrawParameters,
    "walletChainProvider" | "withdrawer" | "walletConnectionProvider" | "botChainProvider"
  > {}

export const WithdrawModalContent = observer(
  ({ fromVault, onClose, setLoading, tradePair }: WithdrawModalContentProps) => {
    const botChainProvider: IBotChainProvider = useLateInitContext(DEXV2BotContext);

    const { ethersProvider, currentAccount } = useLateInitContext(ChainConnectionContext);

    const connectionState = useLateInitContext(BotWalletConnectionContext);

    const withdrawParams: TokenWithdrawParameters = {
      fromVault,
      onClose,
      setLoading,
      walletChainProvider: ethersProvider,
      botChainProvider,
      withdrawer: currentAccount,
      tradePair,
      walletConnectionProvider: connectionState,
    };
    const state = useLocalStore(WithdrawStore, withdrawParams);

    return (
      <Form onSubmit={state.formState.submitHandler()}>
        <ConstraintsInput
          label="Wallet address"
          {...state.formState.getInputProps("receiver")}
          {...state.formState.getFieldErrorAsProps("receiver")}
        />

        <LabeledCreatableSelector label="Token" {...state.getSelectorFormProps("token")} />

        <LabeledInput
          label="Amount"
          type="number"
          step="any"
          {...state.formState.getInputProps("amount")}
        />

        <SubmitButton size="m">Withdraw</SubmitButton>
      </Form>
    );
  }
);
