import { makeAutoObservable, observable } from "mobx";
import { ChainMetaConfig, chainMetaConfig } from "src/config/chainMeta";
import { entries } from "src/helpers/utils";
import { IChainMeta } from "src/modules/chain";

export type ChainMetaMap = Map<string, IChainMeta>;

export interface IChainMetaProvider {
  get chainMetaMap(): ChainMetaMap;
}

export default class ChainMetaStore implements IChainMetaProvider {
  private _chainMetaMap: ChainMetaMap = new Map();

  constructor() {
    makeAutoObservable(this);

    this._initMap(chainMetaConfig);
  }

  private _createObservableMeta = (meta: IChainMeta) =>
    makeAutoObservable(meta, { icon: observable.ref });

  private _initMap = (chainMeta: ChainMetaConfig) => {
    entries(chainMeta).forEach(([chainId, meta]) => {
      if (meta) {
        const observableMeta = this._createObservableMeta(meta);
        this._chainMetaMap.set(chainId, observableMeta);
      }
    });
  };

  get chainMetaMap() {
    return this._chainMetaMap;
  }
}
