import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { toast } from "react-toastify";
import { changeLiquidity, startLiquidity, stopLiquidity } from "src/api/bots/CEX/liquidity";
import { stringDateToUnix } from "src/helpers/dateUtils";
import { calcRoundingValue } from "src/helpers/rounding";
import { Disposable } from "src/helpers/utils";
import { LiquidGridError, LiquidGridInfo, LiquidGridSettings } from "src/modules/exchange/trade";
import LiquidityTabStore from ".";
import { IBaseGridForm } from "./CreateGrid";
import GridForm, { IGridForm } from "./GridForm";

export const EMPTY_GRID_INFO: LiquidGridInfo = {
  id: 0,
  updatedAt: "",
  errorsInfo: {
    count: 0,
    errors: [],
    resets: [],
  },
};

const QUOTES_PRECISION: Record<string, number> = {
  BTC: 8,
  ETH: 6,
  USD: 2,
  USDC: 2,
  BCH: 5,
  VET: 2,
};

type GridStatsSection<K extends string> = Record<K, string | number>;

export type ProfitStatsKeys = "quote" | "base";
export type BuySellStatsKeys = "buy" | "sell";
export type CurrentStatsKeys = "quote" | "base" | "price";

export type GridStats = {
  profit: GridStatsSection<ProfitStatsKeys>;
  buySell: GridStatsSection<BuySellStatsKeys>;
  current: GridStatsSection<CurrentStatsKeys>;
};
export interface LiquidGridCurrentError extends LiquidGridError {
  isCurrent: boolean;
}

export interface IActiveGrid extends IBaseGridForm {
  grid: LiquidGridInfo;
  price: string | number;
  gridErrors: LiquidGridCurrentError[];
  stats: GridStats;
  stopHandler: () => Promise<void>;
  startHandler: () => Promise<void>;
}

class ActiveGrid implements Disposable, IActiveGrid {
  form: IGridForm;

  grid: LiquidGridInfo = EMPTY_GRID_INFO;

  loading = false;

  private _liquidityTab: LiquidityTabStore;

  private _settingsFetchedReaction: IReactionDisposer;

  constructor(liquidityTab: LiquidityTabStore) {
    this._liquidityTab = liquidityTab;
    this.form = new GridForm(liquidityTab);

    makeAutoObservable(this);

    this._settingsFetchedReaction = reaction(
      () => this._liquidityTab.settings,
      (settings) => {
        if (!settings) return;
        this._setData(settings);
      },
      {
        fireImmediately: true,
      }
    );
  }

  private _setData(settings: LiquidGridSettings, setGrid: boolean = true) {
    const { id, errorsInfo, updatedAt, ...formData } = settings;
    this.form.setForm(formData);
    if (setGrid) {
      this.grid = { id, errorsInfo, updatedAt };
    }
  }

  get price() {
    const quote = parseFloat(this.form.data.position.quote);
    const base = parseFloat(this.form.data.position.base);
    const price = quote / base;
    if (!isNaN(price)) {
      if (price < 0) return Math.abs(price).toFixed(calcRoundingValue(price));
      if (price > 0) {
        if (quote > 0) {
          return "NET PROFIT";
        }
        return "NET LOSS";
      }
    }
    return 0;
  }

  get gridErrors(): LiquidGridCurrentError[] {
    const { resets, errors } = this.grid.errorsInfo;

    if (!resets || !resets.length || !errors || !errors.length) return [];

    const lastReset = stringDateToUnix(resets[0]);
    if (!lastReset.valueOf()) return [];

    return errors.map((error) => {
      if (stringDateToUnix(error.time) > lastReset) {
        return { ...error, isCurrent: true };
      }
      return { ...error, isCurrent: false };
    });
  }

  get stats(): GridStats {
    const profit = {
      quote: (+this.form.data.deltas.quote)?.toFixed(
        QUOTES_PRECISION[this.form.data.pair.quote] || 4
      ),
      base: (+this.form.data.deltas.base).toFixed(4),
    };

    const buySell = {
      buy: this.form.data.buyCount,
      sell: this.form.data.sellCount,
    };

    const current = {
      quote: (+this.form.data.position.quote).toFixed(
        QUOTES_PRECISION[this.form.data.pair.quote] || 4
      ),
      base: (+this.form.data.position.base).toFixed(4),
      price: this.price,
    };

    return {
      profit,
      current,
      buySell,
    };
  }

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  private _getSubmitSettings = () => {
    const submitSettings = this.form.data;
    return submitSettings;
  };

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this.form.validate();
    if (valid) {
      this.setLoading(true);

      this.form.data.buyFees.quote = this.form.data.sellFees.quote;

      try {
        const { isError, data } = (await changeLiquidity(
          this.grid.id,
          this._getSubmitSettings()
        )) as any;

        if (!isError) {
          toast.success("Grid settings saved successfully", {
            autoClose: 2000,
          });

          this._setData(data as LiquidGridSettings);
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  stopHandler = async () => {
    this.setLoading(true);
    try {
      const { isError } = (await stopLiquidity(this.grid.id)) as any;
      if (!isError) {
        toast.success("Grid stopped", { autoClose: 2000 });
        this.form.changeState("sleeping");
      }
    } finally {
      this.setLoading(false);
    }
  };

  startHandler = async () => {
    this.setLoading(true);
    try {
      const { isError } = (await startLiquidity(this.grid.id)) as any;
      if (!isError) {
        toast.success("Grid started", { autoClose: 2000 });
        this.form.changeState("active");
      }
    } finally {
      this.setLoading(false);
    }
  };

  destroy() {
    this._settingsFetchedReaction();
  }
}

export default ActiveGrid;
