import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import EditUserStore from "src/state/UserManager/EditUserStore";
import { SelectItem } from "../../shared";
import { Filter } from "../Filter";
import { List } from "../List";
import { AddUser } from "./AddUser";
import { EditUser } from "./EditUser";

export const UserList = observer(() => {
  const { UserManagerState: state } = useAppState();

  const userState = useLocalStore(EditUserStore, state);

  const columns: any = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        disableSortBy: true,

        Cell: ({
          cell: {
            row: { original: user },
          },
        }: any) => (
          <SelectItem onClick={() => userState.openModal(user.name)}>{user.name}</SelectItem>
        ),
      },
      {
        Header: "",
        accessor: "email",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "is_active",
        disableSortBy: true,
        Cell: ({
          cell: {
            row: { original: user },
          },
        }: any) => (
          <ToggleSwitch
            checked={user.is_active}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              state.toggleActiveUser(user, e.target.checked);
            }}
          />
        ),
      },
    ],
    [state, userState]
  );

  return (
    <>
      <List
        style={{ gridArea: "users" }}
        label="User"
        btnLabel="add user +"
        openModal={state.openPanel("showAddUserPanel")}
        data={state.users}
        columns={columns}
        filter={<Filter value={state.usersFilter} onChange={state.setUsersFilters} />}
        loader={state.isLoadingUsers}
      />

      <AddUser />
      <EditUser state={userState} />
    </>
  );
});
