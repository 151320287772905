import styled from "styled-components";
import { ActivationIndicatorStatus } from ".";

export type IndicatorProps = {
  status?: ActivationIndicatorStatus;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Indicator = styled.div<IndicatorProps>`
  width: 10px;
  height: 10px;

  border-radius: ${({ theme }) => theme.radii["100%"]};
  background-color: ${({ status, theme: { botColorStatus } }) => {
    switch (status) {
      case "active":
        return botColorStatus.green;
      case "failed":
        return botColorStatus.blue;
      case "expired":
        return botColorStatus.gray;
      case "stopped":
        return botColorStatus.darkRed;
      case "done":
        return "#2E7D32";

      default:
        return botColorStatus.gray;
    }
  }};
`;

export const StatusTitle = styled.span``;
