import { observer } from "mobx-react-lite";
import { List } from "src/components/shared/List";
import { Loader } from "src/components/shared/Loader";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import useAppState from "src/state";
import { WalletRow } from "./WalletRow";
import * as styles from "./style";

export const WalletsList = observer(
  ({ title, asset, token, wallets, button, leftBalances, rightBalances, nativeBalances }) => {
    const { WalletsState: state } = useAppState();
    return (
      <List title={title} button={button}>
        <styles.Header>
          <styles.Title>Name</styles.Title>
          <styles.Title>Wallet</styles.Title>
          {/* <styles.Title>
          <CheckBox />
        </styles.Title> */}
          <styles.Copy onClick={state.copyAllWallet(title)}> {DEXStatsIcons.copy()}</styles.Copy>
          <styles.Title>{asset}</styles.Title>
          <styles.Title>{token}</styles.Title>
          <styles.Title>Native</styles.Title>
        </styles.Header>
        <styles.Content>
          <WalletRow
            wallets={wallets}
            name={title}
            leftBalances={leftBalances}
            rightBalances={rightBalances}
            nativeBalances={nativeBalances}
          />
          <Loader show={state.loadWallets} />
        </styles.Content>
      </List>
    );
  }
);
