import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import useAppState from "src/state";
import { StatusConnect } from "../shared/Indicators/StatusConnect";
import { Clock } from "./Clock";
import { Menu } from "./Menu";
import { SideMenu } from "./SideMenu";
import * as styles from "./style";

const MOBILE_HEADER_QUERY = "(max-width: 800px)";

export interface HeaderProps {
  themeToggler: () => void;
}

export const Header = observer(({ themeToggler }: HeaderProps) => {
  const { userState, userConnectionState } = useAppState();

  const isMobile = useMediaQuery(MOBILE_HEADER_QUERY);

  if (!userState.isLoggedIn)
    return (
      <styles.HeaderContainer>
        <styles.LogoLink to="/MainPage">
          <styles.Logo isMobile={isMobile} />
        </styles.LogoLink>
      </styles.HeaderContainer>
    );

  return (
    <styles.HeaderContainer>
      <SideMenu isMobile={isMobile} />

      <styles.LogoLink to="/MainPage">
        <styles.Logo isMobile={isMobile} />
      </styles.LogoLink>

      <styles.MenuContainer>
        <styles.LoginWrapper>
          <styles.Login data-tooltip-id="main-menu-tooltip" data-tooltip-content="">
            {userState.login}
          </styles.Login>

          <styles.InfoRow>
            <StatusConnect
              type="ping"
              status={userConnectionState.connectStatus}
              delay={userConnectionState.serverDelay}
            />

            <Clock />
          </styles.InfoRow>
        </styles.LoginWrapper>

        <Menu
          themeToggler={themeToggler}
          onLogOut={userState.logOut}
          onUpdAbil={userState.updAbilities}
        />
      </styles.MenuContainer>
    </styles.HeaderContainer>
  );
});
