import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import ExchangeStore from "src/state/CEX/CEXExchange";
import { BotInfo } from "..";
import { FullExchange } from "./Versions/FullExchange";

interface Props {
  abilityName: string;
  botInfo: BotInfo;
}

export const Exchange = observer(({ botInfo, abilityName }: Props) => {
  const { SettingsBotState: settingsState } = useAppState();

  const mainState = useLocalStore(ExchangeStore, settingsState);

  useEffect(() => {
    settingsState.initLoadsCount();
    return () => {
      settingsState.clearLoadsCount();
    };
  }, [settingsState]);

  useEffect(() => {
    if (!botInfo.market) return;
    mainState.setMarket(botInfo.market);

    if (botInfo.bot_uuid) mainState.setUUID(botInfo.bot_uuid);
    if (botInfo.party) mainState.setParty(botInfo.party);

    mainState.downloadDefaultData();
  }, [mainState, botInfo]);

  const updShortCut = useCallback(
    (e: KeyboardEvent) => {
      if (e.shiftKey && e.code === "KeyR") {
        if (!mainState.market) return;

        mainState.updAllData();
      }
    },
    [mainState]
  );

  useEffect(() => {
    document.addEventListener("keyup", updShortCut, false);

    return () => document.removeEventListener("keyup", updShortCut, false);
  }, [updShortCut]);

  return (
    <ExchangeContext.Provider value={mainState}>
      <FullExchange party={botInfo.party} abilityName={abilityName} botInfoProps={botInfo} />
    </ExchangeContext.Provider>
  );
});
