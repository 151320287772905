import { nanoid } from "nanoid";
import { ComponentPropsWithoutRef, ComponentType } from "react";
import Icons from "src/icons/Icons";
import { PromptMsg } from "../shared";
import * as styles from "./style";

export interface PanelHeaderOwnProps {
  labelIcon?: JSX.Element;
  label: string;
  checkbox?: JSX.Element;
  toggle?: JSX.Element;
  btn?: JSX.Element;
  info?: string;
  titleSlot?: ComponentType<ComponentPropsWithoutRef<"h5">>;
}

export interface PanelHeaderProps
  extends PanelHeaderOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof PanelHeaderOwnProps> {}

export const PanelHeader = ({
  labelIcon,
  label,
  checkbox,
  toggle,
  btn,
  info,
  titleSlot,
  ...props
}: PanelHeaderProps) => {
  const id = nanoid();

  const Title = titleSlot ?? styles.Title;

  return (
    <styles.Header {...props}>
      <styles.Wrapper>
        <Title>
          {labelIcon}
          {label}
          {info ? (
            <span data-tooltip-content={info} data-tooltip-id={id} data-iscapture="true">
              {Icons.prompt()}
            </span>
          ) : null}
          <PromptMsg id={id} place="right" />
        </Title>
        {checkbox}
      </styles.Wrapper>

      <styles.Wrapper>
        {toggle}
        {btn}
      </styles.Wrapper>
    </styles.Header>
  );
};
