import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable, delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";
import { generateAmount, generateTime, generateTokenTickers } from "./FundingStore";
import { GenerateTimeSeriesOptions, generateTimeSeries } from "./SpreadStore";
import { padSeriesData, rawSeriesToData, timeToMs } from "./utils";

interface IBalancesStoreParams extends IBaseStatsStoreParams {}

type BalancesPoints = {
  time: number[];
  value: string[];
};

export type BalancesTokensTransactions = Record<string, string>;

export type BalancesTokensTransactionsValue = {
  in?: BalancesTokensTransactions;
  out?: BalancesTokensTransactions;
} | null;

type BalancesTransactionsPoints = {
  time: number[];
  value: BalancesTokensTransactionsValue[];
};

type BalancesData = {
  free: BalancesPoints;
  locked: BalancesPoints;
  price: BalancesPoints;
};

type TransactionsData = BalancesTransactionsPoints;

const INITIAL_BALANCES_DATA: BalancesData = {
  free: {
    time: [],
    value: [],
  },
  locked: {
    time: [],
    value: [],
  },
  price: {
    time: [],
    value: [],
  },
};

const INITIAL_TRANSACTIONS_DATA: TransactionsData = {
  time: [],
  value: [],
};

const pointsToBalancesData = (points: ChartPoint[]) => {
  const time: number[] = [];
  const value: string[] = [];

  points.forEach((point) => {
    time.push(point.time);
    value.push(point.value.toString());
  });

  return { time, value };
};

export const generateTransactions = (tickers: string[]): BalancesTokensTransactions | undefined => {
  const transactionsTickers = faker.helpers.arrayElements(
    tickers,
    faker.number.int({ min: 0, max: tickers.length })
  );

  if (!transactionsTickers.length) return undefined;

  return transactionsTickers.reduce((acc, ticker) => {
    acc[ticker] = generateAmount();
    return acc;
  }, {} as BalancesTokensTransactions);
};

const generateTransactionsPoints = (times: number[]): BalancesTransactionsPoints => {
  const tokensCount = faker.number.int({ min: 2, max: 5 });

  const tokens = generateTokenTickers({ count: tokensCount });

  const addEmptyPoint = (points: BalancesTransactionsPoints, time: number) => {
    points.time.push(time);
    points.value.push(null);
  };

  const points = times.reduce<BalancesTransactionsPoints>(
    (points, time) => {
      const skipPoint = faker.datatype.boolean();
      if (skipPoint) {
        addEmptyPoint(points, time);
        return points;
      }
      const inTransactions = generateTransactions(tokens);
      const outTransactions = generateTransactions(tokens);
      if (!inTransactions && !outTransactions) {
        addEmptyPoint(points, time);
        return points;
      }

      points.time.push(time);
      points.value.push({ in: inTransactions, out: outTransactions });
      return points;
    },
    { time: [], value: [] } as BalancesTransactionsPoints
  );

  return points;
};

const generateBalancesData = (): BalancesData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 10, max: 30 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    step: {
      value: 1,
      unit: "day",
    },
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 1000,
      precision: 0.01,
    },
  };

  const freeData = generateTimeSeries(generateSeriesOptions);

  const lockedData = generateTimeSeries(generateSeriesOptions);

  const priceData = generateTimeSeries(generateSeriesOptions);

  return {
    free: pointsToBalancesData(freeData),
    locked: pointsToBalancesData(lockedData),
    price: pointsToBalancesData(priceData),
  };
};

export type BalanceType = "total" | "quote" | "base";

export default class BalancesStore implements IStatsFetcher, Disposable {
  private _stateProvider: IDashboardStateProvider;

  private _balancesData: BalancesData = INITIAL_BALANCES_DATA;

  private _transactionsData: TransactionsData = INITIAL_TRANSACTIONS_DATA;

  private _loading = false;

  private _currentType: BalanceType = "total";

  constructor({ stateProvider }: IBalancesStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    makeLoggable(this, { freeLocked: true, price: true, transactions: true });
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _queryParams() {
    return this._stateProvider.queryParams;
  }

  private _setBalancesData = (data: BalancesData) => {
    this._balancesData = data;
  };

  private _setTransactionsData = (data: TransactionsData) => {
    this._transactionsData = data;
  };

  setCurrentType = (type: BalanceType) => {
    this._currentType = type;

    this.getStats();
  };

  private _setInitialData = () => {
    this._setBalancesData(INITIAL_BALANCES_DATA);
    this._setTransactionsData(INITIAL_TRANSACTIONS_DATA);
  };

  get currentType() {
    return this._currentType;
  }

  private get _lockedData() {
    return rawSeriesToData(this._balancesData.locked);
  }

  private get _locked() {
    const seriesData = this._lockedData;
    return padSeriesData(seriesData);
  }

  private get _freeData() {
    return rawSeriesToData(this._balancesData.free);
  }

  private get _free() {
    const seriesData = this._freeData;
    return padSeriesData(seriesData);
  }

  get freeLocked() {
    return {
      free: this._free,
      locked: this._locked,
    };
  }

  private get _price() {
    return rawSeriesToData(this._balancesData.price);
  }

  get price() {
    const seriesData = this._price;
    return padSeriesData(seriesData);
  }

  private get _transactions() {
    const { time, value } = this._transactionsData;
    return { time: timeToMs(time), value };
  }

  get transactions() {
    return padSeriesData(this._transactions);
  }

  get startBalances() {
    const freeStart = this._freeData.value[0] ?? 0;
    const lockedStart = this._lockedData.value[0] ?? 0;
    return { free: freeStart, locked: lockedStart };
  }

  get endBalances() {
    const freeValues = this._freeData.value;
    const freeEnd = freeValues[freeValues.length - 1] ?? 0;

    const lockedValues = this._lockedData.value;
    const lockedEnd = lockedValues[lockedValues.length - 1] ?? 0;

    return { free: freeEnd, locked: lockedEnd };
  }

  get deltaBalances() {
    const { startBalances } = this;
    const { endBalances } = this;
    const lockedDelta = endBalances.locked - startBalances.locked;
    const freeDelta = endBalances.free - startBalances.free;
    return { free: freeDelta, locked: lockedDelta };
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getBalances = async () => {
    // const { party } = this._botParams;
    // const queryParams = this._queryParams;
    // if (!party || !queryParams) return;

    this._setInitialData();
    this._setLoading(true);
    try {
      await delay(1000);
      const balancesData = generateBalancesData();
      const transactionsData = generateTransactionsPoints(balancesData.free.time);

      this._setBalancesData(balancesData);
      this._setTransactionsData(transactionsData);
    } catch {
      this._setInitialData();
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await this._getBalances();
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
