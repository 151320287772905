import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { entries } from "src/helpers/utils";
import { ExchangePieType } from "src/state/CEX/CEXDashboard/ExchangesLegendStore";
import { ExchangesBalancesData } from "src/state/CEX/CEXDashboard/ExchangesStore";
import { useChartColorWheel } from "../hooks/useChartColorWheel";
import { useStatsStore } from "../hooks/useStatsStore";
import { ExchangesLegend } from "./ExchangesLegend";
import { ExchangesPie, ExchangesPieProps } from "./ExchangesPie";
import * as styles from "./style";

const EXCHANGE_INFO_TEXT = `This block shows the current distribution of balances across exchanges in USD. 
You can choose which values to show in the table below from the dropdown menu.`;

export interface ExchangesOverviewProps extends ComponentPropsWithoutRef<"div"> {}

type ExchangesData = {
  data: number[];
  exchanges: string[];
  colors: string[];
};

const getExchangePieData = ({ data, exchanges, colors }: ExchangesData) => {
  const pieData: ExchangesPieProps["data"] = {
    labels: exchanges,
    datasets: [
      {
        indexAxis: "y",
        data,
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 3,
      },
    ],
  };

  return pieData;
};

type ExchangeTypeTitleMap = Record<ExchangePieType, string>;

const EXCHANGE_TYPE_TITLE_MAP: ExchangeTypeTitleMap = {
  [ExchangePieType.FreeUsd]: "Free Balance in USD",
  [ExchangePieType.LockedUsd]: "Locked Balance in USD",
  [ExchangePieType.TotalUsd]: "Total Balance in USD",
  [ExchangePieType.TotalTokens]: "Total Balance in tokens",
};

interface UseExchangesPieDataParams {
  exchanges: Record<ExchangePieType, ExchangesBalancesData>;
}

type ExchangePieData = {
  type: ExchangePieType;
  data: ExchangesPieProps["data"];
  title: string;
};

const useExchangesData = ({ exchanges }: UseExchangesPieDataParams) => {
  const colorWheel = useChartColorWheel();

  const exchangesData: ExchangePieData[] = entries(exchanges).map(([type, balances]) => {
    const values = Object.values(balances);
    const exchanges = Object.keys(balances);
    const colors = colorWheel.slice(0, values.length);
    const data = getExchangePieData({ data: values, exchanges, colors });
    const title = EXCHANGE_TYPE_TITLE_MAP[type];

    return { type, data, title };
  });

  return exchangesData;
};

export type ExchangesBarDataProps = Required<Pick<ExchangesPieProps, "data" | "title">>;

export const ExchangesOverview = observer(({ ...props }: ExchangesOverviewProps) => {
  const { pieData, loading } = useStatsStore("exchanges");

  const exchangesData = useExchangesData({
    exchanges: pieData,
  });

  return (
    <styles.ExchangesCard
      title="Exchange overview"
      info={EXCHANGE_INFO_TEXT}
      after={<ExchangesLegend />}
      loading={loading}
      {...props}
    >
      <styles.PiesContainer>
        {exchangesData.map(({ data, title, type }) => (
          <styles.ChartContainer key={type}>
            <styles.ChartWrapper>
              <ExchangesPie data={data} type={type} />
            </styles.ChartWrapper>
            <styles.ChartTitle>{title}</styles.ChartTitle>
          </styles.ChartContainer>
        ))}
      </styles.PiesContainer>
    </styles.ExchangesCard>
  );
});
