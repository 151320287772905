import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";

interface Props {
  showLoader: boolean;
  party: string;
}

const LINK_INFO = `Link:
link to selected exchange to selected pair`;

export const LinkToPairPage = observer(({ party, showLoader }: Props) => {
  const { SettingsBotState: state } = useAppState();
  return (
    <SettingsPanel
      label="Link"
      party={party}
      loader={showLoader}
      submitHandler={state.submitFieldHandler("link")}
      info={LINK_INFO}
      saved={state.savedModuls.link}
      style={{ gridArea: "link" }}
    >
      <LabeledInput
        label="Link"
        value={state.data.link}
        onChange={state.getHandler("link")}
        errorHint={state.errors.link}
      />
    </SettingsPanel>
  );
});
