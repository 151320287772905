import { observer } from "mobx-react-lite";
import React from "react";
import { CheckBox } from "src/components/shared/CheckBox";
import useAppState from "src/state";
import * as styles from "./style";

export const VirtualRange = observer(() => {
  const { SettingsBotState: state } = useAppState();

  return (
    <styles.Container>
      <CheckBox
        label="Buy Wall"
        checked={state.data.settings.virtualRange.enableBuyWall}
        onChange={state.getHandler("settings.virtualRange.enableBuyWall")}
        disabled
      />

      <CheckBox
        label="Sell Wall"
        checked={state.data.settings.virtualRange.enableSellWall}
        onChange={state.getHandler("settings.virtualRange.enableSellWall")}
        disabled
      />
    </styles.Container>
  );
});
