import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import useAppState from "src/state";
import { TabsStats } from "../style";
import { HashesList } from "./HashesList";
import { WalletsList } from "./WalletsList";
import * as styles from "./style";

export const Lists = observer(({ showTabs, token, asset, bot_uuid, market }) => {
  const { WalletsState: walletState } = useAppState();

  useEffect(() => {
    if (!bot_uuid) return;
    walletState.setBotUUID(bot_uuid);
    walletState.getWallets();
    walletState.getHashes(0, 10);
  }, [walletState, bot_uuid]);

  useEffect(() => {
    if (!market) return;
    walletState.setMarket(market);
  }, [walletState, market]);

  if (!showTabs) {
    return (
      <styles.Lists>
        <ErrorBoundary fallback={<styles.ListItemFallback />}>
          <WalletsList
            title="MM"
            wallets={walletState.wallets?.accounts}
            button={<ReloadButton size="s" onClick={walletState.getWallets} />}
            token={token}
            asset={asset}
            leftBalances={walletState.convertBalances.accounts.leftBalances}
            rightBalances={walletState.convertBalances.accounts.rightBalances}
            nativeBalances={walletState.convertBalances.accounts.nativeBalances}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.ListItemFallback />}>
          <WalletsList
            title="Track"
            wallets={walletState.wallets?.trackAccounts}
            token={token}
            asset={asset}
            leftBalances={walletState.convertBalances.trackAccounts.leftBalances}
            rightBalances={walletState.convertBalances.trackAccounts.rightBalances}
            nativeBalances={walletState.convertBalances.trackAccounts.nativeBalances}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<styles.ListItemFallback />}>
          <HashesList
            title={`Bot hashes ${market}`}
            icon={DEXStatsIcons.hash()}
            hashes={walletState.hashes}
          />
        </ErrorBoundary>
      </styles.Lists>
    );
  }
  return (
    <styles.Lists key="WALLETS">
      <TabsStats
        menuItems={[
          { label: "MM", value: "MM" },
          { label: "Track", value: "TRACK" },
          { label: "Hashes", value: "HASHES" },
        ]}
        defaultKey="MM"
      >
        <ErrorBoundary key="MM" fallback={<styles.ListItemFallback />}>
          <WalletsList
            title="MM"
            wallets={walletState.wallets?.accounts}
            button={<ReloadButton size="s" onClick={walletState.getWallets} />}
            token={token}
            asset={asset}
            leftBalances={walletState.convertBalances.accounts.leftBalances}
            rightBalances={walletState.convertBalances.accounts.rightBalances}
            nativeBalances={walletState.convertBalances.accounts.nativeBalances}
          />
        </ErrorBoundary>

        <ErrorBoundary key="TRACK" fallback={<styles.ListItemFallback />}>
          <WalletsList
            title="Track"
            wallets={walletState.wallets?.trackAccounts}
            token={token}
            asset={asset}
            leftBalances={walletState.convertBalances.trackAccounts.leftBalances}
            rightBalances={walletState.convertBalances.trackAccounts.rightBalances}
            nativeBalances={walletState.convertBalances.trackAccounts.nativeBalances}
          />
        </ErrorBoundary>

        <ErrorBoundary key="HASHES" fallback={<styles.ListItemFallback />}>
          <HashesList
            title={`Bot hashes ${market}`}
            icon={DEXStatsIcons.hash()}
            hashes={walletState.hashes}
          />
        </ErrorBoundary>
      </TabsStats>
    </styles.Lists>
  );
});
