import { makeAutoObservable, runInAction } from "mobx";
import { ChartRequestProps, getPnLStats } from "src/api/bots/CEX/stats";
import { calcStep } from "src/helpers/math";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { calcPnLPrice } from "src/helpers/trading";
import RangePickerStore from "./RangePicker";

interface IPnL {
  deltaToken: number;
  deltaTotal: number;
  prices: {
    [key: string]: number;
  };
}

const EMPTY_PNL = {
  deltaToken: 0,
  deltaTotal: 0,
  prices: {},
};

class PnLStore {
  private _party = "";

  private _symbol = "";

  private _pnl: IPnL = EMPTY_PNL;

  isLoading = false;

  rangeState: RangePickerStore;

  constructor() {
    this.rangeState = new RangePickerStore(this, undefined);

    makeAutoObservable(this);
  }

  get price() {
    return calcPnLPrice(this._pnl.deltaTotal, this._pnl.deltaToken);
  }

  private get reqStep() {
    if (this.rangeState.start && this.rangeState.end)
      return calcStep(this.rangeState.start, this.rangeState.end);

    return 15;
  }

  public get deltaToken() {
    const delta = this._pnl.deltaToken;

    return toRounding(delta, calcRoundingValue(delta));
  }

  public get deltaTotal() {
    return String(this._pnl.deltaTotal);
  }

  loadData = () => {
    if (this.rangeState.start && this.rangeState.end)
      this._getStats({
        party: this._party,
        symbol: this._symbol,
        step: this.reqStep,
        start: this.rangeState.start,
        stop: this.rangeState.end,
      });
  };

  setParty = (party: string) => {
    this._party = party;
  };

  setSymbol = (symbol: string) => {
    this._symbol = symbol;
  };

  private _getStats = async (props: ChartRequestProps) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const {
        isError,
        data: { result },
      } = await getPnLStats(props);

      if (!isError)
        runInAction(() => {
          this._pnl = result;
        });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  destroy = () => {};
}

export default PnLStore;
