import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import {
  DistributeNativeRequest,
  StatsWalletType,
  distributeNative,
} from "src/api/bots/DEXV2/stats";
import { CloseModalCb } from "src/components/shared/ModalPanel";
import { isTransactionSuccess } from "src/helpers/chain/contracts";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { required, strictlyGreaterThan } from "src/validation-schemas";
import { SenderWallet } from ".";
import DefaultFormStore, { IParentFormStore } from "../../../shared/DefaultFormStore";
import { SetLoading } from "./WithdrawGasStore";

interface GasFill extends DistributeNativeRequest {}

interface GasFillForm extends Omit<GasFill, "amount"> {
  amount: number | "";
}

interface IGasFillStore extends IParentFormStore<GasFillForm>, Disposable {}

interface GasDistributors
  extends Pick<GasFill, "volume_executors" | "limit_executors" | "counter_executors"> {}

const addWalletToSenders = (
  id: string,
  type: Exclude<StatsWalletType, "deployer">,
  currentSenders: GasDistributors
) => {
  const executorsType = `${type}_executors` as const;
  // eslint-disable-next-line no-unused-expressions, no-param-reassign
  currentSenders[executorsType]?.push(id) ?? (currentSenders[executorsType] = [id]);
};

const senderWalletsToGasDistributors = (wallets: SenderWallet[]): GasDistributors => {
  const senders: GasDistributors = {};
  wallets.forEach(({ id, type }) => {
    if (type !== "deployer") {
      addWalletToSenders(id, type, senders);
    }
  });
  return senders;
};

const INITIAL_GAS_FILL: GasFillForm = {
  amount: "",
};

export interface GasFillParameters {
  wallets: SenderWallet[];
  deployerId: string;
  onClose: CloseModalCb;
  setLoading: SetLoading;
  chainProvider: IChainProvider;
}

export default class GasFillStore implements IGasFillStore {
  private _data: GasFillForm = INITIAL_GAS_FILL;

  private _deployerId: string;

  validation = {
    amount: [required(), strictlyGreaterThan(0, "The value must be positive")],
  };

  formState: DefaultFormStore<GasFillForm>;

  private _closeModal: CloseModalCb;

  private _setLoading: SetLoading;

  private _chainProvider: IChainProvider;

  constructor({ wallets, deployerId, onClose, setLoading, chainProvider }: GasFillParameters) {
    this._deployerId = deployerId;
    this._initData(wallets);

    this._closeModal = onClose;
    this._setLoading = setLoading;
    this._chainProvider = chainProvider;

    this.formState = new DefaultFormStore(this);

    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _initData = (wallets: SenderWallet[]) => {
    const senders = senderWalletsToGasDistributors(wallets);
    this._data = { ...INITIAL_GAS_FILL, ...senders };
  };

  get data() {
    return this._data;
  }

  closeModal = () => {
    this._closeModal(false);
  };

  private _waitTransaction = async (hash: string) => {
    const { provider } = this._chainProvider;
    if (!provider) {
      return;
    }

    try {
      const receipt = await provider.waitForTransaction(hash);
      if (isTransactionSuccess(receipt)) {
        return receipt;
      }
      return null;
    } catch (error) {
      chainErrorHandler(error);
    }
  };

  submit = async () => {
    try {
      this._setLoading(true);

      const requestData = this.data as GasFill;

      const { isError, data } = await distributeNative(this._deployerId, requestData);

      if (!isError) {
        const receipt = await this._waitTransaction(data.hash);
        if (receipt) {
          toast.success("Gas filled successfully", {
            autoClose: 2000,
          });
          this.closeModal();
        }
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
