import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { changeScope, deleteScope } from "src/api/userManager/scopesAPI";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { SelectorValue } from "src/modules/shared";
import { Scope } from "src/modules/userManager";
import { required, validateData } from "src/validation-schemas";
import { ScopeSelection } from "..";
import { ScopesUpdate } from "./ScopesStore";

interface EditScope extends Scope {}

type Party = string;

type ScopeId = Scope["scope_id"];

export const INITIAL_EDIT_SCOPE: EditScope = {
  parent_id: 0,
  scope_id: 0,
  name: "",
  parties: [],
  full_name: "",
};

export type StringSelectorValue = {
  [k in keyof SelectorValue]: string;
};

export interface EditScopeParams extends ScopesUpdate {
  scopeGraph: Record<number, ScopeSelection>;
}

export default class EditScopeStore {
  private _validation = {
    name: required(),
    parent_id: required(),
    scope_id: required(),
  };

  private _params: EditScopeParams;

  private _isShownReaction: IReactionDisposer;

  private _childrenIds: number[] = [];

  private _selectedParties: Party[] = [];

  editScope: EditScope = INITIAL_EDIT_SCOPE;

  isShown: boolean = false;

  handlers: any = {};

  errors: any = {};

  isLoading = false;

  constructor(params: EditScopeParams) {
    this._params = params;

    makeAutoObservable(this);

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this.resetForm();
        }
      }
    );
  }

  private get _newScope(): EditScope {
    return { ...this.editScope, parties: this._selectedParties };
  }

  private _setIsModalShow(isShown: boolean) {
    this.isShown = isShown;
  }

  private _setScopes = (scope: Scope, childrenIds: ScopeId[]) => {
    this.editScope = scope;
    this._childrenIds = childrenIds;
  };

  get childrenScopes() {
    return this._childrenIds
      .map((childId) => this._params.scopeGraph[childId]?.model)
      .filter(Boolean);
  }

  get selectedParties() {
    return this._selectedParties.map((party) => ({
      value: party,
      label: party,
    }));
  }

  set selectedParties(parties: StringSelectorValue[] | undefined) {
    this._selectedParties = parties ? parties.map(({ value }) => `${value}`) : [];
  }

  removeSelectedParty = (party: string) => {
    this._selectedParties = this._selectedParties.filter(
      (selectedParty) => selectedParty !== party
    );
  };

  openModal = (scope: Scope, childrenIds: ScopeId[]) => {
    this._setIsModalShow(true);
    this._setScopes(scope, childrenIds);
    this._selectedParties = this.editScope.parties;
  };

  closeModal = () => {
    this._setIsModalShow(false);
    this._setScopes({ ...INITIAL_EDIT_SCOPE, scope_id: 0, parent_id: 0 }, []);
  };

  resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this._selectedParties = [];
    this._childrenIds = [];
    this.editScope = INITIAL_EDIT_SCOPE;
  };

  getHandler = (key: string) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.editScope, path);

      switch (key) {
        default: {
          this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
            targetData[endKey] = getChangeEventValue(e);
          };
        }
      }
    }

    return this.handlers[key];
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this._validation, this._newScope, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      try {
        const { isError } = await changeScope(this._newScope as EditScope);

        if (!isError) {
          toast.success("Scope edited successfully", {
            autoClose: 2000,
          });
          this._setIsModalShow(false);
          this._params.getScopesList();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  deleteEditScope = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { isError } = await deleteScope(this.editScope.scope_id);

      if (!isError) {
        toast.success("Scope deleted successfully", {
          autoClose: 2000,
        });
        this._setIsModalShow(false);
        this._params.getScopesList();
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  destroy() {
    this._isShownReaction();
  }
}
