import React from "react";
import * as styles from "./style";

export const List = ({ title, icon, children, button, ...props }) => (
  <styles.Container {...props}>
    <styles.Title>
      <styles.WrapperForTitle>
        {" "}
        {title} {icon}
      </styles.WrapperForTitle>{" "}
      {button}
    </styles.Title>
    <styles.Content>{children}</styles.Content>
  </styles.Container>
);
