import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { PromptMsg } from "src/components/shared/shared";
import { GasWalletsContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/GasWallets";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useId } from "src/hooks/useId";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import * as styles from "./style";

const GAS_FILL_INFO = `
Gas Fill - deposit native tokens to executors from the deployer.

1. Tick the wallets that need to be deposited with native tokens (except the deployer) and press Gas Fill.

2. Enter the amount. Each chosen wallet will be deposited with this amount of native tokens.
`;

const WITHDRAW_GAS_INFO = `
Withdraw Gas - withdraw gas from executors and the deployer to an external wallet.

1. Tick the wallets from which the gas should be withdrawn and press Withdraw Gas.

2. Enter the address to which all available gas should be transferred from the chosen wallets.

`;

interface UseGasWalletsInfoParams
  extends Pick<InteractionPanelProps, "gasFillEnabled" | "withdrawEnabled"> {}

const useGasWalletsInfo = ({ gasFillEnabled, withdrawEnabled }: UseGasWalletsInfoParams) => {
  const id = useId(4);
  const infoMessage = useMemo(() => {
    const gasFillInfo = gasFillEnabled && GAS_FILL_INFO;
    const withdrawInfo = withdrawEnabled && WITHDRAW_GAS_INFO;
    const message = filterBoolean([gasFillInfo, withdrawInfo]).join("\n");
    return message;
  }, [gasFillEnabled, withdrawEnabled]);

  return (
    Boolean(infoMessage) && (
      <styles.InfoWrapper data-tooltip-content={infoMessage} data-tooltip-id={id}>
        {Icons.prompt()}
        <PromptMsg id={id} place="right" />
      </styles.InfoWrapper>
    )
  );
};

export interface InteractionPanelProps {
  showGasFillModal: () => void;
  gasFillEnabled?: boolean;
  showWithdrawModal: () => void;
  withdrawEnabled?: boolean;
}

export const InteractionPanel = observer(
  ({
    showGasFillModal,
    gasFillEnabled,
    showWithdrawModal,
    withdrawEnabled,
  }: InteractionPanelProps) => {
    const state = useLateInitContext(GasWalletsContext);

    const Info = useGasWalletsInfo({ gasFillEnabled, withdrawEnabled });

    return (
      <styles.Container>
        {Info}
        {gasFillEnabled && (
          <OutlineButton
            size="s"
            disabled={state.deployerSelected || !state.isAddressSelected}
            onClick={showGasFillModal}
          >
            Gas fill
          </OutlineButton>
        )}

        {withdrawEnabled && (
          <OutlineButton size="s" disabled={!state.isAddressSelected} onClick={showWithdrawModal}>
            Withdraw Gas
          </OutlineButton>
        )}

        <IconButton onClick={state.refreshBalances}>{Icons.updateBackend()}</IconButton>

        <IconButton onClick={state.refreshChainBalances}>{Icons.updateOnChain()}</IconButton>
      </styles.Container>
    );
  }
);
