import { Graph, GraphProps } from "src/components/shared/Graph";
import { TooltipSeriesData } from "src/state/Graph/TooltipStore";
import { CardProps } from "../../../shared/Card";
import { useGraphsChartOptions } from "../../../shared/config/graphs";
import { BaseBalanceChartTooltip } from "./BaseBalanceChartTooltip";
import * as styles from "./style";

export interface BaseBalanceChartProps extends CardProps {
  graphProps?: GraphProps<TooltipSeriesData>;
}

export const BaseBalanceChart = ({ graphProps, ...props }: BaseBalanceChartProps) => {
  const { options } = useGraphsChartOptions();

  return (
    <styles.StyledBalanceCard {...props}>
      <styles.Content>
        <Graph
          tooltip={BaseBalanceChartTooltip}
          showInfo={false}
          options={options}
          {...graphProps}
        />
      </styles.Content>
    </styles.StyledBalanceCard>
  );
};
