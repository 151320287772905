import dayjs from "dayjs";
import { DashboardQueryParams, DashboardRangeParams } from "src/api/bots/CEX/dashboard";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { noOpAsync } from "src/helpers/utils";
import { CEXDashboardBotParams, IDashboardStateProvider } from ".";

export class MockDashboardProvider implements IDashboardStateProvider {
  get botParams(): CEXDashboardBotParams {
    return { party: "", uuid: "" };
  }

  get selectedExchanges(): string[] {
    return [];
  }

  get queryRangeParams(): DashboardRangeParams | null {
    return null;
  }

  get queryParams(): DashboardQueryParams | null {
    return null;
  }

  get selectedRange(): DateTimeRange {
    return [dayjs(), dayjs()];
  }

  get previousMonth(): DateTimeRange | null {
    return null;
  }

  get currentMonth(): DateTimeRange | null {
    return null;
  }

  getInitialData = noOpAsync;
}
