import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Tooltip } from "react-tooltip";
import * as styles from "src/components/UserManagement/Access/shared";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Subtitle } from "src/components/shared/Typography";
import { ScopesContext } from "src/context/UserManager/Scopes";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useAppState from "src/state";
import EditScopeStore from "src/state/UserManager/Scopes/EditScopeStore";
import WindowConsent from "src/state/WindowConsent";
import {
  AddBtnWrapper,
  ColumnTitle,
  FlexWrapper,
  ScopeInput,
  ScopesContainer,
  SelectPanel,
} from "./styles";

export type EditScopeProps = {
  scopeState: EditScopeStore;
};

export const EditScope = observer(() => {
  const { UserManagerState: managerState } = useAppState();

  const {
    scopesState: { editScopeState: scopeState },
  } = useLateInitContext(ScopesContext);

  const checkChildBindings = Boolean(scopeState.childrenScopes.length);

  const remove = useCallback(() => {
    WindowConsent.showWindow(
      "Are you sure you want to delete the scope:",
      `${scopeState.editScope.name}?`,
      scopeState.deleteEditScope
    );
  }, [scopeState]);

  const childrenScopes = scopeState.childrenScopes.map(({ scope_id, name }) => (
    <ScopeInput label="" key={scope_id} value={name} onChange={noOp} readOnly />
  ));

  return (
    <styles.FlexModalPanel
      label={`Edit scope (${scopeState.editScope.full_name})`}
      show={scopeState.isShown}
      close={scopeState.closeModal}
      loading={scopeState.isLoading}
      style={{ height: "440px" }}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={scopeState.submitHandler()}
      >
        <FlexWrapper>
          <styles.Column>
            <ColumnTitle>Scopes</ColumnTitle>
            {childrenScopes.length ? (
              <styles.ScrollWrapper>
                <ScopesContainer>{childrenScopes}</ScopesContainer>
              </styles.ScrollWrapper>
            ) : (
              <Subtitle>No child scopes</Subtitle>
            )}
          </styles.Column>

          <styles.Column>
            <ColumnTitle>Parties</ColumnTitle>
            <LabeledSelector
              isMulti
              options={managerState.parties}
              value={scopeState.selectedParties}
              onChange={(value) => {
                scopeState.selectedParties = [...value];
              }}
            />

            <styles.ScrollWrapper>
              <SelectPanel
                selectItems={scopeState.selectedParties ?? []}
                removeClick={scopeState.removeSelectedParty}
              />
            </styles.ScrollWrapper>
          </styles.Column>
        </FlexWrapper>

        <styles.SideWrapper>
          {scopeState.editScope.scope_id > 1 ? (
            <AddBtnWrapper
              data-tooltip-content="Can`t delete scope with child scopes"
              data-iscapture="true"
            >
              <OutlineButton stretched disabled={checkChildBindings} onClick={() => remove()}>
                Delete scope
              </OutlineButton>
              {checkChildBindings ? <Tooltip variant="info" /> : null}
            </AddBtnWrapper>
          ) : null}
          <SubmitButton stretched>Save scope</SubmitButton>
        </styles.SideWrapper>
      </styles.AccessForm>
    </styles.FlexModalPanel>
  );
});
