import styled from "styled-components";
import { TOOLTIP_CLASSNAME } from "../../../TrackableTokens/ExchangesListItem/style";
import { InfoTooltip } from "../../../shared/Card/Header/InfoIconTooltip/style";

export const Container = styled.div`
  display: flex;
  gap: 8px;

  padding: 0 4px;

  border-radius: ${({ theme }) => theme.radii["8px"]};

  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.dashboard.backgroundOverlay};
  }
`;

export const IndicatorTooltip = styled(InfoTooltip)`
  &.${TOOLTIP_CLASSNAME} {
    width: 100px;
  }
`;
