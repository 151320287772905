import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import ChartScaleStore, { ChartScaleConfig } from "src/state/Graph/ChartScaleStore";

export const ChartScaleContext = createLateInitContext<ChartScaleStore>();

export interface ChartScaleProviderProps extends ChildrenProps {
  config?: ChartScaleConfig;
}

export const ChartScaleProvider = ({ children, config }: ChartScaleProviderProps) => {
  const store = useLocalStore(ChartScaleStore, config);

  return <ChartScaleContext.Provider value={store}>{children}</ChartScaleContext.Provider>;
};
