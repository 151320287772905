import { makeAutoObservable } from "mobx";
import { Disposable } from "src/helpers/utils";
import { IObservableCache, ObservableTTLCache } from "src/state/shared/Cache";
import { PairRawPrice } from "./TradePairUSDPriceProvider";

const PAIR_PRICE_UST_TTL = 30 * 1000;
const NATIVE_PRICE_UST_TTL = 5 * 60 * 1000;

export interface IDEXV2BotsCacheProvider {
  get pairPriceUSDCache(): IObservableCache<PairRawPrice>;
  get nativePriceUSDCache(): IObservableCache<string>;
}

export default class DEXV2BotsCacheProvider implements Disposable, IDEXV2BotsCacheProvider {
  private _pairPriceUSDCache: IObservableCache<PairRawPrice>;

  private _nativePriceUSDCache: IObservableCache<string>;

  constructor() {
    makeAutoObservable(this);

    this._pairPriceUSDCache = new ObservableTTLCache({
      ttl: PAIR_PRICE_UST_TTL,
    });

    this._nativePriceUSDCache = new ObservableTTLCache({
      ttl: NATIVE_PRICE_UST_TTL,
    });
  }

  get pairPriceUSDCache() {
    return this._pairPriceUSDCache;
  }

  get nativePriceUSDCache() {
    return this._nativePriceUSDCache;
  }

  destroy = () => {
    this._pairPriceUSDCache.clear();
    this._nativePriceUSDCache.clear();
  };
}
