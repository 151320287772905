import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { useFindWidth } from "src/hooks/useFindWidth";
import useAppState from "src/state";
import { useBotsVirtualizer } from "../../hooks/useBotsVirtualizer";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import { DEXBot } from "./DEXBot";
import { DEXBotMob } from "./DEXBotMob";
import * as styles from "./style";

const header = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Last trade", icon: "" },
  { title: "Next trade", icon: "" },
  { title: "Message", icon: "" },
];

export const DEXBots = observer(() => {
  const {
    dexBotsState: DEXState,
    allBotsState: { realtimeEnabled },
  } = useAppState();

  const showMobVers = useFindWidth(1050);

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobVers,
    size: DEXState.bots.length,
  });

  useEffect(() => {
    if (realtimeEnabled) {
      DEXState.resumeBotsFetching();
    } else {
      DEXState.suspendBotsFetching();
    }

    return () => {
      if (realtimeEnabled) {
        DEXState.suspendBotsFetching();
      }
    };
  }, [realtimeEnabled, DEXState.resumeBotsFetching, DEXState.suspendBotsFetching, DEXState]);

  const dexBots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = DEXState.bots[virtualRow.index];

    const statsLink = `/DEX/${bot.swap_bot_uuid}/?tab=STATS`;

    const credential = {
      market: bot.market,
      UUID: bot.swap_bot_uuid,
      link: statsLink,
    };

    return showMobVers ? (
      <DEXBotMob
        key={bot.market}
        favorite
        toggleIsWatched={DEXState.toggleIsWatched}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    ) : (
      <DEXBot
        key={bot.market}
        favorite
        toggleIsWatched={DEXState.toggleIsWatched}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    );
  });

  return (
    <Container>
      {!showMobVers ? (
        <styles.DEXHeader
          favorite
          toggleWatchList={DEXState.toggleWatchList.bind(DEXState)}
          watchListEnabled={DEXState.watchListEnabled}
          cells={header}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{dexBots}</ContentScrollWrapper>
      </Content>
      <Loader show={DEXState.showLoader && DEXState.firstLoad} />
    </Container>
  );
});
