import styled from "styled-components";
import { SpreadChartCard } from "../SpreadChart/style";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ArbitrageChartCard = styled(SpreadChartCard)``;
