import { observer } from "mobx-react-lite";
import { mobileMediaQuery } from "src/components/UserManagement/Teams/style";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DesktopSummary } from "./DesktopSummary";
import { MobileSummary } from "./MobileSummary";
import { DashboardBreakpoints } from "./style";

export interface SummaryViewProps {}

export const SummaryView = observer((props: SummaryViewProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery(DashboardBreakpoints.XMobile));
  return <>{isMobile ? <MobileSummary /> : <DesktopSummary />}</>;
});
