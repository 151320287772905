import { useEventCallback } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { Dayjs } from "dayjs";
import { ChartOptions, ColorType, createChart, DeepPartial, IChartApi } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import {
  ComponentType,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BaseTheme, Theme } from "src/components/Themes";
import { ChartScaleProvider } from "src/context/Graph/ChartScale";
import { SeriesContext, SeriesProvider, SeriesProviderProps } from "src/context/Graph/Series";
import {
  TooltipProvider,
  TooltipProviderProps,
  useTooltipContext,
} from "src/context/Graph/Tooltip";
import { setTextClipboard } from "src/helpers/clipboard";
import { isDevEnv, SetRequired } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useId } from "src/hooks/useId";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import Icons from "src/icons/Icons";
import { ChartScaleConfig } from "src/state/Graph/ChartScaleStore";
import { IPriceScaleApiProvider, ISeriesStateOptions } from "src/state/Graph/SeriesStore";
import { TooltipSeriesDataProvider } from "src/state/Graph/TooltipSeriesDataProvider";
import { ITooltipSeriesDataProvider, TooltipSeriesData } from "src/state/Graph/TooltipStore";
import { useTheme } from "styled-components";
import { PromptMsg } from "../shared";
import { ChartLegend } from "./ChartLegend";
import { Legend, LegendProps } from "./ChartLegend/Legend";
import { ChartTooltip, ChartTooltipProps } from "./ChartTooltip";
import { SelectionCanvas } from "./SelectionCanvas";
import { OptionalSeriesOptions, Series } from "./Series";
import * as styles from "./style";

export type ThemeLineColor = Exclude<
  keyof Theme,
  keyof BaseTheme | "botColorStatus" | "botBgSideColorStatus" | "botBgColorStatus" | "mode"
>;

export type LineColor = ThemeLineColor | string;

export type GraphRange = [Dayjs, Dayjs];

export type GraphDataRequest = (range: GraphRange) => void;

export type SeriesData = {
  time: number;
  value: number;
};

const useMountedRef = <T,>() => {
  const ref = useRef<T | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const setRef = useCallback((node: T | null) => {
    setIsMounted(Boolean(node));
    // Save a reference to the node
    ref.current = node;
  }, []);

  return [ref, setRef, isMounted] as const;
};

const _getPaneCrosshairCanvas = (chartApi: IChartApi): HTMLCanvasElement | undefined => {
  const chart = chartApi as any;
  if (isDevEnv()) {
    const canvas =
      chart._private__chartWidget?._private__paneWidgets[0]?._private__topCanvasBinding
        ?._canvasElement;
    return canvas;
  }
  const canvas = chart.Vm?.Pv[0]?.Vf?._canvasElement;
  return canvas;
};

const getPaneCrosshairCanvas = (chart: IChartApi | null) => {
  if (!chart) return null;
  const canvas = _getPaneCrosshairCanvas(chart);
  if (!canvas) return null;
  return canvas;
};

const _getPaneCanvas = (chartApi: IChartApi): HTMLCanvasElement | undefined => {
  const chart = chartApi as any;
  if (isDevEnv()) {
    const canvas =
      chart._private__chartWidget?._private__paneWidgets[0]?._private__canvasBinding
        ?._canvasElement;
    return canvas;
  }
  const canvas = chart.Vm?.Pv[0]?.zf?._canvasElement;
  return canvas;
};

const getPaneCanvas = (chart: IChartApi | null) => {
  if (!chart) return null;
  const canvas = _getPaneCanvas(chart);
  if (!canvas) return null;
  return canvas;
};

const SELECTION_CANVAS_TEXT = `Range Selection:

Click on the chart to set the starting point, then drag the mouse cursor to define the desired range. 
Release the mouse button to apply the range.
`;

const TOOLTIP_DATA_TEXT = `Tooltip Data Copying:

Hover your mouse over a data point on the chart to display a tooltip.
Double-click while the tooltip is visible, the data from the tooltip will be copied to your clipboard.
`;

interface UseGraphActionsInfoParams
  extends Pick<GraphRootProps, "showTooltip" | "allowTimeScale" | "showInfo"> {}

export const useGraphActionsInfo = ({
  showTooltip = true,
  allowTimeScale = true,
  showInfo = true,
}: UseGraphActionsInfoParams = {}) => {
  const id = useId(4);

  if (!showInfo) return null;

  const tooltipInfo = showTooltip && TOOLTIP_DATA_TEXT;
  const selectionInfo = allowTimeScale && SELECTION_CANVAS_TEXT;
  const info = filterBoolean([tooltipInfo, selectionInfo]).join("\n");
  return (
    Boolean(info) && (
      <styles.InfoWrapper data-tooltip-content={info} data-tooltip-id={id}>
        {Icons.prompt()}
        <PromptMsg id={id} place="right" />
      </styles.InfoWrapper>
    )
  );
};

const DEFAULT_MOBILE_TOOLTIP_QUERY = "(max-width: 500px)";

interface UseTooltipShowParams
  extends Required<Pick<GraphRootProps, "showTooltip" | "tooltipQuery">> {}

const useTooltipShow = ({ tooltipQuery, showTooltip }: UseTooltipShowParams) => {
  const isMobileTooltip = useMediaQuery(tooltipQuery);
  return isMobileTooltip ? false : showTooltip;
};

export type OptionalChartOptions = DeepPartial<ChartOptions>;

interface UseDefaultChartOptionsParams
  extends Pick<GraphRootProps, "allowTimeScale" | "startOptions"> {}

const useDefaultChartOptions = ({ allowTimeScale, startOptions }: UseDefaultChartOptionsParams) => {
  const defaultOptions = useMemo(
    (): OptionalChartOptions => ({
      localization: {
        locale: "en-US",
        dateFormat: "yyyy/MM/dd",
      },
      timeScale: {
        timeVisible: true,
        minBarSpacing: 0.001,
        fixRightEdge: true,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
      },
      rightPriceScale: {
        autoScale: true,
        ticksVisible: true,
        alignLabels: true,
        visible: false,
      },
      leftPriceScale: {
        autoScale: true,
        ticksVisible: true,
        alignLabels: true,
        visible: false,
      },
      handleScale: {
        axisPressedMouseMove: {
          price: false,
          time: !allowTimeScale,
        },
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: !allowTimeScale,
      },
    }),
    [allowTimeScale]
  );

  const options = useMemo(
    () => deepmerge(defaultOptions, startOptions),
    [defaultOptions, startOptions]
  );

  return options;
};

export function useLineColor(color: LineColor): string;
export function useLineColor(color?: LineColor): string | undefined;
export function useLineColor(color?: LineColor) {
  const theme = useTheme();
  if (!color) return undefined;
  const themeColor = theme[color as ThemeLineColor] ?? color;
  return themeColor;
}

interface UseDefaultChartThemeOptionsParams
  extends Pick<GraphRootProps, "options" | "series" | "autoColorScales"> {}

const useDefaultChartThemeOptions = ({
  options: outerOptions,
  series,
  autoColorScales,
}: UseDefaultChartThemeOptionsParams) => {
  const theme = useTheme();

  const leftColor = series?.find(({ side }) => side === "left")?.color;
  const rightColor = series?.find(({ side }) => side === "right")?.color;

  const leftScaleColor = useLineColor(leftColor);
  const rightScaleColor = useLineColor(rightColor);

  const axisColors: OptionalChartOptions = useMemo(() => {
    if (!autoColorScales) return {};

    return {
      leftPriceScale: {
        borderColor: leftScaleColor,
      },
      rightPriceScale: {
        borderColor: rightScaleColor,
      },
    };
  }, [autoColorScales, leftScaleColor, rightScaleColor]);

  const defaultOptions: OptionalChartOptions = useMemo(
    () => ({
      layout: {
        background: {
          type: ColorType.Solid,
          color: theme.contentBackgroundColor,
        },
        textColor: theme.textColor,
      },
      grid: {
        horzLines: {
          color: theme.tradingViewGridColor,
        },
        vertLines: {
          color: theme.tradingViewGridColor,
        },
      },
      ...axisColors,
    }),
    [theme, axisColors]
  );

  const options = useMemo(
    () => deepmerge(defaultOptions, outerOptions),
    [defaultOptions, outerOptions]
  );

  return options;
};

interface UseTooltipDataCopyParams {
  showTooltip: boolean;
}

const useTooltipDataCopy = ({ showTooltip }: UseTooltipDataCopyParams) => {
  const tooltipState = useTooltipContext();

  const copyTooltipData = useEventCallback(() => {
    const seriesData = tooltipState.seriesDataString;
    setTextClipboard(seriesData);
  });

  const onCopy = useCallback(() => {
    if (showTooltip) {
      copyTooltipData();
    }
  }, [copyTooltipData, showTooltip]);

  return onCopy;
};

export interface GraphLegendOptions {
  show?: boolean;
  legend?: ComponentType<LegendProps>;
  position?: "top" | "bottom";
}

interface UseLegendParams extends Pick<GraphRootProps, "legendOptions"> {}

const useLegend = ({ legendOptions: { show, legend, position } = {} }: UseLegendParams) => {
  if (!show) return null;

  const LegendComponent = legend ?? Legend;

  const positionStyles: CSSProperties | undefined =
    position === "bottom" ? { order: 2 } : undefined;

  return <ChartLegend legend={LegendComponent} style={positionStyles} />;
};

interface UseDefaultizedSeriesPropsParams extends Pick<GraphRootProps, "series"> {}

interface DefaultizedSeriesProps extends SetRequired<SeriesProps, "title" | "side" | "id"> {}

const useDefaultizedSeriesProps = ({
  series: seriesProps = [],
}: UseDefaultizedSeriesPropsParams): DefaultizedSeriesProps[] => {
  const defaultizedSeriesProps = useMemo(
    () =>
      seriesProps.map((series, index) => {
        const id = series.id || `${index}`;
        const defaultSide = index === 0 || index > 1 ? "left" : "right";
        const side = series.side ?? defaultSide;
        const title = series.title ?? `line${index}`;
        return { ...series, title, side, id };
      }),
    [seriesProps]
  );

  return defaultizedSeriesProps;
};

interface UseSeriesParams extends Pick<GraphRootProps, "series"> {}

const useSeries = ({ series: seriesProps }: UseSeriesParams) => {
  const { setSeries, seriesMap, seriesTitlesMap, setSeriesOptions, setSeriesTitle } =
    useLateInitContext(SeriesContext);

  const defaultizedSeriesProps = useDefaultizedSeriesProps({
    series: seriesProps,
  });

  return {
    seriesMap,
    setSeries,
    setSeriesOptions,
    seriesTitlesMap,
    setSeriesTitle,
    defaultizedSeriesProps,
  };
};

export type SeriesPriceScaleId = "left" | "right";

export interface SeriesProps {
  data: SeriesData[];
  id?: string;
  title?: string;
  showTitleLabel?: boolean;
  color?: LineColor;
  visible?: boolean;
  side?: SeriesPriceScaleId;
  startOptions?: OptionalSeriesOptions;
  options?: OptionalSeriesOptions;
}

const useChartRef = () => {
  const [chartRef, setChartRef, isChartMounted] = useMountedRef<IChartApi>();

  const { setPriceScalesApi } = useLateInitContext(SeriesContext);

  const getPriceScalesApi = useCallback(
    (chartApi: IChartApi | null): IPriceScaleApiProvider | null => {
      if (!chartApi) return null;
      return {
        setScalesVisibility: ({ left: leftVisible, right: rightVisible }) => {
          chartApi.applyOptions({
            leftPriceScale: { visible: leftVisible },
            rightPriceScale: { visible: rightVisible },
          });
        },
      };
    },
    []
  );

  const setChart = useCallback(
    (chartApi: IChartApi | null) => {
      setChartRef(chartApi);

      const priceScalesApi = getPriceScalesApi(chartApi);
      setPriceScalesApi(priceScalesApi);
    },
    [getPriceScalesApi, setChartRef, setPriceScalesApi]
  );

  return [chartRef, setChart, isChartMounted] as const;
};

export interface GraphRootProps
  extends React.ComponentPropsWithoutRef<"div">,
    Pick<ChartTooltipProps, "showTooltip" | "tooltip"> {
  series?: SeriesProps[];
  showPriceSuffix?: boolean;
  showAreaColor?: boolean;
  allowTimeScale?: boolean;
  autoColorScales?: boolean;
  startOptions?: OptionalChartOptions;
  options?: OptionalChartOptions;
  request?: GraphDataRequest;
  tooltipQuery?: string;
  legendOptions?: GraphLegendOptions;
  showInfo?: boolean;
}

const GraphRoot = observer(
  ({
    series: seriesProps = [],
    request,
    showPriceSuffix = false,
    allowTimeScale = true,
    showTooltip: outerShowTooltip = true,
    showAreaColor = true,
    autoColorScales = true,
    tooltip,
    tooltipQuery = DEFAULT_MOBILE_TOOLTIP_QUERY,
    startOptions,
    options,
    legendOptions,
    showInfo,
    ...props
  }: GraphRootProps) => {
    const container = useRef<HTMLDivElement>(null);

    const [paneCrosshairCanvas, setPaneCrosshairCanvas] = useState<HTMLCanvasElement | null>(null);
    const [paneCanvasContainer, setPaneCanvasContainer] = useState<HTMLDivElement | null>(null);
    const paneCanvas = useRef<HTMLCanvasElement | null>(null);

    const [chart, setChart, isChartMounted] = useChartRef();

    const {
      seriesMap,
      setSeries,
      seriesTitlesMap,
      setSeriesOptions,
      setSeriesTitle,
      defaultizedSeriesProps,
    } = useSeries({
      series: seriesProps,
    });

    const showTooltip = useTooltipShow({
      tooltipQuery,
      showTooltip: outerShowTooltip,
    });

    const onTooltipCopy = useTooltipDataCopy({ showTooltip });

    const Info = useGraphActionsInfo({ showTooltip, allowTimeScale, showInfo });

    const defaultChartOptions = useDefaultChartOptions({
      allowTimeScale,
      startOptions,
    });

    const defaultChartThemeOptions = useDefaultChartThemeOptions({
      options,
      series: defaultizedSeriesProps,
      autoColorScales,
    });

    const Legend = useLegend({ legendOptions });

    // chart rendering and initializing lines
    useEffect(() => {
      if (!container.current) return;

      const { clientWidth, clientHeight } = container.current;

      const options: OptionalChartOptions = {
        ...defaultChartOptions,
        width: clientWidth,
        height: clientHeight,
      };

      const currentChart = createChart(container.current, options);
      setChart(currentChart);

      const paneCrosshairCanvasNode = getPaneCrosshairCanvas(currentChart);
      if (paneCrosshairCanvasNode) {
        setPaneCrosshairCanvas(paneCrosshairCanvasNode);
      }

      const paneCanvasNode = getPaneCanvas(currentChart);
      if (paneCanvasNode) {
        paneCanvas.current = paneCanvasNode;
        const paneCanvasContainerNode = paneCanvasNode?.parentNode;
        if (paneCanvasContainerNode) {
          setPaneCanvasContainer(paneCanvasContainerNode as HTMLDivElement);
        }
      }

      return () => {
        currentChart.remove();
        setChart(null);
        setPaneCanvasContainer(null);
        setPaneCrosshairCanvas(null);
      };
    }, [chart, container, defaultChartOptions, setChart]);

    // applying theme
    useEffect(() => {
      chart.current?.applyOptions(defaultChartThemeOptions);

      chart.current?.timeScale().fitContent();
    }, [chart, defaultChartThemeOptions]);

    // resizing chart
    useEffect(() => {
      const resizeChart = () => {
        if (!container.current) return;
        const { clientWidth, clientHeight } = container.current;

        chart.current?.resize(clientWidth, clientHeight);
        chart.current?.timeScale().fitContent();
      };

      window.addEventListener("resize", resizeChart);

      return () => window.removeEventListener("resize", resizeChart);
    }, [chart, container]);

    return (
      <styles.Container {...props}>
        {Legend}
        <styles.GraphContainer ref={container} onDoubleClick={onTooltipCopy}>
          <SelectionCanvas
            chart={chart}
            paneCanvas={paneCanvas}
            paneCanvasContainer={paneCanvasContainer}
            paneCrosshairCanvas={paneCrosshairCanvas}
            allowTimeScale={allowTimeScale}
            request={request}
          />
          {defaultizedSeriesProps.map((props) => {
            const key = props.id;

            return (
              <Series
                key={key}
                isChartMounted={isChartMounted}
                showPriceSuffix={showPriceSuffix}
                showAreaColor={showAreaColor}
                chart={chart}
                onOptionsUpdate={setSeriesOptions}
                onTitleUpdate={setSeriesTitle}
                onSeriesChange={setSeries}
                {...props}
              />
            );
          })}
        </styles.GraphContainer>
        <ChartTooltip
          container={paneCanvasContainer}
          isChartMounted={isChartMounted}
          chart={chart}
          seriesTitlesMap={seriesTitlesMap}
          seriesMap={seriesMap}
          showTooltip={showTooltip}
          tooltip={tooltip}
        />
        {Info}
      </styles.Container>
    );
  }
);

export interface GraphProps<T extends TooltipSeriesData>
  extends GraphRootProps,
    Partial<Pick<TooltipProviderProps<T>, "seriesDataProvider">>,
    Pick<ISeriesStateOptions, "autoShowScales"> {
  selectionOptions?: ChartScaleConfig;
  seriesStateProvider?: SeriesProviderProps["stateProvider"];
}

export const Graph = <T extends TooltipSeriesData = TooltipSeriesData>({
  selectionOptions,
  seriesDataProvider,
  seriesStateProvider,
  autoShowScales = true,
  ...props
}: GraphProps<T>) => {
  const defaultTooltipSeriesProviderRef = useRef<ITooltipSeriesDataProvider<T>>(
    new TooltipSeriesDataProvider()
  );

  const tooltipSeriesDataProvider = seriesDataProvider ?? defaultTooltipSeriesProviderRef.current;

  const seriesProviderProps = {
    stateProvider: seriesStateProvider,
    autoShowScales,
  };

  return (
    <ChartScaleProvider config={selectionOptions}>
      <SeriesProvider {...seriesProviderProps}>
        <TooltipProvider seriesDataProvider={tooltipSeriesDataProvider}>
          <GraphRoot {...props} />
        </TooltipProvider>
      </SeriesProvider>
    </ChartScaleProvider>
  );
};
