import styled from "styled-components";
import { CardSurface } from "../../shared";

export const PNLV2CardContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 8px;
  gap: 8px;

  ${CardSurface}

  box-shadow: none;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
`;
