import { SelectorDropdown } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown";
import { Title } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown/style";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;

  display: flex;
  flex-direction: column;

  gap: 15px;

  grid-area: PnL;
`;

export const DropdownSelector = styled(SelectorDropdown)`
  ${Title} {
    @media (min-width: 500px) and (max-width: 750px), (min-width: 1100px) and (max-width: 1450px) {
      font-size: ${({ theme }) => theme.fontSizes["11px"]};
    }
  }
`;
