import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ChainConnectionContext } from "src/context/DEXV2/ChainConnectionContext";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { OmitStrict } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import TransferStore, {
  TokenTransferParameters,
} from "src/state/DEXV2/DEXV2Stats/Vaults/TransferStore";
import { Form } from "../../../shared";

export interface TransferModalContentProps
  extends OmitStrict<
    TokenTransferParameters,
    "chainProvider" | "sender" | "walletConnectionProvider"
  > {}

export const TransferModalContent = observer(
  ({
    fromVault,
    deployerId,
    onClose,
    setLoading,
    tradePair,
    withdrawer,
  }: TransferModalContentProps) => {
    const { ethersProvider, currentAccount } = useLateInitContext(ChainConnectionContext);

    const connectionState = useLateInitContext(BotWalletConnectionContext);

    const transferParams: TokenTransferParameters = {
      fromVault,
      onClose,
      setLoading,
      deployerId,
      chainProvider: ethersProvider,
      sender: currentAccount,
      walletConnectionProvider: connectionState,
      tradePair,
      withdrawer,
    };
    const state = useLocalStore(TransferStore, transferParams);

    return (
      <Form onSubmit={state.formState.submitHandler()}>
        <LabeledSelector label="Vault" {...state.getSelectorFormProps("to_vault")} />

        <LabeledSelector label="Token" {...state.getSelectorFormProps("token")} />

        <LabeledInput
          label="Amount"
          type="number"
          step="any"
          {...state.formState.getInputProps("amount")}
        />

        <SubmitButton size="m">Transfer</SubmitButton>
      </Form>
    );
  }
);
