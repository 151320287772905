import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { SeriesProps } from "src/components/shared/Graph";
import useLocalStore from "src/hooks/useLocalStore";
import FiatBalanceStore, { FiatBalanceData } from "src/state/CEX/CEXDashboard/FiatBalanceStore";
import { CardOwnProps, CardProps } from "../../shared/Card";
import { RefreshDataButton } from "../../shared/RefreshDataButton";
import { BaseBalanceChart } from "../shared/BaseBalanceChart";

interface UseSeriesProps {
  data: FiatBalanceData;
}

const useSeriesData = ({ data }: UseSeriesProps): SeriesProps[] => {
  const seriesProps = useMemo((): SeriesProps[] => {
    const series: SeriesProps[] = [
      {
        data,
        title: "USDT",
        options: {
          lineWidth: 1,
        },
      },
    ];
    return series;
  }, [data]);

  return seriesProps;
};

export interface FiatBalanceChartProps extends Omit<CardProps, keyof CardOwnProps> {
  onRangeUpdate?: (range: DateTimeRange) => void;
}

export const FiatBalanceChart = observer(({ onRangeUpdate, ...props }: FiatBalanceChartProps) => {
  const { data, getFiatBalance, loading } = useLocalStore(FiatBalanceStore);

  useEffect(() => {
    getFiatBalance();
  }, [getFiatBalance]);

  const updateRange = useCallback(
    (range: DateTimeRange) => {
      onRangeUpdate?.(range);
      getFiatBalance();
    },
    [getFiatBalance, onRangeUpdate]
  );

  const seriesProps = useSeriesData({ data });

  return (
    <>
      <RefreshDataButton onRefresh={getFiatBalance} />
      <BaseBalanceChart
        title="Total USDT balance"
        loading={loading}
        graphProps={{ series: seriesProps, request: updateRange }}
        {...props}
      />
    </>
  );
});
