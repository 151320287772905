import { BaseDoughnut, BaseDoughnutProps } from "../shared/BaseDoughnut";
import { Card, CardProps } from "../shared/Card";

export interface BalancesDoughnutProps
  extends Pick<BaseDoughnutProps<number[], string>, "data">,
    CardProps {}

export const BalancesDoughnut = ({ data, ...props }: BalancesDoughnutProps) => (
  <Card title="Total balance" {...props}>
    <BaseDoughnut data={data} />
  </Card>
);
