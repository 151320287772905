import { AreaStyleOptions } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { darken, rgba } from "polished";
import { useEffect, useMemo } from "react";
import { Graph, GraphLegendOptions, SeriesProps } from "src/components/shared/Graph";
import useLocalStore from "src/hooks/useLocalStore";
import ArbitrageStore, { ArbitrageData } from "src/state/CEX/CEXDashboard/ArbitrageStore";
import { useDashboardColors } from "../hooks/useDashboardColors";
import { CardProps } from "../shared/Card";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { useGraphsChartOptions } from "../shared/config/graphs";
import { ArbitrageChartLegend } from "./ArbitrageChartLegend";
import { ArbitrageChartTooltip } from "./ArbitrageChartTooltip";
import * as styles from "./style";

interface AreaColorOptions
  extends Pick<AreaStyleOptions, "lineColor" | "topColor" | "bottomColor"> {}

const useMaxPriceAreaColor = () => {
  const {
    accent: { dodgerBlue, coralPink },
  } = useDashboardColors();

  const topColor = coralPink;
  const bottomColor = dodgerBlue;

  const colors = useMemo((): AreaColorOptions => {
    const darkenTopColor = darken(0.2, topColor);
    const topAlphaColor = rgba(darkenTopColor, 0.4);
    const bottomAlphaColor = rgba(bottomColor, 0.2);
    const lineColor = rgba(topColor, 0.75);
    return {
      lineColor,
      topColor: topAlphaColor,
      bottomColor: bottomAlphaColor,
    };
  }, [bottomColor, topColor]);

  return colors;
};

const useMinPriceAreaColor = () => {
  const {
    backgroundBaseDefault,
    accent: { dodgerBlue },
  } = useDashboardColors();

  const topColor = dodgerBlue;
  const backgroundColor = backgroundBaseDefault;

  const colors = useMemo((): AreaColorOptions => {
    const lineColor = rgba(topColor, 0.75);
    return {
      lineColor,
      topColor: backgroundColor,
      bottomColor: backgroundColor,
    };
  }, [backgroundColor, topColor]);

  return colors;
};

interface UseSeriesProps {
  data: ArbitrageData;
}

const useSeriesData = ({ data: { maxPrice, minPrice } }: UseSeriesProps): SeriesProps[] => {
  const maxPriceColors = useMaxPriceAreaColor();
  const minPriceColors = useMinPriceAreaColor();

  const seriesProps = useMemo((): SeriesProps[] => {
    const series: SeriesProps[] = [
      {
        data: maxPrice,
        visible: true,
        title: "Max Price",
        side: "left",
        options: {
          lineWidth: 1,
          ...maxPriceColors,
        },
      },
      {
        data: minPrice,
        visible: true,
        title: "Min Price",
        side: "left",
        options: {
          lineWidth: 1,
          ...minPriceColors,
        },
      },
    ];
    return series;
  }, [maxPrice, maxPriceColors, minPrice, minPriceColors]);

  return seriesProps;
};

export interface ArbitrageChartProps extends Omit<CardProps, "title"> {}

export const ArbitrageChart = observer((props: ArbitrageChartProps) => {
  const state = useLocalStore(ArbitrageStore);

  const { data, getArbitrage, loading } = state;

  useEffect(() => {
    getArbitrage();
  }, [getArbitrage]);

  const { options } = useGraphsChartOptions();

  const seriesProps = useSeriesData({ data });

  const legendOptions = useMemo(
    (): GraphLegendOptions => ({
      show: true,
      legend: ArbitrageChartLegend,
      position: "top",
    }),
    []
  );

  return (
    <>
      <RefreshDataButton onRefresh={getArbitrage} />
      <styles.ArbitrageChartCard title="Arbitrage" loading={loading} {...props}>
        <styles.Container>
          <Graph
            series={seriesProps}
            allowTimeScale={false}
            autoColorScales={false}
            options={options}
            legendOptions={legendOptions}
            showInfo={false}
            showAreaColor={false}
            tooltip={ArbitrageChartTooltip}
          />
        </styles.Container>
      </styles.ArbitrageChartCard>
    </>
  );
});
