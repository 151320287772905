import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { Prompt } from "src/components/shared/Prompts/Prompt";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { calcRecommendDiff } from "src/helpers/calcDiff";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useAppState from "src/state";
import { useTheme } from "styled-components";
import { ConstraintsInput } from "../../shared";
import * as styles from "./style";

export const VitrWallsInputs = observer(() => {
  const { SettingsBotState: state } = useAppState();
  const exchangeState = useLateInitContext(ExchangeContext);
  const { orderBookState } = exchangeState;

  const { primaryColor } = useTheme();

  const calc = calcRecommendDiff(
    +state.diff,
    +orderBookState.maxBuy,
    +orderBookState.minSell,
    +exchangeState.DTPMin,
    +exchangeState.DTPMax
  );

  const checkShowDiff = (): boolean =>
    !(
      exchangeState.DTPMin < orderBookState.minSell && exchangeState.DTPMax > orderBookState.maxBuy
    ) &&
    state.hideDiff &&
    !state.loader &&
    calc !== +state.data.settings.virtualRange.diff &&
    calc !== Infinity &&
    !isNaN(calc);

  return (
    <styles.WrapperForVirtWallsInputs>
      <styles.HorWrapperInputs>
        <ConstraintsInput
          as={SuggestionLabeledInput}
          type="number"
          label="Diff"
          value={state.data.settings.virtualRange.diff}
          onChange={state.getHandler("settings.virtualRange.diff")}
          {...state.getFieldErrorAsProps("settings.virtualRange.diff")}
          getSuggestion={(setRef) =>
            checkShowDiff() ? (
              <Prompt
                style={{
                  top: "revert",
                  color: primaryColor,
                  boxShadow: `0 0 0 1px ${primaryColor}`,
                }}
                value={String(calc)}
                setValue={() => state.getDiff(calc)}
                ownRef={setRef}
              />
            ) : null
          }
          suggestionGap={8}
          step="any"
        />
        <LabeledInput
          type="number"
          label="Spread"
          value={state.data.settings.virtualRange.spread}
          onChange={state.getHandler("settings.virtualRange.spread")}
          errorHint={state.errors.settings?.virtualRange?.spread}
          step="any"
        />
        <LabeledInput
          type="number"
          label="Ratio"
          value={state.data.settings.virtualRange.ratio}
          onChange={state.getHandler("settings.virtualRange.ratio")}
          errorHint={state.errors.settings?.virtualRange?.ratio}
          step="any"
        />
      </styles.HorWrapperInputs>

      <styles.Wrapper>
        <styles.WrapperForInputs>
          <styles.WrapperForCheckBoxWall>
            <CheckBox
              label="Buy Wall"
              checked={state.data.settings.virtualRange.enableBuyWall}
              onChange={state.getHandler("settings.virtualRange.enableBuyWall")}
            />
          </styles.WrapperForCheckBoxWall>
          <LabeledInput
            type="number"
            label="Buy Max Amount"
            value={state.data.settings.virtualRange.buyMaxAmount}
            onChange={state.getHandler("settings.virtualRange.buyMaxAmount")}
            errorHint={state.errors.settings?.virtualRange?.buyMaxAmount}
            step="any"
          />
          <LabeledInput
            type="number"
            label="Buy Min Amount"
            value={state.data.settings.virtualRange.buyMinAmount}
            onChange={state.getHandler("settings.virtualRange.buyMinAmount")}
            errorHint={state.errors.settings?.virtualRange?.buyMinAmount}
            step="any"
          />
        </styles.WrapperForInputs>

        <styles.WrapperForInputs>
          <styles.WrapperForCheckBoxWall>
            <CheckBox
              label="Sell Wall"
              checked={state.data.settings.virtualRange.enableSellWall}
              onChange={state.getHandler("settings.virtualRange.enableSellWall")}
            />
          </styles.WrapperForCheckBoxWall>
          <LabeledInput
            type="number"
            label="Sell Max Amount"
            value={state.data.settings.virtualRange.sellMaxAmount}
            onChange={state.getHandler("settings.virtualRange.sellMaxAmount")}
            errorHint={state.errors.settings?.virtualRange?.sellMaxAmount}
            step="any"
          />
          <LabeledInput
            type="number"
            label="Sell Min Amount"
            value={state.data.settings.virtualRange.sellMinAmount}
            onChange={state.getHandler("settings.virtualRange.sellMinAmount")}
            errorHint={state.errors.settings?.virtualRange?.sellMinAmount}
            step="any"
          />
        </styles.WrapperForInputs>
      </styles.Wrapper>
    </styles.WrapperForVirtWallsInputs>
  );
});
