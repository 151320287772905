import { useCallback, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import useAppState from "src/state";
import { nanoid } from "nanoid";
import { Tooltip } from "react-tooltip";
import { useVirtual } from "react-virtual";
import {
  flexRender,
  Row,
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getFacetedRowModel,
} from "@tanstack/react-table";
import { Loader } from "src/components/shared/Loader";
import { useTableVirtualPadding } from "src/hooks/useTableVirtualPadding";
import * as styles from "./style";
import { ColumnFilter } from "./ColumnFilter";

interface TableVirtualRowsProps {
  party: string;
  rowSize: number;
  columns: any;
  data: any;
  loader: boolean;
  showOrderInfo?: boolean;
  amountAllOrders?: number;
  amountSellOrders?: number;
  amountBuyOrders?: number;
}

export const TableVirtualRows = observer(
  ({ data, party, rowSize, columns, loader }: TableVirtualRowsProps) => {
    const id = useMemo(() => nanoid(), []);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const { userState } = useAppState();

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        columnFilters,
      },
      onColumnFiltersChange: setColumnFilters,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      debugTable: false,
    });

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
      estimateSize: useCallback(() => rowSize, [rowSize]),
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const [PaddingTop, PaddingBottom] = useTableVirtualPadding(virtualRows, totalSize);

    return (
      <styles.Wrapper>
        <styles.Container ref={tableContainerRef}>
          <styles.OrdersTable>
            <styles.Head data-tooltip-id={id}>
              {table.getHeaderGroups().map((headerGroup) => (
                <styles.HeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <styles.HeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={header.column.getCanResize() ? { width: header.getSize() } : undefined}
                      abilities={userState.abilities}
                      party={party}
                      column={header.column}
                      {...{
                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {header.column.getCanFilter() ? (
                        <ColumnFilter
                          label={header.column.columnDef.header as any}
                          column={header.column}
                        />
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </styles.HeaderCell>
                  ))}
                </styles.HeaderRow>
              ))}
            </styles.Head>
            <styles.Body>
              <PaddingTop />

              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<any>;

                return (
                  <styles.Row key={row.id} isOpacity={row.original.skip}>
                    {row.getVisibleCells().map((cell) => (
                      <styles.Cell
                        key={cell.id}
                        style={
                          cell.column.getCanResize() ? { width: cell.column.getSize() } : undefined
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </styles.Cell>
                    ))}
                  </styles.Row>
                );
              })}

              <PaddingBottom />
            </styles.Body>
          </styles.OrdersTable>

          <Tooltip id="totalAmount" variant="info" />
        </styles.Container>

        <Loader show={loader} />
      </styles.Wrapper>
    );
  }
);
