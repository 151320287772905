import { ChildrenProps } from "src/helpers/utils";
import { useChainProvider } from "src/hooks/useChainProvider";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import DEXV2CreatingStore from "src/state/DEXV2/DEXV2Create/Create";

export const DEXV2CreatingContext = createLateInitContext<DEXV2CreatingStore>();

type DEXV2CreatingProviderProps = ChildrenProps;

export const DEXV2CreatingProvider = ({ children }: DEXV2CreatingProviderProps) => {
  const chainProvider = useChainProvider();
  const { chainMetaState } = useAppState();
  const creatingStore = useLocalStore(DEXV2CreatingStore, chainProvider, chainMetaState);

  return (
    <DEXV2CreatingContext.Provider value={creatingStore}>{children}</DEXV2CreatingContext.Provider>
  );
};
