import { useCallback } from "react";
import { ConditionCompareType } from "src/modules/expertSystem";

export const MODULE_COMPARE_LIST: {
  label: string;
  value: ConditionCompareType;
}[] = [
  { label: "<=", value: "lte" },
  { label: "<", value: "lt" },
  { label: "=", value: "eq" },
  { label: "!=", value: "ne" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
];

export const useGetCompare = () =>
  useCallback(
    (v: ConditionCompareType | "") => MODULE_COMPARE_LIST.find((el) => el.value === v),
    []
  );
