import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Loader } from "../../../../../shared/Loader";
import * as styles from "./style";

export const Graphs = observer(
  ({ update, data: { left = [], right = [] }, assetLabel, tokenLabel, showLoader, ...props }) => {
    const [assetLineVisible, setAssetLineVisible] = useState(true);
    const [tokenLineVisible, setTokenLineVisible] = useState(true);

    return (
      <styles.Container {...props}>
        <styles.TitleWrapper>
          <styles.SubTitleWrapper>
            <styles.SubTitle>Balances</styles.SubTitle>
            <styles.AssetCheckBox
              label={assetLabel}
              checked={assetLineVisible}
              onChange={() => setAssetLineVisible((value) => !value)}
            />
            <styles.TokenCheckBox
              label={tokenLabel}
              checked={tokenLineVisible}
              onChange={() => setTokenLineVisible((value) => !value)}
            />
          </styles.SubTitleWrapper>
          <ReloadButton size="s" onClick={() => update()} />
        </styles.TitleWrapper>

        <styles.GraphContainer
          series={[
            {
              data: left,
              title: "buy",
              color: "assetColor",
              visible: assetLineVisible,
              side: "left",
            },
            {
              data: right,
              title: "sell",
              color: "tokenColor",
              visible: tokenLineVisible,
              side: "right",
            },
          ]}
          allowTimeScale={false}
          showTooltip={false}
          showAreaColor={false}
        />
        <Loader show={showLoader} />
      </styles.Container>
    );
  }
);
