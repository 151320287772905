import styled from "styled-components";
import { StyledFee } from "../PNLFee/style";

export const Container = styled.div`
  display: flex;
  gap: 4px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.dashboard.textAdditional};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: 0.2px;
`;

export const StyledPrice = styled(StyledFee)``;
