import debounce from "lodash.debounce";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback, useMemo } from "react";
import { Loader } from "src/components/shared/Loader";
import { PriceCalculatorInfo } from "src/state/DEXV2/DEXV2Swap/SwapModules/v2/PriceCalculator";
import { formatCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { FormContent, FormRow } from "../../Settings/shared/style";
import { InputCol } from "../SwapWidget/style";
import { usePriceCalculatorState } from "../hooks/usePriceCalculatorState";
import { QuoteSpan } from "./QuoteSpan";
import * as styles from "./style";

interface PriceCalculatorProps extends ComponentPropsWithoutRef<"div"> {}

const formatPriceInfo = ({
  quoteAmount: { usd: usdQuoteAmount },
  baseReservesAfter: { usd: usdBaseReservesAfter },
  baseReservesBefore: { usd: usdBaseReservesBefore },
}: PriceCalculatorInfo) => ({
  quoteAmount: formatCurrencyAmount(usdQuoteAmount),
  baseReservesAfter: formatCurrencyAmount(usdBaseReservesAfter),
  baseReservesBefore: formatCurrencyAmount(usdBaseReservesBefore),
});

export const PriceCalculator = observer((props: PriceCalculatorProps) => {
  const state = usePriceCalculatorState();

  const debouncedRefreshCalculator = useMemo(
    () => debounce(state.refreshCalculator, 500),
    [state.refreshCalculator]
  );

  const onUpdateTargetPrice = useCallback(
    (newAmount: string) => {
      state.updateTargetPrice(newAmount);
      debouncedRefreshCalculator();
    },
    [debouncedRefreshCalculator, state]
  );

  const { quoteAmount, baseReservesAfter, baseReservesBefore } = formatPriceInfo(state.info);

  return (
    <styles.Container {...props}>
      <styles.Title>Price calculator</styles.Title>
      <styles.FormContainer $disabled={!state.enabled}>
        <styles.RefreshButton
          loading={state.isRefreshing}
          onRefresh={state.forceRefreshCalculator}
          size="s"
        />
        <FormContent>
          <FormRow>
            <styles.SmallTokenInput
              label="Target price $"
              onChange={onUpdateTargetPrice}
              value={state.targetPrice}
            />
            <styles.SmallTokenInput
              label="Pool size before action $"
              readOnly
              value={baseReservesBefore}
            />
          </FormRow>
          <FormRow>
            <styles.SmallTokenInput
              label="Pool size after action $"
              readOnly
              value={baseReservesAfter}
            />
            <InputCol>
              <styles.SmallTokenInput label="Assets needed $" readOnly value={quoteAmount} />
              <QuoteSpan />
            </InputCol>
          </FormRow>
        </FormContent>
        <Loader show={state.isForceRefreshing} />
      </styles.FormContainer>
    </styles.Container>
  );
});
