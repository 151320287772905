import { makeAutoObservable } from "mobx";
import { createConstraintModalStyledText } from "src/components/BotsContent/CEX/CEXBotSettings/utils";
import { getData, getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { validateData } from "src/validation-schemas";
import { FieldError, FieldErrorProps } from "../CEX/CEXSettings/settingsBot";
import { InputFieldProps } from "../DEXV2/DEXV2Settings";
import WindowConsent from "../WindowConsent";

export type FormWarnings<T> = Partial<Record<FormDataKeys<T>, string>>;

export interface IParentFormStore<T> {
  data: T;
  validation: FormValidation<T>;
  submit: () => Promise<void>;
  validateConstraints?: (warnings: FormWarnings<T>) => boolean;
}

export default class DefaultFormStore<T> {
  handlers: FormHandlers<T> = {};

  errors: FormErrors<T> = {};

  private _warnings: FormWarnings<T> = {};

  isLoading = false;

  private _parentStore: IParentFormStore<T>;

  constructor(parent: IParentFormStore<T>) {
    this._parentStore = parent;

    makeAutoObservable(this);
  }

  private _loading: boolean = false;

  get loading(): boolean {
    return this._loading;
  }

  private _setLoading(value: boolean): void {
    this._loading = value;
  }

  private _getErrorByKey = (key: FormDataKeys<T>) =>
    getData(
      this.errors as Partial<Record<string, string | undefined>>,
      key as any // as FormDataKeys<Required<FormErrors<T>>>
    );

  private _getDataByKey = <K extends FormDataKeys<T> = FormDataKeys<T>>(key: K) =>
    getData(this._parentStore.data, key) as InputFieldProps["value"];

  getInputProps = (key: FormDataKeys<T>): InputFieldProps => ({
    errorHint: this._getErrorByKey(key),
    value: this._getDataByKey(key),
    onChange: this.getHandler(key),
  });

  private _clearConstraints = () => {
    this._warnings = {};
  };

  private _getConstraintsErrorByKey = (key: FormDataKeys<T>): FieldError | undefined => {
    const warningError = this._warnings[key];
    if (warningError) return { type: "warning", message: warningError };

    return undefined;
  };

  private _getConstraintsErrorsReport = () => {
    const warningMessages = filterBoolean(entries(this._warnings)).map(
      ([key, message]) => `${key} : ${message}`
    );

    return {
      title: "warnings",
      message: warningMessages.filter(Boolean).join("\n"),
    };
  };

  private _getFieldError = (key: FormDataKeys<T>): FieldError | undefined => {
    const error = this._getErrorByKey(key);
    if (error) return { type: "error", message: error };

    const constraintError = this._getConstraintsErrorByKey(key);
    return constraintError;
  };

  getFieldErrorAsProps = (key: FormDataKeys<T>): FieldErrorProps | undefined => {
    const fieldError = this._getFieldError(key);
    if (!fieldError) return undefined;
    const { type, message } = fieldError;
    return { errorHint: message, errorType: type };
  };

  getHandler = (key: FormDataKeys<T>): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this._parentStore.data, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (key) {
          default: {
            targetData[endKey] = getChangeEventValue(e);
          }
        }
      };
    }
    return this.handlers[key]!;
  };

  private _validate = (validateKeys?: FormDataKeys<T>[]) =>
    validateData(this._parentStore.validation, this._parentStore.data, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this._validate();

    if (valid) {
      this._clearConstraints();

      const validateConstraints = this._parentStore.validateConstraints ?? (() => true);
      const constraintsValid = validateConstraints(this._warnings);

      const { submit } = this._parentStore;

      if (!constraintsValid) {
        const { title, message } = this._getConstraintsErrorsReport();
        const styledTitle = createConstraintModalStyledText(
          `You have ${title}, do you want to proceed submitting?`
        );
        WindowConsent.showWindow(styledTitle, message, submit);
      } else {
        this._setLoading(true);
        await submit();
        this._setLoading(false);
      }
    }
  };
}
