import { ComponentPropsWithoutRef, useMemo } from "react";
import { PartyExchange } from "src/state/CEX/CEXDashboard/KPIStore";
import { useTextOverflow } from "../../../TrackableTokens/ExchangesListItem";
import {
  CounterTooltip,
  CounterTooltipText,
} from "../../../TrackableTokens/ExchangesListItem/style";
import * as styles from "./style";

export interface ExchangesListProps extends ComponentPropsWithoutRef<"p"> {
  exchanges: PartyExchange[];
}
const EXCHANGES_TOOLTIP_ID = "aum-exchanges-tooltip";

export const ExchangesList = ({ exchanges: partyExchanges, ...props }: ExchangesListProps) => {
  const exchanges = useMemo(() => partyExchanges.map(({ name }) => name), [partyExchanges]);

  const { textRef, overflowItemsCount } = useTextOverflow<HTMLParagraphElement>({
    items: exchanges,
  });

  const exchangesText = useMemo(() => exchanges.join(", "), [exchanges]);
  return (
    <>
      <styles.ExchangesCaption ref={textRef} data-tooltip-id={EXCHANGES_TOOLTIP_ID} {...props}>
        {exchangesText}
      </styles.ExchangesCaption>

      <CounterTooltip id={EXCHANGES_TOOLTIP_ID} hidden={overflowItemsCount <= 0}>
        <CounterTooltipText>{exchangesText}</CounterTooltipText>
      </CounterTooltip>
    </>
  );
};
