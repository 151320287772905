import { makeAutoObservable } from "mobx";
import { getSystemTimezone, isUTCTimezone } from "src/helpers/dateUtils";

export type TimezoneType = "UTC" | "system";

export interface ITimezoneState {
  get timezoneType(): TimezoneType;
  get timezone(): string;
}

export default class TimezoneStore implements ITimezoneState {
  private _currentTimezoneType: TimezoneType = "UTC";

  constructor() {
    makeAutoObservable(this);
  }

  toggleTimezone() {
    this._currentTimezoneType = isUTCTimezone(this._currentTimezoneType) ? "system" : "UTC";
  }

  get timezoneType() {
    return this._currentTimezoneType;
  }

  get timezone() {
    if (isUTCTimezone(this._currentTimezoneType)) {
      return "UTC";
    }

    return getSystemTimezone();
  }
}
