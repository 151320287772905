import { ComponentPropsWithoutRef } from "react";
import { SeriesLegendData } from "src/state/Graph/SeriesStore";
import * as styles from "./style";

export interface LegendOwnProps {
  data: SeriesLegendData[];
  toggleVisibility: (id: string) => void;
}

export interface LegendProps
  extends LegendOwnProps,
    Pick<ComponentPropsWithoutRef<"div">, "style" | "className"> {}

export const Legend = ({ data, toggleVisibility, ...props }: LegendProps) => (
  <styles.Container {...props}>
    {data.map(({ id, color, title, visible }) => (
      <styles.SeriesContainer key={id} onClick={() => toggleVisibility(id)}>
        <styles.SeriesColorBox $color={color} />
        <styles.SeriesTitle $visible={visible}>{title}</styles.SeriesTitle>
      </styles.SeriesContainer>
    ))}
  </styles.Container>
);
