import { TooltipProps } from "src/components/shared/Graph/Tooltip";
import { GraphTooltipRoot } from "../../shared/Graph/GraphTooltip/GraphTooltipRoot";
import { ArbitrageTooltipSeries } from "./ArbitrageTooltipSeries";

export interface ArbitrageChartTooltipProps extends TooltipProps {}

export const ArbitrageChartTooltip = (props: ArbitrageChartTooltipProps) => {
  const TooltipRoot = GraphTooltipRoot;

  const TooltipSeries = ArbitrageTooltipSeries;

  return <TooltipRoot series={TooltipSeries} {...props} />;
};
