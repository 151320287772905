import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import ExchangeIcons from "src/icons/ExchangeIcons";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import useAppState from "src/state";
import { BotInfoProps } from "../..";
import { TerminalSettings } from "../TerminalSettings";
import { TradeHistory } from "../TradeHistory";
import { BotName, ExchNotes } from "../shared";
import * as styles from "./style";

export interface DesktopTradingHistoryProps extends BotInfoProps {}

export const DesktopTradingHistory = observer(({ botInfo }: DesktopTradingHistoryProps) => {
  const exchangeState = useLateInitContext(ExchangeContext);
  const { userState } = useAppState();

  const { bot_uuid, party } = botInfo;

  const checkNotesPermission = checkParty(userState.abilities, "cex-notes-view", party ?? "");

  const [isNotesTab, setIsNotesTab] = useState(false);

  useEffect(() => {
    if (checkNotesPermission) {
      setIsNotesTab(true);
    }
  }, [checkNotesPermission]);

  const Content = isNotesTab ? (
    <ExchNotes party={party} bot_uuid={bot_uuid} minRows={9} />
  ) : (
    <TradeHistory />
  );

  return (
    <>
      <styles.TradeHistoryTitleContainer>
        <BotName>{exchangeState.market.replace(/_/g, " ")}</BotName>
        <PermissionWrapper party={party} abilityName="cex-notes-view">
          <styles.NotesToggleButton
            selected={isNotesTab}
            onChange={() => setIsNotesTab((it) => !it)}
          >
            {ExchangeIcons.notes()}
          </styles.NotesToggleButton>
        </PermissionWrapper>
        <TerminalSettings />
      </styles.TradeHistoryTitleContainer>

      <styles.TradingHistoryContainer>
        <ErrorBoundary>{Content}</ErrorBoundary>
      </styles.TradingHistoryContainer>
    </>
  );
});
