import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export type ActivationIndicatorStatus = "active" | "stopped" | "failed" | "expired" | "done";

export interface ActivationIndicatorProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
  status?: ActivationIndicatorStatus;
}

export const ActivationIndicator = ({ title, status, ...props }: ActivationIndicatorProps) => (
  <styles.Container {...props}>
    <styles.Indicator status={status} />
    <styles.StatusTitle>{title}</styles.StatusTitle>
  </styles.Container>
);
