import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleCategory } from "src/modules/expertSystem";
import useAppState from "src/state";
import { Actions } from "./Actions";
import { Conditions } from "./Conditions";

const getModuleContent = (type: StrategyModuleCategory, editing: boolean) => {
  switch (type) {
    case "condition": {
      return <Conditions editing={editing} />;
    }
    case "action": {
      return <Actions editing={editing} />;
    }
  }
};

export const Modules = observer(() => {
  const { currentType, party } = useLateInitContext(ModulesContext.Context);
  const {
    userState: { abilities },
  } = useAppState();
  const { abilityName } = useLateInitContext(AbilitiesContext);

  const checkPermission = useMemo(
    () => checkParty(abilities, abilityName, party),
    [abilities, abilityName, party]
  );

  const module = getModuleContent(currentType, checkPermission);

  return module;
});
