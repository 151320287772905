import { makeAutoObservable, runInAction, toJS } from "mobx";
import { getData, getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable, ExcludeStrict, Extends, Nullish } from "src/helpers/utils";
import {
  graterThan,
  greaterThanNumberKey,
  isNumber,
  required,
  smallerThanNumberKey,
  strictlyGreaterThan,
  validateData,
} from "src/validation-schemas";
import {
  DEXV2CounterStrategy,
  InputFieldProps,
  MODE_SWITCH_ITEMS,
  SwitchOption,
} from "../../../DEXV2Settings";
import { SetLoading } from "../../../DEXV2Stats/GasWallets/WithdrawGasStore";

const INITIAL_DATA: DEXV2CounterStrategy = {
  input_event: "buy",
  input_lower_bound: "",
  input_upper_bound: "",
  output_event: "buy",
  output_lower_bound_perc: 0,
  output_upper_bound_perc: 0,
  upper_price: "",
  lower_price: "",
  use_receiver: false,
  status: false,
};

type CounterStrategyKeys = FormDataKeys<DEXV2CounterStrategy>;

type CounterSwitchKeys = Extends<CounterStrategyKeys, "input_event" | "output_event">;

type OnSaveStrategy = (strategy: DEXV2CounterStrategy) => Promise<boolean>;
type OnCloseModal = () => void;

export interface CounterStrategiesParameters {
  initialData?: Nullish<DEXV2CounterStrategy>;
  onClose: OnCloseModal;
  setLoading: SetLoading;
  onSave: OnSaveStrategy;
}

export default class CounterStrategyStore implements Disposable {
  private _data: DEXV2CounterStrategy = INITIAL_DATA;

  private _validation: FormValidation<DEXV2CounterStrategy> = {
    input_event: required(),
    input_lower_bound: [
      required(),
      isNumber(),
      strictlyGreaterThan(0, "The value must be positive"),
      smallerThanNumberKey(
        "input_upper_bound",
        "Min Amount Base must be smaller than Max Amount Base"
      ),
    ],
    input_upper_bound: [
      required(),
      isNumber(),
      strictlyGreaterThan(0, "The value must be positive"),
      greaterThanNumberKey(
        "input_lower_bound",
        "Max Amount Base must be greater than Min Amount Base"
      ),
    ],
    output_event: required(),
    output_lower_bound_perc: [
      required(),
      isNumber(),
      strictlyGreaterThan(0, "Fill Percent Min must be > 0"),
      smallerThanNumberKey(
        "output_upper_bound_perc",
        "Fill Percent Min must be smaller than Fill Percent Max"
      ),
    ],
    output_upper_bound_perc: [
      required(),
      isNumber(),
      strictlyGreaterThan(0, "Fill Percent Max must be > 0"),
      greaterThanNumberKey(
        "output_lower_bound_perc",
        "Fill Percent Max must be greater than Fill Percent Min"
      ),
    ],
    upper_price: [
      required(),
      isNumber(),
      graterThan(0, "Max Price must be >= 0"),
      greaterThanNumberKey("lower_price", "Max Price must be greater than Min Price"),
    ],
    lower_price: [required(), isNumber(), graterThan(0, "Min Price must be >= 0")],
  };

  private _onChangeValidate: Partial<Record<CounterStrategyKeys, CounterStrategyKeys[]>> = {
    input_lower_bound: ["input_upper_bound"],
    input_upper_bound: ["input_lower_bound"],
    output_lower_bound_perc: ["output_upper_bound_perc"],
    output_upper_bound_perc: ["output_lower_bound_perc"],
    lower_price: ["upper_price"],
    upper_price: ["lower_price"],
  };

  private _handlers: FormHandlers<DEXV2CounterStrategy> = {};

  private _errors: FormErrors<DEXV2CounterStrategy> = {};

  private _closeModal: OnCloseModal;

  private _setLoading: SetLoading;

  private _onSave: OnSaveStrategy;

  constructor({ setLoading, onClose, initialData: data, onSave }: CounterStrategiesParameters) {
    makeAutoObservable(this);
    this._initData(data);

    this._closeModal = onClose;
    this._setLoading = setLoading;
    this._onSave = onSave;

    makeLoggable(this, { data: true });
  }

  private _initData = (data: Nullish<DEXV2CounterStrategy>) => {
    this._data = toJS(data) || INITIAL_DATA;
  };

  get data() {
    return this._data;
  }

  private _validateOnChangeKey = (key: CounterStrategyKeys) => {
    if (!this._validation[key]) return;

    const validateKeys =
      key in this._onChangeValidate ? [key, ...this._onChangeValidate[key]!] : [key];

    return this._validate(validateKeys);
  };

  getHandler = (key: CounterStrategyKeys): FormFieldHandler => {
    if (!this._handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      runInAction(() => {
        this._handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
          switch (key) {
            case "output_lower_bound_perc":
            case "output_upper_bound_perc": {
              const newValue = getChangeEventValue(e);
              if (+newValue <= 100) {
                targetData[endKey] = newValue;
              }
              break;
            }
            case "input_event":
            case "output_event": {
              targetData[endKey] = getChangeEventValue(e, true);
              break;
            }
            default: {
              targetData[endKey] = getChangeEventValue(e);
            }
          }
          this._validateOnChangeKey(key);
        };
      });
    }

    return this._handlers[key]!;
  };

  private _getErrorByKey = (key: CounterStrategyKeys) => getData(this._errors, key);

  private _getDataByKey = <K extends CounterStrategyKeys = CounterStrategyKeys>(key: K) =>
    getData(this.data, key);

  getInputProps = (
    key: ExcludeStrict<CounterStrategyKeys, "use_receiver" | "status">
  ): InputFieldProps => ({
    errorHint: this._getErrorByKey(key),
    value: this._getDataByKey(key),
    onChange: this.getHandler(key),
  });

  switchOptions = (key: CounterSwitchKeys): SwitchOption[] => {
    switch (key) {
      case "output_event":
      case "input_event": {
        return MODE_SWITCH_ITEMS.slice();
      }
    }
  };

  private _validate = (validateKeys?: CounterStrategyKeys[]) =>
    validateData(this._validation, this._data, this._errors, validateKeys);

  onSubmit = async () => {
    this._setLoading(true);
    try {
      if (this._validate()) {
        const { data } = this;
        const isSuccess = await this._onSave(data);

        if (isSuccess) {
          this._closeModal();
        }
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
