import { observer } from "mobx-react-lite";
import { mobileMediaQuery } from "src/components/UserManagement/Teams/style";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useDashboardViews } from "../../hooks/useDashboardViews";
import { BottomDrawer } from "../../shared/BottomDrawer";
import { DashboardBreakpoints } from "../../views/SummaryView/style";
import { DASHBOARD_VIEW_MENU_ITEMS } from "../DesktopHeader";
import { FilterButton } from "./FilterButton";
import * as styles from "./style";

export interface MobileHeaderProps {}

export const MobileHeader = observer((props: MobileHeaderProps) => {
  const state = useLateInitContext(DashboardContext.Context);

  const { party } = state.botParams;

  const { showToggle, menuItems } = useDashboardViews();

  return (
    <styles.Container {...props}>
      <styles.TitleRow>
        <styles.Title>Dashboard</styles.Title>
        <styles.PartyText>{party}</styles.PartyText>
      </styles.TitleRow>
      <styles.FiltersRow>
        {showToggle && (
          <styles.ViewSwitch
            selectedItem={state.currentView}
            direction="row"
            menuItems={menuItems}
            onChange={state.setCurrentView}
          />
        )}
        <DateTimeRangePickerSelector
          value={state.selectedRange}
          onChange={state.setRange}
          minDate={state.minDate}
          disableFuture
          maxDate={getCurrentDayjs()}
          mobileQuery={mobileMediaQuery(DashboardBreakpoints.XMobile)}
        />
        <BottomDrawer
          title="Filters"
          popupProps={{
            trigger: <FilterButton />,
          }}
        >
          <styles.FiltersWrapper>
            <styles.DrawerExchangesSelector exchangeSelectorProps={{ menuPlacement: "top" }} />
          </styles.FiltersWrapper>
        </BottomDrawer>
      </styles.FiltersRow>

      <styles.PickerButtonsRow>
        <styles.PeriodButton onClick={state.onPreviousMonth} disabled={state.previousMonthDisabled}>
          Previous
        </styles.PeriodButton>
        <styles.PeriodButton onClick={state.onCurrentMonth}>Current</styles.PeriodButton>
        <styles.PeriodButton onClick={state.onNextMonth} disabled={state.nextMonthDisabled}>
          Next
        </styles.PeriodButton>
      </styles.PickerButtonsRow>
    </styles.Container>
  );
});
