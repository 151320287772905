import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, keys } from "src/helpers/utils";

type FiltersState<K extends string> = Record<K, string[]>;

type EnabledState<K extends string> = Record<K, boolean>;

export interface IFiltersStateProvider<K extends string> {
  get state(): FiltersState<K>;
}

interface IFiltersEnableParams<K extends string> {
  filterStateProvider: IFiltersStateProvider<K>;
}

export default class FiltersEnabledStore<K extends string> implements Disposable {
  private _filterStateProvider: IFiltersStateProvider<K>;

  constructor({ filterStateProvider }: IFiltersEnableParams<K>) {
    makeAutoObservable(this);

    this._filterStateProvider = filterStateProvider;

    makeLoggable(this, { state: true });
  }

  private get _filtersState() {
    return this._filterStateProvider.state;
  }

  // Updated computed property to calculate enabled state
  get state(): EnabledState<K> {
    const enabledState = {} as EnabledState<K>;

    const filterState = this._filtersState;

    const filterKeys = keys(filterState) as K[];

    const firstNonEmptyKey = filterKeys.find((key) => filterState[key].length > 0);

    if (firstNonEmptyKey) {
      for (const key of filterKeys) {
        enabledState[key] = key === firstNonEmptyKey;
      }
      return enabledState;
    }
    for (const key of filterKeys) {
      enabledState[key] = true;
    }
    return enabledState;
  }

  destroy = () => {};
}
