import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FundingListContext } from "src/context/CEX/FundingProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FundingList } from "../Tables/FundingList";
import * as styles from "./style";

interface FundingInfoProps {
  market?: string;
}

export const FundingInfo = observer(({ market }: FundingInfoProps) => {
  const state = useLateInitContext(FundingListContext);

  useEffect(() => {
    if (market) state.setMarket(market);
  }, [state, market]);

  return (
    <>
      <styles.FundingPnL
        pnlProps={{
          party: state.party,
          base: state.base,
          range: state.rangeState.range,
          isShortView: true,
          setUpdPnLCb: state.mainState.setUpdHandlers,
        }}
        pickerPosition="bottom left"
        setRange={state.setRange}
        contentDirection="column"
      />
      <FundingList />
    </>
  );
});
