import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { Loader } from "src/components/shared/Loader";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import useAppState from "src/state";
import WindowConsent from "src/state/WindowConsent";
import { BotInfoProps } from "../..";
import { TerminalSettings } from "../TerminalSettings";
import { BindingsModules } from "./BindingsModules";
import { Diff } from "./Diff";
import { PairSettings } from "./PairSettings";
import { TradeSettings } from "./TradeSettings";
import { VirtualRange } from "./VirtualRange";
import * as styles from "./style";

interface SettingsProps extends BotInfoProps {
  abilityName: string;
  isMobile?: boolean;
}

export const Settings = observer(
  ({ botInfo: { party, bot_uuid, market }, abilityName, isMobile }: SettingsProps) => {
    const exchangeState = useLateInitContext(ExchangeContext);
    const stateBindings = useLateInitContext(BindingsBotContext);

    const { SettingsBotState: state, userState } = useAppState();

    const openModal = () => {
      if (state.data.saveDiff) {
        WindowConsent.showWindow(
          "You agree to the terms of the transaction AllDiffForm",
          "Are you sure? This action  may cause problems!",
          state.toggleSaveDiffPair
        );
      } else state.toggleSaveDiffPair();
    };

    useEffect(() => {
      if (!party) return;
      stateBindings.setParty(party);

      if (bot_uuid) stateBindings.setBotUUID(bot_uuid);
      stateBindings.loadBindings(state.checkBindings);

      if (bot_uuid) state.setBotUUID(bot_uuid);
      if (market) state.setMarket(market);
    }, [bot_uuid, party, state, stateBindings, market]);

    useEffect(() => {
      if (stateBindings.currentBindings) {
        stateBindings.checkDiff(state.checkDiffPrompt, exchangeState.isOriginPair);
      }
    }, [
      stateBindings,
      stateBindings.currentBindings,
      state.checkDiffPrompt,
      exchangeState.isOriginPair,
    ]);

    useEffect(() => {
      exchangeState.setUpdHandlers("updSettings", exchangeState.settingsState.getDataForTerminal);
    }, [exchangeState]);

    // set upd handler for upd bindings
    useEffect(() => {
      exchangeState.setUpdHandlers("updBindings", () => {
        stateBindings.loadBindings(state.checkBindings);
      });
    }, [state, exchangeState, stateBindings]);

    const submitDisabled = state.loader || state.botLoadsCount === 0;

    const showMobile = useFindWidth(880);

    return (
      <styles.ExchForm
        onSubmit={state.exchSubmitHandler()}
        onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && event.preventDefault()}
      >
        <styles.Content party={party || ""} abilities={userState.abilities}>
          <PairSettings />
          <Diff />
          <VirtualRange />
          <TradeSettings />
          <BindingsModules />

          <styles.SaveDiffWrapper>
            <styles.SupportText>Range: {state.percentRange}%</styles.SupportText>

            <CheckBox
              label="Save Diff for All Pairs"
              checked={state.data.saveDiff}
              onChange={openModal}
            />
          </styles.SaveDiffWrapper>

          <Loader show={state.loader} />
        </styles.Content>

        <PermissionWrapper party={party} abilityName={abilityName}>
          <styles.Footer>
            {showMobile ? <TerminalSettings /> : null}

            <SubmitButton stretched={isMobile} disabled={submitDisabled} style={{ width: "266px" }}>
              Submit all
            </SubmitButton>
          </styles.Footer>
        </PermissionWrapper>
      </styles.ExchForm>
    );
  }
);
