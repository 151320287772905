import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

class WindowConsent {
  hide: boolean = true;

  component: string | JSX.Element = "";

  _funcSend: (data: any) => void = () => {};

  _params: any = null;

  message: string | JSX.Element = "";

  constructor() {
    makeAutoObservable(this);
  }

  hideWindow() {
    toast.error("Canceled");

    this.hide = true;

    this.component = "";
    this.message = "";
    this._funcSend = () => {};
    this._params = null;
  }

  showWindow = (
    component: string | JSX.Element,
    message: string | number | JSX.Element,
    func: (data: any) => void,
    params?: any
  ) => {
    this.hide = false;
    this.component = component;
    this.message = typeof message === "number" ? String(message) : message;
    this._funcSend = func;
    this._params = params;
  };

  sendData = () => {
    this._funcSend(this._params);

    this.hide = true;

    this.component = "";
    this.message = "";
    this._funcSend = () => {};
    this._params = null;
  };
}

const windowConsent = new WindowConsent();
export default windowConsent;
