import { makeAutoObservable } from "mobx";
import { Trade } from "src/modules/exchange/trade";
import { toast } from "react-toastify";
import ExchangeStore from "..";
import TradesDataStorageStore from "./TradesDataStorageStore";
import { TerminalRequestMode } from "../shared/TerminalSettingsStore";

export default class TradeHistoryStore {
  mainState: ExchangeStore;

  dataStorage: TradesDataStorageStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;
    this.dataStorage = new TradesDataStorageStore(this);

    this.mainState.setUpdHandlers("updTradeHistory", this.downloadData);

    makeAutoObservable(this);
  }

  get pair() {
    return this.mainState.pair;
  }

  get exchange() {
    return this.mainState.exchange;
  }

  get terminalSettings() {
    return this.mainState.terminalSettingsState;
  }

  get settingsConfig() {
    return this.terminalSettings.settingsConfig.tradeHistoryModule;
  }

  get requestMode() {
    return this.settingsConfig.requestMode;
  }

  get pricePrecision() {
    return this.mainState.pricePrecision;
  }

  get amountPrecision() {
    return this.mainState.amountPrecision;
  }

  get isLoading() {
    return this.dataStorage.isLoading;
  }

  get exchStatusConnection() {
    return this.dataStorage.streamProvider.webSocketState.exchStatusConnection;
  }

  get exchangeDelay() {
    return this.dataStorage.streamProvider.webSocketState.exchangeDelayTitle;
  }

  get infoAcc() {
    return this.mainState.infoAcc;
  }

  downloadData = () => {
    this.dataStorage.loadData();
  };

  get tradeHistory(): Trade[] {
    return this.dataStorage.data;
  }

  get lastTrade() {
    return this.tradeHistory[0];
  }

  setStreamMode = (mode: TerminalRequestMode) => {
    if (mode === "WS" && this.dataStorage.lockedConnect) {
      toast.error("WS connection already open");
      return;
    }

    this.terminalSettings.toggleRequestMode("tradeHistoryModule", mode);
  };

  destroy = () => {
    this.dataStorage.streamProvider.closeConnect();
  };
}
