import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { getCEXBot } from "src/api/bots/CEX/bots";
import { startBot, stopBot } from "src/api/bots/CEX/settings";
import { logError } from "src/helpers/network/logger";
import { ICEXBot } from "src/modules/bots";

const EMPTY_CEX_BOT: ICEXBot = {
  name: "",
  base: "",
  diff: "",
  exchange: "",
  link: "",
  message: "",
  modifyTime: 0,
  modifyVolume: false,
  party: "",
  quote: "",
  side: "",
  status: 0,
  timeDontWork: 0,
  updated_at: 0,
  bot_uuid: "",
  created_at: 0,
};

export class CEXBotStore {
  CEXBot: ICEXBot = EMPTY_CEX_BOT;

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (load: boolean) => {
    this.loading = load;
  };

  get market() {
    return this.CEXBot.quote
      ? `${this.CEXBot.quote}_${this.CEXBot.base}_${this.CEXBot.exchange}`
      : "";
  }

  get bot_uuid() {
    return this.CEXBot.bot_uuid;
  }

  get party() {
    return this.CEXBot.party;
  }

  setBotUuid = (uuid: string) => {
    this.resetBot();

    this.CEXBot.bot_uuid = uuid;
  };

  get uuid() {
    return this.CEXBot.bot_uuid;
  }

  private _setBot = (bot: ICEXBot) => {
    this.CEXBot = bot;
  };

  async _fetchBot() {
    this.setLoading(true);

    try {
      const { isError, data } = await getCEXBot(this.uuid);
      if (!isError) {
        const { bot } = data;

        this._setBot(bot);
      }
    } catch {
      this._setBot(EMPTY_CEX_BOT);
    } finally {
      this.setLoading(false);
    }
  }

  loadBot = () => {
    this._fetchBot();
  };

  resetBot = () => {
    this.CEXBot = EMPTY_CEX_BOT;
  };

  start = async () => {
    try {
      const { isError } = await startBot(this.bot_uuid);

      if (!isError) {
        toast.success("Bot started", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  stop = async () => {
    try {
      const { isError } = await stopBot(this.bot_uuid);
      if (!isError) {
        toast.success("Bot stopped", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      logError(error);
    }
  };
}
