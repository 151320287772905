import { makeAutoObservable } from "mobx";
import { GetLiquidityResponse, getLiquidity } from "src/api/bots/CEX/dashboard";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable, Mapper, entries } from "src/helpers/utils";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";
import { timeToMs, valuesToNumber } from "./utils";

interface ILiquidityStoreParams extends IBaseStatsStoreParams {}

export type LiquidityTypeData = {
  total: number[];
  notOur: number[];
  our: number[];
};

type LiquidityData = {
  buy: LiquidityTypeData;
  sell: LiquidityTypeData;
  time: number[];
  price: number[];
};

const INITIAL_DATA: LiquidityData = {
  buy: {
    total: [],
    notOur: [],
    our: [],
  },
  sell: {
    total: [],
    notOur: [],
    our: [],
  },
  time: [],
  price: [],
};

const dataToNumbers = (data: GetLiquidityResponse["buy"]) =>
  Object.fromEntries(entries(data).map(([key, data]) => [key, valuesToNumber(data)])) as Record<
    keyof GetLiquidityResponse["buy"],
    number[]
  >;

const liquidityResponseToData: Mapper<GetLiquidityResponse, LiquidityData> = ({
  time,
  buy,
  sell,
  price_avg,
}) => {
  const buyNumbers = dataToNumbers(buy);

  const sellNumbers = dataToNumbers(sell);

  return {
    time,
    buy: {
      our: buyNumbers.our,
      total: buyNumbers.all,
      notOur: buyNumbers.not_our,
    },
    sell: {
      our: sellNumbers.our,
      total: sellNumbers.all,
      notOur: sellNumbers.not_our,
    },
    price: valuesToNumber(price_avg, false),
  };
};

export default class LiquidityStore implements IStatsFetcher, Disposable {
  private _stateProvider: IDashboardStateProvider;

  private _data: LiquidityData = INITIAL_DATA;

  private _loading = false;

  constructor({ stateProvider }: ILiquidityStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    makeLoggable(this, { priceRange: true });
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _queryParams() {
    return this._stateProvider.queryParams;
  }

  private _setData = (data: LiquidityData) => {
    this._data = data;
  };

  get time() {
    return timeToMs(this._data.time);
  }

  get buy() {
    return this._data.buy;
  }

  get sell() {
    return this._data.sell;
  }

  get price() {
    return this._data.price;
  }

  get _minPrice() {
    return this._data.price.length ? Math.min.apply(0, this._data.price) : undefined;
  }

  get _maxPrice() {
    return this._data.price.length ? Math.max.apply(0, this._data.price) : undefined;
  }

  get priceRange() {
    const max = this._maxPrice;
    const min = this._minPrice;
    if (max === undefined || min === undefined) return undefined;
    return Math.abs(max - min);
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getLiquidity = async () => {
    const { party } = this._botParams;
    const queryParams = this._queryParams;
    if (!party || !queryParams) return;

    this._setData(INITIAL_DATA);
    this._setLoading(true);
    try {
      const { data, isError } = await getLiquidity(party, queryParams);

      if (!isError) {
        const LiquidityData = liquidityResponseToData(data);
        this._setData(LiquidityData);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await this._getLiquidity();
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
