import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import useAppState from "src/state";
import ScopesStore from "src/state/UserManager/Scopes/ScopesStore";

export const ScopesContext = createLateInitContext<ScopesStore>();

type ScopesProviderProps = ChildrenProps;

export const ScopesProvider = ({ children }: ScopesProviderProps) => {
  const { UserManagerState: state } = useAppState();

  const scopesStore: ScopesStore = useLocalStore(ScopesStore, state);

  return <ScopesContext.Provider value={scopesStore}>{children}</ScopesContext.Provider>;
};
