import HeaderIcons from "src/icons/HeaderIcons";
import { Abilities } from "src/modules/abilities";
import useAppState from "src/state";

const stats = {
  label: "Stats",
  value: "STATS",
  icon: HeaderIcons.stats,
  to: (path: string) => `/DEX/${path}/?tab=STATS`,
};

const settings = {
  label: "Settings",
  value: "SETTINGS",
  icon: HeaderIcons.settings,
  to: (path: string) => `/DEX/${path}/?tab=SETTINGS`,
};

const funding = {
  label: "Funding",
  value: "FUNDING",
  icon: HeaderIcons.funding,
  to: (path: string) => `/DEX/${path}/?tab=FUNDING`,
};

const defaultMenuItems = [stats];

const mapRoleToMenuItems = (abilities: Abilities, party: string) => {
  const menuItems = [...defaultMenuItems];

  if (abilities["dex-settings-view"] && abilities["dex-settings-view"].includes(`${party}`)) {
    menuItems.push(settings);
  }

  menuItems.push(funding);

  return menuItems;
};

export const DEXMenuItems = () => {
  const { userState, DEXBotState } = useAppState();

  return mapRoleToMenuItems(userState.abilities, DEXBotState.party);
};
