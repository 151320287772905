import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { TableContentProps } from "src/components/BotsContent/DEX_V2/Stats/Tables/TablePanel/TableContent";
import { EmptyHashesText } from "src/components/shared/HashesList/EmptyHashesText";
import { DEXV2CounterStrategy } from "src/state/DEXV2/DEXV2Settings";
import { ActiveToggle } from "./ActiveToggle";
import { EventSideText } from "./EventSideText";
import { ReceiverIcon } from "./ReceiverIcon";
import { StrategiesDropdownButton } from "./StrategiesDropdownButton";
import * as styles from "./style";

interface UseStrategiesColumnsParams
  extends Pick<
    StrategiesTableProps,
    "onDeleteStrategy" | "onEditStrategy" | "disableDelete" | "onToggleActiveStrategy"
  > {}

const BASE_COLUMN_PROPS: Omit<Column<DEXV2CounterStrategy>, "accessor"> = {
  disableFilters: true,
  disableSortBy: true,
};

const useStrategiesColumns = ({
  onDeleteStrategy,
  onEditStrategy,
  onToggleActiveStrategy,
  disableDelete,
}: UseStrategiesColumnsParams) => {
  const walletsColumns = useMemo(() => {
    const columnsSchema: Column<DEXV2CounterStrategy>[] = [
      {
        Header: "Active",
        accessor: "status",
        Cell: ({ value, row: { index } }) => (
          <ActiveToggle
            showLabel={false}
            checked={value}
            onChange={() => {
              onToggleActiveStrategy?.(index);
            }}
          />
        ),
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Trigger side",
        accessor: "input_event",
        Cell: ({ value }) => <EventSideText side="input" type={value} />,
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Receiver",
        accessor: "use_receiver",
        id: "useReceiver",
        Cell: ({ value }) => <ReceiverIcon useReceiver={value} />,
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Min/Max Base",
        accessor: "input_lower_bound",
        id: "amountBase",
        Cell: ({ row }) => {
          const minBase = row.original.input_lower_bound;
          const maxBase = row.original.input_upper_bound;
          return <>{`${minBase} - ${maxBase}`}</>;
        },
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Min/Max Price",
        id: "price",
        accessor: "lower_price",
        Cell: ({ row }) => {
          const minPrice = row.original.lower_price;
          const maxPrice = row.original.upper_price;
          return <>{`${minPrice} - ${maxPrice}`}</>;
        },
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Event Side",
        accessor: "output_event",
        Cell: ({ value }) => <EventSideText side="output" type={value} />,
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "Min/Max Percent",
        id: "fillPercent",
        accessor: "output_lower_bound_perc",
        Cell: ({ row }) => {
          const minPercent = row.original.output_lower_bound_perc;
          const maxPercent = row.original.output_upper_bound_perc;
          return <>{`${minPercent} - ${maxPercent}`}</>;
        },
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: "",
        id: "cancel",
        accessor: "use_receiver",
        Cell: ({ row: { index } }) => (
          <StrategiesDropdownButton
            onDelete={() => onDeleteStrategy?.(index)}
            disableDelete={disableDelete}
            onEdit={() => onEditStrategy?.(index)}
          />
        ),
        ...BASE_COLUMN_PROPS,
      },
    ];

    return columnsSchema;
  }, [disableDelete, onDeleteStrategy, onEditStrategy, onToggleActiveStrategy]);

  return walletsColumns;
};

export interface StrategiesTableProps extends Omit<TableContentProps, "data" | "columns"> {
  strategies: DEXV2CounterStrategy[];
  onDeleteStrategy?: (index: number) => void;
  onEditStrategy?: (index: number) => void;
  onToggleActiveStrategy?: (index: number) => void;
  disableDelete?: boolean;
  error?: string;
}

export const StrategiesTable = observer(
  ({
    strategies,
    onDeleteStrategy,
    disableDelete,
    onEditStrategy,
    onToggleActiveStrategy,
    error,
    ...props
  }: StrategiesTableProps) => {
    const columns = useStrategiesColumns({
      onDeleteStrategy,
      onEditStrategy,
      onToggleActiveStrategy,
      disableDelete,
    });

    const showEmptyText = !strategies.length;

    return (
      <>
        {showEmptyText ? (
          <styles.EmptyContainer>
            <EmptyHashesText error={error} emptyText="No strategies added" />
          </styles.EmptyContainer>
        ) : (
          <styles.StyledStrategiesTable columns={columns} data={strategies} {...props} />
        )}
      </>
    );
  }
);
