import { makeAutoObservable } from "mobx";
import { calcRoundingValue } from "src/helpers/rounding";
import { entries } from "src/helpers/utils";
import { DEXV2Settings } from ".";
import { formatPoolPercent } from "../utils";

export interface DEXV2SettingsInfo {
  volume: {
    baseBuySell: number;
    fee: number;
    baseVolume: number;
  };
  limit: {
    baseBuy: number;
    spent: number;
    tx: number;
  };
  base: {
    quoteContract: string;
    baseContract: string;
    withdrawer: string;
    poolPercent: string;
  };
}

export interface IDEXV2SettingsProvider {
  data: DEXV2Settings;
  withdrawer: string;
}

const NATIVE_EXCHANGES_MAP = {
  bnb: ["cake", "cake1", "cake2", "ape", "baby", "blhswap", "sushibsc", "biswap"],
  eth: ["uni"],
  matic: ["quick", "sushimatic"],
  avax: ["joe", "pangolin"],
  ftm: ["spooky"],
  ada: ["wingriders"],
  arbitrum: ["sushiarbi"],
};

type Native = keyof typeof NATIVE_EXCHANGES_MAP;

export default class DEXV2SettingsInfoStore {
  private _provider: IDEXV2SettingsProvider;

  constructor(provider: IDEXV2SettingsProvider) {
    this._provider = provider;

    makeAutoObservable(this);
  }

  private get _settings() {
    return this._provider.data;
  }

  private get _withdrawer() {
    return this._provider.withdrawer;
  }

  private _feeByNative = (native: Native): number => {
    switch (native) {
      case "bnb": {
        return 0.0005;
      }
      case "eth": {
        return 0.01;
      }
      case "matic": {
        return 0.0023;
      }
      case "avax": {
        return 0.0017;
      }
      case "ftm": {
        return 0.3;
      }
      case "ada": {
        return 3;
      }
      case "arbitrum": {
        return 0.0001;
      }
    }
  };

  private _feeByExchange = (exchange: string) => {
    const nativeExchange = entries(NATIVE_EXCHANGES_MAP).find(([, exchanges]) =>
      Boolean(exchanges.find((exch) => exchange === exch))
    )?.[0];

    if (nativeExchange) {
      const fee = this._feeByNative(nativeExchange);
      return fee;
    }

    return 0;
  };

  private get _volumeInfo(): DEXV2SettingsInfo["volume"] {
    if (!this._settings.volume_data.period)
      return {
        baseBuySell: 0,
        fee: 0,
        baseVolume: 0,
      };

    const exchangeFee = this._feeByExchange("cake");
    const fee =
      (((1440 / this._settings.volume_data.period) *
        (+this._settings.volume_data.min_trades + +this._settings.volume_data.max_trades)) /
        2) *
      exchangeFee;

    const baseVolume =
      (((1440 / this._settings.volume_data.period) *
        (+this._settings.volume_data.min_trades + +this._settings.volume_data.max_trades) *
        (+this._settings.volume_data.min_amount + +this._settings.volume_data.max_amount)) /
        4) *
      0.997;

    const baseBuySell =
      (((baseVolume / 24) * (+this._settings.volume_data.buy_percent - 50)) / 100) * 2;

    return {
      fee,
      baseBuySell,
      baseVolume,
    };
  }

  private get _limitInfo(): DEXV2SettingsInfo["limit"] {
    if (!this._settings.limit_data.period) {
      return {
        baseBuy: 0,
        spent: 0,
        tx: 0,
      };
    }

    const sign = this._settings.limit_data.mod === "buy" ? +1 : -1;
    const baseBuy =
      (sign * +this._settings.limit_data.max_amount * 3600) / this._settings.limit_data.period;

    return { baseBuy, spent: 0, tx: 0 };
  }

  private get _baseInfo(): DEXV2SettingsInfo["base"] {
    const formattedPoolPercent = formatPoolPercent(this._settings.base_data.pool_percent);

    return {
      quoteContract: this._settings.quote,
      baseContract: this._settings.base,
      withdrawer: this._withdrawer,
      poolPercent: formattedPoolPercent,
    };
  }

  private _roundNumber = (num: number) => {
    const fractionDigits = calcRoundingValue(num);
    return +num.toFixed(fractionDigits);
  };

  private _roundInfo = <T extends Record<string, number>>(data: T) =>
    Object.fromEntries(
      entries(data).map(([key, value]) => {
        const roundedValue = this._roundNumber(value);
        return [key, roundedValue];
      })
    ) as T;

  private get _roundedVolumeInfo() {
    return this._roundInfo(this._volumeInfo);
  }

  private get _roundedLimitInfo() {
    return this._roundInfo(this._limitInfo);
  }

  get info(): DEXV2SettingsInfo {
    return {
      volume: this._roundedVolumeInfo,
      limit: this._roundedLimitInfo,
      base: this._baseInfo,
    };
  }
}
