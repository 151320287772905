import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import BalancesStore from "src/state/CEX/CEXDashboard/BalancesStore";
import { MockDashboardProvider } from "src/state/CEX/CEXDashboard/MockDashboardProvider";

const useBalanceOverviewStore = () => {
  const state = useLocalStore(BalancesStore, {
    stateProvider: new MockDashboardProvider(),
  });
  return state;
};

export const BalanceOverviewContext = createContextProvider({
  stateCreator: useBalanceOverviewStore,
});
