import { GroupType } from "src/modules/userManager";
import UserManagerStore from "../..";
import EditPartyStore from "../EditParty";
import { PartyInfoProvider } from "./PartyInfoStore";

export class PartyInfoProviderImpl implements PartyInfoProvider {
  private _managerState: UserManagerStore;

  private _partyState: EditPartyStore;

  constructor(managerState: UserManagerStore, partyState: EditPartyStore) {
    this._managerState = managerState;
    this._partyState = partyState;
  }

  get party() {
    return this._partyState.party;
  }

  userGroupsByType = (type: GroupType) => this._managerState.userGroupsByType(type);
}
