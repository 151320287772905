import styled from "styled-components";
import { SettingsPanel as BaseSettingsPanel } from "../../shared/SettingsPanel";
import { Content } from "../../shared/SettingsPanel/style";
import { FormContent as BaseFormContent } from "../../shared/style";

export const FormContent = styled(BaseFormContent)`
  justify-content: space-between;
`;

export const SettingsPanel = styled(BaseSettingsPanel)`
  /* ${Content} {
    flex: 1;
  } */
`;
