import { makeAutoObservable } from "mobx";
import {
  GetSupportedChainsResponse,
  GetSupportedExchangesResponse,
  SupportedChain,
  SupportedExchange,
  getSupportedChains,
  getSupportedExchanges,
} from "src/api/bots/DEXV2/chain";
import { arrayToMapObj } from "src/helpers/array";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { trimStartEndSlashes } from "src/helpers/url";
import { Mapper, entries } from "src/helpers/utils";
import { DEXV2ExchangeVersion } from "src/modules/bots";

export interface ScannerLink {
  addressScanner: string;
  txScanner: string;
}

export interface ChainInfo extends ScannerLink {
  name: string;
  id: string;
  rpc: string;
  native: string;
  dexscreenerName: string;
}

export const getScannerLink = (info: ChainInfo | null, type: "tx" | "wallet") => {
  if (!info) return undefined;
  const { addressScanner, txScanner } = info;
  switch (type) {
    case "tx": {
      return txScanner;
    }
    case "wallet": {
      return addressScanner;
    }
  }
};

export type ChainInfosMap = Partial<Record<string, ChainInfo>>;

const supportedChainToChainInfo: Mapper<SupportedChain, ChainInfo> = ({
  chain_id,
  rpc,
  scanner,
  symb,
  dexscreener_alias,
  chain_name,
}) => {
  const scannerBaseUrl = trimStartEndSlashes(scanner);
  return {
    id: `${chain_id}`,
    rpc,
    txScanner: `${scannerBaseUrl}/tx`,
    addressScanner: `${scannerBaseUrl}/address`,
    dexscreenerName: dexscreener_alias,
    name: chain_name,
    native: symb,
  };
};

const supportedChainsRespToChainInfos: Mapper<GetSupportedChainsResponse, ChainInfo[]> = (resp) => {
  const { chains } = resp;
  return chains.map(supportedChainToChainInfo);
};

export interface ExchangeInfo {
  chainId: string;
  router: string;
  name: string;
  factory: string;
  version: DEXV2ExchangeVersion;
  poolPercents: number[];
}

export type ExchangeInfosMap = Partial<Record<string, ExchangeInfo>>;
export type ChainExchangesMap = Partial<Record<string, ExchangeInfosMap>>;

const supportedExchangeRespToExchangeInfo = (
  chainId: number,
  name: string,
  { dex_version, pool_percent, ...exchange }: SupportedExchange
): ExchangeInfo => ({
  name,
  chainId: `${chainId}`,
  version: dex_version,
  poolPercents: pool_percent,
  ...exchange,
});

const supportedExchangesRespToChainExchanges: Mapper<
  GetSupportedExchangesResponse,
  ChainExchangesMap
> = (chainExchanges) => {
  const chainDexMaps = Object.fromEntries(
    entries(chainExchanges).map(([chainId, exchanges]) => {
      const exchangesEntries = entries(exchanges);
      const dexExchanges: ExchangeInfosMap = Object.fromEntries(
        exchangesEntries.map(([name, exchange]) => [
          name,
          supportedExchangeRespToExchangeInfo(chainId, name, exchange),
        ])
      );
      return [chainId, dexExchanges];
    })
  );
  return chainDexMaps;
};

export interface IChainsInfo {
  get chains(): ChainInfosMap;
  get chainExchanges(): ChainExchangesMap;
  getChainsInfo: () => Promise<void>;
}

interface ChainsInfoLoading {
  chains: boolean;
  exchanges: boolean;
}

export default class ChainsInfoStore implements IChainsInfo {
  private _chains: ChainInfo[] | null = null;

  private _chainExchanges: ChainExchangesMap | null = null;

  private _loading: ChainsInfoLoading = { chains: false, exchanges: false };

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chainExchanges: true, chains: true });
  }

  private _setLoading = (loading: boolean, key: keyof ChainsInfoLoading) => {
    this._loading[key] = loading;
  };

  private get _chainsOrEmpty() {
    return this._chains ?? [];
  }

  get chains(): ChainInfosMap {
    return arrayToMapObj(this._chainsOrEmpty, "id");
  }

  private _setChains = (chains: ChainInfo[]) => {
    this._chains = chains;
  };

  private get _chainExchangesOrEmpty() {
    return this._chainExchanges ?? {};
  }

  public get chainExchanges(): ChainExchangesMap {
    return this._chainExchangesOrEmpty;
  }

  private _setChainExchanges = (exchanges: ChainExchangesMap) => {
    this._chainExchanges = exchanges;
  };

  getChainsInfo = async () => {
    try {
      await Promise.all([this._getChains(), this._getExchanges()]);
    } catch (err) {
      logError(err);
    }
  };

  private _getChains = async () => {
    if (this._loading.chains || this._chains) return;

    this._setLoading(true, "chains");

    try {
      const { isError, data } = await getSupportedChains();
      if (!isError) {
        const chains = supportedChainsRespToChainInfos(data);
        this._setChains(chains);
      }
    } finally {
      this._setLoading(false, "chains");
    }
  };

  private _getExchanges = async () => {
    if (this._loading.exchanges || this._chainExchanges) return;

    this._setLoading(true, "exchanges");

    try {
      const { isError, data } = await getSupportedExchanges();
      if (!isError) {
        const chainExchanges = supportedExchangesRespToChainExchanges(data);
        this._setChainExchanges(chainExchanges);
      }
    } finally {
      this._setLoading(false, "exchanges");
    }
  };
}
