import { deepmerge } from "@mui/utils";
import {
  AreaSeriesOptions,
  AreaSeriesPartialOptions,
  AreaStyleOptions,
  IChartApi,
  ISeriesApi,
  LineData,
  PriceFormatterFn,
} from "lightweight-charts";
import {
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { hexToRgb } from "src/helpers/colors";
import { roundSingleValue } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { Nullish, SetRequired } from "src/helpers/utils";
import { SeriesProps as BaseSeriesProps, useLineColor } from "..";

interface UseAreaColorParams extends Pick<UseSeriesOptionsParams, "color" | "showAreaColor"> {}

const getColorAlphaLevel = (alpha: number, showColor: boolean) => (showColor ? alpha : 0);

const useAreaColor = ({
  color: lineColorName,
  showAreaColor,
}: UseAreaColorParams): Pick<AreaStyleOptions, "lineColor" | "topColor" | "bottomColor"> => {
  const lineColor = useLineColor(lineColorName);

  const topColor = hexToRgb(lineColor, getColorAlphaLevel(0.56, showAreaColor));
  const bottomColor = hexToRgb(lineColor, getColorAlphaLevel(0.04, showAreaColor));
  return {
    lineColor,
    topColor,
    bottomColor,
  };
};

const MIN_MOVE_RULES = [{ lowerBound: 1e7, value: 1e-2 }];

const getMinMove = (input: number) => {
  const defaultMove = 1e-4;
  for (const { lowerBound, value } of MIN_MOVE_RULES) {
    if (input >= lowerBound) {
      return value;
    }
  }
  return defaultMove;
};

interface UseMinMoveParams extends Pick<SeriesRootProps, "data"> {}

const useMinMove = ({ data }: UseMinMoveParams) => {
  const averageValue = useMemo(() => {
    if (!data.length) {
      return 0;
    }
    const sum = data.reduce((acc, { value }) => acc + value, 0);
    const avg = sum / data.length;
    return avg;
  }, [data]);

  const minMove = getMinMove(averageValue);

  return minMove;
};

interface UseSeriesOptionsParams
  extends SetRequired<
    Pick<
      SeriesRootProps,
      "options" | "color" | "showAreaColor" | "data" | "title" | "showTitleLabel"
    >,
    "color" | "showAreaColor"
  > {}

const useSeriesOptions = ({
  options: outerOptions,
  color,
  showAreaColor,
  data,
  title,
  showTitleLabel,
}: UseSeriesOptionsParams) => {
  const defaultOptions: OptionalSeriesOptions = useAreaColor({
    color,
    showAreaColor,
  });

  const minMove = useMinMove({ data });

  const titleOptions = useMemo(() => (showTitleLabel ? { title } : {}), [showTitleLabel, title]);

  const options = useMemo(
    () =>
      deepmerge<OptionalSeriesOptions>(
        { ...defaultOptions, priceFormat: { minMove }, ...titleOptions },
        outerOptions
      ),
    [defaultOptions, minMove, outerOptions, titleOptions]
  );

  return options;
};

export type SeriesOptions = AreaSeriesOptions;
export type OptionalSeriesOptions = AreaSeriesPartialOptions;

type PriceFormatOptions = NonNullable<OptionalSeriesOptions["priceFormat"]>;

const priceFormatter: PriceFormatterFn = (price) => numFormatter(roundSingleValue(price));

interface UseDefaultSeriesOptionsParams
  extends Pick<SeriesRootProps, "showPriceSuffix" | "startOptions"> {}

const useDefaultSeriesOptions = ({
  showPriceSuffix,
  startOptions,
}: UseDefaultSeriesOptionsParams) => {
  const defaultOptions: OptionalSeriesOptions = useMemo(() => {
    const priceFormat: PriceFormatOptions = showPriceSuffix
      ? {
          type: "custom",
          formatter: priceFormatter,
        }
      : {
          precision: 4,
        };
    return {
      priceFormat,
    };
  }, [showPriceSuffix]);

  const options = useMemo(
    () => deepmerge(defaultOptions, startOptions),
    [defaultOptions, startOptions]
  );

  return options;
};

export type ISeriesApiProvider = {
  api: () => ISeriesApi<"Area">;
  toggleVisibility: () => void;
  show: () => void;
  hide: () => void;
};

export interface SeriesRootProps extends SetRequired<BaseSeriesProps, "title" | "id"> {
  isChartMounted: boolean;
  chart: RefObject<IChartApi>;
  onOptionsUpdate?: (id: string, options: SeriesOptions) => void;
  onTitleUpdate?: (id: string, title: string) => void;
  showAreaColor?: boolean;
  showPriceSuffix?: boolean;
}

const SeriesRoot = forwardRef<ISeriesApiProvider | undefined, SeriesRootProps>(
  (
    {
      data = [],
      color = "primaryColor",
      visible = true,
      side = "left",
      title,
      id,
      showTitleLabel,
      isChartMounted,
      chart,
      onOptionsUpdate,
      onTitleUpdate,
      showPriceSuffix,
      showAreaColor = true,
      startOptions,
      options,
    },
    ref
  ) => {
    // useState to force useEffects and imperative handle rerun after series is attached to chart
    const [series, setSeries] = useState<ISeriesApi<"Area"> | null>(null);

    // explicit sync callback to expose current options
    const syncUpdateOptions = useCallback(
      (series: ISeriesApi<"Area">, newOptions?: OptionalSeriesOptions) => {
        if (newOptions) {
          series.applyOptions(newOptions);
        }
        const options = series.options();
        onOptionsUpdate?.(id, options);
      },
      [onOptionsUpdate, id]
    );

    useImperativeHandle(
      ref,
      () => {
        const seriesApi = series;
        if (!seriesApi) return undefined;
        return {
          api: () => seriesApi,
          toggleVisibility: () => {
            const isVisible = !seriesApi.options().visible;
            syncUpdateOptions(seriesApi, { visible: isVisible });
          },
          show: () => {
            syncUpdateOptions(seriesApi, { visible: true });
          },
          hide: () => {
            syncUpdateOptions(seriesApi, { visible: false });
          },
        };
      },
      [series, syncUpdateOptions]
    );

    const defaultSeriesOptions = useDefaultSeriesOptions({
      showPriceSuffix,
      startOptions,
    });

    const seriesOptions = useSeriesOptions({
      options,
      showAreaColor,
      color,
      data,
      title,
      showTitleLabel,
    });

    useEffect(() => {
      const chartApi = chart.current;
      if (!chartApi) return;

      const series = chartApi.addAreaSeries(defaultSeriesOptions);
      setSeries(series);
      syncUpdateOptions(series);

      return () => {
        setSeries(null);

        // the chart api doesn't expose a method to check disposed state
        // so we may attempt to remove series from a disposed chart
        try {
          chartApi.removeSeries(series);
        } catch {
          /* error removing series */
        }
      };
    }, [chart, defaultSeriesOptions, isChartMounted, syncUpdateOptions]);

    useEffect(() => {
      if (series) {
        syncUpdateOptions(series, seriesOptions);
      }
    }, [syncUpdateOptions, series, seriesOptions]);

    useEffect(() => {
      onTitleUpdate?.(id, title);
    }, [id, onTitleUpdate, series, title]);

    useEffect(() => {
      if (series) {
        syncUpdateOptions(series, { visible, priceScaleId: side });
      }
    }, [chart, series, side, syncUpdateOptions, visible]);

    useEffect(() => {
      const lineData = data as LineData[];

      series?.setData(lineData);
      chart.current?.timeScale().fitContent();
    }, [chart, data, series]);

    return null;
  }
);

interface SeriesProps extends SeriesRootProps {
  onSeriesChange?: (id: string, series: Nullish<ISeriesApiProvider>) => void;
}

export const Series = ({ onSeriesChange, id, ...props }: SeriesProps) => {
  const seriesRefCb = useCallback(
    (series: Nullish<ISeriesApiProvider>) => {
      onSeriesChange?.(id, series);
    },
    [onSeriesChange, id]
  );

  return <SeriesRoot ref={seriesRefCb} id={id} {...props} />;
};
