import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { ChooseSettingParamsStoreContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ActionsChangeSettingsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ActionChangeSettingsStore";
import { FormRows } from "./FormRows";

export interface FormContentProps {
  moduleIndex: number;
  setModuleParam: UseFormSetValue<IStrategyCreator>;
}

export const FormContent = observer(({ moduleIndex, setModuleParam }: FormContentProps) => {
  const state = useLateInitContext(ChooseSettingParamsStoreContext);
  const actionChangeSettings = useLocalStore(ActionsChangeSettingsStore, state);

  useEffect(() => {
    state.setModuleHardParamSetter(setModuleParam);
    state.setModuleIndex(moduleIndex);
  }, [state, setModuleParam, moduleIndex]);

  return (
    <FormRows
      params={actionChangeSettings.selectedParams}
      removeCb={state.removeSettingParamHandler}
    />
  );
});
