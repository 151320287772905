import { ComponentPropsWithoutRef } from "react";
import { PromptMsg } from "src/components/shared/shared";
import { useId } from "src/hooks/useId";
import Icons from "src/icons/Icons";
import * as styles from "./style";

const INFO_TEXT = "The entered time is presented in the UTC!";

const INFO_PROMPT_ID = "info_prompt";

export interface DateTimeInputPromptProps extends ComponentPropsWithoutRef<"div"> {
  text?: string;
}

export const DateTimeInputPrompt = ({ text = INFO_TEXT, ...props }: DateTimeInputPromptProps) => {
  const id = useId(6);
  const promptId = `${INFO_PROMPT_ID}-${id}`;

  return (
    <styles.InfoWrapper
      data-tooltip-content={text}
      data-tooltip-id={promptId}
      data-iscapture="true"
      {...props}
    >
      {Icons.prompt()}
      <PromptMsg id={promptId} />
    </styles.InfoWrapper>
  );
};
