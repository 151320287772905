import { Disposable } from "src/helpers/utils";
import { AppState } from "src/state";
import AddScopeStore from "./AddScopeStore";
import AddPartyStore from "./AddPartyStore";
import EditScopeStore from "./EditScopeStore";

export type ListUpdate = () => void;
export interface ScopesUpdate {
  getScopesList: ListUpdate;
  getPartiesList: ListUpdate;
}

type UserManagerState = AppState["UserManagerState"];

export default class ScopesStore implements Disposable {
  private _userManagerState: UserManagerState;

  scopesState;

  constructor(userManagerState: UserManagerState) {
    this._userManagerState = userManagerState;
    this.scopesState = {
      addScopeState: new AddScopeStore(this._userManagerState),
      addPartyState: new AddPartyStore(this._userManagerState),
      editScopeState: new EditScopeStore(this._userManagerState),
    };
  }

  destroy() {
    for (const store of Object.values(this.scopesState)) {
      store.destroy();
    }
  }
}
