import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Loader } from "src/components/shared/Loader";
import { Pagination } from "src/components/shared/Pagination";
import useAppState from "src/state";
import * as styles from "./style";

export const HashesList = observer(({ title, icon, hashes }) => {
  // const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const { WalletsState: state } = useAppState();
  return (
    <styles.HashesContainer
      title={title}
      icon={icon}
      button={
        <styles.HashesButtonsContainer>
          <ReloadButton size="s" onClick={() => state.getHashes(currentPage, 10)} />
          <Button variant="dashed" size="s" onClick={state.getAllHashes}>
            Download
          </Button>
        </styles.HashesButtonsContainer>
      }
    >
      <styles.Content>
        {state.hashes && state.hashes.Data
          ? state.hashes.Data.map((el) =>
              el !== "" ? (
                <styles.HashRow
                  href={
                    state.exchanges[state.exchange]
                      ? state.exchanges[state.exchange].hashLink + el
                      : "#"
                  }
                  key={el}
                >
                  <span>{el}</span>
                </styles.HashRow>
              ) : null
            )
          : null}
        <Loader show={state.loadHashes} />
      </styles.Content>
      <Pagination
        onPageChange={setCurrentPage}
        items={hashes?.Data}
        pageCount={hashes?.Pages}
        getItems={state.getHashes}
        itemsPerPage={10}
      />
    </styles.HashesContainer>
  );
});
