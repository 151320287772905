import {
  Body,
  Cell,
  Header,
  HeaderCell,
  TableContainer,
  TableRow,
  TableSortMixin,
} from "src/components/shared/GridTable";
import styled from "styled-components";

export const TableContent = styled(TableContainer)``;

export const Head = styled(Header)``;

export const TableBody = styled(Body)``;

export const Row = styled(TableRow)``;

export const HeaderRow = styled(TableRow)``;

export const TableHeaderCell = styled(HeaderCell)`
  height: 30px;

  padding: 5px;

  ${TableSortMixin}
`;

export const TableHeaderCellContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const TableCell = styled(Cell)`
  height: 32px;

  padding: 5px;
`;
