import { observer } from "mobx-react-lite";
import { darken } from "polished";
import { useMemo } from "react";
import { VolumeData } from "src/state/CEX/CEXDashboard/VolumeStore";
import { useDashboardColors } from "../hooks/useDashboardColors";
import { useStatsStore } from "../hooks/useStatsStore";
import { Card, CardProps } from "../shared/Card";
import { VolumeShareBar, VolumeShareBarProps } from "./VolumeShareBar";

const VOLUME_SHARE_INFO_TEXT = `This block displays the shares of organic and Gotbit 24 hour volume on all chosen exchanges.
`;

interface UseVolumeDataParams {
  data: VolumeData;
}

export const useVolumeData = ({ data: { exchanges, our, notOur, total } }: UseVolumeDataParams) => {
  const {
    accent: { coralPink, dodgerBlue },
  } = useDashboardColors();

  const barData = useMemo(
    (): VolumeShareBarProps["data"] => ({
      labels: exchanges,
      datasets: [
        {
          label: "Gotbit volume",
          data: our,
          borderWidth: 0,
          backgroundColor: dodgerBlue,
          hoverBackgroundColor: darken(0.15, dodgerBlue),
        },
        {
          label: "Organic volume",
          data: notOur,
          borderWidth: 0,
          backgroundColor: coralPink,
          hoverBackgroundColor: darken(0.15, coralPink),
        },
        { label: "Total", data: total, hidden: true },
      ],
    }),
    [coralPink, dodgerBlue, exchanges, notOur, our, total]
  );

  return barData;
};

export interface VolumeShareProps extends CardProps {}

export const VolumeShare = observer((props: VolumeShareProps) => {
  const { data, loading } = useStatsStore("volume");

  const volumeData = useVolumeData({ data });

  const barProps = { data: volumeData };

  return (
    <Card title="Volume share" info={VOLUME_SHARE_INFO_TEXT} loading={loading} {...props}>
      <VolumeShareBar {...barProps} />
    </Card>
  );
});
