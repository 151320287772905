import { Observer, observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column } from "react-table";
import { StatsWallet } from "src/api/bots/DEXV2/stats";
import { SeparatorNum } from "src/components/shared/Separator";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { GasWalletsContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/GasWallets";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useModalState } from "src/hooks/useModalState";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { TablePanelProps } from "../TablePanel";
import { AddressCell, HeaderAddressCell } from "../TablePanel/AddressCell";
import { InteractionPanel } from "./InteractionPanel";
import { ModalGasFill } from "./ModalGasFill";
import { ModalWithdrawGas } from "./ModalWithdrawGas";
import { WalletTypeCell } from "./WalletTypeCell";
import * as styles from "./styles";

export interface GasWalletsProps extends Pick<TablePanelProps, "style"> {}

export const GasWallets = observer((props: GasWalletsProps) => {
  const state = useLateInitContext(GasWalletsContext);

  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID, chainInfo } = botState;
  const scannerLink = getScannerLink(chainInfo, "wallet");

  const { hasAbility } = useLateInitContext(AbilitiesContext);

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getWalletBalances();
  }, [botUUID, state]);

  const {
    isShown: isWithdrawModalShown,
    show: showWithdrawModal,
    close: closeWithdrawModal,
  } = useModalState();
  const withdrawEnabled = hasAbility(DEXV2Ability.WalletsGasWithdraw);

  const {
    isShown: isGasFillModalShown,
    show: showGasFillModal,
    close: closeGasFillModal,
  } = useModalState();
  const gasFillEnabled = hasAbility(DEXV2Ability.WalletsGasFill);

  const columns = useMemo(() => {
    const columnSchema: Column<StatsWallet>[] = [
      {
        Header: (
          <Observer>
            {() => (
              <styles.MainCheckBox
                checked={state.allCheckedAddresses}
                onChange={() => state.selectAll()}
              />
            )}
          </Observer>
        ),
        id: "checkbox",
        accessor: "address",
        Cell: ({ value }) => (
          <Observer>
            {() => (
              <styles.TableCheckBox
                checked={state.selectedAddresses.includes(value)}
                onChange={() => state.selectAddress(value)}
              />
            )}
          </Observer>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: <HeaderAddressCell copyCb={state.copyAllAddresses} />,
        accessor: "address",
        sortType: "string",
        Cell: ({ value }) => <AddressCell address={value} scannerLink={scannerLink} />,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "string",
        Cell: ({ value }) => <WalletTypeCell type={value} />,
        disableFilters: true,
      },
      {
        Header: <Observer>{() => <>{state.nativeTicker}</>}</Observer>,
        accessor: "balance",
        sortType: "number",
        Cell: ({ value }) => <SeparatorNum value={value} />,
        disableFilters: true,
      },
    ];

    return columnSchema;
  }, [scannerLink, state]);

  return (
    <styles.GasWalletsPanel
      title="Gas Wallets"
      afterTitle={
        <InteractionPanel
          showGasFillModal={showGasFillModal}
          gasFillEnabled={gasFillEnabled}
          showWithdrawModal={showWithdrawModal}
          withdrawEnabled={withdrawEnabled}
        />
      }
      isLoading={state.loading}
      {...props}
    >
      <styles.GasWalletsTable data={state.roundedWallets} columns={columns} />

      <ModalWithdrawGas
        show={isWithdrawModalShown}
        close={closeWithdrawModal}
        wallets={state.selectedSenderWallets}
        withdrawer={state.withdrawer}
        deployerId={state.deployerId}
      />

      <ModalGasFill
        show={isGasFillModalShown}
        close={closeGasFillModal}
        wallets={state.selectedSenderWallets}
        deployerId={state.deployerId}
      />
    </styles.GasWalletsPanel>
  );
});
