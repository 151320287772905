import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { generateTime } from "./FundingStore";
import { GenerateTimeSeriesOptions, generateTimeSeries } from "./SpreadStore";

export type ArbitrageData = {
  maxPrice: ChartPoint[];
  minPrice: ChartPoint[];
};

const INITIAL_DATA: ArbitrageData = {
  maxPrice: [],
  minPrice: [],
};

const generateArbitrageData = (): ArbitrageData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
  };

  const maxPrice = generateTimeSeries({
    ...generateSeriesOptions,
    ...{ value: { min: 50_000, max: 100_000 } },
  });
  const minPrice = generateTimeSeries({
    ...generateSeriesOptions,
    ...{ value: { min: 0, max: 50_000 } },
  });

  return { maxPrice, minPrice };
};

export default class ArbitrageStore implements Disposable {
  private _data: ArbitrageData = INITIAL_DATA;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: ArbitrageData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  getArbitrage = async () => {
    this._setLoading(true);
    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateArbitrageData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
