import { observer } from "mobx-react-lite";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ModuleSettings } from "../../ModuleSettings";
import { ChangeSettings } from "../../shared/ChangeSettings";
import { AlertTg } from "./AlertTG";
import { Trade } from "./Trade";

export const ModulesForms = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  return (
    <ModuleSettings currentKey={state.currentAction}>
      <AlertTg key="alert_tg" />
      <Trade key="trade" />
      <ChangeSettings key="change_settings" createCb={state.createActionHandler} />
    </ModuleSettings>
  );
});
