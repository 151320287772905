import { makeAutoObservable, runInAction } from "mobx";
import { checkCode, sendNewCode } from "src/api/auth";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormValidation,
} from "src/helpers/forms/types";
import { setAbilities, setAccessToken, setRefreshToken } from "src/helpers/getToken";
import { CheckCode } from "src/modules/auth";
import { required, validateData } from "src/validation-schemas";

type CheckCodeKeys = FormDataKeys<CheckCode>;

class ConfirmStore {
  data: CheckCode = {
    login: "",
    code: "",
  };

  showLoader = false;

  validation: FormValidation<CheckCode> = {
    code: required(),
  };

  errors: FormErrors<CheckCode> = {};

  constructor() {
    makeAutoObservable(this);
  }

  private _setLoader = (loading: boolean) => {
    this.showLoader = loading;
  };

  setValue = (field: CheckCodeKeys, value: string) => {
    this.data[field] = value;
  };

  private _setError = (field: CheckCodeKeys, value: string) => {
    this.errors[field] = value;
  };

  getHandler =
    (field: CheckCodeKeys): FormFieldHandler =>
    (e) => {
      this.setValue(field, String(getChangeEventValue(e, true)));
    };

  getError = (key: CheckCodeKeys) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  private _checkErrorMessage = (msg: unknown) => {
    if (msg === "rpc error: code = InvalidArgument desc = wrong 2fa code") {
      this._setError("code", "Wrong code");
    }
  };

  sendAuthCode = async () => {
    this._setLoader(true);
    try {
      await sendNewCode(this.data.login);
    } finally {
      this._setLoader(false);
    }
  };

  submitHandler = (toHomePage: () => void) => (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this.validate();
    if (valid) {
      this._fetchCheckCode(toHomePage);
    }
  };

  private _fetchCheckCode = async (toHomePage: () => void) => {
    this._setLoader(true);
    try {
      const { isError, data } = await checkCode(this.data);
      if (!isError) {
        const { accessToken, refreshToken, abilities } = data;
        setAccessToken(accessToken);

        setRefreshToken(refreshToken);

        setAbilities(abilities);

        toHomePage();
      } else this._checkErrorMessage(data);
    } catch ({ message }: any) {
      this._checkErrorMessage(message);
    } finally {
      this._setLoader(false);
    }
  };
}

export default ConfirmStore;
