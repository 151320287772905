import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { addUser } from "src/api/userManager/usersAPI";
import {
  getData,
  getPathAndKey,
  getTargetValueByPath,
  getValueByPath,
  setData,
} from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { Extends } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { GroupType, NewUser, UserGroup } from "src/modules/userManager";
import { email, required, validateData } from "src/validation-schemas";
import { ShowPanels } from ".";

interface FormNewUser extends Omit<NewUser, "group_type"> {
  group_type: "" | GroupType;
}

type FormNewUserKeys = FormDataKeys<FormNewUser>;

type SelectorHandler = (data: SingleValue<SelectorValue>) => void;

type FormSelectors = Extends<
  FormNewUserKeys,
  "group_type" | "hierarchy.role" | "hierarchy.scope_id" | "group_name"
>;

export interface AddUserProvider {
  getUsersList: () => Promise<void>;
  getUserGroupsList: () => Promise<void>;
  visiblePanel: Pick<ShowPanels, "showAddUserPanel">;
  userGroupsByUserType: (type: GroupType) => UserGroup[];
  groupTypesSelect: SelectorValue[];
  scopesSelect: SelectorValue[];
  rolesSelect: SelectorValue[];
}

const INITIAL_NEW_USER: FormNewUser = {
  name: "",
  email: "",
  password: "",
  hierarchy: {
    scope_id: "",
    role: "",
  },
  is_active: true,
  group_type: "",
  tg_handler: "",
  group_name: "",
};

class AddUserStore {
  newUser: FormNewUser = INITIAL_NEW_USER;

  validation: FormValidation<FormNewUser> = {
    name: required(),
    email: [required(), email()],
    password: required(),
    "hierarchy.scope_id": required(),
    "hierarchy.role": required(),
    group_type: required(),
    group_name: required(),
  };

  handlers: FormHandlers<FormNewUser, FormFieldHandler> = {};

  errors: FormErrors<FormNewUser> = {};

  isLoadingUser = false;

  private _provider: AddUserProvider;

  private _isShownReaction: IReactionDisposer;

  private _groupTypeChangedReaction: IReactionDisposer;

  constructor(provider: AddUserProvider) {
    this._provider = provider;

    makeAutoObservable(this, {
      selectorEnabled: false,
      selectorOptions: false,
    });

    this._isShownReaction = reaction(
      () => this._provider.visiblePanel.showAddUserPanel,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );

    this._groupTypeChangedReaction = reaction(
      () => this.newUser.group_type,
      (_group_type) => {
        this._setData("group_name", "");
      }
    );
  }

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};

    this.newUser = INITIAL_NEW_USER;
  };

  getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "group_type": {
        return (data) => {
          if (!data) return;
          this._setData("group_type", String(data.value) as GroupType);
        };
      }
      case "hierarchy.role": {
        return (data) => {
          if (!data) return;
          this._setData("hierarchy.role", String(data.value));
        };
      }
      case "hierarchy.scope_id": {
        return (data) => {
          if (!data) return;
          this._setData("hierarchy.scope_id", parseInt(String(data.id), 10));
        };
      }
      case "group_name": {
        return (data) => {
          if (!data) return;
          this._setData("group_name", String(data.value));
        };
      }
    }
  };

  selectorEnabled = computedFn((key: Extends<FormSelectors, "group_name">) => {
    switch (key) {
      case "group_name": {
        return this.newUser.group_type && this.selectorOptions("group_name").length;
      }
    }
  });

  selectorValue = (key: Extends<FormSelectors, "group_type" | "group_name">): SelectorValue =>
    stringToSelectorValue(this._getData(key));

  selectorOptions = computedFn((key: FormSelectors): SelectorValue[] => {
    switch (key) {
      case "group_name": {
        const groupType = this.newUser.group_type;
        if (groupType === "") {
          return [];
        }
        return getSelectorList(
          this._provider.userGroupsByUserType(groupType).map((group) => group.name)
        );
      }
      case "group_type": {
        return this._provider.groupTypesSelect;
      }
      case "hierarchy.scope_id": {
        return this._provider.scopesSelect;
      }
      case "hierarchy.role": {
        return this._provider.rolesSelect;
      }
    }
  });

  getHandler = (key: FormNewUserKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.newUser, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  private _setData = <K extends FormNewUserKeys>(
    key: K,
    value: ObjectPathValue<FormNewUser, K>
  ) => {
    setData(this.newUser, key, value);
  };

  private _getData = <K extends FormNewUserKeys>(key: K) => getData(this.newUser, key);

  getError = (key: FormDataKeys<NewUser>) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this.validation, this.newUser, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      runInAction(() => {
        this.isLoadingUser = true;
      });

      try {
        const { isError } = await addUser(this.newUser as NewUser);

        if (!isError) {
          toast.success("User created successfully", {
            autoClose: 2000,
          });

          this._provider.getUsersList();
          this._provider.getUserGroupsList();
        }
      } finally {
        runInAction(() => {
          this.isLoadingUser = false;
        });
      }
    }
  };

  destroy() {
    this._isShownReaction();
    this._groupTypeChangedReaction();
  }
}

export default AddUserStore;
