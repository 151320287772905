import { Observer, observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column, TableProps } from "react-table";
import { ExchangesLegendContext } from "src/context/CEX/Dashboard/ExchangesLegend";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import {
  ExchangePieItem,
  ExchangePieItemRow,
} from "src/state/CEX/CEXDashboard/ExchangesLegendStore";
import { StatsTableProps } from "../../../shared/StatsTable";
import { LegendLabel } from "./LegendLabel";
import * as styles from "./style";

const DEFAULT_TABLE_COLUMN: Partial<Column<ExchangePieItemRow>> = {
  disableSortBy: true,
};

export interface LegendTableProps
  extends Pick<StatsTableProps<ExchangePieItem>, keyof TableProps> {}

export const LegendTable = observer((props: LegendTableProps) => {
  const state = useLateInitContext(ExchangesLegendContext.Context);
  const columns = useMemo(() => {
    const columnsSchema: Column<ExchangePieItemRow>[] = [
      {
        Header: "",
        accessor: "label",
        Cell: ({ row }) => {
          const { label, color, visible, index } = row.original;
          return (
            <LegendLabel
              text={label}
              color={color}
              hidden={!visible}
              onClick={() => state.toggleItemVisibility(index)}
              onMouseEnter={() => state.triggerItemHover(index)}
              onMouseLeave={() => state.resetItemHover()}
            />
          );
        },
        Footer: "Total",
        width: 40,
      },
      {
        Header: "Balance",
        accessor: "balance",
        Footer: () => <Observer>{() => <>{state.total}</>}</Observer>,
        width: 30,
      },
      {
        Header: "Percent",
        accessor: "percent",
        Footer: "100%",
        width: 30,
      },
    ];

    return columnsSchema;
  }, [state]);
  return (
    <styles.Table
      columns={columns}
      data={state.data}
      defaultColumn={DEFAULT_TABLE_COLUMN}
      {...props}
    />
  );
});
