import { SettingsContainer as BaseSettingsContainer } from "src/components/BotsContent/DEX_V2/Settings/Main/style";
import { SettingsPanel as BaseSettingsPanel } from "src/components/BotsContent/DEX_V2/Settings/shared/SettingsPanel";
import {
  FormCol as BaseFormCol,
  FormRow as BaseFormRow,
} from "src/components/BotsContent/DEX_V2/Settings/shared/style";
import { mobileMediaQuery } from "src/components/BotsContent/DEX_V2/Settings/style";
import { HashesList } from "src/components/shared/HashesList";
import { Switches } from "src/components/shared/Switches";
import { CardSurface, PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const FormContent = styled.div`
  max-width: 270px;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const Switch = styled(Switches)`
  flex-direction: row;
  justify-content: space-between;
`;

export const WalletsList = styled(HashesList)`
  height: 55px;
`;

export const FormRow = styled(BaseFormRow)``;

export const FormDynamicRow = styled(FormRow)`
  gap: 0px;

  > *:not(:last-child),
  > *:only-child {
    max-width: calc(50% - 14px);
    margin-right: 28px;
  }
`;

export const FormCol = styled(BaseFormCol)``;

export const SettingsPanel = styled(PanelContainer)`
  display: flex;

  padding: 15px 20px;
`;

export const SideSettingsPanel = styled(BaseSettingsPanel)`
  ${CardSurface}

  padding: 15px 20px;
`;

export const SettingsContainer = styled(BaseSettingsContainer)`
  @media ${mobileMediaQuery("500px")} {
    flex-direction: column;
  }
`;
