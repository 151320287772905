import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import useAppState from "src/state";
import { BotInfoProps } from "../CEX";
import { CurrentFundings } from "./CurrentFundings";
import { FundingForm } from "./FundingForm";
import * as styles from "./style";

export interface FundingProps extends BotInfoProps {
  type: "CEX" | "DEX";
  abilityName: string;
}

export const Funding = observer(({ type, botInfo, abilityName }: FundingProps) => {
  const { bot_uuid, party = "" } = botInfo;
  const { FundingDEXState: fundingDEXState, userState } = useAppState();

  useEffect(() => {
    if (!bot_uuid) return;
    fundingDEXState.setBotUUID(bot_uuid);
    fundingDEXState.downloadData();
  }, [fundingDEXState, type, bot_uuid]);

  return (
    <styles.Container>
      <styles.Title>Funding</styles.Title>
      <styles.Content party={party} abilityName={abilityName} abilities={userState.abilities}>
        <CurrentFundings
          type={type}
          botInfo={botInfo}
          state={fundingDEXState}
          abilityName={abilityName}
          abilities={userState.abilities}
        />
        <PermissionWrapper party={party} abilityName={abilityName}>
          <FundingForm state={fundingDEXState} />
        </PermissionWrapper>
      </styles.Content>
    </styles.Container>
  );
});
