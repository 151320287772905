import { observer } from "mobx-react-lite";
import { Notes, NotesProps } from "src/components/shared/Notes";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { DEXV2PermissionsWrapper } from "../../shared/Permissions/DEXV2PermissionsWrapper";

export interface DEXV2NotesProps
  extends Omit<NotesProps, "getNote" | "setNote" | "bot_uuid" | "abilityName" | "party"> {}

export const DEXV2Notes = observer((props: DEXV2NotesProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID } = botState;

  const { party } = useLateInitContext(AbilitiesContext);

  return (
    <DEXV2PermissionsWrapper ability={DEXV2Ability.NotesView}>
      <Notes
        getNote={botState.getNotes}
        setNote={botState.setNotes}
        bot_uuid={botUUID}
        abilityName={DEXV2Ability.NotesEdit}
        party={party}
        {...props}
      />
    </DEXV2PermissionsWrapper>
  );
});
