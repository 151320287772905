import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { mobileMediaQuery } from "src/components/BotsContent/DEX_V2/Settings/style";
import { ButtonsRow } from "./ButtonsRow";
import { SelectorsRow } from "./SelectorsRow";
import * as styles from "./style";

export interface HeaderProps {}

export const Header = observer((props: HeaderProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery("650px"));

  return (
    <styles.Container>
      <SelectorsRow isMobile={isMobile} />
      <ButtonsRow />
    </styles.Container>
  );
});
