import { observer } from "mobx-react-lite";
import { ModalPanel } from "src/components/shared/ModalPanel";
import useAppState from "src/state";

export const EditHierarchy = observer(() => {
  const { UserManagerState: state } = useAppState();

  return (
    <ModalPanel
      label="Edit hierarchy"
      show={state.visiblePanel.showEditHierarchy}
      close={state.openPanel("showEditHierarchy")}
      loading={false}
    >
      <div />
    </ModalPanel>
  );
});
