import { TooltipProps } from "src/components/shared/Graph/Tooltip";
import { GraphTooltipRoot } from "../../../../shared/Graph/GraphTooltip/GraphTooltipRoot";
import { BaseBalanceSeries } from "./BaseBalanceSeries";

export interface BaseBalanceChartTooltipProps extends TooltipProps {}

export const BaseBalanceChartTooltip = (props: BaseBalanceChartTooltipProps) => {
  const TooltipRoot = GraphTooltipRoot;

  const TooltipSeries = BaseBalanceSeries;

  return <TooltipRoot series={TooltipSeries} {...props} />;
};
