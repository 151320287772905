import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

export interface ContentProps {
  abilities: Abilities;
  abilityName: string;
  party: string;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1;

  padding: 0px 10px;
  gap: 30px;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content minmax(280px, calc(100vh - 600px)) 280px;
  flex: 1 1;

  gap: 10px;

  grid-template-areas:
    "PnL           _"
    "funding-list funding-list"
    "transfer-list funding-form";

  @media screen and (max-width: 1100px) {
    grid-template-columns: 100%;

    grid-template-rows: repeat(2, auto) repeat(2, 280px);

    grid-template-areas:
      "funding-form   "
      "PnL            "
      "funding-list   "
      "transfer-list  ";
  }
`;
