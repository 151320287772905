import { Percent } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { FieldError } from "src/state/CEX/CEXSettings/settingsBot";
import {
  MAX_HIGH_SLIPPAGE,
  MIN_HIGH_SLIPPAGE,
  ONE_HUNDRED_PERCENT,
  ZERO_PERCENT,
} from "../../utils";

export interface Slippage {
  slippage: string;
  percent?: Percent;
  error?: FieldError;
}

export function toPercent(maxSlippage: string | undefined): Percent | undefined {
  if (!maxSlippage) return undefined;
  if (Number.isNaN(maxSlippage)) return undefined;
  const numerator = Math.floor(Number(maxSlippage) * 100);
  return new Percent(numerator, 10_000);
}

export function getSlippageError(slippage?: Percent): FieldError | undefined {
  if (slippage?.lessThan(ZERO_PERCENT) || slippage?.greaterThan(ONE_HUNDRED_PERCENT)) {
    return { type: "error", message: "Slippage should be between 0% and 100%" };
  }

  if (slippage?.greaterThan(MAX_HIGH_SLIPPAGE)) {
    return { type: "alert", message: "Slippage is higher than 50%!" };
  }
  if (slippage?.greaterThan(MIN_HIGH_SLIPPAGE)) {
    return { type: "warning", message: "Slippage is higher than 1%!" };
  }
}

export interface ISlippage {
  setSlippage: (slippage: string) => void;
  get slippage(): Slippage;
}

export class SlippageStore {
  private _slippage: string = "";

  private _slippageError?: FieldError;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { slippage: true });
  }

  private _setSlippage = (slippage: string) => {
    this._slippage = slippage;
  };

  private _setSlippageError = (error?: FieldError) => {
    this._slippageError = error;
  };

  setSlippage = (slippage: string) => {
    this._setSlippage(slippage);
    const percent = toPercent(slippage);
    const warning = getSlippageError(percent);
    this._setSlippageError(warning);
  };

  private get _slippagePercent() {
    return toPercent(this._slippage);
  }

  get slippage(): Slippage {
    return {
      slippage: this._slippage,
      percent: this._slippagePercent,
      error: this._slippageError,
    };
  }
}
