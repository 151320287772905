import { toast } from "react-toastify";
import { fetchBalance } from "src/api/bots/CEX/exchange";
import { Balance } from "src/modules/exchange/balance";
import { logError } from "../network/logger";
import { calcRoundingValue, toRounding } from "../rounding";

export const findQuoteBase = <K extends keyof Balance>(
  arr: Balance[],
  value: Balance[K],
  field: K
) => {
  const findBalance = arr.find((el) => el[field] === value);
  if (findBalance) return findBalance;
  return { free: 0, locked: 0, asset: "" };
};

export const calcAvgPrice = (
  minSell: number,
  maxBuy: number,
  dontTradePriceMin: number,
  dontTradePriceMax: number,
  pricePrecision: number
) => {
  let avgPrice = 0;

  if (
    !dontTradePriceMin ||
    !dontTradePriceMax ||
    minSell < dontTradePriceMin ||
    maxBuy > dontTradePriceMax
  ) {
    avgPrice = +((minSell + maxBuy) / 2);
  } else {
    avgPrice = +((Math.max(maxBuy, dontTradePriceMin) + Math.min(minSell, dontTradePriceMax)) / 2);
  }
  return toRounding(avgPrice, pricePrecision);
};

interface CheckBalancesProps {
  account_uuid: string;
  account_name: string;
  quoteTicker: string;
  baseTicker: string;
}

export interface BalanceComparisonProps
  extends Omit<CheckBalancesProps, "account_uuid" | "quoteTicker" | "baseTicker"> {
  balance: Balance;
  ticker: string;
  amount: number;
  typeOperation: string;
}

export const getAccBalances = async ({
  account_uuid,
  quoteTicker,
  baseTicker,
}: CheckBalancesProps) => {
  try {
    const {
      data: { data },
      isError,
    } = await fetchBalance({ account_uuid });

    if (!isError) {
      // converting ticker names to upper case
      // for processing scripts of different registers in a pair and on an account
      const balances = data.map((el: Balance) => ({
        ...el,
        asset: el.asset.toUpperCase(),
      }));

      const upperCaseQuote = quoteTicker.toUpperCase();
      const upperCaseBase = baseTicker.toUpperCase();

      const quoteBalance = findQuoteBase(balances, upperCaseQuote, "asset");
      const baseBalance = findQuoteBase(balances, upperCaseBase, "asset");

      return { quoteBalance, baseBalance };
    }
  } catch (err) {
    logError(err);
  }
};

export const balanceComparison = ({
  balance,
  typeOperation,
  account_name: account,
  ticker,
  amount,
}: BalanceComparisonProps) => {
  if (!balance.free) {
    toast.error(`
${typeOperation}

Insufficient funds on ${account.toUpperCase()} account!
No Free ${ticker} on balance
    `);
    return false;
  }

  if (amount <= balance.free) {
    return true;
  }
  toast.error(`
${typeOperation}

Insufficient funds on ${account.toUpperCase()} account!
Order must not exceed ${balance.free} ${ticker}
    `);

  return false;
};

export const calcPnLPrice = (deltaTotal: number, deltaToken: number) => {
  if (deltaTotal >= 0 && deltaToken >= 0) {
    return "NET PROFIT";
  }

  if (deltaTotal < 0 && deltaToken < 0) {
    return "NET LOSS";
  }

  if (deltaTotal < 0 && deltaToken === 0) {
    return "NET LOSS";
  }

  if (deltaTotal === 0 && deltaToken < 0) {
    return "NET LOSS";
  }

  const price = deltaTotal / deltaToken;

  return Math.abs(+toRounding(price, calcRoundingValue(price)));
};
