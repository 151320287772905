import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { InfoPanel, InfoPanelProps } from "../../shared/InfoPanel";
import * as styles from "./style";

export interface BaseInfoProps extends Omit<InfoPanelProps, "label" | "children"> {}

export const BaseInfo = observer(({ ...props }: BaseInfoProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const scannerLink = getScannerLink(botState.chainInfo, "wallet");

  const state = useLateInitContext(DEXV2SettingsContext);

  const { baseContract, quoteContract, withdrawer, poolPercent } = state.info.base;

  return (
    <InfoPanel label="Base Info" {...props}>
      <styles.Content>
        <styles.LabeledAddress
          label="Quote contract"
          address={quoteContract}
          scannerLink={scannerLink}
        />
        <styles.LabeledAddress
          label="Base contract"
          address={baseContract}
          scannerLink={scannerLink}
        />
        <styles.LabeledAddress label="Withdrawer" scannerLink={scannerLink} address={withdrawer} />
        <LabeledInput label="Pool percent" value={poolPercent} readOnly />
      </styles.Content>
    </InfoPanel>
  );
});
