import styled from "styled-components";
import { StatsTable } from "../shared/StatsTable";
import { Row, TableCell } from "../shared/StatsTable/style";

export const StyledFundingTable = styled(StatsTable)`
  & ${Row} {
    ${TableCell} {
      flex-direction: row-reverse;
      justify-content: start;
    }
  }
` as typeof StatsTable;
