export enum ChainId {
  Ethereum = "1",
  BSC = "56",
  Avalanche = "43114",
  Fantom = "250",
  Polygon = "137",
  Arbitrum = "42161",
  Base = "8453",
  zkSync = "324",
  Mantle = "5000",
  Core = "1116",
  Kava = "2222",
}
