import { TooltipProps } from "src/components/shared/Graph/Tooltip";
import { GraphTooltipRoot } from "../../shared/Graph/GraphTooltip/GraphTooltipRoot";
import { SpreadTooltipSeries } from "./SpreadTooltipSeries";

export interface SpreadChartTooltipProps extends TooltipProps {}

export const SpreadChartTooltip = (props: SpreadChartTooltipProps) => {
  const TooltipRoot = GraphTooltipRoot;

  const TooltipSeries = SpreadTooltipSeries;

  return <TooltipRoot series={TooltipSeries} {...props} />;
};
