import { BigNumber } from "ethers";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { CacheOptions } from "../../../utils";

export interface IBlockTimestampProvider {
  get timestamp(): BigNumber | undefined;
  getBlockTimestamp(options?: CacheOptions): Promise<void>;
}

export class BlockTimestampProvider implements IBlockTimestampProvider {
  private _chainProvider: IChainProvider;

  private _timestamp: BigNumber | undefined;

  constructor(chainProvider: IChainProvider) {
    makeAutoObservable(this);

    this._chainProvider = chainProvider;

    makeLoggable(this, { timestamp: () => this.timestamp?.toString() });
  }

  private get _multicallContract() {
    return this._chainProvider.multicallContract;
  }

  private _setTimestamp = (timestamp: BigNumber) => {
    this._timestamp = timestamp;
  };

  get timestamp() {
    return this._timestamp;
  }

  async getBlockTimestamp() {
    const multicall = this._multicallContract;
    if (!multicall) return;
    try {
      const timestamp = await multicall.getCurrentBlockTimestamp();

      return this._setTimestamp(timestamp);
    } catch (err) {
      chainErrorHandler(err);
    }
  }
}
