import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getActionTitle } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/modules";
import { CreateStrategyContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IActionStrategyCreatorModule, IStrategyCreator } from "src/modules/expertSystem";
import { HardParamsViewProps } from "../../shared/ModuleSoftParamsShell/HardParamsView";
import { FormsAreaContainer } from "../../shared/style";
import { AlertTg, Trade } from "./SoftParams";
import { ChangeSettings } from "./SoftParams/ChangeSettings";

export const FormsArea = () => {
  const state = useLateInitContext(CreateStrategyContext);
  const { control, watch, setValue, getValues } = useFormContext<IStrategyCreator>();

  const { remove } = useFieldArray({
    control,
    name: "actions",
  });

  const watchFieldArray = watch("actions");

  const getRemoveCb = useCallback(
    (index: number) => () => {
      remove(index);
      state.removeActionHandler(index);
    },
    [remove, state]
  );

  const getHardParamsProps = useCallback(
    (uuid: string) => state.getActionHardParamsProps(uuid),
    [state]
  );

  const getModuleProps = useCallback(
    (index: number, module: IActionStrategyCreatorModule, removeCb: () => void) => ({
      index,
      uuid: module.uuid,
      headerProps: { title: getActionTitle(module.type), remove: removeCb },
    }),
    []
  );

  const getContent = useCallback(
    (module: IActionStrategyCreatorModule, index: number) => {
      const removeCb = getRemoveCb(index);

      const hardParamsProps: HardParamsViewProps = getHardParamsProps(module.uuid);

      const moduleProps = getModuleProps(index, module, removeCb);

      switch (module.type) {
        case "alert_tg":
          return <AlertTg key={module.uuid + index} {...hardParamsProps} {...moduleProps} />;
        case "trade":
          return <Trade key={module.uuid + index} {...hardParamsProps} {...moduleProps} />;
        case "change_settings":
          return (
            <ChangeSettings
              key={module.uuid + index}
              setModuleParam={setValue}
              shellProps={{ ...hardParamsProps, ...moduleProps }}
            />
          );

        default:
          return null;
      }
    },
    [getRemoveCb, getHardParamsProps, setValue, getModuleProps]
  );

  return (
    <FormsAreaContainer>
      {watchFieldArray.map((field, index) => getContent(field, index))}
    </FormsAreaContainer>
  );
};
