import { Disposable } from "src/helpers/utils";
import { AppState } from "src/state";
import AddUserGroupStore from "./AddUserGroupStore";
import EditUserGroupStore from "./EditUserGroupStore";

export type ListUpdate = () => void;
export interface UserGroupsUpdate {
  getUserGroupsList: ListUpdate;
}

type UserManagerState = AppState["UserManagerState"];

export default class UserGroupsStore implements Disposable {
  private _userManagerState: UserManagerState;

  userGroupsState;

  constructor(userManagerState: UserManagerState) {
    this._userManagerState = userManagerState;
    this.userGroupsState = {
      addUserGroupState: new AddUserGroupStore(this._userManagerState),
      editUserGroupState: new EditUserGroupStore(this._userManagerState),
    };
  }

  destroy() {
    for (const store of Object.values(this.userGroupsState)) {
      store.destroy();
    }
  }
}
