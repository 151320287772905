import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { CheckBox } from "src/components/shared/CheckBox";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useAppState from "src/state";
import { BotInfoProps } from "../..";
import { BotModule } from "./BotModule";
import { ExchangeModule } from "./ExchangeModule";
import { SwapModule } from "./SwapModule";
import { VitrWallsInputs } from "./VirtWallsInputs";
import * as styles from "./style";

export interface VirtWallsProps extends BotInfoProps {}

const MENU_ITEMS = [
  { label: "No", value: "no" },
  { label: "Swap", value: "swap" },
  { label: "Bot", value: "bot" },
  { label: "Exchange", value: "exchange" },
];

const BINDING_INFO = `Virtual Range:
Swap / Bot / Exchange module — selected module (binding) for range creation

Diff — difference between selected binding price and current price (in percent)

Spread — distance between DTPMin and DTPMax (in pips)

Ratio — how many times current price bigger than selected module (binding) price

Buy wall — turn On Buy wall for price = DTPMin (from account Spread)

Sell Wall — turn On Sell wall for price = DTPMax (from account Spread)

Buy Min / Max Amount — amount of Buy wall in Base currency

Sell Min / Max Amount — amount of Sell wall in Base currency`;

export const VirtWalls = observer(
  ({ botInfo: { bot_uuid, party = "" }, ...props }: VirtWallsProps) => {
    const stateBindings = useLateInitContext(BindingsBotContext);

    const { SettingsBotState: settingsState } = useAppState();
    const exchangeState = useLateInitContext(ExchangeContext);

    const showTabs = useFindWidth(450);

    useEffect(() => {
      if (!bot_uuid) return;
      if (!party) return;
      stateBindings.setBotUUID(bot_uuid);
      stateBindings.setParty(party);

      stateBindings.loadBindings();
    }, [stateBindings, bot_uuid, party]);

    useEffect(() => {
      if (stateBindings.currentBindings) {
        stateBindings.checkDiff(settingsState.checkDiffPrompt, exchangeState.isOriginPair);
      }
    }, [
      stateBindings,
      stateBindings.currentBindings,
      settingsState.checkDiffPrompt,
      exchangeState.isOriginPair,
    ]);

    return (
      <SettingsPanel
        label="Virtual Range"
        party={party}
        loader={settingsState.loader}
        checkbox={
          <CheckBox
            label="Cancel Spread Orders"
            checked={settingsState.data.settings.virtualRange.cancelSpreadOrders}
            onChange={settingsState.getHandler("settings.virtualRange.cancelSpreadOrders")}
          />
        }
        submitHandler={settingsState.submitModulHandler("virtualRange")}
        info={BINDING_INFO}
        saved={settingsState.savedModuls.virtualRange}
        style={{ gridArea: "binding" }}
        {...props}
      >
        <styles.Content>
          <styles.WrapperForModules>
            {showTabs ? (
              <styles.BindingsTabs menuItems={MENU_ITEMS} defaultKey="swap">
                <styles.NoContent key="no">
                  <SwapModule />
                </styles.NoContent>
                <SwapModule key="swap" />
                <BotModule key="bot" />
                <ExchangeModule key="exchange" />
              </styles.BindingsTabs>
            ) : (
              <>
                <SwapModule />
                <BotModule />
                <ExchangeModule />
              </>
            )}
          </styles.WrapperForModules>
          <VitrWallsInputs />
        </styles.Content>
      </SettingsPanel>
    );
  }
);
