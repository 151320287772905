import useAppState from "src/state";

export const useBotsState = () => {
  const {
    allBotsState: { currentTab },
    cexBotsState: CEXState,
    dexBotsState: DEXState,
    dexV2BotsState: DEXV2State,
  } = useAppState();

  switch (currentTab) {
    case "CEX": {
      return CEXState;
    }
    case "DEX": {
      return DEXState;
    }
    case "DEXV2": {
      return DEXV2State;
    }
  }
};
