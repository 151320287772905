import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable } from "src/helpers/utils";
import { generateAmount, generateTokenTickers } from "./FundingStore";
import { generatePercentChange } from "./PairsStore";

export type BalancesDynamicsData = {
  currency: string;
  percent: string;
  abs: string;
  absUsd: string;
};

export type BalancesDynamicsBars = {
  labels: string[];
  data: number[];
};

const generateBalancesDynamics = (): BalancesDynamicsData[] => {
  const tokensCount = faker.number.int({ min: 2, max: 5 });

  const tokens = generateTokenTickers({ count: tokensCount });

  const data = tokens.map((token) => {
    const percent = generatePercentChange();
    const abs = generateAmount();
    const absUsd = generateAmount();

    return {
      currency: token,
      percent,
      abs,
      absUsd,
    };
  });

  return data;
};

export default class BalancesDynamicsStore implements Disposable {
  private _data: BalancesDynamicsData[] = [];

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true, bars: true });
  }

  private _setData = (data: BalancesDynamicsData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  private get _tokens() {
    return this._data.map(({ currency }) => currency);
  }

  private get _tokensPercent() {
    const values = Object.values(this._data);
    const percentage = values.map(({ percent }) => +percent);
    return percentage;
  }

  get bars(): BalancesDynamicsBars {
    const values = this._tokensPercent;
    const labels = this._tokens;
    return { labels, data: values };
  }

  get absTotal() {
    return this._data.reduce((sum, { absUsd }) => sum + +absUsd, 0);
  }

  getBalancesDynamics = () => {
    try {
      const data = generateBalancesDynamics();
      this._setData(data);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
