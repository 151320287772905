import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { SeriesProps } from "src/components/shared/Graph";
import useLocalStore from "src/hooks/useLocalStore";
import TokensBalancesStore, {
  TokenBalanceData,
} from "src/state/CEX/CEXDashboard/TokensBalancesStore";
import { CardOwnProps, CardProps } from "../../shared/Card";
import { RefreshDataButton } from "../../shared/RefreshDataButton";
import { BaseBalanceChart } from "../shared/BaseBalanceChart";
import { TokensSelector } from "./TokensSelector";

interface UseSeriesProps {
  data: TokenBalanceData;
}

const useSeriesData = ({ data: { ticker, data } }: UseSeriesProps): SeriesProps[] => {
  const seriesProps = useMemo((): SeriesProps[] => {
    const series: SeriesProps[] = [
      {
        data,
        title: ticker,
        options: {
          lineWidth: 1,
        },
      },
    ];
    return series;
  }, [data, ticker]);

  return seriesProps;
};

export interface TokensBalancesChartProps extends Omit<CardProps, keyof CardOwnProps> {
  onRangeUpdate?: (range: DateTimeRange) => void;
}

export const TokensBalancesChart = observer(
  ({ onRangeUpdate, ...props }: TokensBalancesChartProps) => {
    const { data, getTokensBalances, tokens, loading, currentToken, setCurrentToken } =
      useLocalStore(TokensBalancesStore);

    useEffect(() => {
      getTokensBalances();
    }, [getTokensBalances]);

    const updateRange = useCallback(
      (range: DateTimeRange) => {
        onRangeUpdate?.(range);
        getTokensBalances();
      },
      [getTokensBalances, onRangeUpdate]
    );

    const seriesProps = useSeriesData({ data });

    return (
      <>
        <RefreshDataButton onRefresh={getTokensBalances} />
        <BaseBalanceChart
          title={`Total ${currentToken} balance`}
          loading={loading}
          afterTitle={
            <TokensSelector value={currentToken} options={tokens} onChange={setCurrentToken} />
          }
          graphProps={{ series: seriesProps, request: updateRange }}
          {...props}
        />
      </>
    );
  }
);
