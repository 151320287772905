import { IReactionDisposer, makeAutoObservable, observable, when } from "mobx";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { ICache, TTLCache } from "src/state/shared/Cache";

import { IRouterProvider } from "../..";
import { ISwapDexNameProvider } from "../../..";
import { getQuoterV2Address } from "../../../utils";
import { IRouter, Router } from "../../shared/Swap/Router";
import {
  IQuoterV2AddressProvider,
  IV3QuotesProvider,
  IV3QuotesProviderParams,
  V3QuotesProvider,
} from "../Swap/Providers/V3QuotesProvider";
import { IV3RouteStateParams, V3RouteStateProvider } from "../Swap/Providers/V3RouteStateProvider";
import {
  IV3RoutesProvider,
  IV3RoutesProviderParams,
  V3RoutesProvider,
} from "../Swap/Providers/V3RoutesProvider";
import { V3Pool } from "../Swap/entities/V3Pool";

const ROUTER_PAIRS_TTL = 15 * 1000;

export interface IRouterProviderParams {
  chainProvider: IChainProvider;
  dexNameProvider: ISwapDexNameProvider;
}

export class V3RouterProvider implements IRouterProvider, IQuoterV2AddressProvider {
  private _chainProvider: IChainProvider;

  private _poolsCache: ICache<V3Pool>;

  private _routesProvider: IV3RoutesProvider | null = null;

  private _quotesProvider: IV3QuotesProvider | null = null;

  private _router: IRouter | null = null;

  private _routesProviderReaction: IReactionDisposer;

  private _quotesProviderReaction: IReactionDisposer;

  private _routerCreationReaction: IReactionDisposer;

  private _dexNameProvider: ISwapDexNameProvider;

  constructor({ chainProvider, dexNameProvider }: IRouterProviderParams) {
    makeAutoObservable<this, "_routesProvider" | "_router">(this, {
      _router: observable.ref,
      _routesProvider: observable.ref,
    });

    this._chainProvider = chainProvider;

    this._dexNameProvider = dexNameProvider;

    this._poolsCache = new TTLCache({
      ttl: ROUTER_PAIRS_TTL,
    });

    this._routesProviderReaction = when(
      () => Boolean(this._routesProviderParams),
      () => {
        const providerParams = this._routesProviderParams;
        if (!providerParams) return;
        const routesProvider = new V3RoutesProvider(providerParams);
        this._setRoutesProvider(routesProvider);
      }
    );

    this._quotesProviderReaction = when(
      () => Boolean(this._quotesProviderParams),
      () => {
        const providerParams = this._quotesProviderParams;
        if (!providerParams) return;
        const routersProvider = new V3QuotesProvider(providerParams);
        this._setQuotesProvider(routersProvider);
      }
    );

    this._routerCreationReaction = when(
      () => Boolean(this._routeStateParams),
      () => {
        const routeStateParams = this._routeStateParams;
        if (!routeStateParams) return;
        const routeStateProvider = new V3RouteStateProvider(routeStateParams);

        const router = new Router({ routeStateProvider });

        this._setRouter(router);
      }
    );
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private _setRoutesProvider = (provider: IV3RoutesProvider) => {
    this._routesProvider = provider;
  };

  private _setQuotesProvider = (provider: IV3QuotesProvider) => {
    this._quotesProvider = provider;
  };

  private get _routesProviderParams(): IV3RoutesProviderParams | null {
    const { multicallProvider, chainID } = this._chainProvider;
    const chainId = +chainID;
    if (!multicallProvider || !chainId) return null;

    return {
      provider: multicallProvider,
      chainId,
      poolsCache: this._poolsCache,
    };
  }

  get quoterAddress() {
    const chainId = this._chainId;

    const { dexName } = this._dexNameProvider;

    const quoterAddress = getQuoterV2Address(chainId, dexName);

    return quoterAddress;
  }

  private get _quotesProviderParams(): IV3QuotesProviderParams | null {
    const { provider, chainID } = this._chainProvider;

    const { quoterAddress } = this;

    if (!provider || !chainID || !quoterAddress) return null;

    return {
      provider,
      chainId: chainID,
      quoterAddressProvider: this,
    };
  }

  private get _routeStateParams(): IV3RouteStateParams | null {
    const routesProvider = this._routesProvider;
    const quotesProvider = this._quotesProvider;
    if (!routesProvider || !quotesProvider) return null;
    return {
      routesProvider,
      quotesProvider,
    };
  }

  private _setRouter = (router: IRouter) => {
    this._router = router;
  };

  get router() {
    return this._router;
  }

  destroy() {
    this._poolsCache.clear();
    this._routesProviderReaction();
    this._quotesProviderReaction();
    this._routerCreationReaction();
  }
}
