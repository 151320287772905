import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import Icons from "src/icons/Icons";

export interface InfoButtonProps extends IconButtonProps {}

export const InfoButton = (props: InfoButtonProps) => (
  <IconButton boxed {...props}>
    {Icons.prompt()}
  </IconButton>
);
