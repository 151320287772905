import { makeAutoObservable } from "mobx";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import {
  StatsWalletType,
  WalletTransactionResponse,
  WithdrawNativeRequest,
  WithdrawNativeResponse,
  withdrawNative,
} from "src/api/bots/DEXV2/stats";
import { CloseModalCb } from "src/components/shared/ModalPanel";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Disposable, arrToObj } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { TransactionState, TransactionStatusType, createTransactionStatus } from "src/modules/bots";
import { isEthAddress, required } from "src/validation-schemas";
import { SenderWallet } from ".";
import DefaultFormStore, { FormWarnings, IParentFormStore } from "../../../shared/DefaultFormStore";

export type SetLoading = Dispatch<boolean>;

export interface GasWithdraw extends WithdrawNativeRequest {}

type SendersMap = Partial<Record<string, SenderWallet>>;

interface IWithdrawGasStore extends IParentFormStore<GasWithdraw>, Disposable {}

interface WithdrawSenders
  extends Pick<
    GasWithdraw,
    "deployer_id" | "volume_executors" | "limit_executors" | "counter_executors"
  > {}

const addWalletToSenders = (
  id: string,
  type: StatsWalletType,
  inCurrentSenders: WithdrawSenders
) => {
  const currentSenders = inCurrentSenders;
  if (type === "deployer") {
    currentSenders.deployer_id = id;
    return;
  }
  const executorsType = `${type}_executors` as const;
  // eslint-disable-next-line no-unused-expressions
  currentSenders[executorsType]?.push(id) ?? (currentSenders[executorsType] = [id]);
};

const senderWalletsToWithdrawSenders = (wallets: SenderWallet[]): WithdrawSenders => {
  const senders: WithdrawSenders = {};
  wallets.forEach(({ id, type }) => {
    addWalletToSenders(id, type, senders);
  });
  return senders;
};

const INITIAL_GAS_WITHDRAW: GasWithdraw = {
  receiver: "",
};

export interface WithdrawGasParameters {
  wallets: SenderWallet[];
  withdrawer: string;
  deployerId: string;
  onClose: CloseModalCb;
  setLoading: SetLoading;
}

export type WalletTransaction = {
  type: StatsWalletType;
  address: string;
} & (
  | TransactionStatusType<TransactionState.Error>
  | (TransactionStatusType<TransactionState.Success> & { trx: string })
);

const transactionResponseToWalletTransaction = (
  { hash: trx, message }: WalletTransactionResponse,
  wallet?: SenderWallet
): WalletTransaction | null => {
  if (!wallet) return null;
  const { address: walletAddress, type } = wallet;
  const baseTransaction = { address: walletAddress, type };

  if (message)
    return {
      ...baseTransaction,
      ...createTransactionStatus(TransactionState.Error, { message }),
    };
  return {
    ...baseTransaction,
    ...createTransactionStatus(TransactionState.Success, {}),
    trx,
  };
};

const withdrawNativeResponseToWalletTransactions = (
  { deployer_hash, volume_hash, limit_hash, counter_hash }: WithdrawNativeResponse,
  sendersMap: SendersMap,
  deployerId: string
): WalletTransaction[] => {
  const deployerTrx: Array<WalletTransaction | null> = deployer_hash
    ? [
        transactionResponseToWalletTransaction(
          { ...deployer_hash, id: deployerId },
          sendersMap[deployerId]
        ),
      ]
    : [];

  const volumeTrx: Array<WalletTransaction | null> = volume_hash
    ? volume_hash.map((hash) => transactionResponseToWalletTransaction(hash, sendersMap[hash.id]))
    : [];
  const limitTrx: Array<WalletTransaction | null> = limit_hash
    ? limit_hash.map((hash) => transactionResponseToWalletTransaction(hash, sendersMap[hash.id]))
    : [];
  const counterTrx: Array<WalletTransaction | null> = counter_hash
    ? counter_hash.map((hash) => transactionResponseToWalletTransaction(hash, sendersMap[hash.id]))
    : [];

  return filterBoolean(deployerTrx.concat(volumeTrx, limitTrx, counterTrx));
};

export default class WithdrawGasStore implements IWithdrawGasStore {
  private _data: GasWithdraw = INITIAL_GAS_WITHDRAW;

  private _withdrawer: string;

  private _deployerId: string;

  private _wallets: SenderWallet[];

  validation = {
    receiver: [required(), isEthAddress()],
  };

  formState: DefaultFormStore<GasWithdraw>;

  private _closeModal: CloseModalCb;

  private _setLoading: SetLoading;

  private _isSuccess = false;

  private _transactions: WalletTransaction[] = [];

  constructor({ wallets, onClose, setLoading, withdrawer, deployerId }: WithdrawGasParameters) {
    this._initData(wallets, withdrawer);

    this._withdrawer = withdrawer;
    this._deployerId = deployerId;
    this._wallets = wallets;
    this._closeModal = onClose;
    this._setLoading = setLoading;

    this.formState = new DefaultFormStore(this);

    makeAutoObservable(this);

    makeLoggable<any>(this, { data: true, _warnings: true, _withdrawer: true });
  }

  private _initData = (wallets: SenderWallet[], withdrawer: string) => {
    const senders = senderWalletsToWithdrawSenders(wallets);
    this._data = {
      ...INITIAL_GAS_WITHDRAW,
      ...senders,
      receiver: withdrawer,
    };
  };

  get data() {
    return this._data;
  }

  closeModal = () => {
    this._closeModal(false);
  };

  get isSuccess() {
    return this._isSuccess;
  }

  private _setIsSuccess = (loading: boolean) => {
    this._isSuccess = loading;
  };

  private _setTransactions = (trx: WalletTransaction[]) => {
    this._transactions = trx;
  };

  private get _walletsMap(): SendersMap {
    return arrToObj(this._wallets, ({ id }) => id);
  }

  get transactions() {
    return this._transactions;
  }

  private _warningsValidation = (warnings: FormWarnings<GasWithdraw>) => {
    const withdrawerValid = this.data.receiver === this._withdrawer;
    if (!withdrawerValid) {
      // eslint-disable-next-line no-param-reassign
      warnings.receiver = "receiver address differs from withdrawer!";
    }
    return withdrawerValid;
  };

  validateConstraints = (warnings: FormWarnings<GasWithdraw>) => this._warningsValidation(warnings);

  submit = async () => {
    this._setLoading(true);
    try {
      const { isError, data } = await withdrawNative(this._deployerId, this.data);
      if (!isError) {
        const trx = withdrawNativeResponseToWalletTransactions(
          data,
          this._walletsMap,
          this._deployerId
        );
        this._setTransactions(trx);
        this._setIsSuccess(true);
        toast.success(`Gas withdrawn to ${this.data.receiver} successfully`, {
          autoClose: 2000,
        });
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
