export const separation = (num: string | number | null): string => {
  if (num === null) return "";

  if (+num === 0) return "0";

  if (!num) return "";

  const n = num.toString();

  const replacer = "$1 ";

  const [integer, fractional] = n.split(".");

  const separInt = integer.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, replacer);

  return fractional ? `${separInt}.${fractional}` : separInt;
};

/**
 * method to convert a number to a shortened format
 * @param num - numeric value to be converted
 * @returns - string representation of the rounded value
 */
export const numFormatter = (num: string | number): string => {
  const n = num.toString();

  const [integer, fractional] = n.split(".");

  let convInt: string = "";

  const convReplacer = "$1,";

  const thous = String(parseInt(String(Math.abs(+integer / 1000)), 10));

  if (+thous >= 1) {
    if (thous.length >= 1 && thous.length <= 3) {
      convInt = `${String(Math.round(+n / 10))}K`;
    } else if (String(thous).length > 3 && thous.length <= 6) {
      convInt = `${String(Math.round(+n / 10 ** 4))}M`;
    } else if (String(thous).length > 6 && thous.length <= 9) {
      convInt = `${String(Math.round(+n / 10 ** 7))}B`;
    } else if (String(thous).length > 9) {
      convInt = `${String(Math.round(+n / 10 ** 10))}T`;
    }
  }

  if (convInt) {
    convInt = convInt.replace(/(\d{1,3}(?=(?:\d\d)+(?!\d)))/g, convReplacer);
    return convInt;
  }

  convInt = integer.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, convReplacer);

  return fractional ? `${convInt}.${fractional}` : convInt;
};
