import { ComponentPropsWithoutRef } from "react";
import { ExchangeTokens } from "src/state/CEX/CEXDashboard/KPIStore";
import { Header } from "../shared/KPICard/Header";
import * as styles from "./style";

const TRACKABLE_TOKENS_INFO_TEXT = "These are the tokens we track on accounts on chosen exchanges";

export interface TrackableTokensProps extends ComponentPropsWithoutRef<"div"> {
  items: ExchangeTokens[];
}

export const TrackableTokens = ({ items, ...props }: TrackableTokensProps) => (
  <styles.Container {...props}>
    <Header title="Trackable tokens" info={TRACKABLE_TOKENS_INFO_TEXT} />
    <styles.ExchangesList>
      {items.map((item) => (
        <styles.ExchangesListItem key={item.exchange.name} {...item} />
      ))}
    </styles.ExchangesList>
  </styles.Container>
);
