import styled from "styled-components";
import { StatsTable } from "../../shared/StatsTable";
import { Row, TableCell, TableHeaderCell } from "../../shared/StatsTable/style";

export const BALANCES_HEADER_CLASS_NAME = "balances-header";
export const BALANCES_CELL_CLASS_NAME = "balances-cell";

export const StyledTable = styled(StatsTable)`
  ${TableCell} {
    position: relative;
    height: 50px;
  }

  ${TableHeaderCell} {
    height: 35px;
  }

  & ${Row} {
    ${TableCell} {
      flex-direction: row-reverse;
      justify-content: start;
      max-width: 300px;
    }
  }

  .${BALANCES_HEADER_CLASS_NAME}, .${BALANCES_CELL_CLASS_NAME} {
    padding: 0 8px;
  }
` as typeof StatsTable;
