import { observer } from "mobx-react-lite";
import { SeparatorNum } from "src/components/shared/Separator";
import { msToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { Funding } from "src/modules/shared";
import * as styles from "./style";

interface FundingItemProps {
  item: Funding;
  deleteCurrentFunding: (id: any) => () => void;
}

export const FundingItem = observer(({ item, deleteCurrentFunding }: FundingItemProps) => (
  <styles.Item>
    <styles.Wrapper>
      <styles.Amount amount={item.amount}>
        {item.amount > 0 ? "+" : ""}
        <SeparatorNum value={item.amount} />
      </styles.Amount>
      <styles.Asset amount={item.amount}>{item.currency} </styles.Asset>
    </styles.Wrapper>
    <styles.Wrapper>
      {item.name ? <styles.Account>{item.name}</styles.Account> : null}
      <styles.Time>{unixToDateFormat(msToUnix(item.time), "FullDate")}</styles.Time>
      <styles.Delete onClick={deleteCurrentFunding(item.funding_id)} />
    </styles.Wrapper>
  </styles.Item>
));
