import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import { ConstraintsInput, Wrapper } from "../../shared";
import { WrapperForInputsGroup } from "../shared/style";

interface Props {
  showLoader: boolean;
  party: string;
}

const PAIR_INFO = `Pair:
MinExchangeAmount — minimum Quote amount on selected exchange on selected pair

DontCheckAmount — amount that will be ignored from 3rd party in order book

DontTradePriceMin (DTPMin) — lower range limit for volume creation

DontTradePriceMax (DTPMax) — upper range limit for volume creation`;

export const PairSettings = observer(({ party, showLoader }: Props) => {
  const { SettingsBotState: state } = useAppState();

  return (
    <SettingsPanel
      label="Pair"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("pair")}
      info={PAIR_INFO}
      saved={state.savedModuls.pair}
      style={{ gridArea: "pair" }}
    >
      <Wrapper>
        <WrapperForInputsGroup>
          <LabeledInput
            type="number"
            label="MinExchangeAmount"
            value={state.data.settings.pair.minExchangeAmount}
            onChange={state.getHandler("settings.pair.minExchangeAmount")}
            // @ts-ignore
            getError={state.getError("settings.pair.minExchangeAmount")}
            errorHint={state.errors.settings?.pair?.minExchangeAmount}
            step="any"
          />
          <LabeledInput
            type="number"
            label="DontCheckAmount"
            value={state.data.settings.pair.dontCheckAmount}
            onChange={state.getHandler("settings.pair.dontCheckAmount")}
            errorHint={state.errors.settings?.pair?.dontCheckAmount}
            step="any"
          />
        </WrapperForInputsGroup>
        <WrapperForInputsGroup>
          <ConstraintsInput
            type="number"
            label="DontTradePriceMax"
            value={state.data.settings.pair.dontTradePriceMax}
            onChange={state.getHandler("settings.pair.dontTradePriceMax")}
            // @ts-ignore
            getError={state.getError("settings.pair.dontTradePriceMax")}
            {...state.getFieldErrorAsProps("settings.pair.dontTradePriceMax")}
            step="any"
          />
          <ConstraintsInput
            type="number"
            label="DontTradePriceMin"
            value={state.data.settings.pair.dontTradePriceMin}
            onChange={state.getHandler("settings.pair.dontTradePriceMin")}
            // @ts-ignore
            getError={state.getError("settings.pair.dontTradePriceMin")}
            {...state.getFieldErrorAsProps("settings.pair.dontTradePriceMin")}
            step="any"
          />
        </WrapperForInputsGroup>
      </Wrapper>
    </SettingsPanel>
  );
});
