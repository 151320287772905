import { saveAs } from "file-saver";
import { IReactionDisposer, makeAutoObservable, observable, reaction } from "mobx";
import { toCSV } from "src/helpers/csv";
import { logError } from "src/helpers/network/logger";
import { Disposable } from "src/helpers/utils";

export interface IHashesDownloadStoreProvider {
  getItems: (itemsCount: number) => Promise<string[] | undefined>;

  get columName(): string;

  get fileName(): string;
}

const EMPTY_CSV_HASHES = { data: "" };

export default class HashesDownloadStore implements Disposable {
  private _hashesCSV = EMPTY_CSV_HASHES;

  private _loading = false;

  private _provider: IHashesDownloadStoreProvider;

  private _saveHashesReaction: IReactionDisposer;

  constructor(provider: IHashesDownloadStoreProvider) {
    makeAutoObservable<this, "_hashesCSV">(this, {
      _hashesCSV: observable.ref,
    });

    this._provider = provider;

    this._saveHashesReaction = reaction(
      () => this._hashesCSV,
      (hashesCSV) => {
        const csv = hashesCSV.data;
        if (!csv) return;
        const blob = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        const fileName = `${this._provider.fileName}_hashes`;
        saveAs(blob, `${fileName}.csv`);
        this._clearHashesCSV();
      }
    );
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _setHashesCSV = (hashes: string) => {
    this._hashesCSV = { data: hashes };
  };

  private _clearHashesCSV = () => {
    this._hashesCSV = EMPTY_CSV_HASHES;
  };

  downloadHashes = async (itemsCount: number) => {
    this._setLoading(true);
    try {
      const items = await this._provider.getItems(itemsCount);
      if (items && items.length) {
        const hashesCSV = toCSV([items], [this._provider.columName], "Columns");
        this._setHashesCSV(hashesCSV);
      } else {
        this._clearHashesCSV();
      }
    } catch (err) {
      logError(err);
      this._clearHashesCSV();
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._saveHashesReaction();
  };
}
