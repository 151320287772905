import styled from "styled-components";
import { StatsTable } from "../../shared/StatsTable";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 100px;
`;

export const CurrencyFeeTable = styled(StatsTable)`` as typeof StatsTable;
