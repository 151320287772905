import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { BotModules } from "src/components/BotsContent/CEX/BotModules";
import { CEXApiKeys } from "src/components/BotsContent/CEX/CEXApiKeys";
import { CEXBotSettings } from "src/components/BotsContent/CEX/CEXBotSettings";
import { Exchange } from "src/components/BotsContent/CEX/Exchange";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { Loader } from "src/components/shared/Loader";
import { ApiKeysProvider } from "src/context/CEX/ApiKeys/ApiKeys";
import { BotModulesProvider } from "src/context/CEX/BotModules/BotModulesProvider";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { ExpertSystemProvider } from "src/context/CEX/ExpertSystem";
import { FundingProvider } from "src/context/CEX/FundingProvider";
import { useQueryParams } from "src/hooks/useQueryParams";
import { ContentPermission } from "src/permissions/ContentPermission";
import useAppState from "src/state";
import WindowConsent from "src/state/WindowConsent";
import { Content } from "../shared";
import { Analytics } from "./Analytics";
import { BotInfoCEX } from "./BotInfoCEX";
import { BotInfoCEXFallback } from "./BotInfoCEX/style";
import { BotSettingsFormFallback } from "./CEXBotSettings/style";
import { CEXFunding } from "./CEXFunding";
import { Dashboard } from "./Dashboard";
import { ExpertSystem } from "./ExpertSystem";
import { LiquidityTab } from "./LiquidityTab";
import { MultiGrid } from "./MultiGrid";
import { Stats } from "./Stats";
import * as styles from "./style";

const useBotParamsStatus = (botInfoProps: Partial<BotInfo>) => {
  const botInfoReady = useMemo(() => {
    const { party, bot_uuid, market } = botInfoProps;
    return Boolean(party && bot_uuid && market);
  }, [botInfoProps]);

  const botInfoLoading = !botInfoReady;

  return { botInfoLoading, botInfoReady };
};

export interface CEXQueryParams {
  path: string;
}

export type BotInfo = {
  party: string;
  market: string;
  bot_uuid: string;
};

export interface BotInfoProps {
  botInfo: Partial<BotInfo>;
}

export const CEXContent = observer(() => {
  const { CEXBotState } = useAppState();

  const { path: bot_uuid } = useParams<CEXQueryParams>();

  // fixing a bug with duplicate requests
  useState(() => {
    runInAction(() => {
      CEXBotState.resetBot();
    });
    return null;
  });

  const botInfoProps: BotInfo = useMemo(
    () => ({
      party: CEXBotState.party,
      market: CEXBotState.market,
      bot_uuid: CEXBotState.uuid,
    }),
    [CEXBotState.party, CEXBotState.market, CEXBotState.uuid]
  );

  const { botInfoLoading, botInfoReady } = useBotParamsStatus(botInfoProps);

  const permissionProps = useMemo(
    () => ({
      party: CEXBotState.party,
      loader: CEXBotState.loading,
    }),
    [CEXBotState.party, CEXBotState.loading]
  );

  const queryParams = useQueryParams();
  const currentTab = queryParams.get("tab");

  const stopModal = () => {
    WindowConsent.showWindow("Are you sure?", "This action will stop the bot.", CEXBotState.stop);
  };

  const startModal = () => {
    WindowConsent.showWindow("Are you sure?", "This action will start the bot.", CEXBotState.start);
  };

  useEffect(() => {
    if (!bot_uuid) return;
    CEXBotState.setBotUuid(bot_uuid);
    CEXBotState.loadBot();
  }, [CEXBotState, bot_uuid]);

  const getContent = useCallback(() => {
    switch (currentTab) {
      case "STATS": {
        return (
          <ContentPermission abilityName="cex-stats-view" {...permissionProps}>
            <ErrorBoundary>
              <Stats botInfo={botInfoProps} />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "DASHBOARD": {
        return (
          <ContentPermission abilityName="cex-stats-view" {...permissionProps}>
            <ErrorBoundary>
              <DashboardContext.Provider>
                <Dashboard botInfo={botInfoProps} />
              </DashboardContext.Provider>
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "SETTINGS": {
        return (
          <ContentPermission abilityName="cex-settings-view" {...permissionProps}>
            <ErrorBoundary fallback={<BotSettingsFormFallback />}>
              <CEXBotSettings botInfo={botInfoProps} />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "GRID": {
        return (
          <ContentPermission abilityName="cex-grid-view" {...permissionProps}>
            <ErrorBoundary>
              <LiquidityTab botInfo={botInfoProps} abilityName="cex-grid-edit" />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "MULTIGRID": {
        return (
          <ContentPermission abilityName="cex-grid-view" {...permissionProps}>
            <ErrorBoundary>
              <MultiGrid botInfo={botInfoProps} abilityName="cex-grid-edit" />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "MODULES": {
        return (
          <ContentPermission abilityName="cex-modules-view" {...permissionProps}>
            <ErrorBoundary>
              <BotModulesProvider>
                <BotModules botInfo={botInfoProps} abilityName="cex-modules-edit" />
              </BotModulesProvider>
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "APIKEYS": {
        return (
          <ContentPermission abilityName="cex-api-view" {...permissionProps}>
            <ApiKeysProvider>
              <ErrorBoundary>
                <CEXApiKeys botInfo={botInfoProps} />
              </ErrorBoundary>
            </ApiKeysProvider>
          </ContentPermission>
        );
      }
      case "EXCHANGE": {
        return (
          <ContentPermission abilityName="cex-exchange-view" {...permissionProps}>
            <ErrorBoundary>
              <Exchange botInfo={botInfoProps} abilityName="cex-exchange-trade" />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "ANALYTICS": {
        return (
          <ContentPermission abilityName="cex-candle-analytics-view" {...permissionProps}>
            <ErrorBoundary>
              <Analytics botInfo={botInfoProps} />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "FUNDING": {
        return (
          <ContentPermission abilityName="cex-funding-view" {...permissionProps}>
            <ErrorBoundary>
              <FundingProvider>
                <CEXFunding botInfo={botInfoProps} abilityName="cex-funding-edit" />
              </FundingProvider>
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "STRATEGIES": {
        return (
          <ContentPermission abilityName="cex-expert-system-view" {...permissionProps}>
            <ExpertSystemProvider>
              <ErrorBoundary>
                <ExpertSystem party={botInfoProps.party} abilityName="cex-expert-system-edit" />
              </ErrorBoundary>
            </ExpertSystemProvider>
          </ContentPermission>
        );
      }
      default: {
        return <Redirect to={`/CEX/${bot_uuid}/?tab=STATS`} />;
      }
    }
  }, [botInfoProps, bot_uuid, currentTab, permissionProps]);

  const content = botInfoReady && getContent();

  return (
    <Content>
      {currentTab === "SETTINGS" ? (
        <ErrorBoundary fallback={<BotInfoCEXFallback />}>
          <styles.Wrapper>
            {" "}
            <BotInfoCEX botInfo={botInfoProps} actions={{ start: startModal, stop: stopModal }} />
          </styles.Wrapper>
        </ErrorBoundary>
      ) : null}

      {content}
      <Loader show={botInfoLoading} />
    </Content>
  );
});
