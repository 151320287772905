import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import useMediaQuery from "src/hooks/useMediaQuery";
import { mobileMediaQuery } from "../style";
import { BaseInfo } from "./BaseInfo";
import { ReceiversInfo } from "./ReceiversInfo";
import * as styles from "./style";

export interface InfoProps extends ComponentPropsWithoutRef<"div"> {}

export const Info = observer((props: InfoProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery("700px"));
  const isTablet = useMediaQuery(mobileMediaQuery());
  const fixedHashesHeight = isTablet && !isMobile;
  return (
    <styles.Container {...props}>
      <BaseInfo />
      <ReceiversInfo fixedHashesHeight={fixedHashesHeight} />
    </styles.Container>
  );
});
