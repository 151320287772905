import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { AccountBalancesContext } from "src/context/CEX/Dashboard/AccountBalances";
import { DashboardFundingContext } from "src/context/CEX/Dashboard/Funding";
import { DashboardPairsContext } from "src/context/CEX/Dashboard/Pairs";
import { AccountBalancesTable } from "../../AccountBalancesTable";
import { ArbitrageChart } from "../../ArbitrageChart";
import { BalancesDoughnut, BalancesDoughnutProps } from "../../BalancesDoughnut";
import { FundingTable } from "../../FundingTable";
import { LiquidityCharts } from "../../LiquidityCharts";
import { LiquidityDoughnut, LiquidityDoughnutProps } from "../../LiquidityDoughnut";
import { PairsTable } from "../../PairsTable";
import { SpreadChart } from "../../SpreadChart";
import { Card } from "../../shared/Card";
import * as styles from "./style";

const BASE_DOUGHNUT_PROPS: Omit<BalancesDoughnutProps["data"]["datasets"][0], "data"> = {
  backgroundColor: ["#00796B", "#78909C"],
  hoverOffset: 15,
  borderWidth: 0,
};

const BALANCES_DATA: BalancesDoughnutProps["data"] = {
  labels: ["Free", "Locked"],
  datasets: [
    {
      data: [80, 20],
      ...BASE_DOUGHNUT_PROPS,
    },
  ],
};

const LIQUIDITY_DATA: LiquidityDoughnutProps["data"] = {
  labels: ["USDC  ", "USDT"],
  datasets: [
    {
      data: [95, 5],
      ...BASE_DOUGHNUT_PROPS,
    },
  ],
};

type CurrencyFee = {
  currency: string;
  amount: string;
  amountUsd: string;
};

const CURRENCY_DATA: CurrencyFee[] = [
  { currency: "CTI", amount: "21.2", amountUsd: "0.342" },
  { currency: "ETH", amount: "2.78", amountUsd: "0.0140" },
  { currency: "GT", amount: "21.2", amountUsd: "11.7" },
  { currency: "USDT", amount: "0.0361", amountUsd: "0.0361" },
  { currency: "CTI", amount: "21.2", amountUsd: "0.342" },
  { currency: "ETH", amount: "2.78", amountUsd: "0.0140" },
  { currency: "GT", amount: "21.2", amountUsd: "11.7" },
  { currency: "USDT", amount: "0.0361", amountUsd: "0.0361" },
  { currency: "CTI", amount: "21.2", amountUsd: "0.342" },
  { currency: "ETH", amount: "2.78", amountUsd: "0.0140" },
  { currency: "GT", amount: "21.2", amountUsd: "11.7" },
  { currency: "USDT", amount: "0.0361", amountUsd: "0.0361" },
];

export interface DetailedViewProps {}

export const DetailedView = observer((props: DetailedViewProps) => {
  const currencyColumns = useMemo(() => {
    const columnsSchema: Column<CurrencyFee>[] = [
      {
        Header: "Currency",
        accessor: "currency",
        Footer: "Total",
        width: 20,
      },
      {
        Header: "Amount",
        accessor: "amount",
        sortType: "number",
        width: 40,
      },
      {
        Header: "Amount $",
        accessor: "amountUsd",
        sortType: "number",
        Footer: "123",
        width: 40,
      },
    ];

    return columnsSchema;
  }, []);

  return (
    <styles.Container>
      <BalancesDoughnut data={BALANCES_DATA} style={{ width: "150px", height: "120px" }} />
      <LiquidityDoughnut data={LIQUIDITY_DATA} style={{ width: "150px", height: "120px" }} />
      <Card title="Fee by Currency" style={{ width: "400px", height: "200px" }}>
        <styles.CurrencyFeeTable columns={currencyColumns} data={CURRENCY_DATA} />
      </Card>
      <LiquidityCharts />
      <AccountBalancesContext.Provider>
        <AccountBalancesTable style={{ width: "100%", height: "250px" }} />
      </AccountBalancesContext.Provider>
      <styles.Spacer />
      <DashboardFundingContext.Provider>
        <FundingTable style={{ maxWidth: "600px", minWidth: "auto", height: "250px" }} />
      </DashboardFundingContext.Provider>
      <styles.Spacer />
      <DashboardPairsContext.Provider>
        <PairsTable style={{ width: "100%", height: "300px" }} />
      </DashboardPairsContext.Provider>
      <styles.Spacer />
      <SpreadChart style={{ maxWidth: "500px", minWidth: "auto", height: "220px" }} />
      <styles.Spacer />
      <ArbitrageChart style={{ maxWidth: "600px", minWidth: "auto", height: "250px" }} />
    </styles.Container>
  );
});
