import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import { toast } from "react-toastify";
import {
  AccTerminalProps,
  LimitOrderResponse,
  OpenOrderTerminalProps,
  cancelOrder,
  fetchFeeRations,
  openStopOrder,
} from "src/api/bots/CEX/exchange";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { BalanceComparisonProps, balanceComparison, getAccBalances } from "src/helpers/trading";
import { Order } from "src/modules/exchange/orderBook";
import {
  LIMIT_ORDER_TYPE,
  LimitOrder,
  LimitOrderType,
  OrderSideType,
  StopOrder,
} from "src/modules/exchange/trade";
import { ApiResponse } from "src/modules/network";
import { Errors, SelectorValue } from "src/modules/shared";
import windowConsent from "src/state/WindowConsent";
import { ApiResponseError } from "src/state/network/ResponseHandler";
import { isNumber, required, validateData } from "src/validation-schemas";
import ExchangeStore from "../../..";

export type OrderType = "buyOrder" | "sellOrder";

interface FeeRations {
  maker: number | "";
  native: string;
  taker: number | "";
}

type OpenOrderCb = ({
  account_uuid,
  pair,
  amount,
  price,
}: OpenOrderTerminalProps) => Promise<ApiResponse<LimitOrderResponse, ApiResponseError>>;

interface OpenOrderRequestProps {
  orderType: OrderType;
  openOrder: OpenOrderCb;
  pair: string;
  pricePrecision: number;
  amountPrecision: number;
}

class BuySellStore {
  buyOrder: LimitOrder = {
    orderType: "GTC",
    price: "",
    amount: "",
    totalAsset: "",
  };

  sellOrder: LimitOrder = {
    orderType: "GTC",
    price: "",
    amount: "",
    totalAsset: "",
  };

  stopBuyOrder: StopOrder = {
    pair: "",
    name: "",
    side: "",
    price: "",
    triggerPrice: "",
    triggerCompare: "",
    amount: "",
    expireIn: 2592000,
  };

  stopSellOrder: StopOrder = {
    pair: "",
    name: "",
    side: "",
    price: "",
    triggerPrice: "",
    triggerCompare: "",
    amount: "",
    expireIn: 2592000,
  };

  stopSellTotal = "";

  stopBuyTotal = "";

  validationBuyOrder = {
    price: [required(), isNumber()],
    amount: [required(), isNumber()],
  };

  validationSellOrder = {
    price: [required(), isNumber()],
    amount: [required(), isNumber()],
  };

  validStopSellOrder = {
    price: [required(), isNumber()],
    triggerPrice: [required(), isNumber()],
    triggerCompare: [required()],
    amount: [required(), isNumber()],
    expireIn: [required(), isNumber()],
  };

  validStopBuyOrder = {
    price: [required(), isNumber()],
    triggerPrice: [required(), isNumber()],
    triggerCompare: [required()],
    amount: [required(), isNumber()],
    expireIn: [required(), isNumber()],
  };

  buyOrderErrors: Errors = {};

  sellOrderErrors: Errors = {};

  stopBuyOrderErrors: any = {};

  stopSellOrderErrors: any = {};

  setCurrentKey: Function = () => {};

  buyLoader: boolean = false;

  sellLoader: boolean = false;

  mainState: ExchangeStore;

  feeRations: FeeRations = {
    maker: "",
    native: "",
    taker: "",
  };

  feesSupported = false;

  feesTooltipMsg = "Fail to get fee information";

  constructor(state: ExchangeStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updFees", this.downloadData);

    makeAutoObservable(this);
  }

  get makerFee() {
    return this.feeRations.maker;
  }

  get takerFee() {
    return this.feeRations.taker;
  }

  get native() {
    return this.feeRations.native;
  }

  get buyOrderTotal() {
    if (!this.buyOrder.amount || !this.buyOrder.price) return "";

    let feelOrder = parseFloat(this.buyOrder.amount);
    let calcTotal = 0;
    const orders = this.mainState.orderBookState.sellOrders;

    for (let i = orders.length - 1; i > 0; i -= 1) {
      if (feelOrder <= 0) break;
      if (parseFloat(this.buyOrder.price) >= parseFloat(orders[i].price)) {
        if (feelOrder <= parseFloat(orders[i].amount)) {
          calcTotal += parseFloat(orders[i].price) * feelOrder;
        } else if (feelOrder > parseFloat(orders[i].amount)) {
          calcTotal += parseFloat(orders[i].price) * parseFloat(orders[i].amount);
        }

        feelOrder -= parseFloat(orders[i].amount);
      } else break;
    }

    if (feelOrder > 0) {
      calcTotal += feelOrder * parseFloat(this.buyOrder.price);
    }

    return toRounding(calcTotal, calcRoundingValue(calcTotal));
  }

  get sellOrderTotal() {
    if (!this.sellOrder.amount || !this.sellOrder.price) return "";

    let feelOrder = parseFloat(this.sellOrder.amount);
    let calcTotal = 0;

    for (const order of this.mainState.orderBookState.buyOrders) {
      if (feelOrder <= 0) break;
      if (parseFloat(this.sellOrder.price) <= parseFloat(order.price)) {
        if (feelOrder <= parseFloat(order.amount)) {
          calcTotal += parseFloat(order.price) * feelOrder;
        } else if (feelOrder > parseFloat(order.amount)) {
          calcTotal += parseFloat(order.price) * parseFloat(order.amount);
        }

        feelOrder -= parseFloat(order.amount);
      } else break;
    }

    if (feelOrder > 0) {
      calcTotal += feelOrder * parseFloat(this.sellOrder.price);
    }

    return toRounding(calcTotal, calcRoundingValue(calcTotal));
  }

  get avgPriceBuyOrder() {
    const avgPrice = parseFloat(this.buyOrderTotal) / parseFloat(this.buyOrder.amount);

    if (isNaN(avgPrice)) return "";

    return toRounding(avgPrice, calcRoundingValue(avgPrice));
  }

  get avgPriceSellOrder() {
    const avgPrice = parseFloat(this.sellOrderTotal) / parseFloat(this.sellOrder.amount);

    return toRounding(avgPrice, calcRoundingValue(avgPrice));
  }

  get limitOrderTypeList() {
    return LIMIT_ORDER_TYPE.map((el) => ({
      value: el,
      label: el,
    }));
  }

  getAvgPrice = (type: OrderType) => {
    switch (type) {
      case "buyOrder":
        return this.avgPriceBuyOrder;

      case "sellOrder":
        return this.avgPriceSellOrder;

      default:
        return "";
    }
  };

  clearForms = () => {
    this.buyOrderErrors = {};
    this.sellOrderErrors = {};
    this.stopBuyOrderErrors = {};
    this.stopSellOrderErrors = {};

    this.buyOrder = {
      orderType: "GTC",
      price: "",
      amount: "",
      totalAsset: "",
    };

    this.sellOrder = {
      orderType: "GTC",
      price: "",
      amount: "",
      totalAsset: "",
    };

    this.stopBuyOrder = {
      pair: "",
      name: "",
      side: "",
      price: "",
      triggerPrice: "",
      triggerCompare: "",
      amount: "",
      expireIn: 2592000,
    };

    this.stopSellOrder = {
      pair: "",
      name: "",
      side: "",
      price: "",
      triggerPrice: "",
      triggerCompare: "",
      amount: "",
      expireIn: 2592000,
    };

    this.stopSellTotal = "";

    this.stopBuyTotal = "";
  };

  setValue = (value: string, field: OrderType, key: keyof Omit<LimitOrder, "orderType">) => {
    this[field][key] = value;
  };

  setOrderType = (data: SelectorValue, field: OrderType) => {
    this[field].orderType = data?.value as LimitOrderType;
  };

  setFuncCurrentKey = (setFunc: Function) => {
    this.setCurrentKey = setFunc;
  };

  setLoading = (bool: boolean, type: "buyLoader" | "sellLoader") => {
    this[type] = bool;
  };

  setOrder = (orderType: OrderType, { price, amount }: Order) => {
    if (this.setCurrentKey) {
      if (orderType === "buyOrder") {
        this.setCurrentKey("BUY");
      } else this.setCurrentKey("SELL");
    }

    this[orderType].price = price;
    this[orderType].amount = amount;

    const total = +price * +amount;

    this[orderType].totalAsset = toRounding(total, calcRoundingValue(total));
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => e.target.value;

  getError = (orderType: OrderType, key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() =>
      orderType === "buyOrder"
        ? getValueByPath(this.buyOrderErrors, path, endKey, undefined)
        : getValueByPath(this.sellOrderErrors, path, endKey, undefined)
    );
    return result;
  };

  priceHandler = (field: OrderType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(this.getChangeEventValue(e), field, "price");
    this.updateTotalAsset(field);
  };

  amountHandler = (field: OrderType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(this.getChangeEventValue(e), field, "amount");
    this.updateTotalAsset(field);
  };

  totalAssetHandler = (field: OrderType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(this.getChangeEventValue(e), field, "totalAsset");
    this.updateAmount(field);
  };

  stopSellTotalHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.stopSellTotal = this.getChangeEventValue(e);
    });

    const newAmount = parseFloat(this.stopSellTotal) / parseFloat(this.stopSellOrder.price);

    const amountPrecision = calcRoundingValue(newAmount);

    this.setStopValue(
      "stopSellOrder",
      "amount",
      isNaN(newAmount) ? "" : toRounding(newAmount, amountPrecision)
    );
  };

  stopBuyTotalHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.stopBuyTotal = this.getChangeEventValue(e);
    });

    const newAmount = parseFloat(this.stopBuyTotal) / parseFloat(this.stopBuyOrder.price);

    const amountPrecision = calcRoundingValue(newAmount);

    this.setStopValue(
      "stopBuyOrder",
      "amount",
      isNaN(newAmount) ? "" : toRounding(newAmount, amountPrecision)
    );
  };

  updateStopTotal = (orderType: "stopBuyOrder" | "stopSellOrder") => {
    const total = parseFloat(this[orderType].price) * parseFloat(this[orderType].amount);

    this[orderType === "stopBuyOrder" ? "stopBuyTotal" : "stopSellTotal"] = isNaN(total)
      ? ""
      : String(total);
  };

  selectorHandler = (field: OrderType) => (data: SelectorValue | null) => {
    if (data) this.setOrderType(data, field);
  };

  updateAmount = (field: OrderType) => {
    this[field].amount = String(+this[field].totalAsset / +this[field].price);
  };

  updateTotalAsset = (field: OrderType) => {
    this[field].totalAsset = String(+this[field].price * +this[field].amount);
  };

  setStopValue = <K extends keyof StopOrder>(
    orderType: "stopBuyOrder" | "stopSellOrder",
    field: K,
    value: StopOrder[K]
  ) => {
    this[orderType][field] = value;
  };

  getTriggerCompareHandler =
    (orderType: "stopBuyOrder" | "stopSellOrder") => (value: "GREATER" | "LESS" | "") => {
      this[orderType].triggerCompare = value;
    };

  getTriggerCompareValue = (orderType: "stopBuyOrder" | "stopSellOrder") =>
    this[orderType].triggerCompare;

  getHandler =
    (orderType: "stopBuyOrder" | "stopSellOrder", field: keyof StopOrder) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setStopValue(orderType, field, this.getChangeEventValue(e));
      if (field === "amount" || field === "price") {
        this.updateStopTotal(orderType);
      }
    };

  validSelectHandler =
    (orderType: "stopBuyOrder" | "stopSellOrder") => (data: SelectorValue | null) => {
      if (data)
        runInAction(() => {
          this[orderType].expireIn = parseFloat(String(data.value));
        });
    };

  validate = (orderType: OrderType, validateKeys: string[] | undefined) => {
    if (orderType === "buyOrder") {
      return validateData(
        this.validationBuyOrder,
        this[orderType],
        this.buyOrderErrors,
        validateKeys
      );
    }
    return validateData(
      this.validationSellOrder,
      this[orderType],
      this.sellOrderErrors,
      validateKeys
    );
  };

  submitHandler =
    (
      side: OrderSideType,
      orderType: OrderType,
      openOrder: OpenOrderCb,
      botName: string,
      pair: string,
      account: string,
      pricePrecision: number,
      amountPrecision: number
    ) =>
    async (e: React.FormEvent) => {
      e.preventDefault();

      const valid = this.validate(orderType, undefined);

      if (valid) {
        if (orderType === "buyOrder") {
          this.setLoading(true, "buyLoader");
        } else this.setLoading(true, "sellLoader");

        const [quote, base] = pair.split("_");

        try {
          const balances = await getAccBalances({
            account_uuid: this.mainState.currentAccID,
            account_name: account,
            quoteTicker: quote,
            baseTicker: base,
          });

          if (balances) {
            let balanceComparisonProps: BalanceComparisonProps | null = null;

            if (orderType === "buyOrder") {
              balanceComparisonProps = {
                balance: balances.quoteBalance,
                account_name: account,
                ticker: quote,
                amount: +this[orderType].totalAsset,
                typeOperation: side,
              };
            } else {
              balanceComparisonProps = {
                balance: balances.baseBalance,
                account_name: account,
                ticker: base,
                amount: +this[orderType].amount,
                typeOperation: side,
              };
            }
            const checkBalance = balanceComparison(balanceComparisonProps);

            const requestProps = { orderType, openOrder, pair, pricePrecision, amountPrecision };
            const textMessage = `Are you sure you want to open a ${side} order from ${account.toUpperCase()}?`;
            const subTextMessage = `Insufficient funds in the account.\n 
            You need ${balanceComparisonProps.amount} ${balanceComparisonProps.ticker}`;

            if (checkBalance) {
              await this.openOrderRequest(requestProps);
            } else {
              windowConsent.showWindow(
                textMessage,
                subTextMessage,
                this.openOrderRequest,
                requestProps
              );
            }
          }
        } catch (error) {
          logError(error);
        } finally {
          this.setLoading(false, "sellLoader");
          this.setLoading(false, "buyLoader");
        }
      }
    };

  openOrderRequest = async ({
    orderType,
    openOrder,
    pair,
    pricePrecision,
    amountPrecision,
  }: OpenOrderRequestProps) => {
    // check of rounded order parameters
    const roundedPrice = toRounding(+this[orderType].price, pricePrecision);

    const roundedAmount = toRounding(+this[orderType].amount, amountPrecision);

    if (!parseFloat(roundedPrice) || !parseFloat(roundedAmount)) {
      if (!parseFloat(roundedPrice)) {
        toast.error("Order price has been rounded down to 0");
      }

      if (!parseFloat(roundedAmount)) {
        toast.error("Order amount has been rounded down to 0");
      }

      return;
    }

    const accTerminalProps: AccTerminalProps = { account_uuid: this.mainState.currentAccID, pair };

    try {
      const { isError, data } = await openOrder({
        ...accTerminalProps,
        price: roundedPrice,
        amount: roundedAmount,
      });

      if (!isError) {
        showSuccessMsg("Application successfully processed");

        if (this[orderType].orderType === "FOK") {
          const orderId = data.data;

          await cancelOrder({ ...accTerminalProps, order_id: orderId });
        }

        this.mainState.updLimitTradingData();
      }
    } catch (error) {
      logError(error);
    }
  };

  getStopOrderError = (orderType: "stopBuyOrder" | "stopSellOrder", key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() =>
      orderType === "stopBuyOrder"
        ? getValueByPath(this.stopBuyOrderErrors, path, endKey, undefined)
        : getValueByPath(this.stopSellOrderErrors, path, endKey, undefined)
    );
    return result;
  };

  stopOrderValidate = (orderType: "stopBuyOrder" | "stopSellOrder") => {
    if (orderType === "stopBuyOrder") {
      return validateData(
        this.validStopBuyOrder,
        this[orderType],
        this.stopBuyOrderErrors,
        undefined
      );
    }
    return validateData(
      this.validStopSellOrder,
      this[orderType],
      this.stopSellOrderErrors,
      undefined
    );
  };

  submitStopOrder = (orderType: "stopBuyOrder" | "stopSellOrder") => async (e: React.FormEvent) => {
    e.preventDefault();

    this[orderType].side = orderType === "stopBuyOrder" ? "BUY" : "SELL";

    this[orderType].pair = this.mainState.pair;
    this[orderType].name = this.mainState.selectedAccount;

    const valid = this.stopOrderValidate(orderType);

    if (valid) {
      this.setLoading(true, orderType === "stopBuyOrder" ? "buyLoader" : "sellLoader");

      try {
        const { isError } = await openStopOrder(this.mainState.currentAccID, this[orderType]);

        if (!isError) {
          showSuccessMsg("Application successfully processed");

          this.mainState.updStopTradingData();
        }
      } finally {
        this.setLoading(false, orderType === "stopBuyOrder" ? "buyLoader" : "sellLoader");
      }
    }
  };

  findQuoteBase = (arr: any, value: any, field: any) => arr.find((el: any) => el[field] === value);

  downloadData = () => {
    this.getFees();
  };

  private getFees = async () => {
    try {
      const { data, isError } = await fetchFeeRations(
        this.mainState.currentAccID,
        this.mainState.pair
      );

      if (!isError) {
        runInAction(() => {
          this.feeRations = data.data;
        });

        runInAction(() => {
          this.feesSupported = true;
        });
      } else {
        runInAction(() => {
          this.feesSupported = false;
        });

        const notSupp = data.includes("not supported");

        if (notSupp) {
          runInAction(() => {
            this.feesTooltipMsg = "Fees information is not supported";
          });
        }
      }
    } catch (error) {
      logError(error);
    }
  };
}

export default BuySellStore;
