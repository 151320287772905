import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { DashboardContext } from "src/context/CEX/Dashboard";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AUMCard } from "../KPI/AUMCard";
import { BalancesCard } from "../KPI/BalancesCard";
import { ImpactCard } from "../KPI/ImpactCard";
import { PNLCard } from "../KPI/PNL/PNLCard";
import { usePNLInfoData } from "../ProfitLossChart/ProfitLossInfoPanel";
import { TrackableTokens } from "../TrackableTokens";
import { useStatsStore } from "../hooks/useStatsStore";
import * as styles from "./style";

const ALL_TIME_PNL_INFO_TEXT = "This is PnL for the selected period";

const CURRENT_PNL_INFO_TEXT = "This is PnL for the current month";

export interface KPIPanelProps extends ComponentPropsWithoutRef<"div"> {}

export const KPIPanel = observer((props: KPIPanelProps) => {
  const { aum, exchanges, balances, exchangesTokens, workingSince, indexImpact, loading } =
    useStatsStore("kpi");

  const { party } = useLateInitContext(DashboardContext.Context);

  const { allTime, current } = usePNLInfoData();

  return (
    <styles.KPIContainer {...props}>
      <AUMCard
        kpi={aum}
        exchanges={exchanges}
        loading={loading("aum")}
        style={{ gridArea: styles.KPIAreas.Aum }}
      />
      <BalancesCard
        free={balances.free}
        locked={balances.locked}
        loading={loading("balances")}
        style={{ gridArea: styles.KPIAreas.Balance }}
      />
      <ImpactCard
        impact={indexImpact}
        sinceDate={workingSince}
        exchanges={exchanges}
        loading={loading("index")}
        party={party}
        style={{ gridArea: styles.KPIAreas.Impact }}
      />
      <styles.PNLWrapper style={{ gridArea: styles.KPIAreas.PNL }}>
        <PNLCard info={ALL_TIME_PNL_INFO_TEXT} {...allTime} />
        <PNLCard info={CURRENT_PNL_INFO_TEXT} {...current} />
      </styles.PNLWrapper>
      <TrackableTokens items={exchangesTokens} style={{ gridArea: styles.KPIAreas.Tokens }} />
    </styles.KPIContainer>
  );
});
