import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { PopupPosition } from "reactjs-popup/dist/types";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { useFindWidth } from "src/hooks/useFindWidth";
import { ContentDirection } from "src/modules/shared";
import { PNLPanelProps, PnLPanel } from "./PnLPanel";
import * as styles from "./style";

export interface PnLWithDatePickerProps {
  setRange: (period: DateTimeRange) => void;
  contentDirection?: ContentDirection;
  pnlProps: PNLPanelProps;
  pickerPosition?: PopupPosition | PopupPosition[];
}

export const PnLWithDatePicker = observer(
  ({
    setRange,
    contentDirection: direction = "column",
    pnlProps,
    pickerPosition,
    ...props
  }: PnLWithDatePickerProps) => {
    const showMobile = useFindWidth(880);

    const defaultPosition = useMemo(
      () => (showMobile ? "bottom left" : "bottom right"),
      [showMobile]
    );

    return (
      <styles.Container style={{ flexDirection: direction }} {...props}>
        <DateTimeRangePickerSelector
          value={pnlProps.range}
          onChange={setRange}
          maxDate={getCurrentDayjs()}
          disableFuture
          popupProps={{
            position: pickerPosition || defaultPosition,
          }}
          showUTC
        />

        <PnLPanel {...pnlProps} />
      </styles.Container>
    );
  }
);
