import { BigNumber, ethers } from "ethers";
import { IReactionDisposer, makeAutoObservable, when } from "mobx";
import {
  createChainMissingModalBody,
  createChainMissingModalTitle,
} from "src/components/chain/modals/ChainMissingModal";
import {
  createWrongChainModalBody,
  createWrongChainModalTitle,
} from "src/components/chain/modals/WrongChainModal";
import { ChainId } from "src/config/chains";
import { makeLoggable } from "src/helpers/logger";
import { hexToNum, toHexString } from "src/helpers/math";
import { chainErrorHandler } from "src/helpers/network/chain";
import { LogLevel, logDev, logError } from "src/helpers/network/logger";
import { Disposable, ValueOf } from "src/helpers/utils";
import { MetaMaskEthereumProvider } from "src/hooks/useMetaMaskProvider";
import { Web3Provider } from "zksync-web3";
import WindowConsent from "../WindowConsent";

export interface MetamaskProviderError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

export const isMetamaskProviderError = (err: unknown): err is MetamaskProviderError =>
  typeof (err as any).message === "string" && typeof (err as any).code === "number";

const logNotInitialized = (propName: string) => {
  logDev([`${propName} is read before initialization! make sure to call init() on the store.`], {
    level: LogLevel.Error,
  });
};

export const ChainConnectionState = {
  Initial: "Initial",
  Initialized: "Initialized",
  Connected: "Connected",
} as const;

export type ChainConnectionStateType = ValueOf<typeof ChainConnectionState>;

export interface IWalletConnectionStateProvider {
  get connectionState(): ChainConnectionStateType;
}

export interface IWalletChainProvider {
  get ethersProvider(): ethers.providers.Web3Provider;
}

export interface IWalletAccountProvider {
  get currentAccount(): string;
}

export interface IWalletConnectionProvider extends IWalletChainProvider, IWalletAccountProvider {
  get isInit(): boolean;
}

export interface IChainConnection
  extends IWalletConnectionStateProvider,
    IWalletConnectionProvider {
  get currentBalance(): string;
  get chainID(): number | null;
  get metaMaskProvider(): MetaMaskEthereumProvider;
  init: (metaMaskProvider: MetaMaskEthereumProvider) => void;
  switchChain: (chainId: number | string, retryAction?: () => any) => Promise<boolean>;
  getAccount: () => Promise<void>;
  connectWallet: (chainId: number) => Promise<void>;
  disconnectWallet: () => void;
  getBalance: () => Promise<void>;
}

export default class ChainConnectionStore implements Disposable, IChainConnection {
  private _metaMaskProvider: MetaMaskEthereumProvider | undefined;

  private _ethersProvider: ethers.providers.Web3Provider | undefined;

  private _currentAccount: string = "";

  private _currentBalance: BigNumber = BigNumber.from(0);

  private _chainID: number | null = null;

  private _walletConnected = false;

  private _listenToMetamaskEvents: boolean;

  private _metamaskProviderInitReaction: IReactionDisposer;

  constructor(listenToMetamaskEvents: boolean = true) {
    this._listenToMetamaskEvents = listenToMetamaskEvents;

    makeAutoObservable(this);

    this._metamaskProviderInitReaction = when(
      () => Boolean(this._metaMaskProvider),
      () => {
        this._initWalletConnection();
        if (this._listenToMetamaskEvents) {
          this._subscribeMetamaskEvents();
        }
      }
    );

    makeLoggable(this, { currentAccount: true, chainID: true });
  }

  get currentBalance() {
    return ethers.utils.formatEther(this._currentBalance);
  }

  get currentAccount() {
    if (this._currentAccount) {
      return ethers.utils.getAddress(this._currentAccount);
    }
    return this._currentAccount;
  }

  get metaMaskProvider() {
    if (!this._metaMaskProvider) {
      logNotInitialized("metaMaskProvider");
    }

    return this._metaMaskProvider!;
  }

  get ethersProvider() {
    if (!this._ethersProvider) {
      logNotInitialized("ethersProvider");
    }

    return this._ethersProvider!;
  }

  get chainID() {
    return this._chainID;
  }

  private _setWalletConnected = (connected: boolean) => {
    this._walletConnected = connected;
  };

  get connectionState() {
    if (!this.isInit) {
      return ChainConnectionState.Initial;
    }

    // connected state = account is not empty && internal connection flag
    if (this._walletConnected && this._currentAccount) {
      return ChainConnectionState.Connected;
    }

    return ChainConnectionState.Initialized;
  }

  private _getDefaultProvider = (metaMaskProvider: MetaMaskEthereumProvider) => {
    logDev(["metamask: getDefaultProvider"]);
    return new ethers.providers.Web3Provider(metaMaskProvider, "any");
  };

  private _getZkSyncProvider = (metaMaskProvider: MetaMaskEthereumProvider) => {
    logDev(["metamask: getZkSyncProvider"]);
    return new Web3Provider(metaMaskProvider, "any") as unknown as ethers.providers.Web3Provider;
  };

  private _getEthersProvider = (metaMaskProvider: MetaMaskEthereumProvider, chainId?: number) => {
    if (`${chainId}` === ChainId.zkSync) {
      return this._getZkSyncProvider(metaMaskProvider);
    }
    return this._getDefaultProvider(metaMaskProvider);
  };

  private _updateEthersProvider = (
    metaMaskProvider: MetaMaskEthereumProvider,
    chainId?: number
  ) => {
    const currentChainId = this._ethersProvider?.network?.chainId;
    // update ethers provider only if chainId is different
    if (chainId && chainId !== currentChainId) {
      this._ethersProvider = this._getEthersProvider(metaMaskProvider, chainId);
    }
  };

  private _initEthersProvider = (metaMaskProvider: MetaMaskEthereumProvider) => {
    this._ethersProvider = this._getEthersProvider(metaMaskProvider);
  };

  init = (metaMaskProvider: MetaMaskEthereumProvider) => {
    this._metaMaskProvider = metaMaskProvider;

    this._initEthersProvider(metaMaskProvider);

    this.isInit = true;
  };

  isInit: boolean = false;

  chainChanged = (chainId: string) => {
    logDev(["chain changed", chainId]);
    this._setChainId(chainId);
  };

  accountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts.
      logError("Please install MetaMask!", {
        dev: { log: true },
      });
      this._setAccount("");
    } else {
      logDev(["accounts changed", accounts]);
      this._setAccount(accounts[0]);
    }
  };

  private _subscribeMetamaskEvents = () => {
    if (!this.isInit) return;
    this.metaMaskProvider.on("chainChanged", this.chainChanged);
    this.metaMaskProvider.on("accountsChanged", this.accountsChanged);
  };

  private _unsubscribeMetamaskEvents = () => {
    if (!this.isInit) return;
    this.metaMaskProvider.removeListener("chainChanged", this.chainChanged);
    this.metaMaskProvider.removeListener("accountsChanged", this.accountsChanged);
  };

  private _setChainId = (chainId: string) => {
    this._chainID = hexToNum(chainId);

    this._updateEthersProvider(this.metaMaskProvider, this._chainID);
  };

  private _getChainID = async () => {
    try {
      const chainId = await this.ethersProvider.send("eth_chainId", []);

      if (chainId) {
        this._setChainId(chainId);
        return true;
      }
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  switchChain = async (chainId: number | string, retryAction?: () => any) => {
    const newChainId = typeof chainId === "number" ? toHexString(chainId) : chainId;

    const retryFunc = retryAction || (() => this.switchChain(chainId));

    try {
      await this.ethersProvider.send("wallet_switchEthereumChain", [{ chainId: newChainId }]);

      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (isMetamaskProviderError(error) && error.code === 4902) {
        WindowConsent.showWindow(
          createChainMissingModalTitle(hexToNum(newChainId)),
          createChainMissingModalBody(),
          retryFunc
        );
      } else {
        chainErrorHandler(error);
      }
      return false;
    }
  };

  private _setAccount = (account: string) => {
    this._currentAccount = account;
  };

  private _listAccounts = async () => {
    const addresses = await this.ethersProvider.listAccounts();
    return addresses;
  };

  private _getAccount = async (setWalletConnected?: boolean) => {
    try {
      const accounts = await this.ethersProvider.send("eth_requestAccounts", []);
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        logError("Please connect to MetaMask!", {
          dev: { log: true },
        });
      } else {
        this._setAccount(accounts[0]);
        if (setWalletConnected) {
          this._setWalletConnected(true);
        }
      }
    } catch (err) {
      // Some unexpected error.
      // For backwards compatibility reasons, if no accounts are available,
      // eth_accounts will return an empty array.
      chainErrorHandler(err);
    }
  };

  private _connectAccount = async () => await this._getAccount(true);

  getAccount = async () => await this._getAccount();

  private _initWalletConnection = async () => {
    try {
      const isSuccess = await this._getChainID();
      if (isSuccess) {
        const addresses = await this._listAccounts();
        // if no listed addresses => wallet is not connected to site yet
        if (addresses.length) {
          await this.getAccount();
        }
      }
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  connectWallet = async (chainId: number) => {
    try {
      await this._getChainID();

      if (chainId !== this.chainID) {
        const switchChainAndConnect = async () => {
          const retryConnect = () => this.connectWallet(chainId);
          const isSwitchSuccess = await this.switchChain(chainId, retryConnect);
          if (isSwitchSuccess) {
            await this._connectAccount();
          }
        };

        WindowConsent.showWindow(
          createWrongChainModalTitle(),
          createWrongChainModalBody(chainId, this.chainID),
          switchChainAndConnect
        );
      } else {
        await this._connectAccount();
      }
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  disconnectWallet = () => {
    this._setWalletConnected(false);
  };

  getBalance = async () => {
    try {
      this._currentBalance = await this.ethersProvider.getBalance(this.currentAccount, "latest");
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  destroy = () => {
    this._unsubscribeMetamaskEvents();
    this._metamaskProviderInitReaction();
  };
}
