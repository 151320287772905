import { observer } from "mobx-react-lite";
import React from "react";
import { LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import * as styles from "./style";

export const TradeSettings = observer(() => {
  const { SettingsBotState: state } = useAppState();

  return (
    <styles.Container>
      <LabeledPercentRangeInput
        label="BuyPercent"
        value={state.data.settings.trade.buyPercent}
        onChange={state.getHandler("settings.trade.buyPercent")}
      />
    </styles.Container>
  );
});
