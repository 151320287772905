import { ethers } from "ethers";
import { IReactionDisposer, makeAutoObservable, when } from "mobx";
import { GroupBase } from "react-select";
import { toast } from "react-toastify";
import { DEXV2Vault, DEXV2VaultType } from "src/api/bots/DEXV2/create";
import {
  DEXV2VaultTokenOrder,
  DEXV2VaultTypeOrder,
  TransferPermissionRequest,
  getTransferPermission,
} from "src/api/bots/DEXV2/stats";
import { LabeledSelectorProps } from "src/components/shared/Forms/Selectors";
import { CloseModalCb } from "src/components/shared/ModalPanel";
import { IVaultMainUpgradeable__factory } from "src/contracts/factories/vaults/IVaultMainUpgradeable__factory";
import { IVaultMainUpgradeable } from "src/contracts/vaults/IVaultMainUpgradeable";
import { setData } from "src/helpers/forms/getByKey";
import { FormDataKeys, FormValidation } from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { Disposable, Extends, Mapper } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import DefaultFormStore, { IParentFormStore } from "src/state/shared/DefaultFormStore";
import { required, strictlyGreaterThan } from "src/validation-schemas";
import {
  EMPTY_ORACLE_PERMISSION,
  permissionResponseToOraclePermission,
} from "../../DEXV2Swap/SwapModules/v2/SwapWidget/SwapExecutorStore";
import { OraclePermission } from "../../DEXV2Swap/Version/SwapVaultContract";
import { tryParseCurrencyAmount } from "../../DEXV2Swap/utils";
import TradePair from "../../shared/TradePair";
import { TradeSide } from "../../shared/TradeToken";
import { SetLoading } from "../GasWallets/WithdrawGasStore";
import { IBotWalletConnectionProvider } from "./BotWalletConnectionStore";

interface TokenTransfer extends TransferPermissionRequest {}

interface TokenTransferForm
  extends Omit<TokenTransfer, "from_vault" | "to_vault" | "token" | "amount"> {
  from_vault: DEXV2VaultTypeOrder | "";
  to_vault: DEXV2VaultTypeOrder | "";
  token: DEXV2VaultTokenOrder | "";
  amount: number | "";
}

const INITIAL_TOKEN_TRANSFER: TokenTransferForm = {
  sender: "",
  from_vault: "",
  to_vault: "",
  token: "",
  amount: "",
};
export interface TokenTransferParameters {
  fromVault: DEXV2Vault;
  deployerId: string;
  onClose: CloseModalCb;
  setLoading: SetLoading;
  chainProvider: ethers.providers.Web3Provider;
  sender: string;
  walletConnectionProvider: IBotWalletConnectionProvider;
  tradePair: TradePair;
  withdrawer: string;
}

export const vaultTypeToOrder: Mapper<DEXV2VaultType, DEXV2VaultTypeOrder> = (type) => {
  switch (type) {
    case "main": {
      return DEXV2VaultTypeOrder.Main;
    }
    case "limit": {
      return DEXV2VaultTypeOrder.Limit;
    }
    case "volume": {
      return DEXV2VaultTypeOrder.Volume;
    }
    case "counter": {
      return DEXV2VaultTypeOrder.Counter;
    }
  }
};

export type DEXV2VaultToken = TradeSide;

export const vaultTokenToOrder: Mapper<DEXV2VaultToken, DEXV2VaultTokenOrder> = (
  token: DEXV2VaultToken
) => {
  switch (token) {
    case "base": {
      return DEXV2VaultTokenOrder.Base;
    }
    case "quote": {
      return DEXV2VaultTokenOrder.Quote;
    }
  }
};

const vaultTokenOrderToToken: Mapper<DEXV2VaultTokenOrder, DEXV2VaultToken> = (
  order: DEXV2VaultTokenOrder
) => {
  switch (order) {
    case DEXV2VaultTokenOrder.Base: {
      return "base";
    }
    case DEXV2VaultTokenOrder.Quote: {
      return "quote";
    }
  }
};

export interface VaultTypeOption extends Pick<SelectorValue, "label" | "value"> {
  label: DEXV2VaultType;
  value: DEXV2VaultTypeOrder;
}

const VAULTS_OPTIONS: VaultTypeOption[] = [
  { label: "main", value: DEXV2VaultTypeOrder.Main },
  { label: "limit", value: DEXV2VaultTypeOrder.Limit },
  { label: "volume", value: DEXV2VaultTypeOrder.Volume },
  { label: "counter", value: DEXV2VaultTypeOrder.Counter },
];

export interface VaultTokenOption extends Pick<SelectorValue, "label" | "value"> {
  id: DEXV2VaultToken;
  label: string;
  value: DEXV2VaultTokenOrder;
}

const BASE_TOKENS_OPTIONS: VaultTokenOption[] = [
  { id: "base", label: "base", value: DEXV2VaultTokenOrder.Base },
  { id: "quote", label: "quote", value: DEXV2VaultTokenOrder.Quote },
];

type TokenTransferKeys = FormDataKeys<TokenTransferForm>;

type TokenTransferSelectors = Extends<TokenTransferKeys, "to_vault" | "token">;

export interface SelectorFormProps<Option extends SelectorValue = SelectorValue>
  extends Pick<
    LabeledSelectorProps<Option, false, GroupBase<Option>>,
    "onChange" | "value" | "options" | "errorHint"
  > {}
interface ITransferStore extends IParentFormStore<TokenTransferForm>, Disposable {}
export default class TransferStore implements ITransferStore {
  private _fromVault: DEXV2Vault;

  private _data: TokenTransferForm = INITIAL_TOKEN_TRANSFER;

  validation: FormValidation<TokenTransferForm> = {
    to_vault: required(),
    token: required(),
    amount: [required(), strictlyGreaterThan(0, "The value must be positive")],
  };

  private _closeModal: CloseModalCb;

  private _setLoading: SetLoading;

  private _tradePair: TradePair;

  private _withdrawer: string;

  private _deployerId: string;

  formState: DefaultFormStore<TokenTransferForm>;

  private _chainProvider: ethers.providers.Web3Provider;

  private _walletConnectionProvider: IBotWalletConnectionProvider;

  private _walletDisconnectedReaction: IReactionDisposer;

  constructor({
    fromVault,
    deployerId,
    sender,
    onClose,
    setLoading,
    tradePair,
    withdrawer,
    chainProvider,
    walletConnectionProvider: chainConnectionProvider,
  }: TokenTransferParameters) {
    this._fromVault = fromVault;
    this._closeModal = onClose;
    this._setLoading = setLoading;
    this._tradePair = tradePair;
    this._withdrawer = withdrawer;
    this._deployerId = deployerId;
    this._chainProvider = chainProvider;
    this._walletConnectionProvider = chainConnectionProvider;

    this._initData(this._fromVault, sender);

    this.formState = new DefaultFormStore(this);

    makeAutoObservable(this);

    this._walletDisconnectedReaction = when(
      () => this._walletConnectionProvider.connectionState !== "Connected",
      () => {
        this.closeModal();
      }
    );

    makeLoggable(this, { data: true });
  }

  private _initData = ({ type }: DEXV2Vault, sender: string) => {
    this._data = {
      ...INITIAL_TOKEN_TRANSFER,
      from_vault: vaultTypeToOrder(type),
      sender,
    };
  };

  get data() {
    return this._data;
  }

  closeModal = () => {
    this._closeModal(false);
  };

  private get _tickers() {
    return this._tradePair.tickers;
  }

  private get _vaultsOptions() {
    return VAULTS_OPTIONS.filter(({ label }) => label !== this._fromVault.type);
  }

  private get _tokenOptions(): VaultTokenOption[] {
    return BASE_TOKENS_OPTIONS.map((option) => ({ ...option, label: this._tickers[option.id] }));
  }

  private _selectorOptions = (key: TokenTransferSelectors): SelectorValue[] => {
    switch (key) {
      case "to_vault": {
        return this._vaultsOptions;
      }
      case "token": {
        return this._tokenOptions;
      }
    }
  };

  private _selectorValue = (key: TokenTransferSelectors): SelectorValue | null => {
    switch (key) {
      case "to_vault": {
        const toVault = this._data.to_vault;
        return this._vaultsOptions.find(({ value }) => value === toVault) ?? null;
      }
      case "token": {
        const { token } = this._data;
        return this._tokenOptions.find(({ value }) => value === token) ?? null;
      }
    }
  };

  private _getSelectHandler = (key: TokenTransferSelectors) => {
    switch (key) {
      case "to_vault": {
        return (data: SelectorValue | null) => {
          const newValue = data?.value ?? "";
          const vaultType = newValue as DEXV2VaultTypeOrder | "";
          this._setData(key, vaultType);
        };
      }
      case "token": {
        return (data: SelectorValue | null) => {
          const newValue = data?.value ?? "";
          const tokenType = newValue as DEXV2VaultTokenOrder | "";
          this._setData(key, tokenType);
        };
      }
    }
  };

  getSelectorFormProps = (key: TokenTransferSelectors): SelectorFormProps => ({
    options: this._selectorOptions(key),
    value: this._selectorValue(key),
    onChange: this._getSelectHandler(key),
    errorHint: this.formState.errors[key],
  });

  private _setData = <K extends TokenTransferKeys>(
    key: K,
    value: ObjectPathValue<TokenTransferForm, K>
  ) => {
    setData(this._data, key, value);
  };

  private get _vaultContract() {
    const contractAddress = this._fromVault.address;

    return IVaultMainUpgradeable__factory.connect(contractAddress, this._chainProvider.getSigner());
  }

  private _getTransferPermission = async (transferData: TokenTransfer) => {
    try {
      const { isError, data } = await getTransferPermission(this._deployerId, transferData);
      if (!isError) {
        return data;
      }
    } catch (err) {
      logError(err);
    }
  };

  private _getPermission = async (transferData: TokenTransfer, withdrawer: string) => {
    const { sender } = transferData;
    if (sender === withdrawer) {
      return EMPTY_ORACLE_PERMISSION;
    }
    const permission = await this._getTransferPermission(transferData);
    if (permission) {
      return permissionResponseToOraclePermission(permission);
    }
  };

  private _getAmountWei = (amount: string, tokenOrder: DEXV2VaultTokenOrder) => {
    const tokenType = vaultTokenOrderToToken(tokenOrder);
    const token = this._tradePair.pair[tokenType];
    const amountWei = tryParseCurrencyAmount(amount, token);
    return amountWei?.quotient.toString();
  };

  private _tokenTransferFormToTokenTransfer: Mapper<TokenTransferForm, TokenTransfer | null> = (
    data
  ) => {
    const { amount } = data;
    const { amount: _amount, ...requiredData } = data as TokenTransfer;
    const { token } = requiredData;
    const amountWei = this._getAmountWei(amount.toString(), token);
    if (!amountWei) return null;
    return {
      ...requiredData,
      amount: amountWei,
    };
  };

  private _tokenTransferToChainTransferParams = (
    data: TokenTransfer,
    oraclePermission: OraclePermission
  ): Parameters<IVaultMainUpgradeable["transfer"]> => {
    const { token, to_vault, amount: amountWei } = data;
    return [token, to_vault, amountWei, oraclePermission];
  };

  private _transferTokens = async (data: TokenTransfer, permission: OraclePermission) => {
    try {
      const params = this._tokenTransferToChainTransferParams(data, permission);

      const transaction = await this._vaultContract.transfer(...params);
      const receipt = await transaction.wait();
      return receipt;
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  private _transfer = async (data: TokenTransfer) => {
    const permission = await this._getPermission(data, this._withdrawer);
    if (permission) {
      const receipt = await this._transferTokens(data, permission);

      if (receipt) {
        toast.success("Tokens transferred successfully", {
          autoClose: 2000,
        });
        this.closeModal();
      }
    }
  };

  submit = async () => {
    this._setLoading(true);
    try {
      const data = this._tokenTransferFormToTokenTransfer(this.data);
      if (!data) return;
      await this._transfer(data);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._walletDisconnectedReaction();
  };
}
