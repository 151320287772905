import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { Graph } from "../../../../shared/Graph";
import { Title } from "../style";
import * as styles from "./style";

export const BalanceUSDT = observer(({ showLoader, data, setRange }) => (
  <styles.BalanceUSDTContainer>
    <Title>Total USDT balance</Title>
    <Graph series={[{ data, title: "USDT" }]} request={setRange} style={{ width: "100%" }} />
    <Loader show={showLoader} />
  </styles.BalanceUSDTContainer>
));
