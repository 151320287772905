import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { noOp } from "src/helpers/utils";
import { useModalState } from "src/hooks/useModalState";
import { ChartPoint } from "src/modules/shared";
import { BaseGraphsProps, priceFormatter } from "..";
import { StatsGraph } from "../StatsGraph";
import * as styles from "../shared";
import { Wrapper } from "../style";

export interface BalancesGraphData {
  quote: ChartPoint[];
  base: ChartPoint[];
}

export interface BalancesGraphProps extends BaseGraphsProps, ComponentPropsWithoutRef<"div"> {
  data: BalancesGraphData;
  quoteTicker: string;
  baseTicker: string;
  basePrecision?: number;
  quotePrecision?: number;
}

type UseLineVisibilityResponse = [boolean, () => void];

const useLineVisibility = (defaultVisible: boolean = true): UseLineVisibilityResponse => {
  const { isShown: isVisible, toggle: toggleVisible } = useModalState(defaultVisible);

  return [isVisible, toggleVisible];
};

export const BalancesGraph = ({
  data: { quote = [], base = [] },
  quoteTicker,
  baseTicker,
  isLoading,
  onRefresh = noOp,
  quotePrecision,
  basePrecision,
  ...props
}: BalancesGraphProps) => {
  const [quoteLineVisible, toggleQuoteLineVisible] = useLineVisibility();
  const [baseLineVisible, toggleBaseLineVisible] = useLineVisibility();

  return (
    <styles.GraphContainer {...props}>
      <Wrapper>
        <styles.QuoteCheckBox
          label={quoteTicker}
          checked={quoteLineVisible}
          onChange={toggleQuoteLineVisible}
        />
        <styles.BaseCheckBox
          label={baseTicker}
          checked={baseLineVisible}
          onChange={toggleBaseLineVisible}
        />
      </Wrapper>

      <StatsGraph
        series={[
          {
            data: quote,
            color: "assetColor",
            visible: quoteLineVisible,
            title: quoteTicker,
            side: "left",
            options: {
              priceFormat: {
                type: "custom",
                formatter: priceFormatter(quotePrecision || 0),
              },
            },
          },
          {
            data: base,
            color: "tokenColor",
            visible: baseLineVisible,
            title: baseTicker,
            side: "right",
            options: {
              priceFormat: {
                type: "custom",
                formatter: priceFormatter(basePrecision || 0),
              },
            },
          },
        ]}
        request={onRefresh}
        showAreaColor={false}
      />
      <Loader show={isLoading} />
    </styles.GraphContainer>
  );
};
