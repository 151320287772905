import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import useAppState from "src/state";
import { SettingsPanel } from "../SettingsPanel";

export const NamePanel = observer(() => {
  const { createBotState: state } = useAppState();

  const id = useMemo(() => nanoid(), []);

  const StartSwitch = (
    <ToggleSwitch
      label="Start"
      id={id}
      checked={state.startConfig}
      onChange={state.toggleStartConf}
    />
  );

  return (
    <SettingsPanel style={{ gridArea: "name" }} title="Name" afterTitle={StartSwitch}>
      <LabeledInput
        label="Bot name"
        value={state.data.name ?? ""}
        onChange={state.getHandler("name")}
        errorHint={state.errors.name}
      />
    </SettingsPanel>
  );
});
