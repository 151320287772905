import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";
import { BuyLiquidityChart, SellLiquidityChart } from "../../../BuySellLiquidityCharts";
import { ExchangesOverview } from "../../../ExchangesOverview";
import { LegendContainer as ExchangesLegendContainer } from "../../../ExchangesOverview/ExchangesLegend/style";
import { KPIPanel } from "../../../KPIPanel";
import { KPIAreas } from "../../../KPIPanel/style";
import { OldBalancesOverviewChart } from "../../../OldBalancesOverviewChart";
import { Container as BalancesInfoPanelContainer } from "../../../OldBalancesOverviewChart/BalancesInfoPanel/style";
import { ProfitLossChart } from "../../../ProfitLossChart";
import { Container as PNLInfoPanelContainer } from "../../../ProfitLossChart/ProfitLossInfoPanel/style";
import { VolumeShare } from "../../../VolumeShare";
import { MainContentWrapper } from "../../../shared/Card/style";
import { SantimentOverview } from "../../../SantimentChart";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StatsKPIPanel = styled(KPIPanel)`
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "${KPIAreas.Aum}"
    "${KPIAreas.Balance}"
    "${KPIAreas.Impact} "
    "${KPIAreas.PNL}"
    "${KPIAreas.Tokens}";
`;

export const KPIFallback = styled(CrashPanel)`
  min-height: 200px;
`;

export const StyledPNLChart = styled(ProfitLossChart)`
  height: 340px;
  flex-direction: column;

  ${MainContentWrapper} {
    width: 100%;
  }

  ${PNLInfoPanelContainer} {
    flex-direction: row;
  }
`;

export const PNLFallback = styled(CrashPanel)`
  height: 340px;
`;

export const StyledBalancesChart = styled(OldBalancesOverviewChart)`
  height: 420px;
  flex-direction: column;

  ${MainContentWrapper} {
    width: 100%;
  }

  ${BalancesInfoPanelContainer} {
    flex-direction: row;
  }
`;

export const BalancesFallback = styled(CrashPanel)`
  height: 420px;
`;

export const StyledExchangesChart = styled(ExchangesOverview)`
  height: 480px;
  flex-direction: column;

  ${MainContentWrapper} {
    width: 100%;
  }

  ${ExchangesLegendContainer} {
    width: 100%;
    padding: 8px;
  }
`;

export const ExchangesFallback = styled(CrashPanel)`
  height: 480px;
`;

export const StyledBuyLiquidityChart = styled(BuyLiquidityChart)`
  height: 180px;
`;

export const BuyLiquidityFallback = styled(CrashPanel)`
  height: 180px;
`;

export const StyledSellLiquidityChart = styled(SellLiquidityChart)`
  height: 180px;
`;

export const SellLiquidityFallback = styled(CrashPanel)`
  height: 180px;
`;

export const StyledVolumeShare = styled(VolumeShare)`
  height: 375px;
`;

export const VolumeLiquidityFallback = styled(CrashPanel)`
  height: 375px;
`;

export const StyledSantimentChart = styled(SantimentOverview)`
  height: 420px;
`;
