import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { Graph } from "../../../../shared/Graph";
import { Title } from "../style";
import * as styles from "./style";

export const BalanceToken = observer(({ showLoader, data, token, setRange }) => (
  <styles.BalanceTokenContainer>
    <Title>Total {token} balance</Title>
    <Graph series={[{ data, title: token }]} request={setRange} />
    <Loader show={showLoader} />
  </styles.BalanceTokenContainer>
));
