import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column } from "react-table";
import { formatFiat } from "src/helpers/string";
import useLocalStore from "src/hooks/useLocalStore";
import AumSummaryStore, { AumSummaryRowData } from "src/state/CEX/CEXDashboard/AumSummaryStore";
import { Card, CardProps } from "../shared/Card";
import { AumCell } from "./AumCell";
import { TypeCell } from "./TypeCell";
import * as styles from "./style";

const DEFAULT_COLUMN: Partial<Column<AumSummaryRowData>> = {
  width: 50,
  disableSortBy: true,
};

export interface AumSummaryTableProps extends CardProps {}

export const AumSummaryTable = observer(({ ...props }: AumSummaryTableProps) => {
  const state = useLocalStore(AumSummaryStore);

  const { data, getAumSummary } = state;

  useEffect(() => {
    getAumSummary();
  }, [getAumSummary]);

  const currencyColumns = useMemo(() => {
    const columnsSchema: Column<AumSummaryRowData>[] = [
      {
        Header: "",
        accessor: "type",
        Cell: ({ value }) => <TypeCell>{value}</TypeCell>,
      },
      {
        Header: "Quote $",
        accessor: "quote",
        Cell: ({ value }) => <>{formatFiat(value)}</>,
      },
      {
        Header: "Base $",
        accessor: "base",
        Cell: ({ value }) => <>{formatFiat(value)}</>,
      },
      {
        Header: "Total $",
        accessor: "total",
        Cell: ({ value, row: { original } }) => {
          const isAum = original?.isAumTotal ?? false;
          return isAum ? <AumCell>{value}</AumCell> : <>{formatFiat(value)}</>;
        },
      },
    ];

    return columnsSchema;
  }, []);

  return (
    <Card title="Summary" {...props}>
      <styles.StyledAumTable columns={currencyColumns} data={data} defaultColumn={DEFAULT_COLUMN} />
    </Card>
  );
});
