import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Input } from "src/components/shared/Forms/Inputs/Input/Input/style";
import { WrapperForIcon } from "src/components/shared/Forms/Label/LabelErrorIcon/style";
import { Label } from "src/components/shared/Forms/Label/style";
import { ErrorMsg } from "src/components/shared/shared";
import { Tabs } from "src/components/shared/Tabs";
import { Panel } from "src/components/shared/Tabs/style";
import { hexToRgb } from "src/helpers/colors";
import { ConstraintLevel, ErrorType } from "src/state/CEX/CEXSettings/settingsBot";
import styled, { css, DefaultTheme } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;

  & > div:first-of-type {
    margin-right: 10px;
  }
`;

export const SettingsTabs = styled(Tabs)`
  ${Panel} {
    margin-bottom: 10px;
  }
`;

export interface ConstraintsInputProps {
  errorType?: ErrorType;
}

type ConstraintErrorType = ConstraintLevel;

type ConstraintInputColors = {
  content: string;
  background: string;
  light: string;
};

const ALERT_COLOR = "#FF832B";
const WARNING_COLOR = "#F1C21B";

export const errorTypeColors = (
  _theme: DefaultTheme,
  type: ConstraintErrorType
): ConstraintInputColors => {
  switch (type) {
    case "alert": {
      return {
        content: ALERT_COLOR,
        background: "#FFF3EA",
        light: hexToRgb(ALERT_COLOR, 0.06),
      };
    }
    case "warning": {
      return {
        content: WARNING_COLOR,
        background: "#FEF9E8",
        light: hexToRgb(WARNING_COLOR, 0.06),
      };
    }
  }
};

export const InputColorMixin = (theme: DefaultTheme, type: ConstraintErrorType) => {
  const { content, background, light } = errorTypeColors(theme, type);
  return css`
    & ${Input} {
      box-shadow: 0 0 0 1px ${content};

      :focus {
        box-shadow: 0 0 0 1px ${content};
      }
    }

    & ${Label} {
      color: ${content};
    }

    & ${WrapperForIcon} {
      svg {
        circle {
          fill: ${background};
          stroke: ${content};
        }

        path {
          fill: ${content};
        }
      }
    }

    & ${ErrorMsg} {
      color: ${content} !important;
      background: ${background} !important;

      :after {
        border-right-color: transparent !important;
      }

      &.place-left::after {
        border-left-color: ${light} !important;
      }

      &.place-right::after {
        border-right-color: ${light} !important;
      }

      &.place-top::after {
        border-top-color: ${light} !important;
      }

      &.place-bottom::after {
        border-bottom-color: ${light} !important;
      }
    }
  `;
};

export const ConstraintsInput = styled(LabeledInput)<ConstraintsInputProps>`
  ${({ theme, errorType }) => {
    switch (errorType) {
      case "warning":
      case "alert": {
        return InputColorMixin(theme, errorType);
      }
    }
  }}
`;

export interface ConstraintSpanProps {
  $type: ConstraintErrorType;
}

export const ConstraintSpan = styled.span<ConstraintSpanProps>`
  ${({ theme, $type }) => {
    const { content } = errorTypeColors(theme, $type);
    return css`
      color: ${content};
    `;
  }}
  text-transform: uppercase;
`;
