import { ColumnDef } from "@tanstack/react-table";
import { Observer, observer } from "mobx-react-lite";
import { useMemo } from "react";
import { TimeModeInfoItem } from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeInfo/ModeInfoItem/TimeModeInfoItem";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { ActivationIndicator } from "src/components/shared/Indicators";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { checkParty } from "src/helpers/checkParty";
import { formatElapsedTime, unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategy, StrategyCreatedModule, StrategyStatus } from "src/modules/expertSystem";
import useAppState from "src/state";
import { TableRows } from "../../shared";
import { CellText } from "../../shared/TableRows/style";
import { TagList } from "../../shared/TagList";
import { getTagList } from "../../shared/utils/modules";
import { ExtendModal } from "./ExtendModal";
import { LaunchesCountSpan } from "./LaunchesCountSpan";
import { LaunchesLimitCell } from "./LaunchesLimitCell";
import { StrategyInfoModal } from "./StrategyInfoModal";

const BASE_COLUMN_PROPS: Omit<ColumnDef<IStrategy>, "header" | "accessor" | "footer" | "cell"> = {
  enableColumnFilter: false,
  enableSorting: false,
};

const BASE_STATIC_COLUMN_PROPS: Omit<
  ColumnDef<IStrategy>,
  "header" | "accessor" | "footer" | "cell"
> = {
  ...BASE_COLUMN_PROPS,
  size: 50,
};

const BASE_CELL_TEXT: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
};

const BASE_CELL_STATUS: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: (info) => {
    const status = info.getValue<StrategyStatus>();

    return (
      <CellText>
        <ActivationIndicator title={status} status={status} />
      </CellText>
    );
  },
};

const BASE_CELL_TIME: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: (info) => <CellText>{unixToDateFormat(info.getValue<number>(), "FullDate")}</CellText>,
};

const BASE_CELL_LAUNCHES_COUNT: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: ({ row }) => {
    const { launches_count, launches_completed } = row.original;
    return <LaunchesCountSpan total={launches_count} completed={launches_completed} />;
  },
};

const BASE_CELL_EXPIRE: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: (info) => {
    const unixTime = info.getValue<number>();

    return (
      <CellText>
        <TimeModeInfoItem
          style={{ width: "auto", gap: 0 }}
          label=""
          time={unixToDateFormat(unixTime, "FullDate")}
          elapsedTime={formatElapsedTime(unixTime)}
        />
      </CellText>
    );
  },
};

const BASE_CELL_MODULES: Pick<ColumnDef<IStrategy>, "cell"> = {
  cell: (info) => {
    const modules = info.getValue<StrategyCreatedModule[]>();

    const tags = getTagList(modules);

    return <TagList tags={tags} />;
  },
};

export const StrategiesTable = observer(() => {
  const {
    userState: { abilities },
  } = useAppState();
  const state = useLateInitContext(StrategyListContext);
  const { abilityName } = useLateInitContext(AbilitiesContext);

  const checkPermission = useMemo(
    () => checkParty(abilities, abilityName, state.party),
    [abilities, abilityName, state.party]
  );

  const deleteBtnCell: ColumnDef<IStrategy>[] = useMemo(() => {
    if (checkPermission) {
      return [
        {
          header: "",
          id: "remove_column",
          accessorKey: "uuid",
          ...BASE_STATIC_COLUMN_PROPS,
          cell: (info) => {
            const uuid = info.getValue<string>();

            return (
              <CellText>
                <DeleteButton onClick={() => state.removeStrategyHandler(uuid)} />
              </CellText>
            );
          },
        },
      ];
    }
    return [];
  }, [state, checkPermission]);

  const startBtnCell: ColumnDef<IStrategy>[] = useMemo(() => {
    if (checkPermission) {
      return [
        {
          header: "",
          id: "start_column",
          accessorKey: "status",
          ...BASE_STATIC_COLUMN_PROPS,
          cell: ({
            cell: {
              row: { original: strategy },
            },
          }) => {
            const strategyStatus = strategy.status === "active";

            return (
              <Observer>
                {() => (
                  <CellText>
                    <ToggleSwitch
                      checked={strategyStatus}
                      onChange={() => state.toggleStrategyHandler(strategy.uuid)}
                    />
                  </CellText>
                )}
              </Observer>
            );
          },
        },
      ];
    }
    return [];
  }, [state, checkPermission]);

  const extendBtn: ColumnDef<IStrategy>[] = useMemo(() => {
    if (checkPermission) {
      return [
        {
          header: "",
          id: "extend_column",
          accessorKey: "uuid",
          ...BASE_STATIC_COLUMN_PROPS,
          cell: (info) => {
            const uuid = info.getValue<string>();

            return (
              <CellText>
                <ExtendModal uuid={uuid} />
              </CellText>
            );
          },
        },
      ];
    }
    return [];
  }, [checkPermission]);

  const launchesLimitColumn: ColumnDef<IStrategy>[] = useMemo(
    () => [
      {
        header: "Launches limit",
        accessorKey: "launches_limit",
        ...BASE_COLUMN_PROPS,
        cell: (info) => {
          const limit = info.getValue<number>();
          const { uuid } = info.row.original;

          return <LaunchesLimitCell limit={limit} uuid={uuid} showEdit={checkPermission} />;
        },
      },
    ],
    [checkPermission]
  );

  const columns = useMemo(() => {
    const columnsSchema: ColumnDef<IStrategy>[] = [
      {
        header: "Status",
        accessorKey: "status",
        size: 80,
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_STATUS,
      },
      {
        header: "Name",
        accessorKey: "name",
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_TEXT,
        enableSorting: true,
      },
      {
        header: "Created at",
        accessorKey: "created_at",
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_TIME,
        enableSorting: true,
      },
      {
        header: "Expire time",
        accessorKey: "expired_at",
        minSize: 160,
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_EXPIRE,
        enableSorting: true,
      },
      {
        header: "Execution order",
        accessorKey: "execution_order",
        minSize: 160,
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_TEXT,
      },
      {
        header: "Condition",
        accessorKey: "conditions",
        minSize: 300,
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_MODULES,
      },
      {
        header: "Action",
        accessorKey: "actions",
        minSize: 200,
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_MODULES,
      },
      {
        header: "Launches count",
        accessorKey: "launches_count",
        ...BASE_COLUMN_PROPS,
        ...BASE_CELL_LAUNCHES_COUNT,
      },
      ...launchesLimitColumn,
      ...extendBtn,
      ...startBtnCell,
      // {
      //   header: "",
      //   id: "edit_colum",
      //   accessorKey: "uuid",
      //   ...BASE_STATIC_COLUMN_PROPS,
      //   cell: (info) => (
      //     <CellText>
      //       <IconButton onClick={() => {}}>{Icons.edit()}</IconButton>
      //     </CellText>
      //   ),
      // },
      {
        header: "",
        id: "info_colum",
        accessorKey: "uuid",
        ...BASE_STATIC_COLUMN_PROPS,
        cell: (info) => (
          <CellText>
            <StrategyInfoModal uuid={info.getValue<string>()} />
          </CellText>
        ),
      },
      ...deleteBtnCell,
    ];

    return columnsSchema;
  }, [launchesLimitColumn, extendBtn, startBtnCell, deleteBtnCell]);

  return (
    <TableRows
      rowSize={75}
      columns={columns}
      data={state.strategies}
      loader={state.isLoading}
      sortingState={state.sortingState}
      onSortingChange={state.setSortingState}
    />
  );
});
