import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, entries } from "src/helpers/utils";
import { generateAmount } from "./FundingStore";

type AumSummaryTypeData = {
  quote: string;
  base: string;
  total: string;
};

export type AumSummaryData = {
  free: AumSummaryTypeData;
  locked: AumSummaryTypeData;
  total: AumSummaryTypeData;
};

export interface AumSummaryRowData extends AumSummaryTypeData {
  type: string;
  isAumTotal?: boolean;
}

const generateAumSummaryTypeData = (): AumSummaryTypeData => ({
  quote: generateAmount(),
  base: generateAmount(),
  total: generateAmount(),
});

const generateAumSummary = (): AumSummaryData => ({
  free: generateAumSummaryTypeData(),
  locked: generateAumSummaryTypeData(),
  total: generateAumSummaryTypeData(),
});

const mapAumSummaryToRowData = (summary: AumSummaryData): AumSummaryRowData[] =>
  entries(summary).map(([type, value]) => ({
    type,
    quote: value.quote,
    base: value.base,
    total: value.total,
    isAumTotal: type === "total",
  }));

export default class AumSummaryStore implements Disposable {
  private _data: AumSummaryRowData[] = [];

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: AumSummaryRowData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  getAumSummary = () => {
    try {
      const data = generateAumSummary();
      const rows = mapAumSummaryToRowData(data);
      this._setData(rows);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
