import styled from "styled-components";
import {
  CounterTooltip,
  TOOLTIP_CLASSNAME,
} from "../../../../TrackableTokens/ExchangesListItem/style";

export const InfoIconWrapper = styled.span`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const InfoTooltip = styled(CounterTooltip)`
  &.${TOOLTIP_CLASSNAME} {
    color: ${({ theme }) => theme.dashboard.textSubhead};
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    letter-spacing: -0.2px;
    white-space: pre-wrap;
  }
`;
