import { observer } from "mobx-react-lite";
import { Input } from "src/components/shared/Forms/Inputs/Input/Input";
import useAppState from "src/state";
import { SubTitle, Wrapper } from "../style";
import * as styles from "./style";

export const Info = observer(() => {
  const { DEXSettingsState: state } = useAppState();

  return (
    <styles.Content>
      <SubTitle>Info</SubTitle>
      <Wrapper>
        <Input
          type="number"
          label="Tokens buy/sell for 1 hour"
          readOnly
          value={state.tokensBuySell || 0}
        />
        <Input type="number" label="Fee per day" readOnly value={state.fee || 0} />
      </Wrapper>
      <Input type="number" label="Token volume per day" readOnly value={state.tokenVolume || 0} />
    </styles.Content>
  );
});
