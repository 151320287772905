import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column, useTable } from "react-table";
import {
  createStringNumberSortBy,
  createTokenPairSortBy,
} from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { DashboardPairsContext } from "src/context/CEX/Dashboard/Pairs";
import { joinPair } from "src/helpers/botName";
import { formatDecimalPercent, formatFiat } from "src/helpers/string";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PairsData, PairsDataKeys } from "src/state/CEX/CEXDashboard/PairsStore";
import { Card, CardProps } from "../shared/Card";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { setTableColumnWidth, useTableOptions } from "../shared/StatsTable";
import { PairsFooter } from "./PairsFooter";
import { PriceChangeText } from "./PriceChangeText";
import * as styles from "./style";
import { getAllPairsTotal } from "./utils/totals";

const PAIRS_COLUMNS_TITLES: Record<PairsDataKeys, string> = {
  exchange: "Exchange",
  pair: "Pair",
  priceUSD: "Price",
  priceChange: "24H Price Change",
  volume: "24H Volume",
  volumeShare: "Gotbit 24H volume % share",
  liquidity: "Gotbit orderbook liquidity",
  indexImpact: "Gotbit index impact",
};

const LOCKED_COLUMNS: PairsDataKeys[] = ["exchange", "pair"];

const getColumnTitleById = (id: string) => PAIRS_COLUMNS_TITLES[id as PairsDataKeys];

const DEFAULT_COLUMN: Partial<Column<PairsData>> = {
  ...setTableColumnWidth(70),
  disableFilters: true,
  sortType: createStringNumberSortBy(),
};

export interface PairsTableProps extends Omit<CardProps, "title"> {}

export const PairsTable = observer((props: PairsTableProps) => {
  const state = useLateInitContext(DashboardPairsContext.Context);

  const { data, getPairs } = state;

  useEffect(() => {
    getPairs();
  }, [getPairs]);

  const columns = useMemo(
    (): Column<PairsData>[] => [
      {
        Header: getColumnTitleById("exchange"),
        accessor: "exchange",
        sortType: "string",
        Footer: "Total",
      },
      {
        Header: getColumnTitleById("pair"),
        accessor: "pair",
        Cell: ({ value: { base, quote } }) => <>{joinPair(quote, base)}</>,
        sortType: createTokenPairSortBy(),
      },
      {
        Header: getColumnTitleById("priceUSD"),
        accessor: "priceUSD",
        Cell: ({ value }) => <>{formatFiat(value)}</>,
      },
      {
        Header: getColumnTitleById("priceChange"),
        accessor: "priceChange",
        Cell: ({ value }) => <PriceChangeText>{value}</PriceChangeText>,
      },
      {
        Header: getColumnTitleById("volume"),
        accessor: "volume",
        Cell: ({ value }) => <>{formatFiat(value)}</>,
        Footer: <PairsFooter columnId="volume" />,
      },
      {
        Header: getColumnTitleById("volumeShare"),
        accessor: "volumeShare",
        Cell: ({ value }) => <>{formatDecimalPercent(value)}</>,
        ...setTableColumnWidth(90),
      },
      {
        Header: getColumnTitleById("liquidity"),
        accessor: "liquidity",
        Cell: ({ value }) => <>{formatFiat(value)}</>,
        Footer: <PairsFooter columnId="liquidity" />,
        ...setTableColumnWidth(90),
      },
      {
        Header: getColumnTitleById("indexImpact"),
        accessor: "indexImpact",
        Cell: ({ value }) => <>{formatDecimalPercent(value)}</>,
        ...setTableColumnWidth(90),
      },
    ],
    []
  );

  const { options, plugins } = useTableOptions({
    data,
    defaultColumn: DEFAULT_COLUMN,
    columns,
  });

  const table = useTable(options, ...plugins);

  return (
    <>
      <RefreshDataButton onRefresh={state.getPairs} />
      <Card
        title="All pairs"
        {...props}
        afterTitle={
          <styles.StyledColumnsFilter
            instance={table}
            getTitleById={getColumnTitleById}
            lockedColumns={LOCKED_COLUMNS}
          />
        }
      >
        <styles.StyledPairsTable instance={table} getColumnsTotal={getAllPairsTotal} />
      </Card>
    </>
  );
});
