import { CurrencyAmount, Percent, Price, Token } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { expToNumber } from "src/helpers/rounding";
import { Disposable, Nullish, entries } from "src/helpers/utils";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { ISwapSlippageProvider } from ".";
import { IRouterProvider } from "../..";
import { ISwapGasLimitProvider, ISwapPairAddressProvider } from "../../..";
import { IBotTradePairProvider } from "../../../../DEXV2Bots/DEXV2BotStore";
import { INativeUSDPriceProvider } from "../../../../Providers/NativeUSDPriceProvider";
import { TradeSide } from "../../../../shared/TradeToken";
import { ISwapVaultProvider } from "../../../Vaults";
import {
  ABSTRACT_NATIVE_CURRENCY,
  CacheOptions,
  getPriceImpactWarning,
  invertPriceImpact,
  tryParseCurrencyAmount,
} from "../../../utils";
import { IBaseUSDPriceProvider } from "../../shared/Providers/BaseUsdPriceProvider";
import { GasPriceProvider, IGasPriceProvider } from "../../shared/Providers/GasPriceProvider";
import { toPercent } from "../../shared/SlippageStore";
import { IRouteParams, SwapRoute, SwapRouteError, SwapRouteState } from "../../shared/Swap/Router";
import { Field, SwapStateStore, isExactInput } from "../../shared/SwapStateStore";
import { V3SwapInfo } from "../../v3/SwapWidget/V3SwapInfoStore";
import { V2SwapRoute, V2SwapRouteError } from "../Swap/Providers/V2RouteStateProvider";

type TradeSideFieldMap = Partial<Record<TradeSide, Field>>;

type SwapBalances = Partial<Record<Field, CurrencyAmount<Token>>>;

type AmountsUSD = SwapBalances;

interface SwapField {
  token?: Token;
  amount?: CurrencyAmount<Token>;
  balance?: CurrencyAmount<Token>;
  usd?: CurrencyAmount<Token>;
}

export interface PriceImpact {
  percent: Percent;
  warning?: boolean;
}

export interface SwapInfo {
  [Field.INPUT]: SwapField;
  [Field.OUTPUT]: SwapField;
  trade: {
    trade?: V2SwapRoute["trade"];
    gasEstimateUSD?: CurrencyAmount<Token>;
    error?: V2SwapRouteError;
  };
  price: {
    midPrice?: Price<Token, Token>;
    nextMidPrice?: Price<Token, Token>;
    executionPrice?: Price<Token, Token>;
  };
  impact?: PriceImpact;
  slippage?: Percent;
}

export const INITIAL_SWAP_INFO = {
  [Field.INPUT]: {},
  [Field.OUTPUT]: {},
};

export interface ISwapInfoParams {
  swapState: SwapStateStore;
  chainProvider: IChainProvider;
  vaultProvider: ISwapVaultProvider;
  slippageProvider: ISwapSlippageProvider;
  gasLimitProvider: ISwapGasLimitProvider;
  tradePairProvider: IBotTradePairProvider;
  routerProvider: IRouterProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  poolAddressProvider: ISwapPairAddressProvider;
}

export interface ISwapInfo {
  get info(): SwapInfo | V3SwapInfo;
  calculateInfo: (options?: CacheOptions) => Promise<void>;
}

export class SwapInfoStore implements ISwapInfo, Disposable {
  private _swapState: SwapStateStore;

  private _swapRoute: SwapRoute | null = null;

  private _swapRouteError: Nullish<SwapRouteError>;

  private _tradePairProvider: IBotTradePairProvider;

  private _vaultProvider: ISwapVaultProvider;

  private _slippageProvider: ISwapSlippageProvider;

  private _gasPriceProvider: IGasPriceProvider;

  private _gasLimitProvider: ISwapGasLimitProvider;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _routerProvider: IRouterProvider;

  private _poolAddressProvider: ISwapPairAddressProvider;

  constructor({
    swapState,
    chainProvider,
    vaultProvider,
    slippageProvider,
    gasLimitProvider,
    tradePairProvider,
    routerProvider,
    baseUSDPriceProvider,
    nativeUSDPriceProvider,
    poolAddressProvider,
  }: ISwapInfoParams) {
    makeAutoObservable<this, "_pairCache" | "_reservesCache">(this, {
      _pairCache: false,
      _reservesCache: false,
    });

    this._swapState = swapState;

    this._vaultProvider = vaultProvider;
    this._slippageProvider = slippageProvider;

    this._gasLimitProvider = gasLimitProvider;
    this._gasPriceProvider = new GasPriceProvider(chainProvider);

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    this._tradePairProvider = tradePairProvider;

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._routerProvider = routerProvider;

    this._poolAddressProvider = poolAddressProvider;

    makeLoggable<any>(this, {
      info: true,
      _swapRoute: true,
      _amounts: true,
      _swapPath: true,
      _parsedAmount: true,
      _vaultTokenField: true,
      _balances: true,
    });
  }

  private get _router() {
    return this._routerProvider.router;
  }

  private get _swap() {
    return this._swapState.swap;
  }

  private _setSwapRoute = (route: SwapRoute) => {
    this._swapRoute = route;
  };

  private _setSwapRouteError = (error: Nullish<SwapRouteError>) => {
    this._swapRouteError = error;
  };

  private get _swapPath() {
    const tokenIn = this._swap[Field.INPUT];
    const tokenOut = this._swap[Field.OUTPUT];
    if (!tokenIn || !tokenOut) return null;
    return [tokenIn, tokenOut];
  }

  private get _parsedAmount() {
    const { type, amount, [Field.INPUT]: currencyIn, [Field.OUTPUT]: currencyOut } = this._swap;
    const token = isExactInput(type) ? currencyIn : currencyOut;
    const parsedAmount = tryParseCurrencyAmount(amount, token);
    return parsedAmount;
  }

  private get _poolAddress() {
    return this._poolAddressProvider.pairAddress;
  }

  private get _routeParams(): Omit<IRouteParams, "options"> | null {
    const { type } = this._swap;
    const path = this._swapPath;
    const parsedAmount = this._parsedAmount;
    const poolAddress = this._poolAddress;
    if (!parsedAmount || !path || !poolAddress) return null;
    return { amount: parsedAmount, swapType: type, path, pools: [poolAddress] };
  }

  private _routerTrade = async (options?: CacheOptions) => {
    const router = this._router;
    const routeParams = this._routeParams;
    if (!router || !routeParams) return;

    const route = await router.route({ ...routeParams, options });
    if (route.state === SwapRouteState.Invalid) {
      this._setSwapRouteError(route.error);
    } else {
      this._setSwapRouteError(null);
      this._setSwapRoute(route);
    }
  };

  private get _amounts() {
    const { type } = this._swap;
    const trade = this._swapRoute?.trade;
    const parsedAmount = this._parsedAmount;
    const amounts = isExactInput(type)
      ? ([parsedAmount, trade?.outputAmount] as const)
      : ([trade?.inputAmount, parsedAmount] as const);
    return amounts;
  }

  private get _isBaseOutput() {
    const { base: baseField } = this._tradeSideField;
    return baseField === Field.OUTPUT;
  }

  // we invert prices in case current prices are in trade base
  private get _shouldInvertPrice() {
    return this._isBaseOutput;
  }

  // we denote price impact not as diff between midPrice and executionPrice
  // but the ratio between midPrice and nextMidPrice
  private get _priceImpact(): PriceImpact | undefined {
    const trade = this._swapRoute?.trade as V2SwapRoute["trade"] | undefined;
    const priceImpact = trade?.nextPriceImpact;
    if (!priceImpact) {
      return undefined;
    }

    const marketPriceImpact = this._shouldInvertPrice
      ? invertPriceImpact(priceImpact)
      : priceImpact;
    return {
      percent: marketPriceImpact,
      warning: getPriceImpactWarning(marketPriceImpact),
    };
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _pair() {
    return this._tradePair?.pair ?? null;
  }

  private get _tradeSideField(): TradeSideFieldMap {
    const tradePair = this._pair;
    if (!tradePair) return {};

    const tradePairEntries = entries(tradePair);

    const tokenField: TradeSideFieldMap = {};

    for (const field of Object.values(Field)) {
      const fieldToken = this._swap[field];
      if (!fieldToken) continue;

      const tradeTokenEntry = tradePairEntries.find(([, token]) => token.equals(fieldToken));
      if (!tradeTokenEntry) continue;
      const [tradeSide] = tradeTokenEntry;

      tokenField[tradeSide] = field;
    }

    return tokenField;
  }

  private get _balances(): SwapBalances {
    const { quote: quoteBalance, base: baseBalance } = this._vaultProvider.vault;

    const fixedQuoteBalance = expToNumber(quoteBalance);
    const fixedBaseBalance = expToNumber(baseBalance);

    const { quote: quoteField, base: baseField } = this._tradeSideField;

    if (!quoteField || !baseField) {
      const tokenIn = this._swap[Field.INPUT];
      const tokenOut = this._swap[Field.OUTPUT];
      if (!tokenIn || !tokenOut) return {};
      return {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(tokenIn, 0),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(tokenOut, 0),
      };
    }

    const quoteToken = quoteField ? this._swap[quoteField] : undefined;
    const baseToken = baseField ? this._swap[baseField] : undefined;
    if (!quoteToken || !baseToken) {
      return {};
    }

    const weiBaseBalance = ethers.utils.parseUnits(fixedBaseBalance, baseToken.decimals);

    const weiQuoteBalance = ethers.utils.parseUnits(fixedQuoteBalance, quoteToken.decimals);

    return {
      [baseField]: CurrencyAmount.fromRawAmount(baseToken, weiBaseBalance.toString()),
      [quoteField]: CurrencyAmount.fromRawAmount(quoteToken, weiQuoteBalance.toString()),
    };
  }

  private get _transactionFee() {
    const { quote: quoteField, base: baseField } = this._tradeSideField;

    if (!quoteField || !baseField) {
      return undefined;
    }

    switch (true) {
      case quoteField === Field.INPUT && baseField === Field.OUTPUT: {
        return this._slippageProvider.transactionFees.buy;
      }
      case quoteField === Field.OUTPUT && baseField === Field.INPUT: {
        return this._slippageProvider.transactionFees.sell;
      }
      default:
        return undefined;
    }
  }

  private get _slippage() {
    const { error: slippageError, percent: baseSlippagePercent } = this._slippageProvider.slippage;
    if (!baseSlippagePercent || slippageError?.type === "error") {
      return undefined;
    }

    const transactionFee = this._transactionFee;
    const transactionFeePercent = toPercent(transactionFee);
    if (!transactionFeePercent) {
      return baseSlippagePercent;
    }

    return baseSlippagePercent.add(transactionFeePercent);
  }

  private get _nativeUSDPrice() {
    return this._nativeUSDPriceProvider.nativeUSDPrice;
  }

  private get _gasEstimateUSD(): CurrencyAmount<Token> | undefined {
    const { gasLimit } = this._gasLimitProvider;
    const { gasPrice } = this._gasPriceProvider;
    const nativeUSDPrice = this._nativeUSDPrice;
    if (!gasLimit || !gasPrice || !nativeUSDPrice) return undefined;
    const { gasPriceWei } = gasPrice;
    const gasEstimate = gasPriceWei.mul(gasLimit);

    const rawGasAmount = CurrencyAmount.fromRawAmount(
      ABSTRACT_NATIVE_CURRENCY,
      gasEstimate.toString()
    );

    return nativeUSDPrice.quote(rawGasAmount);
  }

  private get _baseUSDPrice() {
    return this._baseUSDPriceProvider.baseUSDPrice;
  }

  // helper variables to track state when tokens are switched in ui,
  // but no trade refetch occurred with new tokens order
  private get _isSwitchingAmounts() {
    const [amountIn, amountOut] = this._amounts;
    if (!amountIn || !amountOut) return false;
    return amountIn.currency.equals(amountOut.currency);
  }

  private get _isSwitchingTrade() {
    const trade = this._swapRoute?.trade;
    const tokenIn = this._swap[Field.INPUT];
    if (!trade || !tokenIn) return false;
    return !trade.inputAmount.currency.equals(tokenIn);
  }

  private get _isSwitchingTokens() {
    return this._isSwitchingAmounts || this._isSwitchingTrade;
  }

  private get _amountsUsd(): AmountsUSD {
    if (this._isSwitchingTokens) {
      return {};
    }
    const baseUSDPrice = this._baseUSDPrice;
    const { base: baseField, quote: quoteField } = this._tradeSideField;
    const baseToken = baseField ? this._swap[baseField] : undefined;

    if (!baseUSDPrice || !quoteField || !baseField || !baseToken) {
      return {};
    }

    const [amountIn, amountOut] = this._amounts;

    const fieldAmounts = {
      [Field.INPUT]: amountIn,
      [Field.OUTPUT]: amountOut,
    };

    const baseAmount = fieldAmounts[baseField];
    if (!baseAmount) return {};

    const baseAmountUSD = baseUSDPrice.quote(baseAmount);

    const route = this._swapRoute?.route;

    const quoteAmount = fieldAmounts[quoteField];

    if (!quoteAmount || !route) {
      return { [baseField]: baseAmountUSD };
    }

    // get mid price in terms of trade base
    const midPriceInTradeBase = route.midPrice.baseCurrency.equals(baseToken)
      ? route.midPrice.invert()
      : route.midPrice;
    const quoteUsdPrice = midPriceInTradeBase.multiply(baseUSDPrice);

    const quoteAmountUSD = quoteUsdPrice.quote(quoteAmount);

    return { [baseField]: baseAmountUSD, [quoteField]: quoteAmountUSD };
  }

  private get _priceInfo(): SwapInfo["price"] {
    if (this._isSwitchingTokens) {
      return {};
    }

    const trade = this._swapRoute?.trade as V2SwapRoute["trade"] | undefined;
    const route = this._swapRoute?.route as V2SwapRoute["route"] | undefined;

    const midPrice = route?.midPrice;
    const nextMidPrice = trade?.nextMidPrice;
    const executionPrice = trade?.executionPrice;

    // invert prices in case current prices are in trade base
    if (this._shouldInvertPrice) {
      return {
        midPrice: midPrice?.invert(),
        nextMidPrice: nextMidPrice?.invert(),
        executionPrice: executionPrice?.invert(),
      };
    }

    return { midPrice, nextMidPrice, executionPrice };
  }

  get info(): SwapInfo {
    const [amountIn, amountOut] = this._amounts;
    const amountsUsd = this._amountsUsd;
    const trade = this._swapRoute?.trade as V2SwapRoute["trade"] | undefined;
    const routeError = (this._swapRouteError ?? undefined) as V2SwapRouteError | undefined;
    const balances = this._balances;
    const priceInfo = this._priceInfo;

    return {
      [Field.INPUT]: {
        token: this._swap[Field.INPUT],
        amount: amountIn,
        balance: balances[Field.INPUT],
        usd: amountsUsd[Field.INPUT],
      },
      [Field.OUTPUT]: {
        token: this._swap[Field.OUTPUT],
        amount: amountOut,
        balance: balances[Field.OUTPUT],
        usd: amountsUsd[Field.OUTPUT],
      },
      trade: {
        trade,
        gasEstimateUSD: this._gasEstimateUSD,
        error: routeError,
      },
      price: priceInfo,
      impact: this._priceImpact,
      slippage: this._slippage,
    };
  }

  private _refreshGasPrice = async (options?: CacheOptions) => {
    await this._gasPriceProvider.getGasPrice(options);
  };

  private _refreshTokenPrice = async (options?: CacheOptions) => {
    await this._baseUSDPriceProvider.getBaseUSDPrice(options);
  };

  private _refreshNativeUSDPrice = async (options?: CacheOptions) => {
    await this._nativeUSDPriceProvider.getNativeUSDPrice(options);
  };

  private get _swapInitialized() {
    const path = this._swapPath;
    const router = this._router;
    if (!path || !router) return false;
    return true;
  }

  calculateInfo = async (options?: CacheOptions) => {
    if (!this._swapInitialized) return;
    try {
      await Promise.all([
        this._routerTrade(options),
        this._refreshGasPrice(options),
        this._refreshTokenPrice(options),
        this._refreshNativeUSDPrice(options),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
