import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import * as styles from "./style";
import { Switch } from "./Switch";

export interface SwitchesProps {
  label?: string;
  name?: string;
  selectedItem: string | number;
  items: { value: string | number; label: string | number }[];
  onChange: any;
  direction?: "row" | "column";
  onClickActive?: (value: string | number) => void;
  disabled?: boolean;
}

export const Switches = ({
  label,
  name,
  selectedItem,
  items,
  onChange,
  direction = "row",
  onClickActive = (value) => null,
  disabled,
  ...props
}: SwitchesProps) => {
  const id = useMemo(() => nanoid(3), []);

  const switchName = name || `switch-${id}`;

  return (
    <styles.WrapperForSwitch {...props}>
      {label ? <styles.SwitchLabel error={false}>{label}</styles.SwitchLabel> : null}

      <styles.WrapperForRadioItems style={{ flexDirection: direction }}>
        {items.map(({ label, value }) => (
          <Switch
            disabled={disabled}
            key={value}
            label={label}
            value={value}
            name={switchName}
            checked={selectedItem === value}
            onChange={onChange}
            onClick={() => value === selectedItem && onClickActive(value)}
          />
        ))}
      </styles.WrapperForRadioItems>
    </styles.WrapperForSwitch>
  );
};
