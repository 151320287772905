import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { FieldValues, Resolver } from "react-hook-form";
import { copyDataByKey, getDataByKey } from "src/helpers/forms/getByKey";
import { InferType, ObjectSchema } from "yup";

export const useYupValidationResolver = (
  validationSchema: ObjectSchema<FieldValues>
): Resolver<InferType<typeof validationSchema>> => {
  const [resolver] = useState(() => yupResolver(validationSchema));

  return useCallback(
    async (data: any, context, options: any) => {
      const result = await resolver(data, context, options);

      const { names } = options;

      const sliceResult: typeof result = { values: result.values, errors: {} };

      (names as string[]).forEach((path) => {
        const error = getDataByKey(result.errors, path);

        if (error !== undefined) copyDataByKey(sliceResult.errors, result.errors, path);
      });

      if (!Object.keys(sliceResult.errors).length) return { values: data, errors: {} };

      return sliceResult;
    },
    [resolver]
  );
};
