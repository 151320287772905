import { Currency, CurrencyAmount, TradeType } from "@uniswap/sdk-core";
import { BigNumber, providers } from "ethers";
import { invariant } from "mobx-utils";
import { IQuoterV2__factory } from "src/contracts/factories/uniswap/v3/IQuoterV2__factory";
import { IQuoterV2 } from "src/contracts/uniswap/v3/IQuoterV2";
import { logDev } from "src/helpers/network/logger";
import { ICache, MapCache } from "src/state/shared/Cache";
import { V3Route } from "../entities/V3Route";
import { QuotesError } from "../error/Error";

type GetQuoteExactSingleParams = Parameters<IQuoterV2["quoteExactInputSingle"]>[0];
type GetQuoteExactOutputSingleParams = Parameters<IQuoterV2["quoteExactOutputSingle"]>[0];

type GetQuoteParams =
  | (GetQuoteExactSingleParams & { method: "quoteExactInputSingle" })
  | (GetQuoteExactOutputSingleParams & { method: "quoteExactOutputSingle" });

export type V3QuoteResult = {
  amountOut: BigNumber;
  sqrtPriceX96After: BigNumber;
};

export interface IV3QuotesProvider {
  getQuote: <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ) => Promise<V3QuoteResult | null>;
}

export interface IQuoterV2AddressProvider {
  get quoterAddress(): string | null;
}

export interface IV3QuotesProviderParams {
  chainId: string;
  quoterAddressProvider: IQuoterV2AddressProvider;
  provider: providers.JsonRpcProvider;
}

export class V3QuotesProvider implements IV3QuotesProvider {
  private _provider: providers.JsonRpcProvider;

  private _chainId: string;

  private _quotersContractsCache: ICache<IQuoterV2>;

  private _quoterAddressProvider: IQuoterV2AddressProvider;

  constructor({ provider, chainId, quoterAddressProvider }: IV3QuotesProviderParams) {
    this._provider = provider;

    this._chainId = chainId;

    this._quoterAddressProvider = quoterAddressProvider;

    this._quotersContractsCache = new MapCache();
  }

  private _getQuotersCacheKey = (chainId: string, address: string) =>
    `quoter-${chainId}-${address}`;

  private get _quoterAddress() {
    return this._quoterAddressProvider.quoterAddress;
  }

  private _getQuoterContract = () => {
    const provider = this._provider;

    const quoterAddress = this._quoterAddress;

    if (!provider || !quoterAddress) {
      return null;
    }

    const contract = IQuoterV2__factory.connect(quoterAddress, provider);
    return contract;
  };

  private _getCachedQuoterContract = () => {
    const address = this._quoterAddress;

    if (!address) {
      return null;
    }

    const quoterKey = this._getQuotersCacheKey(this._chainId, address);

    const cachedContract = this._quotersContractsCache.get(quoterKey);
    if (cachedContract) {
      return cachedContract;
    }

    const contract = this._getQuoterContract();
    if (contract) {
      this._quotersContractsCache.set(quoterKey, contract);
    }
    return contract;
  };

  private _getQuoteParams = <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ): GetQuoteParams => {
    const quoteAmount = amount.quotient.toString();

    const quoteParams: GetQuoteParams = {
      tokenIn: route.tokenPath[0].address,
      tokenOut: route.tokenPath[1].address,
      fee: route.pools[0].fee,
      sqrtPriceLimitX96: 0,
      ...(tradeType === TradeType.EXACT_INPUT
        ? { amountIn: quoteAmount, method: "quoteExactInputSingle" }
        : { amount: quoteAmount, method: "quoteExactOutputSingle" }),
    };

    return quoteParams;
  };

  private _getQuote = async (
    quoter: IQuoterV2,
    quoteParams: GetQuoteParams
  ): Promise<V3QuoteResult> => {
    if (quoteParams.method === "quoteExactInputSingle") {
      const { method, ...params } = quoteParams;

      const [quote, sqrtPriceX96After] = await quoter.callStatic.quoteExactInputSingle(params);

      return { amountOut: quote, sqrtPriceX96After };
    }

    const { method, ...params } = quoteParams;

    const [quote, sqrtPriceX96After] = await quoter.callStatic.quoteExactOutputSingle(params);

    return { amountOut: quote, sqrtPriceX96After };
  };

  getQuote = async <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ) => {
    invariant(route.pools.length === 1, "Multi-hop quotes not supported");

    const quoterContract = this._getCachedQuoterContract();

    if (!quoterContract) {
      throw new QuotesError(`Quoter contract not found for the chain ${this._chainId}!`);
    }

    try {
      const quote = await this._getQuote(
        quoterContract,
        this._getQuoteParams(route, amount, tradeType)
      );

      logDev(["getQuote", quote]);

      return quote;
    } catch (err) {
      throw new QuotesError("Failed to get quote");
    }
  };
}
