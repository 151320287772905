import { makeAutoObservable } from "mobx";
import { ISwapModules } from "..";
import { EmptyPriceCalculator } from "../empty/EmptyPriceCalculator";
import { V3RouterProvider } from "./Providers/V3RouterProvider";
import V3SwapWidgetStore, { IV3SwapWidgetParams } from "./SwapWidget";

interface IV3SwapModulesParams extends Omit<IV3SwapWidgetParams, "routerProvider"> {}

export class V3SwapModulesStore implements ISwapModules {
  private _swapWidgetState;

  private _calculatorState = new EmptyPriceCalculator();

  private _routerProvider;

  constructor(params: IV3SwapModulesParams) {
    makeAutoObservable(this);

    this._routerProvider = new V3RouterProvider({
      chainProvider: params.chainInfoProvider.chainProvider,
      dexNameProvider: params.swapInfoProvider,
    });

    this._swapWidgetState = new V3SwapWidgetStore({
      ...params,
      routerProvider: this._routerProvider,
    });
  }

  get swapWidgetState() {
    return this._swapWidgetState;
  }

  get calculatorState() {
    return this._calculatorState;
  }

  get router() {
    return this._routerProvider.router;
  }

  destroy = () => {
    this._swapWidgetState.destroy();
    this._routerProvider.destroy();
  };
}
