import { Funding } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";
import {
  AggregatedFunding,
  AutoFunding,
  ManualFunding,
  TransfersFunding,
  NewManualFunding,
  UpdateFunding,
  PartyFundingRange,
} from "src/state/funding/CEX/types";
import { ApiModules, apiUrl } from "..";

/* CEX Funding */

export const getAggregatedFunding = async ({ party, from, to }: PartyFundingRange) =>
  ResponseHandler.handler<AggregatedFunding[]>({
    url: apiUrl(ApiModules.Party, `/${party}/funding?from=${from}&to=${to}`),
    method: "GET",
  });

export const getTransfersFunding = async ({ party, from, to }: PartyFundingRange) =>
  ResponseHandler.handler<TransfersFunding[]>({
    url: apiUrl(
      ApiModules.Party,
      `/${party}/funding/transfers?from=${from}&to=${to}&include-skipped=true`
    ),
    method: "GET",
  });

export const getManualFunding = async ({ party, from, to }: PartyFundingRange) =>
  ResponseHandler.handler<ManualFunding[]>({
    url: apiUrl(
      ApiModules.Party,
      `/${party}/funding/manual-transfers?from=${from}&to=${to}&include-skipped=true`
    ),
    method: "GET",
  });

export const getAutoFunding = async ({ party, from, to }: PartyFundingRange) =>
  ResponseHandler.handler<AutoFunding[]>({
    url: apiUrl(
      ApiModules.Party,
      `/${party}/funding/balance-transfers?from=${from}&to=${to}&include-skipped=true`
    ),
    method: "GET",
  });

export const addManualFunding = async (data: NewManualFunding) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Funding, "/manual-transfer"),
    method: "POST",
    data,
  });

export const updateManualFunding = async (fundingId: number, data: UpdateFunding) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Funding, `/manual-transfer/${fundingId}`),
    method: "PATCH",
    data,
  });

export const deleteManualFunding = async (fundingId: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Funding, `/manual-transfer/${fundingId}`),
    method: "DELETE",
  });

export const updateTransfersFunding = async (fundingId: number, data: UpdateFunding) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Funding, `/transfer/${fundingId}`),
    method: "PATCH",
    data,
  });

export const updateAutoFunding = async (fundingId: number, data: UpdateFunding) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Funding, `/balance-transfer/${fundingId}`),
    method: "PATCH",
    data,
  });

/* DEX Funding */

export const getAllDEXFundings = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${bot_uuid}/fundings`),
    method: "GET",
  });

export const addDEXFunding = async (data: Funding, bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${bot_uuid}/funding`),
    method: "POST",
    data: { funding: data },
  });

export const deleteDEXFunding = async (bot_uuid: string, id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${bot_uuid}/funding/${id}`),
    method: "DELETE",
  });

export const getAssets = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, "/assets"),
    method: "GET",
  });
