import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import { SettingsPanel } from "../SettingsPanel";
import { VerticWrapper, Wrapper } from "../shared";

export const PairSettings = observer(() => {
  const { createBotState: state } = useAppState();

  return (
    <SettingsPanel style={{ gridArea: "pair" }} title="Pair">
      <Wrapper>
        <VerticWrapper>
          <LabeledInput
            type="number"
            label="MinExchangeAmount"
            value={state.data.settings?.pair?.minExchangeAmount ?? ""}
            onChange={state.getHandler("settings.pair.minExchangeAmount")}
            errorHint={state.errors.settings?.pair?.minExchangeAmount}
            step="any"
          />
          <LabeledInput
            type="number"
            label="DontCheckAmount"
            value={state.data.settings?.pair?.dontCheckAmount ?? ""}
            onChange={state.getHandler("settings.pair.dontCheckAmount")}
            errorHint={state.errors.settings?.pair?.dontCheckAmount}
            step="any"
          />
        </VerticWrapper>

        <VerticWrapper>
          <LabeledInput
            type="number"
            label="DontTradePriceMax"
            value={state.data.settings?.pair?.dontTradePriceMax ?? ""}
            onChange={state.getHandler("settings.pair.dontTradePriceMax")}
            errorHint={state.errors.settings?.pair?.dontTradePriceMax}
            step="any"
          />
          <LabeledInput
            type="number"
            label="DontTradePriceMin"
            value={state.data.settings?.pair?.dontTradePriceMin ?? ""}
            onChange={state.getHandler("settings.pair.dontTradePriceMin")}
            errorHint={state.errors.settings?.pair?.dontTradePriceMin}
            step="any"
          />
        </VerticWrapper>
      </Wrapper>
    </SettingsPanel>
  );
});
