import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { computedFn } from "mobx-utils";
import { toast } from "react-toastify";
import { addUserGroup } from "src/api/userManager/userGroupsAPI";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { Disposable, Extends } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { GroupType, NewUserGroup, User } from "src/modules/userManager";
import { required, validateData } from "src/validation-schemas";

export const INITIAL_NEW_USER_GROUP: NewUserGroup = {
  name: "",
  type: "other",
  head: "",
};

type NewUserGroupKeys = FormDataKeys<NewUserGroup>;

type FormSelectors = Extends<NewUserGroupKeys, "type" | "head">;

export type SelectorHandler = (data: SelectorValue | null) => void;

export interface AddUserGroupProvider {
  getUserGroupsList: () => Promise<void>;
  usersByGroupType: (type: GroupType) => User[];
  groupTypesSelect: SelectorValue[];
}

export default class AddUserGroupStore implements Disposable {
  private _validation: FormValidation<NewUserGroup> = {
    name: required(),
    type: required(),
  };

  private _isShownReaction: IReactionDisposer;

  private _typeChangedReaction: IReactionDisposer;

  handlers: FormHandlers<NewUserGroup> = {};

  errors: FormErrors<NewUserGroup> = {};

  private _isLoading = false;

  newUserGroup: NewUserGroup = INITIAL_NEW_USER_GROUP;

  isShown: boolean = false;

  private _provider: AddUserGroupProvider;

  constructor(provider: AddUserGroupProvider) {
    this._provider = provider;

    makeAutoObservable(this, {
      selectorEnabled: false,
      selectorValue: false,
      selectorOptions: false,
    });

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );

    this._typeChangedReaction = reaction(
      () => this.newUserGroup.type,
      () => {
        this._setData("head", "");
      }
    );
  }

  setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  get isLoading() {
    return this._isLoading;
  }

  private _setIsModalShow(isShown: boolean) {
    this.isShown = isShown;
  }

  private _setModalShow = (isShown: boolean) => {
    this._setIsModalShow(isShown);
  };

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this.newUserGroup = INITIAL_NEW_USER_GROUP;
  };

  openModal = () => {
    this._setModalShow(true);
  };

  closeModal = () => {
    this._setModalShow(false);
  };

  getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "type": {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value) as GroupType);
        };
      }
      case "head": {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value));
        };
      }
    }
  };

  selectorEnabled = computedFn((key: Extends<FormSelectors, "head">) => {
    switch (key) {
      case "head": {
        return this.newUserGroup.type && this.selectorOptions("head").length;
      }
    }
  });

  selectorValue = computedFn((key: FormSelectors): SelectorValue => {
    const value = this._getData(key);
    return stringToSelectorValue(value);
  });

  selectorOptions = computedFn((key: FormSelectors): SelectorValue[] => {
    switch (key) {
      case "type": {
        return this._provider.groupTypesSelect;
      }
      case "head": {
        return getSelectorList(
          this._provider.usersByGroupType(this.newUserGroup.type).map((user) => user.name)
        );
      }
    }
  });

  private _setData = <K extends NewUserGroupKeys>(key: K, value: NewUserGroup[K]) => {
    const [path, endKey] = getPathAndKey(key);
    const targetData = getTargetValueByPath(this.newUserGroup, path);
    targetData[endKey] = value;
  };

  private _getData = <K extends NewUserGroupKeys>(key: K): NewUserGroup[K] => {
    const [path, endKey] = getPathAndKey(key);
    return getValueByPath(this.newUserGroup, path, endKey);
  };

  getHandler = (key: NewUserGroupKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newUserGroup, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.newUserGroup, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.setLoading(true);

      try {
        const { isError } = await addUserGroup(this.newUserGroup);

        if (!isError) {
          toast.success("User group created successfully", {
            autoClose: 2000,
          });
          this._setIsModalShow(false);
          this._provider.getUserGroupsList();
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  destroy() {
    this._isShownReaction();
    this._typeChangedReaction();
  }
}
