import { Input, InputOwnProps } from "src/components/shared/Forms/Inputs/Input";
import {
  FormField,
  FormFieldControlProps,
  FormFieldOuterProps,
  FormFieldProps,
} from "../../../FormField";

export type LabeledInputOwnProps = Omit<InputOwnProps, "id" | "error">;

export interface LabelInputCombinedProps {
  label: string;
  errorHint?: string;
}
export interface LabeledInputProps
  extends LabeledInputOwnProps,
    Pick<FormFieldProps, "label" | "errorHint">,
    Omit<FormFieldOuterProps, keyof LabeledInputOwnProps> {}

export const LabeledInput = ({
  after,
  inputRef,
  preventControlsEdit,
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  pattern,
  inputMode,
  step,
  placeholder,
  ...props
}: LabeledInputProps) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const FieldControl = ({ isError, id }: FormFieldControlProps) => (
    <Input
      id={id}
      error={isError}
      inputRef={inputRef}
      after={after}
      preventControlsEdit={preventControlsEdit}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      readOnly={readOnly}
      inputMode={inputMode}
      pattern={pattern}
      step={step}
      placeholder={placeholder}
    />
  );

  return <FormField control={FieldControl} {...props} />;
};
