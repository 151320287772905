import { makeAutoObservable, runInAction } from "mobx";
import {
  getDEXBots,
  getDEXClients,
  getDEXExchanges,
  getDEXRecentParties,
} from "src/api/bots/DEX/bots";
import { addDEXWatchList, removeDEXWatchList } from "src/api/shared/watchList";
import { getParties } from "src/api/userManager/partiesAPI";
import { BotStatusColors } from "src/components/Themes";
import { DEFAULT_FULL_TIME_FORMAT, getCurrentUnix, unixToUTCFormat } from "src/helpers/dateUtils";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { Comparators } from "src/helpers/sorting";
import { DEXBot, RequestRecentParty } from "src/modules/bots";
import {
  BotsListSelectorValue,
  IBotsFilter,
  IBotsSelectors,
  IRecentPartiesProvider,
} from "../CEX/CEXBots/CEXBots";
import RecentPartiesFilterStore, {
  IRecentPartiesFilter,
} from "../CEX/CEXBots/RecentPartiesFilterStore";
import RecentlyAddedFilterStore from "../CEX/CEXBots/RecentlyAddedFilterStore";

const BOTS_FETCHING_INTERVAL = 5000;

export interface ListDEXBot extends Omit<DEXBot, "lastTrade" | "nextTrade"> {
  colorStatus: BotStatusColors | "red";
  market: string;
  pair: string;
  lastTrade: string;
  nextTrade: string;
}

class DEXBotsState implements IRecentPartiesProvider, IBotsFilter, IBotsSelectors {
  private _bots: ListDEXBot[] = [];

  private _selectedBase: string[] = [];

  private _selectedSwaps: string[] = [];

  private _selectedParties: string[] = [];

  private _allBase: BotsListSelectorValue[] = [];

  private _allSwaps: BotsListSelectorValue[] = [];

  private _allParties: BotsListSelectorValue[] = [];

  private _intervalHandler?: ReturnType<typeof setInterval>;

  private _redSwitch = false;

  private _graySwitch = false;

  private _greenSwitch = false;

  private _yellowSwitch = false;

  private _redStatus = "red";

  private _grayStatus = "gray";

  private _greenStatus = "green";

  // yellow no status for this bot type
  private _yellowStatus = "yellow";

  private _recentlyAddedFilter: RecentlyAddedFilterStore;

  private _recentPartiesFilter: IRecentPartiesFilter;

  firstLoad = true;

  showLoader = false;

  watchListEnabled = localStorage.getItem("watchListEnabled") === "true";

  constructor() {
    this._recentlyAddedFilter = new RecentlyAddedFilterStore();
    this._recentPartiesFilter = new RecentPartiesFilterStore();

    makeAutoObservable(this);
  }

  chainsEnabled = false as const;

  dexVersionsEnabled = false as const;

  private _setBots(bots: DEXBot[]) {
    this._bots = bots.map((bot) => ({
      ...bot,
      colorStatus: this._getColorStatus(bot.nextTrade),
      market: `${bot.base}_${bot.quote}_${bot.exchange}`,
      pair: `${bot.base}_${bot.quote}`,
      lastTrade: bot?.lastTrade
        ? unixToUTCFormat(bot.lastTrade, DEFAULT_FULL_TIME_FORMAT)
        : "No trade",
      nextTrade: bot?.nextTrade
        ? unixToUTCFormat(bot.nextTrade, DEFAULT_FULL_TIME_FORMAT)
        : "No trade",
    }));
  }

  setSelectedBases = (options: readonly BotsListSelectorValue[]) => {
    this._selectedBase = options.map(({ value }) => value);
  };

  setSelectedExchanges = (options: readonly BotsListSelectorValue[]) => {
    this._selectedSwaps = options.map(({ value }) => value);
  };

  setSelectedParties = (options: readonly BotsListSelectorValue[]) => {
    this._selectedParties = options.map(({ value }) => value);
  };

  get selectedBase() {
    return getSelectorList(this._selectedBase);
  }

  get selectedExchanges() {
    return getSelectorList(this._selectedSwaps);
  }

  get selectedParties() {
    return getSelectorList(this._selectedParties);
  }

  get allBase() {
    return this._sortSelectorValue(this._allBase);
  }

  set allBase(allBase) {
    this._allBase = allBase;
  }

  get allExchanges() {
    return this._sortSelectorValue(this._allSwaps);
  }

  set allExchanges(allSwaps) {
    this._allSwaps = allSwaps;
  }

  get allParties() {
    return this._sortSelectorValue(this._allParties);
  }

  set allParties(allParties) {
    this._allParties = allParties;
  }

  private _sortSelectorValue = (values: BotsListSelectorValue[]) =>
    values.slice().sort((that, other) => Comparators.String(that.value, other.value));

  get bots() {
    return this._bots.filter(
      (el) =>
        this._baseFilter(el) &&
        this._swapsFilter(el) &&
        this._partiesFilter(el) &&
        this._watchListFilter(el) &&
        this._statusFilter(el) &&
        this._recentlyAddedFilter.switchableFilter(el) &&
        this._recentPartiesFilter.filter(el)
    );
  }

  get totalBotsCount(): number {
    return this._bots.length;
  }

  private get _redStatuses() {
    return this._bots.filter((el) => el.colorStatus === "red").length;
  }

  get redCount(): number {
    return this._redStatuses;
  }

  get redStatus(): boolean {
    return this._redSwitch;
  }

  private get _grayStatuses() {
    return this._bots.filter((el) => el.colorStatus === "gray").length;
  }

  get blueCount(): number {
    return this._grayStatuses;
  }

  get blueStatus(): boolean {
    return this._graySwitch;
  }

  private get _yellowStatuses() {
    return 0;
  }

  get yellowCount(): number {
    return this._yellowStatuses;
  }

  get yellowStatus(): boolean {
    return this._yellowSwitch;
  }

  get greenStatuses() {
    return this._bots.filter((el) => el.colorStatus === "green").length;
  }

  get recentlyAddedEnabled() {
    return this._recentlyAddedFilter.isEnabled;
  }

  get recentlyAddedCount() {
    return this._bots.filter(this._recentlyAddedFilter.filter).length;
  }

  get recentParties() {
    return this._recentPartiesFilter.recentParties;
  }

  togglePartySelection = (name: string) => this._recentPartiesFilter.togglePartySelection(name);

  toggleWatchList = () => {
    this.watchListEnabled = !this.watchListEnabled;
    localStorage.setItem("watchListEnabled", String(this.watchListEnabled));
  };

  removeBaseSelected = (item: string) => {
    this._selectedBase = this._selectedBase.filter((value) => value !== item);
  };

  removeExchSelected = (item: string) => {
    this._selectedSwaps = this._selectedSwaps.filter((value) => value !== item);
  };

  removePartiesSelected = (item: string) => {
    this._selectedParties = this._selectedParties.filter((value) => value !== item);
  };

  private _watchListFilter = ({ visible }: ListDEXBot) => !this.watchListEnabled || visible;

  toggleIsWatched = async (bot_uuid: string) => {
    this._setShowLoader(true);
    const botIndex = this._findBotIndex(bot_uuid);

    const { visible } = this._bots[botIndex];
    this._bots[botIndex].visible = !visible;

    try {
      if (visible) await removeDEXWatchList(bot_uuid);
      else await addDEXWatchList(bot_uuid);
    } catch {
      runInAction(() => {
        this._bots[botIndex].visible = !visible;
      });
    } finally {
      this._setShowLoader(false);
    }
  };

  private _findBotIndex = (bot_uuid: string) =>
    this._bots.findIndex((item) => item.swap_bot_uuid === bot_uuid);

  private _fetchAllBots = async () => {
    this._setShowLoader(true);

    try {
      const { isError: botsError, data: botsData } = await getDEXBots();
      const { isError: clientsError, data: clientsData } = await getDEXClients();
      const { isError: exchangesError, data: exchangesData } = await getDEXExchanges();
      const { data: allParties } = await getParties();
      await this._getRecentParties();

      runInAction(() => {
        if (!botsError) {
          const { swap_bots } = botsData;
          this._setBots(swap_bots?.length ? swap_bots : []);
        }

        if (!clientsError) {
          const { clients } = clientsData;
          this.allBase = clients?.map((el: string) => ({ label: el, value: el }));
        }

        if (!exchangesError) {
          const { exchanges } = exchangesData;
          this.allExchanges = exchanges?.map((el: string) => ({
            label: el,
            value: el,
          }));
        }

        this.allParties = getSelectorList(allParties || []);
        this.firstLoad = false;
      });
    } catch (error) {
      runInAction(() => {
        this.firstLoad = false;
      });
    } finally {
      this._setShowLoader(false);
    }
  };

  private _getRecentParties = async () => {
    try {
      const { isError, data } = await getDEXRecentParties();
      if (!isError) {
        const parties = data.parties as RequestRecentParty[];
        this._recentPartiesFilter.setRecentParties(parties || []);
      }
    } catch (err) {
      logError(err);
    }
  };

  resumeBotsFetching = () => {
    this._fetchAllBots();
    this._intervalHandler = setInterval(() => this._fetchAllBots(), BOTS_FETCHING_INTERVAL);
  };

  suspendBotsFetching = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  private _getColorStatus = (time: number) => {
    if (time === -1) {
      return "gray";
    }

    if (time + 60 < getCurrentUnix()) {
      return "red";
    }

    return "green";
  };

  private _baseFilter = ({ client }: ListDEXBot) =>
    this._selectedBase.length === 0 || this._selectedBase.includes(client);

  private _swapsFilter = ({ exchange }: ListDEXBot) =>
    this._selectedSwaps.length === 0 || this._selectedSwaps.includes(exchange);

  private _partiesFilter = ({ party }: ListDEXBot) =>
    this._selectedParties.length === 0 || this._selectedParties.includes(party);

  private _statusFilter = ({ colorStatus }: ListDEXBot) =>
    (!this._redSwitch && !this._graySwitch && !this._greenSwitch && !this._yellowSwitch) ||
    (this._redSwitch && colorStatus === this._redStatus) ||
    (this._graySwitch && colorStatus === this._grayStatus) ||
    (this._yellowSwitch && colorStatus === this._yellowStatus) ||
    (this._greenSwitch && colorStatus === this._greenStatus);

  toggleRedStatus = () => {
    this._redSwitch = !this._redSwitch;
  };

  private _toggleGrayStatus = () => {
    this._graySwitch = !this._graySwitch;
  };

  toggleBlueStatus = () => {
    this._toggleGrayStatus();
  };

  toggleGreenStatus = () => {
    this._greenSwitch = !this._greenSwitch;
  };

  toggleYellowStatus = () => {
    this._yellowSwitch = !this._yellowSwitch;
  };

  toggleRecentlyAddedEnabled = () => {
    this._recentlyAddedFilter.toggleEnabled();
  };

  private _setShowLoader = (bool: boolean) => {
    this.showLoader = bool;
  };
}

export default DEXBotsState;
