import { unix } from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column } from "react-table";
import { createStringNumberSortBy } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { DashboardFundingContext } from "src/context/CEX/Dashboard/Funding";
import { formatFiat } from "src/helpers/string";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FundingData, FundingDataKeys } from "src/state/CEX/CEXDashboard/FundingStore";
import { Card, CardProps } from "../shared/Card";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { setTableColumnWidth } from "../shared/StatsTable";
import * as styles from "./style";

const DEFAULT_COLUMN: Partial<Column<FundingData>> = {
  ...setTableColumnWidth(60),
};

const FUNDING_COLUMNS_TITLES: Record<FundingDataKeys, string> = {
  type: "Type",
  amount: "Amount",
  account: "Account",
  currency: "Currency",
  exchange: "Exchange",
  time: "Time",
};

const getColumnTitleById = (id: FundingDataKeys) => FUNDING_COLUMNS_TITLES[id];

const DEFAULT_FUNDING_TIME_FORMAT = "DD.MM, HH:mm";

const formatFundingTime = (time: number) => unix(time).format(DEFAULT_FUNDING_TIME_FORMAT);

export interface FundingTableProps extends CardProps {}

export const FundingTable = observer((props: FundingTableProps) => {
  const state = useLateInitContext(DashboardFundingContext.Context);

  const { data, getFundings } = state;

  useEffect(() => {
    getFundings();
  }, [getFundings]);

  const columns = useMemo(
    (): Column<FundingData>[] => [
      {
        Header: getColumnTitleById("type"),
        accessor: "type",
      },
      {
        Header: getColumnTitleById("amount"),
        accessor: "amount",
        Cell: ({ value }) => <>{formatFiat(value, false)}</>,
        sortType: createStringNumberSortBy(),
      },
      { Header: getColumnTitleById("currency"), accessor: "currency" },
      { Header: getColumnTitleById("exchange"), accessor: "exchange" },
      { Header: getColumnTitleById("account"), accessor: "account" },
      {
        Header: getColumnTitleById("time"),
        accessor: "time",
        Cell: ({ value }) => <>{formatFundingTime(value)}</>,
        ...setTableColumnWidth(70),
      },
    ],
    []
  );

  return (
    <>
      <RefreshDataButton onRefresh={state.getFundings} />
      <Card title="Funding" {...props}>
        <styles.StyledFundingTable data={data} columns={columns} defaultColumn={DEFAULT_COLUMN} />
      </Card>
    </>
  );
});
