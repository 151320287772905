import { makeAutoObservable, when } from "mobx";
import { showSuccessMsg } from "src/helpers/message";
import { toast } from "react-toastify";
import ExchangeStore from "..";
import CallBlockerStore from "./CallBlocker";

const STORAGE_KEY = "terminalSettings";

export type TerminalRequestMode = "WS" | "FETCH";

export interface IExchangeStore {
  pair: string;
  requestMode: TerminalRequestMode;
  exchange: string;
  accountUUID?: string;
}

interface StreamSupportModule {
  requestMode: TerminalRequestMode;
}

export interface ItvChartConfig extends StreamSupportModule {}

export interface IOrderBookConfig extends StreamSupportModule {}

export interface TradeHistoryConfig extends StreamSupportModule {}

export interface ISettingsConfig {
  tvChartModule: ItvChartConfig;
  orderBookModule: IOrderBookConfig;
  tradeHistoryModule: TradeHistoryConfig;
  depthChartModule: {};
}

export type IBlockerManager = Record<StreamSupportModuleKeys, CallBlockerStore>;

export type ExchangesSettingsMap = Record<string, ISettingsConfig>;

export type ISettingsConfigMap = Record<string, ExchangesSettingsMap>;

type StreamSupportModuleKeys = keyof Omit<ISettingsConfig, "depthChartModule">;

const STREAM_SUP_MODULES: StreamSupportModuleKeys[] = [
  "orderBookModule",
  "tradeHistoryModule",
  "tvChartModule",
];

const DEFAULT_SETTINGS_CONFIG: ISettingsConfig = {
  tvChartModule: {
    requestMode: "FETCH",
  },
  orderBookModule: {
    requestMode: "FETCH",
  },
  tradeHistoryModule: {
    requestMode: "FETCH",
  },
  depthChartModule: {},
};

class TerminalSettingsStore {
  mainState: ExchangeStore;

  private _blockerManager: IBlockerManager = {
    orderBookModule: new CallBlockerStore(),
    tradeHistoryModule: new CallBlockerStore(),
    tvChartModule: new CallBlockerStore(),
  };

  settingsConfig: ISettingsConfig = {
    tvChartModule: {
      requestMode: "FETCH",
    },
    orderBookModule: {
      requestMode: "FETCH",
    },
    tradeHistoryModule: {
      requestMode: "FETCH",
    },
    depthChartModule: {},
  };

  private _partiesSettings: ISettingsConfigMap = {};

  constructor(state: ExchangeStore) {
    makeAutoObservable(this);

    this.mainState = state;

    when(
      () => this._isBotInfoReceived,
      () => this._getDefaultSettings()
    );
  }

  private get _isBotInfoReceived() {
    return !!this.mainState.party && !!this.mainState.exchange;
  }

  get wsModeSupport() {
    return this.mainState.wsSupport;
  }

  private _getDefaultSettings = () => {
    const storedItems = localStorage.getItem(STORAGE_KEY);

    if (storedItems) {
      const configs = JSON.parse(storedItems);

      if (configs) {
        this._partiesSettings = configs;
        this._setDefaultSettings();
      }
    }
  };

  private _setDefaultSettings = () => {
    const partiesConfigs = this._partiesSettings[this.mainState.party];

    if (partiesConfigs) {
      const savedConfig = partiesConfigs[this.mainState.exchange];

      if (savedConfig) {
        this.settingsConfig = savedConfig;
      }
    }
  };

  toggleRequestMode = (module: StreamSupportModuleKeys, mode: TerminalRequestMode) => {
    const settingsModule = this.settingsConfig[module];
    const blocker = this._blockerManager[module];
    const prevState = settingsModule.requestMode;

    this.settingsConfig[module].requestMode = mode;

    if (mode === "WS") {
      if (blocker.isBlocked) {
        settingsModule.requestMode = prevState;

        toast.error(`Action will be available in ${blocker.remainingTime} seconds`);

        return;
      }

      blocker.blockFunctionCall(15000);
    }

    this.mainState.updAllData();

    showSuccessMsg(`${this.settingsConfig[module].requestMode} mode switched successfully`);
  };

  lockWSMode = () => {
    STREAM_SUP_MODULES.forEach((moduleName) => {
      this.settingsConfig[moduleName].requestMode = "FETCH";
    });
  };

  private _serializeSettings() {
    return JSON.stringify(this._partiesSettings);
  }

  private _setExchConfig = (config: ISettingsConfig) => {
    const exchConfigsMap = this._partiesSettings[this.mainState.party];

    if (exchConfigsMap) {
      exchConfigsMap[this.mainState.exchange] = config;
    } else {
      this._partiesSettings[this.mainState.party] = {
        [this.mainState.exchange]: config,
      };
    }
  };

  saveSettings = () => {
    this._setExchConfig(this.settingsConfig);

    localStorage.setItem(STORAGE_KEY, this._serializeSettings());

    showSuccessMsg(`Terminal settings for ${this.mainState.exchange} saved successfully`);
  };

  resetSettings = () => {
    this.settingsConfig = DEFAULT_SETTINGS_CONFIG;

    this.mainState.updAllData();
  };
}

export default TerminalSettingsStore;
