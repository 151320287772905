import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { FundingAbilitiesContext, FundingListContext } from "src/context/CEX/FundingProvider";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useAppState from "src/state";
import { TableVirtualRows } from "../../TableVirtualRows";
import { getColumnsForTab } from "./columns";

export const FundingTable = observer(() => {
  const state = useLateInitContext(FundingListContext);
  const { abilityName } = useLateInitContext(FundingAbilitiesContext);
  const { userState } = useAppState();

  const checkPermsn = checkParty(userState.abilities, abilityName, state.party);
  const columns = useMemo(() => getColumnsForTab(state, checkPermsn), [checkPermsn, state]);

  useEffect(() => {
    if (state.party) state.loadData();
  }, [state, state.party, state.activeFundingMenu]);

  return (
    <TableVirtualRows
      party="Demo"
      data={state.tableData}
      columns={columns}
      rowSize={30}
      loader={state.isLoading}
    />
  );
});
