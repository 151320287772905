import { ComponentPropsWithoutRef, useCallback, useMemo, useRef, useState } from "react";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { useId } from "src/hooks/useId";
import useResizeObserver, { UseResizeObserverCallback } from "src/hooks/useResizeObserver";
import { PartyExchange } from "src/state/CEX/CEXDashboard/KPIStore";
import * as styles from "./style";

interface UseTextOverflowProps {
  items: string[];
}

const RATIO_TOLERANCE = 0.01;

export const useTextOverflow = <T extends HTMLElement>({ items }: UseTextOverflowProps) => {
  const textRef = useRef<T>(null);

  const [overflowRatio, setOverflowRatio] = useState(1);

  const textLengthSums = useMemo(() => {
    const textLengths: number[] = [];
    items.forEach((str, index) => {
      const newSum = (textLengths[index - 1] ?? 0) + str.length;
      textLengths[index] = newSum;
    });
    return textLengths;
  }, [items]);

  const displayedItemsCount = useMemo(() => {
    // overflowRatio close to 1 or > => we have enough space to display all items
    if (overflowRatio + RATIO_TOLERANCE >= 1) {
      return items.length;
    }

    let currentOverflowRatio = 0;
    let itemInd = 0;

    const textLength = textLengthSums[textLengthSums.length - 1];

    while (currentOverflowRatio < overflowRatio && itemInd < items.length) {
      // eslint-disable-next-line no-plusplus
      const displayItemsLength = textLengthSums[itemInd++];
      currentOverflowRatio = roundToTwoDecimals(displayItemsLength / textLength);
    }

    // index optimistically increased in last iteration
    const itemsCount = Math.max(0, itemInd - 1);
    return itemsCount;
  }, [items.length, overflowRatio, textLengthSums]);

  const overflowItemsCount = items.length - displayedItemsCount;

  const updateOverflowRatio: UseResizeObserverCallback = useCallback((textEntry) => {
    const textElement = textRef.current;
    if (textElement && textEntry.contentRect) {
      const contentWidth = textEntry.contentRect.width;
      const overflowWidth = textElement.scrollWidth;
      setOverflowRatio(roundToTwoDecimals(contentWidth / overflowWidth));
    }
  }, []);

  useResizeObserver(textRef, updateOverflowRatio);

  return { textRef, overflowItemsCount };
};

export interface ExchangesListItemProps extends ComponentPropsWithoutRef<"li"> {
  exchange: PartyExchange;
  tokens: string[];
}

const TOKENS_TOOLTIP_ID = "tokens-tooltip";

export const ExchangesListItem = ({ exchange, tokens, ...props }: ExchangesListItemProps) => {
  const { textRef, overflowItemsCount } = useTextOverflow<HTMLParagraphElement>({ items: tokens });

  const id = useId();
  const tooltipId = `${TOKENS_TOOLTIP_ID}-${id}`;

  const tokensText = useMemo(() => tokens.join(", "), [tokens]);

  return (
    <styles.Container {...props}>
      <styles.ExchangeText>{exchange.name}: </styles.ExchangeText>
      <styles.TokensTextWrapper data-tooltip-id={tooltipId}>
        <styles.TokensText ref={textRef}>{tokensText}</styles.TokensText>
        {overflowItemsCount > 0 && <styles.CounterText>+{overflowItemsCount}</styles.CounterText>}
      </styles.TokensTextWrapper>

      <styles.CounterTooltip id={tooltipId}>
        <styles.CounterTooltipText>{tokensText}</styles.CounterTooltipText>
      </styles.CounterTooltip>
    </styles.Container>
  );
};
