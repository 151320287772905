import { makeAutoObservable, reaction } from "mobx";
import WebSocketStore from "../network/WebSocketHandler";
import UserState from "../User";

class UserConnection {
  webSocketState;

  _userState;

  constructor(userState: UserState) {
    makeAutoObservable(this);

    this._userState = userState;
    this.webSocketState = new WebSocketStore({});

    reaction(
      () => this._userState.isLoggedIn,
      (isLoggedIn) => {
        this._loggedReaction(isLoggedIn);
      },
      { fireImmediately: true }
    );
  }

  get connectStatus() {
    return this.webSocketState.statusConnection;
  }

  get serverDelay() {
    return this.webSocketState.sendDelayTitle;
  }

  private _loggedReaction(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.webSocketState.setupWebSocket({ url: "" });
    } else {
      this.webSocketState.closeWebSocket();
    }
  }
}

export default UserConnection;
