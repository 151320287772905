import { Dayjs } from "dayjs";
import { IReactionDisposer, makeAutoObservable, reaction, when } from "mobx";
import { computedFn } from "mobx-utils";
import {
  DashboardQueryParams,
  DashboardRangeParams,
  getPartiesMetrics,
} from "src/api/bots/CEX/dashboard";
import { getShortPartyAccounts } from "src/api/userManager/partiesAPI";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectPanelProps } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { getCurrentDayjs, getDayjsFromUnix } from "src/helpers/dateUtils";
import { getSelectorList } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { joinStrings } from "src/helpers/string";
import { Disposable, Nullish } from "src/helpers/utils";
import { StringSelectorValue } from "src/modules/shared";
import { Account } from "src/modules/userManager";
import RangePickerStore, { IUseRangePicker } from "src/state/shared/RangePicker";
import ExchangesStore from "./ExchangesStore";
import KPIStore from "./KPIStore";
import LiquidityStore from "./LiquidityStore";
import OldBalancesStore from "./OldBalancesStore";
import PNLStore from "./PNLStore";
import VolumeStore from "./VolumeStore";

export type AccountsMap = Record<string, Account[]>;

export interface CEXDashboardBotParams {
  uuid: string;
  party: string;
}

type DashboardSelectors = "account" | "exchange";

export type DashboardView = "detailed" | "summary" | "balance-summary";

const getMinLimitRange = (min: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (end.isSameOrBefore(min, "second")) {
    return null;
  }

  const isMinInRange = min.isBetween(start, end, "second", "[)");

  return isMinInRange ? [min, end] : range;
};

const getMaxLimitRange = (max: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (start.isSameOrAfter(max, "second")) {
    return null;
  }

  const isMaxInRange = max.isBetween(start, end, "second", "(]");

  return isMaxInRange ? [start, max] : range;
};

export interface IDashboardStateProvider {
  get botParams(): CEXDashboardBotParams;
  get selectedExchanges(): string[];

  get queryRangeParams(): DashboardRangeParams | null;
  get queryParams(): DashboardQueryParams | null;

  get selectedRange(): DateTimeRange;
  get previousMonth(): DateTimeRange | null;
  get currentMonth(): DateTimeRange | null;

  getInitialData: () => Promise<void>;
}

export interface IStatsFetcher {
  getStats: () => Promise<void>;
}

export type StatsStores = {
  kpi: KPIStore;
  pnl: PNLStore;
  balances: OldBalancesStore;
  exchanges: ExchangesStore;
  liquidity: LiquidityStore;
  volume: VolumeStore;
};

export type StatsStoresKeys = keyof StatsStores;

export const getDashboardRangeQueryParams = (
  range: DateTimeRange | null
): DashboardRangeParams | null => {
  if (!range) return null;

  const [start, end] = range;
  if (!start || !end) return null;
  return { from: `${start.unix()}`, to: `${end.unix()}` };
};

export const getDashboardQueryParams = (
  rangeParams: DashboardRangeParams | null,
  exchanges: string[]
): DashboardQueryParams | null => {
  if (!rangeParams) return null;
  return {
    ...rangeParams,
    exchanges: exchanges.length ? joinStrings(exchanges, ",") : undefined,
  };
};

export interface IBaseStatsStoreParams {
  stateProvider: IDashboardStateProvider;
}

export default class CEXDashboardStore
  implements Disposable, IUseRangePicker, IDashboardStateProvider
{
  private _loading = false;

  private _initialLoading = false;

  private _botParams: CEXDashboardBotParams = {
    party: "",
    uuid: "",
  };

  private _currentView: DashboardView = "summary";

  private _exchanges: string[] = [];

  private _accounts: Account[] = [];

  private _selectedExchanges: string[] = [];

  private _selectedAccounts: Account[] = [];

  private _rangePickerState: RangePickerStore;

  private _statsStoresMap: StatsStores;

  private _initialStatsFetchReaction: IReactionDisposer;

  private _exchangesSelectionReaction: IReactionDisposer;

  private _workingSince: number | null = null;

  constructor() {
    makeAutoObservable<this, "_statsStoresMap" | "_currentMonth">(this, {
      selectorValue: false,
      selectorOptions: false,
      selectorProps: false,
      _statsStoresMap: false,
      _currentMonth: false,
    });

    const providerParams = { stateProvider: this };

    const kpiStore = new KPIStore(providerParams);
    const pnlStore = new PNLStore(providerParams);
    const oldBalancesStore = new OldBalancesStore(providerParams);
    const exchangesStore = new ExchangesStore(providerParams);
    const liquidityStore = new LiquidityStore(providerParams);
    const volumeStore = new VolumeStore(providerParams);

    this._statsStoresMap = {
      kpi: kpiStore,
      pnl: pnlStore,
      balances: oldBalancesStore,
      exchanges: exchangesStore,
      liquidity: liquidityStore,
      volume: volumeStore,
    };

    this._rangePickerState = new RangePickerStore(this, undefined);

    this._initialStatsFetchReaction = when(
      () => this._initialLoadReady,
      () => {
        this._getInitialStats();
      }
    );

    this._exchangesSelectionReaction = reaction(
      () => this._selectedExchanges.slice(),
      () => {
        this._updateStats();
      }
    );

    makeLoggable<any>(this, {
      _accounts: true,
      selectedRange: true,
      loading: true,
      _botParams: true,
      previousMonth: true,
      currentMonth: true,
    });
  }

  private get _fetchDepsReady() {
    const { party, uuid } = this._botParams;
    const workingSince = this._workingSince;

    return Boolean(party && uuid && workingSince);
  }

  private get _initialLoadReady() {
    return this._fetchDepsReady;
  }

  get statsStoresMap() {
    return this._statsStoresMap;
  }

  private get _statsStores(): Array<IStatsFetcher & Disposable> {
    return Object.values(this._statsStoresMap);
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get initialLoading() {
    return this._initialLoading;
  }

  private _setInitialLoading = (loading: boolean) => {
    this._initialLoading = loading;
  };

  setBotParams = (botParams: CEXDashboardBotParams) => {
    this._botParams = botParams;
  };

  get botParams() {
    return this._botParams;
  }

  get party() {
    return this.botParams.party;
  }

  private _setWorkingSince = (workingSince: number | null) => {
    this._workingSince = workingSince;
  };

  get selectedRange() {
    return this._rangePickerState.range;
  }

  get queryRangeParams(): DashboardRangeParams | null {
    const currentRange = this.selectedRange;
    return getDashboardRangeQueryParams(currentRange);
  }

  get queryParams(): DashboardQueryParams | null {
    const rangeParams = this.queryRangeParams;
    const exchanges = this._selectedExchanges;
    return getDashboardQueryParams(rangeParams, exchanges);
  }

  get minDate() {
    const workingSince = this._workingSince;
    return workingSince ? getDayjsFromUnix(workingSince) : undefined;
  }

  setRange = (range: DateTimeRange) => {
    this._rangePickerState.setRange(range);
  };

  private get _currentSelectedEndDate() {
    const [, endDate] = this.selectedRange;
    return endDate;
  }

  private _getMinMaxMonthRange = (range: DateTimeRange): DateTimeRange | null => {
    const now = getCurrentDayjs();

    const maxLimitRange = getMaxLimitRange(now, range);

    if (!maxLimitRange) return null;

    const min = this.minDate;

    if (!min) return maxLimitRange;

    const minMaxLimitRange = getMinLimitRange(min, maxLimitRange);

    return minMaxLimitRange;
  };

  private _getMonthRange = (
    monthOffset: number,
    currentDate: Nullish<Dayjs> = this._currentSelectedEndDate
  ): DateTimeRange | null => {
    if (!currentDate) {
      return null;
    }

    const targetMonthFirstDay = currentDate.startOf("month").add(monthOffset, "month");

    const targetMonthLastDay = targetMonthFirstDay.endOf("month");

    const targetRange = this._getMinMaxMonthRange([targetMonthFirstDay, targetMonthLastDay]);

    return targetRange;
  };

  private get _previousMonth() {
    return this._getMonthRange(-1);
  }

  get previousMonth() {
    return this._previousMonth;
  }

  onPreviousMonth = () => {
    const previousMonth = this._previousMonth;
    if (previousMonth) {
      this.setRange(previousMonth);
    }
  };

  get previousMonthDisabled() {
    const previousMonth = this._previousMonth;
    return !previousMonth;
  }

  private get _currentMonth() {
    return this._getMonthRange(0, getCurrentDayjs());
  }

  get currentMonth() {
    return this._currentMonth;
  }

  onCurrentMonth = () => {
    const currentMonth = this._currentMonth;
    if (currentMonth) {
      this.setRange(currentMonth);
    }
  };

  private get _nextMonth() {
    return this._getMonthRange(1);
  }

  onNextMonth = () => {
    const nextMonth = this._nextMonth;
    if (nextMonth) {
      this.setRange(nextMonth);
    }
  };

  get nextMonthDisabled() {
    const nextMonth = this._nextMonth;
    return !nextMonth;
  }

  get currentView() {
    return this._currentView;
  }

  setCurrentView = (view: string) => {
    this._currentView = view as DashboardView;
  };

  private _setExchanges = (exchanges: string[]) => {
    this._exchanges = exchanges;
  };

  private _setAccounts = (accounts: Account[]) => {
    this._accounts = accounts;
  };

  private get _accountsOptions() {
    const selectedExchanges = this._selectedExchanges;

    return this._accounts
      .filter(({ exchange }) => selectedExchanges.includes(exchange))
      .map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      }));
  }

  private _onAccountSelected = (values: readonly StringSelectorValue[]) => {
    const newAccountsIds = values.map(({ value }) => String(value));

    this._selectedAccounts = this._accounts.filter(({ uuid }) => newAccountsIds.includes(uuid));
  };

  get selectedExchanges() {
    return this._selectedExchanges;
  }

  private get _selectedAccountsValue() {
    return this._selectedAccounts.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));
  }

  private get _exchangesOptions() {
    return getSelectorList(this._exchanges);
  }

  private _onExchangeSelected = (values: readonly StringSelectorValue[]) => {
    const newExchanges = values.map(({ value }) => String(value));
    this._selectedAccounts = this._accounts.filter(({ exchange }) =>
      newExchanges.includes(exchange)
    );

    this._selectedExchanges = newExchanges;
  };

  private get _selectedExchangesValue() {
    return getSelectorList(this._selectedExchanges);
  }

  selectorValue = computedFn((key: DashboardSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._selectedExchangesValue;
      }
      case "account": {
        return this._selectedAccountsValue;
      }
    }
  });

  selectorOnChange = (
    key: DashboardSelectors
  ): ((data: readonly StringSelectorValue[]) => void) => {
    switch (key) {
      case "exchange": {
        return this._onExchangeSelected;
      }
      case "account": {
        return this._onAccountSelected;
      }
    }
  };

  selectorOptions = computedFn((key: DashboardSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._exchangesOptions;
      }
      case "account": {
        return this._accountsOptions;
      }
    }
  });

  selectorProps = (
    key: DashboardSelectors
  ): Pick<SelectorProps<StringSelectorValue, true, any>, "options" | "value" | "onChange"> => ({
    options: this.selectorOptions(key),
    value: this.selectorValue(key),
    onChange: this.selectorOnChange(key),
  });

  private _removeSelectedExchange = (exchange: string) => {
    const newExchanges = this._selectedExchanges.filter(
      (currentExchange) => currentExchange !== exchange
    );
    this._selectedExchanges = newExchanges;
    this._selectedAccounts = this._selectedAccounts.filter(({ exchange }) =>
      newExchanges.includes(exchange)
    );
  };

  private _removeSelectedAccount = (accountId: string) => {
    this._selectedAccounts = this._selectedAccounts.filter(({ uuid }) => uuid !== accountId);
  };

  onRemoveSelect = (key: DashboardSelectors): ((value: string) => void) => {
    switch (key) {
      case "exchange": {
        return this._removeSelectedExchange;
      }
      case "account": {
        return this._removeSelectedAccount;
      }
    }
  };

  selectProps = (
    key: DashboardSelectors
  ): Pick<SelectPanelProps, "selectItems" | "removeClick"> => ({
    selectItems: this.selectorValue(key),
    removeClick: this.onRemoveSelect(key),
  });

  private _getExchangesAccounts = async () => {
    try {
      const { data, isError } = await getShortPartyAccounts(this._botParams.party);

      if (!isError) {
        const exchanges = Object.keys(data);
        this._setExchanges(exchanges);

        const accounts = Object.values(data).flatMap((it) => it);
        this._setAccounts(accounts);
      }
    } catch {
      this._setExchanges([]);
      this._setAccounts([]);
    }
  };

  private _getWorkingSince = async () => {
    const { party } = this;
    if (!party) return null;

    try {
      const { isError, data } = await getPartiesMetrics(party);
      if (!isError) {
        this._setWorkingSince(data.created);
      }
    } catch {
      this._setWorkingSince(null);
    }
  };

  getInitialData = async () => {
    try {
      this._setInitialLoading(true);
      await Promise.all([this._getExchangesAccounts(), this._getWorkingSince()]);
    } finally {
      this._setInitialLoading(false);
    }
  };

  private _getInitialStats = async () => {
    // auto refresh happens on new range
    const currentMonth = this._currentMonth!;
    this.setRange(currentMonth);
  };

  private _updateStats = async () => {
    if (!this._fetchDepsReady) return;

    this._setLoading(true);
    try {
      await Promise.all(this._statsStores.map((store) => store.getStats()));
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  loadData = () => {
    this._updateStats();
  };

  destroy = () => {
    this._initialStatsFetchReaction();
    this._exchangesSelectionReaction();
    this._statsStores.forEach((store) => store.destroy());
  };
}
