import { ITimeScaleApi, UTCTimestamp } from "lightweight-charts";
import { makeAutoObservable } from "mobx";
import { GraphDataRequest } from "src/components/shared/Graph";
import { getUTCRange, localTimestampToUTC } from "src/helpers/dateUtils";
import { Disposable } from "src/helpers/utils";

type Position = {
  x: number;
  y: number;
};

const INITIAL_POSITION: Position = {
  x: 0,
  y: 0,
};

type PointFields = "startPoint" | "endPoint" | "startTimePoint" | "endTimePoint";

export interface ChartScaleConfig {
  selectionColor?: string;
  minRefreshInterval?: number;
}

export default class ChartScaleStore implements Disposable {
  startPoint: number | null = null;

  endPoint: number | null = null;

  startTimePoint: number | null = null;

  endTimePoint: number | null = null;

  viewPortPosition: Position = INITIAL_POSITION;

  private _gradientBackgroundColor!: string;

  private _gradientSelectionColor!: string;

  private _minRefreshInterval!: number;

  constructor(config: ChartScaleConfig = {}) {
    this._initConfig(config);

    makeAutoObservable(this);
  }

  private _initConfig = ({ selectionColor, minRefreshInterval }: ChartScaleConfig) => {
    this._gradientBackgroundColor = "rgba(101, 145, 240, 0)";
    this._gradientSelectionColor = selectionColor ?? "rgba(101, 145, 240, 0.1)";
    this._minRefreshInterval = minRefreshInterval ?? 500;
  };

  private _setPoint = (field: PointFields, value: number | null) => {
    this[field] = value;
  };

  findCanvasViewPortPosition = (obj: HTMLElement | null) => {
    let curLeft = 0;
    let curTop = 0;
    if (obj?.offsetParent) {
      do {
        curLeft += obj.offsetLeft;
        curTop += obj.offsetTop;
        // eslint-disable-next-line no-cond-assign, no-param-reassign
      } while ((obj = obj.offsetParent as HTMLElement));
      this.viewPortPosition = { x: curLeft, y: curTop };
    } else this.viewPortPosition = INITIAL_POSITION;
  };

  private _coordinateInCanvas = (coord: number, type: keyof Position) =>
    coord - this.viewPortPosition[type];

  private _canvasXToUTC = (canvasX: number, timeScale: ITimeScaleApi | undefined) => {
    const coordinateTime = (timeScale?.coordinateToTime(canvasX) ?? 0) as UTCTimestamp;
    // the "lightweight-charts" library has no built-in time conversion mechanisms,
    // so the time is converted to a local format before the chart is drawn,
    // then inside the library this date is converted to the Timestamp format,
    //  which causes inaccuracy when getting the date via the library API (in GMT size)
    const utcTimestamp = localTimestampToUTC(coordinateTime ?? 0);
    return utcTimestamp;
  };

  setStartPoint = (viewportX: number, timeScale: ITimeScaleApi | undefined) => {
    const canvasX = this._coordinateInCanvas(viewportX, "x");
    this._setPoint("startPoint", canvasX);
    const utcTimestamp = this._canvasXToUTC(canvasX, timeScale);
    this._setPoint("startTimePoint", utcTimestamp);
  };

  private _refreshData = (request: GraphDataRequest, startPoint: number, endPoint: number) => {
    const range = getUTCRange(startPoint, endPoint);

    const start = range[0];
    const end = range[1];

    if (request) {
      if (end.isAfter(start)) {
        request([start, end]);
      } else request([end, start]);
    }
  };

  setEndPoint = (
    viewportX: number,
    timeScale: ITimeScaleApi | undefined,
    request: GraphDataRequest,
    canvas: HTMLCanvasElement
  ) => {
    const canvasX = this._coordinateInCanvas(viewportX, "x");
    this._setPoint("endPoint", canvasX);

    const utcTimestamp = this._canvasXToUTC(canvasX, timeScale);
    this._setPoint("endTimePoint", utcTimestamp);

    if (
      this.startTimePoint &&
      this.endTimePoint &&
      Math.abs(this.startTimePoint - this.endTimePoint) >= this._minRefreshInterval
    ) {
      this._refreshData(request, this.startTimePoint, this.endTimePoint);
    }
    //  else {
    //   this.resetCanvas(canvas);
    // }

    this.resetCanvas(canvas);

    this.resetPoints();
  };

  paintOverScale = (viewportX: number, canvas: HTMLCanvasElement) => {
    const canvasX = this._coordinateInCanvas(viewportX, "x");
    this._setPoint("endPoint", canvasX);

    if (this.startPoint !== null) {
      this.drawGradient(canvas);
    }
  };

  private _getGradientSelectionPoints = (width: number): [number, number] => {
    const startPoint = this.startPoint ?? 0;
    const endPoint = this.endPoint ?? 0;

    if (startPoint > endPoint) {
      const selectionStart = endPoint / width;
      const selectionEnd = startPoint / width;
      return [selectionStart, selectionEnd];
    }
    const selectionStart = startPoint / width;
    const selectionEnd = endPoint / width;
    return [selectionStart, selectionEnd];
  };

  private _drawGradient = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    this._resetCanvas(canvas, ctx);

    const width = canvas.clientWidth;

    ctx.globalCompositeOperation = "copy";

    ctx.rect(0, 0, canvas.width, canvas.height);
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

    ctx.fillStyle = gradient;
    // ctx.fillRect(0, 0, canvas.width, canvas.height);

    const [selectionStart, selectionEnd] = this._getGradientSelectionPoints(width);

    gradient.addColorStop(0, this._gradientBackgroundColor);
    gradient.addColorStop(selectionStart, this._gradientBackgroundColor);

    gradient.addColorStop(selectionStart, this._gradientSelectionColor);
    gradient.addColorStop(selectionEnd, this._gradientSelectionColor);

    gradient.addColorStop(selectionEnd, this._gradientBackgroundColor);
    gradient.addColorStop(1, this._gradientBackgroundColor);

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fill();
  };

  drawGradient = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.save();

    this._drawGradient(canvas, ctx);

    ctx.restore();
  };

  private _resetCanvas = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  resetPoints = () => {
    this._setPoint("endPoint", null);
    this._setPoint("startPoint", null);
  };

  resetCanvas = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    this._resetCanvas(canvas, ctx);
  };

  destroy = () => {};
}
