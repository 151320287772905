import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Notes } from "src/components/shared/Notes";
import { PNL } from "src/components/shared/PNL";
import { Tabs } from "src/components/shared/Tabs";
import { checkParty } from "src/helpers/checkParty";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

interface AbilitiesProps {
  abilities: Abilities;
  party: string;
}

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  color: #166ce2;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-bottom: 10px;
  border-bottom: ${({ theme }) => theme.borders.solidGray};
  margin-top: 0px;
  & > svg {
    margin-right: 5px;
  }
`;

export const TextTitle = styled.span``;

export const Content = styled.div`
  display: flex;
  width: 100%;
`;

export const TabsStats = styled(Tabs)`
  grid-area: tabs;
  justify-content: space-between;

  & > * {
    padding: 15px 0;
  }
`;

export const Dashboards = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  border-right: ${({ theme }) => theme.borders.solidGray};

  @media (max-width: 1100px) {
    width: 100%;
    border-right: none;
    padding-right: 0px;
    /* height: 100%; */
  }
`;

export const DashboardsWrapper = styled.div<AbilitiesProps>`
  display: grid;
  padding: 10px 0px;
  grid-template-rows: 180px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  grid-template-columns: ${({ abilities, party }) => {
    const checkPermsn = checkParty(abilities, "dex-notes-view", party);

    if (checkPermsn) return "repeat(auto-fill, minmax(330px, 1fr))";

    return "repeat(auto-fill, minmax(255px, 100%))";
  }};

  @media (max-width: 1300px) {
    grid-template-columns: ${({ abilities, party }) => {
      const checkPermsn = checkParty(abilities, "dex-notes-view", party);

      if (checkPermsn) return "repeat(auto-fill, minmax(255px, 1fr))";

      return "repeat(auto-fill, minmax(255px, 100%))";
    }};
  }

  @media (max-width: 1100px) {
    grid-template-columns: ${({ abilities, party }) => {
      const checkPermsn = checkParty(abilities, "dex-notes-view", party);

      if (checkPermsn) return "repeat(auto-fill, minmax(425px, 1fr));";

      return "repeat(auto-fill, minmax(255px, 100%))";
    }};
  }

  @media (max-width: 900px) {
    grid-template-columns: ${({ abilities, party }) => {
      const checkPermsn = checkParty(abilities, "dex-notes-view", party);

      if (checkPermsn) return "repeat(auto-fill, minmax(290px, 1fr));";

      return "repeat(auto-fill, minmax(255px, 100%))";
    }};
  }
`;

export const NotesContainer = styled(Notes)`
  @media (max-width: 615px) {
    height: fit-content;
  }
`;

export const NotesFallback = styled(CrashPanel)`
  @media (max-width: 615px) {
    height: 350px;
  }
`;

export const PNLContainer = styled.section`
  /* min-width: 290px; */
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-of-type {
    margin-bottom: 10px;
  }
`;

export const PNLInfo = styled(PNL)`
  /* height: 145px; */
`;
