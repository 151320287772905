import { observer } from "mobx-react-lite";
import { useTableTotalizer } from "src/context/CEX/Dashboard/TableTotalizer";
import {
  AccountBalances,
  BalancesColumnsKeys,
} from "src/state/CEX/CEXDashboard/AccountBalancesStore";
import { AccountBalanceList, AccountBalanceListProps } from "../AccountBalanceList";
import { AccountBalancesTotalMap } from "../utils/totals";

export interface BalancesFooterProps extends Omit<AccountBalanceListProps, "balances"> {
  columnId: BalancesColumnsKeys;
}

export const BalancesFooter = observer(({ columnId, ...props }: BalancesFooterProps) => {
  const total = useTableTotalizer<AccountBalances, AccountBalancesTotalMap>();

  const balances = total.columnTotal(columnId) ?? {};

  return <AccountBalanceList balances={balances} {...props} />;
});
