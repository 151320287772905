import { makeAutoObservable, runInAction, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import {
  TurnOnSettingsRequest,
  UpdateSettingsRequest,
  getBotSettings,
  getSwapOracleWallets,
  getTransferOracleWallets,
  turnOffForceSettings,
  turnOnSettings,
  updateBotSettings,
  updateForceBotSettings,
  updateSwapOracleWallets,
  updateTransferOracleWallets,
} from "src/api/bots/DEXV2/settings";
import { createConstraintModalStyledText } from "src/components/BotsContent/CEX/CEXBotSettings/utils";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { HashItem } from "src/components/shared/HashesList/HashesListItem/HashLabel";
import { AddressHelper__factory } from "src/contracts/factories/AddressHelper__factory";
import { ContractsMap, isWalletAddress } from "src/helpers/chain/contracts";
import {
  copyDataByKey,
  equalData,
  getData,
  getPathAndKey,
  getTargetValueByPath,
  setData,
} from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
  Validator,
} from "src/helpers/forms/types";
import {
  NestedObjectPaths,
  NonArrayObject,
  ObjectPathValue,
} from "src/helpers/forms/types/NestedObject";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { joinStrings } from "src/helpers/string";
import { Disposable, ExcludeStrict, KeysOfType, Mapper, Nullish, keys } from "src/helpers/utils";
import contracts from "src/json/contracts.json";
import { DEXV2BotVersion, DEXV2ExchangeVersion } from "src/modules/bots";
import { ConstraintsErrorsReport } from "src/state/CEX/CEXSettings/ConstraintsStore";
import { FieldError, FieldErrorProps, SubmitConfig } from "src/state/CEX/CEXSettings/settingsBot";
import WindowConsent from "src/state/WindowConsent";
import {
  graterThan,
  greaterThanNumberKey,
  isNumber,
  required,
  shortThan,
  smallerThan,
  smallerThanNumberKey,
  strictlyGreaterThan,
  strictlySmallerThan,
  validateData,
} from "src/validation-schemas";
import { IBotChainProvider, IBotUUIDProvider } from "../DEXV2Bots/DEXV2BotStore";
import TokenTickerValidationStore, {
  ITokenTickerValidation,
} from "../shared/TokenTickerValidationStore";
import { IWalletAddressValidation, IWalletsState } from "../shared/WalletsStore";
import WithdrawerProvider, { IWithdrawerProvider } from "../shared/WithdrawerProvider";
import BlacklistWalletsStore, {
  IBlacklistWalletsProvider,
} from "../shared/settings/BlacklistWalletsStore";
import SwapWalletsStore, { ISwapWalletsProvider } from "../shared/settings/SwapWalletsStore";
import TransferWalletsStore, {
  ITransferWalletsProvider,
} from "../shared/settings/TransferWalletsStore";
import CounterStrategiesStore, {
  ICounterStrategies,
  ICounterStrategiesProvider,
} from "../shared/settings/counter/CounterStrategiesStore";
import DEXV2SettingsInfoStore from "./DEXV2SettingsInfoStore";
import SettingsAddressProviderStore from "./SettingsAddressProviderStore";

export type SwitchOption = {
  value: string;
  label: string;
};

export const MODE_SWITCH_ITEMS = [
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
] as const;

export type LimitMod = (typeof MODE_SWITCH_ITEMS)[number]["value"];

export type InputEventType = LimitMod;
export type OutputEventType = InputEventType;

export const TRIGGER_COMPARE_ITEMS = [
  { label: ">", value: "greater" },
  { label: "<", value: "less" },
] as const;

export type LimitCompare = (typeof TRIGGER_COMPARE_ITEMS)[number]["value"];

export type DEXV2CounterStrategy = {
  input_event: InputEventType;
  input_lower_bound: number | "";
  input_upper_bound: number | "";

  output_event: OutputEventType;
  output_lower_bound_perc: number;
  output_upper_bound_perc: number;
  upper_price: number | "";
  lower_price: number | "";
  use_receiver: boolean;

  status: boolean;
};

export interface DEXV2Settings {
  party: string;
  bot_name: string;
  chain_id: number;
  base: string;
  quote: string;
  market: string;
  receivers: string[];
  market_router: string;
  pair_addr: string;
  dex_version: DEXV2ExchangeVersion | "";
  version: DEXV2BotVersion | "";
  base_data: {
    tt_buy_fee: number;
    tt_sell_fee: number;
    gas_limit: number | "";
    pool_percent: number;
    stable: string;
  };
  volume_data: {
    period: number | "";
    min_trades: number | "";
    max_trades: number | "";
    buy_percent: number | "";
    min_amount: number | "";
    max_amount: number | "";
    slippage: number | "";
    gas_mult: number;
    gas_price_limit: number | "";
    use_receiver: boolean;
    status: boolean;
  };
  limit_data: {
    mod: LimitMod;
    trigger_compare: LimitCompare;
    period: number | "";
    price: number | "";
    min_amount: number | "";
    max_amount: number | "";
    slippage: number | "";
    gas_mult: number;
    gas_price_limit: number | "";
    use_receiver: boolean;
    status: boolean;
  };
  counter_data: {
    black_listed_wallets: string[];
    data: DEXV2CounterStrategy[] | null;
    gas_mult: number;
    gas_price_limit: number | "";
    slippage: number | "";
    status: boolean;
    cumulative: boolean;
  };
}

export const INITIAL_DEXV2_SETTINGS: DEXV2Settings = {
  party: "",
  bot_name: "",
  chain_id: 0,
  base: "",
  quote: "",
  market: "",
  receivers: [],
  market_router: "",
  pair_addr: "",
  dex_version: "",
  version: "",
  base_data: {
    tt_buy_fee: 0,
    tt_sell_fee: 0,
    gas_limit: 0,
    pool_percent: 0,
    stable: "",
  },
  volume_data: {
    period: 0,
    min_trades: 0,
    max_trades: 0,
    buy_percent: 0,
    min_amount: 0,
    max_amount: 0,
    slippage: "",
    gas_mult: 0,
    gas_price_limit: 0,
    use_receiver: false,
    status: false,
  },
  limit_data: {
    mod: "buy",
    trigger_compare: "greater",
    period: 0,
    price: 0,
    min_amount: 0,
    max_amount: 0,
    slippage: "",
    gas_mult: 0,
    gas_price_limit: 0,
    use_receiver: false,
    status: false,
  },
  counter_data: {
    black_listed_wallets: [],
    data: [],
    gas_mult: 0,
    gas_price_limit: "",
    status: false,
    cumulative: false,
    slippage: "",
  },
};

export type DEXV2SettingsKeys = FormDataKeys<DEXV2Settings>;

export type DEXV2SettingsModuleNames = KeysOfType<DEXV2Settings, NonArrayObject>;

type DEXV2SettingsModule<M extends DEXV2SettingsModuleNames> = M extends "base_data"
  ? Pick<DEXV2Settings, M | "bot_name">
  : Pick<DEXV2Settings, M>;

export type ModulesStatus = Required<TurnOnSettingsRequest>;
export type ModuleStatusName = keyof ModulesStatus;

type SettingsStatusModule = ExcludeStrict<DEXV2SettingsModuleNames, "base_data">;

const settingsModuleToStatusModule: Mapper<SettingsStatusModule, ModuleStatusName> = (module) => {
  const [moduleStatus] = module.split("_");
  return moduleStatus as ModuleStatusName;
};

const statusModuleToSettingsModule: Mapper<ModuleStatusName, SettingsStatusModule> = (module) =>
  `${module}_data` as const;

const SETTINGS_MODULES: readonly DEXV2SettingsModuleNames[] = [
  "limit_data",
  "base_data",
  "volume_data",
  "counter_data",
] as const;

export const SLIPPAGE_VALIDATION: Validator[] = [
  required(),
  isNumber(),
  graterThan(0, "Slippage must be >= 0"),
  smallerThan(100, "Slippage must be <= 100"),
];

const VOLUME_VALIDATION: FormValidation<DEXV2SettingsModule<"volume_data">> = {
  "volume_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(1480, "Period must be less than 1480"),
  ],
  "volume_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "volume_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("volume_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "volume_data.min_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_trades", "Min Trades must be smaller than Max Trades"),
  ],
  "volume_data.max_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    strictlySmallerThan(100, "Max Trades must be less than 100"),
    greaterThanNumberKey("volume_data.min_trades", "Min Trades must be greater than Max Trades"),
  ],
  "volume_data.buy_percent": required(),
  "volume_data.slippage": SLIPPAGE_VALIDATION,
  "volume_data.gas_mult": required(),
  "volume_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

const LIMIT_VALIDATION: FormValidation<DEXV2SettingsModule<"limit_data">> = {
  "limit_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(3600, "Period must be less than 3600"),
  ],
  "limit_data.mod": required(),
  "limit_data.trigger_compare": required(),
  "limit_data.price": [required(), isNumber(), strictlyGreaterThan(0, "PriceUSD must be positive")],
  "limit_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("limit_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "limit_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("limit_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "limit_data.slippage": SLIPPAGE_VALIDATION,
  "limit_data.gas_mult": required(),
  "limit_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

export const COUNTER_VALIDATION: FormValidation<DEXV2SettingsModule<"counter_data">> = {
  "counter_data.gas_mult": required(),
  "counter_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
  "counter_data.slippage": SLIPPAGE_VALIDATION,
};

const BASE_VALIDATION: FormValidation<DEXV2SettingsModule<"base_data">> = {
  "base_data.tt_buy_fee": required(),
  "base_data.tt_sell_fee": required(),
  bot_name: [required(), shortThan(20)],
  "base_data.gas_limit": [
    required(),
    isNumber(),
    strictlyGreaterThan(21_000, "Gas Limit must be greater than 21000"),
    strictlySmallerThan(20_000_000, "Gas Limit must be less than 20000000"),
  ],
};

export interface InputFieldProps
  extends Pick<LabeledInputProps, "value" | "onChange" | "errorHint"> {}

export type ConstraintsValidator = () => Promise<boolean>;
export interface SubmitConstraintsConfig<P, R> extends SubmitConfig<P, R> {
  validator: ConstraintsValidator;
  getValidationReport: () => ConstraintsErrorsReport;
}

export interface IWalletsSubmitState {
  addUpdateWallet: () => void;
  deleteUpdateWallet: (wallet: string) => void;
}

type SaveModuleParams = {
  module: DEXV2SettingsModuleNames;
  isForce: boolean;
};

type SetLoading = Dispatch<boolean>;

export default class DEXV2SettingsStore
  implements
    Disposable,
    ITransferWalletsProvider,
    IWalletAddressValidation,
    IBlacklistWalletsProvider,
    ISwapWalletsProvider,
    IBotUUIDProvider,
    ICounterStrategiesProvider
{
  data: DEXV2Settings = INITIAL_DEXV2_SETTINGS;

  private _savedSettings: DEXV2Settings = INITIAL_DEXV2_SETTINGS;

  private _infoStore: DEXV2SettingsInfoStore;

  private _transferWallets: string[] = [];

  private _transferWalletsLoading = false;

  private _transferWalletsStore: TransferWalletsStore;

  private _swapWallets: string[] = [];

  private _swapWalletsLoading = false;

  private _swapWalletsStore: SwapWalletsStore;

  private _botChainProvider: IBotChainProvider;

  private _withdrawerProvider: IWithdrawerProvider;

  private _counterStrategiesState: ICounterStrategies;

  handlers: FormHandlers<DEXV2Settings> = {};

  private _modulesLoading: Record<DEXV2SettingsModuleNames, boolean> = {
    base_data: false,
    volume_data: false,
    limit_data: false,
    counter_data: false,
  };

  validation: FormValidation<DEXV2Settings> = {
    ...VOLUME_VALIDATION,
    ...LIMIT_VALIDATION,
    ...BASE_VALIDATION,
    ...COUNTER_VALIDATION,
  };

  onChangeValidate: Partial<Record<DEXV2SettingsKeys, DEXV2SettingsKeys[]>> = {
    "volume_data.min_amount": ["volume_data.max_amount"],
    "volume_data.max_amount": ["volume_data.min_amount"],
    "volume_data.min_trades": ["volume_data.max_trades"],
    "volume_data.max_trades": ["volume_data.min_trades"],
    "limit_data.min_amount": ["limit_data.max_amount"],
    "limit_data.max_amount": ["limit_data.min_amount"],
  };

  modulesValidationKeys: Record<DEXV2SettingsModuleNames, DEXV2SettingsKeys[]>;

  private _getModuleValidationKeys = (module: DEXV2SettingsModuleNames): DEXV2SettingsKeys[] => {
    const moduleValidation = (() => {
      switch (module) {
        case "volume_data": {
          return VOLUME_VALIDATION;
        }
        case "limit_data": {
          return LIMIT_VALIDATION;
        }
        case "base_data": {
          return BASE_VALIDATION;
        }
        case "counter_data": {
          return COUNTER_VALIDATION;
        }
      }
    })();

    return Object.keys(moduleValidation) as DEXV2SettingsKeys[];
  };

  errors: FormErrors<DEXV2Settings> = {};

  private _contracts: ContractsMap = contracts;

  private _botUUID = "";

  private _blacklistWalletsStore: BlacklistWalletsStore;

  private _stableTickerValidation: ITokenTickerValidation;

  private _warnings: Partial<Record<DEXV2SettingsKeys, string | undefined>> = {};

  constructor(botChainProvider: IBotChainProvider) {
    this.modulesValidationKeys = Object.fromEntries(
      SETTINGS_MODULES.map((module) => [module, this._getModuleValidationKeys(module)])
    ) as Record<DEXV2SettingsModuleNames, DEXV2SettingsKeys[]>;

    makeAutoObservable(this, { moduleSavedStatus: false });

    this._transferWalletsStore = new TransferWalletsStore(this, this, false);

    this._swapWalletsStore = new SwapWalletsStore(this, this, false);

    this._blacklistWalletsStore = new BlacklistWalletsStore(this);

    this._infoStore = new DEXV2SettingsInfoStore(this);

    this._botChainProvider = botChainProvider;

    this._withdrawerProvider = new WithdrawerProvider(this);

    this._counterStrategiesState = new CounterStrategiesStore(this);

    this._stableTickerValidation = new TokenTickerValidationStore({
      chainProvider: botChainProvider.chainProvider,
      addressProvider: new SettingsAddressProviderStore({
        dataProvider: this,
        tokenType: "stable",
      }),
      tokenType: "stable",
    });

    makeLoggable<any>(this, {
      data: true,
      _savedSettings: true,
      errors: true,
      handlers: true,
      withdrawer: true,
      blacklistWallets: true,
      modulesStatuses: true,
    });
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setModuleStatus = (statusModule: ModuleStatusName, enabled: boolean) => {
    const module = statusModuleToSettingsModule(statusModule);
    this.data[module].status = enabled;
  };

  private _toggleModuleStatus = (statusModule: ModuleStatusName) => {
    const module = statusModuleToSettingsModule(statusModule);
    this.data[module].status = !this.data[module].status;
  };

  modulesStatuses = computedFn((statusModule: ModuleStatusName) => {
    const module = statusModuleToSettingsModule(statusModule);
    return this.data[module].status;
  });

  private _getModuleStartKeys = (module: DEXV2SettingsModuleNames): Array<keyof DEXV2Settings> => {
    switch (module) {
      case "base_data": {
        return ["bot_name", module];
      }
      default: {
        return [module];
      }
    }
  };

  private _setSavedSettings = (
    settings: DEXV2Settings,
    startKey?: NestedObjectPaths<DEXV2Settings>
  ) => {
    if (!startKey) {
      this._savedSettings = toJS(settings);
      return;
    }
    copyDataByKey(this._savedSettings, settings, startKey);
  };

  private _updateSettings = (module?: DEXV2SettingsModuleNames) => {
    const startKeys = module ? this._getModuleStartKeys(module) : undefined;
    this._syncSavedSettings(startKeys);
  };

  private _syncSavedSettings = (startKeys?: Array<keyof DEXV2Settings>) => {
    if (!startKeys) {
      this._setSavedSettings(this.data);
      return;
    }

    startKeys.forEach((key) => {
      this._setSavedSettings(this.data, key);
    });
  };

  private _setData = (settings: DEXV2Settings, startKey?: keyof DEXV2Settings) => {
    if (!startKey) {
      this.data = toJS(settings);
      this.clearErrors();
      this.clearHandlers();
    } else {
      copyDataByKey(this.data, settings, startKey);
      this.clearErrors(startKey);
      this.clearHandlers(startKey);
    }
  };

  private _resetSettings = (module?: DEXV2SettingsModuleNames) => {
    const startKeys = module ? this._getModuleStartKeys(module) : undefined;
    this._resetToSavedSettings(startKeys);
  };

  private _resetToSavedSettings = (startKeys?: Array<keyof DEXV2Settings>) => {
    if (!startKeys) {
      this._setData(this._savedSettings);
      return;
    }

    startKeys.forEach((key) => {
      this._setData(this._savedSettings, key);
    });
  };

  moduleSavedStatus = computedFn((module: DEXV2SettingsModuleNames) => {
    const startKeys = this._getModuleStartKeys(module);
    for (const key of startKeys) {
      if (!this._isDataSaved(key)) {
        return false;
      }
    }
    return true;
  });

  private _isDataSaved = (startKey: NestedObjectPaths<DEXV2Settings>) =>
    equalData(this.data, this._savedSettings, startKey);

  private _setModuleLoading = (module: DEXV2SettingsModuleNames, loading: boolean) => {
    this._modulesLoading[module] = loading;
  };

  private _setLoading: SetLoading = (loading: boolean) => {
    (Object.keys(this._modulesLoading) as Array<DEXV2SettingsModuleNames>).forEach((key) => {
      this._setModuleLoading(key, loading);
    });
  };

  private _getModuleLoadingHandler =
    (module: DEXV2SettingsModuleNames): SetLoading =>
    (loading: boolean) => {
      this._setModuleLoading(module, loading);
    };

  moduleLoading = (module: DEXV2SettingsModuleNames) => this._modulesLoading[module];

  get transferWalletsLoading() {
    return this._transferWalletsLoading;
  }

  private _setTransferWalletsLoading = (loading: boolean) => {
    this._transferWalletsLoading = loading;
  };

  get swapWalletsLoading() {
    return this._swapWalletsLoading;
  }

  private _setSwapWalletsLoading = (loading: boolean) => {
    this._swapWalletsLoading = loading;
  };

  private get _chainProvider() {
    return this._botChainProvider.chainProvider;
  }

  get info() {
    return this._infoStore.info;
  }

  get receivers(): HashItem[] {
    if (!this.data.receivers) return [];
    return this.data.receivers.map((address) => ({
      address,
    }));
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private get _rpcProvider() {
    return this._chainProvider.provider;
  }

  private get _addressHelper() {
    if (!this._rpcProvider || !this._chainId) return null;

    const contractAddress = this._contracts[this._chainId]?.[0].contracts.AddressHelper;

    if (!contractAddress) return null;

    return AddressHelper__factory.connect(contractAddress, this._rpcProvider);
  }

  isWalletAddress = async (address: string | string[]): Promise<Record<string, boolean>> =>
    await isWalletAddress(address, this._addressHelper);

  private _validateOnChangeKey = (key: DEXV2SettingsKeys) => {
    if (!this.validation[key]) return;

    const validateKeys =
      key in this.onChangeValidate
        ? ([key, ...this.onChangeValidate[key]!] as DEXV2SettingsKeys[])
        : [key];

    return this.validate(validateKeys);
  };

  getHandler = (key: DEXV2SettingsKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      runInAction(() => {
        this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
          switch (key) {
            case "base_data.tt_buy_fee":
            case "base_data.tt_sell_fee":
            case "volume_data.buy_percent":
            case "volume_data.gas_mult":
            case "limit_data.gas_mult":
            case "counter_data.gas_mult": {
              const newValue = getChangeEventValue(e);
              if (+newValue <= 100) {
                targetData[endKey] = newValue;
              }
              break;
            }
            case "limit_data.trigger_compare":
            case "limit_data.mod": {
              targetData[endKey] = getChangeEventValue(e, true);
              break;
            }
            default: {
              targetData[endKey] = getChangeEventValue(e);
            }
          }
          this._validateOnChangeKey(key);
        };
      });
    }

    return this.handlers[key]!;
  };

  private _getErrorByKey = (key: DEXV2SettingsKeys) =>
    getData(this.errors as Required<FormErrors<DEXV2Settings>>, key);

  private _getDataByKey = <K extends DEXV2SettingsKeys = DEXV2SettingsKeys>(key: K) =>
    getData(this.data, key);

  getInputProps = (
    key: ExcludeStrict<
      DEXV2SettingsKeys,
      | "volume_data.use_receiver"
      | "volume_data.status"
      | "limit_data.use_receiver"
      | "limit_data.status"
      | "counter_data.status"
      | "counter_data.data"
      | "counter_data.cumulative"
    >
  ): InputFieldProps => ({
    errorHint: this._getErrorByKey(key),
    value: this._getDataByKey(key),
    onChange: this.getHandler(key),
  });

  private _getWarningByKey = (key: DEXV2SettingsKeys) => {
    switch (key) {
      case "base_data.stable": {
        return this._stableTickerValidation.warning;
      }
      default: {
        return this._warnings[key];
      }
    }
  };

  private _getFieldError = (key: DEXV2SettingsKeys): FieldError | undefined => {
    const error = this._getErrorByKey(key);
    if (error) return { type: "error", message: error };

    const constraintError = this._getWarningByKey(key);

    if (constraintError) return { type: "warning", message: constraintError };
  };

  getFieldErrorAsProps = (key: DEXV2SettingsKeys): FieldErrorProps | undefined => {
    const fieldError = this._getFieldError(key);
    if (!fieldError) return undefined;
    const { type, message } = fieldError;
    return { errorHint: message, errorType: type };
  };

  switchOptions = (key: "mod" | "trigger_compare"): SwitchOption[] => {
    switch (key) {
      case "mod": {
        return MODE_SWITCH_ITEMS.slice();
      }
      case "trigger_compare": {
        return TRIGGER_COMPARE_ITEMS.slice();
      }
    }
  };

  private _setDataByKey = <K extends DEXV2SettingsKeys>(
    key: K,
    value: ObjectPathValue<DEXV2Settings, K>
  ) => {
    setData(this.data, key, value);
  };

  setSwitchOption = (key: "trigger_compare") => (value: string) => {
    switch (key) {
      case "trigger_compare": {
        this._setDataByKey("limit_data.trigger_compare", value as LimitCompare);
      }
    }
  };

  private _getWalletsSubmitState = (
    walletsState: IWalletsState,
    walletsUpdater: () => void
  ): IWalletsSubmitState => {
    const addUpdateWallet = () => {
      const isValidAction = walletsState.addWallet();
      if (isValidAction) {
        walletsUpdater();
      }
    };

    const deleteUpdateWallet = (wallet: string) => {
      const isValidAction = walletsState.deleteWallet(wallet);
      if (isValidAction) {
        walletsUpdater();
      }
    };

    return { addUpdateWallet, deleteUpdateWallet };
  };

  get transferWallets() {
    return this._transferWallets;
  }

  setTransferWallets = (wallets: string[]) => {
    this._transferWallets = wallets;
  };

  private get _transferWalletsState() {
    return this._transferWalletsStore.transferWalletsState;
  }

  get transferWalletsState(): IWalletsState {
    return this._transferWalletsState;
  }

  get transferWalletsSubmitState(): IWalletsSubmitState {
    const submitState = this._getWalletsSubmitState(
      this.transferWalletsState,
      this.updateTransferWallets
    );
    return submitState;
  }

  get swapWallets() {
    return this._swapWallets;
  }

  setSwapWallets = (wallets: string[]) => {
    this._swapWallets = wallets;
  };

  private get _swapWalletsState() {
    return this._swapWalletsStore.swapWalletsState;
  }

  get swapWalletsState(): IWalletsState {
    return this._swapWalletsState;
  }

  get swapWalletsSubmitState(): IWalletsSubmitState {
    const submitState = this._getWalletsSubmitState(this.swapWalletsState, this.updateSwapWallets);
    return submitState;
  }

  get blacklistWallets() {
    return this.data.counter_data.black_listed_wallets;
  }

  setBlacklistWallets = (wallets: string[]) => {
    this.data.counter_data.black_listed_wallets = wallets;
  };

  get blacklistWalletsState(): IWalletsState {
    return this._blacklistWalletsStore.blacklistWalletsState;
  }

  get counterStrategies() {
    return this.data.counter_data.data ?? [];
  }

  setCounterStrategies = (strategies: DEXV2CounterStrategy[]) => {
    this.data.counter_data.data = strategies;
  };

  saveCounterStrategies = async () => {
    const submitter = this.submitModuleHandler("counter_data");
    const isSuccess = await submitter();
    return isSuccess;
  };

  updateCounterStrategy = async (strategy: DEXV2CounterStrategy) =>
    await this._counterStrategiesState.updateCounterStrategy(strategy);

  deleteCounterStrategy = async (index: number) =>
    await this._counterStrategiesState.deleteCounterStrategy(index);

  toggleCounterStrategyStatus = async (index: number) =>
    await this._counterStrategiesState.toggleActiveCounterStrategy(index);

  setSelectedCounterStrategy = (index: Nullish<number>) => {
    this._counterStrategiesState.setSelectedCounterStrategy(index);
  };

  get selectedCounterStrategy() {
    return this._counterStrategiesState.selectedCounterStrategy;
  }

  clearHandlers = (startKey?: keyof DEXV2Settings) => {
    if (!startKey) {
      this.handlers = {};
      return;
    }

    keys(this.handlers).forEach((key) => {
      if (key.startsWith(startKey)) {
        this.handlers[key] = undefined;
      }
    });
  };

  clearErrors = (startKey?: keyof DEXV2Settings) => {
    if (!startKey) {
      this.errors = {};
      return;
    }

    this.errors[startKey] = undefined;
  };

  validate = (validateKeys?: DEXV2SettingsKeys[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  private _getSettings = async () => {
    const { isError, data } = await getBotSettings(this._botUUID);
    if (!isError) {
      this._setData(data);
      this._syncSavedSettings();
    }
  };

  private _getTransferWallets = async () => {
    this._setTransferWalletsLoading(true);
    try {
      const { isError, data } = await getTransferOracleWallets(this._botUUID);
      if (!isError) {
        this.setTransferWallets(data ?? []);
      } else {
        this.setTransferWallets([]);
      }
    } finally {
      this._setTransferWalletsLoading(false);
    }
  };

  updateTransferWallets = async (e?: React.FormEvent<Element>) => {
    e?.preventDefault();

    const valid = this._transferWalletsState.validateWallets();

    if (valid) {
      const setLoading = this._setTransferWalletsLoading;

      await this._submitWithConstraints(
        {
          func: this._updateTransferOracleWallets,
          param: undefined,
          validator: this._transferWalletsState.validateWalletsConstraints,
          getValidationReport: this._getWalletsValidationReport,
        },
        setLoading
      );
    }
  };

  private _updateTransferOracleWallets = async () => {
    this._setTransferWalletsLoading(true);
    try {
      const { isError } = await updateTransferOracleWallets(this._botUUID, this.transferWallets);
      if (!isError) {
        toast.success("Transfer wallets updated successfully", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setTransferWalletsLoading(false);
    }
  };

  private _getSwapWallets = async () => {
    this._setSwapWalletsLoading(true);
    try {
      const { isError, data } = await getSwapOracleWallets(this._botUUID);
      if (!isError) {
        this.setSwapWallets(data ?? []);
      } else {
        this.setSwapWallets([]);
      }
    } finally {
      this._setSwapWalletsLoading(false);
    }
  };

  updateSwapWallets = async (e?: React.FormEvent<Element>) => {
    e?.preventDefault();
    const valid = this._swapWalletsState.validateWallets();

    if (valid) {
      await this._submitWithConstraints(
        {
          func: this._updateSwapOracleWallets,
          param: undefined,
          validator: this._swapWalletsState.validateWalletsConstraints,
          getValidationReport: this._getWalletsValidationReport,
        },
        this._setSwapWalletsLoading
      );
    }
  };

  private _updateSwapOracleWallets = async () => {
    this._setSwapWalletsLoading(true);
    try {
      const { isError } = await updateSwapOracleWallets(this._botUUID, this.swapWallets);
      if (!isError) {
        toast.success("Swap wallets updated successfully", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setSwapWalletsLoading(false);
    }
  };

  get withdrawer() {
    return this._withdrawerProvider.withdrawer ?? "";
  }

  private _getWithdrawer = async () => {
    await this._withdrawerProvider.getWithdrawer();
  };

  getData = async () => {
    this._setLoading(true);

    try {
      await Promise.all([
        this._getSettings(),
        this._getTransferWallets(),
        this._getSwapWallets(),
        this._getWithdrawer(),
      ]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _getValidationReport = (warningsText: string) => {
    const warningsTitle = warningsText.length > 0 ? "\nThere are following warnings: " : "";

    const messages = [warningsText, "\nWant to continue?"];
    return { title: warningsTitle, message: joinStrings(messages) };
  };

  private _getWalletsValidationReport = (): ConstraintsErrorsReport => {
    const { message: transferWalletsMessage } =
      this._transferWalletsState.walletsValidationReport();

    const { message: swapWalletsMessage } = this._swapWalletsState.walletsValidationReport();

    const warningsText = joinStrings([transferWalletsMessage, swapWalletsMessage]);

    return this._getValidationReport(warningsText);
  };

  private _validateConstraints = async (
    setLoading: SetLoading,
    validator: ConstraintsValidator
  ) => {
    setLoading(true);
    try {
      const isChainValid = await validator();
      return isChainValid;
    } finally {
      setLoading(false);
    }
  };

  private _submitWithConstraints = async <P, R>(
    { func: submitter, param, validator, getValidationReport }: SubmitConstraintsConfig<P, R>,
    setLoading: SetLoading = this._setLoading
  ) => {
    try {
      const constraintsValid = await this._validateConstraints(setLoading, validator);

      if (!constraintsValid) {
        const { title, message } = getValidationReport();
        const styledTitle = createConstraintModalStyledText(title);
        WindowConsent.showWindow(styledTitle, message, submitter, param);
        // return true since modal doesn't support promises
        return true;
      }
      return await submitter(param);
    } catch (err) {
      logError(err);
      return false;
    }
  };

  private _setOnSettings = (module: ModuleStatusName, newEnabled: boolean) => {
    const requestData: TurnOnSettingsRequest = { [module]: newEnabled };

    return newEnabled
      ? turnOnSettings(this._botUUID, requestData)
      : turnOffForceSettings(this._botUUID, requestData);
  };

  getOnHandler = (module: ExcludeStrict<DEXV2SettingsModuleNames, "base_data">) => {
    const statusModule = settingsModuleToStatusModule(module);

    const resyncOnSettings = (newEnabled: boolean) => {
      // ignore all current model edits
      this._resetSettings(module);
      this._setModuleStatus(statusModule, newEnabled);
      // sync new on state to saved settings
      this._updateSettings(module);
    };

    return async (e: React.ChangeEvent<HTMLInputElement>) => {
      const setLoading = this._getModuleLoadingHandler(module);

      setLoading(true);
      const newEnabled = e.target.checked;
      this._setModuleStatus(statusModule, newEnabled);

      try {
        const { isError } = await this._setOnSettings(statusModule, newEnabled);

        if (!isError) {
          toast.success(`${statusModule} ${newEnabled ? "enabled" : "disabled"} successfully`, {
            autoClose: 2000,
          });
          resyncOnSettings(newEnabled);
        } else {
          this._toggleModuleStatus(statusModule);
        }
      } catch (err) {
        this._toggleModuleStatus(statusModule);
        logError(err);
      } finally {
        setLoading(false);
      }
    };
  };

  private _getModulesConstraints = (module: DEXV2SettingsModuleNames): ConstraintsValidator[] => {
    switch (module) {
      case "base_data": {
        return [this._stableTickerValidation.validateTicker];
      }
      default: {
        return [];
      }
    }
  };

  private _validateSettingsConstraints = async (module?: DEXV2SettingsModuleNames) => {
    const modules = module ? [module] : SETTINGS_MODULES;

    const constraints = modules.flatMap((module) => this._getModulesConstraints(module));

    const constraintsValid = await Promise.all(constraints.map((constraint) => constraint()));

    return constraintsValid.every(Boolean);
  };

  private _getModulesValidationText = (
    module: DEXV2SettingsModuleNames
  ): Array<string | undefined> => {
    switch (module) {
      case "base_data": {
        const stableWarning = this._stableTickerValidation.warning;
        return [stableWarning];
      }
      default: {
        return [];
      }
    }
  };

  private _getSettingsValidationReport = (
    module?: DEXV2SettingsModuleNames
  ): ConstraintsErrorsReport => {
    const modules = module ? [module] : SETTINGS_MODULES;

    const warningsTextParts = modules.flatMap((module) => this._getModulesValidationText(module));

    const warningText = joinStrings(warningsTextParts);

    return this._getValidationReport(warningText);
  };

  private _submitConstraintsAllSettings = async (isForce: boolean) => {
    const loading = this._setLoading;

    await this._submitWithConstraints(
      {
        func: this._submitAllSettings,
        param: isForce,
        validator: this._validateSettingsConstraints,
        getValidationReport: this._getSettingsValidationReport,
      },
      loading
    );
  };

  private _submitAllSettings = async (isForce: boolean) => {
    this._setLoading(true);

    const updateSettings = this._getUpdateBotSettingsRequest();

    const updater = isForce ? updateForceBotSettings : updateBotSettings;

    try {
      const { isError } = await updater(this._botUUID, updateSettings);

      if (!isError) {
        toast.success("Settings saved successfully", {
          autoClose: 2000,
        });
        this._updateSettings();
      }
    } finally {
      this._setLoading(false);
    }
  };

  private _submitHandler = async (isForce: boolean) => {
    try {
      const valid = this.validate();

      if (valid) {
        await this._submitConstraintsAllSettings(isForce);
      }
    } catch (err) {
      logError(err);
    }
  };

  submitHandler = async () => {
    await this._submitHandler(false);
  };

  submitForceHandler = async () => {
    await this._submitHandler(true);
  };

  private _getUpdateBotSettingsRequest = (
    module?: DEXV2SettingsModuleNames
  ): UpdateSettingsRequest => {
    const { bot_name, base_data, ...settings } = this.data;
    if (!module) {
      return { ...settings, base_data: { ...base_data, bot_name } };
    }
    const moduleKeys = this._getModuleStartKeys(module) as Array<
      keyof DEXV2SettingsModule<DEXV2SettingsModuleNames>
    >;
    const moduleData = moduleKeys.reduce((data, key) => {
      // eslint-disable-next-line no-param-reassign
      data[key] = this.data[key];
      return data;
    }, {} as DEXV2SettingsModule<DEXV2SettingsModuleNames>);

    if (module === "base_data") {
      const { base_data, bot_name } = moduleData as DEXV2SettingsModule<"base_data">;
      return { base_data: { ...base_data, bot_name } };
    }

    return moduleData as DEXV2SettingsModule<Exclude<DEXV2SettingsModuleNames, "base_data">>;
  };

  private _saveModuleSettings = async ({ module, isForce = false }: SaveModuleParams) => {
    const setLoading = this._getModuleLoadingHandler(module);
    setLoading(true);

    const updateSettings = this._getUpdateBotSettingsRequest(module);

    const updater = isForce ? updateForceBotSettings : updateBotSettings;

    try {
      const { isError } = await updater(this._botUUID, updateSettings);

      if (!isError) {
        toast.success(`${module} settings module successfully saved`, {
          autoClose: 2000,
        });

        this._updateSettings(module);
        return true;
      }
      // reset settings to saved state for counter settings only
      if (module === "counter_data") {
        this._resetSettings("counter_data");
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  private _saveConstraintsModuleSettings = async (
    module: DEXV2SettingsModuleNames,
    isForce: boolean
  ) => {
    const setLoading = this._getModuleLoadingHandler(module);

    return await this._submitWithConstraints(
      {
        func: this._saveModuleSettings,
        param: { module, isForce },
        validator: this._validateSettingsConstraints,
        getValidationReport: this._getSettingsValidationReport,
      },
      setLoading
    );
  };

  private _submitModuleHandler =
    (module: DEXV2SettingsModuleNames, isForce: boolean) => async (e?: React.FormEvent) => {
      e?.preventDefault();

      try {
        const valid = this.validate(this.modulesValidationKeys[module]);

        if (valid) {
          const isSuccess = await this._saveConstraintsModuleSettings(module, isForce);
          return isSuccess;
        }
        return false;
      } catch (err) {
        logError(err);
        return false;
      }
    };

  submitModuleHandler = (module: DEXV2SettingsModuleNames) =>
    this._submitModuleHandler(module, false);

  submitForceModuleHandler = (module: DEXV2SettingsModuleNames) =>
    this._submitModuleHandler(module, true);

  destroy = () => {};
}
