import { ColumnDef } from "@tanstack/table-core";
import { Observer, observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { TableContext } from "src/context/shared/Table";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CreatedLimitOrder, OrderSideType } from "src/modules/exchange/trade";
import useAppState from "src/state";
import { AmountCell } from "../AmountCell";
import { OrderInfoModal } from "../OrderInfoModal";
import { ColumnFilter } from "../OrdersTable/ColumnFilter";
import { TableRows } from "../OrdersTable/TableRows";
import { InfoButton } from "../shared/InfoButton";
import * as styles from "../shared/style";

interface LimitOrdersProps {
  party: string;
  abilityName: string;
}

const SIDE_FILTERS = ["BUY", "SELL"];

export const VIEW_LIMIT_ORDER_PARAMS = [
  "time",
  "id",
  "pair",
  "side",
  "amount",
  "price",
  "filled",
  "average",
  "fee",
  "feeCurrency",
  "role",
  "orderType",
  "canceled",
  "partiallyFilled",
  "status",
] as Array<keyof CreatedLimitOrder>;

export const LimitOrders = observer(({ party, abilityName }: LimitOrdersProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const ordersState = mainState.limitOrdersState;

  const { tableState } = ordersState;

  const { getStatusState } = ordersState;

  const { userState } = useAppState();

  const checkPermsn = useMemo(
    () => checkParty(userState.abilities, abilityName, party),
    [userState.abilities, abilityName, party]
  );

  const [showModal, setShowModal] = useState(false);

  const allColumns: any = useMemo(() => {
    const columnSchema: ColumnDef<CreatedLimitOrder>[] = [
      {
        header: () => (
          <Observer>
            {() => (
              <styles.HeadCheckBox
                checked={tableState.allChecked}
                style={{ marginLeft: "0px", width: "18px" }}
                onChange={tableState.allCheck}
              />
            )}
          </Observer>
        ),
        id: "checkbox",
        accessorKey: "id",
        enableSorting: false,
        size: 20,
        cell: (info) => (
          <Observer>
            {() => (
              <CheckBox
                checked={tableState.selectItems.includes(info.getValue<string>())}
                onChange={() => tableState.check(info.getValue<string>())}
                style={{ marginLeft: "0px", width: "18px" }}
              />
            )}
          </Observer>
        ),
      },
      {
        header: () => "Price",
        accessorKey: "price",
        id: "priceOrder",
        enableResizing: false,
        cell: (info: any) => <SeparatorNum value={info.getValue()} />,
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        id: "amountOrder",
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { amount, price },
            },
          },
        }) => <AmountCell amount={amount} price={price} />,
      },
      {
        header: () => <ColumnFilter label="Side" filterParam="side" filters={SIDE_FILTERS} />,
        accessorKey: "side",
        enableSorting: false,
        id: "sideOrder",
        size: 50,
        cell: ({
          cell: {
            row: { original: order },
          },
        }: any) => (
          <styles.Side side={order.side}>
            {order.side}
            <InfoButton
              onClick={() => {
                setShowModal(true);
                getStatusState.setSelectOrderId(order.id);
              }}
            />
          </styles.Side>
        ),
      },

      {
        header: () => (
          <Observer>
            {() => (
              <Button
                size="xs"
                disabled={!tableState.cancelActive}
                onClick={tableState.cancelOrders}
              >
                Cancel
              </Button>
            )}
          </Observer>
        ),
        id: "cancelOrder",
        accessorKey: "id",
        enableSorting: false,
        size: 50,
        cell: (info) => (
          <Observer>
            {() => (
              <DeleteButton
                style={{ margin: "auto" }}
                onClick={() => tableState.cancelOrder([info.getValue<string>()])}
              />
            )}
          </Observer>
        ),
      },
    ];

    return columnSchema;
  }, [tableState, getStatusState]);

  const shortColumns = useMemo(() => {
    const columnSchema: ColumnDef<CreatedLimitOrder>[] = [
      {
        header: () => "Price",
        accessorKey: "price",
        cell: (info) => <SeparatorNum value={info.getValue<string>()} />,
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        cell: ({
          cell: {
            row: {
              original: { amount, price },
            },
          },
        }: any) => <AmountCell amount={amount} price={price} />,
      },
      {
        header: () => "Side",
        accessorKey: "side",
        cell: (info) => (
          <styles.Side side={info.getValue<OrderSideType>()}>
            {info.getValue<OrderSideType>()}
          </styles.Side>
        ),
      },
    ];

    return columnSchema;
  }, []);

  return (
    <TableContext.Provider value={tableState}>
      <TableRows
        data={tableState.tableItems}
        columns={checkPermsn ? allColumns : shortColumns}
        loader={ordersState.isLoading}
        headTooltipProps={{
          amountAllOrders: ordersState.limitOrders.length,
          amountBuyOrders: ordersState.amountBuyOrders,
          amountSellOrders: ordersState.amountSellOrders,
        }}
      />
      {showModal ? (
        <OrderInfoModal
          label="Limit order info:"
          data={ordersState.orderInfo}
          keys={VIEW_LIMIT_ORDER_PARAMS}
          show={showModal}
          close={setShowModal}
          loading={getStatusState.isStatusLoading}
          afterLabel={
            <DeleteButton
              disabled={ordersState.orderInfo?.status !== "open"}
              onClick={() => {
                tableState.cancelOrder([String(ordersState.orderInfo?.id)]);
                setShowModal(false);
              }}
            />
          }
        />
      ) : null}
    </TableContext.Provider>
  );
});
