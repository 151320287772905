import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { mobileMediaQuery } from "src/components/BotsContent/DEX_V2/Settings/style";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { FormCol, FormRow } from "../shared";
import { SwapWalletsPanel } from "./SwapWalletsPanel";
import { TransferWalletsPanel } from "./TransferWalletsPanel";
import * as styles from "./style";

export const BaseSettings = observer(() => {
  const state = useLateInitContext(DEXV2CreatingContext);

  const isMobile = useMediaQuery(mobileMediaQuery("500px"));

  const WalletsWrapper = isMobile ? FormCol : FormRow;

  useEffect(() => {
    state.getPartiesList();
  }, [state]);

  return (
    <styles.SettingsContainer $orientation="column">
      <styles.SettingsPanel />

      <WalletsWrapper>
        <TransferWalletsPanel />
        <SwapWalletsPanel />
      </WalletsWrapper>
    </styles.SettingsContainer>
  );
});
