import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { ExchangesLegendContext } from "src/context/CEX/Dashboard/ExchangesLegend";
import { BuyLiquidityChart, SellLiquidityChart } from "../../../BuySellLiquidityCharts";
import { ExchangesOverview } from "../../../ExchangesOverview";
import { OldBalancesOverviewChart } from "../../../OldBalancesOverviewChart";
import { ProfitLossChart } from "../../../ProfitLossChart";
import { VolumeShare } from "../../../VolumeShare";
import { SantimentOverview } from "../../../SantimentChart";
import { StatsAreas } from "./style";
import * as styles from "./style";

export interface DesktopSummaryProps {}

export const DesktopSummary = observer((props: DesktopSummaryProps) => (
  <styles.Container>
    <ErrorBoundary fallback={<styles.KPIFallback />}>
      <styles.StatsKPIPanel />
    </ErrorBoundary>
    <styles.StatsContainer>
      <ErrorBoundary fallback={<styles.PNLFallback />}>
        <ProfitLossChart style={{ gridArea: StatsAreas.Pnl }} />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.BalancesFallback />}>
        <OldBalancesOverviewChart style={{ gridArea: StatsAreas.Balances }} />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.ExchangesFallback />}>
        <ExchangesLegendContext.Provider>
          <ExchangesOverview style={{ gridArea: StatsAreas.Exchange }} />
        </ExchangesLegendContext.Provider>
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.BuyLiquidityFallback />}>
        <BuyLiquidityChart style={{ gridArea: StatsAreas.BuyLiquidity }} />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.SellLiquidityFallback />}>
        <SellLiquidityChart style={{ gridArea: StatsAreas.SellLiquidity }} />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.VolumeFallback />}>
        <VolumeShare style={{ gridArea: StatsAreas.Volume }} />
      </ErrorBoundary>
      <SantimentOverview style={{ gridArea: StatsAreas.Table }} />
    </styles.StatsContainer>
  </styles.Container>
));
