import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column } from "react-table";
import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { VaultsContext } from "src/context/DEXV2/DEXV2Stats/Vaults/Vaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useModalState } from "src/hooks/useModalState";
import { BotWalletConnectionState } from "src/state/DEXV2/DEXV2Stats/Vaults/BotWalletConnectionStore";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { AddressCell, HeaderAddressCell } from "../TablePanel/AddressCell";
import { ActionsPanel } from "./ActionsPanel";
import { ModalTransfer } from "./ModalTransfer";
import { ModalWithdraw } from "./ModalWithdraw";
import { VaultTypeCell } from "./VaultTypeCell/VaultTypeCell";
import { VaultsInteractionPanel } from "./VaultsInteractionPanel";
import { VaultsQuotesTooltip, getVaultsQuotesTooltipAttrs } from "./VaultsQuotesTooltip";
import * as styles from "./styles";
import { TablePanelProps } from "../TablePanel";

export interface VaultsProps extends Pick<TablePanelProps, "style"> {}

export const Vaults = observer((props: VaultsProps) => {
  const state = useLateInitContext(VaultsContext);

  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID, chainInfo } = botState;
  const scannerLink = getScannerLink(chainInfo, "wallet");

  const { connectionState } = useLateInitContext(BotWalletConnectionContext);
  const isWalletConnected = connectionState === BotWalletConnectionState.Connected;

  const { hasAbility } = useLateInitContext(AbilitiesContext);

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getVaults();
  }, [botUUID, state]);

  const {
    isShown: isShownTransfer,
    close: onTransferClose,
    show: onTransferOpen,
  } = useModalState();
  const transferEnabled = hasAbility(DEXV2Ability.VaultsTransfer);

  const {
    isShown: isShownWithdraw,
    close: onWithdrawClose,
    show: onWithdrawOpen,
  } = useModalState();
  const withdrawEnabled = hasAbility(DEXV2Ability.VaultsWithdraw);

  const {
    tickers: { quote: quoteTicker, base: baseTicker },
    modalDepsReady,
    withdrawParams,
    transferParams,
  } = state;

  const columns = useMemo(() => {
    const columnsSchema: Column<DEXV2Vault>[] = [
      {
        Header: <HeaderAddressCell copyCb={state.copyAllAddresses} />,
        accessor: "address",
        Cell: ({ value }) => <AddressCell address={value} scannerLink={scannerLink} />,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "string",
        Cell: ({ value }) => <VaultTypeCell type={value} />,
        disableFilters: true,
      },
      {
        Header: quoteTicker,
        accessor: "quote",
        sortType: "number",
        Cell: ({ value, row }) => (
          <styles.BalancesCell
            {...getVaultsQuotesTooltipAttrs(row.original.address, "quote")}
            value={value}
          />
        ),
        disableFilters: true,
      },
      {
        Header: baseTicker,
        accessor: "base",
        sortType: "number",
        Cell: ({ value, row }) => (
          <styles.BalancesCell
            {...getVaultsQuotesTooltipAttrs(row.original.address, "base")}
            value={value}
          />
        ),
        disableFilters: true,
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: "address",
        Cell: ({ value }) => (
          <ActionsPanel
            address={value}
            onTransferOpen={onTransferOpen}
            transferEnabled={transferEnabled}
            onWithdrawOpen={onWithdrawOpen}
            withdrawEnabled={withdrawEnabled}
            onVaultAddressChange={state.setCurrentVaultAddress}
            disabled={!isWalletConnected || !modalDepsReady}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    return columnsSchema;
  }, [
    quoteTicker,
    baseTicker,
    scannerLink,
    onTransferOpen,
    transferEnabled,
    onWithdrawOpen,
    withdrawEnabled,
    state,
    isWalletConnected,
    modalDepsReady,
  ]);

  return (
    <styles.VaultsPanel
      title="Vaults"
      afterTitle={<VaultsInteractionPanel />}
      isLoading={state.loading}
      {...props}
    >
      <styles.VaultsTable data={state.roundedVaults} columns={columns} />

      <VaultsQuotesTooltip quotesProvider={state} />

      {transferParams && (
        <ModalTransfer show={isShownTransfer} close={onTransferClose} {...transferParams} />
      )}

      {withdrawParams && (
        <ModalWithdraw show={isShownWithdraw} close={onWithdrawClose} {...withdrawParams} />
      )}
    </styles.VaultsPanel>
  );
});
