import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import {
  ConditionTypes,
  IStrategyModuleCreateType,
  StrategyModuleTypes,
} from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { BaseModuleForm, BaseModuleFormProps } from "../BaseModuleForm";
import { getConditionOptionList } from "../utils/modules";

export interface BaseModuleFormWithTypeProps extends BaseModuleFormProps {
  types: readonly ConditionTypes[];
}

export const BaseModuleFormWithType = ({
  types,
  children,
  ...props
}: BaseModuleFormWithTypeProps) => {
  const typeOptions = useMemo(() => getConditionOptionList(types), [types]);

  const { control } = useFormContext<IStrategyModuleCreateType<StrategyModuleTypes>>();

  const TypesSelector = (
    <Controller
      control={control}
      name="type"
      rules={{ required: ERRORS_MSGS.isRequired }}
      render={({ field: { onChange }, formState: { errors } }) => (
        <LabeledSelector
          label="Type"
          options={typeOptions}
          onChange={(data) => onChange(data?.value)}
          errorHint={errors.type?.message}
        />
      )}
    />
  );

  return (
    <BaseModuleForm beforeNameField={TypesSelector} {...props}>
      {children}
    </BaseModuleForm>
  );
};
