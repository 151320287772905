import { observer } from "mobx-react-lite";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import useAppState from "src/state";
import { Accounts } from "./Accounts";
import { Settings } from "./Settings";
import * as styles from "./style";

export const CEXBotCreating = observer(() => {
  const { createBotState: state } = useAppState();
  useSubscribableStore(state);

  return (
    <styles.FormBotCreating
      onSubmit={state.submitHandler}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
    >
      <styles.Content>
        <Settings />
        <Accounts />
      </styles.Content>

      <styles.FormFooter>
        <OutlineButton color="neutral" onClick={() => state.cancelHandler()}>
          Clear form
        </OutlineButton>
        <SubmitButton />
      </styles.FormFooter>
    </styles.FormBotCreating>
  );
});
