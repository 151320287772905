import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { getPairs } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { filterCallback } from "src/helpers/utils";
import { Pair } from "src/modules/exchange/trade";
import { SelectorValue } from "src/modules/shared";
import { required, validateData } from "src/validation-schemas";
import { MAIN_PAIRS } from "../CEXExchange";
import { IModuleCreator } from "./CreateMultiGrid";

export type GridStatus = "active" | "stopped" | "error";

export interface ModuleConfig {
  accountUUID: string;
  amountDecimals: number | "";
  priceDecimals: number | "";
  makerFee: number | "";
}

export interface INewExchangeModule {
  pair: string;
  id: string;
  settings: ModuleConfig;
  status: GridStatus;
}

type NewModuleKeys = FormDataKeys<INewExchangeModule>;

const selectorFields = ["settings.accountUUID", "id", "pair"] as const;

type moduleSelectorFields = (typeof selectorFields)[number];

export const EMPTY_MODULE: INewExchangeModule = {
  id: "",
  pair: "",
  status: "active",
  settings: {
    accountUUID: "",
    amountDecimals: "",
    priceDecimals: "",
    makerFee: 0,
  },
};

class AddModalModuleStore {
  module: INewExchangeModule = EMPTY_MODULE;

  isLoading = false;

  handlers: FormHandlers<INewExchangeModule> = {};

  errors: FormErrors<INewExchangeModule> = {};

  private _pairs: Pair[] = [];

  private _searchPair: string = "";

  private _validation: FormValidation<INewExchangeModule> = {
    id: required(),
    pair: required(),
    "settings.amountDecimals": [required()],
    "settings.priceDecimals": [required()],
    "settings.accountUUID": required(),
    "settings.makerFee": required(),
  };

  private _exchangeChangedReaction?: IReactionDisposer;

  closeModal: (bool: boolean) => void;

  creatorState: IModuleCreator;

  constructor(state: IModuleCreator, closeModalCb: (bool: boolean) => void) {
    this.closeModal = closeModalCb;

    this.creatorState = state;

    makeAutoObservable(this);
  }

  private _setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  getAccounts = async () => {
    this._setLoading(true);

    try {
      await this.creatorState.accountsState.loadAccounts();
    } finally {
      this._setLoading(false);
    }
  };

  subscribe = () => {
    this._exchangeChangedReaction = reaction(
      () => this.getSelector("id"),
      (exchange) => {
        this._updateSelector("settings.accountUUID", "");
        this._updateSelector("pair", "");
        if (!exchange) return;
        this._getAllPairs(exchange);
      }
    );
  };

  unsubscribe = () => {
    this._exchangeChangedReaction?.();
  };

  getSelector = (field: moduleSelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.module, path);

    return targetData[endKey];
  };

  get exchangeSelected() {
    return Boolean(this.module.id);
  }

  get accounts() {
    return this.creatorState.accountsState.accounts;
  }

  get accountList() {
    const exchAccounts = this.accounts[this.module.id];

    if (exchAccounts)
      return exchAccounts.map(({ name, uuid }) => ({
        value: uuid,
        label: name,
      }));

    return [];
  }

  private get pairs() {
    return this._pairs
      .map(({ base, quote }) => ({
        value: `${quote}_${base}`,
        label: `${quote}_${base}`,
      }))
      .sort((a, b) => this._pairsSort(a.value, b.value));
  }

  get shortPairsList() {
    return this.pairs.filter(({ value }) => filterCallback(value, this._searchPair)).slice(0, 50);
  }

  get currentAccount() {
    const exchAccounts = this.accounts[this.module.id];

    if (!exchAccounts) return undefined;

    const account = exchAccounts.find((el) => el.uuid === this.module.settings.accountUUID);

    if (account) return { value: this.module.settings.accountUUID, label: account.name };

    return undefined;
  }

  private _pairsSort = (a: string, b: string) => {
    const firstCheck = this._priorityCheck(a);
    const secondCheck = this._priorityCheck(b);

    return secondCheck - firstCheck;
  };

  private _priorityCheck = (pair: string) => {
    if (MAIN_PAIRS.includes(pair)) return 2;
    // if (pair.includes(this.originBase)) return 1;
    return 0;
  };

  setNewPair = (value: string) => {
    const [quote, base] = value.split("_");

    const newPair: Pair = {
      base,
      quote,
      minAmountBase: "",
      minAmountQuote: "",
    };

    this._pairs = [newPair, ...this._pairs];
  };

  changePair = (pair: string): void => {
    this.module.pair = pair;
  };

  setSearchPair = () => (value: string) => {
    runInAction(() => {
      this._searchPair = value;
    });
  };

  private _getAllPairs = (exchange: string) => {
    this._getPairs(exchange);
  };

  private _getPairs = async (exchange: string) => {
    try {
      const {
        data: { data },
        isError,
      } = await getPairs(exchange);

      if (!isError) {
        runInAction(() => {
          this._pairs = data;
        });
      }
    } catch {
      runInAction(() => {
        this._pairs = [];
      });
    }
  };

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this._setLoading(true);

      try {
        this.creatorState.addExchModule(this.module, this.closeModal);
      } finally {
        this._setLoading(false);
      }
    }
  };

  private _stringToSelectorValue = (str: string) => ({ value: str, label: str });

  selectorValue = computedFn((field: moduleSelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.module, path);

    const currentValue = targetData[endKey];

    if (!currentValue) {
      return null;
    }
    return this._stringToSelectorValue(currentValue);
  });

  onSelectorChange = (field: moduleSelectorFields) => (newValue: SelectorValue | null) => {
    if (!newValue) return;
    this._updateSelector(field, String(newValue.value));
  };

  private _updateSelector = (field: moduleSelectorFields, value: string) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.module, path);

    targetData[endKey] = value;
  };

  getHandler = (key: NewModuleKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.module, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.module, this.errors, validateKeys);
}

export default AddModalModuleStore;
