import { makeAutoObservable } from "mobx";
import { getHistory } from "src/api/bots/CEX/stats";
import { getColor } from "src/helpers/getStatus/getBotStatusColor";
import { logError } from "src/helpers/network/logger";
import { getCurrentDayjs, getDayjsFromUnix, unixToDateFormat } from "../../../helpers/dateUtils";

class SpectrStore {
  statusList = [];

  actionsList = [
    {
      firstTime: 1643706956,
      endTime: 1643707376,
      actions: ["settings", "modify", "funding"],
    },
  ];

  bot_uuid = "";

  timePeriod = 720;

  timeIntervals = [];

  timeInterval = getCurrentDayjs();

  gradients = [];

  trackedTime = null;

  trackedStatus = null;

  spectrWidth = null;

  constructor() {
    makeAutoObservable(this);
  }

  clearGradients = () => {
    this.gradients = [];
  };

  setBotUUID = (uuid) => {
    this.bot_uuid = uuid;
  };

  setStatusList = (arr) => {
    if (arr.length) {
      this.statusList = arr;
      this.getGradient(this.statusList);
      this.createCanvasGradient(this.spectrWidth);
      // this.changeCanvasGradient(this.spectrWidth);
      this.createTimeIntervals(this.spectrWidth);
      this.setStatusValues();
    }
  };

  setSpectrWidth = (width) => {
    this.spectrWidth = width;
  };

  setStatusValues = () => {
    this.trackedTime = unixToDateFormat(this.statusList[0].time, "FullDate");
    this.trackedStatus = this.statusDecoding(this.statusList[0].status);
  };

  setStatus = (color) => {
    this.trackedStatus = color;
  };

  setObsvTime = (time) => {
    this.trackedTime = time;
  };

  setTimePeriod = (value) => {
    this.timePeriod = value;
  };

  getStatusList = async (startPoint, stopPoint) => {
    try {
      const {
        isError,
        data: { history },
      } = await getHistory(this.bot_uuid, startPoint, stopPoint);
      if (!isError) this.setStatusList(history);
    } catch (error) {
      logError(error);
    }
  };

  getFullTime = (time) => unixToDateFormat(time, "FullDate");

  getCoordinate = (el) => {
    if (this.statusList.length) {
      const coord = this.calculateStep(
        el.firstTime,
        this.statusList[0].time,
        this.statusList[this.statusList.length - 1].time
      );
      return (this.spectrWidth * coord) / 100;
    }
  };

  getWidthUserActions = (el) => {
    if (this.statusList.length) {
      const firstCoord = this.calculateStep(
        el.firstTime,
        this.statusList[0].time,
        this.statusList[this.statusList.length - 1].time
      );

      const endCoord = this.calculateStep(
        el.endTime,
        this.statusList[0].time,
        this.statusList[this.statusList.length - 1].time
      );
      const width = endCoord - firstCoord;

      return (this.spectrWidth * width) / 100;
    }
  };

  getGradient = (arr) => {
    this.clearGradients();
    let lastCoord = 0;
    let color = this.statusDecoding(arr[0].status);
    let coord = 0;

    if (arr.length > 2) {
      for (let i = 1; i < arr.length; i += 1) {
        lastCoord = this.calculateStep(arr[i].time, arr[0].time, arr[arr.length - 1].time);
        this.gradients.push({
          color,
          coord: coord * 0.01,
          lastCoord: lastCoord * 0.01,
        });
        color = this.statusDecoding(arr[i].status);
        coord = lastCoord;
      }
    } else
      this.gradients.push({
        color,
        coord: 0,
        lastCoord: 1,
      });
  };

  getFullInterval = () => {
    if (!this.statusList.length) return "";

    return `From ${unixToDateFormat(this.statusList[0].time, "FullDate")} to ${unixToDateFormat(
      this.statusList[this.statusList.length - 1].time,
      "FullDate"
    )}`;
  };

  calculateStep = (numStatus, startPoint, endPoint) =>
    (100 * ((numStatus - startPoint) / (endPoint - startPoint))).toFixed(2);

  calcIntervals = () => this.timePeriod / this.defineInterval();

  statusDecoding = (status) => getColor(status, 301);

  defineInterval = () => {
    switch (this.timePeriod) {
      // minutes
      case 5:
        return 1;
      case 15:
        return 1;
      case 30:
        return 5;
      // hours
      case 60:
        return 10;
      case 180:
        return 30;
      case 360:
        return 30;
      case 720:
        return 60;
      case 1440:
        return 120;
      case 2880:
        return 240;
      // days
      case 10080:
        return 1440;
      case 43200:
        return 2880;
      default:
        return this.timePeriod;
    }
  };

  addMinutes = (value) => {
    if (this.timeInterval) this.timeInterval = this.timeInterval.add(value, "minutes");
  };

  addHours = (value) => {
    if (this.timeInterval) this.timeInterval = this.timeInterval.add(value, "hours");
  };

  addDay = (value) => {
    if (this.timeInterval) this.timeInterval = this.timeInterval.add(value, "days");
  };

  timeCounter = () => {
    const unixTimeInterval = this.timeInterval.unix();

    const shortTime = unixToDateFormat(unixTimeInterval, "ShortTime");

    const shortDate = unixToDateFormat(unixTimeInterval, "ShortDate");

    switch (this.timePeriod) {
      // minutes
      case 5:
        this.addMinutes(1);
        return shortTime;
      case 15:
        this.addMinutes(1);
        return shortTime;
      case 30:
        this.addMinutes(5);
        return shortTime;
      // hours
      case 60:
        this.addMinutes(10);
        return shortTime;
      case 180:
        this.addMinutes(30);
        return shortTime;
      case 360:
        this.addMinutes(30);
        return shortTime;
      case 720:
        this.addHours(1);
        return shortTime;
      case 1440:
        this.addHours(2);
        return shortTime;
      case 2880:
        this.addHours(4);
        return shortTime;
      // days
      case 10080:
        this.addDay(1);
        return shortDate;
      case 43200:
        this.addDay(2);
        return shortDate;
      default:
        return this.getFullInterval();
    }
  };

  createTimeIntervals = (width) => {
    const interval = this.calcIntervals();
    const widthInterval = (width / this.calcIntervals()).toFixed(2);
    this.timeIntervals = [];

    if (this.statusList.length) {
      this.timeInterval = getDayjsFromUnix(this.statusList[0].time);
    }

    for (let i = 0; i < interval; i += 1) {
      this.timeIntervals.push({
        widthInterval,
        timeInterval: this.timeCounter(),
      });
    }
  };

  createCanvasGradient = (width) => {
    const canvas = document.getElementById("spectr");

    if (width && canvas) {
      canvas.width = width;

      const ctx = canvas.getContext("2d", { willReadFrequently: true });

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.rect(0, 0, canvas.width, canvas.height);

      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

      for (const el of this.gradients) {
        gradient.addColorStop(el.coord, el.color);
        gradient.addColorStop(el.lastCoord, el.color);
      }

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, 75);
      ctx.fill();
    }
  };

  rgbToHex = (r, g, b) => {
    if (r > 255 || g > 255 || b > 255) throw new Error("Invalid color component");
    // eslint-disable-next-line no-bitwise
    return ((r << 16) | (g << 8) | b).toString(16);
  };

  findPos = (obj) => {
    let curleft = 0;
    let curtop = 0;
    if (obj.offsetParent) {
      do {
        curleft += obj.offsetLeft;
        curtop += obj.offsetTop;
        // eslint-disable-next-line no-cond-assign, no-param-reassign
      } while ((obj = obj.offsetParent));
      return { x: curleft, y: curtop };
    }
    return undefined;
  };

  showStatusTime = (e, width, obj) => {
    const pos = this.findPos(obj);
    if (this.statusList.length) {
      const section = this.statusList[this.statusList.length - 1].time - this.statusList[0].time;
      const timeEqv = section / width;
      const x = e.pageX - pos.x;
      const startPoint = this.statusList[0].time;
      const time = startPoint + x * timeEqv;
      const convTime = unixToDateFormat(Math.round(time), "FullDate");
      this.setObsvTime(convTime);
    }
  };

  changeStatus = (e, obj) => {
    const canvas = document.getElementById("spectr");
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const pos = this.findPos(obj);
    const x = e.pageX - pos.x;
    const p = ctx.getImageData(x, 7, 1, 1).data;
    this.setStatus(`rgba(${p[0]}, ${p[1]}, ${p[2]}, ${p[3]})`);
  };
}

export default SpectrStore;
