import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { getClients } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { noOp } from "src/helpers/utils";
import { Client } from "src/modules/bots";
import { SelectorValue } from "src/modules/shared";
import { NewAccount, RequestAccount } from "src/modules/userManager";
import { validateData } from "src/validation-schemas";
import { APIStore } from "./APIStore";
import { AccStore } from "./AccStore";

export interface AccountParams {
  message: string;
  addEntity: (acc: NewAccount) => Promise<any>;
  validation: Record<string, (v: string | number) => string>;
  _mutateAccount?: (acc: NewAccount) => RequestAccount;
}

class AddAccStore {
  //   party: string = "";

  account: NewAccount = {
    exchange: "",
    name: "",
    credentials: {
      name: "",
      is_active: false,
      data: {
        KEY: "",
        SECRET: "",
        ACCOUNT_ID: "",
      },
      meta: {},
    },
    meta: {},
  };

  private _exchanges: Client[] = [];

  handlers: any = {};

  errors: any = {};

  isLoadingAcc = false;

  updateList: () => void = () => {};

  showTrigger: React.Dispatch<React.SetStateAction<boolean>> = noOp;

  paramsStore: AccountParams;

  constructor(currentParty: string, type: "API" | "ACCOUNT", uuid: string) {
    makeAutoObservable(this);

    if (type === "API") {
      this.paramsStore = new APIStore(currentParty, uuid);
    } else this.paramsStore = new AccStore(currentParty);
  }

  get exchanges() {
    return this._exchanges.map((el) => ({ value: el.id, label: el.id }));
  }

  getExchangesList = async () => {
    try {
      const { data, isError } = await getClients();

      if (!isError) {
        runInAction(() => {
          this._exchanges = data?.data ?? [];
        });
      } else {
        runInAction(() => {
          this._exchanges = [];
        });
      }
    } catch {
      runInAction(() => {
        this._exchanges = [];
      });
    }
  };

  setExchange = (exchange: string) => {
    this.account.exchange = exchange;
  };

  resetForm = () => {
    this.account = {
      exchange: "",
      name: "",
      credentials: {
        name: "",
        data: {
          KEY: "",
          SECRET: "",
          ACCOUNT_ID: "",
        },
        meta: {},
      },
      meta: {},
    };

    this.handlers = {};
    this.errors = {};
  };

  setUpdateList = (func: () => void) => {
    this.updateList = func;
  };

  setTrigger = (func: React.Dispatch<React.SetStateAction<boolean>>) => {
    this.showTrigger = func;
  };

  getAccountHandler = (key: string) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.account, path);

      if (key === "exchange") {
        this.handlers[key] = (value: SelectorValue) => {
          targetData[endKey] = value.value;
        };
      } else {
        this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
          targetData[endKey] = this.getChangeEventValue(e);
        };
      }
    }
    return this.handlers[key];
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number" || e.target.type === "radio" || e.target.type === "range") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    if (e.target.type === "checkbox") {
      return e.target.checked;
    }
    if (e.target.type === "text") {
      return e.target.value;
    }
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this.paramsStore.validation, this.account, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      runInAction(() => {
        this.isLoadingAcc = true;
      });

      try {
        const { isError } = await this.paramsStore.addEntity(this.account);

        if (!isError) {
          toast.success(this.paramsStore.message, {
            autoClose: 2000,
          });

          this.showTrigger(false);
          this.updateList();
        }
      } finally {
        runInAction(() => {
          this.isLoadingAcc = false;
        });
      }
    }
  };
}

export default AddAccStore;
