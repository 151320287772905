import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BalancesDynamicsBars } from "src/state/CEX/CEXDashboard/BalancesDynamicsStore";
import { useChartColorWheel } from "../hooks/useChartColorWheel";
import { CardProps } from "../shared/Card";
import { RefreshDataButton } from "../shared/RefreshDataButton";
import { BalancesBar, BalancesBarProps } from "./BalancesBar";
import { BalancesTable } from "./BalancesTable";
import { BalancesDynamicsContext } from "./context";
import * as styles from "./style";

export type TokensColorsMap = Record<string, string>;

const useTokensColors = (tokens: string[]) => {
  const colorWheel = useChartColorWheel();

  const tokensColors = useMemo(
    () =>
      tokens.reduce(
        (acc, token, index) => {
          acc[token] = colorWheel[index];
          return acc;
        },
        {} as Record<string, string>
      ),
    [colorWheel, tokens]
  );

  return tokensColors;
};

interface UseBalanceDynamicsDataParams {
  bars: BalancesDynamicsBars;
  colorsMap: TokensColorsMap;
}

const useBalanceDynamicsData = ({
  bars: { labels: tokens, data: values },
  colorsMap,
}: UseBalanceDynamicsDataParams) => {
  const colors = useMemo(() => tokens.map((token) => colorsMap[token]), [colorsMap, tokens]);

  const data = useMemo(
    (): BalancesBarProps["data"] => ({
      labels: tokens,
      datasets: [
        {
          indexAxis: "y",
          data: values,
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    }),
    [colors, tokens, values]
  );

  return data;
};

export interface BalancesDynamicsProps extends Omit<CardProps, "title" | "after"> {}

export const BalancesDynamics = observer(({ ...props }: BalancesDynamicsProps) => {
  const { getBalancesDynamics, bars } = useLateInitContext(BalancesDynamicsContext.Context);

  const colorsMap = useTokensColors(bars.labels);

  const barsData = useBalanceDynamicsData({ bars, colorsMap });

  useEffect(() => {
    getBalancesDynamics();
  }, [getBalancesDynamics]);

  return (
    <>
      <RefreshDataButton onRefresh={getBalancesDynamics} />
      <styles.StyledBalancesCard
        title="Balance sheet dynamics"
        after={<BalancesTable colorsMap={colorsMap} />}
        {...props}
      >
        <BalancesBar data={barsData} />
      </styles.StyledBalancesCard>
    </>
  );
});
