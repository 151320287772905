import { MainSettings } from "./MainSettings";
import { SwapSettings } from "./SwapSettings";
import * as styles from "./style";

export const Settings = ({ party, abilityName }) => (
  <styles.SettingsContainer>
    <MainSettings />
    <SwapSettings party={party} abilityName={abilityName} />
  </styles.SettingsContainer>
);
