import { observer } from "mobx-react-lite";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { TooltipSeriesData } from "src/state/Graph/TooltipStore";
import * as styles from "./style";

export interface TooltipSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const TooltipSeries = observer(({ title: seriesTitle }: TooltipSeriesProps) => {
  const { seriesDataMap } = useTooltipContext();

  const { color, title, price } = seriesDataMap.get(seriesTitle)!;
  return (
    <styles.SeriesContainer>
      <styles.SeriesNameContainer>
        <styles.SeriesColor color={color} />
        <styles.SeriesName>{title}:</styles.SeriesName>
      </styles.SeriesNameContainer>
      <styles.SeriesPrice>{price}</styles.SeriesPrice>
    </styles.SeriesContainer>
  );
});
