import { makeAutoObservable } from "mobx";
import { UseFormReset } from "react-hook-form";
import {
  createCEXBindingModule,
  deleteCEXBindingModule,
  editCEXBindingModule,
  getCEXBindings,
} from "src/api/bots/CEX/bindingModules";
import { getClients } from "src/api/bots/CEX/exchange";
import { getPartyBots } from "src/api/userManager/partiesAPI";
import { joinMarket, joinMarketBotName } from "src/helpers/botName";
import { getSelectorList } from "src/helpers/forms/selectors";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { IExchange } from "src/modules/shared";
import WindowConsent from "src/state/WindowConsent";

const SWAP_ROUTES = [
  "cake1",
  "cake2",
  "uni",
  "uni3/1",
  "uni3/0.3",
  "uni3/0.05",
  "uni3/0.01",
  "quick",
  "sushimatic",
  "joe",
  "viper",
  "ape",
  "yode",
  "despace",
  "wingriders",
  "uni3polygon/1",
  "uni3polygon/0.3",
  "uni3polygon/0.05",
  "uni3polygon/0.01",
  "spooky",
  "zyber",
  "sushiarbi",
  "aptcake",
  "quick3",
  "uni3arbi/1",
  "uni3arbi/0.3",
  "uni3arbi/0.05",
  "uni3arbi/0.01",
  "minswap",
  "cakezk",
  "cake3bsc/1",
  "cake3bsc/0.25",
  "cake3bsc/0.05",
  "cake3bsc/0.01",
  "cake2_uni3/1",
  "syncswap",
  "fusionx3/1",
  "fusionx3/0.3",
  "fusionx3/0.05",
  "fusionx3/0.01",
  "raydium",
  "curve",
];

export interface IPartyBot {
  base: string;
  bot_uuid: string;
  exchange: string;
  name: string;
  quote: string;
}

export type BotModuleKind = "swap" | "exchange" | "bot";

export interface ITypeBotModuleParams {
  uuid: string;
  name: string;
  kind: BotModuleKind;
  mainToCurrent: boolean;
  same: boolean;
}

export interface ISwapModule extends ITypeBotModuleParams {
  baseAddress: string;
  currentQuote: string;
  quoteAddress: string;
  swap: string;
  swapQuote: string;
}

export interface IExchangeModule extends ITypeBotModuleParams {
  currentQuote: string;
  exchange: string;
  exchangeBase: string;
  exchangeQuote: string;
}

export interface IBotModule extends ITypeBotModuleParams {
  currentQuote: string;
  mainBot: string;
  mainBotQuote: string;
}

export type CEXBindingModule = ISwapModule | IBotModule | IExchangeModule;

export type BindingArray = CEXBindingModule[];

export interface BotModuleCredProps {
  kind: BotModuleKind;
  uuid: string;
}
type MAP = { swap: ISwapModule; exchange: IExchangeModule; bot: IBotModule };

class BotModulesStore {
  private _party: string = "";

  private _market: string = "";

  private _bindings: BindingArray = [];

  private _exchangesList: IExchange[] = [];

  private _partyBots: IPartyBot[] = [];

  isLoading = false;

  isCreateLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _modulesFilter = <T extends BotModuleKind>(kind: T, arr: CEXBindingModule[]): MAP[T][] =>
    arr.filter((el) => el.kind === kind) as MAP[T][];

  get viewMarket() {
    if (!this._market) return "";

    return this._market.split("_").join(" ");
  }

  get swapRoutesList() {
    return getSelectorList(SWAP_ROUTES);
  }

  get exchangesList() {
    const exchStringList = this._exchangesList.map((el) => el.id);

    return getSelectorList(exchStringList);
  }

  get swapModules() {
    return this._modulesFilter("swap", this._bindings);
  }

  get exchangeModules() {
    return this._modulesFilter("exchange", this._bindings);
  }

  get botModules() {
    return this._modulesFilter("bot", this._bindings);
  }

  get partyBots() {
    return this._partyBots
      .map((bot) => ({
        market: joinMarket({
          quote: bot.quote,
          base: bot.base,
          exchange: bot.exchange,
        }),
        ...bot,
      }))
      .map((bot) => ({
        value: bot.bot_uuid,
        label: joinMarketBotName(bot.market, bot.name),
      }));
  }

  get partyBotList() {
    return this._partyBots;
  }

  get party() {
    return this._party;
  }

  getFullBotName(bot_uuid: string) {
    const findBot = this._partyBots.find((bot) => bot.bot_uuid === bot_uuid);

    if (findBot) {
      const market = joinMarket({
        quote: findBot.quote,
        base: findBot.base,
        exchange: findBot.exchange,
      });

      return joinMarketBotName(market, findBot.name);
    }

    return "";
  }

  setParty = (party: string) => {
    this._party = party;
  };

  setMarket = (market: string) => {
    this._market = market;
  };

  private _setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  private _setCreateLoading = (loading: boolean) => {
    this.isCreateLoading = loading;
  };

  private _setBindings = (bindings: BindingArray) => {
    this._bindings = bindings;
  };

  private _setPartyBots = (bots: IPartyBot[]) => {
    this._partyBots = bots;
  };

  private _setExchangesList = (exchanges: IExchange[]) => {
    this._exchangesList = exchanges;
  };

  loadData = () => {
    this._getAllModules(this._party);
    this._getExchangesList();
    this._getMainBotBindings(this._party);
  };

  private _getAllModules = async (party: string) => {
    this._setLoading(true);

    try {
      const { isError, data } = await getCEXBindings(party);

      if (!isError) {
        const { bindings } = data;

        this._setBindings(bindings);
      }
    } catch (error) {
      logError(error);
      this._setBindings([]);
    } finally {
      this._setLoading(false);
    }
  };

  private _getExchangesList = async () => {
    try {
      const { data, isError } = await getClients();

      if (!isError) {
        this._setExchangesList(data.data);
      }
    } catch (error) {
      logError(error);
      this._setExchangesList([]);
    }
  };

  private _getMainBotBindings = async (party: string) => {
    try {
      const { data, isError } = await getPartyBots(party);

      if (!isError) {
        this._setPartyBots(data);
      }
    } catch (error) {
      logError(error);
      this._setPartyBots([]);
    }
  };

  createModule = (closeModalCb: () => void) => async (module: CEXBindingModule) => {
    this._setCreateLoading(true);

    try {
      const { isError } = await createCEXBindingModule({ data: module, party: this._party });

      if (!isError) {
        showSuccessMsg("Module successfully created");
        closeModalCb();
        this.loadData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setCreateLoading(false);
    }
  };

  editModule = (reset: UseFormReset<CEXBindingModule>) => async (module: CEXBindingModule) => {
    this._setLoading(true);

    try {
      const { isError } = await editCEXBindingModule({ data: module, party: this._party });

      if (!isError) {
        showSuccessMsg("Module edited successfully");

        reset(module);
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  removeHandler = (module: CEXBindingModule) => () => {
    WindowConsent.showWindow(
      "Are you sure you want to remove the module:",
      `${module.name}`,
      this._deleteModule,
      { kind: module.kind, uuid: module.uuid }
    );
  };

  private _deleteModule = async ({ kind, uuid }: BotModuleCredProps) => {
    try {
      const { isError } = await deleteCEXBindingModule({ party: this._party, type: kind, uuid });

      if (!isError) {
        showSuccessMsg("Module removed successfully");
        this.loadData();
      }
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}

export default BotModulesStore;
