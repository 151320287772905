import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { PNLV2ItemContainer } from "../Item/PNLV2Item/style";

export interface PNLV2ItemsContainerProps {
  orientation?: "row" | "column";
  space?: "space-between" | "center" | "flex-end" | "flex-start";
}

interface StyledContainerProps extends StyledProps<PNLV2ItemsContainerProps> {}

export const PNLV2ItemsContainer = styled.div<StyledContainerProps>`
  width: 100%;

  display: flex;

  flex-direction: ${({ $orientation = "row" }) => $orientation};
  justify-content: ${({ $space = "center" }) => $space};

  ${({ $space }) => {
    if ($space === "space-between") {
      return css`
        // make items auto width for space between to work
        ${PNLV2ItemContainer} {
          width: auto;
        }
      `;
    }
  }}

  gap: 8px;
`;
