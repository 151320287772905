import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { computedFn } from "mobx-utils";
import {
  changePartyGroupInfo,
  changePartySantimentInfo,
  getPartyInfo,
} from "src/api/userManager/partiesAPI";
import { getUserGroup } from "src/api/userManager/userGroupsAPI";
import { getData, getPathAndKey, getTargetValueByPath, setData } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { showSuccessMsg } from "src/helpers/message";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { Disposable, Extends } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { PartyInfo, GroupType, UserGroup } from "src/modules/userManager";
import { required, validateData } from "src/validation-schemas";
import { logError } from "src/helpers/network/logger";
import { SelectorHandler } from "../../UserGroups/AddUserGroupStore";
import { PartyInfoMapper } from "./PartyInfoMapper";

export const INITIAL_GROUP_INFO: EditGroupInfo = {
  tg_chat_id: 0,
  client_group: "",
  admin_group: "",
  admin: "",
};

export const INITIAL_SANTIMENT_INFO: EditSantimentInfo = {
  slug: "",
  ticker: "",
};

export interface PartyInfoProvider {
  party: string;
  userGroupsByType: (type: GroupType) => UserGroup[];
}

export type EditGroupInfo = Omit<PartyInfo, "slug" | "ticker">;

export type EditSantimentInfo = Pick<PartyInfo, "slug" | "ticker">;

type EditGroupInfoKeys = FormDataKeys<EditGroupInfo>;

type FormSelectors = Extends<EditGroupInfoKeys, "admin_group" | "client_group" | "admin">;

export class PartyInfoStore implements Disposable {
  private _validation: FormValidation<EditGroupInfo> = {
    admin_group: required(),
    admin: required(),
  };

  private _provider: PartyInfoProvider;

  handlers: FormHandlers<EditGroupInfo> = {};

  errors: FormErrors<EditGroupInfo> = {};

  private _isLoading = false;

  private _editGroupInfo: EditGroupInfo = INITIAL_GROUP_INFO;

  private _editSantimentInfo: EditSantimentInfo = INITIAL_SANTIMENT_INFO;

  private _adminUserNames: string[] = [];

  private _adminGroupChangedReaction: IReactionDisposer;

  constructor(provider: PartyInfoProvider) {
    this._provider = provider;

    makeAutoObservable(this, {
      selectorEnabled: false,
      selectorValue: false,
      selectorOptions: false,
    });

    this._adminGroupChangedReaction = reaction(
      () => this.editGroupInfo.admin_group,
      (admin_group) => {
        if (!admin_group) {
          this._setAdminUserNames([]);
        } else {
          this._getAdminUserGroupUsers(admin_group);
        }
      }
    );
  }

  private _setEditGroupInfo = (groupInfo: EditGroupInfo) => {
    this._resetForm();
    this._editGroupInfo = groupInfo;
  };

  private _setEditSantimentInfo = (santimentInfo: EditSantimentInfo) => {
    this._editSantimentInfo = santimentInfo;
  };

  get editGroupInfo() {
    return this._editGroupInfo;
  }

  get editSantimentInfo() {
    return this._editSantimentInfo;
  }

  get party() {
    return this._provider.party;
  }

  setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  get isLoading() {
    return this._isLoading;
  }

  private _setAdminUserNames = (adminUserNames: string[]) => {
    this._adminUserNames = adminUserNames;
  };

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
  };

  private get _adminGroups() {
    return this._provider.userGroupsByType("admin");
  }

  getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "admin_group": {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value));
          this._setData("admin", "");
        };
      }
      default: {
        return (data) => {
          if (!data) return;
          this._setData(key, String(data.value));
        };
      }
    }
  };

  selectorEnabled = computedFn((key: Extends<FormSelectors, "admin">) => {
    switch (key) {
      case "admin": {
        return this.editGroupInfo.admin_group && this.selectorOptions("admin").length;
      }
    }
  });

  selectorValue = computedFn((key: FormSelectors): SelectorValue => {
    const value = this._getData(key);
    return stringToSelectorValue(value);
  });

  selectorOptions = computedFn((key: FormSelectors): SelectorValue[] => {
    switch (key) {
      case "client_group": {
        return getSelectorList(
          this._provider.userGroupsByType("client").map((group) => group.name)
        );
      }
      case "admin_group": {
        return getSelectorList(this._adminGroups.map((group) => group.name));
      }
      case "admin": {
        return getSelectorList(this._adminUserNames);
      }
    }
  });

  private _setData = <K extends EditGroupInfoKeys>(
    key: K,
    value: ObjectPathValue<EditGroupInfo, K>
  ) => {
    setData(this.editGroupInfo, key, value);
  };

  private _getData = <K extends EditGroupInfoKeys>(key: K) => getData(this.editGroupInfo, key);

  getHandler = (key: EditGroupInfoKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this._editGroupInfo, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (key) {
          case "tg_chat_id": {
            const newValue = getChangeEventValue(e);
            targetData[endKey] = newValue || 0;
            break;
          }
          default: {
            targetData[endKey] = getChangeEventValue(e);
          }
        }
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.editGroupInfo, this.errors, validateKeys);

  submitGroupInfoHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.setLoading(true);

      try {
        const { isError } = await changePartyGroupInfo({
          party: this._provider.party,
          groupInfo: this.editGroupInfo,
        });

        if (!isError) showSuccessMsg("Information edited successfully");
      } finally {
        this.setLoading(false);
      }
    }
  };

  submitSantimentInfoHandler = async (data: EditSantimentInfo) => {
    this.setLoading(true);

    try {
      const { isError } = await changePartySantimentInfo(this._provider.party, data);

      if (!isError) showSuccessMsg("Information edited successfully");
    } finally {
      this.setLoading(false);
    }
  };

  private _getAdminUserGroupUsers = async (groupName: string) => {
    try {
      const { data, isError } = await getUserGroup(groupName);

      if (!isError) {
        this._setAdminUserNames(data.users);
      } else {
        this._setAdminUserNames([]);
      }
    } catch {
      this._setAdminUserNames([]);
    }
  };

  getPartyInfo = async () => {
    this.setLoading(true);

    try {
      const { data, isError } = await getPartyInfo(this.party);

      if (!isError) {
        this._setEditGroupInfo(PartyInfoMapper.groupInfoMapFrom(data));
        this._setEditSantimentInfo(PartyInfoMapper.santimentInfoMapFrom(data));
      }
    } catch (err) {
      logError(err);
    } finally {
      this.setLoading(false);
    }
  };

  destroy() {
    this._adminGroupChangedReaction();
  }
}
