import { observer } from "mobx-react-lite";
import { useStatsStore } from "../../hooks/useStatsStore";
import { InfoPanelSection } from "./InfoPanelSection";
import * as styles from "./style";

export interface BalancesInfoPanelProps {}

export const BalancesInfoPanel = observer((props: BalancesInfoPanelProps) => {
  const { endBalances, startBalances, deltaBalances } = useStatsStore("balances");

  return (
    <styles.Container {...props}>
      <InfoPanelSection title="Start of period balance:" {...startBalances} />
      <InfoPanelSection title="End of period balance:" {...endBalances} />
      <InfoPanelSection title="Delta end - start:" {...deltaBalances} />
    </styles.Container>
  );
});
