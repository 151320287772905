import { makeAutoObservable, runInAction } from "mobx";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { getAllOpenOrders } from "src/api/bots/CEX/exchange";
import { calcRoundingValue, calcRoundingValues, toRounding } from "src/helpers/rounding";
import { keys } from "src/helpers/utils";
import { Order, OrderBookOrder } from "src/modules/exchange/orderBook";
import { CreatedLimitOrder } from "src/modules/exchange/trade";
import ExchangeStore from "..";
import { filterBaseNameAccounts } from "../../CEXApiKeys/AccountsBindings";
import { TerminalRequestMode } from "../shared/TerminalSettingsStore";
import DataStorageStore from "./DataStorageStore";

export interface OurOrder {
  price: string;
  amount: string;
  side: "sell" | "buy";
  total: string;
  time?: number;
}

export interface IOrderBookOrder {
  price: string;
  amount: string;
  total: string;
  ourAmount: string;
}

export interface IFillingOrderBook {
  totalQuote: number;
  totalBase: number;
  avg: number;
}

export type FillingState = IFillingOrderBook | null;

export type OrdersType = "ALL" | "OUR" | "ORGANIC";

type OrderBookMode = "ALL" | "BUY" | "SELL";

type SelectedRow = "selectedRowSell" | "selectedRowBuy";

const getPrecision = (number: number) => {
  const [significant, exponent] = String(number).split("e");
  const fractional = significant.split(".")[1];

  return (fractional?.length || 0) + Math.max(0, -(exponent || 0));
};

const pricePrecisionReducer = (accumulate: number, { price }: Order) =>
  Math.max(accumulate, getPrecision(+price));

// const amountPrecisionReducer = (accumulate: number, { amount }: Order) =>
//   Math.max(accumulate, getPrecision(+amount));

class OrderBookStore {
  private _ourSellOrders: OurOrder[] = [];

  private _ourBuyOrders: OurOrder[] = [];

  private _pricePrecision = 0;

  private _amountPrecision = 0;

  private _totalPrecision = 0;

  selectedRowSell: number | null = null;

  selectedRowBuy: number | null = null;

  openOrders: CreatedLimitOrder[] = [];

  currentOrderType: OrdersType = "ALL";

  currentMode: OrderBookMode = "ALL";

  mainStore: ExchangeStore;

  dataStorage: DataStorageStore;

  constructor(state: ExchangeStore) {
    this.mainStore = state;

    this.dataStorage = new DataStorageStore(this);

    this.mainStore.setUpdHandlers("updOrderBook", this.downloadData);

    makeAutoObservable(this);
  }

  downloadData = () => {
    this.dataStorage.loadData();

    this.downloadOpenOrders();
  };

  get pair() {
    return this.mainStore.pair;
  }

  get exchange() {
    return this.mainStore.exchange;
  }

  get terminalSettings() {
    return this.mainStore.terminalSettingsState;
  }

  get settingsConfig() {
    return this.terminalSettings.settingsConfig.orderBookModule;
  }

  get requestMode() {
    return this.settingsConfig.requestMode;
  }

  get exchStatusConnection() {
    return this.dataStorage.streamProvider.webSocketState.exchStatusConnection;
  }

  get exchangeDelay() {
    return this.dataStorage.streamProvider.webSocketState.exchangeDelayTitle;
  }

  get isLoading() {
    return this.dataStorage.isLoading;
  }

  get totalFillingSell(): FillingState {
    if (this.currentTotalSell)
      return {
        totalBase: this.currentTotalSell,
        totalQuote: this.currentTotalAssetSell,
        avg: this.currentAvgPriceSell,
      };

    return null;
  }

  get ourFillingSell(): FillingState {
    if (this.currentOurSell)
      return {
        totalBase: this.currentOurSell,
        totalQuote: this.currentOurAssetSell,
        avg: this.currentOurAvgPriceSell,
      };

    return null;
  }

  get organicFillingSell(): FillingState {
    if (this.currentOrganicSell)
      return {
        totalBase: this.currentOrganicSell,
        totalQuote: this.currentOrganicAssetSell,
        avg: this.currentOrganicAvgPriceSell,
      };

    return null;
  }

  get totalFillingBuy(): FillingState {
    if (this.currentTotalBuy)
      return {
        totalBase: this.currentTotalBuy,
        totalQuote: this.currentTotalAssetBuy,
        avg: this.currentAvgPriceBuy,
      };

    return null;
  }

  get ourFillingBuy(): FillingState {
    if (this.currentOurBuy)
      return {
        totalBase: this.currentOurBuy,
        totalQuote: this.currentOurAssetBuy,
        avg: this.currentOurAvgPriceBuy,
      };

    return null;
  }

  get organicFillingBuy(): FillingState {
    if (this.currentOrganicBuy)
      return {
        totalBase: this.currentOrganicBuy,
        totalQuote: this.currentOrganicAssetBuy,
        avg: this.currentOrganicAvgPriceBuy,
      };

    return null;
  }

  private get _orderBookSell(): Order[] {
    const responseData = this.dataStorage.data?.data;

    if (responseData) {
      return responseData.asks.map((item: Order) => ({
        ...item,
        id: nanoid(),
      }));
    }

    return [];
  }

  private get _orderBookBuy(): Order[] {
    const responseData = this.dataStorage.data?.data;

    if (responseData) {
      return responseData.bids
        .map((item: Order) => ({
          ...item,
          id: nanoid(),
        }))
        .reverse();
    }

    return [];
  }

  get showSellCup() {
    return this.currentMode === "ALL" || this.currentMode === "SELL";
  }

  get showBuyCup() {
    return this.currentMode === "ALL" || this.currentMode === "BUY";
  }

  private get _accounts() {
    const accounts = filterBaseNameAccounts(this.mainStore._accounts, ["info", "mm"]);
    return accounts;
  }

  get accountsUUID() {
    const accounts = this._accounts;

    const accountIds = Object.values(accounts)
      .map((account) => account?.uuid)
      .filter(Boolean) as string[];

    return accountIds;
  }

  get accounts() {
    return keys(this._accounts);
  }

  get ourOrderBookSellOrders(): IOrderBookOrder[] {
    return this._ourSellOrders
      .map(({ total, price, amount }) => ({
        price: toRounding(+price, this.pricePrecision),
        total: toRounding(+total, calcRoundingValue(+total)),
        amount,
        ourAmount: "0",
      }))
      .sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
  }

  get ourOriginSellOrders() {
    return this._ourSellOrders.map(({ total, price, ...order }) => ({
      ...order,
      price: toRounding(+price, this.pricePrecision),
      total: toRounding(+total, calcRoundingValue(+total)),
    }));
  }

  get organicSellOrders(): IOrderBookOrder[] {
    return this.orderBookSell
      .filter((order) => this.checkOrganicOrder(order))
      .map((order) => this.calcOrganicOrder(order));
  }

  get organicBuyOrders(): IOrderBookOrder[] {
    return this.orderBookBuy
      .filter((order) => this.checkOrganicOrder(order))
      .map((order) => this.calcOrganicOrder(order));
  }

  private checkOrganicOrder(order: OrderBookOrder) {
    if (
      parseFloat(order.amount) === parseFloat(order.ourAmount) ||
      parseFloat(order.amount) < parseFloat(order.ourAmount)
    )
      return false;

    return true;
  }

  private calcOrganicOrder = (order: IOrderBookOrder) => {
    const organicAmount = toRounding(
      parseFloat(order.amount) - parseFloat(order.ourAmount),
      this.amountPrecision
    );

    return {
      ...order,
      amount: organicAmount,
      ourAmount: "0",
      total: toRounding(+organicAmount * +order.price, this.pricePrecision),
    };
  };

  get ourOrderBookBuyOrders(): IOrderBookOrder[] {
    return this._ourBuyOrders.map(({ total, price, amount }) => ({
      price: toRounding(+price, this.pricePrecision),
      total: toRounding(+total, calcRoundingValue(+total)),
      amount,
      ourAmount: "0",
    }));
  }

  get ourOriginBuyOrders() {
    return this._ourBuyOrders.map(({ total, price, ...order }) => ({
      ...order,
      price: toRounding(+price, this.pricePrecision),
      total: toRounding(+total, calcRoundingValue(+total)),
    }));
  }

  get pricePrecision() {
    return this.mainStore.pricePrecision;
  }

  get amountPrecision() {
    return this.mainStore.amountPrecision;
  }

  get calculatedPricePrecision() {
    return this._pricePrecision;
  }

  get calculatedAmountPrecision() {
    return this._amountPrecision;
  }

  get totalPrecision() {
    return this._totalPrecision;
  }

  get orderBookSell(): IOrderBookOrder[] {
    return this._orderBookSell.map(({ price, amount, ...rest }) => ({
      ...rest,
      price: toRounding(+price, this.pricePrecision),
      amount: toRounding(+amount, this.amountPrecision),
      ourAmount: toRounding(this.findOrder(price, this._ourSellOrders), this.amountPrecision),
      total: toRounding(+price * +amount, this.pricePrecision),
    }));
  }

  get orderBookBuy(): IOrderBookOrder[] {
    return this._orderBookBuy.map(({ price, amount, ...rest }) => ({
      ...rest,
      price: toRounding(+price, this.pricePrecision),
      amount: toRounding(+amount, this.amountPrecision),
      ourAmount: toRounding(this.findOrder(price, this._ourBuyOrders), this.amountPrecision),
      total: toRounding(+price * +amount, this.pricePrecision),
    }));
  }

  get maxBuy() {
    if (this._orderBookBuy.length) {
      return parseFloat(this._orderBookBuy[0]?.price);
    }
    return 0;
  }

  get minSell() {
    return parseFloat(this._orderBookSell[this.orderBookSell.length - 1]?.price);
  }

  get spreadPercent(): string {
    if (this.minSell) {
      return (((+this.minSell - +this.maxBuy) / +this.maxBuy) * 100)?.toFixed(1);
    }
    return "0";
  }

  get rowIsSelectedSell(): boolean {
    return this.selectedRowSell !== null;
  }

  get rowIsSelectedBuy(): boolean {
    return this.selectedRowBuy !== null;
  }

  get selectedSellOrder() {
    if (this.selectedRowSell === null) return null;

    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookSell[this.selectedRowSell];

      case "OUR":
        return this.ourOrderBookSellOrders[this.selectedRowSell];

      case "ORGANIC":
        return this.organicSellOrders[this.selectedRowSell];

      default:
        return null;
    }
  }

  get selectedBuyOrder() {
    if (this.selectedRowBuy === null) return null;

    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookBuy[this.selectedRowBuy];

      case "OUR":
        return this.ourOrderBookBuyOrders[this.selectedRowBuy];

      case "ORGANIC":
        return this.organicBuyOrders[this.selectedRowBuy];

      default:
        return null;
    }
  }

  get currentTotalSell() {
    return parseFloat(toRounding(this._currentTotalSell, this.amountPrecision));
  }

  private get _currentTotalSell() {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookSell
          .slice(Number(this.selectedRowSell), this.orderBookSell.length)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      case "OUR":
        return this.ourOrderBookSellOrders
          .slice(Number(this.selectedRowSell), this.ourOrderBookSellOrders.length)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      case "ORGANIC":
        return this.organicSellOrders
          .slice(Number(this.selectedRowSell), this.organicSellOrders.length)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      default:
        return 0;
    }
  }

  get currentOurSell() {
    return parseFloat(toRounding(this._currentOurSell, this.amountPrecision));
  }

  private get _currentOurSell() {
    return this.orderBookSell
      .slice(Number(this.selectedRowSell), this.orderBookSell.length)
      .reduce((accumulator, { ourAmount }) => accumulator + Number(ourAmount), 0);
  }

  get currentOrganicSell() {
    return parseFloat(toRounding(this._currentOrganicSell, this.amountPrecision));
  }

  private get _currentOrganicSell(): number {
    return this.orderBookSell
      .slice(Number(this.selectedRowSell), this.orderBookSell.length)
      .reduce(
        (accumulator, { amount, ourAmount }) => accumulator + Number(amount) - Number(ourAmount),
        0
      );
  }

  get currentTotalBuy() {
    return parseFloat(toRounding(this._currentTotalBuy, this.amountPrecision));
  }

  private get _currentTotalBuy(): number {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookBuy
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      case "OUR":
        return this.ourOrderBookBuyOrders
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      case "ORGANIC":
        return this.organicBuyOrders
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce((accumulator, { amount }) => accumulator + Number(amount), 0);

      default:
        return 0;
    }
  }

  get currentOurBuy() {
    return parseFloat(toRounding(this._currentOurBuy, this.amountPrecision));
  }

  private get _currentOurBuy(): number {
    return this.orderBookBuy
      .slice(0, Number(this.selectedRowBuy) + 1)
      .reduce((accumulator, { ourAmount }) => accumulator + Number(ourAmount), 0);
  }

  get currentOrganicBuy() {
    return parseFloat(toRounding(this._currentOrganicBuy, this.amountPrecision));
  }

  private get _currentOrganicBuy(): number {
    return this.orderBookBuy
      .slice(0, Number(this.selectedRowBuy) + 1)
      .reduce(
        (accumulator, { amount, ourAmount }) => accumulator + Number(amount) - Number(ourAmount),
        0
      );
  }

  get currentTotalAssetSell() {
    return parseFloat(
      toRounding(this._currentTotalAssetSell, calcRoundingValue(this._currentTotalAssetSell))
    );
  }

  private get _currentTotalAssetSell(): number {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookSell
          .slice(Number(this.selectedRowSell), this.orderBookSell.length)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      case "OUR":
        return this.ourOrderBookSellOrders
          .slice(Number(this.selectedRowSell), this.ourOrderBookSellOrders.length)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      case "ORGANIC":
        return this.organicSellOrders
          .slice(Number(this.selectedRowSell), this.organicSellOrders.length)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      default:
        return 0;
    }
  }

  get currentOurAssetSell() {
    return parseFloat(
      toRounding(this._currentOurAssetSell, calcRoundingValue(this._currentOurAssetSell))
    );
  }

  private get _currentOurAssetSell() {
    return this.orderBookSell
      .slice(Number(this.selectedRowSell), this.orderBookSell.length)
      .reduce(
        (accumulator, { ourAmount, price }) => accumulator + Number(ourAmount) * Number(price),
        0
      );
  }

  get currentOrganicAssetSell() {
    return parseFloat(
      toRounding(this._currentOrganicAssetSell, calcRoundingValue(this._currentOrganicAssetSell))
    );
  }

  private get _currentOrganicAssetSell(): number {
    return this.orderBookSell
      .slice(Number(this.selectedRowSell), this.orderBookSell.length)
      .reduce(
        (accumulator, { amount, ourAmount, price }) =>
          accumulator + (Number(amount) - Number(ourAmount)) * Number(price),
        0
      );
  }

  get currentTotalAssetBuy() {
    return parseFloat(
      toRounding(this._currentTotalAssetBuy, calcRoundingValue(this._currentTotalAssetBuy))
    );
  }

  private get _currentTotalAssetBuy(): number {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookBuy
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      case "OUR":
        return this.ourOrderBookBuyOrders
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      case "ORGANIC":
        return this.organicBuyOrders
          .slice(0, Number(this.selectedRowBuy) + 1)
          .reduce(
            (accumulator, { amount, price }) => accumulator + Number(amount) * Number(price),
            0
          );

      default:
        return 0;
    }
  }

  get currentOurAssetBuy() {
    return parseFloat(
      toRounding(this._currentOurAssetBuy, calcRoundingValue(this._currentOurAssetBuy))
    );
  }

  private get _currentOurAssetBuy(): number {
    return this.orderBookBuy
      .slice(0, Number(this.selectedRowBuy) + 1)
      .reduce(
        (accumulator, { ourAmount, price }) => accumulator + Number(ourAmount) * Number(price),
        0
      );
  }

  get currentOrganicAssetBuy() {
    return parseFloat(
      toRounding(this._currentOrganicAssetBuy, calcRoundingValue(this._currentOrganicAssetBuy))
    );
  }

  private get _currentOrganicAssetBuy(): number {
    return this.orderBookBuy
      .slice(0, Number(this.selectedRowBuy) + 1)
      .reduce(
        (accumulator, { amount, ourAmount, price }) =>
          accumulator + (Number(amount) - Number(ourAmount)) * Number(price),
        0
      );
  }

  get currentAvgPriceSell() {
    return parseFloat(
      this._currentAvgPriceSell?.toFixed(calcRoundingValue(+this._currentAvgPriceSell))
    );
  }

  private get _currentAvgPriceSell() {
    if (this._currentTotalAssetSell && this._currentTotalSell)
      return this._currentTotalAssetSell / this._currentTotalSell;
    return 0;
  }

  get currentOurAvgPriceSell() {
    return parseFloat(toRounding(this._currentOurAvgPriceSell, this.pricePrecision));
  }

  private get _currentOurAvgPriceSell(): number {
    if (this._currentOurAssetSell && this._currentOurSell)
      return this._currentOurAssetSell / this._currentOurSell;
    return 0;
  }

  get currentOrganicAvgPriceSell() {
    return parseFloat(toRounding(this._currentOrganicAvgPriceSell, this.pricePrecision));
  }

  private get _currentOrganicAvgPriceSell(): number {
    if (this._currentOrganicAssetSell && this._currentOrganicSell)
      return this._currentOrganicAssetSell / this._currentOrganicSell;
    return 0;
  }

  get currentAvgPriceBuy() {
    return parseFloat(
      this._currentAvgPriceBuy.toFixed(calcRoundingValue(this._currentAvgPriceBuy))
    );
  }

  get _currentAvgPriceBuy(): number {
    if (this.currentTotalAssetBuy && this._currentTotalBuy)
      return this.currentTotalAssetBuy / this._currentTotalBuy;
    return 0;
  }

  get currentOurAvgPriceBuy() {
    return parseFloat(toRounding(this._currentOurAvgPriceBuy, this.pricePrecision));
  }

  private get _currentOurAvgPriceBuy(): number {
    if (this._currentOurAssetBuy && this._currentOurBuy)
      return this._currentOurAssetBuy / this._currentOurBuy;
    return 0;
  }

  get currentOrganicAvgPriceBuy() {
    return parseFloat(toRounding(this._currentOrganicAvgPriceBuy, this.pricePrecision));
  }

  private get _currentOrganicAvgPriceBuy(): number {
    if (this._currentOrganicAssetBuy && this._currentOrganicBuy)
      return this._currentOrganicAssetBuy / this._currentOrganicBuy;
    return 0;
  }

  get sellOrders() {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookSell;

      case "OUR":
        return this.ourOrderBookSellOrders;

      case "ORGANIC":
        return this.organicSellOrders;

      default:
        return [];
    }
  }

  get buyOrders() {
    switch (this.currentOrderType) {
      case "ALL":
        return this.orderBookBuy;

      case "OUR":
        return this.ourOrderBookBuyOrders;

      case "ORGANIC":
        return this.organicBuyOrders;

      default:
        return [];
    }
  }

  get depthSellOrders() {
    const orders = this.orderBookSell.slice().reverse();
    const depthOrders = [];
    let reduceVolume = 0;

    for (const el of orders) {
      reduceVolume += +el.total;

      depthOrders.push({ ...el, volume: reduceVolume });
    }

    return depthOrders;
  }

  get depthBuyOrders() {
    const orders = this.orderBookBuy.slice();
    const depthOrders = [];
    let reduceVolume = 0;

    for (const el of orders) {
      reduceVolume += +el.total;

      depthOrders.push({ ...el, volume: reduceVolume });
    }

    return depthOrders.reverse();
  }

  private _getPrecision = (orders: Order[], field: keyof Order) => {
    const values: string[] = [];
    orders.forEach((el) => values.push(el[field]));

    return calcRoundingValues(values);
  };

  toggleOrdersType = (type: OrdersType) => {
    this.currentOrderType = type;

    this.resetSellCupScroll();
  };

  resetSellCupScroll = () => {
    this.dataStorage.resetScroll();
  };

  setSelectedRow = (field: SelectedRow, value: number | null): void => {
    this[field] = value;
  };

  findOrder = (price: string, orders: OurOrder[]): number => {
    const findOrder = orders.find((el) => +el.price === +price);
    if (findOrder) {
      return +findOrder.amount;
    }
    return 0;
  };

  downloadOrderBook = () => {
    this.dataStorage.loadData();
  };

  downloadOpenOrders = () => {
    if (this.accountsUUID.length) this._fetchAllOpenOrders(this.mainStore.pair);
  };

  setCurrentMode = (mode: OrderBookMode) => {
    this.currentMode = mode;
  };

  setStreamMode = (mode: TerminalRequestMode) => {
    if (mode === "WS" && this.dataStorage.lockedConnect) {
      toast.error("WS connection already open");
      return;
    }

    this.terminalSettings.toggleRequestMode("orderBookModule", mode);
  };

  calcPrecisions = () => {
    if (this.dataStorage.firstLoad) {
      this._calcPricePrecision();
      this._calcAmountPrecision();
      this._calcTotalPrecision();
    }
  };

  private _calcPricePrecision = () => {
    if (this._orderBookBuy.length === 0 && this._orderBookSell.length === 0) {
      this._pricePrecision = 0;
    } else {
      this._pricePrecision = Math.max(
        this._orderBookBuy.reduce(pricePrecisionReducer, 0),
        this._orderBookSell.reduce(pricePrecisionReducer, 0)
      );
    }
  };

  private _calcAmountPrecision = () => {
    const orders = this._orderBookBuy.concat(this._orderBookSell);

    if (orders.length) {
      this._amountPrecision = this._getPrecision(orders, "amount");
    } else if (this._orderBookBuy.length === 0 && this._orderBookSell.length === 0) {
      this._amountPrecision = 8;
    } else {
      this._amountPrecision = 0;
    }
  };

  private _calcTotalPrecision = () => {
    const orders = this.orderBookBuy.concat(this.orderBookSell);
    if (orders.length) {
      const totals = [];
      for (const el of orders) {
        totals.push(el.total);
      }
      this._totalPrecision = calcRoundingValues(totals);
    } else {
      this._totalPrecision = 0;
    }
  };

  private _fetchAllOpenOrders = async (pair: string) => {
    try {
      const {
        isError,
        data: { buy_orders, sell_orders },
      } = await getAllOpenOrders({
        pair,
        account_uuids: this.accountsUUID,
      });

      if (!isError) {
        runInAction(() => {
          this._ourBuyOrders = buy_orders;
        });

        runInAction(() => {
          this._ourSellOrders = sell_orders;
        });
      }
    } catch {
      runInAction(() => {
        this._ourBuyOrders = [];
      });

      runInAction(() => {
        this._ourSellOrders = [];
      });
    }
  };

  destroy = () => {
    this.dataStorage.closeConnect();
    this.dataStorage.unsubscribe();
  };
}

export default OrderBookStore;
