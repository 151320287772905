import { mobileMediaQuery } from "src/components/UserManagement/Teams/style";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";
import { KPIPanel as BaseKPIPanel } from "../../../KPIPanel";
import { KPIAreas } from "../../../KPIPanel/style";
import { BalancesCard } from "../../../OldBalancesOverviewChart/style";
import { PNLCard } from "../../../ProfitLossChart/style";
import { MainContentWrapper } from "../../../shared/Card/style";
import { DashboardBreakpoints } from "../style";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
`;

export enum StatsAreas {
  Pnl = "pnl",
  Exchange = "exchange",
  Balances = "balances",
  BuyLiquidity = "buy-liquidity",
  SellLiquidity = "sell-liquidity",
  Volume = "volume",
  Table = "table",
}

export const StatsContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(3, minmax(200px, 2fr));
  grid-template-rows: 288px repeat(2, 170px) 340px;

  grid-template-areas:
    "${StatsAreas.Pnl}           ${StatsAreas.Pnl}              ${StatsAreas.Exchange}"
    "${StatsAreas.Balances}      ${StatsAreas.Balances}         ${StatsAreas.BuyLiquidity}"
    "${StatsAreas.Balances}      ${StatsAreas.Balances}         ${StatsAreas.SellLiquidity}"
    "${StatsAreas.Table}         ${StatsAreas.Table}            ${StatsAreas.Volume}";
  gap: 16px 8px;

  @media ${mobileMediaQuery(DashboardBreakpoints.Tablet)} {
    grid-template-columns: repeat(2, minmax(150px, 2fr)) minmax(200px, 3fr);

    ${PNLCard} {
      ${MainContentWrapper} {
        width: calc(100% - 120px);
      }
    }

    ${BalancesCard} {
      ${MainContentWrapper} {
        width: calc(100% - 120px);
      }
    }
  }

  @media ${mobileMediaQuery(DashboardBreakpoints.XMobile)} {
    grid-template-rows: 340px 440px 480px 183px 183px 375px;
    grid-template-areas:
      "${StatsAreas.Pnl}"
      "${StatsAreas.Exchange}"
      "${StatsAreas.Balances}" "${StatsAreas.BuyLiquidity}"
      "${StatsAreas.SellLiquidity}"
      "${StatsAreas.Volume}";
    grid-template-columns: minmax(150px, 1fr);
  }
`;

export const StatsKPIPanel = styled(BaseKPIPanel)`
  @media ${mobileMediaQuery(DashboardBreakpoints.Tablet)} {
    grid-template-columns: minmax(100px, 2fr) repeat(2, minmax(50px, 1fr)) minmax(100px, 2fr);
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
      "${KPIAreas.Aum}  ${KPIAreas.Balance}  ${KPIAreas.Balance}  ${KPIAreas.Impact} "
      "${KPIAreas.PNL}  ${KPIAreas.PNL}      ${KPIAreas.Tokens}   ${KPIAreas.Tokens}";
  }
`;

export const KPIFallback = styled(CrashPanel)`
  min-height: 130px;
`;

export const PNLFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.Pnl};
`;

export const BalancesFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.Balances};
`;

export const ExchangesFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.Exchange};
`;

export const SellLiquidityFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.SellLiquidity};
`;

export const BuyLiquidityFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.BuyLiquidity};
`;

export const VolumeFallback = styled(CrashPanel)`
  grid-area: ${StatsAreas.Volume};
`;
