import { InputEventType, OutputEventType } from "src/state/DEXV2/DEXV2Settings";
import styled from "styled-components";

export interface EventSideSpanProps {
  type?: InputEventType | OutputEventType;
}

export const EventSideSpan = styled.span<EventSideSpanProps>`
  color: ${({ type: side, theme: { successColor, dangerColor } }) =>
    side === "buy" ? successColor : dangerColor};
`;
