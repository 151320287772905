import { observer } from "mobx-react-lite";
import { ApiKeysPanelHeaderProps } from "src/components/BotsContent/CEX/CEXApiKeys/ApiKeysPanel";
import { AccountSelector } from "src/components/BotsContent/CEX/CEXApiKeys/shared/Account/AccountSelector";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { BotAccountName } from "src/modules/accounts";
import useAppState from "src/state";
import * as styles from "./style";

export interface AccountProps {
  accountName: BotAccountName;
}

export const Account = observer(({ accountName }: AccountProps) => {
  const { createBotState: state } = useAppState();

  const Selector = (
    <AccountSelector
      options={state.bindings.accountBindingSelectorOptions(accountName)}
      onChange={state.bindings.onAccountBindingSelected(accountName)}
      value={state.bindings.selectedAccountBinding(accountName)}
      errorHint={state.accountError(accountName)}
      isDisabled={!state.accountsBindingEnabled}
      fixed
    />
  );

  const headerProps: ApiKeysPanelHeaderProps = {
    btn: (
      <DeleteButton
        disabled={!state.deleteEnabled(accountName)}
        onClick={state.deleteAccount(accountName)}
      />
    ),
  };

  return <styles.ApiKeysPanel title={accountName} selector={Selector} headerProps={headerProps} />;
});
