import { ChainId } from "./chains";

export type ChainSymbolMap = Partial<Record<string, string>>;

export const chainSymbolMap: ChainSymbolMap = {
  [ChainId.BSC]: "bsc",
  [ChainId.Ethereum]: "ethereum",
  [ChainId.Avalanche]: "avalanche",
  [ChainId.Fantom]: "fantom",
  [ChainId.Polygon]: "polygon",
  [ChainId.Arbitrum]: "arbitrum",
  [ChainId.Base]: "base",
  [ChainId.zkSync]: "zksync",
  [ChainId.Mantle]: "mantle",
  [ChainId.Core]: "core",
  [ChainId.Kava]: "kava",
};
