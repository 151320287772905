import { useMemo } from "react";
import { Doughnut, DoughnutProps } from "src/components/shared/Charts/Doughnut";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useDashboardColors } from "../../hooks/useDashboardColors";

const useBaseOptions = () => {
  const { textAdditional } = useDashboardColors();
  const options: DoughnutProps<any, any>["options"] = useMemo(
    () => ({
      plugins: {
        legend: {
          position: "right",
          labels: {
            pointStyle: "circle",
            usePointStyle: true,
            boxHeight: 4,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
          },
        },
      },
      cutout: 25,
      maintainAspectRatio: false,
      layout: { padding: 8 },
    }),
    [textAdditional]
  );

  return options;
};

const useChartProps = <TData, TLabel>({
  options,
  ...props
}: DoughnutProps<TData, TLabel>): DoughnutProps<TData, TLabel> => {
  const baseOptions = useBaseOptions();

  const chartProps = {
    options: deepMerge(baseOptions, options),
    ...props,
  };

  return chartProps;
};

export interface BaseDoughnutProps<TData, TLabel> extends DoughnutProps<TData, TLabel> {}

export const BaseDoughnut = <TData = number[], TLabel = unknown>(
  props: BaseDoughnutProps<TData, TLabel>
) => {
  const chartProps = useChartProps(props);

  return <Doughnut {...chartProps} />;
};
