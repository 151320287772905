import { observer } from "mobx-react-lite";
import { ComponentType } from "react";
import { TooltipProps } from "src/components/shared/Graph/Tooltip";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import * as styles from "./style";

const useSeriesTitles = () => {
  const { seriesTitles } = useTooltipContext();
  return seriesTitles;
};

interface GraphTooltipSeriesProps {
  title: string;
}

export interface GraphTooltipRootProps extends TooltipProps {
  date?: JSX.Element;
  series: ComponentType<GraphTooltipSeriesProps>;
}

export const GraphTooltipRoot = observer(
  ({ ownRef, date, series: SeriesComponent, ...props }: GraphTooltipRootProps) => {
    const seriesTitles = useSeriesTitles();

    const Date = date ?? <styles.Date />;

    return (
      <styles.Container ref={ownRef} {...props}>
        <styles.Content>
          {Date}
          <styles.SeriesWrapper>
            {seriesTitles.map((title) => (
              <SeriesComponent title={title} key={title} />
            ))}
          </styles.SeriesWrapper>
        </styles.Content>
      </styles.Container>
    );
  }
);
