import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { UseFormUnregister, useForm } from "react-hook-form";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { ChooseSettingParamsStoreContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import {
  ChangeableCEXSettings,
  SettingsParamsPaths,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";
import { settingsValidationSchema } from "src/state/expertSystem/strategies/changeSettingsModule/shared/validation";
import { SubModuleShell } from "../../../../../shared/SubModuleShell";
import { getControlConfig } from "../../../../../shared/utils/changeSettings";
import { SwitchController } from "./SwitchController";
import { useYupValidationResolver } from "./shared/hooks/useYupValidationResolver";
import * as styles from "./style";

export interface FormRowsProps {
  params: SettingsParamsPaths[];
  removeCb: (
    param: SettingsParamsPaths,
    unregister: UseFormUnregister<Partial<ChangeableCEXSettings>>
  ) => () => void;
}

export const FormRows = observer(({ params, removeCb }: FormRowsProps) => {
  const state = useLateInitContext(ChooseSettingParamsStoreContext);

  const { control, unregister, setValue, handleSubmit } = useForm<Partial<ChangeableCEXSettings>>({
    // @ts-ignore
    resolver: useYupValidationResolver(settingsValidationSchema),
  });

  useEffect(() => {
    state.setFormSetter(setValue);
  }, [state, setValue]);

  return (
    <>
      <styles.RowsContainer>
        {params.map((param) => (
          <SubModuleShell
            key={param}
            headerProps={{
              remove: removeCb(param, unregister),
            }}
          >
            <SwitchController
              config={getControlConfig(param)}
              control={control}
              name={param}
              param={param}
            />
          </SubModuleShell>
        ))}
      </styles.RowsContainer>

      <OutlineButton
        disabled={state.isAddedParams}
        size="m"
        onClick={handleSubmit(state.generateSettingsConfig)}
      >
        Confirm
      </OutlineButton>
    </>
  );
});
