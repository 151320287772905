import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import React from "react";
import { FormErrors, FormValidation } from "src/helpers/forms/types";
import { Disposable } from "src/helpers/utils";
import { AccountApi, AccountType, BotAccountName, VolumeAccountName } from "src/modules/accounts";
import { SelectorValue } from "src/modules/shared";
import { required, validateData } from "src/validation-schemas";
import { IAccountsBindingsProvider, accountToSelectorValue } from "./AccountsBindings";
import { INITIAL_ACCOUNT } from "./AddLiquidityBinding";

export interface AddVolumeBindingForm {
  name: BotAccountName<"volume">;
  account: AccountApi;
}
export interface IBotUUIDProvider {
  get botUUID(): string;
}

const INITIAL_ADD_VOLUME_BINDING: AddVolumeBindingForm = {
  name: "mm",
  account: INITIAL_ACCOUNT,
};

export type FormSelectors = "accountName";

export type SetLoading = (loading: boolean) => void;
export interface AddBindingParams {
  setLoading?: SetLoading;
  onSuccess?: () => void;
}

export type AddAccountCallback<T extends AccountType> = (
  botAccountName: BotAccountName<T>,
  accountToBind: AccountApi
) => Promise<boolean>;
export interface IAddVolumeBindingParams extends AddBindingParams {
  bindingsProvider: IAccountsBindingsProvider;
  onAddAccount: AddAccountCallback<"volume">;
}

export default class AddVolumeBinding implements Disposable {
  private _data: AddVolumeBindingForm = INITIAL_ADD_VOLUME_BINDING;

  private _errors: FormErrors<AddVolumeBindingForm> = {};

  private _validation: FormValidation<AddVolumeBindingForm> = {
    name: [required()],
    "account.name": [required()],
    "account.uuid": [required()],
  };

  private _bindingsProvider: IAccountsBindingsProvider;

  private _setLoading?: SetLoading;

  private _onSuccess?: () => void;

  private _onAddAccount: AddAccountCallback<"volume">;

  constructor({ bindingsProvider, setLoading, onSuccess, onAddAccount }: IAddVolumeBindingParams) {
    makeAutoObservable(this);

    this._bindingsProvider = bindingsProvider;
    this._setLoading = setLoading;
    this._onSuccess = onSuccess;
    this._onAddAccount = onAddAccount;
  }

  get data() {
    return this._data;
  }

  get errors() {
    return this._errors;
  }

  private get _exchangeAccounts() {
    return this._bindingsProvider.exchangeAccounts;
  }

  private _updateSelectedAccount = (account: AccountApi) => {
    this._data.account = account;
  };

  private get _selectedAccount(): SelectorValue | null {
    const { account } = this._data;
    if (!account.name) return null;

    return accountToSelectorValue(account);
  }

  private get _accountSelectorOptions(): SelectorValue[] {
    const accountName = this.data.name;
    return this._bindingsProvider.accountBindingSelectorOptions(accountName);
  }

  private _onAccountSelected = (value: SelectorValue | null) => {
    if (!value) {
      this._updateSelectedAccount(INITIAL_ACCOUNT);
      return;
    }

    const accountId = String(value.id);

    const selectedAccount = this._exchangeAccounts.find(({ uuid }) => uuid === accountId);

    if (!selectedAccount) return;

    this._updateSelectedAccount(selectedAccount);
  };

  selectorHandler = (key: FormSelectors) => (value: SelectorValue | null) => {
    switch (key) {
      case "accountName": {
        this._onAccountSelected(value);
      }
    }
  };

  selectorOptions = computedFn((key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return this._accountSelectorOptions;
      }
    }
  });

  selectorValue = computedFn((key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return this._selectedAccount;
      }
    }
  });

  private _validate = () => validateData(this._validation, this._data, this._errors, undefined);

  private _addAccount = async (botAccountName: VolumeAccountName, accountToBind: AccountApi) => {
    try {
      this._setLoading?.(true);

      const isError = await this._onAddAccount(botAccountName, accountToBind);

      if (!isError) {
        this._onSuccess?.();
      }
    } finally {
      this._setLoading?.(false);
    }
  };

  addAccount = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const valid = this._validate();

    if (!valid) return;

    const accountToBind = this._data.account;
    const accountName = this._data.name;

    this._addAccount(accountName, accountToBind);
  };

  destroy = () => {};
}
