import * as BaseStyles from "src/components/shared/Graph/Tooltip/TooltipSeries/style";
import styled from "styled-components";
import { QuotesInfo as BaseQuotesInfo } from "../../../../Tables/Vaults/VaultsQuotesTooltip/QuotesInfo";
import { Quote } from "../../../../Tables/Vaults/VaultsQuotesTooltip/QuotesInfo/style";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SeriesWrapper = styled(BaseStyles.SeriesContainer)``;

export const QuotesWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
  flex-direction: column;
`;

export const QuotesInfo = styled(BaseQuotesInfo)`
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;

  ${Quote} {
    width: auto;
    color: ${({ theme }) => theme.inputTextColor};
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const NameContainer = styled(BaseStyles.SeriesNameContainer)``;

export const Color = styled(BaseStyles.SeriesColor)``;

export const Name = styled(BaseStyles.SeriesName)``;

export const Price = styled(BaseStyles.SeriesPrice)``;
