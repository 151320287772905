import { observer } from "mobx-react-lite";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import useAppState from "src/state";
import * as styles from "./styles";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";

interface Props {
  showLoader: boolean;
  party: string;
}

const CANDLES_INFO = `Candles:
5 or 15 minute period`;

export const CandleCloser = observer(({ party, showLoader }: Props) => {
  const { SettingsBotState: state } = useAppState();

  const items = [
    { value: 5, label: "5" },
    { value: 15, label: "15" },
  ];

  return (
    <styles.CandlesSettings
      label="Candle Closer"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("candleCloser")}
      info={CANDLES_INFO}
      saved={state.savedModuls.candleCloser}
      disabled={!state.data.settings.candleCloser.on}
      toggle={
        <SettingsToggleSwitch
          party={party}
          checked={state.data.settings.candleCloser.on}
          onChange={state.getOnHandler("candleCloser")}
        />
      }
      style={{ gridArea: "candle" }}
    >
      <styles.InputsWrapper>
        <styles.CandlesSwitches
          label="Period"
          items={items}
          selectedItem={state.data.settings.candleCloser.period}
          onChange={state.getHandler("settings.candleCloser.period")}
        />

        <styles.RangeCheckbox
          label="ONLY out of range"
          checked={state.data.settings.candleCloser.outOfRange}
          onChange={state.getHandler("settings.candleCloser.outOfRange")}
        />
      </styles.InputsWrapper>
    </styles.CandlesSettings>
  );
});
