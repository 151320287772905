import { Currency, CurrencyAmount, Token, TradeType } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import { IGetRouteStateParams, IRouteStateProvider } from "../../../shared/Swap/Router";
import { TokenAmount } from "../../../shared/Swap/entities/TokenAmount";
import { V3Route } from "../entities/V3Route";
import { V3Trade } from "../entities/V3Trade";
import { IV3QuotesProvider } from "./V3QuotesProvider";
import { IV3RoutesProvider } from "./V3RoutesProvider";

export enum V3SwapRouteError {
  QuoteError = "QuoteError",
}

export type V3SwapRoute = {
  trade: V3Trade<Token, Token, TradeType>;

  route: V3Route<Token, Token>;
};

export interface IV3RouteStateParams {
  routesProvider: IV3RoutesProvider;
  quotesProvider: IV3QuotesProvider;
}

type TradeAmounts<TInput extends Currency, TOutput extends Currency> = {
  amountIn: CurrencyAmount<TInput>;
  amountOut: CurrencyAmount<TOutput>;
};

export class V3RouteStateProvider implements IRouteStateProvider {
  private _routesProvider: IV3RoutesProvider;

  private _quotesProvider: IV3QuotesProvider;

  constructor({ routesProvider, quotesProvider }: IV3RouteStateParams) {
    this._routesProvider = routesProvider;
    this._quotesProvider = quotesProvider;
  }

  private _getAmounts = <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: TokenAmount,
    tradeType: TradeType,
    quote: BigNumber
  ): TradeAmounts<TInput, TOutput> => {
    const quoteAmount = quote.toString();

    if (tradeType === TradeType.EXACT_INPUT) {
      const amountIn = amount as CurrencyAmount<TInput>;

      const amountOut = CurrencyAmount.fromRawAmount(route.output, quoteAmount);

      return { amountIn, amountOut };
    }

    const amountOut = amount as CurrencyAmount<TOutput>;

    const amountIn = CurrencyAmount.fromRawAmount(route.input, quoteAmount);

    return { amountIn, amountOut };
  };

  getRouteState = async ({ path, pools, amount, swapType, options }: IGetRouteStateParams) => {
    const route = await this._routesProvider.getRoute(path, pools, options);
    if (!route) return null;

    const quote = await this._quotesProvider.getQuote(route, amount, swapType);

    if (!quote) return null;

    const { amountOut, sqrtPriceX96After } = quote;

    const tradeAmount = this._getAmounts(route, amount, swapType, amountOut);

    const trade = new V3Trade({
      route,
      inputAmount: tradeAmount.amountIn,
      outputAmount: tradeAmount.amountOut,
      tradeType: swapType,
      sqrtPriceX96After: sqrtPriceX96After.toString(),
    });

    return { trade, route };
  };
}
