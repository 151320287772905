import styled from "styled-components";

export const Row = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-column-gap: 10px;
  grid-template-columns:
    45px 95px
    /* 20px */
    20px repeat(3, minmax(90px, 1fr));
  :not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const Header = styled(Row)`
  /* grid-template-columns: 45px 130px 20px 20px repeat(3, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: 45px 1fr 20px 20px repeat(3, 1fr);
  } */
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  height: 210px;
  // overflow-y: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
  @media (max-width: 500px) {
    width: fit-content;
  }
`;

export const Title = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.textColor};
`;

export const Copy = styled.button`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  fill: #767676;
  border: none;
  background-color: inherit;
  cursor: pointer;
  & > svg {
    width: 15px;
    height: 15px;
  }
`;
