import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import useAppState from "src/state";
import * as styles from "./style";

interface Props {
  showLoader: boolean;
  party: string;
}

const TRADE_INFO = `Trade:
Buy Percent — Buy order drop chance

BBsMin — minimum pause value between Orders (in seconds)

BBsMax — maximum pause value between Orders (in seconds)

TradesMin — minimum Trades value in Period

TradesMax — maximum Trades value in Period`;

export const TradeSettings = observer(({ party, showLoader, ...props }: Props) => {
  const { SettingsBotState: state } = useAppState();

  return (
    <SettingsPanel
      label="Trade"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("trade")}
      style={{ gridArea: "trade" }}
      info={TRADE_INFO}
      saved={state.savedModuls.trade}
      {...props}
    >
      <LabeledPercentRangeInput
        label="BuyPercent"
        value={state.data.settings.trade.buyPercent}
        onChange={state.getHandler("settings.trade.buyPercent")}
        // @ts-ignore
        getError={state.getError("settings.trade.buyPercent")}
        errorHint={state.errors.settings?.trade?.buyPercent}
      />

      <styles.HorizontalWrap>
        <LabeledInput
          type="number"
          label="BBsMin"
          value={state.data.settings.trade.buyBeforeSellMin}
          onChange={state.getHandler("settings.trade.buyBeforeSellMin")}
          // @ts-ignore
          getError={state.getError("settings.trade.buyBeforeSellMin")}
          errorHint={state.errors.settings?.trade?.buyBeforeSellMin}
          step="any"
        />
        <LabeledInput
          type="number"
          label="BBsMax"
          value={state.data.settings.trade.buyBeforeSellMax}
          onChange={state.getHandler("settings.trade.buyBeforeSellMax")}
          // @ts-ignore
          getError={state.getError("settings.trade.buyBeforeSellMax")}
          errorHint={state.errors.settings?.trade?.buyBeforeSellMax}
          step="any"
        />
      </styles.HorizontalWrap>

      <styles.TradesMinWrap>
        <LabeledInput
          type="number"
          label="TradesMin"
          value={state.data.settings.trade.minTrades}
          onChange={state.getHandler("settings.trade.minTrades")}
          // @ts-ignore
          getError={state.getError("settings.trade.minTrades")}
          errorHint={state.errors.settings?.trade?.minTrades}
          step="any"
        />
      </styles.TradesMinWrap>

      <LabeledInput
        type="number"
        label="TradesMax"
        value={state.data.settings.trade.maxTrades}
        onChange={state.getHandler("settings.trade.maxTrades")}
        // @ts-ignore
        getError={state.getError("settings.trade.maxTrades")}
        errorHint={state.errors.settings?.trade?.maxTrades}
        step="any"
      />
    </SettingsPanel>
  );
});
