import { mobileMediaQuery } from "src/components/UserManagement/Teams/style";
import styled from "styled-components";
import { ButtonsRow } from "./ButtonsRow/style";

export const Container = styled.div`
  width: 100%;

  @media ${mobileMediaQuery("700px")} {
    flex-flow: column;
  }

  display: flex;
  gap: 8px;

  ${ButtonsRow} {
    @media ${mobileMediaQuery("500px")} {
      flex-flow: column;
    }
  }
`;
