import { PropertyKeyKeys, PropertyKeyValues } from "src/modules/shared";

export interface GenericSelectorValue<T, K extends PropertyKeyKeys<T>> {
  label: T[K];
  value: K;
}

export const objectToArrayOptions = <T, K extends PropertyKeyKeys<T>>(
  obj: PropertyKeyValues<T>
): GenericSelectorValue<T, K>[] => {
  const result: GenericSelectorValue<T, K>[] = [];

  const keys = Object.keys(obj) as K[];

  keys.forEach((key) => {
    const value = obj[key];
    result.push({ label: value, value: key });
  });

  return result;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;
