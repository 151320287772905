import { providers } from "ethers";
import { MulticallProvider, MulticallWrapper } from "ethers-multicall-provider";
import { getMulticall } from "ethers-multicall-provider/lib/utils";
import { IReactionDisposer, makeAutoObservable } from "mobx";
import { ChainId } from "src/config/chains";
import { Multicall3 } from "src/contracts/ethers";
import { ChainID } from "src/helpers/chain/contracts";
import { Networks } from "src/helpers/getBalances";
import { makeLoggable } from "src/helpers/logger";
import { makeComputedCache } from "src/helpers/mobx";
import { Disposable } from "src/helpers/utils";
import { Provider } from "zksync-web3";
import { ChainInfo, ExchangeInfosMap, IChainsInfo } from "./ChainsInfoStore";

export interface IChainIDProvider {
  get chainID(): ChainID;
}

export interface IChainProvider extends IChainIDProvider {
  get chainsInfo(): IChainsInfo;
  get network(): Networks | "";
  get rpc(): string | undefined;
  get provider(): providers.JsonRpcProvider | null;
  get multicallProvider(): MulticallProvider<providers.JsonRpcProvider> | null;
  get multicallContract(): Multicall3 | null;
  get currentChain(): ChainInfo | null;
  get currentChainExchanges(): ExchangeInfosMap | null;
  setChainId: (chainId: ChainID) => void;
  getChainsInfo: () => Promise<void>;
}

export default class ChainProviderStore implements IChainProvider, Disposable {
  private _chainId: ChainID = "";

  private _chainsInfo: IChainsInfo;

  private _computedCacheDispose: IReactionDisposer;

  constructor(chainsInfo: IChainsInfo) {
    this._chainsInfo = chainsInfo;

    makeAutoObservable(this);

    this._computedCacheDispose = makeComputedCache(this, [
      "provider",
      "multicallProvider",
      "multicallContract",
    ]);

    makeLoggable(this, {
      multicallProvider: true,
      chainsInfo: true,
      chainID: true,
    });
  }

  get chainsInfo() {
    return this._chainsInfo;
  }

  get chainID() {
    return this._chainId;
  }

  private get _chains() {
    return this._chainsInfo.chains;
  }

  private get _currentChain() {
    return this._chains[this.chainID];
  }

  get currentChain() {
    return this._currentChain ?? null;
  }

  private get _chainExchanges() {
    return this._chainsInfo.chainExchanges;
  }

  private get _currentChainExchanges() {
    return this._chainExchanges[this.chainID];
  }

  get currentChainExchanges() {
    return this._currentChainExchanges ?? null;
  }

  get network() {
    return (this._currentChain?.name ?? "") as Networks | "";
  }

  get rpc() {
    return this._currentChain?.rpc;
  }

  private _getDefaultProvider = (rpc: string) => new providers.JsonRpcProvider(rpc);

  private _getZkSyncProvider = (rpc: string) => new Provider(rpc);

  private _getProvider = (rpc?: string, chainId?: string) => {
    if (!rpc || !chainId) return null;

    if (chainId === ChainId.zkSync) {
      return this._getZkSyncProvider(rpc);
    }
    return this._getDefaultProvider(rpc);
  };

  get provider() {
    const provider = this._getProvider(this.rpc, this._chainId);

    return provider;
  }

  get multicallProvider() {
    const provider = this._getProvider(this.rpc, this._chainId);
    if (!provider) return null;

    return MulticallWrapper.wrap(provider);
  }

  get multicallContract() {
    const { provider } = this;
    const chainId = this.chainID;
    if (!provider || !chainId) return null;
    return getMulticall(null, +chainId, provider) as Multicall3;
  }

  setChainId = (chainId: ChainID) => {
    this._chainId = chainId;
  };

  getChainsInfo = async () => {
    await this._chainsInfo.getChainsInfo();
  };

  destroy = () => {
    this._computedCacheDispose();
  };
}
