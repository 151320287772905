import { makeAutoObservable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { decimalToPercentString } from "src/helpers/calcPerc";
import {
  getData,
  getPathAndKey,
  getTargetValueByPath,
  getValueByPath,
} from "src/helpers/forms/getByKey";
import {
  getChangeEventValue,
  getChangeEventValueNumb,
  getChangeEventValueRangePer,
} from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { Extends, objProp } from "src/helpers/utils";
import { NewLiquidGridSettings } from "src/modules/exchange/trade";
import {
  graterThan,
  isInteger,
  required,
  smallerThan,
  strictlyGreaterThan,
  strictlyGreaterThanKey,
  strictlySmallerThanKey,
  validateData,
} from "src/validation-schemas";
import LiquidityTabStore, { LiquidityFormSuggestions, Precision } from ".";

export type FormSelectors = "accountName";

type LiquidGridSettingsKeys = FormDataKeys<NewLiquidGridSettings>;
type PercentRangeKeys = Extends<LiquidGridSettingsKeys, "profit.quote" | "sellFees.quote">;

type StringSelectorValue = {
  value: string;
  label: string;
};

export interface IGridForm {
  data: NewLiquidGridSettings;
  handlers: FormHandlers<NewLiquidGridSettings>;
  errors: FormErrors<NewLiquidGridSettings>;
  validation: FormValidation<NewLiquidGridSettings>;
  clearForm: () => void;
  setForm: (settings: NewLiquidGridSettings) => void;
  changeState: (state: string) => void;
  percentRangeValue: (key: PercentRangeKeys) => number | "";
  selectorHandler: (key: FormSelectors) => (value: StringSelectorValue | null) => void;
  selectorOptions: (key: FormSelectors) => StringSelectorValue[];
  selectorValue: (key: FormSelectors) => StringSelectorValue;
  suggestions: LiquidityFormSuggestions;
  isActiveSuggestion: (key: FormDataKeys<LiquidityFormSuggestions>) => boolean;
  applySuggestion: (key: FormDataKeys<LiquidityFormSuggestions>) => () => void;
  getHandler: (key: LiquidGridSettingsKeys) => FormFieldHandler;
  getError: (key: LiquidGridSettingsKeys) => string | undefined;
  validate: (validateKeys?: string[]) => boolean;
}

export const EMPTY_LIQUID_SETTINGS: NewLiquidGridSettings = {
  pair: {
    base: "",
    quote: "",
    minAmountBase: "0",
    minAmountQuote: "0",
  },
  exchange: "",
  accountName: "",
  minPrice: "",
  maxPrice: "",
  ordersCount: 0,
  quotePerOrder: "",
  delay: "",
  state: "active",
  buyCount: 0,
  lastCount: 0,
  sellCount: 0,
  profit: {
    base: "0",
    quote: "0",
  },
  sellFees: {
    base: "0",
    quote: "0",
  },
  buyFees: {
    base: "0",
    quote: "0",
  },
  precision: {
    amount: 0,
    price: 0,
  },
  deltas: {
    base: "0",
    quote: "0",
  },
  position: {
    base: "0",
    quote: "0",
  },
  botUUID: "",
};

class GridForm implements IGridForm {
  data: NewLiquidGridSettings = EMPTY_LIQUID_SETTINGS;

  handlers: FormHandlers<NewLiquidGridSettings> = {};

  errors: FormErrors<NewLiquidGridSettings> = {};

  validation: FormValidation<NewLiquidGridSettings> = {
    accountName: required(),
    quotePerOrder: [required(), strictlyGreaterThan(0, "The value must be positive")],
    "precision.price": [required(), graterThan(0, "The value must be positive")],
    "precision.amount": [required(), isInteger()],
    "profit.quote": [required()],
    "sellFees.quote": [required()],
    delay: [
      required(),
      isInteger(),
      strictlyGreaterThan(0, "The value must be positive"),
      graterThan(15, "The value must be greater 15"),
      smallerThan(60, "The value must be less 60"),
    ],
    ordersCount: [required(), isInteger(), strictlyGreaterThan(0, "The value must be positive")],
    minPrice: [
      required(),
      strictlyGreaterThan(0, "The value must be positive"),
      strictlySmallerThanKey("maxPrice", "Min price should be less than max price"),
    ],
    maxPrice: [
      required(),
      strictlyGreaterThan(0, "The value must be positive"),
      strictlyGreaterThanKey("minPrice", "Max price must be greater than min price"),
    ],
  };

  private _onChangeValidate = {
    minPrice: ["minPrice", "maxPrice"],
    maxPrice: ["minPrice", "maxPrice"],
  };

  private _liquidityTab: LiquidityTabStore;

  constructor(liquidityTab: LiquidityTabStore) {
    this._liquidityTab = liquidityTab;

    makeAutoObservable(this);
  }

  private _clearHandlers = () => {
    this.handlers = {};
  };

  private _clearErrors = () => {
    this.errors = {};
  };

  clearForm = () => {
    this.data = EMPTY_LIQUID_SETTINGS;
    this._clearHandlers();
    this._clearErrors();
  };

  setForm = (settings: NewLiquidGridSettings) => {
    this.clearForm();
    this.data = settings;
  };

  changeState = (state: string) => {
    this.data.state = state;
  };

  percentRangeValue = (key: PercentRangeKeys) => {
    const value = getData(this.data, key);
    return value === "" ? value : decimalToPercentString(+value);
  };

  selectorHandler = (key: FormSelectors) => (value: StringSelectorValue | null) => {
    const newValue = value?.value ?? "";
    runInAction(() => {
      this.data[key] = newValue;
    });
  };

  selectorOptions = computedFn((key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return getSelectorList(this._liquidityTab.accounts);
      }
    }
  });

  selectorValue = (key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return stringToSelectorValue(this.data.accountName);
      }
    }
  };

  get suggestions() {
    return this._liquidityTab.suggestions;
  }

  isActiveSuggestion = computedFn((key: FormDataKeys<LiquidityFormSuggestions>) => {
    switch (key) {
      case "precision.amount": {
        return (
          this.suggestions.precision.amount !== "" &&
          this.suggestions.precision.amount !== this.data.precision.amount
        );
      }
      case "precision.price": {
        return (
          this.suggestions.precision.price !== "" &&
          this.suggestions.precision.price !== this.data.precision.price
        );
      }
    }
  });

  applySuggestion = (key: FormDataKeys<LiquidityFormSuggestions>) => () => {
    const { precision } = this.suggestions;
    switch (key) {
      case "precision.amount": {
        this._setPrecisionByKey("amount", +precision.amount);
        break;
      }
      case "precision.price": {
        this._setPrecisionByKey("price", +precision.price);
        break;
      }
    }
  };

  private _setPrecisionByKey = (key: keyof Precision, value: number) => {
    this.data.precision[key] = value;
  };

  getHandler = (key: LiquidGridSettingsKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (key) {
          case "profit.quote": {
            const newValue = getChangeEventValueRangePer(e);
            if (newValue === null) break;
            targetData[endKey] = newValue;
            break;
          }
          case "sellFees.quote": {
            const newValue = getChangeEventValueRangePer(e);
            if (newValue === null) break;
            targetData[endKey] = newValue;
            break;
          }
          case "ordersCount":
          case "delay":
          case "precision.amount":
          case "precision.price": {
            targetData[endKey] = getChangeEventValueNumb(e);
            break;
          }
          default: {
            targetData[endKey] = getChangeEventValue(e);
          }
        }

        if (objProp(key, this._onChangeValidate)) {
          this.validate(this._onChangeValidate[key]);
        }
      };
    }
    return this.handlers[key]!;
  };

  getError = (key: LiquidGridSettingsKeys) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);
}

export default GridForm;
