import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { mobileMediaQuery } from "../../Settings/style";
import { DEXV2PermissionsWrapper } from "../../shared/Permissions/DEXV2PermissionsWrapper";
import * as styles from "./style";

export interface DesktopSwapProps {}

export const DesktopSwap = (_props: DesktopSwapProps) => {
  const isTablet = useMediaQuery(mobileMediaQuery("1450px"));

  const showScreenerInfo = !isTablet;

  return (
    <styles.Content>
      <ErrorBoundary fallback={<styles.SwapScreenerFallback />}>
        <styles.SwapScreener showInfo={showScreenerInfo} />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.SwapWidgetFallback />}>
        <styles.SwapWidget />
      </ErrorBoundary>
      <DEXV2PermissionsWrapper ability={DEXV2Ability.VaultsView}>
        <ErrorBoundary fallback={<styles.VaultsFallback />}>
          <styles.Vaults />
        </ErrorBoundary>
      </DEXV2PermissionsWrapper>
      <styles.PairInfo />
      <ErrorBoundary fallback={<styles.PriceCalculatorFallback />}>
        <styles.PriceCalculator />
      </ErrorBoundary>
    </styles.Content>
  );
};
