import { makeAutoObservable, observable, reaction } from "mobx";
import { toast } from "react-toastify";
import { getHashes, getWallets } from "src/api/bots/DEX/stats";
import { toCSV } from "src/helpers/csv";
// import * as exch from "src/json/exchanges.json";
import {
  getBalanceFloat,
  getBalanceHelperContract,
  getBalanceNativeFloat,
} from "src/helpers/getBalances";
// import { calcRoundingValues } from "src/helpers/rounding";
import { saveAs } from "file-saver";

const exch = require("src/json/exchanges.json");

const EMPTY_CSV_HASHES = { data: "" };

class WalletsState {
  _bot_uuid = "";

  market = "";

  wallets = [];

  hashes = [];

  hashesCount = 0;

  _allHashesCSV = EMPTY_CSV_HASHES;

  link = "";

  exchanges = null;

  convertBalances = {
    accounts: {
      nativeBalances: [],
      leftBalances: [],
      rightBalances: [],
    },
    trackAccounts: {
      nativeBalances: [],
      leftBalances: [],
      rightBalances: [],
    },
  };

  loadWallets = false;

  loadHashes = false;

  _saveAllHashesReaction = undefined;

  constructor() {
    makeAutoObservable(this, {
      _allHashesCSV: observable.ref,
    });
    // set exchanges info from json file
    this.exchanges = exch;
  }

  get bot_uuid() {
    return this._bot_uuid;
  }

  setMarket = (value) => {
    this.market = value;
  };

  subscribe = () => {
    this._saveAllHashesReaction = reaction(
      () => this._allHashesCSV,
      (hashesCSV) => {
        const csv = hashesCSV?.data;
        if (!csv) return;
        const blob = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        const fileName = `${this.market}_hashes`;
        saveAs(blob, `${fileName}.csv`);
        this._clearAllHashesCSV();
      }
    );
  };

  _clearAllHashesCSV = () => {
    this._allHashesCSV = EMPTY_CSV_HASHES;
  };

  unsubscribe = () => {
    this._saveAllHashesReaction?.();
  };

  setBotUUID = (uuid) => {
    this._bot_uuid = uuid;

    this.convertBalances = {
      accounts: {
        nativeBalances: [],
        leftBalances: [],
        rightBalances: [],
      },
      trackAccounts: {
        nativeBalances: [],
        leftBalances: [],
        rightBalances: [],
      },
    };
  };

  setLoad = (field, bool) => {
    this[field] = bool;
  };

  setData = (field, data) => {
    this[field] = data;
  };

  setWalletValue = (arr, wallets) => {
    if (wallets) {
      for (const el of wallets) {
        arr.push(el.open);
      }
    }
  };

  setBalances = (arr = [], startField, endField) => {
    this.convertBalances[startField][endField] = arr;
  };

  get exchange() {
    if (this.market) return this.market.split("_")[2];
    return "";
  }

  setTextClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied!", {
            autoClose: 2000,
          });
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } else this.fallback(text);
  };

  get _balancesHelperContract() {
    const exchangeInfo = this.exchanges[this.exchange];
    if (!exchangeInfo) return null;
    const { network } = exchangeInfo;
    return getBalanceHelperContract(network);
  }

  getNativeBalances = (walletsAccountValue, walletsTrackValue, field) => {
    const contract = this._balancesHelperContract;
    if (!contract) return;

    getBalanceNativeFloat(contract, walletsAccountValue).then((data) => {
      this.setBalances(data, "accounts", field);
    });

    getBalanceNativeFloat(contract, walletsTrackValue).then((data) => {
      this.setBalances(data, "trackAccounts", field);
    });
  };

  getBalances = (walletsAccountValue, walletsTrackValue, token, field) => {
    const contract = this._balancesHelperContract;
    if (!contract) return;

    getBalanceFloat(
      contract,
      token,
      walletsAccountValue,
      this.exchanges[this.exchange].spender
    ).then((data) => {
      this.setBalances(data, "accounts", field);
    });

    getBalanceFloat(contract, token, walletsTrackValue, this.exchanges[this.exchange].spender).then(
      (data) => {
        this.setBalances(data, "trackAccounts", field);
      }
    );
  };

  getWallets = async () => {
    this.setLoad("loadWallets", true);

    try {
      const { data, isError } = await getWallets(this._bot_uuid);
      if (isError) return;
      const { wallets } = data;
      this.setData("wallets", wallets);

      const walletsAccountValue = [];
      const walletsTrackValue = [];
      this.setWalletValue(walletsAccountValue, wallets.accounts);
      this.setWalletValue(walletsTrackValue, wallets.trackAccounts);

      this.getNativeBalances(walletsAccountValue, walletsTrackValue, "nativeBalances");
      this.getBalances(walletsAccountValue, walletsTrackValue, wallets.left, "leftBalances");
      this.getBalances(walletsAccountValue, walletsTrackValue, wallets.right, "rightBalances");
    } finally {
      this.setLoad("loadWallets", false);
    }
  };

  updateHashes = () => {
    this.getHashes(0, 15);
  };

  getAllHashes = async () => {
    if (this.hashesCount === 0) return;

    this.setLoad("loadHashes", true);

    try {
      const { data, isError } = await getHashes(this._bot_uuid, 0, this.hashesCount);

      if (!isError) {
        const { hashes } = data;
        const hashesCSV = toCSV([hashes.Data], [this.market], "Columns");
        this.setData("_allHashesCSV", { data: hashesCSV });
      } else {
        this._clearAllHashesCSV();
      }
    } catch {
      this._clearAllHashesCSV();
    } finally {
      this.setLoad("loadHashes", false);
    }
  };

  getHashes = async (page, size) => {
    this.setLoad("loadHashes", true);

    try {
      const { data, isError } = await getHashes(this._bot_uuid, page, size);
      if (!isError) {
        const { hashes } = data;
        this.setData("hashes", hashes);
        const pagesCount = hashes?.Pages ?? 0;
        this.setData("hashesCount", size * pagesCount);
      }
    } finally {
      this.setLoad("loadHashes", false);
    }
  };

  copyWalletHandler = (text) => () => {
    this.setTextClipboard(text);
  };

  copyAllWallet = (field) => () => {
    if (this.wallets) {
      const text = [];
      if (field === "MM") {
        for (const el of this.wallets.accounts) {
          text.push(el.open);
        }
      } else if (field === "Track") {
        for (const el of this.wallets.trackAccounts) {
          text.push(el.open);
        }
      }
      const allText = text.join("\n");
      this.setTextClipboard(allText);
    }
  };

  fallback = (text) => {
    const isIos = navigator.userAgent.match(/ipad|iphone/i);
    const textarea = document.createElement("textarea");

    // create textarea
    textarea.value = text;

    // ios will zoom in on the input if the font-size is < 16px
    textarea.style.fontSize = "20px";
    document.body.appendChild(textarea);

    // select text
    if (isIos) {
      const range = document.createRange();
      range.selectNodeContents(textarea);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textarea.setSelectionRange(0, 999999);
    } else {
      textarea.select();
    }

    // copy selection
    document.execCommand("copy");

    // cleanup
    document.body.removeChild(textarea);

    toast.success("Copied!", {
      autoClose: 2000,
    });
  };
}

export default WalletsState;
