import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { Disposable, delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { generateTime } from "./FundingStore";
import { GenerateTimeSeriesOptions, generateTimeSeries } from "./SpreadStore";

export type FiatBalanceData = ChartPoint[];

const generateFiatData = (): FiatBalanceData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 100,
      precision: 0.01,
    },
  };

  const data = generateTimeSeries(generateSeriesOptions);

  return data;
};

export default class FiatBalanceStore implements Disposable {
  private _data: FiatBalanceData = [];

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: FiatBalanceData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  getFiatBalance = async () => {
    this._setLoading(true);
    this._setData([]);
    try {
      await delay(200);
      const data = generateFiatData();
      this._setData(data);
    } catch {
      this._setData([]);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
