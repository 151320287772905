import { makeAutoObservable } from "mobx";
import { noOp } from "src/helpers/utils";
import { ISwapModules } from "..";
import { EmptyPriceCalculator } from "./EmptyPriceCalculator";
import { EmptySwapWidget } from "./EmptySwapWidget";

export class EmptySwapModulesStore implements ISwapModules {
  private _swapWidgetState = new EmptySwapWidget();

  private _calculatorState = new EmptyPriceCalculator();

  constructor() {
    makeAutoObservable(this);
  }

  get swapWidgetState() {
    return this._swapWidgetState;
  }

  get calculatorState() {
    return this._calculatorState;
  }

  get router() {
    return null;
  }

  destroy = noOp;
}
