import { Chart } from "chart.js";
import { makeAutoObservable } from "mobx";
import { sumArray } from "src/helpers/array";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { Comparators } from "src/helpers/sorting";
import { formatFiat, formatPercent } from "src/helpers/string";
import { Disposable } from "src/helpers/utils";
import { StringSelectorValue } from "src/modules/shared";

export enum ExchangePieType {
  FreeUsd = "Free usd",
  LockedUsd = "Locked usd",
  TotalUsd = "Total usd",
  TotalTokens = "Total tokens",
}

const DEFAULT_CHART_IDS = Object.values(ExchangePieType);

export interface ExchangePieItem {
  label: string;
  balance: number;
  index: number;
  visible: boolean;
  color: string;
}

export interface ExchangePieItemRow extends Omit<ExchangePieItem, "balance"> {
  percent: string;
  balance: string;
  rawBalance: number;
}

export interface IExchangesLegendState {
  setChartData: (chartId: ExchangePieType, chart: Chart, data: ExchangePieItem[]) => void;
  removeChartData: (chartId: ExchangePieType) => void;
}

interface ExchangeChartData {
  chart: Chart;
  data: ExchangePieItemRow[];
  total: string;
}

const getPercentString = (value: number, total: number) => {
  if (!total) return "0%";
  const rawPercent = Math.round((value / total) * 100);
  const percentage = formatPercent(rawPercent);
  return percentage;
};

const exchangeItemsToRowItems = (items: ExchangePieItem[]): Omit<ExchangeChartData, "chart"> => {
  const total = sumArray(items.map((it) => it.balance));
  const formattedTotal = formatFiat(total, false);
  const rowItems: ExchangePieItemRow[] = items.map(({ balance, ...item }) => {
    const percent = getPercentString(balance, total);
    const formattedBalance = formatFiat(balance, false);
    return { ...item, balance: formattedBalance, percent, rawBalance: balance };
  });
  return { total: formattedTotal, data: rowItems };
};

export default class ExchangesLegendStore implements IExchangesLegendState, Disposable {
  private _chartDataMap: Map<string, ExchangeChartData> = new Map();

  private _selectedChart: ExchangePieType = ExchangePieType.FreeUsd;

  constructor() {
    makeAutoObservable(this);

    makeLoggable<any>(this, { _chartDataMap: true });
  }

  private get _chartIds() {
    return Array.from(this._chartDataMap.keys());
  }

  private get _chartOptions() {
    const keys = this._chartIds.length ? this._chartIds : DEFAULT_CHART_IDS;
    return getSelectorList(keys);
  }

  private get _chartValue() {
    return stringToSelectorValue(this._selectedChart);
  }

  private _onChartChange = (data: StringSelectorValue | null) => {
    if (!data) return;
    this._selectedChart = data.value as ExchangePieType;
  };

  get chartSelectorProps() {
    return {
      value: this._chartValue,
      options: this._chartOptions,
      onChange: this._onChartChange,
    };
  }

  private get _currentChartData() {
    return this._chartDataMap.get(this._selectedChart);
  }

  get data() {
    const data = this._currentChartData?.data ?? [];
    return data.slice().sort((a, b) => Comparators.Number.reverse(a.rawBalance, b.rawBalance));
  }

  get total() {
    return this._currentChartData?.total;
  }

  private get _charts() {
    return Array.from(this._chartDataMap.values()).map(({ chart }) => chart);
  }

  toggleItemVisibility = (index: number) => {
    const charts = this._charts;

    charts.forEach((chart) => {
      chart.toggleDataVisibility(index);
      chart.update();
    });
  };

  resetItemHover = () => {
    const charts = this._charts;

    charts.forEach((chart) => {
      chart.setActiveElements([]);
      chart.update();
    });
  };

  triggerItemHover = (index: number) => {
    const charts = this._charts;

    charts.forEach((chart) => {
      if (chart.getActiveElements().length > 0) {
        chart.setActiveElements([]);
      } else {
        const datasetIndex = 0;
        const datasetMeta = chart.getDatasetMeta(datasetIndex);
        const hasElement = datasetMeta.data[index];
        // check if element is available to handle case
        // when different exchanges count is presented across pies

        if (hasElement) {
          chart.setActiveElements([
            {
              datasetIndex,
              index,
            },
          ]);
        }
      }
      chart.update();
    });
  };

  setChartData = (chartId: ExchangePieType, chart: Chart, data: ExchangePieItem[]) => {
    const rowItems = exchangeItemsToRowItems(data);
    this._chartDataMap.set(chartId, { chart, ...rowItems });
  };

  removeChartData = (chartId: string) => {
    this._chartDataMap.delete(chartId);
  };

  destroy = () => {
    this._chartDataMap.clear();
  };
}
