import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Funding } from "src/components/BotsContent/Funding";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { ContentPermission } from "src/permissions/ContentPermission";
import useAppState from "src/state";
import { BotInfo } from "../CEX";
import { Content } from "../shared";
import { DEXBotSettings } from "./DEXBotSettings";
import { Stats } from "./Stats";

export const DEXContent = observer(() => {
  const { path: bot_uuid } = useParams<{ path: string }>();

  const { DEXBotState } = useAppState();

  // fixing a bug with duplicate requests
  useState(() => {
    runInAction(() => {
      DEXBotState.resetBot();
    });
    return null;
  });

  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const currentTab = queryParams.get("tab");

  const botInfoProps: BotInfo = useMemo(
    () => ({
      party: DEXBotState.party,
      market: DEXBotState.market,
      bot_uuid: DEXBotState.bot_uuid,
    }),
    [DEXBotState.party, DEXBotState.market, DEXBotState.bot_uuid]
  );

  useEffect(() => {
    if (!bot_uuid) return;
    DEXBotState.setBotUUID(bot_uuid);
    DEXBotState.loadBot();
  }, [DEXBotState, bot_uuid]);

  const getContent = () => {
    const permissionProps = {
      party: DEXBotState.party,
      loader: DEXBotState.showLoader,
    };

    switch (currentTab) {
      case "STATS": {
        return (
          <ContentPermission abilityName="dex-stats-view" {...permissionProps}>
            <ErrorBoundary>
              <Stats botInfo={botInfoProps} />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "SETTINGS": {
        return (
          <ContentPermission abilityName="dex-settings-view" {...permissionProps}>
            <ErrorBoundary>
              <DEXBotSettings botInfo={botInfoProps} abilityName="dex-settings-edit" />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      case "FUNDING": {
        return (
          <ContentPermission abilityName="dex-funding-view" {...permissionProps}>
            <ErrorBoundary>
              <Funding type="DEX" botInfo={botInfoProps} abilityName="dex-funding-edit" />
            </ErrorBoundary>
          </ContentPermission>
        );
      }
      default: {
        return <Redirect to={`/DEX/${bot_uuid}/?tab=STATS`} />;
      }
    }
  };

  return <Content>{getContent()}</Content>;
});
