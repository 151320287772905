import { css } from "styled-components";
import { Container } from "./Card/Header/style";
import { Content } from "./Card/style";

export const ChartCardPaddingFix = css`
  ${Container} {
    z-index: 1;
  }

  ${Content} {
    //chart adds weird top padding, compensate with margin
    margin-top: -10px;
  }
`;
