import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import useAppState from "src/state";
import * as styles from "./style";
// import { UserActions } from "./UserActions";

export const Spectr = observer(({ botInfo: { bot_uuid }, startPoint, endPoint }) => {
  const { SpectrState: state } = useAppState();

  const spectr = useRef(null);
  const spectrLine = useRef(null);

  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (spectr && spectr.current) {
      const { clientWidth } = spectr.current;
      setWidth(clientWidth);
    }
  }, [spectr]);

  useEffect(() => {
    if (!bot_uuid) return;
    state.setBotUUID(bot_uuid);
    state.getStatusList(startPoint, endPoint);
  }, [state, bot_uuid, startPoint, endPoint]);

  useEffect(() => {
    const spectrCurrent = spectr.current;

    state.setTimePeriod((endPoint - startPoint) / 60);

    updateWidth();

    state.setSpectrWidth(width);

    state.createTimeIntervals(width);

    // state.getStatusList(startPoint, endPoint);

    // state.getGradient(state.statusList);
    // state.createCanvas(spectr.current.clientWidth);
    // state.createTimeIntervals(spectr.current.clientWidth);
    const resize = () => {
      updateWidth();

      // state.createCanvasGradient(spectr.current.clientWidth);
      state.createTimeIntervals(width);
    };

    const cb = (e) => {
      state.changeStatus(e, spectrLine.current);
      state.showStatusTime(e, width, spectrLine.current);
    };

    window.addEventListener("resize", resize);

    spectrCurrent.addEventListener("mousemove", cb);

    return () => {
      window.removeEventListener("resize", resize);

      spectrCurrent.removeEventListener("mousemove", cb);
    };
  }, [state, startPoint, endPoint, updateWidth, width]);

  return (
    <styles.SpectrContainer>
      {/* <UserActions /> */}
      <styles.SpectrTimeLine ref={spectr}>
        <styles.Spectr id="spectr" ref={spectrLine} />
        {state.timeIntervals.map((el, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <styles.TimeInterval style={{ width: `${el.widthInterval}px` }} key={index}>
            {el.timeInterval}
          </styles.TimeInterval>
        ))}
      </styles.SpectrTimeLine>
    </styles.SpectrContainer>
  );
});
